import i18n from "i18next";
import { months } from "src/utils/Date";

class Convert {
    private convert(num: number) {
        return {
            from: function (baseFrom: number) {
                return {
                    to: function (baseTo: number) {
                        return parseInt(num.toString(), baseFrom).toString(baseTo);
                    }
                };
            }
        };
    }

    private decimal2binary(num: number) {
        return this.convert(num).from(10).to(2).padStart(12, "0");
    }

    private toMonths(bin: string, mm: string[]) {
        return mm
            .filter((month, index) => {
                return bin[index] === "1" ? month : false;
            })
            .join(", ");
    }

    public dec2months(num: number, short: boolean) {
        if (num <= 0 || num > 4095) return i18n.t("labels.none");
        return this.toMonths(
            this.decimal2binary(num),
            short
                ? months.map((m) => i18n.t("others.months_short." + m))
                : months.map((m) => i18n.t("others.months_long." + m))
        );
    }

    public getMonthLong(num: number) {
        return months.map((m) => i18n.t("others.months_long." + m))[num - 1];
    }

    public decMonths(num: number) {
        if (num <= 0 || num > 4095) return i18n.t("labels.none");
        return this.toMonths(
            this.decimal2binary(num),
            months.map((m) => i18n.t("others.months_short." + m))
        );
    }

    public getShortMonthName(index: number): string {
        const shortMonthNames: string[] = months.map((m) => i18n.t("others.months_short." + m));
        return shortMonthNames[index];
    }
}

export const convert = new Convert();
