import { gql } from "@apollo/client";

export const GET_REALESTATE_PERSONS = gql`
    query GetRealestatePersons($realestateid: uuid!, $lang: String = "de") {
        realestatepersons: common_realestatepersons(
            where: { realestateid: { _eq: $realestateid } }
            order_by: { role: asc }
        ) {
            id
            personid
            role
            person {
                legal
                name1
                name2
            }
        }
        realestatepersonroles: common_enumerations(
            where: { type: { _eq: 15 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;
