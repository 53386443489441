import { gql } from "@apollo/client";

export const GET_USER_RATING = gql`
    query GetUserRating($userid: uuid!) {
        ums_users(where: { id: { _eq: $userid } }) {
            id
            ratingstateportal
        }
    }
`;

export const INSERT_USER_RATING = gql`
    mutation InsertUserRating($rating: Int!, $comment: String!, $userid: uuid!, $system: Int!) {
        insert_ums_userfeedbacks(objects: { rating: $rating, comment: $comment, system: $system }) {
            affected_rows
        }
        update_ums_users(_set: { ratingstateportal: 0 }, where: { id: { _eq: $userid } }) {
            affected_rows
        }
    }
`;

export const UPDATE_USER_RATING_STATE = gql`
    mutation UpdateUserRatingstate($userid: uuid!, $state: Int!) {
        update_ums_users(_set: { ratingstateportal: $state }, where: { id: { _eq: $userid } }) {
            affected_rows
        }
    }
`;

export const GET_USER_RATINGS_PORTAL = gql`
    query GetUserRatingsPortal {
        userratingsportal: ums_v_userratingportal(where: { ratingdateportal: { _is_null: false } }) {
            userid
            username
            email
            ratingdateportal
            rating
            comment
            userroles
        }

        userratingsportalheader: ums_v_userratingportal_aggregate(where: { ratingdateportal: { _is_null: false } }) {
            aggregate {
                count
                avg {
                    rating
                }
            }
        }
    }
`;
