import React, { useContext, useCallback } from "react";
import { RootStoreContext } from "src/stores/RootStore";
import { UI, ScreenSize } from "@wwimmo/ui";
import { getDate, getDateWithMonthAndYear } from "src/utils/Date";
import { Route, selectRoute } from "src/config/routes";
import { InvoiceDetail } from "src/types";
import { getCurrencyElement } from "src/utils/Currency";

interface ServicesCostsAndInvoicesHistoryListItemProps {
    invoiceDetail: InvoiceDetail;
    screenSize: ScreenSize;
}

export const ServicesCostsAndInvoicesHistoryListItem = (props: ServicesCostsAndInvoicesHistoryListItemProps) => {
    const { authStore, servicesStore } = useContext(RootStoreContext);

    const onChangeCheckItem = useCallback(() => {
        servicesStore.toggleInvoiceSelected(props.invoiceDetail.id);
    }, [props.invoiceDetail.id, servicesStore]);

    const monthAndYearText = getDateWithMonthAndYear(props.invoiceDetail.yearAndMonth);
    const amount = getCurrencyElement(props.invoiceDetail.amount);
    const invoiced = getDate(props.invoiceDetail.invoiced);

    const dateParts = props.invoiceDetail.yearAndMonth.split("-");
    const year = Number(dateParts[0]);
    const month = Number(dateParts[1]);

    const invoiceDetailsUrl = selectRoute(
        Route.servicesCostsAndInvoicesHistoryInvoiceDetailsMonth,
        authStore.user?.role,
        { year: year.toString(), month: month.toString() }
    );

    const checkItem = (
        <div>
            <UI.Checkbox
                name={"chk-isInvoiceMonthSelected"}
                onChange={onChangeCheckItem}
                key={"chk-isInvoiceMonthSelected"}
                checked={props.invoiceDetail.selected}
            />
        </div>
    );

    let listRows: React.ReactElement[] = [];

    switch (props.screenSize) {
        case ScreenSize.DESKTOP:
            listRows = [
                <UI.List.Row key={"r-1"}>
                    <UI.List.Cell key={"c-1"} colspan={0} value={""} />
                    <UI.List.Cell key={"c-2"} colspan={3} value={monthAndYearText ?? ""} />
                    <UI.List.Cell key={"c-3"} colspan={1} value={amount ?? ""} />
                    <UI.List.Cell key={"c-4"} colspan={1} value={invoiced ?? ""} />
                </UI.List.Row>
            ];
            break;

        case ScreenSize.MOBILE:
            listRows = [
                <UI.List.Row key={"r-1"}>
                    <UI.List.Cell key={"c-1"} colspan={0} value={""} />
                    <UI.List.Cell key={"c-2"} colspan={2} value={monthAndYearText ?? ""} />
                    <UI.List.Cell key={"c-3"} colspan={1} value={amount ?? ""} />
                    <UI.List.Cell key={"c-4"} colspan={1} value={invoiced ?? ""} />
                </UI.List.Row>
            ];
            break;
    }

    return (
        <UI.List.Item
            key={props.invoiceDetail.yearAndMonth}
            screenSize={props.screenSize}
            rows={listRows}
            to={invoiceDetailsUrl}
            checkBox={checkItem}
        />
    );
};
