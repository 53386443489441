import { runInAction } from "mobx";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { Route } from "src/config/routes";
import { Loading } from "src/screens/loading/Loading";
import { RootStoreContext } from "src/stores/RootStore";
import { AuthWrapper } from "src/screens/auth/AuthWrapper";
import { UI } from "@wwimmo/ui";
import i18next from "i18next";
import { useUrlQuery } from "src/utils/Route";

enum RECOVERYSTATES {
    PENDING = 0,
    ACTIVE = 1,
    DONE = 2
}

interface PasswordRecoveryForm {
    email: string;
}

export const PasswordRecovery = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const history = useHistory();

    let urlQuery = useUrlQuery();
    const email = urlQuery.get("email");

    const { navStore, authStore } = useContext(RootStoreContext);
    const [retrieveState, setRetrieveState] = useState<RECOVERYSTATES>(RECOVERYSTATES.PENDING);
    const [error, setError] = useState<Error | undefined>(undefined);
    const {
        register,
        handleSubmit,

        formState: { errors }
    } = useForm<PasswordRecoveryForm>({
        defaultValues: { email: email ?? "" }
    });

    useEffect(() => {
        runInAction(() => {
            navStore.setTitle(t("screens.auth.password.recovery.title"));
            navStore.setBackbutton(Route.login, t("screens.auth.login"));
        });
    }, [navStore, t, props.location.search]);

    const onSubmit = async (formData: PasswordRecoveryForm) => {
        setRetrieveState(RECOVERYSTATES.ACTIVE);

        let hasPasswordRecoveryError = false;

        urlQuery.set("email", formData.email);
        history.replace({ search: urlQuery.toString() });

        try {
            await authStore.authClient.passwordRecovery(formData.email);
        } catch (error: any) {
            hasPasswordRecoveryError = true;

            error.message = i18next.exists(`error.${error.message}`)
                ? t(`error.${error.message}`)
                : t("screens.auth.password.recovery.default_error");

            setError(error);
        }

        if (hasPasswordRecoveryError) {
            setRetrieveState(RECOVERYSTATES.PENDING);
        } else {
            setRetrieveState(RECOVERYSTATES.DONE);
        }
    };

    if (retrieveState === RECOVERYSTATES.ACTIVE) {
        return (
            <AuthWrapper>
                <Loading noLogo noBackgroundChange />
            </AuthWrapper>
        );
    }

    const content = () => {
        if (retrieveState === RECOVERYSTATES.DONE) {
            return (
                <>
                    <div>
                        <Trans>screens.auth.password.recovery.email_title</Trans>
                    </div>
                    <div>
                        <Trans>screens.auth.password.recovery.email</Trans>
                    </div>
                </>
            );
        }
        return (
            <UI.Form onSubmit={handleSubmit(onSubmit)}>
                <UI.FormGroup controlId="formEmail" className="mb-3">
                    <UI.Form.Label>
                        <Trans>screens.auth.emailaddress</Trans>
                    </UI.Form.Label>
                    <UI.Form.Control
                        {...register("email", { required: true })}
                        type="email"
                        placeholder={t("screens.auth.emailaddress_placeholder")}
                        isInvalid={errors.email !== undefined}
                    />
                </UI.FormGroup>
                <UI.Button type="submit" label={t("screens.auth.password.recovery.title")} />
                {error && (
                    <UI.Alert variant="danger" className="mt-2">
                        {error.message}
                    </UI.Alert>
                )}
            </UI.Form>
        );
    };

    return (
        <AuthWrapper>
            {content()}
            <hr />
            <p>
                <Link to={Route.login}>
                    <Trans>screens.auth.login</Trans>
                </Link>
                <br />
                <Link to={Route.register}>
                    <Trans>screens.auth.register.title</Trans>
                </Link>
                <br />
                <Link to={Route.resendActivationEMail}>
                    <Trans>screens.auth.activate.resendactivationemail</Trans>
                </Link>
            </p>
        </AuthWrapper>
    );
};
