import React, { useCallback, useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { IIncidentPerson, INCIDENT_PERSON_ROLE } from "src/stores/TicketStore";
import { ContactItem } from "src/components/contact/contactitem/ContactItem";
import { ContactItemType } from "src/components/contact/Contact";
import { useQuery } from "@apollo/client";
import { GetIncidentPersonTypes, GetIncidentPersonTypesVariables } from "src/api/generated/GetIncidentPersonTypes";
import { getLang } from "src/utils/i18n";
import { GET_INCIDENTPERSON_ENUM } from "src/api/ticket";
import { RootStoreContext } from "src/stores/RootStore";
interface TicketContactCardProps {
    incidentPersons: IIncidentPerson[];
}
const Person = (props: { person: IIncidentPerson; role: string }) => {
    const { person, role } = props;
    const contacts: { type: ContactItemType; content: string }[] = [];
    person.phone && contacts.push({ type: ContactItemType.PHONE, content: person.phone });
    person.mobile && contacts.push({ type: ContactItemType.DEVICE, content: person.mobile });
    person.email && contacts.push({ type: ContactItemType.MAIL, content: person.email });
    return (
        <UI.Col>
            <div className="mb-4">
                <ContactItem name={`${person.name1} ${person.name2 ?? ""}`} contacts={contacts} role={role} />
            </div>
        </UI.Col>
    );
};

const TicketContactCardBase = (props: TicketContactCardProps) => {
    const { manualEmailModalStore } = useContext(RootStoreContext);
    const { incidentPersons } = props;
    const { t } = useTranslation();
    const { data } = useQuery<GetIncidentPersonTypes, GetIncidentPersonTypesVariables>(GET_INCIDENTPERSON_ENUM, {
        variables: { language: getLang() }
    });
    const incidentPersonEnums = useMemo(() => data?.common_enumerations, [data]);
    manualEmailModalStore.setIncidentPersonEnums(incidentPersonEnums || []);

    const getPerson = useCallback(
        (key: number) => {
            return {
                person: incidentPersons.find((ip) => ip.role === key),
                label: incidentPersonEnums?.find((e) => e.key === key)?.label ?? ""
            };
        },
        [incidentPersons, incidentPersonEnums]
    );
    const contacts = useMemo(
        () => ({
            contact: getPerson(INCIDENT_PERSON_ROLE.CONTACT), // Melder
            contractor: getPerson(INCIDENT_PERSON_ROLE.CONTRACTOR), // Dienstleister
            facility: getPerson(INCIDENT_PERSON_ROLE.FACILITY), // Hauswart
            owner: getPerson(INCIDENT_PERSON_ROLE.OWNER), // Eigentümer
            manager: getPerson(INCIDENT_PERSON_ROLE.MANAGER) // Bewirtschaftung
        }),
        [getPerson]
    );
    const PersonComponent = React.memo(Person);

    return (
        <UI.Card title={t("screens.contact.title").toString()} flex>
            <UI.Row className="ContactTile d-flex flex-column flex-md-row flex-xl-column">
                {contacts.contact.person && (
                    <PersonComponent person={contacts.contact.person} role={contacts.contact.label} />
                )}
                {contacts.contractor.person && (
                    <PersonComponent person={contacts.contractor.person} role={contacts.contractor.label} />
                )}
                {contacts.facility.person && (
                    <PersonComponent person={contacts.facility.person} role={contacts.facility.label} />
                )}
                {contacts.owner.person && (
                    <PersonComponent person={contacts.owner.person} role={contacts.owner.label} />
                )}
                {contacts.manager.person && (
                    <PersonComponent person={contacts.manager.person} role={contacts.manager.label} />
                )}
            </UI.Row>
        </UI.Card>
    );
};

export const TicketContactCard = Sentry.withProfiler(observer(TicketContactCardBase));
