import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import {
    INCIDENT_CONTACT_PERSON_PROPERTY,
    PERSON_SEARCH_FILTER_TYPE,
    TICKET_PROPERTY,
    TICKET_STATE
} from "src/stores/TicketStore";
import { IIncidentContactPersonData } from "src/stores/tickets/ContactPersonSearchStore";
import { useSelectionListKeyHandler } from "src/hooks/ticket/selection-list-key-handler/useSelectionListKeyHandler";
import { boldenMatchedSubstring } from "src/utils/Common";
import styles from "./PersonFormFields.module.css";
import { ColorStyle } from "src/utils/Colors";
import debounce from "lodash/debounce";
import "./GeneralFormStyles.css";

const PersonFormFieldsBase = () => {
    const { t } = useTranslation();
    const { ticketStore, uiStore, contactPersonSearchStore, realestateSearchStore, unitSearchStore } =
        useContext(RootStoreContext);

    const [displayPersonList, setDisplayPersonList] = useState<boolean>(false);
    const [lastScrollPositionYPersonItems, setLastSrollYPositionPersonItems] = useState<number>(0);

    const personInputRef = useRef<HTMLInputElement>(null);

    const resetSearchQuery = useCallback(() => {
        contactPersonSearchStore.setCurrentPersonSearchQuery(contactPersonSearchStore.selectedPersonQueryString ?? "");
    }, [contactPersonSearchStore]);

    useEffect(() => {
        const handleClickOutsidePersonInput = (event: any) => {
            if (
                displayPersonList &&
                personInputRef.current &&
                !personInputRef.current.contains(event.target) &&
                !(event.target.classList.contains("list-item") && event.target.classList.contains("person")) &&
                !(event.target.classList.contains("highlighted-text") && event.target.classList.contains("person")) &&
                !event.target.classList.contains("person-list-item-span") &&
                !event.target.classList.contains("person-filter-container") &&
                !event.target.classList.contains("person-filter-button") &&
                !event.target.classList.contains("person-filter-button-text") &&
                !event.target.classList.contains("person-filter-button-icon") &&
                !event.target.classList.contains("person-list-item-line-1") &&
                !event.target.classList.contains("person-list-item-line-2") &&
                !event.target?.parentElement?.parentElement?.parentElement.classList?.contains("IconSVG")
            ) {
                setDisplayPersonList(false);

                if (
                    ticketStore.currentTicket?.isIncidentContactPersonSelected &&
                    contactPersonSearchStore.currentPersonSearchQuery !==
                        contactPersonSearchStore.selectedPersonQueryString
                ) {
                    resetSearchQuery();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutsidePersonInput);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsidePersonInput);
        };
    }, [
        displayPersonList,
        ticketStore,
        resetSearchQuery,
        contactPersonSearchStore.currentPersonSearchQuery,
        contactPersonSearchStore.selectedPersonQueryString
    ]);

    useEffect(() => {
        if (contactPersonSearchStore.triggerFocusOnPersonInput) {
            personInputRef.current?.focus();
            contactPersonSearchStore.setTriggerFocusOnPersonInput(false);
        }
        // This useEffect only listens to changes in contactPersonSearchStore.triggerFocusOnPersonInput
        // eslint-disable-next-line
    }, [contactPersonSearchStore.triggerFocusOnPersonInput]);

    const updateTicketStoreWithSelectedPerson = useCallback(
        async (person: IIncidentContactPersonData | undefined) => {
            if (person) {
                contactPersonSearchStore.setCurrentPersonSearchQuery("");
                if (ticketStore.currentTicket) {
                    ticketStore.resetPersonFormField();
                    ticketStore.currentTicket.updateProperty(TICKET_PROPERTY.INCIDENT_CONTACT_PERSON, person);
                }
            }
        },
        [ticketStore, contactPersonSearchStore]
    );

    const onSelectPersonItem = useCallback(
        (activeListItemIndex: number) => {
            const selectedPersonItem = contactPersonSearchStore.filteredPersonData[activeListItemIndex];

            updateTicketStoreWithSelectedPerson(selectedPersonItem);
        },
        [contactPersonSearchStore.filteredPersonData, updateTicketStoreWithSelectedPerson]
    );

    const { onKeyDownFunction, setActiveListItemIndex, NO_LIST_ITEM_SELECTED } = useSelectionListKeyHandler({
        listName: "person",
        totalNumberOfDisplayedItems: ticketStore.numberOfDisplayedItems,
        setIsListDisplayed: setDisplayPersonList,
        isListDisplayed: displayPersonList,
        onSelectItem: onSelectPersonItem,
        resetSearchQuery: resetSearchQuery
    });

    const resetAmountOfItemsDisplayedInPersonList = useCallback(() => {
        setLastSrollYPositionPersonItems(0);
        ticketStore.setNumberOfDisplayedItems(ticketStore.sliceSizeOfDisplayedItems);
    }, [ticketStore]);

    const searchPerson = debounce((query: string) => {
        contactPersonSearchStore.searchPersonsInBackendByQuery(query);
    }, 800);

    const onChangePersonInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            resetAmountOfItemsDisplayedInPersonList();

            const inputPerson = e.target.value;
            contactPersonSearchStore.setCurrentPersonSearchQuery(inputPerson);

            if (contactPersonSearchStore.isSearchingPersonsInBackendByQuery) {
                if (inputPerson.length > 2) {
                    searchPerson(inputPerson);
                } else {
                    contactPersonSearchStore.setPersonData([]);
                }
            }
        },

        // searchPerson function cannot be in dependency array, because it is a debounced function
        // eslint-disable-next-line
        [contactPersonSearchStore, resetAmountOfItemsDisplayedInPersonList]
    );

    const onFocusPersonInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (ticketStore.currentTicket?.isIncidentContactPersonSelected && personInputRef.current) {
                personInputRef.current.select();
            }

            resetAmountOfItemsDisplayedInPersonList();
            setDisplayPersonList(true);
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
        },
        [
            resetAmountOfItemsDisplayedInPersonList,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            ticketStore.currentTicket?.isIncidentContactPersonSelected
        ]
    );

    const onClickPersonItem = useCallback(
        (personId: string) => async (e: any) => {
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
            setDisplayPersonList(false);

            const selectedPersonItem = contactPersonSearchStore.filteredPersonData.find((person) => {
                return person.personid === personId;
            });

            updateTicketStoreWithSelectedPerson(selectedPersonItem);
        },
        [
            updateTicketStoreWithSelectedPerson,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            contactPersonSearchStore.filteredPersonData
        ]
    );

    const onScrollPersonList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYPersonItems;

            const hasMoreResulsToDisplay =
                ticketStore.numberOfDisplayedItems < contactPersonSearchStore.filteredPersonData.length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                ticketStore.setNumberOfDisplayedItems(
                    ticketStore.numberOfDisplayedItems + ticketStore.sliceSizeOfDisplayedItems
                );
            }

            setLastSrollYPositionPersonItems(pixelsFromTop);
        },
        [lastScrollPositionYPersonItems, ticketStore, contactPersonSearchStore.filteredPersonData.length]
    );

    const getSpanElementWithFormattedText = useCallback(
        (stringToFormat: string) => {
            const formattedString =
                contactPersonSearchStore.currentPersonSearchQuery !== ""
                    ? boldenMatchedSubstring(
                          contactPersonSearchStore.currentPersonSearchQuery ?? "",
                          stringToFormat,
                          "highlighted-text person"
                      )
                    : stringToFormat;

            return (
                <span
                    className="person-list-item-span"
                    dangerouslySetInnerHTML={{
                        __html: formattedString
                    }}
                />
            );
        },
        [contactPersonSearchStore.currentPersonSearchQuery]
    );

    const togglePersonFilter = useCallback(
        (personSearchFilterType: PERSON_SEARCH_FILTER_TYPE) => () => {
            switch (personSearchFilterType) {
                case PERSON_SEARCH_FILTER_TYPE.UNIT:
                    contactPersonSearchStore.togglePersonSearchFilterUnit();
                    break;
                case PERSON_SEARCH_FILTER_TYPE.REALESTATE:
                    contactPersonSearchStore.togglePersonSearchFilterRealestate();
                    break;
            }
        },
        [contactPersonSearchStore]
    );

    const onChangeIncidentContactPersonField = useCallback(
        (incidentContactPersonProperty: INCIDENT_CONTACT_PERSON_PROPERTY) =>
            (e: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = e.target.value;

                if (ticketStore.currentTicket) {
                    ticketStore.currentTicket.updateIncidentContactPersonProperty(
                        incidentContactPersonProperty,
                        newValue
                    );
                }
            },
        [ticketStore.currentTicket]
    );

    const filterActivationIcon = uiStore.isMobile ? (
        <div className="person-filter-button-icon">+</div>
    ) : (
        <UI.Icon className="person-filter-button-icon" icon={UI.SVGIcon.Plus} size={25} color={ColorStyle("grey")} />
    );

    const filterDeactivationIcon = (
        <UI.Icon className="person-filter-button-icon" icon={UI.SVGIcon.Close} size={12} color={ColorStyle("white")} />
    );

    const personFilterButtonUnit = (
        <div
            className={`person-filter-button mr-2 ${!unitSearchStore.selectedUnitQueryString ? "not-clickable" : ""} ${
                contactPersonSearchStore.personSearchFilter.byUnit ? "filter-active" : ""
            }`}
            onClick={
                unitSearchStore.selectedUnitQueryString ? togglePersonFilter(PERSON_SEARCH_FILTER_TYPE.UNIT) : undefined
            }
        >
            <div className="person-filter-button-text">
                {t("screens.tickets.form.person.unit_filter_abr")}: {unitSearchStore.selectedUnitQueryString}
            </div>
            {contactPersonSearchStore.personSearchFilter.byUnit ? filterDeactivationIcon : filterActivationIcon}
        </div>
    );

    const personFilterButtonRealestate = (
        <div
            className={`person-filter-button ${!realestateSearchStore.selectedRealestateName ? "not-clickable" : ""} ${
                contactPersonSearchStore.personSearchFilter.byRealestate ? "filter-active" : ""
            }`}
            onClick={
                realestateSearchStore.selectedRealestateName
                    ? togglePersonFilter(PERSON_SEARCH_FILTER_TYPE.REALESTATE)
                    : undefined
            }
        >
            <div className="person-filter-button-text">
                {t("screens.tickets.form.person.realestate_filter_abr")}: {realestateSearchStore.selectedRealestateName}
            </div>
            {contactPersonSearchStore.personSearchFilter.byRealestate ? filterDeactivationIcon : filterActivationIcon}
        </div>
    );

    const personSelectionList = (
        <div id="person-dropdown-list-container">
            <div className={"person-filter-container"}>
                {personFilterButtonUnit}
                {personFilterButtonRealestate}
            </div>
            <div
                className={`${styles.PersonSelectionListSearchIndicator} ${
                    !contactPersonSearchStore.isSearchingPersonData &&
                    !(
                        contactPersonSearchStore.personData.length === 0 &&
                        !contactPersonSearchStore.isSearchingPersonData
                    )
                        ? styles.NoBorderBottom
                        : ""
                }`}
            >
                {contactPersonSearchStore.isSearchingPersonData ? (
                    <UI.RotatingSpinner noLogo size={30} className={styles.SearchingPersonsSpinner} />
                ) : undefined}
            </div>
            {contactPersonSearchStore.isSearchingPersonData ? undefined : (
                <ul id="person-dropdown-list" className={"dropdown-list person"} onScroll={onScrollPersonList}>
                    {contactPersonSearchStore.filteredPersonData
                        .slice(0, ticketStore.numberOfDisplayedItems)
                        .map((person, index) => {
                            const personName = `${person.name1}${`${person.name2 ? ` ${person.name2}` : ""}`}`.trim();
                            const personZipCity = `${person.zip} ${person.city}`.trim();

                            const formattedPersonNameSpan = getSpanElementWithFormattedText(personName ?? "");
                            const formattedPersonStreetSpan = getSpanElementWithFormattedText(person.street ?? "");
                            const formattedPersonZipCitySpan = getSpanElementWithFormattedText(personZipCity ?? "");
                            const formattedPersonEmailSpan = person.email
                                ? getSpanElementWithFormattedText(person.email)
                                : undefined;
                            const formattedPersonMobileSpan = person.mobile
                                ? getSpanElementWithFormattedText(person.mobile)
                                : undefined;

                            return (
                                <li key={index}>
                                    <div
                                        className={"list-item person"}
                                        id={`person-list-item-${index}`}
                                        onClick={onClickPersonItem(person.personid)}
                                    >
                                        {uiStore.isMobile ? (
                                            <>
                                                <div>{formattedPersonNameSpan}</div>
                                                <div>{formattedPersonStreetSpan}</div>
                                                <div>{formattedPersonZipCitySpan}</div>
                                                <div>{formattedPersonEmailSpan}</div>
                                                <div>{formattedPersonMobileSpan}</div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={"person-list-item-line-1"}>
                                                    {formattedPersonNameSpan}, {formattedPersonStreetSpan},{" "}
                                                    {formattedPersonZipCitySpan}
                                                </div>
                                                <div className={"person-list-item-line-2"}>
                                                    {formattedPersonEmailSpan}
                                                    {formattedPersonEmailSpan === undefined ||
                                                    formattedPersonMobileSpan === undefined
                                                        ? ""
                                                        : formattedPersonEmailSpan !== undefined &&
                                                          formattedPersonMobileSpan !== undefined
                                                        ? ", "
                                                        : ""}
                                                    {formattedPersonMobileSpan}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            )}
        </div>
    );

    const editModePersonElements = (
        <>
            <UI.Row className="mt-md-2">
                <UI.Col lg={7}>
                    <UI.Input
                        ref={personInputRef}
                        type="text"
                        autoComplete="off"
                        className={styles.PersonInputField}
                        id="ticket-form-peson-input"
                        value={contactPersonSearchStore.currentPersonSearchQuery ?? ""}
                        onChange={onChangePersonInput}
                        onFocus={onFocusPersonInput}
                        onKeyDown={onKeyDownFunction}
                        disabled={
                            !ticketStore.isEditing ||
                            ticketStore.currentTicket?.state === TICKET_STATE.COMPLETED ||
                            ticketStore.currentTicket?.state === TICKET_STATE.ARCHIVED
                        }
                        iconRight={
                            <UI.Icon icon={UI.SVGIcon.Search} color="grey" size={"small"} style={{ right: "5px" }} />
                        }
                    />
                    {displayPersonList ? personSelectionList : undefined}
                </UI.Col>
            </UI.Row>
            <UI.Row>
                <UI.Col lg={3}>
                    <UI.Input
                        label={t("screens.tickets.form.person.salutation").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            ticketStore.currentTicket &&
                            ticketStore.currentTicket.incidentContactPerson &&
                            ticketStore.currentTicket &&
                            ticketStore.currentTicket.incidentContactPerson.gender
                                ? t(
                                      `screens.tickets.form.person.gender_${ticketStore.currentTicket.incidentContactPerson?.gender}`
                                  ).toString()
                                : ""
                        }
                    />
                </UI.Col>
            </UI.Row>
            <UI.Row>
                <UI.Col lg={3}>
                    <UI.Input
                        label={t("screens.tickets.form.person.firstname").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.incidentContactPerson
                                ? ticketStore.currentTicket.incidentContactPerson?.name1 ?? ""
                                : ""
                        }
                    />
                </UI.Col>
                <UI.Col lg={3}>
                    <UI.Input
                        label={t("screens.tickets.form.person.lastname").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.incidentContactPerson
                                ? ticketStore.currentTicket.incidentContactPerson?.name2 ?? ""
                                : ""
                        }
                    />
                </UI.Col>
                <UI.Col lg={6}>
                    <UI.Input
                        label={t("screens.tickets.form.person.email").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={ticketStore.currentTicket?.canUpdateIncidentContactPerson ? false : true}
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.incidentContactPerson
                                ? ticketStore.currentTicket.incidentContactPerson?.email ?? ""
                                : ""
                        }
                        onChange={onChangeIncidentContactPersonField(INCIDENT_CONTACT_PERSON_PROPERTY.EMAIL)}
                        iconRight={<UI.Icon icon={UI.SVGIcon.Mail} color="grey" size={"medium"} />}
                    />
                </UI.Col>
            </UI.Row>
            <UI.Row>
                <UI.Col lg={5}>
                    <UI.Input
                        label={t("screens.tickets.form.person.street").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.incidentContactPerson
                                ? ticketStore.currentTicket.incidentContactPerson?.street ?? ""
                                : ""
                        }
                    />
                </UI.Col>
                <UI.Col lg={1}>
                    <UI.Input
                        label={t("screens.tickets.form.person.street_number").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.incidentContactPerson
                                ? ticketStore.currentTicket.incidentContactPerson?.housenumber ?? ""
                                : ""
                        }
                    />
                </UI.Col>
                <UI.Col lg={6}>
                    <UI.Input
                        label={t("screens.tickets.form.person.mobile").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={ticketStore.currentTicket?.canUpdateIncidentContactPerson ? false : true}
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.incidentContactPerson
                                ? ticketStore.currentTicket.incidentContactPerson?.mobile ?? ""
                                : ""
                        }
                        onChange={onChangeIncidentContactPersonField(INCIDENT_CONTACT_PERSON_PROPERTY.MOBILE)}
                        iconRight={<UI.Icon icon={UI.SVGIcon.Device} color="grey" size={"medium"} />}
                    />
                </UI.Col>
            </UI.Row>
            <UI.Row>
                <UI.Col lg={3}>
                    <UI.Input
                        label={t("screens.tickets.form.person.zip").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.incidentContactPerson
                                ? ticketStore.currentTicket.incidentContactPerson?.zip ?? ""
                                : ""
                        }
                    />
                </UI.Col>
                <UI.Col lg={3}>
                    <UI.Input
                        label={t("screens.tickets.form.person.city").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.incidentContactPerson
                                ? ticketStore.currentTicket.incidentContactPerson?.city ?? ""
                                : ""
                        }
                    />
                </UI.Col>
                <UI.Col lg={6}>
                    <UI.Input
                        label={t("screens.tickets.form.person.phone_private").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={ticketStore.currentTicket?.canUpdateIncidentContactPerson ? false : true}
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.incidentContactPerson
                                ? ticketStore.currentTicket.incidentContactPerson?.phoneprivate ?? ""
                                : ""
                        }
                        onChange={onChangeIncidentContactPersonField(INCIDENT_CONTACT_PERSON_PROPERTY.PHONEPRIVATE)}
                        iconRight={<UI.Icon icon={UI.SVGIcon.Device} color="grey" size={"medium"} />}
                    />
                </UI.Col>
            </UI.Row>
        </>
    );

    const currentContactPerson = ticketStore.currentTicket?.incidentContactPerson;

    const currentContactPersonName = `${currentContactPerson?.name1}${`${
        currentContactPerson?.name2 ? ` ${currentContactPerson?.name2}` : ""
    }`}`.trim();

    const viewPersonElementsDesktop = (
        <UI.Container fluid className="mt-3">
            <UI.Row>
                {/* First Column */}
                <UI.Col md={3}>
                    <UI.Row>
                        {currentContactPersonName.length > 0 ? (
                            <div className={`${styles.PersonName} mb-1`}>{currentContactPersonName}</div>
                        ) : undefined}
                    </UI.Row>
                    <UI.Row>
                        {currentContactPerson?.street ? (
                            <div className="mb-1">{currentContactPerson?.street}</div>
                        ) : undefined}
                    </UI.Row>
                    <UI.Row>
                        {currentContactPerson?.zip || currentContactPerson?.city ? (
                            <div>{`${currentContactPerson?.zip} ${currentContactPerson?.city}`.trim()}</div>
                        ) : undefined}
                    </UI.Row>
                </UI.Col>

                {/* Second Column, which grows as needed (E-Mail-Address) */}
                <UI.Col md="auto">
                    <UI.Row>
                        {currentContactPerson?.email ? (
                            <div className="mb-1">{currentContactPerson?.email}</div>
                        ) : undefined}
                    </UI.Row>
                    <UI.Row>
                        {currentContactPerson?.mobile ? (
                            <div className="mb-1">{currentContactPerson?.mobile}</div>
                        ) : undefined}
                    </UI.Row>
                    <UI.Row>
                        {currentContactPerson?.phoneprivate ? (
                            <div className="mb-1">{currentContactPerson?.phoneprivate}</div>
                        ) : undefined}
                    </UI.Row>
                </UI.Col>

                {/* Third Column */}
                <UI.Col>
                    <UI.Row>
                        {currentContactPerson?.email ? (
                            <div className={`${styles.Icon} mb-1`}>
                                <UI.Icon icon={UI.SVGIcon.Mail} color="grey" size={"medium"} />
                            </div>
                        ) : undefined}
                    </UI.Row>
                    <UI.Row>
                        {currentContactPerson?.mobile ? (
                            <div className={`${styles.Icon} mb-1`}>
                                <UI.Icon icon={UI.SVGIcon.Device} color="grey" size={"medium"} />
                            </div>
                        ) : undefined}
                    </UI.Row>
                    <UI.Row>
                        {currentContactPerson?.phoneprivate ? (
                            <div className={`${styles.Icon} ${styles.Phone}`}>
                                <UI.Icon icon={UI.SVGIcon.Phone} color="grey" size={25} />
                            </div>
                        ) : undefined}
                    </UI.Row>
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );

    const viewOnlyPersonElementsMobile = (
        <>
            <div className={`${styles.ViewOnlyPersonCol} ${styles.PersonName}`}>
                {currentContactPerson
                    ? `${currentContactPerson.name1}${`${
                          currentContactPerson.name2 ? ` ${currentContactPerson.name2}` : ""
                      }`}`.trim()
                    : ""}
            </div>
            <div>{currentContactPerson?.street}</div>
            <div className="mb-2">{`${currentContactPerson?.zip} ${currentContactPerson?.city}`.trim()}</div>

            <div>{currentContactPerson?.email}</div>
            <div>{currentContactPerson?.mobile}</div>
            <div>{currentContactPerson?.phoneprivate}</div>
        </>
    );

    const viewOnlyPersonElements = currentContactPerson ? (
        !uiStore.isMobile ? (
            viewPersonElementsDesktop
        ) : (
            viewOnlyPersonElementsMobile
        )
    ) : (
        <></>
    );

    return ticketStore.isEditing ? editModePersonElements : viewOnlyPersonElements;
};

export const PersonFormFields = Sentry.withProfiler(observer(PersonFormFieldsBase));
