import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Route, selectRoute } from "src/config/routes";
import { RootStoreContext } from "src/stores/RootStore";

export const NewTicketCard = () => {
    const { authStore } = useContext(RootStoreContext);
    return (
        <Link to={selectRoute(Route.newTicket, authStore.user?.role)}>
            <svg viewBox="0 0 293 138" fill="none" className="w-100">
                <rect
                    x="2"
                    y="2"
                    width="289"
                    height="134"
                    rx="9"
                    stroke="white"
                    strokeWidth="4"
                    strokeDasharray="8 8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M145.943 67.2606H139.258V74.2186H137.03V67.2606H130.346V64.9413H137.03V57.9833H139.258V64.9413H145.943V67.2606Z"
                    fill="white"
                />
            </svg>
        </Link>
    );
};
