import React, { useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { AssignInvoiceToWorkflowUser } from "src/screens/creditors/creditor/details/actions/assignInvoice/AssignInvoiceToWorkflowUser";
import { WORKFLOWUSER_ROLE } from "src/stores/krediflow/InvoiceEnums";

const InvoiceWorkflowCardBase = () => {
    const { t } = useTranslation();

    const { navStore } = useContext(RootStoreContext);

    const numberOfVisas =
        navStore.customer.settings && navStore.customer.settings.cred && navStore.customer.settings.cred.numberOfVisas
            ? navStore.customer.settings.cred.numberOfVisas
            : 0;

    return (
        <UI.Card title={t("screens.tickets.workflow.title").toString()} flex>
            {Array.from({ length: numberOfVisas }).map((_, index) => {
                let workflowUserRole = WORKFLOWUSER_ROLE.VISA_1;

                const visaNumber = index + 1;

                switch (visaNumber) {
                    case 1:
                        workflowUserRole = WORKFLOWUSER_ROLE.VISA_1;
                        break;
                    case 2:
                        workflowUserRole = WORKFLOWUSER_ROLE.VISA_2;
                        break;
                    case 3:
                        workflowUserRole = WORKFLOWUSER_ROLE.VISA_3;
                        break;
                }

                return (
                    <AssignInvoiceToWorkflowUser
                        key={workflowUserRole.toString()}
                        workflowUserRole={workflowUserRole}
                    />
                );
            })}
            <AssignInvoiceToWorkflowUser
                key={WORKFLOWUSER_ROLE.ACCOUNTANT.toString()}
                workflowUserRole={WORKFLOWUSER_ROLE.ACCOUNTANT}
            />
        </UI.Card>
    );
};

export const InvoiceWorkflowCard = Sentry.withProfiler(observer(InvoiceWorkflowCardBase));
