import React, { useContext, useEffect } from "react";
import { Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react";
import { Role } from "src/network/User";
import { PrivateRoute } from "src/components";
import { Route, SearchRoute, selectRoute } from "src/config/routes";
import * as Screens from "src/screens";
import { Search } from "src/screens/search";
import i18n from "src/utils/i18n";

const CustomerRoutesBase = () => {
    const { authStore, navStore } = useContext(RootStoreContext);
    const { user } = authStore;

    /* ADDED BECAUSE OF BUG #6106 */
    /* TODO: REMOVE ONCE PORTAL IS TRANSLATED */
    useEffect(() => {
        i18n.changeLanguage("de");
    }, []);

    const ownerRoutes: React.ReactNode[] = [
        <PrivateRoute
            key={Route.owner + Route.realestatesOwner}
            path={Route.owner + Route.realestatesOwner}
            exact
            component={Screens.Realestates}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestates}
            path={Route.owner + Route.realestates}
            exact
            component={Screens.Realestates}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestate}
            path={Route.owner + Route.realestate}
            component={Screens.Realestate}
        />,
        <PrivateRoute key={Route.owner} path={Route.owner} exact>
            <Redirect to={Route.owner + Route.realestates} />
        </PrivateRoute>
    ];

    const coownerRoutes: React.ReactNode[] = [
        <PrivateRoute
            key={Route.coowner + Route.realestatesOwner}
            path={Route.coowner + Route.realestatesOwner}
            exact
            component={Screens.Realestates}
        />,
        <PrivateRoute
            key={Route.coowner + Route.realestates}
            path={Route.coowner + Route.realestates}
            exact
            component={Screens.Realestates}
        />,
        <PrivateRoute
            key={Route.coowner + Route.realestate}
            path={Route.coowner + Route.realestate}
            component={Screens.Realestate}
        />,
        <PrivateRoute key={Route.coowner} path={Route.coowner} exact>
            <Redirect to={Route.coowner + Route.realestates} />
        </PrivateRoute>
    ];

    const tenantRoutes: React.ReactNode[] = [
        <PrivateRoute
            key={Route.tenant + Route.realestatesOwner}
            path={Route.tenant + Route.realestatesOwner}
            exact
            component={Screens.Realestates}
        />,
        <PrivateRoute
            key={Route.tenant + Route.realestates}
            path={Route.tenant + Route.realestates}
            exact
            component={Screens.Realestates}
        />,
        <PrivateRoute
            key={Route.tenant + Route.realestate}
            path={Route.tenant + Route.realestate}
            component={Screens.Realestate}
        />,
        <PrivateRoute key={Route.tenant} path={Route.tenant} exact>
            <Redirect to={Route.tenant + Route.realestates} />
        </PrivateRoute>
    ];

    const auditorRoutes: React.ReactNode[] = [
        <PrivateRoute
            key={Route.auditor + Route.realestatesOwner}
            path={Route.auditor + Route.realestatesOwner}
            exact
            component={Screens.Realestates}
        />,
        <PrivateRoute
            key={Route.auditor + Route.realestates}
            path={Route.auditor + Route.realestates}
            exact
            component={Screens.Realestates}
        />,
        <PrivateRoute
            key={Route.auditor + Route.realestate}
            path={Route.auditor + Route.realestate}
            component={Screens.Realestate}
        />,
        <PrivateRoute key={Route.auditor} path={Route.auditor} exact>
            <Redirect to={Route.auditor + Route.realestates} />
        </PrivateRoute>
    ];

    const managerRoutes: React.ReactNode[] = [
        <PrivateRoute
            key={Route.manager + Route.managerDashboard}
            path={Route.manager + Route.managerDashboard}
            exact
            component={Screens.ManagerDashboard}
        />,
        <PrivateRoute
            key={Route.manager + Route.owners}
            path={Route.manager + Route.owners}
            exact
            component={Screens.Owners}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestates}
            path={Route.manager + Route.realestates}
            exact
            component={Screens.Realestates}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestatesOwner}
            path={Route.manager + Route.realestatesOwner}
            exact
            component={Screens.Realestates}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestate}
            path={Route.manager + Route.realestate}
            component={Screens.Realestate}
        />,
        <PrivateRoute
            key={Route.manager + Route.services}
            path={Route.manager + Route.services}
            component={Screens.Services}
        />,
        <PrivateRoute key={Route.theming} path={Route.theming} exact component={Screens.Theming} />,
        <PrivateRoute key={Route.manager} path={Route.manager} exact>
            <Redirect to={Route.manager + Route.managerDashboard} />
        </PrivateRoute>
    ];

    if (navStore.hasFeatureTicketing) {
        managerRoutes.unshift(
            <PrivateRoute
                key={Route.manager + Route.tickets}
                path={Route.manager + Route.tickets}
                exact
                component={Screens.Tickets}
            />
        );
        managerRoutes.unshift(
            <PrivateRoute
                key={Route.manager + Route.ticket}
                path={Route.manager + Route.ticket}
                component={Screens.Ticket}
            />
        );
        managerRoutes.unshift(
            <PrivateRoute
                key={Route.manager + Route.ticketOrder}
                path={Route.manager + Route.ticketOrder}
                component={Screens.Order}
            />
        );
    }

    if (navStore.hasFeatureKrediFlow) {
        managerRoutes.unshift(
            <PrivateRoute
                key={Route.manager + Route.creditors}
                path={Route.manager + Route.creditors}
                exact
                component={Screens.Creditors}
            />
        );

        managerRoutes.unshift(
            <PrivateRoute
                key={Route.manager + Route.creditor}
                path={Route.manager + Route.creditor}
                component={Screens.Creditor}
            />
        );
    }

    const ecAdminRoutes: React.ReactNode[] = [
        <PrivateRoute
            key={Route.manager + Route.managerDashboard}
            path={Route.manager + Route.managerDashboard}
            exact
            component={Screens.ManagerDashboard}
        />,
        <PrivateRoute key={Route.theming} path={Route.theming} exact component={Screens.Theming} />,
        <PrivateRoute path="/" key="error-not-found" component={Screens.ErrorNotFound} />
    ];

    const fallbackRoute = <PrivateRoute path="/" key="error-not-found" component={Screens.ErrorNotFound} />;

    const hasOnlyInspectionUserRoles = user?.hasInspectionRole && !user.hasPortalRole;
    const entryRoute = hasOnlyInspectionUserRoles
        ? Route.root
        : user?.role === Role.MANAGER
        ? Route.managerDashboard
        : user?.role === Role.OWNER ||
          user?.role === Role.COOWNER ||
          user?.role === Role.AUDITOR ||
          user?.role === Role.TENANT
        ? Route.realestates
        : Route.owner;

    const hasOnlyECAdminRole = user?.hasECAdminRole && !user.hasPortalRole;

    const visibleRoutes: React.ReactNode[] = [];

    authStore.user?.availablePortalRoles.forEach((availableRole) => {
        switch (availableRole) {
            case Role.MANAGER:
                visibleRoutes.push(...managerRoutes);
                break;
            case Role.OWNER:
                visibleRoutes.push(...ownerRoutes);
                break;
            case Role.COOWNER:
                visibleRoutes.push(...coownerRoutes);
                break;
            case Role.AUDITOR:
                visibleRoutes.push(...auditorRoutes);
                break;
            case Role.TENANT:
                visibleRoutes.push(...tenantRoutes);
                break;
        }
    });

    visibleRoutes.push(fallbackRoute);

    return (
        <Switch>
            <PrivateRoute path={Route.login} exact>
                <Redirect
                    to={
                        hasOnlyECAdminRole
                            ? Route.manager + Route.managerDashboard
                            : selectRoute(entryRoute, user?.role)
                    }
                />
            </PrivateRoute>
            <PrivateRoute path={"/"} exact>
                <Redirect
                    to={
                        hasOnlyECAdminRole
                            ? Route.manager + Route.managerDashboard
                            : selectRoute(entryRoute, user?.role)
                    }
                />
            </PrivateRoute>
            <PrivateRoute path={Route.profile} exact component={Screens.Profile} />
            <PrivateRoute path={SearchRoute.person} exact component={Search.Person} />
            <PrivateRoute path={Route.passwordchange} exact component={Screens.PasswordChange} />
            <PrivateRoute path={Route.customerusers} exact component={Screens.CustomerUsers} />
            <PrivateRoute path={Route.easycontactsettings} component={Screens.EasyContactSettings} />
            <PrivateRoute path={Route.ticketingsettings} component={Screens.TicketingSettings} />
            <PrivateRoute path={Route.tileAdministration} component={Screens.TileAdministration} />
            <PrivateRoute path={Route.inspectionapphint} component={Screens.InspectionAppHint} />
            <PrivateRoute path={Route.agendaTemplate} component={Screens.AgendaTemplate} />
            <PrivateRoute path={Route.customerUserdetails} component={Screens.CustomerUserDetails} />
            <PrivateRoute path={Route.portalDemo} component={Screens.PortalDemoSettings} />
            <PrivateRoute path={Route.userRatingsPortal} component={Screens.UserRatingsPortalSetting} />

            {hasOnlyECAdminRole ? ecAdminRoutes : undefined}
            {visibleRoutes}

            <PrivateRoute render={Screens.ErrorNotFound} />
        </Switch>
    );
};
export const CustomerRoutes = observer(CustomerRoutesBase);
