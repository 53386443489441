import { de, fr, enGB, it } from "date-fns/locale";
import { format } from "date-fns";
import { getLang } from "./i18n";
export const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

export const dateFormat = (date: any, formatStr = "PP") => {
    let locale: Locale = de;
    if (getLang().indexOf("de") !== -1) locale = de;
    if (getLang().indexOf("en") !== -1) locale = enGB;
    if (getLang().indexOf("fr") !== -1) locale = fr;
    if (getLang().indexOf("it") !== -1) locale = it;
    return format(date, formatStr, { locale });
};

export const getDate = (timestamp: string) => {
    if (timestamp) {
        return dateFormat(new Date(timestamp), "dd.MM.yyyy");
    }
};

export const getDateAndTime = (timestamp: string) => {
    if (timestamp) {
        return dateFormat(new Date(timestamp), "dd.MM.yyyy HH:mm");
    }
};

export const getDateWithLongMonthAndTime = (timestamp: string) => {
    if (timestamp) {
        return dateFormat(new Date(timestamp), "dd MMMM yyyy - HH:mm");
    }
};

export const getDateWithLongMonth = (timestamp: string) => {
    if (timestamp) {
        return dateFormat(new Date(timestamp), "dd. MMMM yyyy");
    }
};

export const getDateWithMonthAndYear = (timestamp: string) => {
    if (timestamp) {
        return dateFormat(new Date(timestamp), "MMMM yyyy");
    }
};

export const getDateForHasuraQuery = (timestamp: string) => {
    if (timestamp) {
        return dateFormat(new Date(timestamp), "yyyy-MM-dd");
    }
};

export const getTime = (timestamp: string) => {
    if (timestamp) {
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();

        if (minutes === 0) {
            return hours + ":00";
        } else if (hours === 0) {
            return "00:" + minutes;
        } else return hours + ":" + minutes;
    }
};
