import { observable, makeObservable, action, computed } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { LOADING_TYPE, TICKET_PROPERTY, IAssignedUser, TICKET_STATE } from "src/stores/TicketStore";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { GET_USERS_TO_ASSIGN_TICKET } from "src/api/ticket";
import { GetUsersToAssignTicket } from "src/api/generated/GetUsersToAssignTicket";
import i18next from "i18next";

export enum ASSIGN_TICKET_STATE {
    INIT,
    ASSIGNING,
    FINISHED,
    SUCCESS,
    ERROR
}

export class AssignTicketModalStore {
    rootStore: RootStore;

    isModalDisplayed = false;

    assignTicketState: ASSIGN_TICKET_STATE = ASSIGN_TICKET_STATE.INIT;

    assignUserData: IAssignedUser[] = [];
    currentAssignUserSearchQuery: string = "";
    selectedAssignUser?: IAssignedUser = undefined;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            assignTicketState: observable,
            isModalDisplayed: observable,
            assignUserData: observable,
            currentAssignUserSearchQuery: observable,
            selectedAssignUser: observable,

            init: action,
            resetAssignTicketModal: action,
            setIsModalDisplayed: action,
            setAssignUserData: action,
            setCurrentAssignUserSearchQuery: action,
            setSelectedAssignUser: action,
            assignTicketToUser: action,

            filteredAssignUserData: computed
        });
    }

    init = async () => {
        await this.loadAssignUserData();
    };

    resetAssignTicketModal = () => {
        this.assignTicketState = ASSIGN_TICKET_STATE.INIT;
        this.currentAssignUserSearchQuery = "";
        this.selectedAssignUser = undefined;
    };

    loadAssignUserData = async () => {
        this.rootStore.ticketStore.setLoadingType(LOADING_TYPE.TICKET);

        const { data }: { data: GetUsersToAssignTicket } = await apolloClientInstance.query<GetUsersToAssignTicket>({
            query: GET_USERS_TO_ASSIGN_TICKET
        });

        const usersToAssignTicket: IAssignedUser[] = data.ums_v_customer_users.map((user) => {
            const assignPerson: IAssignedUser = {
                id: user.userid,
                name1: user.name ?? ""
            };

            return assignPerson;
        });

        this.setAssignUserData(usersToAssignTicket);

        this.rootStore.ticketStore.setLoadingType(LOADING_TYPE.NONE);
    };

    get filteredAssignUserData(): IAssignedUser[] {
        const searchQuery = this.currentAssignUserSearchQuery ?? "";

        let filteredAssignUserData: IAssignedUser[] = [...this.assignUserData];

        filteredAssignUserData = filteredAssignUserData.filter((assignUser) => {
            return assignUser.id !== this.rootStore.ticketStore.currentTicket?.assignedUser?.id;
        });

        const filteredApplianceDataBySearchQuery = filteredAssignUserData.filter((assignUser) => {
            return (
                assignUser.name1?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                assignUser.name2?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        });

        if (searchQuery === "") {
            return filteredAssignUserData;
        } else {
            return filteredApplianceDataBySearchQuery;
        }
    }

    assignTicketToUser = async (userIdToAssign: string, loadingType: LOADING_TYPE): Promise<boolean> => {
        this.rootStore.ticketStore.setLoadingType(loadingType);
        this.assignTicketState = ASSIGN_TICKET_STATE.ASSIGNING;

        const currentlyAssignedUser = this.rootStore.ticketStore.currentTicket?.assignedUser
            ? { ...this.rootStore.ticketStore.currentTicket?.assignedUser }
            : undefined;
        const ticketAssignUserNeedsToBeConcluded = !currentlyAssignedUser;

        let wasTicketSuccessfullyAssigned = await this.rootStore.ticketStore.currentTicket?.assignToUser(
            userIdToAssign
        );

        let wasTicketAssignUserSuccessfullyConcluded = false;

        if (wasTicketSuccessfullyAssigned && ticketAssignUserNeedsToBeConcluded) {
            wasTicketAssignUserSuccessfullyConcluded =
                (await this.rootStore.ticketStore.currentTicket?.concludeUserAssigend()) ?? false;
        }

        wasTicketSuccessfullyAssigned = ticketAssignUserNeedsToBeConcluded
            ? wasTicketSuccessfullyAssigned && wasTicketAssignUserSuccessfullyConcluded
            : wasTicketSuccessfullyAssigned;

        if (wasTicketSuccessfullyAssigned) {
            this.assignTicketState =
                loadingType === LOADING_TYPE.TAKING_OVER_TICKET
                    ? ASSIGN_TICKET_STATE.INIT
                    : ASSIGN_TICKET_STATE.FINISHED;
            const userToAssign = this.assignUserData.find((user) => user.id === userIdToAssign);
            this.rootStore.ticketStore.currentTicket?.updateProperty(TICKET_PROPERTY.ASSIGNED_USER, userToAssign);
            this.rootStore.ticketStore.currentTicket?.updateProperty(TICKET_PROPERTY.STATE, TICKET_STATE.ASSIGNED);

            await this.rootStore.ticketStore.currentTicket?.save();
        } else {
            this.assignTicketState = ASSIGN_TICKET_STATE.INIT;

            this.rootStore.ticketStore.currentTicket?.updateProperty(
                TICKET_PROPERTY.ASSIGNED_USER,
                currentlyAssignedUser
            );
            await this.rootStore.ticketStore.currentTicket?.save();

            this.rootStore.uiStore.printTicketingErrorMessage(
                i18next.t("screens.tickets.action.assign_ticket.failure")
            );
        }

        this.rootStore.ticketStore.setLoadingType(LOADING_TYPE.NONE);

        return wasTicketSuccessfullyAssigned ?? false;
    };

    /* SETTERS */
    setIsModalDisplayed = (isModalDisplayed: boolean) => {
        this.isModalDisplayed = isModalDisplayed;
    };

    setAssignUserData = (assignUserData: IAssignedUser[]) => {
        this.assignUserData = assignUserData;
    };

    setCurrentAssignUserSearchQuery = (currentAssignUserSearchQuery: string) => {
        this.currentAssignUserSearchQuery = currentAssignUserSearchQuery;
    };

    setSelectedAssignUser = (selectedAssignUser: IAssignedUser) => {
        this.selectedAssignUser = selectedAssignUser;
    };

    setAssignTicketState = (assignTicketState: ASSIGN_TICKET_STATE) => {
        this.assignTicketState = assignTicketState;
    };
}
