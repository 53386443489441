import { useTranslation } from "react-i18next";
import { UI } from "@wwimmo/ui";
import { GET_AGENDAITEMS_BY_ASSEMBLYID } from "src/api/agenda";
import {
    GetAgendaitemsByAssemblyId,
    GetAgendaitemsByAssemblyIdVariables
} from "src/api/generated/GetAgendaitemsByAssemblyId";
import { useQuery } from "@apollo/client";
import { observer } from "mobx-react-lite";
import * as Screens from "src/screens";
import { getLang } from "src/utils/i18n";
import styles from "./AssembliesAgendaItems.module.css";
import { NetworkConfig } from "src/network/NetworkConfig";
import { ColorStyle } from "src/utils/Colors";
import { v4 as uuidv4 } from "uuid";

interface AssembliesAgendaProps {
    assemblyid: string;
}

const AssemblyAgendaItems = (props: AssembliesAgendaProps) => {
    const { t } = useTranslation();

    const { data, loading, error } = useQuery<GetAgendaitemsByAssemblyId, GetAgendaitemsByAssemblyIdVariables>(
        GET_AGENDAITEMS_BY_ASSEMBLYID,
        {
            variables: {
                language: getLang(),
                assemblyid: props.assemblyid
            }
        }
    );

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const getText = (array: any) => {
        return array && array.length > 0 ? array[0].text : "";
    };

    const getDescription = (array: any) => {
        const description = array && array.length > 0 && array[0].description ? array[0].description : "";
        const descriptionWithLineBreaks = description.replace(/\n/g, "<br>");
        return <span dangerouslySetInnerHTML={{ __html: descriptionWithLineBreaks }} />;
    };

    return (
        <>
            {data?.agendaitem_parents.length > 0 ? (
                <ul className={styles.AgendaList}>
                    {data?.agendaitem_parents.map((parentitem) => (
                        <li key={parentitem.sort + parentitem.id}>
                            <div className={styles.ListItem}>
                                <div
                                    className={parentitem.agendaitem_children.length > 0 ? styles.AgendaItemParent : ""}
                                >
                                    <div className={styles.AgendaItemText}>{`${parentitem.sort}. ${getText(
                                        parentitem.agendaitems_mls
                                    )}`}</div>
                                    <div className={styles.AgendaItemsDescription}>
                                        {getDescription(parentitem.agendaitems_mls)}
                                    </div>
                                    {parentitem.agendaitemfiles.length > 0 ? (
                                        <div>
                                            <div className={styles.Document}>
                                                {parentitem.agendaitemfiles.map((file) => (
                                                    <a
                                                        key={file.fileid + file.id}
                                                        href={NetworkConfig.datafileUrl + file.fileid}
                                                        className="link-underline"
                                                        rel="noopener noreferrer d-flex"
                                                        target="_blank"
                                                    >
                                                        {file.file?.name}
                                                        <UI.Icon
                                                            icon={UI.SVGIcon.Documents}
                                                            color={ColorStyle("primary")}
                                                            size="small"
                                                            className={styles.DocumentIcon}
                                                        />
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    ) : undefined}
                                </div>
                                {parentitem.agendaitem_children.length > 0 ? (
                                    <>
                                        {parentitem.agendaitem_children.map((childernItem) => (
                                            <div key={uuidv4()}>
                                                <div
                                                    style={{ marginLeft: 15 }}
                                                    className={styles.AgendaItemChildText}
                                                    key={childernItem.sort + childernItem.id}
                                                >{`${parentitem.sort}.${childernItem.sort} ${getText(
                                                    childernItem.agendaitems_mls
                                                )}`}</div>
                                                <div
                                                    className={
                                                        childernItem.agendaitemfiles.length > 0
                                                            ? styles.AgendaItemsChildDescriptionWithFiles
                                                            : styles.AgendaItemsChildDescription
                                                    }
                                                >
                                                    {getDescription(childernItem.agendaitems_mls)}
                                                </div>
                                                {childernItem.agendaitemfiles.length > 0 ? (
                                                    <>
                                                        <div className={styles.ChildDocument}>
                                                            {childernItem.agendaitemfiles.map((childernFile) => (
                                                                <a
                                                                    key={childernFile.fileid + childernFile.id}
                                                                    href={
                                                                        NetworkConfig.datafileUrl + childernFile.fileid
                                                                    }
                                                                    className={`${styles.ChildDocumentLink} link-underline`}
                                                                    rel="noopener noreferrer d-flex"
                                                                    target="_blank"
                                                                >
                                                                    {childernFile.file?.name}
                                                                    <UI.Icon
                                                                        icon={UI.SVGIcon.Documents}
                                                                        color={ColorStyle("primary")}
                                                                        size="small"
                                                                        className={styles.DocumentIcon}
                                                                    />
                                                                </a>
                                                            ))}
                                                        </div>
                                                    </>
                                                ) : undefined}
                                            </div>
                                        ))}
                                    </>
                                ) : undefined}
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                <div className={styles.NoAgenda}>{t("screens.realestate.assembly.no_agenda")}</div>
            )}
        </>
    );
};

export default observer(AssemblyAgendaItems);
