import React, { useCallback, useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import { GET_STWEG_DEMO_DATA_BY_SUBDOMAIN } from "src/api/demoPortal";
import {
    GetStwegDemoDataBySubdomain,
    GetStwegDemoDataBySubdomainVariables,
    GetStwegDemoDataBySubdomain_ums_v_demoportalsettings as vDemoPortalSettings
} from "src/api/generated/GetStwegDemoDataBySubdomain";
import { useQuery } from "@apollo/client";
import * as Screens from "src/screens";
import { getLang } from "src/utils/i18n";
import imageOnboarding1 from "src/assets/images/demo-stewg-portal/Onboarding_1.png";
import imageOnboarding2 from "src/assets/images/demo-stewg-portal/Onboarding_2.png";
import imageOnboarding3 from "src/assets/images/demo-stewg-portal/Onboarding_3.png";
import imageOnboarding4 from "src/assets/images/demo-stewg-portal/Onboarding_4.png";
import Carousel from "react-bootstrap/Carousel";
import { ColorStyle } from "src/utils/Colors";
import { ContactItem, ContactItemRow } from "src/components/contact/contactitem/ContactItem";
import { useHistory } from "react-router-dom";
import styles from "./StwegDemoPage.module.css";
import { Route } from "src/config/routes";

export enum ContactItemType {
    PHONE,
    MAIL,
    DEVICE
}

const StwegDemoPageBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore } = useContext(RootStoreContext);

    const history = useHistory();

    const host = window.location.host;
    const isLocal = host.indexOf("localhost") === 0;
    const subdomain = isLocal ? "dev" : window.location.host.split(".")[0];
    const initialLanguage = getLang();

    const { data, loading, error, refetch } = useQuery<
        GetStwegDemoDataBySubdomain,
        GetStwegDemoDataBySubdomainVariables
    >(GET_STWEG_DEMO_DATA_BY_SUBDOMAIN, {
        variables: {
            subdomain: subdomain,
            language: initialLanguage
        }
    });

    useEffect(() => {
        if (
            data &&
            data.ums_v_demoportalsettings &&
            data.ums_v_demoportalsettings.length === 0 &&
            initialLanguage !== "de"
        ) {
            refetch({ subdomain, language: "de" });
        }
    }, [data, initialLanguage, refetch, subdomain]);

    useEffect(() => {
        navStore.setTitle(t("screens.portalDemo.title_stweg_demo_page"));
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);
        navStore.setBackbutton(null);
    }, [navStore, t]);

    const onClickRegister = useCallback(() => {
        history.push(Route.register);
    }, [history]);

    const onClickGetLogin = useCallback(() => {
        window.open(
            `https://${subdomain}.wwportal.ch/ec/process/5030e670-5630-3acc-2857-eb9790920da0/5/5030e670-5630-3acc-2857-eb9790920da1?loginrequest=true`,
            "_blank"
        );
    }, [subdomain]);

    const onClickOnlineContact = useCallback(() => {
        window.open(`https://${subdomain}.wwportal.ch/ec/start`, "_blank");
    }, [subdomain]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    let demoPortalSettings: vDemoPortalSettings;
    if (data && data.ums_v_demoportalsettings && data.ums_v_demoportalsettings[0]) {
        demoPortalSettings = data.ums_v_demoportalsettings[0];
    } else {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const carouselItem = (src: string, alt: string) => (
        <Carousel.Item className="slide">
            <div className={styles.imageWithIcons}>
                <div className={styles.leftIconContainer}>
                    <UI.Icon icon={UI.SVGIcon.ChevronLeft} color={ColorStyle("primary")} />
                </div>
                <img className="d-block w-100" src={src} alt={alt} />
                <div className={styles.rightIconContainer}>
                    <UI.Icon icon={UI.SVGIcon.ChevronRight} color={ColorStyle("primary")} />
                </div>
            </div>
        </Carousel.Item>
    );

    const content: Array<ContactItemRow> = [
        { type: ContactItemType.PHONE, content: demoPortalSettings.phone },
        { type: ContactItemType.DEVICE, content: demoPortalSettings.mobile },
        { type: ContactItemType.MAIL, content: demoPortalSettings.email }
    ];

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Card className={styles.card}>
                    <div className={styles.welcomeText}>
                        {t("screens.portalDemo.welcome")} {demoPortalSettings.name}
                    </div>
                    <div>{demoPortalSettings.text1}</div>
                </UI.Card>
                <UI.Card className={styles.card}>
                    <section className="slider container mb-3">
                        <Carousel className="custom-carousel" fade>
                            {carouselItem(imageOnboarding1, "First slide")}
                            {carouselItem(imageOnboarding2, "Second slide")}
                            {carouselItem(imageOnboarding3, "Third slide")}
                            {carouselItem(imageOnboarding4, "Fourth slide")}
                        </Carousel>
                    </section>
                </UI.Card>
                <UI.Card className={styles.text2}>
                    <div>{demoPortalSettings.text2}</div>
                </UI.Card>
                <UI.Row className={styles.bottomRow}>
                    <UI.Col className="colRegister" xl={4} md={5} sm={6}>
                        <UI.Card className="login" title={t("screens.portalDemo.login")!}>
                            <UI.Button
                                className={styles.registerButton}
                                label={t("screens.portalDemo.register")!}
                                onClick={onClickRegister}
                            />
                            <UI.Button label={t("screens.portalDemo.login_request")!} onClick={onClickGetLogin} />
                        </UI.Card>
                    </UI.Col>
                    <UI.Col xl={4} md={5} sm={6}>
                        <UI.Card className={styles.card} title="Kontakt">
                            <UI.Button
                                className={styles.onlineContactButton}
                                label={t("screens.portalDemo.online_contact")!}
                                onClick={onClickOnlineContact}
                            />
                            <div className={styles.contactPerson}>{t("screens.portalDemo.contactperson")}</div>
                            <ContactItem name={demoPortalSettings.contactperson!} contacts={content} />
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const StwegDemoPage = withRouter(Sentry.withProfiler(observer(StwegDemoPageBase)));
