import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { submitUserFeedback } from "src/utils/Feedback";
import { RootStoreContext } from "src/stores/RootStore";

export interface ErrorProps {
    message?: string;
    networkError?: Error & any;
    componentStack?: string | null;
    resetError?: () => void;
    error?: Error | null;
}

const ErrorBase = (props: ErrorProps) => {
    const { navStore } = useContext(RootStoreContext);

    const handleSubmitFeedback = useCallback(() => {
        submitUserFeedback(navStore.customer);
    }, [navStore.customer]);

    return (
        <UI.Container className="pt-4">
            <UI.Row>
                <UI.Col className="text-center mt-4 pt-4">
                    <UI.ErrorCard
                        onSubmitFeedback={handleSubmitFeedback}
                        {...props}
                        showBackButton={navStore.historyStack.length > 0}
                    />
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};

export const Error = Sentry.withProfiler(ErrorBase);
