import React, { useCallback, useContext, useEffect, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { UI } from "@wwimmo/ui";
import { SCREEN_SIZE } from "src/stores/UIStore";
import styles from "./Creditors.module.css";
import { InvoiceList } from "./list/InvoiceList";
import { INVOICE_LIST_STATE, INVOICE_TYPE, SORT } from "src/stores/krediflow/InvoiceEnums";
import { TagSearch } from "src/components/tagSearch/TagSearch";
import { Route, selectRoute } from "src/config/routes";

export const getInvoiceTypeIcon = (type?: INVOICE_TYPE) => {
    switch (type) {
        case INVOICE_TYPE.BILL:
            return "/icons/cred/credit-type.svg";
        case INVOICE_TYPE.INVOICE:
            return "/icons/cred/invoice-type.svg";
        default:
            return "/icons/cred/credit-type.svg";
    }
};

const CreditorsBase = () => {
    const { t } = useTranslation();
    const { uiStore, navStore, invoiceStore, invoiceListStore, authStore } = useContext(RootStoreContext);
    const { setFilter, invoiceFilter } = invoiceListStore;

    useEffect(() => {
        navStore.setTitle("");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);
        navStore.setBackbutton(null);
    }, [navStore]);

    useEffect(() => {
        invoiceStore.initialLoadAllInvoices();

        // Load all invoices once component is rendered
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        invoiceStore.startLoadAllInvoicesTimer();
        return () => {
            invoiceStore.clearLoadAllInvoicesTimer();
        };
    }, [invoiceStore]);

    const onFilterChange = useCallback(
        (invoiceListState: INVOICE_LIST_STATE) => {
            setFilter({ ...invoiceFilter, state: invoiceListState });
        },
        [invoiceFilter, setFilter]
    );

    const onUpdateSearchTag = useCallback(
        (searchTagId: string, status: boolean) => {
            invoiceListStore.setTag(searchTagId, status);
        },
        [invoiceListStore]
    );

    const onChangeSearchQuery = useCallback(
        (query: string) => {
            setFilter({ ...invoiceFilter, query });
        },
        [invoiceFilter, setFilter]
    );

    const onResetSearch = useCallback(() => {
        invoiceListStore.clearFilter();
    }, [invoiceListStore]);

    const onSortChange = useCallback(
        (newSort: SORT) => {
            setFilter({ ...invoiceFilter, sort: newSort });
        },
        [invoiceFilter, setFilter]
    );

    const sortItems = useMemo(
        () => [
            { label: t("screens.tickets.sort.dateNewest"), value: SORT.DATE_NEWEST },
            { label: t("screens.tickets.sort.dateOldest"), value: SORT.DATE_OLDEST }
        ],
        [t]
    );

    return (
        <UI.Container
            className={`pt-5 ${styles.CreditorsContainer} ${
                uiStore.currentScreenSize <= SCREEN_SIZE.LAPTOP ? styles.MinPadding : ""
            }`}
            fluid
        >
            <UI.Row className="mb-2">
                <UI.Col xs={12} sm={8} lg={9}>
                    <UI.Title>{t("screens.kredi_flow.sidebar_title")}</UI.Title>
                </UI.Col>
                <UI.Col xs={12} sm={4} lg={3}>
                    <UI.Button
                        to={selectRoute(Route.newInvoice, authStore.user?.role)}
                        label={t("screens.kredi_flow.list.new_invoice_button")}
                        className={styles.AddButton}
                    />
                </UI.Col>
            </UI.Row>
            <UI.Row>
                <UI.Col xs={12}>
                    <div className={`${styles.Toolbar} mb-3`}>
                        <div className={styles.ToolbarTagSearch}>
                            <TagSearch
                                searchTags={invoiceListStore.searchTags}
                                value={invoiceFilter.query}
                                updateTagStatusById={onUpdateSearchTag}
                                onChangeInput={onChangeSearchQuery}
                                reset={onResetSearch}
                            />
                        </div>

                        <div className={styles.ToolbarSort}>
                            <UI.Dropdown
                                id="sort"
                                onSelect={onSortChange}
                                items={sortItems}
                                label={uiStore.isMobile ? "" : t(`screens.tickets.sort.${invoiceFilter.sort}`)}
                                icon={uiStore.isMobile && <UI.Icon icon={UI.SVGIcon.Sort} />}
                            />
                        </div>
                    </div>
                </UI.Col>
            </UI.Row>
            <UI.Row>
                <UI.Col xs={12}>
                    <InvoiceList onFilterChange={onFilterChange} />
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};

export const Creditors = Sentry.withProfiler(observer(CreditorsBase));
