import { useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { GET_TENANT_BOOKINGS_BY_USER, GET_TENANT_BOOKINGS_BY_USER_IT2 } from "src/api/bookings";
import { RouteComponentProps } from "react-router";
import { GetTenantBookingsByUser } from "src/api/generated/GetTenantBookingsByUser";
import * as Screens from "src/screens";
import { ScreenType } from "src/api/tiles";
import { ScreenWithTiles } from "src/components/screenWithTiles/ScreenWithTiles";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { Route, selectRoute } from "src/config/routes";
import MyAccountsList from "./list/MyAccountsList";
import { ErpType, Role } from "src/network/User";

interface MatchParams {
    realestateid: string;
    ownerId: string;
}

export const MyAccountsBase = (props: RouteComponentProps<MatchParams>) => {
    const { t } = useTranslation();
    const { navStore, authStore } = useContext(RootStoreContext);
    const realestateid = props.match.params.realestateid;
    const { data, loading, error } = useQuery<GetTenantBookingsByUser>(
        (authStore.user?.hasCoownerRole ||
            authStore.user?.availablePortalRoles.find((x) => x === Role.AUDITOR) !== undefined) &&
            authStore.user.erpType === ErpType.IT2
            ? GET_TENANT_BOOKINGS_BY_USER_IT2
            : GET_TENANT_BOOKINGS_BY_USER,
        {
            variables: {
                realestateid: realestateid
            }
        }
    );

    useEffect(() => {
        if (navStore.currentOwnerId) {
            if (navStore.singleRealestate) {
                navStore.setBackbutton(selectRoute(Route.owners, authStore.user?.role), t("screens.owners.title"));
            } else {
                navStore.setBackbutton(
                    selectRoute(Route.realestatesOwner, authStore.user?.role, { ownerid: navStore.currentOwnerId }),
                    t("screens.realestates.title")
                );
            }
        } else {
            navStore.setBackbutton(
                selectRoute(Route.realestates, authStore.user?.role),
                t("screens.realestates.title")
            );
        }
    });

    if (loading) {
        return <Screens.Loading />;
    }

    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }

    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    return (
        <ScreenWithTiles
            title={t("screens.realestate.my_account.title")}
            screenId={ScreenType.ACCOUNT}
            realestateid={realestateid}
        >
            <UI.Card>
                <MyAccountsList tenants={data} />
            </UI.Card>
        </ScreenWithTiles>
    );
};

export const MyAccounts = Sentry.withProfiler(observer(MyAccountsBase));
