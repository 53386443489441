import { useQuery } from "@apollo/client";
import { NewsEventState, NewsEventStateVariables } from "src/api/generated/NewsEventState";
import { GET_NEWS_NOTIFICATION_STATE_BY_ASSEMBLYID } from "src/api/news";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { INotificationStatus } from "src/stores/NotificationStore";
import { RootStoreContext } from "src/stores/RootStore";

type GetDataAssemblyNotificationResult =
    | { loading: true }
    | { error: string; networkError?: any }
    | INotificationStatus[]
    | [];

export const GetDataNewsNotification = (): GetDataAssemblyNotificationResult => {
    const { notificationStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const { data, loading, error } = useQuery<NewsEventState, NewsEventStateVariables>(
        GET_NEWS_NOTIFICATION_STATE_BY_ASSEMBLYID,
        {
            variables: {
                newsid: notificationStore.notificationId
            }
        }
    );

    if (loading) {
        return { loading: true };
    }

    if (error) {
        return { error: error.message, networkError: error.networkError };
    }

    if (!data) {
        return { error: t("error.nodata").toString() };
    }

    if (data && data.NewsNotificationState && data.NewsNotificationState.length > 0) {
        const notificationStates: INotificationStatus[] = data.NewsNotificationState.map((item) => ({
            name: item.name || "",
            email: item.email || "",
            date: item.datetime || "",
            state: item.state || 0
        }));
        return notificationStates;
    } else {
        return [];
    }
};

export default GetDataNewsNotification;
