import { observable, action, makeObservable, computed } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { GET_TENANCY_PERSONS_BY_REALESTATEID, GET_TENANCY_PERSONS_WITH_QUERY } from "src/api/ticket";
import {
    GetTenancyPersonsWithQuery,
    GetTenancyPersonsWithQueryVariables
} from "src/api/generated/GetTenancyPersonsWithQuery";
import {
    GetTenancyPersonsByRealestateId,
    GetTenancyPersonsByRealestateIdVariables,
    GetTenancyPersonsByRealestateId_tenancy_persons
} from "src/api/generated/GetTenancyPersonsByRealestateId";
import { apolloClientInstance } from "src/network/apolloClientInstance";

export interface IIncidentContactPersonData {
    customerid?: any | null;
    realestateid?: any | null;
    unitid?: any | null;
    personid?: any | null;
    name1?: string | null;
    name2?: string | null;
    mobile?: string | null;
    phoneprivate?: string | null;
    email?: string | null;
    gender?: number | null;
    street?: string | null;
    housenumber?: string | null;
    zip?: string | null;
    city?: string | null;
    origin?: number | null;
}

export interface IPersonSearchFilter {
    byRealestate: boolean;
    byUnit: boolean;
}

export class ContactPersonSearchStore {
    rootStore: RootStore;

    personData: IIncidentContactPersonData[] = [];
    currentPersonSearchQuery: string = "";
    selectedPersonQueryString: string | undefined = undefined;
    isSearchingPersonData: boolean = false;
    personSearchFilter: IPersonSearchFilter = {
        byRealestate: false,
        byUnit: false
    };
    triggerFocusOnPersonInput: boolean = false;
    isSearchingPersonsInBackendByQuery: boolean = true;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            personData: observable,
            triggerFocusOnPersonInput: observable,
            currentPersonSearchQuery: observable,
            selectedPersonQueryString: observable,
            personSearchFilter: observable,
            isSearchingPersonData: observable,
            isSearchingPersonsInBackendByQuery: observable,

            searchPersonsInBackendByQuery: action,
            searchPersonsByRealestate: action,
            setCurrentPersonSearchQuery: action,
            setSelectedPersonQueryString: action,
            setTriggerFocusOnPersonInput: action,
            setPersonData: action,
            setPersonSearchFilter: action,
            setPersonSearchFilterRealestate: action,
            setPersonSearchFilterUnit: action,
            setIsSearchingPersonData: action,
            setIsSearchingPersonsInBackendByQuery: action,
            togglePersonSearchFilterRealestate: action,
            togglePersonSearchFilterUnit: action,

            filteredPersonData: computed
        });
    }

    getMappedAndSortedPersonData = (
        tenancyPersons: GetTenancyPersonsByRealestateId_tenancy_persons[]
    ): IIncidentContactPersonData[] => {
        const personData: IIncidentContactPersonData[] = tenancyPersons.map((person) => {
            const address = person.addresses.length > 0 ? person.addresses[0] : undefined;
            return {
                customerid: person.customerid,
                realestateid: person.realestateid,
                unitid: person.unitid,
                personid: person.personid,
                name1: person.name1,
                name2: person.name2,
                mobile: person.mobile,
                phoneprivate: person.phoneprivate,
                email: person.email,
                gender: person.gender,
                street: address ? person.addresses[0].street : null,
                housenumber: address ? person.addresses[0].housenumber : null,
                zip: address ? person.addresses[0].zip : null,
                city: address ? person.addresses[0].city : null,
                origin: person.origin
            };
        });

        // Sorting persons by last-name (name2)
        personData.sort((personA, personB) => {
            if (personA.name2 === null && personB.name2 !== null) {
                return 1;
            }

            if (personA.name2 !== null && personB.name2 === null) {
                return -1;
            }

            const personAName2 = personA.name2 ?? "";
            const personBName2 = personB.name2 ?? "";

            if (personAName2 < personBName2) {
                return -1;
            }

            if (personAName2 > personBName2) {
                return 1;
            }

            return 0;
        });

        return personData;
    };

    searchPersonsInBackendByQuery = async (searchQuery: string) => {
        this.setIsSearchingPersonData(true);

        const { data }: { data: GetTenancyPersonsWithQuery } = await apolloClientInstance.query<
            GetTenancyPersonsWithQuery,
            GetTenancyPersonsWithQueryVariables
        >({
            query: GET_TENANCY_PERSONS_WITH_QUERY,
            variables: {
                searchQuery: `%${searchQuery}%`
            }
        });

        const personData: IIncidentContactPersonData[] = this.getMappedAndSortedPersonData(data.tenancy_persons);

        this.setPersonData(personData);

        this.setIsSearchingPersonData(false);
    };

    searchPersonsByRealestate = async () => {
        this.setIsSearchingPersonData(true);

        const { data }: { data: GetTenancyPersonsByRealestateId } = await apolloClientInstance.query<
            GetTenancyPersonsByRealestateId,
            GetTenancyPersonsByRealestateIdVariables
        >({
            query: GET_TENANCY_PERSONS_BY_REALESTATEID,
            variables: {
                realestateId: this.rootStore.ticketStore.currentTicket?.realestate?.id
            }
        });

        const personData: IIncidentContactPersonData[] = this.getMappedAndSortedPersonData(data.tenancy_persons);

        this.setPersonData(personData);

        this.setIsSearchingPersonData(false);
    };

    get filteredPersonData(): IIncidentContactPersonData[] {
        let filteredPersonData: IIncidentContactPersonData[] = [...this.personData];

        if (this.personSearchFilter.byRealestate) {
            const filteredPersonDataByRealestate = this.personData.filter((person) => {
                return person.realestateid === this.rootStore.ticketStore.currentTicket?.realestate?.id;
            });

            filteredPersonData = [...filteredPersonDataByRealestate];
        }

        if (this.personSearchFilter.byUnit) {
            const filteredPersonDataByUnit = filteredPersonData.filter((person) => {
                return person.unitid === this.rootStore.ticketStore.currentTicket?.unit?.unitId;
            });

            filteredPersonData = [...filteredPersonDataByUnit];
        }

        const filteredPersonDataWithoutDuplicates =
            filteredPersonData.length < 1000
                ? filteredPersonData.filter((person, index, self) => {
                      return index === self.findIndex((p) => p.personid === person.personid);
                  })
                : filteredPersonData;

        if (!this.isSearchingPersonsInBackendByQuery) {
            const searchQuery = this.currentPersonSearchQuery ?? "";

            const filteredPersonDataBySearchQuery = filteredPersonDataWithoutDuplicates.filter((person) => {
                const personName = `${person.name1} ${person.name2}`.trim();
                const zipCity = `${person.zip} ${person.city}`.trim();
                return (
                    personName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    person.street?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    zipCity.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    person.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    person.mobile?.toLowerCase().includes(searchQuery.toLowerCase())
                );
            });

            return filteredPersonDataBySearchQuery;
        } else {
            return filteredPersonDataWithoutDuplicates;
        }
    }

    // Setters
    setIsSearchingPersonData = (isSearchingPersonData: boolean) => {
        this.isSearchingPersonData = isSearchingPersonData;
    };

    setCurrentPersonSearchQuery = (currentPersonSearchQuery: string) => {
        this.currentPersonSearchQuery = currentPersonSearchQuery;
    };

    setSelectedPersonQueryString = (selectedPersonQueryString: string | undefined) => {
        this.selectedPersonQueryString = selectedPersonQueryString;
    };

    setTriggerFocusOnPersonInput = (triggerFocusOnPersonInput: boolean) => {
        this.triggerFocusOnPersonInput = triggerFocusOnPersonInput;
    };

    setPersonData = (personData: IIncidentContactPersonData[]) => {
        this.personData = personData;
    };

    setPersonSearchFilter = (personSearchFilter: IPersonSearchFilter) => {
        this.personSearchFilter = personSearchFilter;
    };

    setPersonSearchFilterRealestate = (filterByRealestate: boolean) => {
        this.personSearchFilter.byRealestate = filterByRealestate;
    };

    setPersonSearchFilterUnit = (filterByUnit: boolean) => {
        this.personSearchFilter.byUnit = filterByUnit;
    };

    togglePersonSearchFilterRealestate = () => {
        const realestateFilterIsBeingDeactivated = this.personSearchFilter.byRealestate;

        if (realestateFilterIsBeingDeactivated) {
            this.setIsSearchingPersonsInBackendByQuery(true);

            if (this.currentPersonSearchQuery.length > 2) {
                this.searchPersonsInBackendByQuery(this.currentPersonSearchQuery);
            } else {
                this.setPersonData([]);
            }
        } else {
            this.setIsSearchingPersonsInBackendByQuery(false);
            this.searchPersonsByRealestate();
        }

        this.personSearchFilter.byRealestate = !this.personSearchFilter.byRealestate;
    };

    togglePersonSearchFilterUnit = () => {
        this.personSearchFilter.byUnit = !this.personSearchFilter.byUnit;
    };

    setIsSearchingPersonsInBackendByQuery = (isSearchingPersonsInBackendByQuery: boolean) => {
        this.isSearchingPersonsInBackendByQuery = isSearchingPersonsInBackendByQuery;
    };
}
