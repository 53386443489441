import { gql } from "@apollo/client";

export const GET_DEMO_PORTAL_SETTINGS = gql`
    query GetDemoPortalSettings($lang: String!) {
        ums_demoportalsettings(where: { language: { _eq: $lang } }) {
            id
            contactperson
            email
            mobile
            phone
            onlinecontactemail
            text1
            text2
            text1default
            text2default
        }
    }
`;

export const UPSERT_DEMO_PORTAL_SETTINGS = gql`
    mutation UpsertDemoPortalSettings($objects: [ums_demoportalsettings_insert_input!]!) {
        insert_ums_demoportalsettings(
            objects: $objects
            on_conflict: {
                constraint: demoportalsettings_pkey
                update_columns: [text1, text2, contactperson, email, mobile, phone, onlinecontactemail, language]
            }
        ) {
            returning {
                id
            }
        }
    }
`;

export const GET_STWEG_DEMO_DATA_BY_SUBDOMAIN = gql`
    query GetStwegDemoDataBySubdomain($subdomain: String!, $language: String! = "de") {
        ums_v_demoportalsettings(where: { subdomain: { _eq: $subdomain }, _and: { language: { _eq: $language } } }) {
            text1
            text2
            email
            mobile
            phone
            name
            onlinecontactemail
            contactperson
        }
    }
`;
