import React, { useContext, useCallback, useEffect, useState } from "react";
import {
    GetTenantBookingsByUser,
    GetTenantBookingsByUser_tenantbookings,
    GetTenantBookingsByUser_tenantbookings_tenantbookingfiles
} from "src/api/generated/GetTenantBookingsByUser";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize, DropdownItem } from "@wwimmo/ui";
import { getDate } from "src/utils/Date";
import { useParams } from "react-router";
import { getCurrencyElement } from "src/utils/Currency";
import { useListWithStore } from "src/hooks/list/useListWithStore";
import { NetworkConfig } from "src/network/NetworkConfig";
import { ColorStyle } from "src/utils/Colors";
import { uuid4 } from "@sentry/utils";

interface MyAccountsListProps {
    tenants: GetTenantBookingsByUser;
}

interface BookingItems {
    id?: any;
    date?: string;
    text?: string;
    debit?: any;
    credit?: any;
    isTitle?: boolean;
    tenantId?: string;
    tenantbookingfiles?: GetTenantBookingsByUser_tenantbookings_tenantbookingfiles[];
}

interface Params {
    realestateid: string;
}

const MyAccountsList = (props: MyAccountsListProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore } = useContext(RootStoreContext);
    const { realestateid } = useParams<Params>();

    const tenants = props.tenants;

    const listIdentifier = `coownerAccountsList-${realestateid}`;
    const { listParameters, currentSearchQuery, currentFilterQuery } = useListWithStore(listIdentifier);

    const [displayedTenantBookings, setDisplayedTenantBookings] = useState<BookingItems[]>([]);
    const [filterDropdownItems, setFilterDropdownItems] = useState<Array<DropdownItem>>([]);

    const getSaldoTenantBooking = useCallback(
        (accountbalance: any) => {
            let saldo: BookingItems[] = [];
            if (accountbalance < 0) {
                accountbalance = Math.abs(accountbalance);
                saldo = [
                    {
                        id: "saldo-row",
                        isTitle: true,
                        text: t("screens.realestate.my_account.saldo_debit"),
                        credit: "",
                        debit: accountbalance
                    }
                ];
            } else if (accountbalance > 0) {
                accountbalance = Math.abs(accountbalance);
                saldo = [
                    {
                        id: "saldo-row",
                        isTitle: true,
                        text: t("screens.realestate.my_account.saldo_credit"),
                        credit: accountbalance,
                        debit: ""
                    }
                ];
            } else if (accountbalance === 0 || accountbalance === null) {
                saldo = [
                    {
                        id: "saldo-row",
                        isTitle: true,
                        text: t("screens.realestate.my_account.saldo"),
                        credit: "",
                        debit: accountbalance
                    }
                ];
            }
            return saldo;
        },
        [t]
    );

    const convertToBookingItems = (tenantBookings: GetTenantBookingsByUser_tenantbookings[]): BookingItems[] => {
        return tenantBookings.map((tenantBooking) => ({
            id: tenantBooking.tenantbookingid,
            date: tenantBooking.date || "",
            text: tenantBooking.text || "",
            debit: tenantBooking.debit,
            credit: tenantBooking.credit,
            tenantId: tenantBooking.tenantid || "",
            tenantbookingfiles: tenantBooking.tenantbookingfiles || []
        }));
    };

    const filterAndSetSearchQuery = useCallback(
        (searchQuery: string = "", tenantId: string) => {
            if (tenants) {
                let foundTenant = tenants.tenants.find((tenant) => {
                    return tenant?.tenantid === tenantId;
                });

                if (tenantId === "0") {
                    foundTenant = tenants.tenants[0];
                }

                if (foundTenant) {
                    let foundTenantbookings = tenants.tenantbookings.filter((tenantbooking) => {
                        return tenantbooking.tenantid === foundTenant?.tenantid;
                    });
                    foundTenantbookings = foundTenantbookings.filter((tenantbooking) => {
                        return (
                            tenantbooking.text?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            tenantbooking.date.toLowerCase().includes(searchQuery.toLowerCase())
                        );
                    });
                    setDisplayedTenantBookings([
                        ...(getSaldoTenantBooking(foundTenant.accountbalance) || []),
                        ...convertToBookingItems(foundTenantbookings)
                    ]);
                }
            }
        },
        [tenants, getSaldoTenantBooking]
    );

    const handleSearchInput = useCallback(
        ({ searchQuery, filterItemValue }) => {
            filterAndSetSearchQuery(searchQuery, filterItemValue);
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: searchQuery, filterValue: filterItemValue }
                    : { scrollPosition: 0, searchQuery: searchQuery, filterValue: filterItemValue }
            );
        },
        [filterAndSetSearchQuery, navStore, listIdentifier, listParameters]
    );

    const getFilterItems = (tenants: GetTenantBookingsByUser) => {
        const filterDropdownItemsArray: DropdownItem[] = [];
        tenants.tenants.forEach((tenant) => {
            if ((tenant?.tenantid !== null && tenant?.accountbalance !== 0) || tenant.accountbalance !== null) {
                filterDropdownItemsArray.push({
                    value: String(tenant?.tenantid),
                    label: tenant?.name ?? ""
                });
            }
        });
        return filterDropdownItemsArray;
    };

    useEffect(() => {
        if (tenants) {
            setFilterDropdownItems(getFilterItems(tenants));
        }
    }, [tenants]);

    const onChangeFilter = useCallback(
        ({ searchQuery, filterItemValue }) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, filterValue: filterItemValue, searchQuery: searchQuery }
                    : { filterValue: filterItemValue, searchQuery: searchQuery }
            );
            filterAndSetSearchQuery(searchQuery, filterItemValue);
        },
        [filterAndSetSearchQuery, navStore, listIdentifier, listParameters]
    );

    const renderItem = useCallback((tenantbooking: BookingItems, screenSize: ScreenSize) => {
        const date = getDate(tenantbooking.date || "");

        const listRows: any[] = [];
        if (tenantbooking) {
            let rows: React.ReactElement[] = [];
            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    if (tenantbooking.isTitle) {
                        rows = [
                            <UI.List.Row key={"r-1"} className="bold">
                                <UI.List.Cell key={"c-3"} colspan={1} value={date} />
                                <UI.List.Cell
                                    key={"c-1"}
                                    colspan={3}
                                    value={tenantbooking?.text}
                                    className="text-left"
                                />
                                <UI.List.Cell
                                    key={"c-2"}
                                    colspan={1}
                                    value={getCurrencyElement(tenantbooking?.debit)}
                                    className="text-right"
                                />
                                <UI.List.Cell
                                    key={"c-4"}
                                    colspan={1}
                                    value={getCurrencyElement(tenantbooking?.credit)}
                                    className="text-right"
                                />
                            </UI.List.Row>
                        ];
                    } else {
                        rows = [
                            <UI.List.Row key={"row-1"}>
                                <UI.List.Cell key={"c-3"} colspan={1} value={date} />
                                <UI.List.Cell key={"c-1"} colspan={3} value={tenantbooking?.text} />
                                <UI.List.Cell
                                    key={"c-2"}
                                    colspan={1}
                                    value={getCurrencyElement(tenantbooking?.debit)}
                                    className="text-right"
                                />
                                <UI.List.Cell
                                    key={"c-4"}
                                    colspan={1}
                                    value={getCurrencyElement(tenantbooking?.credit)}
                                    className="text-right"
                                />
                            </UI.List.Row>
                        ];
                    }

                    break;
                case ScreenSize.MOBILE:
                    rows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={2} value={date} />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1}
                                value={getCurrencyElement(tenantbooking?.debit)}
                                className="text-right"
                            />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={1}
                                value={getCurrencyElement(tenantbooking?.credit)}
                                className="text-right"
                            />
                        </UI.List.Row>,
                        <UI.List.Row key={"r-2"}>
                            <UI.List.Cell key={"c-4"} colspan={2} value={tenantbooking?.text} className="sublabel" />
                        </UI.List.Row>
                    ];
                    break;
            }

            listRows.push(rows);
        }

        let documentLinkIcon = undefined;
        if (tenantbooking && tenantbooking.tenantbookingfiles && tenantbooking.tenantbookingfiles.length >= 1) {
            const fileid = tenantbooking.tenantbookingfiles[0].fileid;
            if (fileid) {
                let urlLink = NetworkConfig.datafileUrl + fileid;
                documentLinkIcon = (
                    <a href={urlLink} className="link d-flex" rel="noopener noreferrer d-flex" target="_blank">
                        <UI.Icon icon={UI.SVGIcon.Documents} color={ColorStyle("primary")} />
                    </a>
                );
            }
        }

        return (
            <UI.List.Item
                key={tenantbooking.id + uuid4()}
                screenSize={screenSize}
                rows={listRows}
                icon={documentLinkIcon}
            />
        );
    }, []);

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={t("screens.realestate.my_account.date")} />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={3}
                                value={t("screens.realestate.my_account.booking_description")}
                            />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={1}
                                value={t("screens.realestate.my_account.debit")}
                                className="text-right"
                            />
                            <UI.List.Cell
                                key={"c-4"}
                                colspan={1}
                                value={t("screens.realestate.my_account.credit")}
                                className="text-right"
                            />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={2} value={t("screens.realestate.my_account.date")} />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={1}
                                value={t("screens.realestate.my_account.debit")}
                                className="text-right"
                            />
                            <UI.List.Cell
                                key={"c-4"}
                                colspan={1}
                                value={t("screens.realestate.my_account.credit")}
                                className="text-right"
                            />
                        </UI.List.Row>
                    ];
                    break;
            }
            return <UI.List.Header rows={headerRows} />;
        },
        [t]
    );

    let initialFilterItem = filterDropdownItems.find((item) => item.value === currentFilterQuery);
    if (currentFilterQuery === "0") {
        initialFilterItem = filterDropdownItems[0];
    }

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={displayedTenantBookings}
            renderItem={renderItem}
            renderHeader={renderHeader}
            emptyListMessage={t("screens.realestate.assembly.no_bookings")}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={10}
            initialPage={listParameters?.paginationPage ?? undefined}
            onSearch={handleSearchInput}
            initialSearchQuery={currentSearchQuery}
            filterItems={filterDropdownItems}
            initialFilterItem={initialFilterItem}
            onChangeFilter={onChangeFilter}
        />
    );
};

export default observer(MyAccountsList);
