import { Login } from "./login/Login";
import { Logout } from "./logout/Logout";
import { PasswordChange } from "./passwordChange/PasswordChange";
import { PasswordRecovery } from "./passwordRecovery/PasswordRecovery";
import { PasswordReset } from "./passwordRecovery/PasswordReset";
import { Activate } from "./register/Activate";
import { ResendActivationEmail } from "./resendactivationemail/ResendActivationEmail";

export const Auth = {
    Login,
    Logout,
    PasswordChange,
    PasswordRecovery,
    PasswordReset,
    Activate,
    ResendActivationEmail
};
