import React, { useContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    GetAssembliesByRealestateid_assemblyitems as assemblyitems,
    GetAssembliesByRealestateid_assemblystates as assemblystatus
} from "src/api/generated/GetAssembliesByRealestateid";
import { RootStoreContext } from "src/stores/RootStore";
import { Route, selectRoute } from "src/config/routes";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import { useParams } from "react-router";
import { getDate, getDateWithLongMonth } from "src/utils/Date";

interface AssembliesManagerListProps {
    assemblyItems: assemblyitems[];
    assemblyStatus: assemblystatus[];
}

interface Params {
    realestateid: string;
}

const AssembliesManagerList = (props: AssembliesManagerListProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore, authStore } = useContext(RootStoreContext);
    const { realestateid } = useParams<Params>();

    const listIdentifier = `assembliesmanagerlist-${realestateid}`;

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    const [assemblystatus, setAssemblystatus] = useState<Map<number | null, { label: string }>>();

    useEffect(() => {
        if (props.assemblyItems) {
            setAssemblystatus(getEnumerationMap(props.assemblyStatus));
        }
    }, [props.assemblyItems, props.assemblyStatus]);

    const onRowClick = useCallback(() => {
        navStore.setListMapValue(
            listIdentifier,
            listParameters
                ? { ...listParameters, scrollPosition: window.pageYOffset }
                : { scrollPosition: window.pageYOffset }
        );
    }, [navStore, listIdentifier, listParameters]);

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listIdentifier, listParameters]
    );

    const getEnumerationMap = (enumerations: Array<assemblystatus>) => {
        const enumerationMap = new Map();

        enumerations.forEach((enumeration) => {
            if (enumeration.key !== null) {
                enumerationMap.set(enumeration.key, {
                    label: enumeration.label
                });
            }
        });

        return enumerationMap;
    };

    const renderItem = useCallback(
        (assemblyitem: assemblyitems, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];
            const AssemblyStatus = assemblystatus?.get(assemblyitem.state)?.label;
            const formatAddress = `${assemblyitem.street} ${assemblyitem.housenumber}, ${assemblyitem.zip} ${assemblyitem.city}`;

            let link: string | undefined = selectRoute(Route.managerAssembliesEdit, authStore.user?.role, {
                realestateid: realestateid,
                assemblyid: assemblyitem.id
            });

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={0.25}
                                value={
                                    <>
                                        <div>{assemblyitem.id[0].title}</div>
                                        <div>{getDateWithLongMonth(assemblyitem.date)}</div>
                                    </>
                                }
                            />
                            <UI.List.Cell key={"c-2"} colspan={0.2} value={<span>{AssemblyStatus}</span>} />
                            <UI.List.Cell key={"c-3"} colspan={0.3} value={<span>{formatAddress}</span>} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={0.25}
                                value={
                                    <>
                                        <div>{assemblyitem.id[0].title}</div>
                                        <div>{getDate(assemblyitem.date)}</div>
                                    </>
                                }
                            />
                            <UI.List.Cell key={"c-2"} colspan={0.25} value={<span>{AssemblyStatus}</span>} />
                            <UI.List.Cell key={"c-3"} colspan={0.5} value={<span>{formatAddress}</span>} />
                        </UI.List.Row>
                    ];
                    break;
            }

            return (
                <UI.List.Item
                    key={assemblyitem.id}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                    onClick={link ? onRowClick : undefined}
                />
            );
        },
        [assemblystatus, authStore.user?.role, onRowClick, realestateid]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={0.25} value={t("screens.realestate.assemblies.date")} />
                            <UI.List.Cell key={"c-2"} colspan={0.2} value={t("screens.realestate.assemblies.state")} />
                            <UI.List.Cell key={"c-3"} colspan={0.3} value={t("screens.realestate.assemblies.city")} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={0.25} value={t("screens.realestate.assemblies.date")} />
                            <UI.List.Cell key={"c-2"} colspan={0.25} value={t("screens.realestate.assemblies.state")} />
                            <UI.List.Cell key={"c-3"} colspan={0.5} value={t("screens.realestate.assemblies.city")} />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} />;
        },
        [t]
    );

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={props.assemblyItems}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={10}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
            emptyListMessage={t("screens.realestate.dashboard.no_assembly_available")}
        />
    );
};

export default observer(AssembliesManagerList);
