import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import { selectRoute, Route } from "src/config/routes";
import { GET_AGENDAITEM_TEMPLATES } from "src/api/agenda";
import { GetAgendaitemsTemplates, GetAgendaitemsTemplatesVariables } from "src/api/generated/GetAgendaitemsTemplates";
import { useQuery } from "@apollo/client";
import * as Screens from "src/screens";
import { getLang } from "src/utils/i18n";
import { AgendaItem, AgendaItemChild } from "src/types";
import { AgendaTemplateForm } from "./form/AgendaTemplateForm";

const AgendaTemplateBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore, assemblyStore } = useContext(RootStoreContext);

    const { data, loading, error } = useQuery<GetAgendaitemsTemplates, GetAgendaitemsTemplatesVariables>(
        GET_AGENDAITEM_TEMPLATES,
        {
            variables: {
                language: getLang()
            }
        }
    );

    useEffect(() => {
        navStore.setTitle(t("sidebar.settings.agenda_template"));
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);

        if (uiStore.isSidebarActive) {
            navStore.setBackbutton(null);
        } else {
            navStore.setBackbutton(selectRoute(Route.profile), t("screens.account.title").toString());
        }
    }, [navStore, t, authStore.user?.role, uiStore.isSidebarActive]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const initalAgendaItems: AgendaItem[] = data.agendaitem_parents.map(
        ({ id, sort, agendaitemtemplates_mls, agendaitem_children }) => {
            const initalAgendaItemsChildren = agendaitem_children.map(({ id, sort, agendaitemtemplates_mls }) => {
                const agendaItem: AgendaItemChild = {
                    id: id,
                    sort: sort,
                    text: agendaitemtemplates_mls[0].text ?? "",
                    description: agendaitemtemplates_mls[0].description ?? "",
                    agendaitemfiles: []
                };
                return agendaItem;
            });
            const agendaItem: AgendaItem = {
                id: id,
                sort: sort,
                text: agendaitemtemplates_mls[0].text ?? "",
                description: agendaitemtemplates_mls[0].description ?? "",
                agendaitemfiles: [],
                agendaitemchild: initalAgendaItemsChildren
            };

            return agendaItem;
        }
    );

    assemblyStore.setOriginalAgendaItems(initalAgendaItems);
    assemblyStore.setCurrentAgendaItems(initalAgendaItems);
    assemblyStore.setIsAgendaItemTemplate(true);

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <AgendaTemplateForm agendaItems={initalAgendaItems} />
            </UI.Container>
        </React.Fragment>
    );
};

export const AgendaTemplate = withRouter(Sentry.withProfiler(observer(AgendaTemplateBase)));
