import React, { useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./TicketsKanban.module.css";
import { Droppable } from "react-beautiful-dnd";
import { TicketCard } from "./TicketCard";
import { TICKET_STATE } from "src/stores/TicketStore";
import { RootStoreContext } from "src/stores/RootStore";
import { NewTicketCard } from "./NewTicketCard";

export const KanbanRailBase = (
    props: { title: string; id: string; ticketNumbers: number[] } & React.HTMLProps<HTMLDivElement>
) => {
    const { ticketListStore, uiStore } = useContext(RootStoreContext);
    return (
        <div className={styles.Rail}>
            <h6 className={styles.RailTitle}>{props.title}</h6>
            <div className={`${styles.RailContainer} ${uiStore.isMobile ? styles.RailMobile : styles.RailDesktop}`}>
                <Droppable droppableId={props.id}>
                    {(provided) => (
                        <div style={{ minHeight: 400 }} ref={provided.innerRef} {...provided.droppableProps}>
                            {props.ticketNumbers.map((ticketNumber, index) => {
                                const ticket = ticketListStore.getTicketByNumber(ticketNumber);
                                if (ticket) {
                                    return <TicketCard key={ticketNumber} ticket={ticket} index={index} />;
                                } else {
                                    return <></>;
                                }
                            })}
                            {provided.placeholder}
                            {props.id === TICKET_STATE.NEW && <NewTicketCard />}
                        </div>
                    )}
                </Droppable>
            </div>
        </div>
    );
};
export const KanbanRail = Sentry.withProfiler(observer(KanbanRailBase));
