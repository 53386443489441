import React, { useCallback } from "react";
import styles from "./Tag.module.css";
import { UI } from "@wwimmo/ui";
export interface TagProps {
    label: string;
    id: string;
    highlighted?: boolean;
    add?: (id: string) => void;
    remove?: (id: string) => void;
    className?: string;
}
export const Tag = (props: TagProps) => {
    const { label, add, remove, id, className, highlighted } = props;
    const onClickAdd = useCallback(() => add && add(id), [id, add]);
    const onClickRemove = useCallback(() => remove && remove(id), [id, remove]);
    return (
        <div
            className={`${styles.Tag} ${className ?? ""} ${highlighted ? styles.Highlighted : ""} cursor-pointer`}
            onClick={add ? onClickAdd : remove ? onClickRemove : undefined}
        >
            {label}
            {remove && <UI.Icon className={styles.IconClose} icon={UI.SVGIcon.Close} size={14}></UI.Icon>}
        </div>
    );
};
