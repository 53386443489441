import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { TicketActionSelector } from "src/screens/tickets/ticket/details/actions/TicketActionSelector";
import { useCallback, useContext, useState } from "react";
import { RootStoreContext } from "src/stores/RootStore";
import { LOADING_TYPE } from "src/stores/tickets/AssignOrderModalStore";
import styles from "./TicketActionCard.module.css";
import { MessageType } from "src/components/notifications/Notifier";
import { Route, selectRoute } from "src/config/routes";
import { useHistory } from "react-router-dom";

const TicketActionCardBase = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { assignOrderModalStore, uiStore, ticketStore, authStore } = useContext(RootStoreContext);

    const [isModalDisplayed, setIsModalDisplayed] = useState<boolean>(false);
    const [isMenuPointClicked, setIsMenuPointClicked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    const onClickMenuPoints = useCallback(() => {
        setIsMenuPointClicked(true);
    }, []);

    const onCloseModal = useCallback(() => {
        setIsModalDisplayed(false);
        setIsMenuPointClicked(false);
    }, []);

    const onClickDeleteModal = useCallback(async () => {
        if (isLoading) return;
        setIsLoading(true);

        const responseIncidentArchive = await ticketStore.currentTicket?.archive();

        if (responseIncidentArchive) {
            history.replace(selectRoute(Route.tickets, authStore.user?.role));
            uiStore.printStatusMessage(t("screens.tickets.action.archive.successfull_archived"), MessageType.SUCCESS);
        } else {
            uiStore.printStatusMessage(t("screens.tickets.action.archive.failed"), MessageType.ERROR);
        }

        setIsLoading(false);
        setIsModalDisplayed(false);
        setIsMenuPointClicked(false);
    }, [uiStore, ticketStore, t, history, authStore, isLoading]);

    const onClickCancelDeleteButton = useCallback(() => {
        setIsModalDisplayed(true);
    }, []);

    return (
        <UI.Card title={t("screens.tickets.actions").toString()} flex>
            <div className={styles.MenuPoint} onClick={onClickMenuPoints}>
                ...
            </div>
            <UI.Modal size="lg" show={isModalDisplayed} centered={true} className={styles.MenuPointModal}>
                <div className={styles.ModalTitle}>{t("screens.tickets.action.archive.cancel_delete")}</div>
                <div>{t("screens.tickets.action.archive.warning_message")}</div>
                <div className={styles.ButtonContainer}>
                    <UI.Button label="Abbrechen" className={styles.ModalButtonCancel} onClick={onCloseModal} />
                    <UI.Button
                        label={
                            isLoading === true ? (
                                <UI.RotatingSpinner className={styles.LoadSpinner} size={20} noLogo />
                            ) : (
                                t("screens.tickets.action.archive.delete_ticket")
                            )
                        }
                        className={styles.ModalButtonDelete}
                        onClick={onClickDeleteModal}
                        disabled={isLoading}
                    />
                </div>
            </UI.Modal>

            {assignOrderModalStore.loadingType === LOADING_TYPE.SERVICE_PERSON_DATA ? (
                <UI.RotatingSpinner noLogo size={50} />
            ) : (
                <TicketActionSelector />
            )}

            <UI.Button
                className={styles.ButtonCancelDelete}
                label={t("screens.tickets.action.archive.cancel_delete")}
                hidden={!isMenuPointClicked}
                onClick={onClickCancelDeleteButton}
            />
        </UI.Card>
    );
};

export const TicketActionCard = Sentry.withProfiler(observer(TicketActionCardBase));
