import React, { useCallback, useContext, useEffect, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { PROCESS } from "src/stores/TicketStore";
import { FilterStateType, SORT } from "src/stores/tickets/TicketListStore";
import { ColorStyle } from "src/utils/Colors";
import { Route, selectRoute } from "src/config/routes";
import { SCREEN_SIZE, TICKET_VIEW_TYPE } from "src/stores/UIStore";
import { TicketsList } from "./list/TicketsList";
import { TicketsKanban } from "./kanban/TicketsKanban";
import { TagSearch } from "src/components/tagSearch/TagSearch";
import styles from "./Tickets.module.css";

export const getIcon = (type: PROCESS) => {
    switch (type) {
        case PROCESS.DAMAGE_NOTIFICATION:
            return "/icons/damage.svg";
        case PROCESS.COMMON_REQUEST:
            return "/icons/unknown.svg";
        case PROCESS.NAMESIGN:
            return "/icons/doorbell.svg";
        case PROCESS.DOCUMENT_QUESTION:
            return "/icons/document_question.svg";
        case PROCESS.KEY_ORDER:
            return "/icons/keys.svg";
        default:
            return "/icons/unknown.svg";
    }
};

const TicketsBase = () => {
    const { t } = useTranslation();
    const { uiStore, navStore, ticketListStore, ticketStore, authStore } = useContext(RootStoreContext);
    const { setFilter, ticketFilter, clearFilter } = ticketListStore;

    useEffect(() => {
        navStore.setTitle("");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);
        navStore.setBackbutton(null);
    }, [navStore]);

    useEffect(() => {
        ticketStore.initialLoadAllTickets();

        // Load all tickets once component is rendered
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        ticketStore.startLoadAllTicketsTimer();
        return () => {
            ticketStore.clearLoadAllTicketsTimer();
        };
    }, [ticketStore]);

    const onFilterChange = useCallback(
        (id: FilterStateType) => {
            setFilter({ ...ticketFilter, state: id });
        },
        [ticketFilter, setFilter]
    );

    const onSortChange = useCallback(
        (newSort: SORT) => {
            setFilter({ ...ticketFilter, sort: newSort });
        },
        [ticketFilter, setFilter]
    );

    const onChangeSearchQuery = useCallback(
        (query: string) => {
            setFilter({ ...ticketFilter, query });
        },
        [ticketFilter, setFilter]
    );

    const toggleViewType = useCallback(() => {
        uiStore.setTicketViewType(
            uiStore.ticketListView === TICKET_VIEW_TYPE.LIST ? TICKET_VIEW_TYPE.KANBAN : TICKET_VIEW_TYPE.LIST
        );
    }, [uiStore]);

    const sortItems = useMemo(
        () => [
            { label: t("screens.tickets.sort.dateNewest"), value: SORT.DATE_NEWEST },
            { label: t("screens.tickets.sort.dateOldest"), value: SORT.DATE_OLDEST }
        ],
        [t]
    );

    const onUpdateSearchTag = useCallback(
        (tagId: string, status: boolean) => {
            ticketListStore.setTag(tagId, status);
        },
        [ticketListStore]
    );

    const onResetSearch = useCallback(() => {
        clearFilter();
    }, [clearFilter]);

    return (
        <UI.Container
            className={`pt-5 ${styles.TicketsContainer} ${
                uiStore.currentScreenSize <= SCREEN_SIZE.LAPTOP ? styles.MinPadding : ""
            }`}
            fluid
        >
            <UI.Row className="mb-2 ticket-list-header">
                <UI.Col xs={12} sm={{ span: 8 }}>
                    <UI.Title>{t("screens.tickets.sidebar_title")}</UI.Title>
                </UI.Col>
                {uiStore.ticketListView === TICKET_VIEW_TYPE.LIST && (
                    <UI.Col xs={12} sm={4} md={4} lg={{ span: 4 }}>
                        <UI.Button
                            to={selectRoute(Route.newTicket, authStore.user?.role)}
                            label={t("screens.tickets.list.new_ticket_button")}
                            className={styles.AddButton}
                        />
                    </UI.Col>
                )}
            </UI.Row>
            <UI.Row className="ticket-list-searchbar-row">
                <UI.Col xs={12}>
                    <div className={`${styles.Toolbar} mb-3`}>
                        <div className={styles.ToolbarViewSwitcher}>
                            <UI.Icon
                                className={styles.Icon}
                                icon={UI.SVGIcon.TicketListView}
                                color={ColorStyle(uiStore.ticketListView === TICKET_VIEW_TYPE.LIST ? "black" : "grey")}
                                onClick={toggleViewType}
                            />
                            <UI.Icon
                                className={styles.Icon}
                                icon={UI.SVGIcon.TicketKanbanView}
                                color={ColorStyle(
                                    uiStore.ticketListView === TICKET_VIEW_TYPE.KANBAN ? "black" : "grey"
                                )}
                                onClick={toggleViewType}
                            />
                        </div>
                        <div className={styles.ToolbarTagSearch}>
                            <TagSearch
                                searchTags={ticketListStore.searchTags}
                                value={ticketFilter.query}
                                updateTagStatusById={onUpdateSearchTag}
                                onChangeInput={onChangeSearchQuery}
                                reset={onResetSearch}
                            />
                        </div>
                        {uiStore.ticketListView === TICKET_VIEW_TYPE.LIST && (
                            <div className={styles.ToolbarSort}>
                                <UI.Dropdown
                                    id="sort"
                                    onSelect={onSortChange}
                                    items={sortItems}
                                    label={uiStore.isMobile ? "" : t(`screens.tickets.sort.${ticketFilter.sort}`)}
                                    icon={uiStore.isMobile && <UI.Icon icon={UI.SVGIcon.Sort} />}
                                />
                            </div>
                        )}
                    </div>
                </UI.Col>
            </UI.Row>
            <UI.Row className="ticket-list-row">
                <UI.Col xs={12}>
                    {uiStore.ticketListView === TICKET_VIEW_TYPE.LIST ? (
                        <TicketsList onFilterChange={onFilterChange} />
                    ) : (
                        <TicketsKanban />
                    )}
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};

export const Tickets = Sentry.withProfiler(observer(TicketsBase));
