import React from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { withRouter, useParams } from "react-router";
import { ScreenWithTiles } from "src/components/screenWithTiles/ScreenWithTiles";
import { ScreenType } from "src/api/tiles";
import { UI } from "@wwimmo/ui";
import { ManagerNews } from "src/components/news/manager/ManagerNews";

interface Params {
    realestateid: string;
}

const NewsPageBase = () => {
    const { realestateid } = useParams<Params>();

    const { t } = useTranslation();

    return (
        <ScreenWithTiles
            title={t("screens.realestate.news_preview.title")}
            screenId={ScreenType.TENANT}
            realestateid={realestateid}
        >
            <UI.Card flex={true} title={t("screens.realestate.news_preview.title")}>
                <ManagerNews />
            </UI.Card>
        </ScreenWithTiles>
    );
};

export const NewsPage = withRouter(Sentry.withProfiler(observer(NewsPageBase)));
