import React, { useState, useCallback } from "react";
import { Pie as PieChartjs } from "react-chartjs-2";
import { ChartConfig } from "src/config/charts";
import { ChartDataType } from "src/components/tiles/Tile";

interface PieProps {
    data: PieData;
}

export interface PieHint {
    title: string;
    label: string;
}
interface PieData {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string[];
        textColor: string[];
        hint?: PieHint[];
    }[];
    valuetype: ChartDataType;
}

export const Pie = (props: PieProps) => {
    const [dimension, setDimension] = useState({
        width: undefined,
        height: undefined
    });

    const ref = useCallback((node) => {
        if (node !== null) {
            setDimension({
                width: node.getBoundingClientRect().width,
                height: node.getBoundingClientRect().height
            });
        }
    }, []);

    const anchor: "end" = "end";
    const align: "start" = "start";

    const options = {
        title: {
            display: false
        },
        legend: { display: false },
        cutoutPercentage: 0,
        animation: {
            animateScale: true,
            animateRotate: true,
            duration: ChartConfig.animationDuration
        },
        tooltips: {
            enabled: true,
            displayColors: false,
            callbacks: {
                title: function (tooltipItem: any, data: any) {
                    const hint = data.datasets[0].hint[tooltipItem[0].index];
                    return hint.title;
                },
                label: (tooltipItem: any, data: any) => {
                    const hint = data.datasets[0].hint[tooltipItem.index];
                    return hint.label;
                }
            }
        },
        hover: {
            mode: undefined
        },
        plugins: {
            datalabels: {
                anchor,
                align,
                padding: 7,
                color: props.data.datasets[0].textColor,
                formatter: (value: any) => {
                    return `${Math.round(value)}%`;
                },
                display: (context: any) => {
                    const percentageValue = context.dataset.data[context.dataIndex];

                    return percentageValue > 5;
                }
            }
        }
    };

    const legendWidth = 0.4;
    const bullet = (index: number) => {
        const size = { width: 10, height: 10 };
        return (
            <svg {...size}>
                <rect {...size} fill={props.data.datasets[0].backgroundColor[index] || "black"} />
            </svg>
        );
    };

    return (
        <div className="Pie d-flex flex-row" ref={ref}>
            {dimension.width && dimension.height && (
                <div>
                    <PieChartjs
                        height={dimension.height}
                        width={(dimension.width || 100) * (1 - legendWidth)}
                        data={props.data}
                        options={options}
                    />
                </div>
            )}
            <div className="PieLegend">
                <ul>
                    {props.data.labels.map((legend: string, index: number) => (
                        <li key={`legend-${index}`}>
                            {bullet(index)} &nbsp;
                            {legend}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
