const getNewListIndexValueDown = (oldIndexValue: number, numberOfListItems: number) => {
    let newIndexValue;

    if (oldIndexValue < numberOfListItems - 1) {
        newIndexValue = oldIndexValue + 1;
    } else {
        newIndexValue = numberOfListItems - 1;
    }
    return newIndexValue;
};

const getNewListIndexValueUp = (oldIndexValue: number) => {
    let newIndexValue = 0;

    if (oldIndexValue !== 0) {
        newIndexValue = oldIndexValue - 1;
    }

    return newIndexValue;
};

const handleListElementDown = (listType: string, oldIndexValue: number, newIndexValue: number) => {
    const listElement = document.getElementById(`${listType}-dropdown-list`);
    const previousElement = document.getElementById(`${listType}-list-item-${oldIndexValue}`);
    const currentElement = document.getElementById(`${listType}-list-item-${newIndexValue}`);

    previousElement?.classList.remove("active");
    currentElement?.classList.add("active");

    const listHeight = listElement?.offsetHeight;
    const currentElementHeight = currentElement?.offsetHeight;
    const currentElementOffsetTop = currentElement?.offsetTop;

    if (
        typeof listHeight === "number" &&
        typeof currentElementHeight === "number" &&
        typeof currentElementOffsetTop === "number"
    ) {
        const pixelsToScroll = listHeight - currentElementHeight - currentElementOffsetTop;

        if (pixelsToScroll < 0) {
            if (Math.abs(pixelsToScroll) > currentElementHeight) {
                listElement?.scrollBy(0, currentElementHeight);
            } else {
                listElement?.scrollBy(0, Math.abs(pixelsToScroll));
            }
        }
    }
};

const handleListElementUp = (listType: string, oldIndexValue: number, newIndexValue: number) => {
    const listElement = document.getElementById(`${listType}-dropdown-list`);
    const previousElement = document.getElementById(`${listType}-list-item-${oldIndexValue}`);
    const currentElement = document.getElementById(`${listType}-list-item-${newIndexValue}`);

    previousElement?.classList.remove("active");
    currentElement?.classList.add("active");

    const zipListScrollTop = listElement?.scrollTop;
    const currentElementHeight = currentElement?.offsetHeight;
    const currentElementOffsetTop = currentElement?.offsetTop;

    if (
        typeof zipListScrollTop === "number" &&
        typeof currentElementHeight === "number" &&
        typeof currentElementOffsetTop === "number"
    ) {
        const pixelsToScroll = currentElementOffsetTop - zipListScrollTop;

        if (pixelsToScroll < 0) {
            if (Math.abs(pixelsToScroll) > currentElementHeight) {
                listElement?.scrollBy(0, -currentElementHeight);
            } else {
                listElement?.scrollBy(0, pixelsToScroll);
            }
        }
    }
};

export const handleListKeyDown = (
    numberOfListItems: number,
    listType: string,
    oldIndexValue: number,
    setNewListIndexStateFunction: any
) => {
    const newIndexValue = getNewListIndexValueDown(oldIndexValue, numberOfListItems);
    handleListElementDown(listType, oldIndexValue, newIndexValue);
    setNewListIndexStateFunction(newIndexValue);
};

export const handleListKeyUp = (listType: string, oldIndexValue: number, setNewListIndexStateFunction: any) => {
    const newIndexValue = getNewListIndexValueUp(oldIndexValue);
    handleListElementUp(listType, oldIndexValue, newIndexValue);
    setNewListIndexStateFunction(newIndexValue);
};

export const setFocus = (inputElementId: string) => {
    const inputElement: HTMLInputElement | null = document?.getElementById(inputElementId) as HTMLInputElement;

    if (inputElement) {
        var tempEl = document.createElement("input");
        tempEl.style.position = "absolute";
        tempEl.style.top = inputElement.offsetTop + 7 + "px";
        tempEl.style.left = inputElement.offsetLeft + "px";
        tempEl.style.height = "0";
        tempEl.style.opacity = "0";

        document.body.appendChild(tempEl);
        tempEl.focus();

        setTimeout(function () {
            inputElement.focus();
            inputElement.click();

            document.body.removeChild(tempEl);
        }, 100);
    }
};
