import React, { FunctionComponent } from "react";
import { Tiles } from "src/components";
import { UI } from "@wwimmo/ui";
import { useParams } from "react-router";
import { ScreenWidth } from "src/stores/UIStore";
import { ScreenType } from "src/api/tiles";

interface Params {
    realestateid: string;
}

type ScreenWithTilesProps = {
    screenId: ScreenType;
    realestateid?: string;
    title: JSX.Element | string;
    tiles?: JSX.Element;
};

const ScreenWithContactTilesBase: FunctionComponent<ScreenWithTilesProps> = (props) => {
    const { realestateid } = useParams<Params>();

    return (
        <UI.Container className="pt-4">
            <UI.Row>
                <UI.Col xl={9}>
                    <UI.Title>{props.title}</UI.Title>
                </UI.Col>
            </UI.Row>
            <UI.Row>
                <UI.Col xl={9} className="position-relative">
                    {props.children}
                </UI.Col>
                {window.innerWidth >= ScreenWidth.XL ? (
                    <UI.Col xl={3}>
                        <Tiles.ContactTile realestateid={props.realestateid ?? realestateid} />
                        {props.tiles}
                    </UI.Col>
                ) : (
                    <>
                        <UI.Col sm={12}>
                            <Tiles.ContactTile realestateid={props.realestateid ?? realestateid} />
                            {props.tiles}
                        </UI.Col>
                    </>
                )}
            </UI.Row>
        </UI.Container>
    );
};

export const ScreenWithContactTiles = ScreenWithContactTilesBase;
