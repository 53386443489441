import React from "react";
import "src/components/contact/Contact.css";
import { UI } from "@wwimmo/ui";
import { ContactItemType } from "src/components/contact/Contact";

interface ContactItemProps {
    name: string;
    contacts: ContactItemRow[];
    role?: string;
}
export interface ContactItemRow {
    type: ContactItemType;
    content: string | null;
}

export const ContactItem = (props: ContactItemProps) => {
    const contactItem = (index: number, type: ContactItemType, content: string | null) => {
        let icon = <></>;
        let linkType = "http://";
        if (content === null) {
            return <React.Fragment key={"item-" + index} />;
        }

        switch (type) {
            case ContactItemType.PHONE: {
                icon = <UI.Icon icon={UI.SVGIcon.PhoneOutlined} />;
                linkType = "tel:";
                break;
            }
            case ContactItemType.DEVICE: {
                icon = <UI.Icon icon={UI.SVGIcon.Device} />;
                linkType = "tel:";
                break;
            }
            case ContactItemType.MAIL: {
                icon = <UI.Icon icon={UI.SVGIcon.Mail} />;
                linkType = "mailto:";
                break;
            }
            default: {
                icon = <></>;
                linkType = "http://";
                break;
            }
        }

        return (
            <div key={"item-" + index} className="ContactIcons d-flex">
                <div className="ContactIcon">{icon}</div>
                <div className="ContactDetail">
                    <a href={linkType + content}>{content}</a>
                </div>
            </div>
        );
    };

    const contactItems = props.contacts
        .filter((contact) => contact.content)
        .map((item, index) => contactItem(index, item.type, item.content));

    return (
        <div>
            <div>
                <div className="ContactName">{props.name}</div>
                {props.role && <div className="ContactRole">{props.role}</div>}
                {contactItems.length !== 0 ? <div className="ContactDetails">{contactItems}</div> : undefined}
            </div>
        </div>
    );
};
