import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_UNIT_APPLIANCES, GET_REALESTATE_APPLIANCES } from "src/api/appliances";
import { GetUnitAppliances, GetUnitAppliancesVariables } from "src/api/generated/GetUnitAppliances";
import AppliancesList from "src/screens/realestates/realestate/appliances/list/AppliancesList";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { Route, selectRoute } from "src/config/routes";
import { withRouter, RouteComponentProps, useParams } from "react-router";
import { ScreenWithTiles } from "src/components/screenWithTiles/ScreenWithTiles";
import { ScreenType } from "src/api/tiles";

interface Params {
    realestateid: string;
    unitid?: string;
}

const AppliancesBase = (props: RouteComponentProps) => {
    const { realestateid, unitid } = useParams<Params>();

    const { t } = useTranslation();
    const { navStore, authStore } = useContext(RootStoreContext);

    useEffect(() => {
        navStore.setBackbutton(
            selectRoute(unitid ? Route.unit : Route.dashboard, authStore.user?.role, {
                realestateid: realestateid,
                unitid: unitid ?? ""
            }),
            unitid ? t("screens.unit.title") : t("screens.realestate.dashboard.title")
        );
    });

    const { data, loading, error } = useQuery<GetUnitAppliances, GetUnitAppliancesVariables>(
        unitid ? GET_UNIT_APPLIANCES : GET_REALESTATE_APPLIANCES,
        {
            variables: {
                realestateid: realestateid,
                unitid: unitid,
                isunitidnull: unitid ? false : true
            }
        }
    );

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    let screenTitle = t("screens.realestate.appliances.title");

    if (unitid) {
        screenTitle = t("screens.unit.appliances");
    } else if (navStore.isConsolidation) {
        screenTitle = t("screens.realestate.appliances.title_short");
    }

    return (
        <ScreenWithTiles title={screenTitle} screenId={ScreenType.APPLIANCES} realestateid={realestateid}>
            <UI.Card>
                <AppliancesList appliances={data.appliances} />
            </UI.Card>
        </ScreenWithTiles>
    );
};

export const Appliances = withRouter(Sentry.withProfiler(observer(AppliancesBase)));
