import React, { useContext, useEffect, useCallback, useState } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { UI, ScreenSize } from "@wwimmo/ui";
import { RouteProps, withRouter } from "react-router";
import { Route } from "src/config/routes";
import { Role, RolesMap } from "src/network/User";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { getRoleKey } from "src/network/User";
import { NetworkConfig } from "src/network/NetworkConfig";
import { CustomerType } from "src/components/customer/Customer";
import { useQuery } from "@apollo/client";
import { GET_ANNOUNCEMENT_HISTORY } from "src/api/announcements";
import { GetAnnouncementHistory, GetAnnouncementHistoryVariables } from "src/api/generated/GetAnnouncementHistory";
import * as Screens from "src/screens";
import { AnnouncementHistoryModal } from "src/screens/profile/announcement-history-modal/AnnouncementHistoryModal";

interface ListItemInformation {
    label: string;
    link?: string;
    onClick?: any;
}

export const ProfileBase = (props: RouteProps) => {
    const { uiStore, navStore, authStore } = useContext(RootStoreContext);
    const { t } = useTranslation();
    const [isAnnouncementModalDisplayed, setIsAnnouncementModalDisplayed] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const customerOptions = navStore.customer.options.map((option) => option.option);

    const {
        data: dataAnnouncementHistory,
        loading: loadingAnnouncementHistory,
        error: errorAnnouncementHistory
    } = useQuery<GetAnnouncementHistory, GetAnnouncementHistoryVariables>(GET_ANNOUNCEMENT_HISTORY, {
        variables: {
            apiversion: navStore.customer.apiversion ?? 1.0,
            userroles: authStore.user?.availableRoleIds ?? [],
            targetoptions: customerOptions
        }
    });

    useEffect(() => {
        runInAction(() => {
            const lastNonProfilePage = navStore.historyStack
                .slice()
                .reverse()
                .find((url) => !isProfileSubRoute(url));

            navStore.setBackbutton(lastNonProfilePage ?? "/", t("labels.goback").toString());

            if (authStore.user?.hasECAdminRole && !authStore.user.hasPortalRole) {
                uiStore.setShowSearchAppbarButton(false);
                navStore.setCloseNavTarget(Route.profile);
            }

            navStore.setTitle(t("screens.account.title"));
        });
    });

    const isProfileSubRoute = (url: string | undefined): boolean => {
        return (
            url?.includes(Route.profile) ||
            url === Route.passwordchange ||
            url === Route.customerusers ||
            url === Route.tileAdministration ||
            url === Route.easycontactsettings ||
            url === Route.ticketingsettings ||
            url === Route.agendaTemplate ||
            url === Route.portalDemo
        );
    };

    const openMyWWPortal = useCallback(async () => {
        if (authStore.user) {
            const accessToken = authStore.token;
            const tokenType = "Bearer";
            const role = authStore.user?.role;

            try {
                const fetchResult = await fetch(`${NetworkConfig.getCustomerPortalUrl}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    try {
                        const returnData = await fetchResult.json();
                        const { url } = returnData.data;
                        window.open(url, "_blank");
                        setError("");
                    } catch (jsonError) {
                        setError(t("screens.account.customerportalerror").toString());
                    }
                } else {
                    setError(t("screens.account.customerportalerror").toString());
                }
            } catch (fetchError: any) {
                setError(fetchError.error);
            }
        }
    }, [authStore.token, authStore.user, t]);

    const openAnnouncementHistoryModal = useCallback(() => {
        setIsAnnouncementModalDisplayed(true);
    }, []);

    const onCloseAnnouncementModal = useCallback(() => {
        setIsAnnouncementModalDisplayed(false);
    }, []);

    const renderItem = useCallback((item: ListItemInformation, screenSize: ScreenSize) => {
        let listRows: React.ReactElement[] = [];

        listRows = [
            <UI.List.Row key={"r-1"}>
                <UI.List.Cell key={"c-1"} colspan={1} value={item.label} className="" />
            </UI.List.Row>
        ];

        return (
            <UI.List.Item
                key={item.label}
                screenSize={screenSize}
                rows={listRows}
                to={item.link}
                onClick={item.onClick}
            />
        );
    }, []);

    if (loadingAnnouncementHistory) {
        return <Screens.Loading />;
    }
    if (errorAnnouncementHistory) {
        return (
            <Screens.Error
                message={errorAnnouncementHistory?.message}
                networkError={errorAnnouncementHistory?.networkError}
            />
        );
    }
    if (!dataAnnouncementHistory) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    const listItems: ListItemInformation[] = [
        {
            label: t("screens.auth.password.change.title"),
            link: Route.passwordchange
        }
    ];

    if (authStore.user?.availableRoles) {
        if (
            (authStore.user.availableRoles.includes(Role.MANAGER) ||
                authStore.user.availableRoles.includes(Role.ECADMIN)) &&
            navStore.customer.partner === CustomerType.WW
        ) {
            listItems.push({
                label: t("screens.account.customerportal"),
                onClick: openMyWWPortal
            });
        }
    }

    if (!authStore.user?.hasOnlyEcAdminRole) {
        listItems.push({
            label: t("screens.account.announcements_history"),
            onClick: openAnnouncementHistoryModal
        });
    }

    return (
        <UI.Container className="pt-4">
            <UI.Row>
                <UI.Col xs={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
                    <UI.Card
                        title={
                            navStore.customer.userName +
                            ", " +
                            authStore.user?.availableRoles
                                .map((role) => RolesMap.find((r) => r.role === role)?.label)
                                .join(", ")
                        }
                    >
                        <UI.List.BasicList
                            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                            items={listItems}
                            renderItem={renderItem}
                        />
                        <UI.Button to={Route.logout} label={t("screens.auth.logout")} />
                        {error ? <p className="mt-3 text-danger">{error}</p> : ""}
                    </UI.Card>
                </UI.Col>
            </UI.Row>
            {!authStore.user?.hasOnlyEcAdminRole ? (
                <AnnouncementHistoryModal
                    historyAnnouncements={dataAnnouncementHistory.announcements}
                    isModalDisplayed={isAnnouncementModalDisplayed}
                    onCloseAnnouncementModal={onCloseAnnouncementModal}
                />
            ) : undefined}
        </UI.Container>
    );
};
export const Profile = Sentry.withProfiler(withRouter(observer(ProfileBase)));
