import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import { selectRoute, Route } from "src/config/routes";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_USER } from "src/api/customer";
import { GetCustomerUsers } from "src/api/generated/GetCustomerUsers";
import * as Screens from "src/screens";
import CustomerUserList from "src/screens/customerusers/list/details/list/CustomerUserDetailsList";
import { CustomerUserResetButton } from "./resetButton/CustomerUserResetButton";

interface Params {
    userid: any;
}

const CustomerUserDetailsBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore, uiStore } = useContext(RootStoreContext);
    const { userid } = useParams<Params>();

    useEffect(() => {
        navStore.setTitle(t(""));
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);
        navStore.setBackbutton(selectRoute(Route.customerusers), t("screens.customer_users.title").toString());
    }, [navStore, t]);

    useEffect(() => {
        if (uiStore.isCustomerUserReset) {
            refetch();
            uiStore.setIsCustomerUserReset(false);
        }
        // eslint-disable-next-line
    }, [uiStore.isCustomerUserReset, t, uiStore]);

    const { data, loading, error, refetch } = useQuery<GetCustomerUsers>(GET_CUSTOMER_USER, {
        variables: {
            userid: userid
        }
    });

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    const customerUser = data.customer_users.find((user) => user.userid === userid);

    return (
        <UI.Container className="pt-4">
            <UI.Title>{t("screens.customer_users.user_details")}</UI.Title>
            <UI.Card title={t("screens.customer_users.basic_data")}>
                <CustomerUserList
                    userrolesDefinition={data.userrolesdefinition}
                    user={customerUser || data.customer_users[0]}
                />
                <CustomerUserResetButton user={customerUser || data.customer_users[0]} userid={userid} />
            </UI.Card>
        </UI.Container>
    );
};

export const CustomerUserDetails = withRouter(Sentry.withProfiler(observer(CustomerUserDetailsBase)));
