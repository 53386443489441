import Resizer from "react-image-file-resizer";

const MAX_IMAGE_DIMENSION = {
    WIDTH: 640,
    HEIGHT: 640
};

export const resizeImageFile = (file: File): Promise<string> => {
    return new Promise(async (resolve) => {
        const mimeTypeParts = file.type.split("/");

        Resizer.imageFileResizer(
            file,
            MAX_IMAGE_DIMENSION.WIDTH,
            MAX_IMAGE_DIMENSION.HEIGHT,
            mimeTypeParts.length > 1 ? mimeTypeParts[1] : "jpeg",
            100, // image quality
            0, // rotation
            (uri) => {
                if (typeof uri === "string") {
                    resolve(uri);
                } else {
                    resolve("");
                }
            },
            "base64"
        );
    });
};

export const fileToBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(typeof reader.result === "string" ? reader.result : "");
        reader.onerror = (error) => reject(error);
    });
