import * as Sentry from "@sentry/react";

export enum Role {
    NONE = "none",
    MANAGER = "manager",
    OWNER = "owner",
    ADMINISTRATOR = "administrator",
    INSPECTION_USER = "inspectionuser",
    INSPECTION_ADMIN = "inspectionadmin",
    ECADMIN = "ecadmin",
    COOWNER = "coowner",
    AUDITOR = "auditor",
    TENANT = "tenant"
}

export enum ErpType {
    RIMO = 1,
    IT2 = 2,
    FAIRWALTER = 5
}

interface RoleMapEntry {
    key: string;
    role: Role;
    rootRoute: string;
    label: string;
    roleid: number;
}

export const RolesMap: Array<RoleMapEntry> = [
    { key: "manager", role: Role.MANAGER, rootRoute: "/manager", label: "Bewirtschafter", roleid: 30 },
    { key: "owner", role: Role.OWNER, rootRoute: "/owner", label: "Eigentümer", roleid: 31 },
    { key: "coowner", role: Role.COOWNER, rootRoute: "/coowner", label: "Stockwerkeigentümer", roleid: 32 },
    { key: "auditor", role: Role.AUDITOR, rootRoute: "/auditor", label: "Revisor", roleid: 36 },
    { key: "tenant", role: Role.TENANT, rootRoute: "/tenant", label: "Mieter", roleid: 33 },
    { key: "administrator", role: Role.ADMINISTRATOR, rootRoute: "/", label: "Administrator", roleid: 39 },
    {
        key: "inspectionuser",
        role: Role.INSPECTION_USER,
        rootRoute: "/inspectionapphint",
        label: "Abnahme Benutzer",
        roleid: 20
    },
    {
        key: "inspectionadmin",
        role: Role.INSPECTION_ADMIN,
        rootRoute: "/inspectionapphint",
        label: "Abnahme Administrator",
        roleid: 21
    },
    { key: "ecadmin", role: Role.ECADMIN, rootRoute: "/", label: "EasyContact Administrator", roleid: 52 },
    { key: "none", role: Role.NONE, rootRoute: "/", label: "", roleid: 0 }
];

export const getValidRole = (role: string): Role => {
    const validRole = RolesMap.find((r) => r.key === role);
    return validRole ? validRole.role : Role.NONE;
};

export const isValidRole = (role: string): boolean => {
    const validRole = RolesMap.find((r) => r.key === role);
    return validRole ? true : false;
};

const getValidRoleId = (role: string): number => {
    const validRole = RolesMap.find((r) => r.key === role);
    return validRole ? validRole.roleid : 0;
};

export const getRoleKey = (role: Role | undefined) => {
    const currentRole = RolesMap.find((r) => r.role === role);
    return currentRole ? currentRole.key : "";
};

export const getRoleLabel = (role: Role) => {
    return RolesMap.find((r) => r.role === role)?.label || "Unbekannt";
};

export const getRoleByRoleId = (roleId: number): Role => {
    const foundRoleMapEntry = RolesMap.find((role) => role.roleid === roleId);
    return foundRoleMapEntry ? foundRoleMapEntry.role : Role.NONE;
};

export const getRoleId = (role: Role): number => {
    const foundRoleMapEntry = RolesMap.find((roleMapEntry) => roleMapEntry.role === role);
    return foundRoleMapEntry ? foundRoleMapEntry.roleid : 0;
};

export class User {
    role: Role = Role.NONE;
    availableRoles: Role[] = [];
    availablePortalRoles: Role[] = [];
    availableRoleIds: number[] = [];
    userid?: string;
    username?: string;
    customerid?: string;
    erpType?: ErpType;
    hasPortalRole: boolean = false;
    hasInspectionRole: boolean = false;
    hasECAdminRole: boolean = false;
    hasAuditorRole: boolean = false;
    hasCoownerAuditorRole: boolean = false;
    hasCoownerRole: boolean = false;
    hasOnlyCoownerRole: boolean = false;
    hasOnlyEcAdminRole: boolean = false;

    userIdCidaas: string = ""; // sub
    identityIdCidaas: string = "";

    error(message: string) {
        console.error(message);
    }

    setRole = (role: Role) => {
        this.role = role;
    };

    async init(access_token: string, userIdCidaas: string, identityIdCidaas: string) {
        if (access_token) {
            const token = await this.parseJwt(access_token);

            this.userIdCidaas = userIdCidaas;
            this.identityIdCidaas = identityIdCidaas;

            this.username = token.email;
            this.customerid = token.hasOwnProperty("xhasura01") ? token["xhasura01"] : undefined;
            this.userid = token.hasOwnProperty("xhasura03") ? token["xhasura03"] : undefined;

            if (token.hasOwnProperty("roles")) {
                const roles = token["roles"];

                if (roles.length > 0) {
                    this.availableRoles = roles.filter((role: string) => getValidRole(role) !== Role.NONE);

                    this.availablePortalRoles = roles
                        .filter((role: string) => {
                            const roleId = getValidRoleId(role);

                            // Excluding non-stand-alone 'administrator' role (39).
                            if (roleId >= 30 && roleId < 39) {
                                return true;
                            } else {
                                return false;
                            }
                        })
                        .sort((roleA: string, roleB: string) => {
                            const roleIdA = getValidRoleId(roleA);
                            const roleIdB = getValidRoleId(roleB);

                            return roleIdA - roleIdB;
                        })
                        .map((role: string) => getValidRole(role));

                    this.availableRoleIds = roles.map((role: string) => getValidRoleId(role));
                } else {
                    this.error("x-hasura-allowed-roles claim not found");
                    this.availableRoles = [];
                    this.availableRoleIds = [];
                    this.availablePortalRoles = [];
                }

                if (this.availableRoles.includes(Role.MANAGER) || this.availableRoles.includes(Role.OWNER)) {
                    this.hasPortalRole = true;
                }

                if (
                    this.availableRoles.includes(Role.INSPECTION_ADMIN) ||
                    this.availableRoles.includes(Role.INSPECTION_USER)
                ) {
                    this.hasInspectionRole = true;
                }

                if (this.availableRoles.includes(Role.ECADMIN)) {
                    this.hasECAdminRole = true;
                }

                if (this.availableRoles.includes(Role.AUDITOR)) {
                    this.hasAuditorRole = true;
                }

                if (this.availableRoles.includes(Role.AUDITOR) && this.availableRoles.includes(Role.COOWNER)) {
                    this.hasCoownerAuditorRole = true;
                }

                if (this.availableRoles.length === 1 && this.availableRoles.includes(Role.COOWNER)) {
                    this.hasOnlyCoownerRole = true;
                }

                if (this.availableRoles.includes(Role.COOWNER)) {
                    this.hasCoownerRole = true;
                }

                if (this.availableRoles.length === 1 && this.availableRoles.includes(Role.ECADMIN)) {
                    this.hasOnlyEcAdminRole = true;
                }

                if (this.availablePortalRoles.length > 0) {
                    this.role = getValidRole(this.availablePortalRoles[0]);
                } else if (this.availableRoles.length > 0) {
                    this.role = getValidRole(this.availableRoles[0]);
                } else {
                    this.error("no default role could be determined");
                    this.role = Role.NONE;
                }

                Sentry.configureScope((scope) => {
                    scope.setUser({ id: this.userid, username: this.username });
                });
            } else {
                this.error("no 'roles' claim found!");
            }
        }
    }

    private async parseJwt(token: string) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
                .join("")
        );

        return JSON.parse(jsonPayload);
    }
}
