import React, { useContext, useCallback, FunctionComponent } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPSERT_CUSTOMERSETTING } from "src/api/customer";
import { UpsertCustomersetting, UpsertCustomersettingVariables } from "src/api/generated/UpsertCustomersetting";
import { MessageType } from "src/components/notifications/Notifier";
import { ums_customersettings_insert_input } from "src/api/generated/globalTypes";
import { GetTicketingSettings as TicketingSettings } from "src/api/generated/GetTicketingSettings";
import styles from "./TicketingBillingInformationForm.module.css";

type TicketingBillingInformationFormProps = {
    ticketingSettings: TicketingSettings;
    refetchTicketingSettings: any;
};

interface TicketingBillingInformationFormFields {
    name: string;
    co: string;
    city: string;
    zip: string;
    street: string;
    housenumber: string;
    email: string;
}

const TicketingBillingInformationFormBase: FunctionComponent<TicketingBillingInformationFormProps> = (props) => {
    const { t } = useTranslation();

    const { uiStore } = useContext(RootStoreContext);

    const { refetchTicketingSettings, ticketingSettings } = props;

    const [upsertCustomersettingMutation, { loading: isUpsertCustomersettingMutationLoading }] = useMutation<
        UpsertCustomersetting,
        UpsertCustomersettingVariables
    >(UPSERT_CUSTOMERSETTING, {
        onCompleted: () => {
            uiStore.printStatusMessage(t("screens.easycontact_settings.success_save_message"), MessageType.INFO);
        }
    });

    let name = "";
    let co = "";
    let city = "";
    let zip = "";
    let street = "";
    let housenumber = "";
    let email = "";

    if (ticketingSettings.billingaddress.length > 0 && ticketingSettings.billingaddress[0].value) {
        const billingAddress = JSON.parse(ticketingSettings.billingaddress[0].value);

        name = billingAddress.hasOwnProperty("name") ? billingAddress["name"] : undefined;
        co = billingAddress.hasOwnProperty("c/o") ? billingAddress["c/o"] : undefined;
        city = billingAddress.hasOwnProperty("city") ? billingAddress["city"] : undefined;
        zip = billingAddress.hasOwnProperty("zip") ? billingAddress["zip"] : undefined;
        street = billingAddress.hasOwnProperty("street") ? billingAddress["street"] : undefined;
        housenumber = billingAddress.hasOwnProperty("housenumber") ? billingAddress["housenumber"] : undefined;
    }

    if (ticketingSettings.billingemail.length > 0 && ticketingSettings.billingemail[0].value) {
        email = ticketingSettings.billingemail[0].value;
    }

    const { register, handleSubmit, formState, reset } = useForm<TicketingBillingInformationFormFields>({
        mode: "onBlur",
        defaultValues: {
            name: name,
            co: co,
            city: city,
            zip: zip,
            street: street,
            housenumber: housenumber,
            email: email
        }
    });

    const { errors } = formState;

    const resetForm = useCallback(() => {
        let name = "";
        let co = "";
        let city = "";
        let zip = "";
        let street = "";
        let housenumber = "";
        let email = "";

        if (ticketingSettings.billingaddress.length > 0 && ticketingSettings.billingaddress[0].value) {
            const billingAddress = JSON.parse(ticketingSettings.billingaddress[0].value);

            name = billingAddress.hasOwnProperty("name") ? billingAddress["name"] : undefined;
            co = billingAddress.hasOwnProperty("c/o") ? billingAddress["c/o"] : undefined;
            city = billingAddress.hasOwnProperty("city") ? billingAddress["city"] : undefined;
            zip = billingAddress.hasOwnProperty("zip") ? billingAddress["zip"] : undefined;
            street = billingAddress.hasOwnProperty("street") ? billingAddress["street"] : undefined;
            housenumber = billingAddress.hasOwnProperty("housenumber") ? billingAddress["housenumber"] : undefined;
        }

        if (ticketingSettings.billingemail.length > 0 && ticketingSettings.billingemail[0].value) {
            email = ticketingSettings.billingemail[0].value;
        }

        reset({
            name: name,
            co: co,
            city: city,
            zip: zip,
            street: street,
            housenumber: housenumber,
            email: email
        });
    }, [reset, ticketingSettings]);

    const { dirtyFields } = formState;

    const handleFormSubmit = useCallback(
        async (formData: TicketingBillingInformationFormFields) => {
            try {
                const insertInput: ums_customersettings_insert_input[] = [];

                const billingAddress = {
                    name: formData.name ?? "",
                    "c/o": formData.co ?? "",
                    city: formData.city ?? "",
                    zip: formData.zip ?? "",
                    street: formData.street ?? "",
                    housenumber: formData.housenumber ?? ""
                };

                insertInput.push({
                    key: "TICKET.BILLINGADDRESS",
                    value: JSON.stringify(billingAddress),
                    valuetype: 3
                });

                if (dirtyFields.email) {
                    insertInput.push({
                        key: "TICKET.BILLINGEMAIL",
                        value: formData.email.toString(),
                        valuetype: 3
                    });
                }

                await upsertCustomersettingMutation({
                    variables: {
                        objects: insertInput
                    }
                });

                refetchTicketingSettings();

                reset(
                    { ...formData },
                    {
                        keepDirty: false,
                        keepDefaultValues: false,
                        keepValues: false
                    }
                );
            } catch (error: any) {
                uiStore.printStatusMessage(
                    `${t("screens.easycontact_settings.failure_save_message")}: ${error.message}`,
                    MessageType.ERROR
                );
            }
        },
        [reset, upsertCustomersettingMutation, uiStore, t, dirtyFields, refetchTicketingSettings]
    );

    return (
        <UI.Form>
            <UI.Row>
                <UI.Col md={12} lg={6}>
                    <UI.Row>
                        <UI.Col>
                            <UI.Input
                                label={t("screens.tickets.form.person.name").toString()}
                                {...register("name")}
                                type="text"
                                className={`${dirtyFields.name ? styles.changed : ""}`}
                                errorMsg={errors.name ? errors.name.message : undefined}
                            />
                        </UI.Col>
                    </UI.Row>
                    <UI.Row>
                        <UI.Col>
                            <UI.Input
                                label={t("screens.tickets.form.person.co").toString()}
                                {...register("co")}
                                type="text"
                                className={`${dirtyFields.co ? styles.changed : ""}`}
                                errorMsg={errors.co ? errors.co.message : undefined}
                            />
                        </UI.Col>
                    </UI.Row>
                    <UI.Row>
                        <UI.Col>
                            <UI.Input
                                label={t("screens.tickets.form.person.street").toString()}
                                {...register("street")}
                                type="text"
                                className={`${dirtyFields.street ? styles.changed : ""}`}
                                errorMsg={errors.street ? errors.street.message : undefined}
                            />
                        </UI.Col>
                        <UI.Col>
                            <UI.Input
                                label={t("screens.tickets.form.person.street_number").toString()}
                                {...register("housenumber")}
                                type="text"
                                className={`${dirtyFields.housenumber ? styles.changed : ""}`}
                                errorMsg={errors.housenumber ? errors.housenumber.message : undefined}
                            />
                        </UI.Col>
                    </UI.Row>
                    <UI.Row>
                        <UI.Col>
                            <UI.Input
                                label={t("screens.tickets.form.person.zip").toString()}
                                {...register("zip")}
                                type="text"
                                className={`${dirtyFields.zip ? styles.changed : ""}`}
                                errorMsg={errors.zip ? errors.zip.message : undefined}
                            />
                        </UI.Col>
                        <UI.Col>
                            <UI.Input
                                label={t("screens.tickets.form.person.city").toString()}
                                {...register("city")}
                                type="text"
                                className={`${dirtyFields.city ? styles.changed : ""}`}
                                errorMsg={errors.city ? errors.city.message : undefined}
                            />
                        </UI.Col>
                    </UI.Row>
                </UI.Col>
                <UI.Col md={12} lg={6}>
                    <UI.Input
                        label={t("screens.tickets.form.person.email").toString()}
                        {...register("email")}
                        type="text"
                        className={`${dirtyFields.email ? styles.changed : ""}`}
                        errorMsg={errors.email ? errors.email.message : undefined}
                    />
                </UI.Col>
            </UI.Row>

            <div className="mt-4">
                <UI.Button
                    type="submit"
                    label={isUpsertCustomersettingMutationLoading ? t("labels.is_saving") : t("labels.save")}
                    disabled={!formState.isDirty}
                    onClick={handleSubmit(handleFormSubmit)}
                    className={styles.FormButton}
                />
                <UI.Button
                    label={t("labels.reset")}
                    disabled={!formState.isDirty}
                    onClick={resetForm}
                    className={`${styles.FormButton} ml-sm-2 mt-2 mt-sm-0`}
                />
            </div>
        </UI.Form>
    );
};

export const TicketingBillingInformationForm = Sentry.withProfiler(TicketingBillingInformationFormBase);
