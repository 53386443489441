import React, { useCallback, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { useQuery } from "@apollo/client";
import { GET_COMMON_OWNERS } from "src/api/owners";
import { CommonOwner as CommonOwners, CommonOwner_common_owners as CommonOwner } from "src/api/generated/CommonOwner";
import * as Screens from "src/screens";
import { observer } from "mobx-react-lite";
import { Route, selectRoute } from "src/config/routes";
import { UI, ScreenSize } from "@wwimmo/ui";
import { runInAction } from "mobx";

const OwnersBase = () => {
    const [searchQuery, setSearchQuery] = useState<string>("");
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);

    const listIdentifier = "ownerlist";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    let currentSearchQuery: string | undefined = undefined;

    if (listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    }

    useEffect(() => {
        runInAction(() => {
            navStore.setBackbutton(null);
            navStore.setTitle(t("screens.owners.title"));
            navStore.setCurrentSubTitle(null);
            navStore.resetActionButtons();
        });
    });

    const { loading, error, data } = useQuery<CommonOwners>(GET_COMMON_OWNERS);

    const onChangeQueryInput = useCallback(
        ({ searchQuery }) => {
            setSearchQuery(searchQuery);

            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: searchQuery }
                    : { scrollPosition: 0, searchQuery: searchQuery }
            );
        },
        [setSearchQuery, listParameters, navStore]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            const headerRows: React.ReactElement[] = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={7} value={""} />
                    <UI.List.Cell key={"c-2"} colspan={3} value={t("screens.realestates.title")} />
                </UI.List.Row>
            ];

            return <UI.List.Header rows={headerRows} />;
        },
        [t]
    );

    const onOwnerClick = useCallback(
        (ownerid: string) => () => {
            navStore.setCurrentOwnerId(ownerid);
            navStore.setSingleRealEstate(true);
        },
        [navStore]
    );

    const renderItem = useCallback(
        (owner: CommonOwner, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];

            const name = owner.person
                ? `${owner.person.name1 || ""} ${owner.person.name2 || ""}`
                : t("screens.owners.no_name");

            listRows = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={7} value={name} />
                    <UI.List.Cell key={"c-2"} colspan={3} value={String(owner.realestates.length)} />
                </UI.List.Row>
            ];

            let link =
                owner.realestates.length === 1
                    ? selectRoute(Route.dashboard, authStore.user?.role, { realestateid: owner.realestates[0].id })
                    : selectRoute(Route.realestatesOwner, authStore.user?.role, { ownerid: owner.id });

            return (
                <UI.List.Item
                    key={owner.id}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                    onClick={owner.realestates.length === 1 ? onOwnerClick(owner.id) : undefined}
                />
            );
        },
        [t, authStore.user?.role, onOwnerClick]
    );

    if (loading) return <Screens.Loading fullscreen />;
    if (error) return <Screens.Error message={error.message} networkError={error.networkError} />;
    if (!data) return <Screens.Error message={t("error.nodata")} />;

    const displayedOwners = data.common_owners
        .filter((owner) => {
            if (owner.person) {
                return (
                    owner.person.name1.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    owner.person.name2?.toLowerCase().includes(searchQuery.toLowerCase())
                );
            } else {
                return false;
            }
        })
        .sort((ownerA, ownerB) => {
            let nameOwnerA = `${ownerA.person?.name1} ${ownerA.person?.name2}`.toLowerCase().trim();
            let nameOwnerB = `${ownerB.person?.name1} ${ownerB.person?.name2}`.toLowerCase().trim();

            return nameOwnerA.localeCompare(nameOwnerB);
        });

    return (
        <UI.Container className="pt-4">
            <UI.Row>
                <UI.Col sm={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
                    <UI.Card flex={true}>
                        <UI.Row>
                            <UI.Col>
                                <UI.List.BasicList
                                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                                    items={displayedOwners}
                                    renderItem={renderItem}
                                    renderHeader={renderHeader}
                                    onSearch={data.common_owners.length > 15 ? onChangeQueryInput : undefined}
                                    initialSearchQuery={currentSearchQuery}
                                    emptyListMessage={t("screens.owners.no_owners_found")}
                                />
                            </UI.Col>
                        </UI.Row>
                    </UI.Card>
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};
export const Owners = Sentry.withProfiler(observer(OwnersBase));
