import React, { useCallback, useContext, useState } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { ColorStyle } from "src/utils/Colors";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ANNOUNCEMENTS, INSERT_ANNOUNCEMENT_AUDIT } from "src/api/announcements";
import { GetAnnouncements, GetAnnouncementsVariables } from "src/api/generated/GetAnnouncements";
import { InsertAnnouncementAudit, InsertAnnouncementAuditVariables } from "src/api/generated/InsertAnnouncementAudit";
import { uccm_announcementaudits_insert_input } from "src/api/generated/globalTypes";
import "./FeatureAnnouncementBanner.css";

enum AUDIT_EVENT {
    ANNOUNCED = 10,
    CLOSED = 20,
    VIEWED = 30,
    POSTPONED = 40
}

const FeatureAnnouncementBannerBase = () => {
    const { t } = useTranslation();
    const { authStore, uiStore } = useContext(RootStoreContext);

    const [isAnnouncementModalDisplayed, setIsAnnouncementModalDisplayed] = useState<boolean>(false);

    const { loading, data } = useQuery<GetAnnouncements, GetAnnouncementsVariables>(GET_ANNOUNCEMENTS, {
        variables: {
            customerid: authStore.user?.customerid,
            userid: authStore.user?.userid
        }
    });

    const [insertAnnouncementAuditMutation] = useMutation<InsertAnnouncementAudit, InsertAnnouncementAuditVariables>(
        INSERT_ANNOUNCEMENT_AUDIT
    );

    const writeAnnouncementAudit = useCallback(
        async (announcementId: string, auditEvent: AUDIT_EVENT) => {
            const insertInput: uccm_announcementaudits_insert_input[] = [
                {
                    announcementid: announcementId,
                    event: auditEvent
                }
            ];

            await insertAnnouncementAuditMutation({
                variables: {
                    objects: insertInput
                }
            });
        },
        [insertAnnouncementAuditMutation]
    );

    const onClickOk = useCallback(async () => {
        if (uiStore.currentAnnouncement?.id) {
            await writeAnnouncementAudit(uiStore.currentAnnouncement?.id, AUDIT_EVENT.CLOSED);
        }
        uiStore.setCurrentAnnouncement(undefined);
    }, [writeAnnouncementAudit, uiStore]);

    const onClickMoreInformation = useCallback(async () => {
        if (uiStore.currentAnnouncement?.id) {
            await writeAnnouncementAudit(uiStore.currentAnnouncement?.id, AUDIT_EVENT.VIEWED);
        }

        setIsAnnouncementModalDisplayed(true);
    }, [writeAnnouncementAudit, uiStore]);

    const onCloseAnnouncementModal = useCallback(() => {
        setIsAnnouncementModalDisplayed(false);
        uiStore.setCurrentAnnouncement(undefined);
    }, [uiStore]);

    if (loading || data === undefined) {
        return <></>;
    }

    if (!uiStore.wasAnnouncementDownloaded && data.announcements && data.announcements.length > 0) {
        const announcement = data.announcements[0];

        uiStore.setCurrentAnnouncement({
            id: announcement.id,
            teaser: announcement.details[0].teaser,
            content: announcement.details[0].content
        });

        writeAnnouncementAudit(announcement.id, AUDIT_EVENT.ANNOUNCED);
        uiStore.setWasAnnouncementDownloaded(true);
    }

    return (
        <>
            {uiStore.currentAnnouncement ? (
                <>
                    <div className="FeatureAnnouncementBanner container-fluid">
                        <UI.Row>
                            <UI.Col md={12} xl={9} className="d-flex align-items-center mb-4 mb-lg-0">
                                <UI.Icon
                                    className="AnnouncementInfoIcon"
                                    icon={UI.SVGIcon.Information}
                                    color={ColorStyle("white")}
                                />
                                <div className="FeatureAnnouncementText d-inline">
                                    {uiStore.currentAnnouncement.teaser}
                                </div>
                            </UI.Col>
                            <UI.Col md={12} xl={3} className="d-flex align-items-center justify-content-end">
                                <button onClick={onClickMoreInformation} className="FeatureAnnouncementButton">
                                    {t("labels.learn_more")}...
                                </button>
                                <button onClick={onClickOk} className="FeatureAnnouncementButton">
                                    {t("labels.ok").toLocaleUpperCase()}...
                                </button>
                            </UI.Col>
                        </UI.Row>
                    </div>
                    {isAnnouncementModalDisplayed ? (
                        <>
                            <UI.Modal
                                show={isAnnouncementModalDisplayed}
                                onClose={onCloseAnnouncementModal}
                                size="lg"
                                isScrollable={true}
                            >
                                <div
                                    className="AnnouncmentModalContent"
                                    dangerouslySetInnerHTML={{ __html: uiStore.currentAnnouncement.content ?? "" }}
                                />
                                <UI.Button
                                    className="AnnouncementModalButton"
                                    label={t("labels.ok").toUpperCase()}
                                    onClick={onCloseAnnouncementModal}
                                />
                            </UI.Modal>
                        </>
                    ) : undefined}
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export const FeatureAnnouncementBanner = Sentry.withProfiler(observer(FeatureAnnouncementBannerBase));
