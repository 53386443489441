import React, { useCallback, useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { observer } from "mobx-react";
import { UseFormRegister, DeepMap, FieldError } from "react-hook-form";
import { GetCitiesByName, GetCities } from "src/utils/Location";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";
import "src/screens/realestates/realestate/assemblies/manager/edit/form/AssembliesEditForm.css";
import {
    SEARCH_STATE,
    ERROR_TYPE,
    LIST_TYPE,
    AssemblyEditFormFields
} from "src/screens/realestates/realestate/assemblies/manager/edit/form/AssembliesEditForm";
import { handleListKeyDown, handleListKeyUp, setFocus } from "src/utils/AutocompleteInputHelper";

interface CityAutocompleteInputProps {
    setZipWasSelected: any;
    cityWasSelected: boolean;
    setStreetWasSelected: any;
    streetWasSelected: boolean;
    setStreetNumberWasSelected: any;
    setCityWasSelected: any;
    closeAllLists: any;
    setValue: any;
    watchCity: string;
    register: UseFormRegister<AssemblyEditFormFields>;
    errors: DeepMap<AssemblyEditFormFields, FieldError>;
    setSearchType: any;
    setNameItems: any;
    searchType: SEARCH_STATE;
    setError: any;
    nameItems: string[];
    zipItems: string[];
    setZipItems: any;
    setCurrentZip: any;
    currentCityName: string;
    setCurrentCityName: any;
    dirtyFields: DeepMap<AssemblyEditFormFields, true>;
}

const CityAutocompleteInputBase = (props: CityAutocompleteInputProps) => {
    const { t } = useTranslation();

    const [activeListItemIndex, setActiveListItemIndex] = useState<number>(-1);
    const [hasRendered, setHasRendered] = useState<boolean>(false);

    const {
        setError,
        searchType,
        zipItems,
        setZipItems,
        setZipWasSelected,
        cityWasSelected,
        setStreetWasSelected,
        streetWasSelected,
        setStreetNumberWasSelected,
        setValue,
        watchCity,
        register,
        errors,
        setSearchType,
        setNameItems,
        nameItems,
        closeAllLists,
        setCityWasSelected,
        setCurrentZip,
        currentCityName,
        setCurrentCityName,
        dirtyFields
    } = props;

    useEffect(() => {
        if (!hasRendered) {
            setHasRendered(true);
        } else if (watchCity !== currentCityName) {
            onChangeInputCityName(watchCity);
        }
        // Only changes to 'watchCity' should cause an running of this function
        // eslint-disable-next-line
    }, [watchCity]);

    const onChangeInputCityName = debounce(async (inputName: string) => {
        const inputNameCity = inputName.trim();

        if (inputNameCity !== currentCityName || !streetWasSelected) {
            closeAllLists();
            setError(ERROR_TYPE.NONE);

            setCityWasSelected(false);
            setStreetWasSelected(false);
            setStreetNumberWasSelected(false);

            setValue("street", "");
            setValue("housenumber", "");

            setSearchType(SEARCH_STATE.CITY);

            if (inputNameCity.length > 1 && zipItems.length === 0) {
                const cities = await GetCitiesByName(inputNameCity);

                setNameItems(cities.map((city: any) => `${city.zipcode} ${city.city}`));

                if (cities.length === 0) {
                    setError(ERROR_TYPE.CITY);
                }
            } else {
                setZipItems([]);
                setNameItems([]);
            }

            setSearchType(SEARCH_STATE.NONE);
        }
    }, 800);

    const onClickInputCityName = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            closeAllLists();
            setActiveListItemIndex(-1);

            const inputCity = e.target.value.trim();
            if (inputCity.length > 1 && zipItems.length === 0 && !cityWasSelected) {
                const cities = await GetCitiesByName(inputCity);

                setNameItems(cities.map((city: any) => `${city.zipcode} ${city.city}`));

                if (cities.length === 0) {
                    setError(ERROR_TYPE.CITY);
                }
            } else {
                setZipItems([]);
                setNameItems([]);
            }
        },
        [closeAllLists, cityWasSelected, zipItems.length, setError, setNameItems, setZipItems]
    );

    const getZipAndCityValues = (zipAndCity: string): { zip: string; city: string } => {
        const addressParts = zipAndCity.split(" ");

        let zip = "";
        let city = "";

        if (addressParts && addressParts.length > 0) {
            zip = addressParts[0];
            addressParts.shift();
            city = addressParts.join(" ");
        }

        return { zip: zip, city: city };
    };

    const setZipAndCityFormValues = useCallback(
        (zipAndCity: string) => {
            const { zip, city } = getZipAndCityValues(zipAndCity);

            setValue("zip", zip);
            setValue("city", city);

            setCurrentZip(zip);
            setCurrentCityName(city);

            setZipItems([]);
            setNameItems([]);

            setCityWasSelected(true);
            setZipWasSelected(true);

            setFocus("street");
        },
        [setValue, setCityWasSelected, setNameItems, setZipWasSelected, setZipItems, setCurrentCityName, setCurrentZip]
    );

    const onClickNameItem = useCallback(
        (e: any) => {
            const itemValue = e.target.innerHTML;
            setZipAndCityFormValues(itemValue);
        },
        [setZipAndCityFormValues]
    );

    const onFocusInput = useCallback(
        (e: any) => {
            closeAllLists();
            setActiveListItemIndex(-1);
        },
        [closeAllLists]
    );

    const onKeyDownInputCityName = useCallback(
        async (e: any) => {
            if (e.key === "Tab") {
                closeAllLists();
                onChangeInputCityName.cancel();

                const cityName = e.target.value.trim();

                const isCityNameListItemSelected = nameItems.length > 0 && activeListItemIndex !== -1;

                if (isCityNameListItemSelected) {
                    e.preventDefault();
                    setZipAndCityFormValues(nameItems[activeListItemIndex]);
                } else if (cityName.length !== 0) {
                    setSearchType(SEARCH_STATE.CITY);

                    const cities = await GetCities({ city: cityName });

                    if (cities.length > 0) {
                        const cityWithShortestName = cities.reduce(
                            (city: { city: string; zip: string }, cityB: { city: string; zip: string }) =>
                                city.city.length <= cityB.city.length ? city : cityB
                        );

                        setCurrentZip(cityWithShortestName.zipcode);
                        setCurrentCityName(cityWithShortestName.city);

                        setValue("zip", cityWithShortestName.zipcode, { shouldDirty: true });
                        setValue("city", cityWithShortestName.city);

                        setZipWasSelected(true);
                        setCityWasSelected(true);
                    }
                }

                setSearchType(SEARCH_STATE.NONE);
            } else if (nameItems.length > 0 && e.key === "ArrowDown") {
                handleListKeyDown(nameItems.length, LIST_TYPE.CITY, activeListItemIndex, setActiveListItemIndex);
            } else if (nameItems.length > 0 && e.key === "ArrowUp") {
                handleListKeyUp(LIST_TYPE.CITY, activeListItemIndex, setActiveListItemIndex);
            } else if (nameItems.length > 0 && e.key === "Enter") {
                setZipAndCityFormValues(nameItems[activeListItemIndex]);
            }
        },
        [
            closeAllLists,
            setValue,
            onChangeInputCityName,
            activeListItemIndex,
            nameItems,
            setZipAndCityFormValues,
            setCityWasSelected,
            setSearchType,
            setZipWasSelected,
            setCurrentCityName,
            setCurrentZip
        ]
    );

    const loadingSpinner = <UI.RotatingSpinner noLogo size={20} className="loading-items-spinner" />;

    const citySelectionList = (
        <ul
            id="city-dropdown-list"
            className={`${searchType === SEARCH_STATE.CITY ? "searching" : ""} dropdown-list city `}
        >
            {searchType === SEARCH_STATE.CITY ? loadingSpinner : undefined}
            {nameItems && nameItems.length > 0
                ? nameItems.map((item, index) => (
                      <li key={index}>
                          <div className="list-item" id={`city-list-item-${index}`} onClick={onClickNameItem}>
                              {item}
                          </div>
                      </li>
                  ))
                : undefined}
        </ul>
    );

    return (
        <>
            <UI.Input
                id="city"
                label={`${t("screens.realestate.assembly.city")}`}
                {...register("city")}
                type="text"
                onFocus={onFocusInput}
                onKeyDown={onKeyDownInputCityName}
                onClick={onClickInputCityName}
                autoComplete="nope"
                errorMsg={errors.city ? errors.city.message : undefined}
                className={dirtyFields.city ? "changed" : ""}
            />
            {citySelectionList}
        </>
    );
};

export const CityAutocompleteInput = Sentry.withProfiler(observer(CityAutocompleteInputBase));
