import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { DAMAGE_NOTIFICATION_PROPERTY, IApplianceData, IMAGE_TYPE, TICKET_PROPERTY } from "src/stores/TicketStore";
import { useSelectionListKeyHandler } from "src/hooks/ticket/selection-list-key-handler/useSelectionListKeyHandler";
import { boldenMatchedSubstring } from "src/utils/Common";
import styles from "./DamageNotificationFormFields.module.css";
import { useSetFocus } from "src/hooks/set-focus/useSetFocus";
import "./GeneralFormStyles.css";
import { ImageSelectorContainer } from "src/components/imageSelector/ImageSelectorContainer";
import { ImageSelectorItem } from "src/components/imageSelector/ImageSelectorItem";

const DamageNotificationFormFieldsBase = () => {
    const { t } = useTranslation();
    const { ticketStore, damageNotificationStore } = useContext(RootStoreContext);

    const [displayApplianceList, setDisplayApplianceList] = useState<boolean>(false);
    const [lastScrollPositionYUnitItems, setLastSrollYPositionUnitItems] = useState<number>(0);

    const applianceInputRef = useRef<HTMLInputElement>(null);

    const setFocus = useSetFocus();

    const resetSearchQuery = useCallback(() => {
        damageNotificationStore.setCurrentApplianceSearchQuery(
            damageNotificationStore.selectedApplianceQueryString ?? ""
        );
    }, [damageNotificationStore]);

    useEffect(() => {
        const handleClickOutsideUnitInput = (event: any) => {
            if (
                displayApplianceList &&
                applianceInputRef.current &&
                !applianceInputRef.current.contains(event.target) &&
                !(event.target.classList.contains("list-item") && event.target.classList.contains("appliance")) &&
                !(
                    event.target.classList.contains("highlighted-text") && event.target.classList.contains("appliance")
                ) &&
                !event.target.classList.contains("appliance-list-item-span") &&
                !event.target.classList.contains("appliance-list-item-line-1") &&
                !event.target.classList.contains("appliance-list-item-line-2")
            ) {
                setDisplayApplianceList(false);

                if (
                    ticketStore.currentTicket?.isUnitSelected &&
                    damageNotificationStore.currentApplianceSearchQuery !==
                        damageNotificationStore.selectedApplianceQueryString
                ) {
                    resetSearchQuery();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutsideUnitInput);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideUnitInput);
        };
    }, [displayApplianceList, damageNotificationStore, resetSearchQuery, ticketStore.currentTicket?.isUnitSelected]);

    const updateTicketStoreWithSelectedAppliance = useCallback(
        async (appliance?: IApplianceData) => {
            if (ticketStore.currentTicket) {
                ticketStore.currentTicket.updateProperty(TICKET_PROPERTY.APPLIANCE, appliance);
            }
        },
        [ticketStore.currentTicket]
    );

    const onSelectApplianceItem = useCallback(
        (activeListItemIndex: number) => {
            const selectedApplianceItem = damageNotificationStore.filteredApplianceData[activeListItemIndex];

            updateTicketStoreWithSelectedAppliance(selectedApplianceItem);
        },
        [damageNotificationStore.filteredApplianceData, updateTicketStoreWithSelectedAppliance]
    );

    const { onKeyDownFunction, setActiveListItemIndex, NO_LIST_ITEM_SELECTED } = useSelectionListKeyHandler({
        listName: "appliance",
        totalNumberOfDisplayedItems: ticketStore.numberOfDisplayedItems,
        setIsListDisplayed: setDisplayApplianceList,
        isListDisplayed: displayApplianceList,
        onSelectItem: onSelectApplianceItem,
        resetSearchQuery: resetSearchQuery
    });

    const resetAmountOfItemsDisplayedInApplianceList = useCallback(() => {
        setLastSrollYPositionUnitItems(0);
        ticketStore.setNumberOfDisplayedItems(ticketStore.sliceSizeOfDisplayedItems);
    }, [ticketStore]);

    const onChangeApplianceInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            resetAmountOfItemsDisplayedInApplianceList();

            const inputAppliance = e.target.value;
            damageNotificationStore.setCurrentApplianceSearchQuery(inputAppliance);
        },
        [damageNotificationStore, resetAmountOfItemsDisplayedInApplianceList]
    );

    const onFocusApplianceInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (ticketStore.currentTicket?.isApplianceSelected && applianceInputRef.current) {
                applianceInputRef.current.select();
            }

            resetAmountOfItemsDisplayedInApplianceList();
            setDisplayApplianceList(true);
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
        },
        [
            resetAmountOfItemsDisplayedInApplianceList,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            ticketStore.currentTicket?.isApplianceSelected
        ]
    );

    const onClickApplianceItem = useCallback(
        (applianceId: string) => async (e: any) => {
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
            setDisplayApplianceList(false);

            const selectedApplianceItem = damageNotificationStore.filteredApplianceData.find((appliance) => {
                return appliance.id === applianceId;
            });

            updateTicketStoreWithSelectedAppliance(selectedApplianceItem);
        },
        [
            updateTicketStoreWithSelectedAppliance,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            damageNotificationStore.filteredApplianceData
        ]
    );

    const onScrollApplianceUnitList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYUnitItems;

            const hasMoreResulsToDisplay =
                ticketStore.numberOfDisplayedItems < damageNotificationStore.filteredApplianceData.length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                ticketStore.setNumberOfDisplayedItems(
                    ticketStore.numberOfDisplayedItems + ticketStore.sliceSizeOfDisplayedItems
                );
            }

            setLastSrollYPositionUnitItems(pixelsFromTop);
        },
        [lastScrollPositionYUnitItems, ticketStore, damageNotificationStore]
    );

    const getSpanElementWithFormattedText = useCallback(
        (stringToFormat: string) => {
            const formattedString =
                damageNotificationStore.currentApplianceSearchQuery !== ""
                    ? boldenMatchedSubstring(
                          damageNotificationStore.currentApplianceSearchQuery ?? "",
                          stringToFormat,
                          "highlighted-text appliance"
                      )
                    : stringToFormat;

            return (
                <span
                    className="appliance-list-item-span"
                    dangerouslySetInnerHTML={{
                        __html: formattedString
                    }}
                />
            );
        },
        [damageNotificationStore.currentApplianceSearchQuery]
    );

    const applianceSelectionList = (
        <ul id="appliance-dropdown-list" className={"dropdown-list appliance"} onScroll={onScrollApplianceUnitList}>
            {damageNotificationStore.filteredApplianceData
                .slice(0, ticketStore.numberOfDisplayedItems)
                .map((appliance, index) => {
                    const formattedApplianceNameSpan = getSpanElementWithFormattedText(appliance.name ?? "");
                    const formattedApplianceManufacturerSpan = getSpanElementWithFormattedText(
                        appliance.manufacturer ?? ""
                    );
                    const formattedApplianceModelSpan = getSpanElementWithFormattedText(appliance.model ?? "");
                    const formattedApplianceSerialnumberSpan = getSpanElementWithFormattedText(
                        appliance.serialnumber ?? ""
                    );

                    return (
                        <li key={index}>
                            <div
                                className={"list-item appliance"}
                                id={`appliance-list-item-${index}`}
                                onClick={onClickApplianceItem(appliance.id)}
                            >
                                <div className={"appliance-list-item-line-1"}>{formattedApplianceNameSpan}</div>
                                <div className={"appliance-list-item-line-2"}>
                                    {formattedApplianceManufacturerSpan}
                                    {(appliance.manufacturer && appliance.model) ||
                                    (appliance.manufacturer && appliance.serialnumber)
                                        ? ", "
                                        : ""}
                                    {formattedApplianceModelSpan}
                                    {appliance.model && appliance.serialnumber ? ", " : ""}
                                    {formattedApplianceSerialnumberSpan}
                                </div>
                            </div>
                        </li>
                    );
                })}
        </ul>
    );

    const toggleIsApplianceSearchedFromMasterdata = useCallback(() => {
        const isApplianceSearchedFromMasterdata = damageNotificationStore.isApplianceSearchedFromMasterdata;

        if (isApplianceSearchedFromMasterdata) {
            damageNotificationStore.resetApplianceSelection();
        } else {
            damageNotificationStore.resetComponentSelection();
        }
        damageNotificationStore.setIsApplianceSearchedFromMasterdata(!isApplianceSearchedFromMasterdata);
    }, [damageNotificationStore]);

    const onClickComponentTreeElement = useCallback(
        (componentId: string, componentTreeRow: number) => (e: any) => {
            damageNotificationStore.handleClickOnComponentTreeElement(componentId, componentTreeRow);
        },
        [damageNotificationStore]
    );

    const onClickEditComponentTree = useCallback(() => {
        damageNotificationStore.handleEditComponentTreeAfterFinishedSelection();
    }, [damageNotificationStore]);

    const onClickEditApplianceSelection = useCallback(() => {
        ticketStore.currentTicket?.updateProperty(TICKET_PROPERTY.APPLIANCE, undefined);

        setFocus("ticket-form-appliance-input");
    }, [ticketStore.currentTicket, setFocus]);

    const componentTree = (
        <>
            <div className={styles.ComponentTreeContainer}>
                {damageNotificationStore.formComponentTreeData.map((componentRow, index) => {
                    return (
                        <div
                            key={`component-row-${index}`}
                            className={`${styles.ComponentTreeRowContainer} ${
                                ticketStore.isEditing ? "" : styles.ViewOnly
                            }`}
                        >
                            {componentRow.map((component) => {
                                const componentTreeElement = (
                                    <div
                                        key={component.id}
                                        className={`${styles.ComponentTreeRowElement} ${
                                            component.isSelected ? styles.Selected : ""
                                        } ${
                                            component.childComponentsIds && component.childComponentsIds.length > 0
                                                ? styles.HasChildren
                                                : ""
                                        }
                        ${
                            damageNotificationStore.isComponentSelectionFinished
                                ? styles.ComponentSelectionFinished
                                : ""
                        }`}
                                        onClick={onClickComponentTreeElement(component.id, index)}
                                    >
                                        {component.title}
                                    </div>
                                );

                                return damageNotificationStore.isComponentSelectionFinished
                                    ? component.isSelected
                                        ? componentTreeElement
                                        : undefined
                                    : componentTreeElement;
                            })}
                        </div>
                    );
                })}
                {damageNotificationStore.isComponentSelectionFinished && ticketStore.isEditing ? (
                    <div
                        key={"component-tree-edit-button-container"}
                        className={`${styles.ComponentTreeRowElement} ${styles.EditButton}`}
                        onClick={onClickEditComponentTree}
                    >
                        {t("screens.tickets.form.edit").toString()}
                    </div>
                ) : undefined}
            </div>
            {ticketStore.currentTicket?.errors.damageSelectComponent ? (
                <div className={styles.Error}>{ticketStore.currentTicket?.errors.damageSelectComponent}</div>
            ) : undefined}
        </>
    );

    const displayApplianceSearchInput =
        damageNotificationStore.applianceData.length > 0 &&
        damageNotificationStore.isApplianceSearchedFromMasterdata &&
        !damageNotificationStore.isApplianceSelectionFromMasterdataFinished;

    const renderSelectedApplianceFromMasterdata = useCallback(() => {
        const selectedAppliance = ticketStore.currentTicket?.appliance;
        let selectedApplianceElement = undefined;

        if (selectedAppliance) {
            selectedApplianceElement = (
                <div className={"selected-appliance-from-masterdata-container"}>
                    <div className={"selected-appliance-line-1"}>{selectedAppliance.name}</div>
                    <div className={"selected-appliance-line-2"}>
                        {selectedAppliance.manufacturer}
                        {(selectedAppliance.manufacturer && selectedAppliance.model) ||
                        (selectedAppliance.manufacturer && selectedAppliance.serialnumber)
                            ? ", "
                            : ""}
                        {selectedAppliance.model}
                        {selectedAppliance.model && selectedAppliance.serialnumber ? ", " : ""}
                        {selectedAppliance.serialnumber}
                    </div>
                </div>
            );
        }

        return selectedApplianceElement;
    }, [ticketStore.currentTicket?.appliance]);

    const onChangeDamageNotificationField = useCallback(
        (damageNotificationProperty: DAMAGE_NOTIFICATION_PROPERTY) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;

            if (ticketStore.currentTicket) {
                ticketStore.currentTicket.updateDamageNotificationProperty(damageNotificationProperty, newValue);
            }
        },
        [ticketStore.currentTicket]
    );

    const onBlurDescriptionField = useCallback(
        (e: any) => {
            ticketStore.currentTicket?.validateDamageNotificationDetails();
        },
        [ticketStore.currentTicket]
    );

    const updateTicketImageProperty = useCallback(
        (damageNotificationProperty: DAMAGE_NOTIFICATION_PROPERTY) => (newValue: any, fileId?: string) => {
            if (ticketStore.currentTicket) {
                ticketStore.currentTicket.updateDamageNotificationProperty(
                    damageNotificationProperty,
                    newValue,
                    fileId
                );
            }
        },
        [ticketStore.currentTicket]
    );

    const images = ticketStore.currentTicket?.damageNotificationDetails?.images || [];
    const imagesToRender = [...images, null].slice(0, 8); // Limit to 8 items

    const damageNotificationDetails = (
        <div className="p-2">
            {/* OTHER COMPONENT ROW */}
            {ticketStore.currentTicket?.damageNotificationDetails?.isOtherComponentSelected ? (
                <UI.Row>
                    <UI.Col>
                        <UI.Input
                            label={`${t("screens.tickets.form.notification.damage.other_component_name").toString()} *`}
                            type="text"
                            autoComplete="off"
                            value={
                                ticketStore.currentTicket && ticketStore.currentTicket.damageNotificationDetails
                                    ? ticketStore.currentTicket.damageNotificationDetails?.otherComponentName ?? ""
                                    : ""
                            }
                            onChange={onChangeDamageNotificationField(
                                DAMAGE_NOTIFICATION_PROPERTY.OTHER_COMPONENT_NAME
                            )}
                            onBlur={onBlurDescriptionField}
                            errorMsg={ticketStore.currentTicket?.errors.keyOrderOtherKeyInscription ?? undefined}
                            disabled={!ticketStore.isEditing}
                        />
                    </UI.Col>
                </UI.Row>
            ) : undefined}

            {/* DESCRITION ROW */}
            <UI.Row>
                <UI.Col>
                    {ticketStore.isEditing ? (
                        <UI.Input
                            label={`${t("screens.tickets.form.notification.description").toString()} *`}
                            type="text"
                            as="textarea"
                            autoComplete="off"
                            value={
                                ticketStore.currentTicket && ticketStore.currentTicket.damageNotificationDetails
                                    ? ticketStore.currentTicket.damageNotificationDetails?.description ?? ""
                                    : ""
                            }
                            onChange={onChangeDamageNotificationField(DAMAGE_NOTIFICATION_PROPERTY.DESCRIPTION)}
                            onBlur={onBlurDescriptionField}
                            errorMsg={ticketStore.currentTicket?.errors.damageDescription ?? undefined}
                            disabled={!ticketStore.isEditing}
                        />
                    ) : (
                        <>
                            <div className="title">{t("screens.tickets.form.notification.description")}</div>
                            <div className="mb-2 mt-3 pl-0">
                                {ticketStore.currentTicket && ticketStore.currentTicket.damageNotificationDetails
                                    ? ticketStore.currentTicket.damageNotificationDetails?.description ?? ""
                                    : ""}
                            </div>
                        </>
                    )}
                </UI.Col>
            </UI.Row>

            {/* MANUFACTURER & SERIALNUMBER ROW */}
            <UI.Row>
                {ticketStore.currentTicket?.damageNotificationDetails?.hasManufacturer ||
                damageNotificationStore.isApplianceSelectionFromMasterdataFinished ? (
                    <UI.Col lg={6}>
                        <UI.Input
                            label={t("screens.tickets.form.notification.manufacturer").toString()}
                            type="text"
                            autoComplete="off"
                            value={
                                ticketStore.currentTicket && ticketStore.currentTicket.damageNotificationDetails
                                    ? ticketStore.currentTicket.damageNotificationDetails?.manufacturer ?? ""
                                    : ""
                            }
                            onChange={onChangeDamageNotificationField(DAMAGE_NOTIFICATION_PROPERTY.MANUFACTURER)}
                            disabled={!ticketStore.isEditing}
                        />
                    </UI.Col>
                ) : undefined}

                {ticketStore.currentTicket?.damageNotificationDetails?.hasSerialNumber ||
                damageNotificationStore.isApplianceSelectionFromMasterdataFinished ? (
                    <UI.Col lg={6}>
                        <UI.Input
                            label={t("screens.tickets.form.notification.serialnumber").toString()}
                            type="text"
                            autoComplete="off"
                            value={
                                ticketStore.currentTicket && ticketStore.currentTicket.damageNotificationDetails
                                    ? ticketStore.currentTicket.damageNotificationDetails?.serialnumber ?? ""
                                    : ""
                            }
                            onChange={onChangeDamageNotificationField(DAMAGE_NOTIFICATION_PROPERTY.SERIALNUMBER)}
                            disabled={!ticketStore.isEditing}
                        />
                    </UI.Col>
                ) : undefined}
            </UI.Row>

            {/* DAMAGE IMAGES ROW */}
            <UI.Row>
                <UI.Col>
                    <div>
                        <span className={styles.ImagesLabel}>{t("screens.tickets.form.notification.images")}</span>
                        <ImageSelectorContainer>
                            {imagesToRender.map((image, index) => (
                                <ImageSelectorItem
                                    key={index}
                                    label={`${t("screens.tickets.form.notification.damage_image")} ${index + 1}`}
                                    imageType={IMAGE_TYPE.DAMAGE}
                                    image={image || {}}
                                    updateTicketImagePropertyFunction={updateTicketImageProperty(
                                        DAMAGE_NOTIFICATION_PROPERTY.IMAGES
                                    )}
                                    disabled={!ticketStore.isEditing}
                                />
                            ))}
                            {ticketStore.currentTicket?.damageNotificationDetails?.hasSerialNumber ||
                            damageNotificationStore.isApplianceSelectionFromMasterdataFinished ? (
                                <ImageSelectorItem
                                    label={`${t("screens.tickets.form.notification.label")}`}
                                    imageType={IMAGE_TYPE.SERIALNUMBER}
                                    image={ticketStore.currentTicket?.damageNotificationDetails?.serialNumberImage}
                                    updateTicketImagePropertyFunction={updateTicketImageProperty(
                                        DAMAGE_NOTIFICATION_PROPERTY.SERIALNUMBER_IMAGE
                                    )}
                                    disabled={!ticketStore.isEditing}
                                />
                            ) : undefined}
                        </ImageSelectorContainer>
                    </div>
                </UI.Col>
            </UI.Row>
        </div>
    );

    return (
        <div className={`${styles.DamageFormContainer} ${!ticketStore.isEditing ? "view-only-container" : ""}`}>
            {damageNotificationStore.applianceData.length > 0 ? (
                <div className={styles.DamageFormFromMasterdataSwitch}>
                    <UI.Switch
                        label={t("screens.tickets.form.notification.damage.masterdata_switch").toString()}
                        labelPosition="after"
                        checked={damageNotificationStore.isApplianceSearchedFromMasterdata}
                        onToggle={toggleIsApplianceSearchedFromMasterdata}
                        disabled={!ticketStore.isEditing}
                    />
                </div>
            ) : undefined}

            <div className={`px-2 mt-3 ${displayApplianceSearchInput ? "" : "d-none"}`}>
                <UI.Input
                    ref={applianceInputRef}
                    type="text"
                    autoComplete="off"
                    className={styles.DamageNotificationInputField}
                    id="ticket-form-appliance-input"
                    value={damageNotificationStore.currentApplianceSearchQuery ?? ""}
                    onChange={onChangeApplianceInput}
                    onFocus={onFocusApplianceInput}
                    onKeyDown={onKeyDownFunction}
                    disabled={!ticketStore.isEditing}
                />
                {displayApplianceList ? applianceSelectionList : undefined}{" "}
            </div>

            {damageNotificationStore.isApplianceSearchedFromMasterdata &&
            damageNotificationStore.isApplianceSelectionFromMasterdataFinished ? (
                <div className={`${styles.SelectedApplianceContainer} px-2 mt-3`}>
                    {renderSelectedApplianceFromMasterdata()}
                    {ticketStore.isEditing ? (
                        <div
                            key={"appliance-selection-edit-button-container"}
                            className={`${styles.ComponentTreeRowElement} ${styles.EditButton}`}
                            onClick={onClickEditApplianceSelection}
                        >
                            {t("screens.tickets.form.edit").toString()}
                        </div>
                    ) : undefined}
                </div>
            ) : undefined}
            {damageNotificationStore.isApplianceSearchedFromMasterdata ? undefined : componentTree}
            {damageNotificationStore.isComponentSelectionFinished ||
            damageNotificationStore.isApplianceSelectionFromMasterdataFinished
                ? damageNotificationDetails
                : undefined}
        </div>
    );
};

export const DamageNotificationFormFields = Sentry.withProfiler(observer(DamageNotificationFormFieldsBase));
