import { useCallback, useContext } from "react";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, WMSTileLayer } from "react-leaflet";
import styles from "./Location.module.css";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { RootStoreContext } from "src/stores/RootStore";

export interface Coordinates {
    lat: number;
    lng: number;
    E: number;
    N: number;
}

interface LocationProps {
    title?: string;
    className?: string;
    coordinates: Coordinates;
}

L.Icon.Default.mergeOptions({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon2x,
    shadowUrl: markerShadow
});

export const Location = (props: LocationProps) => {
    const { t } = useTranslation();
    const { uiStore } = useContext(RootStoreContext);
    const onMapClicked = useCallback(
        (event) => {
            window.open(
                `https://map.geo.admin.ch/?lang=de&topic=ech&bgLayer=ch.swisstopo.swissimage&layers=ch.swisstopo.amtliches-strassenverzeichnis&E=${props.coordinates.E}&N=${props.coordinates.N}&zoom=13&layers_opacity=0.85`,
                "_blank"
            );
        },
        [props.coordinates]
    );

    const onMapCreated = useCallback(
        (map: any) => {
            map.on("click", onMapClicked);
        },
        [onMapClicked]
    );

    return (
        <div>
            <UI.Card
                title={props.title ?? t("screens.realestate.units.location")}
                square={true}
                className={props.className}
            >
                <div className={styles.swisstopomapcontainer}>
                    <MapContainer
                        className={styles.swisstopomap}
                        center={[props.coordinates.lat, props.coordinates.lng]}
                        zoom={18}
                        maxZoom={18}
                        minZoom={13}
                        scrollWheelZoom={false}
                        doubleClickZoom={false}
                        whenCreated={onMapCreated}
                        dragging={uiStore.isMobile ? false : true}
                    >
                        <WMSTileLayer
                            url={"https://wms.geo.admin.ch/?"}
                            layers="ch.swisstopo.swissimage"
                            format="image/jpeg"
                            attribution="<a href='https://www.swisstopo.admin.ch/de/home.html' target='_blank'>Maps by swisstopo</a>"
                            detectRetina={true}
                        />
                        <WMSTileLayer
                            url={"https://wms.geo.admin.ch/?"}
                            layers="ch.swisstopo.amtliches-strassenverzeichnis"
                            format="image/png"
                            transparent={true}
                        />
                        <Marker position={[props.coordinates.lat, props.coordinates.lng]} />
                    </MapContainer>
                </div>
            </UI.Card>
        </div>
    );
};
