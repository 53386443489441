import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_COMMON_REALESTATE_UNITS } from "src/api/realestateUnits";
import { UI, ScreenSize } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { getLang } from "src/utils/i18n";
import {
    CommonRealestateUnits,
    CommonRealestateUnitsVariables,
    CommonRealestateUnits_realestates_houses_units as common_units,
    CommonRealestateUnits_realestates_houses as realestate_houses
} from "src/api/generated/CommonRealestateUnits";
import { selectRoute, Route } from "src/config/routes";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import styles from "./UnitLists.module.css";
import { getTenancyChangeInformation } from "src/utils/TenancyChangeHelper";
import { getCurrencyElement } from "src/utils/Currency";

interface UnitListsProp {
    realestatesId: string;
}

interface DisplayUnitRow {
    number: boolean;
    name: boolean;
    space: boolean;
    netRent: boolean;
    tenantName: boolean;
    valueQuota: boolean;
    grossRent: boolean;
}

const UnitListsBase = (props: UnitListsProp) => {
    const { t } = useTranslation();
    const { authStore, uiStore, navStore } = useContext(RootStoreContext);

    const { loading, error, data } = useQuery<CommonRealestateUnits, CommonRealestateUnitsVariables>(
        GET_COMMON_REALESTATE_UNITS,
        {
            variables: { realestateid: props.realestatesId, lang: getLang() }
        }
    );

    const renderUnitItem = useCallback(
        (displayUnitRow: DisplayUnitRow) => (unit: common_units, screenSize: ScreenSize) => {
            const isCondominium = navStore.isCondominium;

            const tenancy = unit.tenancys_today[0];

            let name: any = "-";
            let netRent: any = "-";
            let link = undefined;
            let rentsqmyear: any = "-";
            let grossrent: any = "-";

            if (tenancy) {
                name = tenancy.vacancy ? t("screens.realestate.units.vacancy") : tenancy.tenantname;
                netRent = getCurrencyElement(tenancy.netrent);
                rentsqmyear = getCurrencyElement(tenancy.rentsqmyear);
                grossrent = getCurrencyElement(tenancy.grossrent);

                link = selectRoute(Route.unit, authStore.user?.role, {
                    realestateid: props.realestatesId,
                    unitid: unit.id
                });
            }

            let listRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={0.3} value={unit.number} />
                            <UI.List.Cell key={"c-2"} colspan={isCondominium ? 1 : 0.8} value={unit.name} />
                            {isCondominium ? (
                                <>
                                    <UI.List.Cell
                                        key={"c-4"}
                                        colspan={0.3}
                                        value={grossrent}
                                        className="text-right pr-3"
                                    />
                                    <UI.List.Cell
                                        key={"c-5"}
                                        colspan={0.3}
                                        value={unit.valuequota ? unit.valuequota : ""}
                                        className="text-right pr-3 text-nowrap"
                                    />
                                </>
                            ) : (
                                <>
                                    <UI.List.Cell
                                        key={"c-3"}
                                        colspan={0.5}
                                        value={netRent}
                                        className="text-right pr-3 text-nowrap"
                                    />
                                    <UI.List.Cell
                                        key={"c-4"}
                                        colspan={0.3}
                                        value={unit.space ? unit.space + "m²" : ""}
                                        className="text-right pr-3"
                                    />
                                    <UI.List.Cell
                                        key={"c-5"}
                                        colspan={0.75}
                                        value={rentsqmyear}
                                        className="text-right pr-3 text-nowrap"
                                    />
                                </>
                            )}

                            <UI.List.Cell key={"c-6"} colspan={1.2} value={name} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={0.2} value={unit.number} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={unit.name} />
                        </UI.List.Row>,
                        <UI.List.Row key={"r-2"}>
                            <UI.List.Cell key={"c-5"} colspan={1} value={name} className="sublabel" />
                        </UI.List.Row>
                    ];
                    break;
            }

            const tenancyChangeIconInformation = getTenancyChangeInformation(unit);

            let toolTip = undefined;
            let tenancyChangeIcon = undefined;

            if (tenancyChangeIconInformation) {
                toolTip = (
                    <UI.Tooltip id={`${unit.id}-tooltip`}>
                        {t(tenancyChangeIconInformation.descriptionTranslationPath)}
                    </UI.Tooltip>
                );
                tenancyChangeIcon = (
                    <UI.OverlayTrigger key={`${unit.id}-tooltip`} placement="left" overlay={toolTip}>
                        <UI.BootstrapButton variant="link">
                            <img
                                src={tenancyChangeIconInformation.iconFilePath}
                                alt={t(tenancyChangeIconInformation.descriptionTranslationPath)}
                                style={{ width: 25 }}
                            />
                        </UI.BootstrapButton>
                    </UI.OverlayTrigger>
                );
            }

            return (
                <UI.List.Item
                    key={unit.id}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                    icon={tenancyChangeIconInformation !== undefined ? tenancyChangeIcon : undefined}
                    iconInListWithLinks={true}
                />
            );
        },
        [authStore.user?.role, props.realestatesId, t, navStore.isCondominium]
    );

    const renderUnitListHeader = useCallback(
        (utilizationTypeTabel?: string | null) => {
            const renderHeader = (displayUnitRow: DisplayUnitRow) => (screenSize: ScreenSize) => {
                let headerRows: React.ReactElement[] = [];

                const isCondominium = navStore.isCondominium;

                const number = t("screens.realestate.units.number");
                const space = t("screens.realestate.units.space");
                const rent = t("screens.unit.netrent");
                const tenant = isCondominium ? t("screens.owners.title") : t("screens.realestate.units.tenant");
                const rentsqmyear = t("screens.realestate.units.rentsqmyear");
                const budget = t("screens.realestate.units.grossrent");
                const valuequota = t("screens.realestate.units.valuequota");

                switch (screenSize) {
                    case ScreenSize.DESKTOP:
                        headerRows = [
                            <UI.List.Row key={"row-1"}>
                                <UI.List.Cell key={"c-1"} colspan={0.3} value={number} />
                                <UI.List.Cell
                                    key={"c-2"}
                                    colspan={isCondominium ? 1 : 0.8}
                                    value={utilizationTypeTabel}
                                />
                                {isCondominium ? (
                                    <>
                                        <UI.List.Cell
                                            key={"c-4"}
                                            colspan={0.3}
                                            value={displayUnitRow.grossRent ? budget : ""}
                                            className="text-right pr-3"
                                        />
                                        <UI.List.Cell
                                            key={"c-5"}
                                            colspan={0.3}
                                            value={displayUnitRow.valueQuota ? valuequota : ""}
                                            className="text-right pr-3 text-nowrap"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <UI.List.Cell
                                            key={"c-3"}
                                            colspan={0.5}
                                            value={displayUnitRow.netRent ? rent : ""}
                                            className="text-right pr-3 text-nowrap"
                                        />
                                        <UI.List.Cell
                                            key={"c-4"}
                                            colspan={0.3}
                                            value={displayUnitRow.space ? space : ""}
                                            className="text-right pr-3"
                                        />
                                        <UI.List.Cell
                                            key={"c-5"}
                                            colspan={0.75}
                                            value={displayUnitRow.space ? rentsqmyear : ""}
                                            className="text-right pr-3 text-nowrap"
                                        />
                                    </>
                                )}

                                <UI.List.Cell key={"c-6"} colspan={1.2} value={tenant} />
                            </UI.List.Row>
                        ];
                        break;
                    case ScreenSize.MOBILE:
                        headerRows = [
                            <UI.List.Row key={"row-1"}>
                                <UI.List.Cell key={"c-1"} colspan={1} value={utilizationTypeTabel} />
                            </UI.List.Row>
                        ];
                        break;
                }

                return <UI.List.Header rows={headerRows} />;
            };

            return renderHeader;
        },
        [t, navStore.isCondominium]
    );

    if (loading) {
        return <UI.LoadingCard />;
    }
    if (error) {
        return <UI.ErrorCard message={error.message} networkError={error.networkError} />;
    }
    if (!(data && data.realestates)) {
        return <UI.ErrorCard message={t("error.nodata")} />;
    }
    if (data.realestates.length > 0 && data.realestates[0]?.houses.length === 0) {
        return (
            <UI.Card flex>
                <p className="pt-3 text-center">{t("screens.realestate.units.no-tenancys")}</p>
            </UI.Card>
        );
    }

    const utilizationTypes = new Map();

    data.enumerations.forEach((enumeration) => {
        if (enumeration.key) {
            utilizationTypes.set(enumeration.key, {
                label: enumeration.label,
                renderHeaderFunction: renderUnitListHeader(enumeration.label)
            });
        }
    });

    const houses: {
        details: realestate_houses;
        unitsByUtilization: any[];
    }[] = data.realestates[0].houses.map((house) => {
        const houses: any = {};

        const unitsByUtilization: any = {};

        house.units.forEach((unit) => {
            const tenancy = unit.tenancys_today[0];

            if (unit.utilization) {
                if (unitsByUtilization[unit.utilization]) {
                    unitsByUtilization[unit.utilization].units.push(unit);
                    if (unit.space) {
                        unitsByUtilization[unit.utilization].atLeastOneUnitHasSpaceValue = true;
                    }
                    if (tenancy?.netrent) {
                        unitsByUtilization[unit.utilization].atLeastOneUnitHasNetrentValue = true;
                    }
                    if (unit.valuequota) {
                        unitsByUtilization[unit.utilization].atLeastOneUnitHasValueQuota = true;
                    }
                    if (tenancy?.grossrent) {
                        unitsByUtilization[unit.utilization].atLeastOneUnitHasGrossRent = true;
                    }
                } else {
                    unitsByUtilization[unit.utilization] = {
                        units: new Array(unit),
                        atLeastOneUnitHasSpaceValue: unit.space ? true : false,
                        atLeastOneUnitHasNetrentValue: tenancy?.netrent ? true : false,
                        atLeastOneUnitHasValueQuota: unit.valuequota ? true : false,
                        atLeastOneUnitHasGrossRent: tenancy?.grossrent ? true : false
                    };
                }
            }
        });

        houses["unitsByUtilization"] = unitsByUtilization;
        houses["details"] = house;

        return houses;
    });

    const houseLists: any[] = houses.map((house) => {
        const houseLists: any = [];

        for (const [utilizationNumber, unitsByUtilization] of Object.entries(house.unitsByUtilization)) {
            const utilizationTypeNumber: number = parseInt(utilizationNumber);
            const unitItems: common_units[] = unitsByUtilization.units;
            const atLeastOneUnitHasSpaceValue = unitsByUtilization.atLeastOneUnitHasSpaceValue;
            const atLeastOneUnitHasNetrentValue = unitsByUtilization.atLeastOneUnitHasNetrentValue;
            const atLeastOneUnitHasValueQuota = unitsByUtilization.atLeastOneUnitHasValueQuota;
            const atLeastOneUnitHasGrossRent = unitsByUtilization.atLeastOneUnitHasGrossRent;

            const className = houseLists.length >= 1 ? "mt-4" : "";

            const utilizationType = utilizationTypes.get(utilizationTypeNumber);

            const displayUnitRow: DisplayUnitRow = {
                number: true,
                name: true,
                space: atLeastOneUnitHasSpaceValue,
                netRent: atLeastOneUnitHasNetrentValue,
                tenantName: true,
                valueQuota: atLeastOneUnitHasValueQuota,
                grossRent: atLeastOneUnitHasGrossRent
            };

            const renderHeaderFunction = utilizationType["renderHeaderFunction"];

            houseLists.push(
                <UI.List.BasicList
                    key={`${house.details.id}-${utilizationTypeNumber}`}
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={unitItems}
                    renderItem={renderUnitItem(displayUnitRow)}
                    renderHeader={utilizationType ? renderHeaderFunction(displayUnitRow) : undefined}
                    className={className}
                />
            );
        }

        return (
            <UI.Card
                key={house.details.id}
                title={`${house.details.street ?? ""} ${house.details.housenumber ?? ""}`}
                className={`${styles.UnitListCard}`}
            >
                {houseLists.length > 0 ? (
                    houseLists
                ) : (
                    <span className={`${styles.ErrorMessage} pt-3`}>{t("screens.realestate.units.no-units")}</span>
                )}
            </UI.Card>
        );
    });

    return <React.Fragment>{houseLists}</React.Fragment>;
};

export const UnitLists = Sentry.withProfiler(observer(UnitListsBase));
