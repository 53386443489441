import React, { useContext } from "react";
import { UI } from "@wwimmo/ui";
import "./AppbarButtons.css";
import { withRouter, RouterProps } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import { ColorStyle } from "src/utils/Colors";

export interface AppbarButtonProps {
    svgIcon: React.ReactNode;
    to?: string;
    onClick?: any;
    isDisplayed?: boolean;
}
interface AppbarButtonsProps {
    buttons: AppbarButtonProps[];
}

const AppbarButtonsBase = (props: RouterProps & AppbarButtonsProps & any) => {
    const { navStore } = useContext(RootStoreContext);

    const onClickIcon = (button: AppbarButtonProps) => () => {
        if (button.to) {
            navStore.setCloseNavTarget(props.location.pathname);
            props.history.push(button.to);
        }
        if (button.onClick) {
            button.onClick();
        }
    };

    return (
        <div className="AppbarButtons">
            {props.buttons.map((button: AppbarButtonProps, index: number) => {
                let isButtonDisplayed = true;

                if (button.isDisplayed !== undefined && button.isDisplayed === false) {
                    isButtonDisplayed = false;
                }

                return isButtonDisplayed ? (
                    <UI.Icon
                        key={`action-${index}`}
                        onClick={onClickIcon(button)}
                        icon={button.svgIcon}
                        color={ColorStyle("white")}
                        circular={true}
                        backgroundColor={ColorStyle("primary900")}
                    />
                ) : undefined;
            })}
        </div>
    );
};

export const AppbarButtons = withRouter(AppbarButtonsBase);
