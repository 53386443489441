import { useContext, useEffect } from "react";
import { autorun } from "mobx";
import { RootStoreContext } from "src/stores/RootStore";
import { toast } from "react-toastify";
import "./Notifier.css";

export const Notifier = () => {
    const { uiStore } = useContext(RootStoreContext);

    useEffect(() => {
        autorun(() => {
            const { notifications = [], displayedNotifications = [] } = uiStore;

            notifications.forEach((notification) => {
                if (notification.key && displayedNotifications.includes(notification.key)) {
                    return;
                }
                toast(notification.content, notification.options);
                if (notification.key) {
                    uiStore.storeDisplayedNotification(notification.key);
                    uiStore.removeSnackbar(notification);
                }
            });
        });
    }, [uiStore, uiStore.notifications, uiStore.displayedNotifications]);
    return null;
};

export enum MessageType {
    INFO = "info",
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
    DEFAULT = "default"
}
