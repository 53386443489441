import React, { useContext, useCallback, useState, useMemo, useEffect } from "react";
import {
    GetCustomerUsers_customer_users as umsUser,
    GetCustomerUsers_customer_users_userroles,
    GetCustomerUsers_userrolesdefinition as umsUserrolesDefinition
} from "src/api/generated/GetCustomerUsers";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import { dateFormat } from "src/utils/Date";
import styles from "./CustomerUserDetailsList.module.css";
import { v4 as uuidv4 } from "uuid";

interface CustomerUserDetailListProps {
    userrolesDefinition: umsUserrolesDefinition[];
    user: umsUser;
}

const CustomerUserDetailList = (props: CustomerUserDetailListProps) => {
    const { uiStore } = useContext(RootStoreContext);
    const { t } = useTranslation();
    const [displayedUserDetails, setDisplayedUserDetails] = useState<any[]>([]);
    const [activatedAudit, setActivatedAudit] = useState<string>("");
    const [registeredAudit, setRegisteredAudit] = useState<string>("");

    const getDate = useCallback((timestamp: string) => {
        return dateFormat(new Date(`${timestamp}+00:00`), "dd.MM.yyyy HH:mm");
    }, []);

    const formatUserDetailsToTableRows = useCallback(
        (user: umsUser) => {
            const formattedData: { key: string; value: any }[] = [];

            const userName =
                user.name1 === user.name2 ? user.name1 : user.name2 ? `${user.name1} ${user.name2}` : user.name1;

            if (userName) {
                formattedData.push({ key: t("screens.customer_users.name"), value: userName });
            }
            if (user.email) {
                formattedData.push({ key: t("screens.customer_users.email"), value: user.email });
            }
            if (user.registered !== undefined) {
                formattedData.push({ key: t("screens.customer_users.registered"), value: user.registered });
            }
            if (user.registered_audit.length > 0) {
                setRegisteredAudit(getDate(user.registered_audit[0].timestamp));
            }
            if (user.emailverified !== undefined) {
                formattedData.push({ key: t("screens.customer_users.activated"), value: user.emailverified });
            }
            if (user.activated_audit.length > 0) {
                setActivatedAudit(getDate(user.activated_audit[0].timestamp));
            }
            if (user.login_audit.length > 0) {
                formattedData.push({
                    key: t("screens.customer_users.last_login"),
                    value: getDate(user.login_audit[0].timestamp)
                });
            }
            if (user.activated !== undefined && user.registered !== undefined) {
                formattedData.push({
                    key: t("screens.customer_users.blocked"),
                    value: !user.registered ? false : !user.activated
                });
            }
            if (user.userroles.length > 0) {
                formattedData.push({ key: t("screens.customer_users.roles"), value: user.userroles });
            }

            setDisplayedUserDetails(formattedData);
        },
        [getDate, t]
    );

    useEffect(() => {
        formatUserDetailsToTableRows(props.user);
    }, [props.user, formatUserDetailsToTableRows]);

    const RolesMap = useMemo(() => props.userrolesDefinition, [props.userrolesDefinition]);

    const getUserRoles = useCallback(
        (userRoles: GetCustomerUsers_customer_users_userroles[] | undefined) => {
            if (!userRoles || !Array.isArray(userRoles)) {
                return "";
            }

            return userRoles
                .map(({ role }) => {
                    return `${RolesMap.find((m) => m.key === role)?.label}`;
                })
                .join("\n");
        },
        [RolesMap]
    );

    const renderItem = useCallback(
        (user: any, screenSize: ScreenSize) => {
            if (user.key === t("screens.customer_users.roles")) {
                if (user.value.length > 0) {
                    user.value = getUserRoles(user.value);
                } else {
                    user.value = "";
                }
            }

            let listRows: React.ReactElement[] = [];

            const isUserKeyCheckboxRow =
                user.key === t("screens.customer_users.registered") ||
                user.key === t("screens.customer_users.activated") ||
                user.key === t("screens.customer_users.blocked");

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell
                                className={isUserKeyCheckboxRow ? styles.KeyCell : undefined}
                                key={"c-1"}
                                colspan={1}
                                value={user.key ? user.key : ""}
                            />
                            {isUserKeyCheckboxRow ? (
                                <UI.Col className={styles.ColCheckbox}>
                                    <UI.Checkbox name="" disabled={true} checked={user.value} />
                                    {user.value && user.key === t("screens.customer_users.registered") ? (
                                        <div className={styles.DivActivatedAudit}>
                                            {t("screens.customer_users.registered_audit")} {registeredAudit}
                                        </div>
                                    ) : user.value && user.key === t("screens.customer_users.activated") ? (
                                        <div className={styles.DivActivatedAudit}>
                                            {t("screens.customer_users.activated_audit")} {activatedAudit}
                                        </div>
                                    ) : undefined}
                                </UI.Col>
                            ) : (
                                <UI.List.Cell
                                    key={"c-2"}
                                    colspan={2.25}
                                    value={user.value}
                                    className={styles.CustomerUserDetailListCell}
                                />
                            )}
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={0.5} value={user.key ? user.key : ""} />
                            {isUserKeyCheckboxRow ? (
                                <UI.Col className={styles.ColCheckbox}>
                                    <UI.Checkbox name="" disabled={true} checked={user.value} />
                                    {user.value && user.key === t("screens.customer_users.registered") ? (
                                        <div className={styles.DivActivatedAudit}>
                                            {t("screens.customer_users.registered_audit")} {registeredAudit}
                                        </div>
                                    ) : user.value && user.key === t("screens.customer_users.activated") ? (
                                        <div className={styles.DivActivatedAudit}>
                                            {t("screens.customer_users.activated_audit")} {activatedAudit}
                                        </div>
                                    ) : undefined}
                                </UI.Col>
                            ) : (
                                <UI.List.Cell
                                    key={"c-2"}
                                    colspan={1}
                                    value={user.value}
                                    className={styles.CustomerUserDetailListCell}
                                />
                            )}
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Item key={uuidv4()} screenSize={screenSize} rows={listRows} />;
        },
        [getUserRoles, activatedAudit, registeredAudit, t]
    );

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={displayedUserDetails}
            renderItem={renderItem}
        />
    );
};

export default observer(CustomerUserDetailList);
