import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { IRealestate, IRealestateData, TICKET_STATE } from "src/stores/TicketStore";
import { useSelectionListKeyHandler } from "src/hooks/ticket/selection-list-key-handler/useSelectionListKeyHandler";
import { boldenMatchedSubstring } from "src/utils/Common";
import styles from "./RealestateFormField.module.css";
import "./GeneralFormStyles.css";
import { ColorStyle } from "src/utils/Colors";

const RealestateFormFieldBase = () => {
    const { t } = useTranslation();
    const { ticketStore, realestateSearchStore } = useContext(RootStoreContext);

    const [displayRealestateList, setDisplayRealesteateList] = useState<boolean>(false);
    const [lastScrollPositionYRealestateItems, setLastSrollYPositionRealestateItems] = useState<number>(0);

    const realestateInputRef = useRef<HTMLInputElement>(null);

    const resetSearchQuery = useCallback(() => {
        realestateSearchStore.setCurrentRealestateSearchQuery(
            realestateSearchStore.selectedRealestateQueryString ?? ""
        );
    }, [realestateSearchStore]);

    useEffect(() => {
        const handleClickOutsideRealestateInput = (event: any) => {
            if (
                displayRealestateList &&
                realestateInputRef.current &&
                !realestateInputRef.current.contains(event.target) &&
                !(event.target.classList.contains("list-item") && event.target.classList.contains("realestate")) &&
                !(
                    event.target.parentElement.classList.contains("list-item") &&
                    event.target.parentElement.classList.contains("realestate")
                ) &&
                !(event.target.localName === "path" || event.target.localName === "svg") &&
                !(event.target.classList.contains("highlighted-text") && event.target.classList.contains("realestate"))
            ) {
                setDisplayRealesteateList(false);

                if (
                    ticketStore.currentTicket?.isRealestateSelected &&
                    realestateSearchStore.currentRealestateSearchQuery !==
                        realestateSearchStore.selectedRealestateQueryString
                ) {
                    resetSearchQuery();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutsideRealestateInput);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideRealestateInput);
        };
    }, [
        displayRealestateList,
        ticketStore,
        resetSearchQuery,
        realestateSearchStore.currentRealestateSearchQuery,
        realestateSearchStore.selectedRealestateQueryString
    ]);

    const updateTicketStoreWithSelectedRealestate = useCallback(
        async (realestate: IRealestate) => {
            ticketStore.updateFormWithSelectedRealestate(realestate);
        },
        [ticketStore]
    );

    const onSelectRealestateItem = useCallback(
        (activeListItemIndex: number) => {
            const selectedRealestateItem = realestateSearchStore.filteredRealestateData[activeListItemIndex];

            const realestate: IRealestate = {
                id: selectedRealestateItem.id,
                number: selectedRealestateItem.number,
                name: selectedRealestateItem.name,
                zip: selectedRealestateItem.zip,
                city: selectedRealestateItem.city
            };

            updateTicketStoreWithSelectedRealestate(realestate);
        },
        [updateTicketStoreWithSelectedRealestate, realestateSearchStore.filteredRealestateData]
    );

    const { onKeyDownFunction, setActiveListItemIndex, NO_LIST_ITEM_SELECTED } = useSelectionListKeyHandler({
        listName: "realestate",
        totalNumberOfDisplayedItems: ticketStore.numberOfDisplayedItems,
        setIsListDisplayed: setDisplayRealesteateList,
        isListDisplayed: displayRealestateList,
        onSelectItem: onSelectRealestateItem,
        resetSearchQuery: resetSearchQuery
    });

    const resetAmountOfItemsDisplayedInRealestateList = useCallback(() => {
        setLastSrollYPositionRealestateItems(0);
        ticketStore.setNumberOfDisplayedItems(ticketStore.sliceSizeOfDisplayedItems);
    }, [ticketStore]);

    const onChangeRealestateInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            resetAmountOfItemsDisplayedInRealestateList();

            const inputRealestate = e.target.value;
            realestateSearchStore.setCurrentRealestateSearchQuery(inputRealestate);
        },
        [realestateSearchStore, resetAmountOfItemsDisplayedInRealestateList]
    );

    const onFocusRealestateInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (ticketStore.currentTicket?.isRealestateSelected && realestateInputRef.current) {
                realestateInputRef.current.select();
            }

            resetAmountOfItemsDisplayedInRealestateList();
            setDisplayRealesteateList(true);
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
        },
        [
            resetAmountOfItemsDisplayedInRealestateList,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            ticketStore.currentTicket?.isRealestateSelected
        ]
    );

    const onClickRealestateItem = useCallback(
        (realestateData: IRealestateData) => async (e: any) => {
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
            setDisplayRealesteateList(false);

            const realestate: IRealestate = {
                id: realestateData.id,
                number: realestateData.number,
                name: realestateData.name,
                zip: realestateData.zip,
                city: realestateData.city
            };

            updateTicketStoreWithSelectedRealestate(realestate);
        },
        [updateTicketStoreWithSelectedRealestate, setActiveListItemIndex, NO_LIST_ITEM_SELECTED]
    );

    const onScrollRealestateList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYRealestateItems;

            const hasMoreResulsToDisplay =
                ticketStore.numberOfDisplayedItems < realestateSearchStore.filteredRealestateData.length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                ticketStore.setNumberOfDisplayedItems(
                    ticketStore.numberOfDisplayedItems + ticketStore.sliceSizeOfDisplayedItems
                );
            }

            setLastSrollYPositionRealestateItems(pixelsFromTop);
        },
        [lastScrollPositionYRealestateItems, ticketStore, realestateSearchStore.filteredRealestateData.length]
    );

    const realestateSelectionList = (
        <ul id="realestate-dropdown-list" className={"dropdown-list realestate"} onScroll={onScrollRealestateList}>
            {realestateSearchStore.filteredRealestateData
                .slice(0, ticketStore.numberOfDisplayedItems)
                .map((realestate, index) => {
                    const realestateName = `${realestate.nameZipCity} [${realestate.number}]`;

                    const formattedRealestateName = boldenMatchedSubstring(
                        realestateSearchStore.currentRealestateSearchQuery ?? "",
                        realestateName,
                        "highlighted-text realestate"
                    );

                    return (
                        <li
                            key={index}
                            className="d-flex align-items-center list-item realestate"
                            onClick={onClickRealestateItem(realestate)}
                            id={`realestate-list-item-${index}`}
                        >
                            <UI.Icon
                                icon={realestate.consolidation ? UI.SVGIcon.Realestates : UI.SVGIcon.Realestate}
                                size={25}
                                circular
                                color={ColorStyle("grey")}
                                backgroundColor="#D2D5D9"
                                className="realestate-icon"
                            />
                            <div
                                className="realestate-text"
                                dangerouslySetInnerHTML={{
                                    __html: formattedRealestateName
                                }}
                            />
                        </li>
                    );
                })}
        </ul>
    );

    const readOnlyRealestateElement = (
        <div className="view-only-container mt-3 mt-lg-0">
            <div className="title realestate">{t("screens.realestate.title")}</div>
            <div>
                <span className="text-with-number">{ticketStore.currentTicket?.realestate?.name}</span>
                <span>{`${
                    ticketStore.currentTicket?.realestate?.number
                        ? `[${ticketStore.currentTicket?.realestate?.number}]`
                        : ""
                }`}</span>
            </div>

            <div>
                {ticketStore.currentTicket?.realestate?.zip} {ticketStore.currentTicket?.realestate?.city}
            </div>
        </div>
    );

    return (
        <>
            {ticketStore.isEditing ? (
                <>
                    <UI.Input
                        ref={realestateInputRef}
                        label={t("screens.realestate.title").toString()}
                        type="text"
                        as="textarea"
                        autoComplete="off"
                        className={styles.RealestateTextArea}
                        value={realestateSearchStore.currentRealestateSearchQuery ?? ""}
                        onChange={onChangeRealestateInput}
                        onFocus={onFocusRealestateInput}
                        onKeyDown={onKeyDownFunction}
                        disabled={
                            !ticketStore.isEditing ||
                            ticketStore.currentTicket?.state === TICKET_STATE.COMISSIONED ||
                            ticketStore.currentTicket?.state === TICKET_STATE.COMPLETED ||
                            ticketStore.currentTicket?.state === TICKET_STATE.ARCHIVED
                        }
                        errorMsg={ticketStore.currentTicket?.errors.realestate ?? undefined}
                    />
                    {displayRealestateList ? realestateSelectionList : undefined}
                </>
            ) : (
                readOnlyRealestateElement
            )}
        </>
    );
};

export const RealestateFormField = Sentry.withProfiler(observer(RealestateFormFieldBase));
