import i18next from "i18next";
import { AuthStore } from "src/stores/AuthStore";
import { ConfigSettings } from "./NetworkConfig";
import { User } from "./User";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { GetUserRating, GetUserRatingVariables } from "src/api/generated/GetUserRating";
import { GET_USER_RATING } from "src/api/userRating";
import { v4 as uuidv4 } from "uuid";
import crypto from "crypto";
import base64url from "base64url";
import { setCookie } from "src/utils/Cookie";

function info(message: string) {
    // console.log(message);
}

class AuthError extends Error {
    area: string;
    constructor(message: string, area: string) {
        super(message);
        this.name = "AuthError";
        this.area = area;
    }
}

const getErrorMessage = async (response: Response): Promise<string> => {
    try {
        const body = await response.json();
        if (body?.code) {
            return body.code;
        } else if (body?.error) {
            return `${body.error}: ${body.error_description}`;
        } else if (body?.value) {
            return body.value;
        } else if (body) {
            return `${body}`;
        }
        return `${response.status}: ${response.statusText}`;
    } catch (error) {
        return "screens.auth.generic_error";
    }
};

export class AuthClient {
    private config: ConfigSettings;
    private authStore: AuthStore;

    constructor(config: ConfigSettings, authStore: AuthStore) {
        this.config = config;
        this.authStore = authStore;
    }

    initiateLogin() {
        const loginCodeVerifier = uuidv4();
        this.authStore.setLoginCodeVerifier(loginCodeVerifier);

        const hash = crypto.createHash("sha256").update(loginCodeVerifier).digest();
        const loginCodeChallenge = base64url.encode(hash);

        const redirectUri = window.location.host.includes("localhost")
            ? `http://localhost:3000${this.authStore.entryPath}`
            : this.authStore.entryUri;

        this.authStore.setLoginRedirectUri(redirectUri);

        const subdomain = window.location.host.includes("localhost")
            ? this.config.environment
            : window.location.host.split(".")[0];

        const urlInitiateLoginEndpoint = `${this.config.cidaasBaseUrl}${this.config.cidaasAuthorisationServerRessource}?client_id=${this.config.wwPortalClientId}&response_type=code&scopes=${this.authStore.scopes}&redirect_uri=${redirectUri}&code_challenge=${loginCodeChallenge}&code_challenge_method=${this.authStore.loginCodeChallengeMethod}&subdomain=${subdomain}`;

        window.location.href = urlInitiateLoginEndpoint;
    }

    async login(loginVerificationCode: string): Promise<boolean> {
        info("loginCidaas starts");
        this.authStore.setLogin(true);

        const getCidaasAccessTokenRequestBody = {
            grant_type: "authorization_code",
            client_id: this.config.wwPortalClientId,
            code: loginVerificationCode,
            code_verifier: this.authStore.loginCodeVerifier,
            scope: this.authStore.scopes,
            redirect_uri: this.authStore.loginRedirectUri
        };

        const response = await fetch(`${this.config.cidaasBaseUrl}${this.config.cidaasGetTokenRessource}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            },
            body: new URLSearchParams(getCidaasAccessTokenRequestBody)
        });

        info(`login response: ${response.status}: ${response.statusText}`);

        if (response.status === 200) {
            const token = await response.json();

            const user: User = new User();
            await user.init(token.access_token, token.sub, token.identity_id);

            this.authStore.addUser(user, token.access_token, token.refresh_token, token.expires_in);

            setCookie("token", token.access_token, "Strict");

            const result = await apolloClientInstance.query<GetUserRating, GetUserRatingVariables>({
                query: GET_USER_RATING,
                variables: {
                    userid: user.userid
                }
            });

            if (!result.error && result.data.ums_users[0]?.id) {
                this.authStore.rootStore.userRatingModalStore.setRatingState(
                    result.data.ums_users[0].ratingstateportal
                );
            }

            info("loginCidaas successful");
            this.authStore.setLogin(false);
            return Promise.resolve(true);
        } else {
            info("loginCidaas unsuccessful");
            this.authStore.setLogin(false);
            const body = await response.json();

            if (body?.error) {
                info(`--> ${body.error}: ${body.error_description}`);

                if (body.error_description) {
                    return Promise.reject(new AuthError(`${body.error_description}`, "login"));
                } else {
                    return Promise.reject(new AuthError(`${body.error}`, "login"));
                }
            }
            return Promise.reject(new AuthError(`${response.status}: ${response.statusText}`, "login"));
        }
    }

    async relogin(refreshToken: string | undefined): Promise<boolean> {
        if (refreshToken) {
            if (!this.authStore.relogin) {
                info("relogin start");
                this.authStore.setRelogin(true);
                try {
                    await this.refresh(refreshToken);
                    return Promise.resolve(true);
                } catch (error) {
                    info(`relogin unsuccessful: ${error}`);
                    return Promise.reject(new AuthError(`relogin unsuccessful: ${error}`, "relogin"));
                } finally {
                    this.authStore.setRelogin(false);
                }
            }
            info("relogin already in progress");
            return Promise.resolve(false);
        }
        info("relogin omitted because no token available");
        return Promise.resolve(false);
    }

    async refresh(refreshToken: string | undefined): Promise<boolean> {
        // TODO: Cookie hat das refresh-token
        if (refreshToken) {
            info("token refresh starts");
            const response = await fetch(`${this.config.cidaasBaseUrl}${this.config.cidaasGetTokenRessource}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: new URLSearchParams({
                    grant_type: "refresh_token",
                    refresh_token: refreshToken,
                    response_type: this.authStore.refreshTokenResponseType,
                    client_id: this.config.wwPortalClientId,
                    scope: this.authStore.scopes
                })
            });

            if (response.ok) {
                const token = await response.json();
                const user: User = new User();
                if (this.authStore.user) {
                    this.authStore.addUser(
                        this.authStore.user,
                        token.access_token,
                        token.refresh_token,
                        token.expires_in
                    );
                } else {
                    await user.init(token.access_token, token.sub, token.identity_id);
                    this.authStore.addUser(user, token.access_token, token.refresh_token, token.expires_in);
                }

                setCookie("token", token.access_token, "Strict");

                info("token refresh successful");
                return Promise.resolve(true);
            } else {
                info(`"token refresh not successful --> ${response.status}: ${response.statusText}`);
                this.authStore.setRefreshToken(undefined);
                return Promise.reject(
                    new AuthError(
                        `token refresh not successful --> ${response.status}: ${response.statusText}`,
                        "refresh"
                    )
                );
            }
        }
        info("refresh omitted because no refresh-token available");
        return Promise.resolve(false);
    }

    async logout(): Promise<boolean> {
        info("logout starts");

        this.authStore.setLogout(true);

        if (this.authStore.user || this.authStore.token) {
            const username = this.authStore.user?.username;
            if (this.authStore.token) {
                const response = await fetch(
                    `${this.config.cidaasBaseUrl}${this.config.cidaasEndSessionRessource}?access_token_hint=${this.authStore.token}`,
                    {
                        method: "POST"
                    }
                );

                info(`logout response: ${response.status}: ${response.statusText}`);
                if (response.ok) {
                    info(`logout token revocation successful: ${username}`);
                } else {
                    info(`logout token revocation not successful: ${username}`);
                    info(`--> ${response.status}: ${response.statusText}`);
                }
            } else {
                info("revocation omitted because no token available");
            }
            this.authStore.removeUser();
            info(`logout successful: ${username}`);
            this.authStore.setLogout(false);
            return Promise.resolve(true);
        } else {
            info("logout omitted because no user or token available");
            this.authStore.setLogout(false);
            return Promise.resolve(false);
        }
    }

    async passwordChange(password: string, newpassword: string): Promise<boolean> {
        info("password change starts");

        const changePasswordBody = {
            client_id: this.config.wwPortalClientId,
            redirect_url: window.location.origin,
            identityId: this.authStore.user?.identityIdCidaas,
            sub: this.authStore.user?.userIdCidaas,
            old_password: password,
            new_password: newpassword,
            confirm_password: newpassword,
            notify_user: false,
            need_reset_password_on_next_login: false
        };

        const response = await fetch(`${this.config.cidaasBaseUrl}${this.config.cidaasChangePasswordRessource}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.authStore.token}`
            },
            body: JSON.stringify(changePasswordBody)
        });
        info(`password change response: ${response.status}: ${response.statusText}`);

        if (response.ok) {
            info("password change successful");
            return Promise.resolve(true);
        } else {
            info("password change not successful");
            const message = await getErrorMessage(response);
            info(`--> ${message}`);
            return Promise.reject(
                new AuthError(`Beim Ändern des Passworts ist ein Fehler aufgetreten: ${message}`, "password change")
            );
        }
    }

    getLanguageCheck(language: string) {
        if (language !== "de" && language !== "en" && language !== "fr" && language !== "it") {
            language = "en";
        }
        return language;
    }

    async passwordRecovery(username: string): Promise<boolean> {
        info(`password recovery starts: ${username}`);
        const response = await fetch(`${this.config.authority}/account/forgotpasswordapp`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ email: username, language: this.getLanguageCheck(i18next.language.split("-")[0]) })
        });
        info(`password recovery response: ${response.status}: ${response.statusText}`);

        if (response.ok) {
            info(`password recovery successful: ${username}`);
            return Promise.resolve(true);
        } else {
            info(`password recovery not successful: ${username}`);
            const message = await getErrorMessage(response);
            info(`--> ${message}`);
            return Promise.reject(new AuthError(message, "password recovery"));
        }
    }

    async resendActivationEmail(username: string): Promise<boolean> {
        info(`re-send activation email starts: ${username}`);

        const response = await fetch(`${this.config.authority}/account/sendactivationemail`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: username,
                language: this.getLanguageCheck(i18next.language.split("-")[0])
            })
        });
        info(`re-send activation email: ${response.status}: ${response.statusText}`);

        if (response.ok) {
            info(`re-send activation email successful: ${username}`);
            return Promise.resolve(true);
        } else {
            info(`re-send activation email not successful: ${username}`);
            const message = await getErrorMessage(response);
            info(`--> ${message}`);
            return Promise.reject(new AuthError(message, "re-send activation email"));
        }
    }

    async passwordReset(username: string, code: string, newpassword: string): Promise<boolean> {
        info(`password reset starts: ${username}`);
        const response = await fetch(`${this.config.authority}/account/passwordresetapp`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: username,
                code: code,
                newpassword: newpassword
            })
        });
        info(`password reset response: ${response.status}: ${response.statusText}`);

        if (response.ok) {
            info(`password reset successful: ${username}`);
            return Promise.resolve(true);
        } else {
            info(`password reset not successful: ${username}`);
            const message = await getErrorMessage(response);
            info(`--> ${message}`);
            return Promise.reject(new AuthError(message, "password reset"));
        }
    }

    async register(username: string, password: string, registrationcode: string): Promise<boolean> {
        info(`register starts: ${username}`);

        const response = await fetch(`${this.config.authority}/account/registeruserapp`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: username,
                password: password,
                passwordconfirm: password,
                registrationcode: registrationcode,
                language: this.getLanguageCheck(i18next.language.split("-")[0])
            })
        });
        info(`register response: ${response.status}: ${response.statusText}`);

        if (response.ok) {
            info(`register successful: ${username}`);
            return Promise.resolve(true);
        } else {
            info(`register not successful: ${username}`);
            const message = await getErrorMessage(response);
            info(`--> ${message}`);
            return Promise.reject(new AuthError(message, "register"));
        }
    }

    async activate(username: string, code: string): Promise<boolean> {
        info(`activation starts: ${username}`);
        const response = await fetch(`${this.config.authority}/account/activateuserapp`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: username,
                code: code
            })
        });
        info(`activation response: ${response.status}: ${response.statusText}`);

        if (response.ok) {
            info(`activation successful: ${username}`);
            return Promise.resolve(true);
        } else {
            info(`activation not successful: ${username}`);
            const message = await getErrorMessage(response);
            info(`--> ${message}`);
            return Promise.reject(new AuthError(message, "activation"));
        }
    }
}
