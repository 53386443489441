import { observer } from "mobx-react";
import * as Sentry from "@sentry/react";
import { GetServiceDetailsByServiceType_uccm_v_customerservices_tiles as customerservice } from "src/api/generated/GetServiceDetailsByServiceType";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useState, useEffect } from "react";
import { RootStoreContext } from "src/stores/RootStore";
import { UI } from "@wwimmo/ui";
import { startCase } from "lodash";
import {
    SERVICE_ACTIVATION_PROCESS,
    SERVICE_ACTIVATION_PROCESS_STEP
} from "src/stores/services/modals/ServicesActivationProgressModalStore";
import styles from "./ServicesActivateProgressModal.module.css";
import { getDate } from "src/utils/Date";
import { ContactCustomerSuccess } from "src/components";
import contacts from "src/components/contactsales/ContactSales.json";

export enum ContactItemType {
    PHONE,
    MAIL
}
interface ServicesActivateModalProps {
    customerService?: customerservice;
}

const ServicesActivateProgressModalBase = (props: ServicesActivateModalProps) => {
    const { t } = useTranslation();
    const { uiStore, servicesActivationProgressModalStore, navStore, authStore } = useContext(RootStoreContext);
    const {
        setCurrentProcessType,
        setIsActivationProgressModalDisplayed,
        currentStepType,
        currentStep,
        isActivationProgressModalDisplayed,
        ACTIVATION_MODAL_MAX_NUMBER_OF_STEPS,
        activateServices,
        setCostMonth,
        costMonth,
        isServiceSuccessfullyActivated,
        setCurrentStepType
    } = servicesActivationProgressModalStore;
    const { customerService } = props;

    const [isActivationConfirmed, setIsActivationConfirmed] = useState<boolean>(false);

    const onClickStep = useCallback(
        (processType: SERVICE_ACTIVATION_PROCESS) => () => {
            setCurrentProcessType(processType);
        },
        [setCurrentProcessType]
    );

    useEffect(() => {
        setCostMonth();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (currentStepType === SERVICE_ACTIVATION_PROCESS_STEP.SERVICE_ACTIVATION_RESULT) {
            activateServices(customerService?.serviceid);
        }
    }, [currentStepType, activateServices, customerService?.serviceid]);

    const onCloseModal = useCallback(() => {
        setIsActivationProgressModalDisplayed(false);
        setIsActivationConfirmed(false);
        setCurrentStepType(0);
    }, [setIsActivationProgressModalDisplayed, setIsActivationConfirmed, setCurrentStepType]);

    const objectLicence = navStore.customer.licencedunits;

    const currentDate = new Date();
    const trialenddate = new Date(customerService?.trialenddate);

    let dateTestverion: string | undefined;

    if (customerService?.trialenddate === null) {
        const trialmonth = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
        dateTestverion = getDate(trialmonth.toString());
    } else if (trialenddate < currentDate) {
        dateTestverion = t("screens.services.activate_and_deactivate.noTestVersion") || "";
    } else if (trialenddate >= currentDate) {
        dateTestverion = getDate(customerService?.trialenddate);
    }

    const renderCosts = () => {
        return (
            <>
                <UI.Row className={"mt-3 mb-2"}>
                    <UI.Col md={4}>
                        <div className={styles.CostsTitle}>
                            {t("screens.services.costs_and_invoices.overview.costs")}
                        </div>
                    </UI.Col>
                </UI.Row>
                <div>{t("screens.services.activate_and_deactivate.costs_subtitle")}:</div>
                <UI.Row className={"mt-4"}>
                    <UI.Col md={4} className={"mr-3"}>
                        <div>{t("screens.services.activate_and_deactivate.object")}</div>
                        <div className={styles.CostsValue}>{objectLicence}</div>
                    </UI.Col>
                </UI.Row>
                <UI.Row className={"mt-3"}>
                    <UI.Col md={4} className={"mr-3"}>
                        <div>{t("screens.services.activate_and_deactivate.costs_month")}</div>
                        <div className={styles.CurrencyAndCosts}>
                            <div>{t("others.units.chf")}</div>
                            <div>{costMonth}</div>
                        </div>
                    </UI.Col>
                    <UI.Col md={4}>
                        <>
                            <div>{t("screens.services.activate_and_deactivate.free_testversion_until")}</div>
                            <div className={styles.CostsValueWithBackground}>{dateTestverion}</div>
                        </>
                    </UI.Col>
                </UI.Row>
            </>
        );
    };

    const onChangeActivationConfirmed = useCallback(() => {
        isActivationConfirmed ? setIsActivationConfirmed(false) : setIsActivationConfirmed(true);
    }, [isActivationConfirmed, setIsActivationConfirmed]);

    const email =
        navStore.customer.partner === 1 && authStore.user?.erpType === 1
            ? contacts.contactCustomerSuccess[2].email
            : navStore.customer.partner === 1 && authStore.user?.erpType === 2
            ? contacts.contactCustomerSuccess[1].email
            : navStore.customer.partner === 9
            ? contacts.contactCustomerSuccess[0].email
            : "";

    const subject = t("screens.services.activate_and_deactivate.email_subject") + " " + customerService?.name + ".";
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

    const renderAfterSuccessfullyActivated = () => {
        return (
            <>
                <UI.Row className={"mt-2"}>
                    <UI.Col className={styles.ColAfterActivate}>
                        <UI.Icon icon={UI.SVGIcon.Check} color={"black"} size={80} />
                    </UI.Col>
                </UI.Row>
                <UI.Row className={"mt-2"}>
                    <UI.Col className={styles.ColAfterActivate}>
                        <div>{t("screens.services.activate_and_deactivate.successfull_activaion")}</div>
                    </UI.Col>
                </UI.Row>
                <UI.Row className={"mt-2"}>
                    <UI.Col className={styles.ColAfterActivate}>
                        <div>
                            {t("screens.services.activate_and_deactivate.free_testversion")}{" "}
                            {getDate(customerService?.trialenddate)}
                        </div>
                    </UI.Col>
                </UI.Row>
                <UI.Row className={"mt-2"}>
                    <UI.Col md={12} className={styles.ColAfterActivate}>
                        <div
                            className={styles.AfteractivateValue}
                            dangerouslySetInnerHTML={{
                                __html: customerService?.afteractivate ?? ""
                            }}
                        />
                    </UI.Col>
                </UI.Row>
                <UI.Row className={"mt-2"}>
                    <UI.Col md={12} className={styles.ColAfterActivate}>
                        <div>{t("screens.services.activate_and_deactivate.arrange_an_appointment")}</div>
                    </UI.Col>
                    <UI.Col className={styles.ColAfterActivate}>
                        <a className={styles.AppointmentButton} href={mailtoLink}>
                            {t("screens.services.activate_and_deactivate.book_an_appointment")}
                        </a>
                    </UI.Col>
                </UI.Row>
                <UI.Row>
                    <ContactCustomerSuccess />
                </UI.Row>
                <UI.Row className={"mt-2 justify-content-end"}>
                    <UI.Col xs={12} md={4}>
                        <UI.Button
                            className={"d-flex align-self-end ticket-button mt-2"}
                            label={t("labels.finished")}
                            onClick={onCloseModal}
                        ></UI.Button>
                    </UI.Col>
                </UI.Row>
            </>
        );
    };

    const renderStep = () => {
        switch (currentStepType) {
            case SERVICE_ACTIVATION_PROCESS_STEP.START_SERVICE_ACTIVATION:
            default:
                return (
                    <>
                        <UI.Row className={"mt-2"}>
                            <UI.Col>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: customerService?.activate ?? ""
                                    }}
                                />
                            </UI.Col>
                        </UI.Row>
                        {renderCosts()}
                        <UI.Row className={"mt-5"}>
                            <UI.Col xs={12} md={6} className={styles.ColActivationConfirmed}>
                                <UI.Checkbox
                                    className={styles.ActivationConfirmed}
                                    name="activationConfirmed"
                                    label={t("screens.services.activate_and_deactivate.activationConfirmed")}
                                    checked={isActivationConfirmed}
                                    onChange={onChangeActivationConfirmed}
                                />
                            </UI.Col>
                            <UI.Col xs={12} md={3}>
                                <UI.Button
                                    className={`ticket-button mt-2 ${styles.DiscardButton}`}
                                    label={startCase(t("labels.cancel").toString())}
                                    variant={"outline-primary"}
                                    onClick={onCloseModal}
                                ></UI.Button>
                            </UI.Col>
                            <UI.Col xs={12} md={3}>
                                <UI.Button
                                    className={"ticket-button mt-2"}
                                    label={t("screens.services.activate_and_deactivate.activate")}
                                    disabled={!isActivationConfirmed}
                                    onClick={onClickStep(SERVICE_ACTIVATION_PROCESS.SERVICE_ACTIVATION_RESULT)}
                                ></UI.Button>
                            </UI.Col>
                        </UI.Row>
                    </>
                );
            case SERVICE_ACTIVATION_PROCESS_STEP.SERVICE_ACTIVATION_RESULT:
                return (
                    <>
                        {!isServiceSuccessfullyActivated ? (
                            <>
                                <UI.Row className={styles.DivImageLoadSpinner}>
                                    <div>
                                        <UI.RotatingSpinner noLogo size={50} className={styles.ImageLoadSpinner} />
                                        <div>
                                            {customerService?.name}{" "}
                                            {t("screens.services.activate_and_deactivate.will_be_activated")}...
                                        </div>
                                    </div>
                                </UI.Row>
                                <UI.Row className={"mt-2 justify-content-end"}>
                                    <UI.Col xs={12} md={3}>
                                        <UI.Button
                                            className={`ticket-button mt-2 ${styles.DiscardButton}`}
                                            label={startCase(t("labels.cancel").toString())}
                                            variant={"outline-primary"}
                                            onClick={onCloseModal}
                                        ></UI.Button>
                                    </UI.Col>
                                </UI.Row>
                            </>
                        ) : (
                            <>{renderAfterSuccessfullyActivated()}</>
                        )}
                    </>
                );
        }
    };

    return (
        <UI.ProgressModal
            currentStep={currentStep?.stepNumber ?? 1}
            totalSteps={ACTIVATION_MODAL_MAX_NUMBER_OF_STEPS}
            title={`${customerService?.name} ${t("screens.services.activate_and_deactivate.activate_small")}`}
            show={isActivationProgressModalDisplayed}
            onDismissModal={onCloseModal}
            centered={uiStore.isMobile ? true : false}
            size={uiStore.isMobile ? undefined : "lg"}
        >
            {renderStep()}
        </UI.ProgressModal>
    );
};

export const ServicesActivateProgressModal = Sentry.withProfiler(observer(ServicesActivateProgressModalBase));
