import React, { useCallback, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { MessageType } from "src/components/notifications/Notifier";
import { GetCustomerUsers_customer_users as umsUser } from "src/api/generated/GetCustomerUsers";
import styles from "./CustomerUserResetButton.module.css";
import { getRoleKey } from "src/network/User";
import { NetworkConfig } from "src/network/NetworkConfig";

interface UserResetButtonProps {
    user: umsUser;
    userid: string;
}

const CustomerUserResetButtonBase = (props: UserResetButtonProps) => {
    const { t } = useTranslation();
    const { uiStore, authStore } = useContext(RootStoreContext);
    const [isUserResetModalDisplaying, setIsUserResetModalDisplaying] = useState(false);
    const [userIsReseting, setUserIsReseting] = useState(false);

    const resetUserStatus = useCallback(async () => {
        const user = props.user;

        if (authStore.user && user) {
            const accessToken = authStore.token;
            const tokenType = "Bearer";
            const role = authStore.user?.role;

            const requestBody = {
                action: "RESET",
                registrationcode: user.registrationcode,
                email: user.email ?? "",
                userid: user.userid,
                customerid: user.customerid
            };

            try {
                const fetchResult = await fetch(`${NetworkConfig.updateUserStatusUrl}`, {
                    method: "POST",
                    body: JSON.stringify(requestBody),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    uiStore.printStatusMessage(t("screens.customer_users.user_is_reset"), MessageType.INFO);
                } else {
                    const returnValue = await fetchResult.json();

                    uiStore.printStatusMessage(
                        `${t("screens.customer_users.error_reset_user")} ${returnValue.message}`,
                        MessageType.ERROR
                    );
                }
            } catch (fetchError: any) {
                const errorMessage = fetchError.error ?? fetchError.message ?? fetchError;
                const error = `${t("screens.customer_users.error_reset_user")}: ${errorMessage}`;

                uiStore.printStatusMessage(error, MessageType.ERROR);

                console.error(error);
            }
        }
    }, [authStore.token, authStore.user, props.user, uiStore, t]);

    const resetUser = useCallback(async () => {
        if (props.user) {
            setUserIsReseting(true);

            await resetUserStatus();

            uiStore.setIsCustomerUserReset(true);

            setUserIsReseting(true);
            setIsUserResetModalDisplaying(false);
            setUserIsReseting(false);
        }
    }, [props.user, resetUserStatus, uiStore]);

    const toggleResetUserModal = useCallback(() => {
        setIsUserResetModalDisplaying((isDisplaying) => !isDisplaying);
    }, []);

    const isUserRegisterd = !props.user.registered || false;

    return (
        <>
            <UI.Button
                label={t("screens.customer_users.reset")}
                className={isUserRegisterd ? styles.ResetButtonNotActive : styles.ResetButton}
                onClick={toggleResetUserModal}
                disabled={isUserRegisterd}
            />

            <UI.Modal
                backdrop={"static"}
                title={t("screens.customer_users.reset_user").toString()}
                show={isUserResetModalDisplaying}
                onClose={toggleResetUserModal}
            >
                <div>
                    <p>{t("screens.customer_users.question_reset_user")}</p>
                    <p>{t("screens.customer_users.reset_reference")}</p>

                    {userIsReseting ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span>{t("screens.customer_users.user_is_going_to_be_reset")}</span>
                            <UI.RotatingSpinner noLogo={true} size={35} className={"align-items-start"} />
                        </div>
                    ) : (
                        <UI.Button
                            label={t("labels.yes")}
                            disabled={userIsReseting}
                            onClick={resetUser}
                            className={styles.YesButton}
                            variant="danger"
                        />
                    )}
                </div>
            </UI.Modal>
        </>
    );
};

export const CustomerUserResetButton = observer(CustomerUserResetButtonBase);
