import React, { useCallback, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { Switch, withRouter } from "react-router";
import { Header, PrivateRoute } from "src/components/";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { CommonRealestateById, CommonRealestateByIdVariables } from "src/api/generated/CommonRealestateById";
import { GET_COMMON_REALESTATE_BY_ID } from "src/api/realestate";
import { useQuery } from "@apollo/client";
import { Route, getRoute } from "src/config/routes";
import * as Screens from "src/screens";
import { runInAction } from "mobx";
import { Role } from "src/network/User";
import { useRoleChanger } from "src/hooks/role-changer/useRoleChanger";
import { NAVIGATIONBAR_TYPE } from "src/components/header/navigationbar/Navigationbar";
import { GET_COMMON_REALESTATEFEATURE_TENANT } from "src/api/realestates";
import {
    GetRealestateFeatureTenant,
    GetRealestateFeatureTenantVariables
} from "src/api/generated/GetRealestateFeatureTenant";

interface MatchParams {
    realestateid: string;
}

export const RealestateBase = (props: RouteComponentProps<MatchParams>) => {
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);

    const { t } = useTranslation();
    const realestateid = props.match.params.realestateid;
    const role = authStore.user?.role;
    const currentPage = role ? getRoute(props.location.pathname, role) : Route.dashboard;
    const [realestateFeatureTenantExists, setRealestateFeatureTenantExists] = useState<boolean>(false);

    const { loading: loadingById, data: dataById } = useQuery<CommonRealestateById, CommonRealestateByIdVariables>(
        GET_COMMON_REALESTATE_BY_ID,
        {
            variables: {
                realestateid: realestateid
            }
        }
    );

    const { loading: loadingRealestateFeature, data: dataRealestateFeature } = useQuery<
        GetRealestateFeatureTenant,
        GetRealestateFeatureTenantVariables
    >(GET_COMMON_REALESTATEFEATURE_TENANT, {
        variables: {
            realestateid: realestateid
        }
    });

    useEffect(() => {
        if (
            loadingRealestateFeature === false &&
            dataRealestateFeature?.common_realestatefeatures_aggregate.aggregate &&
            dataRealestateFeature?.common_realestatefeatures_aggregate?.aggregate?.count > 0
        ) {
            setRealestateFeatureTenantExists(true);
        } else {
            setRealestateFeatureTenantExists(false);
        }
    }, [dataRealestateFeature, loadingRealestateFeature]);

    const resetRealestateState = useCallback(() => {
        uiStore.setFinanceListSelectionId(undefined);
    }, [uiStore]);

    useRoleChanger();

    useEffect(() => {
        if (loadingById === false && dataById && dataById.common_realestates.length !== 0 && role) {
            if (
                currentPage === Route.dashboard ||
                currentPage === Route.units ||
                currentPage === Route.finances ||
                currentPage === Route.documents ||
                currentPage === Route.coownerAccount ||
                currentPage === Route.coownerAssemblies ||
                currentPage === Route.realestatePersons ||
                currentPage === Route.managerAssemblies ||
                currentPage === Route.managerAssembliesEdit
            ) {
                // Manage Realestates Navigations: Only when current Page is Realestate-Root (dashboard, tenancy, finances or documents)
                const title = dataById.common_realestates[0].name || t("screens.realestate.title");
                navStore.setTitle(title);
            }
        }
        runInAction(() => {
            navStore.setActionButtonMiddle(null);
            navStore.setActionButtonLeft(null);
            navStore.setActionButtonRight(null);
        });
    }, [
        props.match.params.realestateid,
        uiStore,
        navStore,
        role,
        t,
        loadingById,
        dataById,
        currentPage,
        resetRealestateState
    ]);

    const ownerRoutes: React.ReactNode[] = [
        <PrivateRoute
            key={Route.owner + Route.dashboard}
            path={Route.owner + Route.dashboard}
            component={Screens.Dashboard}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestateAppliances}
            path={Route.owner + Route.realestateAppliances}
            exact
            component={Screens.Appliances}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestateAppliance}
            path={Route.owner + Route.realestateAppliance}
            exact
            component={Screens.Appliance}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestateApplianceServiceCompany}
            path={Route.owner + Route.realestateApplianceServiceCompany}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestateMaintenances}
            path={Route.owner + Route.realestateMaintenances}
            exact
            component={Screens.Maintenances}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestatePersons}
            path={Route.owner + Route.realestatePersons}
            exact
            component={Screens.RealestatePersons}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestatePerson}
            path={Route.owner + Route.realestatePerson}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.owner + Route.ownerPerson}
            path={Route.owner + Route.ownerPerson}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.owner + Route.units}
            path={Route.owner + Route.units}
            exact
            component={Screens.Units}
        />,
        <PrivateRoute
            key={Route.owner + Route.finances}
            path={Route.owner + Route.finances}
            exact
            component={Screens.Finances}
        />,
        <PrivateRoute
            key={Route.owner + Route.documents}
            path={Route.owner + Route.documents}
            component={Screens.Documents}
        />,
        <PrivateRoute key={Route.owner + Route.unit} path={Route.owner + Route.unit} exact component={Screens.Unit} />,
        <PrivateRoute
            key={Route.owner + Route.account}
            path={Route.owner + Route.account}
            exact
            component={Screens.Account}
        />,
        <PrivateRoute
            key={Route.owner + Route.unitPerson}
            path={Route.owner + Route.unitPerson}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.owner + Route.unitTenant}
            path={Route.owner + Route.unitTenant}
            exact
            component={Screens.Tenant}
        />,
        <PrivateRoute
            key={Route.owner + Route.unitAppliances}
            path={Route.owner + Route.unitAppliances}
            exact
            component={Screens.Appliances}
        />,
        <PrivateRoute
            key={Route.owner + Route.unitAppliance}
            path={Route.owner + Route.unitAppliance}
            exact
            component={Screens.Appliance}
        />,
        <PrivateRoute
            key={Route.owner + Route.unitApplianceServiceCompany}
            path={Route.owner + Route.unitApplianceServiceCompany}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.owner + Route.unitMaintenances}
            path={Route.owner + Route.unitMaintenances}
            exact
            component={Screens.Maintenances}
        />,
        <PrivateRoute
            key={Route.owner + Route.unitApplianceMaintenances}
            path={Route.owner + Route.unitApplianceMaintenances}
            exact
            component={Screens.Maintenances}
        />,
        <PrivateRoute
            key={Route.owner + Route.unitMaintenance}
            path={Route.owner + Route.unitMaintenance}
            exact
            component={Screens.Maintenance}
        />,
        <PrivateRoute
            key={Route.owner + Route.unitMaintenanceCraftsman}
            path={Route.owner + Route.unitMaintenanceCraftsman}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.owner + Route.unitMaintenanceAccount}
            path={Route.owner + Route.unitMaintenanceAccount}
            exact
            component={Screens.Account}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestateMaintenance}
            path={Route.owner + Route.realestateMaintenance}
            exact
            component={Screens.Maintenance}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestateMaintenanceCraftsman}
            path={Route.owner + Route.realestateMaintenanceCraftsman}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestateMaintenanceAccount}
            path={Route.owner + Route.realestateMaintenanceAccount}
            exact
            component={Screens.Account}
        />,
        <PrivateRoute
            key={Route.owner + Route.unitApplianceMaintenance}
            path={Route.owner + Route.unitApplianceMaintenance}
            exact
            component={Screens.Maintenance}
        />,
        <PrivateRoute
            key={Route.owner + Route.unitApplianceMaintenanceCraftsman}
            path={Route.owner + Route.unitApplianceMaintenanceCraftsman}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.owner + Route.unitApplianceMaintenanceAccount}
            path={Route.owner + Route.unitApplianceMaintenanceAccount}
            exact
            component={Screens.Account}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestateApplianceMaintenance}
            path={Route.owner + Route.realestateApplianceMaintenance}
            exact
            component={Screens.Maintenance}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestateApplianceMaintenanceCraftsman}
            path={Route.owner + Route.realestateApplianceMaintenanceCraftsman}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestateApplianceMaintenanceAccount}
            path={Route.owner + Route.realestateApplianceMaintenanceAccount}
            exact
            component={Screens.Account}
        />,
        <PrivateRoute
            key={Route.owner + Route.realestateApplianceMaintenances}
            path={Route.owner + Route.realestateApplianceMaintenance}
            exact
            component={Screens.Maintenances}
        />
    ];

    const auditorRoutes: React.ReactNode[] = [
        <PrivateRoute
            key={Route.auditor + Route.account}
            path={Route.auditor + Route.account}
            exact
            component={Screens.Account}
        />,
        <PrivateRoute
            key={Route.auditor + Route.finances}
            path={Route.auditor + Route.finances}
            component={Screens.Finances}
        />
    ];

    const coownerRoutes: React.ReactNode[] = [
        <PrivateRoute
            key={Route.coowner + Route.dashboard}
            path={Route.coowner + Route.dashboard}
            component={Screens.CoownerDashboard}
        />,
        <PrivateRoute
            key={Route.coowner + Route.documents}
            path={Route.coowner + Route.documents}
            component={Screens.Documents}
        />,
        <PrivateRoute
            key={Route.coowner + Route.coownerAccount}
            path={Route.coowner + Route.coownerAccount}
            exact
            component={Screens.MyAccounts}
        />,
        <PrivateRoute
            key={Route.coowner + Route.coownerAssemblies}
            path={Route.coowner + Route.coownerAssemblies}
            exact
            component={Screens.CoownerAssemblies}
        />,
        <PrivateRoute
            key={Route.coowner + Route.account}
            path={Route.coowner + Route.account}
            exact
            component={Screens.Account}
        />,
        <PrivateRoute
            key={Route.coowner + Route.finances}
            path={Route.coowner + Route.finances}
            exact
            component={Screens.Finances}
        />
    ];

    const tenantRoutes: React.ReactNode[] = [
        <PrivateRoute
            key={Route.tenant + Route.dashboard}
            path={Route.tenant + Route.dashboard}
            component={Screens.TenantDashboard}
        />,
        <PrivateRoute
            key={Route.tenant + Route.documents}
            path={Route.tenant + Route.documents}
            component={Screens.Documents}
        />,
        <PrivateRoute
            key={Route.tenant + Route.tenantAccount}
            path={Route.tenant + Route.tenantAccount}
            exact
            component={Screens.MyAccounts}
        />,
        <PrivateRoute
            key={Route.tenant + Route.account}
            path={Route.tenant + Route.account}
            exact
            component={Screens.Account}
        />,
        <PrivateRoute
            key={Route.tenant + Route.finances}
            path={Route.tenant + Route.finances}
            exact
            component={Screens.Finances}
        />
    ];

    const managerRoutes: React.ReactNode[] = [
        <PrivateRoute
            key={Route.manager + Route.dashboard}
            path={Route.manager + Route.dashboard}
            component={Screens.Dashboard}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateAppliances}
            path={Route.manager + Route.realestateAppliances}
            exact
            component={Screens.Appliances}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateAppliance}
            path={Route.manager + Route.realestateAppliance}
            exact
            component={Screens.Appliance}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateApplianceServiceCompany}
            path={Route.manager + Route.realestateApplianceServiceCompany}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestatePersons}
            path={Route.manager + Route.realestatePersons}
            exact
            component={Screens.RealestatePersons}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestatePerson}
            path={Route.manager + Route.realestatePerson}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateUsers}
            path={Route.manager + Route.realestateUsers}
            exact
            component={Screens.RealestateUsers}
        />,
        <PrivateRoute
            key={Route.manager + Route.ownerPerson}
            path={Route.manager + Route.ownerPerson}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateMaintenances}
            path={Route.manager + Route.realestateMaintenances}
            exact
            component={Screens.Maintenances}
        />,
        <PrivateRoute
            key={Route.manager + Route.units}
            path={Route.manager + Route.units}
            exact
            component={Screens.Units}
        />,
        <PrivateRoute
            key={Route.manager + Route.finances}
            path={Route.manager + Route.finances}
            exact
            component={Screens.Finances}
        />,
        <PrivateRoute
            key={Route.manager + Route.documents}
            path={Route.manager + Route.documents}
            component={Screens.Documents}
        />,
        <PrivateRoute
            key={Route.manager + Route.unit}
            path={Route.manager + Route.unit}
            exact
            component={Screens.Unit}
        />,
        <PrivateRoute
            key={Route.manager + Route.account}
            path={Route.manager + Route.account}
            exact
            component={Screens.Account}
        />,
        <PrivateRoute
            key={Route.manager + Route.unitPerson}
            path={Route.manager + Route.unitPerson}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.manager + Route.unitTenant}
            path={Route.manager + Route.unitTenant}
            exact
            component={Screens.Tenant}
        />,
        <PrivateRoute
            key={Route.manager + Route.unitAppliances}
            path={Route.manager + Route.unitAppliances}
            exact
            component={Screens.Appliances}
        />,
        <PrivateRoute
            key={Route.manager + Route.unitAppliance}
            path={Route.manager + Route.unitAppliance}
            exact
            component={Screens.Appliance}
        />,
        <PrivateRoute
            key={Route.manager + Route.unitApplianceServiceCompany}
            path={Route.manager + Route.unitApplianceServiceCompany}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.manager + Route.unitMaintenances}
            path={Route.manager + Route.unitMaintenances}
            exact
            component={Screens.Maintenances}
        />,
        <PrivateRoute
            key={Route.manager + Route.unitApplianceMaintenances}
            path={Route.manager + Route.unitApplianceMaintenances}
            exact
            component={Screens.Maintenances}
        />,
        <PrivateRoute
            key={Route.manager + Route.unitMaintenance}
            path={Route.manager + Route.unitMaintenance}
            exact
            component={Screens.Maintenance}
        />,
        <PrivateRoute
            key={Route.manager + Route.unitMaintenanceCraftsman}
            path={Route.manager + Route.unitMaintenanceCraftsman}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.manager + Route.unitMaintenanceAccount}
            path={Route.manager + Route.unitMaintenanceAccount}
            exact
            component={Screens.Account}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateMaintenance}
            path={Route.manager + Route.realestateMaintenance}
            exact
            component={Screens.Maintenance}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateMaintenanceCraftsman}
            path={Route.manager + Route.realestateMaintenanceCraftsman}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateMaintenanceAccount}
            path={Route.manager + Route.realestateMaintenanceAccount}
            exact
            component={Screens.Account}
        />,
        <PrivateRoute
            key={Route.manager + Route.unitApplianceMaintenance}
            path={Route.manager + Route.unitApplianceMaintenance}
            exact
            component={Screens.Maintenance}
        />,
        <PrivateRoute
            key={Route.manager + Route.unitApplianceMaintenanceCraftsman}
            path={Route.manager + Route.unitApplianceMaintenanceCraftsman}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.manager + Route.unitApplianceMaintenanceAccount}
            path={Route.manager + Route.unitApplianceMaintenanceAccount}
            exact
            component={Screens.Account}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateApplianceMaintenance}
            path={Route.manager + Route.realestateApplianceMaintenance}
            exact
            component={Screens.Maintenance}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateApplianceMaintenanceCraftsman}
            path={Route.manager + Route.realestateApplianceMaintenanceCraftsman}
            exact
            component={Screens.Person}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateApplianceMaintenanceAccount}
            path={Route.manager + Route.realestateApplianceMaintenanceAccount}
            exact
            component={Screens.Account}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateApplianceMaintenances}
            path={Route.manager + Route.realestateApplianceMaintenances}
            exact
            component={Screens.Maintenances}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateNewsEdit}
            path={Route.manager + Route.realestateNewsEdit}
            exact
            component={Screens.NewsEdit}
        />,
        <PrivateRoute
            key={Route.manager + Route.managerAssemblies}
            path={Route.manager + Route.managerAssemblies}
            exact
            component={Screens.ManagerAssemblies}
        />,
        <PrivateRoute
            key={Route.manager + Route.managerAssembliesEdit}
            path={Route.manager + Route.managerAssembliesEdit}
            exact
            component={Screens.ManagerAssembliesEdit}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateNewsPreviewEdit}
            path={Route.manager + Route.realestateNewsPreviewEdit}
            exact
            component={Screens.NewsEdit}
        />,
        <PrivateRoute
            key={Route.manager + Route.realestateNewsPreview}
            path={Route.manager + Route.realestateNewsPreview}
            exact
            component={Screens.NewsPreview}
        />,
        <PrivateRoute
            key={Route.manager + Route.news}
            path={Route.manager + Route.news}
            exact
            component={Screens.NewsPage}
        />
    ];

    if (loadingById || loadingRealestateFeature) return <Screens.Loading fullscreen />;
    if (!dataById) return <Screens.Error message={t("error.nodata")} />;

    let isConsolidation = false;
    let isCondominium = false;
    if (dataById && dataById.common_realestates.length !== 0) {
        isConsolidation = dataById?.common_realestates[0].consolidation;
        isCondominium = dataById?.common_realestates[0].condominium;
        navStore.setConsolidationId(dataById?.common_realestates[0].consolidationid);
    }

    navStore.setIsCondominium(isCondominium);
    navStore.setIsConsolidation(isConsolidation);

    if (
        dataById.common_realestates_aggregate.aggregate &&
        dataById.common_realestates_aggregate.aggregate?.count === 1
    ) {
        navStore.setSingleRealEstate(true);
    }

    const visibleRoutes: React.ReactNode[] = [];

    authStore.user?.availablePortalRoles.forEach((availableRole) => {
        switch (availableRole) {
            case Role.MANAGER:
                visibleRoutes.push(...managerRoutes);
                break;
            case Role.OWNER:
                visibleRoutes.push(...ownerRoutes);
                break;
            case Role.COOWNER:
                visibleRoutes.push(...coownerRoutes);
                break;
            case Role.AUDITOR:
                visibleRoutes.push(...auditorRoutes);
                break;
            case Role.TENANT:
                visibleRoutes.push(...tenantRoutes);
                break;
        }
    });

    return (
        <React.Fragment>
            <Header.Navigationbar
                realestateId={props.match.params.realestateid}
                isConsolidation={isConsolidation}
                isCondominium={isCondominium}
                navigationbarType={NAVIGATIONBAR_TYPE.PORTAL}
                isRealestatefeatureTenant={realestateFeatureTenantExists}
            />
            <Switch>
                {visibleRoutes}
                <PrivateRoute component={Screens.ErrorNotFound} />
            </Switch>
        </React.Fragment>
    );
};

export const Realestate = Sentry.withProfiler(withRouter(observer(RealestateBase)));
