import React, { useEffect, useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { RouteComponentProps, withRouter, useParams } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { UI, ScreenSize } from "@wwimmo/ui";
import { GET_APPLIANCE_DETAILS } from "src/api/appliance";
import { GetApplianceDetails, GetApplianceDetailsVariables } from "src/api/generated/GetApplianceDetails";
import { getDate } from "src/utils/Date";
import { Route, selectRoute } from "src/config/routes";
import * as Screens from "src/screens";
import { runInAction } from "mobx";
import { ScreenWithTiles } from "src/components/screenWithTiles/ScreenWithTiles";
import { observer } from "mobx-react-lite";
import { ScreenType } from "src/api/tiles";
import { getName } from "src/utils/PersonName";
import ApplianceMaintenances from "./maintenances/ApplianceMaintenances";
import styles from "./Appliance.module.css";

interface Params {
    realestateid: string;
    unitid: string;
    applianceid: string;
}

interface ApplianceItem {
    label?: string;
    value?: any;
    to?: string;
    className?: string;
    onClick?: any;
}

const ApplianceBase = (props: RouteComponentProps<Params>) => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);

    const { realestateid, unitid, applianceid } = useParams<Params>();

    const { loading, error, data } = useQuery<GetApplianceDetails, GetApplianceDetailsVariables>(
        GET_APPLIANCE_DETAILS,
        {
            variables: { applianceid: applianceid }
        }
    );

    useEffect(() => {
        runInAction(() => {
            navStore.resetActionButtons();

            if (unitid) {
                navStore.setBackbutton(
                    selectRoute(Route.unitAppliances, authStore.user?.role, {
                        realestateid: realestateid,
                        unitid: unitid
                    }),
                    t("screens.unit.appliances")
                );
            } else {
                navStore.setBackbutton(
                    selectRoute(Route.realestateAppliances, authStore.user?.role, {
                        realestateid: realestateid
                    }),
                    t("screens.realestate.dashboard.appliances")
                );
            }
        });
    }, [t, authStore.user, navStore, realestateid, unitid, data]);

    const renderItem = useCallback((item: ApplianceItem, screenSize: ScreenSize) => {
        const listRows: any[] = [];

        if (item.value) {
            let rows: React.ReactElement[] = [];

            rows = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={1} value={item.label} />
                    <UI.List.Cell
                        key={"c-2"}
                        colspan={screenSize === ScreenSize.DESKTOP ? 2 : 1}
                        value={item.value}
                        className={item.className ?? ""}
                    />
                </UI.List.Row>
            ];

            listRows.push(rows);

            return (
                <UI.List.Item
                    key={item.label}
                    screenSize={screenSize}
                    rows={listRows}
                    to={item.to}
                    onClick={item.onClick}
                />
            );
        }
    }, []);

    const onRowClick = useCallback(() => {
        if (unitid) {
            navStore.setBackbutton(
                selectRoute(Route.unitAppliance, authStore.user?.role, {
                    applianceid: applianceid,
                    realestateid: realestateid,
                    unitid: unitid
                }),
                t("screens.realestate.appliance.title")
            );
        } else {
            navStore.setBackbutton(
                selectRoute(Route.realestateAppliance, authStore.user?.role, {
                    applianceid: applianceid,
                    realestateid: realestateid
                }),
                t("screens.realestate.appliance.title")
            );
        }
    }, [navStore, t, authStore.user?.role, realestateid, applianceid, unitid]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data || data.appliance.length === 0) {
        return <Screens.ErrorEmptyData message={t("error.nodata")} />;
    }

    const l = (key: string) => {
        return t("screens.realestate.appliance." + key);
    };

    const appliance = data.appliance[0];

    const servicecompany = appliance.servicecompany;
    const servicecompanyName = servicecompany
        ? getName(servicecompany?.name1, servicecompany?.name2, servicecompany?.legal)
        : undefined;

    let servicecompanyLink = undefined;

    if (servicecompany?.id) {
        servicecompanyLink = selectRoute(
            unitid ? Route.unitApplianceServiceCompany : Route.realestateApplianceServiceCompany,
            authStore.user?.role,
            {
                servicecompanyid: servicecompany?.id,
                applianceid: applianceid,
                realestateid: realestateid,
                unitid: unitid
            }
        );
    }

    const unit = appliance.unit;
    const unitName = unit ? unit.name ?? "-" : undefined;

    let unitLink = undefined;

    if (unit?.id) {
        unitLink = selectRoute(Route.unit, authStore.user?.role, {
            realestateid: realestateid,
            unitid: unit.id
        });
    }

    const applianceDetails: Array<ApplianceItem> = [
        { label: l("label"), value: appliance.name ?? undefined },
        { label: l("manufacturer"), value: appliance.manufacturer ?? undefined },
        {
            label: l("servicecompany"),
            value: servicecompanyName,
            className: styles.DisplayAllText,
            to: servicecompanyLink,
            onClick: onRowClick
        },
        { label: l("serialnumber"), value: appliance.serialnumber ?? undefined },
        { label: l("productionnumber"), value: appliance.productionnumber ?? undefined },
        {
            label: t("screens.unit.object"),
            value: unitName,
            className: styles.DisplayAllText,
            to: unitLink,
            onClick: onRowClick
        },
        { label: l("remarks"), value: appliance.remarks, className: styles.DisplayAllText }
    ];

    const lifespanDetails: Array<ApplianceItem> = [
        { label: l("purchasedate"), value: getDate(appliance.purchasedate) },
        { label: l("outofservicedate"), value: getDate(appliance.outofservicedate) },
        { label: l("lastrevision"), value: getDate(appliance.lastrevision) },
        { label: l("nextrevision"), value: getDate(appliance.nextrevision) },
        { label: l("lastofficialinspection"), value: getDate(appliance.lastofficialinspection) },
        { label: l("nextofficialinspection"), value: getDate(appliance.nextofficialinspection) },
        { label: l("servicestart"), value: getDate(appliance.servicestart) },
        { label: l("serviceend"), value: getDate(appliance.serviceend) },
        {
            label: l("serviceconditions"),
            value: appliance.serviceconditions ?? undefined,
            className: styles.DisplayAllText
        }
    ];

    let applianceDetailsContainsAnyValue = false;

    applianceDetails.forEach((applianceDetail) => {
        if (applianceDetail.value) {
            applianceDetailsContainsAnyValue = true;
        }
    });

    const applianceDetailList = applianceDetailsContainsAnyValue ? (
        <UI.Card title={l("title")} flex>
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={applianceDetails}
                renderItem={renderItem}
            />
        </UI.Card>
    ) : undefined;

    let lifespanDetailsContainsAnyValue = false;

    lifespanDetails.forEach((lifespanDetail) => {
        if (lifespanDetail.value) {
            lifespanDetailsContainsAnyValue = true;
        }
    });

    const lifespanDetailList = lifespanDetailsContainsAnyValue ? (
        <UI.Card title={l("lifespan")} flex>
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={lifespanDetails}
                renderItem={renderItem}
            />
        </UI.Card>
    ) : undefined;

    const titleName = `${appliance.name ?? ""} ${appliance.model ?? ""}`;

    return (
        <ScreenWithTiles title={titleName} screenId={ScreenType.APPLICANCE} realestateid={realestateid}>
            {applianceDetailList}
            {lifespanDetailList}
            {data.appliance[0].maintenances.length > 0 ? (
                <UI.Card title={t("screens.realestate.appliance.maintenances")} flex>
                    <ApplianceMaintenances
                        maintenances={data.appliance[0].maintenances}
                        maintenancestatus={data.maintenancestatus}
                    />
                </UI.Card>
            ) : undefined}
        </ScreenWithTiles>
    );
};
export const Appliance = Sentry.withProfiler(withRouter(observer(ApplianceBase)));
