import React, { useState, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { Search } from "src/screens/search";
import { ColorStyle } from "src/utils/Colors";
import debounce from "lodash/debounce";
import "./Searchbar.css";

interface SearchbarBaseProps {
    onClose(): void;
}

export const Searchbar = (props: SearchbarBaseProps) => {
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState<string>("");

    const onInputChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    }, 800);

    const onCloseSearch = useCallback(() => {
        setSearchQuery("");
        props.onClose();
    }, [props]);

    return (
        <React.Fragment>
            <div className="Searchbar">
                <UI.Input
                    id="searchbarInput"
                    className="mr-2 mb-0"
                    type="search"
                    placeholder={t("labels.search")}
                    autoFocus={true}
                    onChange={onInputChange}
                />
                <UI.Icon icon={UI.SVGIcon.Close} color={ColorStyle("white")} onClick={props.onClose} />
            </div>
            <Search.Result query={searchQuery} onClose={onCloseSearch} />
        </React.Fragment>
    );
};
