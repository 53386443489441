import React, { useCallback, useContext, useEffect, useState } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./ManualEmailModal.module.css";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { EmailToFormField } from "./fields/EmailToFormField";
import { EmailCCFormField } from "./fields/EmailCCFormField";
import { EmailData } from "src/stores/tickets/ManualEmailModalStore";
import { useForm } from "react-hook-form";
import { MANUAL_EMAIL_PROCESS, MANAUAL_EMAIL_PROCESS_STEP } from "src/stores/tickets/ManualEmailModalStore";

interface ManualEmailModalProps {
    showModal: boolean;
    onClickClose: () => void;
}

interface DropDownItem {
    label: string;
    value: string;
}

export const ManualEmailModalBase = (props: ManualEmailModalProps) => {
    const { t } = useTranslation();
    const { ticketStore, manualEmailModalStore, uiStore } = useContext(RootStoreContext);

    const [currentOrderLabel, setCurrentOrderLabel] = useState<string>("-");
    const [subject, setSubject] = useState<string>("");
    const [dropdownItems, setDropdownItems] = useState<DropDownItem[]>([]);

    const { register, handleSubmit, setValue, getValues } = useForm<EmailData>({
        mode: "onBlur",
        defaultValues: {
            incidentId: ticketStore.currentTicket?.id,
            orderId: "",
            subject: `${t("screens.tickets.ticket")}-${ticketStore.currentTicket?.number}: ${
                ticketStore.currentTicket?.title
            }`,
            body: "",
            recipients: [],
            ccs: []
        }
    });

    const formatOrderString = useCallback(
        (number: number, serviceProviderName: string): string => {
            return `${t("screens.orders.order")}-${number} (${serviceProviderName})`;
        },
        [t]
    );

    useEffect(() => {
        if (ticketStore.currentTicket?.orders && ticketStore.currentTicket?.orders.length > 0) {
            const newDropdownItems: DropDownItem[] = ticketStore.currentTicket.orders.map((order) => ({
                label: formatOrderString(order.number, order.serviceProviderName!),
                value: order.id
            }));
            setDropdownItems(newDropdownItems);
        }
    }, [ticketStore.currentTicket?.orders, formatOrderString]);

    useEffect(() => {
        if (currentOrderLabel === "-") {
            setSubject(
                `${t("screens.tickets.ticket")}-${ticketStore.currentTicket?.number}: ${
                    ticketStore.currentTicket?.title
                }`
            );
        } else {
            setSubject(
                `${t("screens.tickets.ticket")}-${ticketStore.currentTicket?.number}: ${
                    ticketStore.currentTicket?.title
                } / ${currentOrderLabel}`
            );
        }
    }, [ticketStore.currentTicket?.number, ticketStore.currentTicket?.title, currentOrderLabel, t]);

    const onDismissModal = useCallback(() => {
        manualEmailModalStore.setIsActivationProgressModalDisplayed(false);
        manualEmailModalStore.setCurrentStepType(0);
        setCurrentOrderLabel("-");
    }, [manualEmailModalStore]);

    const handleFormSubmit = useCallback(
        async (formData: EmailData) => {
            manualEmailModalStore.setCurrentProcessType(MANUAL_EMAIL_PROCESS.SEND_EMAIL_RESULT);
            manualEmailModalStore.setEmailData({
                incidentId: formData.incidentId,
                body: formData.body,
                ccs: formData.ccs,
                recipients: formData.recipients,
                subject: formData.subject,
                orderId: formData.orderId
            });

            manualEmailModalStore.sendManualEmail();

            setCurrentOrderLabel("-");
        },
        [manualEmailModalStore]
    );

    const onSelectDropdown = useCallback(
        (item: string) => {
            setValue("orderId", item);

            const newDropdownLabel = dropdownItems.find((sortItem: DropDownItem) => sortItem.value === item)?.label;

            setValue(
                "subject",
                `${t("screens.tickets.ticket")}-${ticketStore.currentTicket?.number}: ${
                    ticketStore.currentTicket?.title
                } / ${newDropdownLabel}`
            );
            setCurrentOrderLabel(newDropdownLabel!);
        },
        [setValue, dropdownItems, t, ticketStore.currentTicket?.number, ticketStore.currentTicket?.title]
    );

    const renderStep = () => {
        switch (manualEmailModalStore.currentStepType) {
            case MANAUAL_EMAIL_PROCESS_STEP.START_SEND_EMAIL:
            default:
                return (
                    <div>
                        <EmailToFormField setValue={setValue} getValues={getValues} />
                        <EmailCCFormField setValue={setValue} getValues={getValues} />
                        <div className="mt-3">
                            <UI.Input
                                {...register("subject")}
                                label={t("labels.subject")}
                                className={styles.InputSubject}
                                value={subject}
                                readOnly={true}
                                autoFocus={false}
                            />
                        </div>
                        <p className="form-label mb-1">{t("screens.tickets.action.email.order_reference")}</p>
                        <UI.Dropdown
                            className="mb-2"
                            id="ordersReference"
                            label={currentOrderLabel}
                            items={dropdownItems}
                            onSelect={onSelectDropdown}
                        />
                        <UI.Input
                            {...register("body")}
                            className={styles.TextArea}
                            type="text"
                            as="textarea"
                            placeholder="Guten Tag"
                        />
                        <UI.Button
                            className={styles.SubmitButton}
                            label={t("labels.submit")}
                            onClick={handleSubmit(handleFormSubmit)}
                            disabled={manualEmailModalStore.isRecipientsEmpty}
                        />
                    </div>
                );
            case MANAUAL_EMAIL_PROCESS_STEP.SEND_EMAIL_RESULT:
                return (
                    <>
                        <UI.Row className={styles.DivImageLoadSpinner}>
                            {!manualEmailModalStore.isManualEmailSuccessfullySend ? (
                                <div className={styles.LoadSendMail}>
                                    <UI.RotatingSpinner noLogo size={50} className={styles.ImageLoadSpinner} />
                                    <div className={styles.SendEmail}>{t("labels.send_email")}...</div>{" "}
                                </div>
                            ) : (
                                <>
                                    {manualEmailModalStore.errorMessage.length > 0 ? (
                                        <div className={styles.LoadSendMail}>
                                            <UI.Icon
                                                icon={UI.SVGIcon.Error}
                                                size={50}
                                                color={"black"}
                                                className={styles.IconCheck}
                                            />
                                            <div className={styles.SendEmail}>{t("labels.send_email_failed")}</div>
                                            <div className={styles.SendEmailError}>
                                                {t("manualEmailModalStore.errorMessage")}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles.EmailSendIconText}>
                                            <UI.Icon
                                                icon={UI.SVGIcon.Check}
                                                size={50}
                                                color={"black"}
                                                className={styles.IconCheck}
                                            />
                                            <div className={styles.SendEmail}>{t("labels.send_email_successfull")}</div>
                                        </div>
                                    )}
                                </>
                            )}
                        </UI.Row>
                        <UI.Row className={"mt-2 justify-content-end"}>
                            <UI.Col xs={12} md={3}>
                                <UI.Button
                                    className={`ticket-button mt-2 ${styles.NextButton}`}
                                    label={t("labels.finished").toString()}
                                    disabled={!manualEmailModalStore.isManualEmailSuccessfullySend}
                                    onClick={onDismissModal}
                                ></UI.Button>
                            </UI.Col>
                        </UI.Row>
                    </>
                );
        }
    };

    return (
        <UI.ProgressModal
            currentStep={manualEmailModalStore.currentStep?.stepNumber ?? 1}
            totalSteps={manualEmailModalStore.ACTIVATION_MODAL_MAX_NUMBER_OF_STEPS}
            title={t("labels.email")}
            show={manualEmailModalStore.isManualEmailProgressModalDisplayed}
            onDismissModal={onDismissModal}
            centered={uiStore.isMobile ? true : false}
            size={uiStore.isMobile ? undefined : "lg"}
        >
            {renderStep()}
        </UI.ProgressModal>
    );
};

export const ManualEmailModal = Sentry.withProfiler(observer(ManualEmailModalBase));
