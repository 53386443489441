import React, { useContext, useEffect, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { useUrlQuery } from "src/utils/Route";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { ColorStyle } from "src/utils/Colors";

export interface ErrorProps {
    message?: string;
    networkError?: Error & any;
    componentStack?: string | null;
    resetError?: () => void;
    error?: Error | null;
}

const FileErrorBase = (props: ErrorProps) => {
    const { navStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    let urlQuery = useUrlQuery();
    const code = urlQuery.get("code");
    const fileid = urlQuery.get("fileid");
    const message = urlQuery.get("message");

    useEffect(() => {
        navStore.setTitle(t("screens.fileerror.header"));
        navStore.setBackbutton(null);

        Sentry.captureMessage("404 - File not found", (scope) =>
            scope.setExtras({ url: window.location.href, code, fileid, message })
        );
    }, [navStore, t, code, fileid, message]);

    const errorMessage = i18next.exists(`error.${code}`) ? t(`error.${code}`) : t("error.generic_file_open");

    const closeTab = useCallback(() => {
        window.close();
    }, []);

    return (
        <UI.Container>
            <UI.Row>
                <UI.Col className="mt-sm-2 mt-1 mx-auto pt-sm-4 pt-1 col-sm-8">
                    <UI.Card>
                        <div className="d-flex align-items-center flex-column">
                            <UI.Icon icon={UI.SVGIcon.Error} color={ColorStyle("danger")} className="mb-2" />
                            <p className="mt-4">{errorMessage}</p>
                            <UI.Button
                                variant="link"
                                label={t("screens.fileerror.close")}
                                onClick={closeTab}
                                className="w-50"
                            />
                        </div>
                    </UI.Card>
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};

export const FileError = Sentry.withProfiler(FileErrorBase);
