import { observable, action, makeObservable, computed } from "mobx";
import { PROCESS, TICKET_STATE } from "src/stores/TicketStore";
import { RootStore } from "src/stores/RootStore";
import i18next from "i18next";
import { GetRealestateUserAndAccessData_realestateUsersAndAccess } from "src/api/generated/GetRealestateUserAndAccessData";

export enum SORT {
    DATE_NEWEST = "dateNewest",
    DATE_OLDEST = "dateOldest"
}

interface IKanbanBoard {
    [TICKET_STATE.NEW]: number[];
    [TICKET_STATE.ASSIGNED]: number[];
    [TICKET_STATE.COMISSIONED]: number[];
    [TICKET_STATE.COMPLETED]: number[];
    [TICKET_STATE.ARCHIVED]: number[];
}
export type FilterStateType = TICKET_STATE | "All";

export interface FilterSelection {
    label: string;
    id: FilterStateType;
}
export interface IFilter {
    state: FilterStateType;
    sort: SORT;
    query: string;
    types: PROCESS[];
    showOwn: boolean;
    showMyRealestates: boolean;
}
export interface SearchTag {
    label: string;
    id: string;
    active: boolean;
    type?: PROCESS;
}
export const getTicketStateWithId = (id: number): TICKET_STATE => {
    switch (id) {
        case 0:
            return TICKET_STATE.NEW;
        case 10:
            return TICKET_STATE.ASSIGNED;
        case 20:
            return TICKET_STATE.COMISSIONED;
        case 90:
            return TICKET_STATE.COMPLETED;
        case 100:
            return TICKET_STATE.ARCHIVED;
        default:
            return TICKET_STATE.NEW;
    }
};

export class TicketListStore {
    rootStore: RootStore;
    ticketFilter: IFilter = {
        state: TICKET_STATE.NEW,
        sort: SORT.DATE_NEWEST,
        query: "",
        types: [],
        showOwn: true,
        showMyRealestates: false
    };
    searchTags: SearchTag[] = [
        { label: i18next.t("screens.tickets.list.show_own"), id: "SHOW_OWN", active: this.ticketFilter.showOwn },
        { label: i18next.t("easyticket.workflow_types.1"), id: "2", active: false, type: PROCESS.COMMON_REQUEST },
        { label: i18next.t("easyticket.workflow_types.2"), id: "3", active: false, type: PROCESS.DAMAGE_NOTIFICATION },
        { label: i18next.t("easyticket.workflow_types.4"), id: "4", active: false, type: PROCESS.DOCUMENT_QUESTION },
        { label: i18next.t("easyticket.workflow_types.5"), id: "5", active: false, type: PROCESS.KEY_ORDER },
        { label: i18next.t("easyticket.workflow_types.3"), id: "6", active: false, type: PROCESS.NAMESIGN },
        { label: i18next.t("screens.tickets.list.my_realestates"), id: "SHOW_MYREALESTATE", active: false }
    ];

    kanbanBoard: IKanbanBoard = {
        [TICKET_STATE.NEW]: [],
        [TICKET_STATE.ASSIGNED]: [],
        [TICKET_STATE.COMISSIONED]: [],
        [TICKET_STATE.COMPLETED]: [],
        [TICKET_STATE.ARCHIVED]: []
    };

    realestateUserData: GetRealestateUserAndAccessData_realestateUsersAndAccess[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            kanbanBoard: observable,
            ticketFilter: observable,
            searchTags: observable,
            realestateUserData: observable,
            resetKanbanBoard: action,
            setKanbanTicketState: action,
            setFilter: action,
            setTag: action,
            clearFilter: action,
            setRealestateUserData: action,
            filteredTickets: computed,
            ticketStats: computed,
            tickets: computed
        });
    }

    get tickets() {
        return this.rootStore.ticketStore.tickets;
    }

    getTicketByState = (state: TICKET_STATE) => {
        return this.tickets.filter((ticket) => ticket.state === state);
    };
    getTicketByNumber = (number: number) => {
        return this.tickets.find((ticket) => ticket.number === number);
    };

    setRealestateUserData = (realestateUserData: GetRealestateUserAndAccessData_realestateUsersAndAccess[]) => {
        this.realestateUserData = realestateUserData;
    };

    get filteredTickets() {
        const filter = this.ticketFilter;
        return this.tickets
            .filter((ticket) => {
                if (filter.query === "") {
                    return true;
                }
                const searchString = [
                    ticket.number ?? "",
                    ticket.title ?? "",
                    ticket.incidentContactPerson?.name1 ?? "",
                    ticket.incidentContactPerson?.name2 ?? "",
                    ticket.realestate?.name ?? "",
                    ticket.realestate?.city ?? "",
                    ticket.realestate?.zip ?? "",
                    ticket.appliance ?? ""
                ]
                    .join("")
                    .toLowerCase();
                return searchString.search(filter.query.toLowerCase()) !== -1;
            })

            .filter((ticket) => {
                if (filter.showOwn) {
                    //TODO: Filter realestatesuser as well
                    return ticket.assignedUser?.id === this.rootStore.authStore.user?.userid;
                }
                return true;
            })
            .filter((ticket) => {
                if (filter.showMyRealestates) {
                    return this.realestateUserData.some((user) => user.realestateid === ticket.realestate?.id);
                }
                return true;
            })
            .filter((ticket) => {
                if (filter.types.length === 0) {
                    return true;
                }
                return filter.types.includes(ticket.workflow_type);
            })

            .sort((lhs, rhs) => {
                if (filter.sort === SORT.DATE_OLDEST) {
                    return (lhs.number ?? 0) - (rhs.number ?? 0);
                } else if (filter.sort === SORT.DATE_NEWEST) {
                    return (rhs.number ?? 0) - (lhs.number ?? 0);
                } else {
                    return 0;
                }
            });
    }

    get ticketStats() {
        const allTickets = [...this.filteredTickets];
        const getLength = (ticketState: TICKET_STATE) => {
            return allTickets.filter((t) => t.state === ticketState)?.length ?? 0;
        };
        return {
            new: getLength(TICKET_STATE.NEW),
            assigned: getLength(TICKET_STATE.ASSIGNED),
            comissioned: getLength(TICKET_STATE.COMISSIONED),
            completed: getLength(TICKET_STATE.COMPLETED),
            archived: getLength(TICKET_STATE.ARCHIVED),
            total: { total: this.tickets.length, filtered: allTickets.length }
        };
    }

    resetKanbanBoard = () => {
        this.kanbanBoard = {
            [TICKET_STATE.NEW]: [],
            [TICKET_STATE.ASSIGNED]: [],
            [TICKET_STATE.COMISSIONED]: [],
            [TICKET_STATE.COMPLETED]: [],
            [TICKET_STATE.ARCHIVED]: []
        };

        this.filteredTickets.forEach((ticket) => {
            this.kanbanBoard[ticket.state].push(ticket.number || 0);
        });
    };

    setKanbanTicketState = (
        newState: TICKET_STATE,
        oldState: TICKET_STATE,
        ticketNumber: number,
        newIndex: number,
        oldIndex: number
    ) => {
        const oldColumn = this.kanbanBoard[oldState];
        const newColumn = this.kanbanBoard[newState];

        if (oldState === newState) {
            const newTicketArray = newColumn;
            newTicketArray.splice(oldIndex, 1);
            newTicketArray.splice(newIndex, 0, ticketNumber);
            this.kanbanBoard[newState] = newTicketArray;
            return;
        } else {
            const oldTicketsArray = oldColumn;
            oldTicketsArray.splice(oldIndex, 1);

            const newTicketsArray = newColumn;
            newTicketsArray.splice(newIndex, 0, ticketNumber);

            this.kanbanBoard[newState] = newTicketsArray;
            this.kanbanBoard[oldState] = oldTicketsArray;
        }
    };

    setFilter = (newFilter: IFilter) => {
        this.ticketFilter = newFilter;
        this.resetKanbanBoard();
    };

    clearFilter = () => {
        this.searchTags = [...this.searchTags].map((t) => {
            return { ...t, active: false };
        });

        this.setFilter({
            state: TICKET_STATE.NEW,
            sort: SORT.NUMBER_UP,
            query: "",
            types: [],
            showOwn: false,
            showMyRealestates: false
        });
    };

    setTag = (id: string, active: boolean) => {
        const newSearchTags: SearchTag[] = [...this.searchTags].map((t) => {
            if (t.id === id) {
                return { ...t, active: active };
            }
            return t;
        });

        const activeProcesses: PROCESS[] = [];

        newSearchTags.forEach((tag) => {
            if (tag.active && tag.type) {
                activeProcesses.push(tag.type);
            }
        });

        this.searchTags = newSearchTags;

        this.setFilter({
            ...this.ticketFilter,
            types: activeProcesses,
            showOwn: newSearchTags.find((st) => st.id === "SHOW_OWN")?.active ?? false,
            showMyRealestates: newSearchTags.find((st) => st.id === "SHOW_MYREALESTATE")?.active ?? false
        });
    };
}
