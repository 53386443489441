import React, { memo } from "react";
import styles from "./UserAvatar.module.css";
export const UserAvatar = memo(function UserAvatar(props: { name1: string; name2?: string | null }) {
    const label = `${props.name1.charAt(0)}${props.name2?.charAt(0) ?? ""}`;
    const possibleColors = ["#AE9595", "#9FAEB6", "#BC9EBB", "#A0AE95", "#A3A5A6"];
    return (
        <div
            className={styles.UserAvatar}
            style={{ color: possibleColors[props.name1.length % possibleColors.length] }}
        >
            <svg viewBox="0 0 64 64">
                <circle cx={32} r={32} cy={32} fill="currentColor" />
                <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="200%" fontWeight="bold">
                    {label}
                </text>
            </svg>
        </div>
    );
});
