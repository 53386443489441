import React, { useCallback, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import styles from "./EmailCCFormField.module.css";
import { Patterns } from "src/utils/Patterns";

interface EmailCCFormFieldProps {
    setValue: any;
    getValues: any;
}

const EmailCCFormFieldBase = (props: EmailCCFormFieldProps) => {
    const { t } = useTranslation();

    const { setValue, getValues } = props;

    const { ticketStore, manualEmailModalStore } = useContext(RootStoreContext);

    const [displayEmailList, setDisplayEmailList] = useState<boolean>(false);
    const [emails, setEmails] = useState<string[]>([]);
    const [isEmailInputValid, setIsEmailInputValid] = useState<boolean>(true);
    const [inputValue, setInputValue] = useState<string>("");

    const checkIfEmailIsValid = useCallback(() => {
        if (inputValue && Patterns.EMAILADDRESS.test(inputValue)) {
            setInputValue("");
            setIsEmailInputValid(true);
            setEmails([...emails, inputValue]);
            setValue("ccs", emails);
        } else {
            setIsEmailInputValid(false);
        }
    }, [setValue, inputValue, emails]);

    const onFocusEmailInput = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        setDisplayEmailList(true);
    }, []);

    const onClickEmailItem = useCallback(
        (email: string) => async (e: any) => {
            setEmails([...emails, email]);
            setDisplayEmailList(false);
        },
        [emails]
    );

    useEffect(() => {
        setValue("ccs", emails);
    }, [emails, setValue]);

    const emailFilterList =
        ticketStore.currentTicket?.incidentPersons
            ?.map((incidentPerson) => {
                if (incidentPerson.email) {
                    return {
                        emails: incidentPerson.email,
                        text: `${incidentPerson.name1} ${incidentPerson.name2}, ${incidentPerson.email} (${
                            manualEmailModalStore.incidentPersonEnums.find((item) => item.key === incidentPerson.role)
                                ?.label
                        })`
                    };
                }
                return null; // Skip items without an emails
            })
            .filter(Boolean) || [];

    const emailSelectionList = (
        <ul
            id="emails-dropdown-list"
            className={isEmailInputValid ? "dropdown-list emailCC" : "dropdown-list errorEmailCC"}
        >
            {emailFilterList.map((emailItem, index) => {
                const isEmailInList = emails.includes(emailItem?.emails || "");

                if (!isEmailInList) {
                    return (
                        <li key={index}>
                            <div
                                className={"list-item email"}
                                id={`email-list-item-${index}`}
                                onMouseDown={onClickEmailItem(emailItem?.emails || "")}
                                dangerouslySetInnerHTML={{
                                    __html: emailItem?.text || ""
                                }}
                            />
                        </li>
                    );
                }

                return null;
            })}
        </ul>
    );

    const handleKeyPress = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            manualEmailModalStore.setDisplayEmailToList(false);
            if (event.key === "Enter" || event.key === "Tab") {
                manualEmailModalStore.setDisplayEmailToList(false);
                checkIfEmailIsValid();
            }
        },
        [manualEmailModalStore, checkIfEmailIsValid]
    );

    const onClickRemoveEmail = useCallback(
        (emailToRemove: string) => async (e: any) => {
            const emailList = getValues("ccs");

            const updatedEmailList = emailList.filter((email: string) => email !== emailToRemove);
            setEmails(updatedEmailList);
            setValue("ccs", updatedEmailList);

            setDisplayEmailList(false);
        },
        [getValues, setValue]
    );

    const generateEmailCCItem = (email: string) => (
        <div key={email} className={styles.EmailCCItem} onClick={onClickRemoveEmail(email)}>
            {email}
            <UI.Icon className={"ml-1"} icon={UI.SVGIcon.Close} size={13} onClick={onClickRemoveEmail(email)} />
        </div>
    );
    const onChangeFormInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setDisplayEmailList(false);
            setValue("ccs", "");
            setInputValue(event.currentTarget.value);
        },
        [setValue]
    );

    const onBlurEmailInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (inputValue !== "") {
                if (Patterns.EMAILADDRESS.test(inputValue)) {
                    setEmails([...emails, inputValue]);
                    setValue("ccs", emails);
                    setInputValue("");
                    setIsEmailInputValid(true);
                } else {
                    setIsEmailInputValid(false);
                }
            }

            setDisplayEmailList(false);
        },
        [inputValue, setValue, emails]
    );

    return (
        <>
            <p className={styles.EmailCCTitle}>{t("labels.cc")}</p>
            <div className={styles.EmailCCInputDiv} tabIndex={0}>
                <div className={styles.EmailCCItemWrapper}>{emails.map((email) => generateEmailCCItem(email))}</div>
                <input
                    className={`flex-fill ${styles.Input}`}
                    onChange={onChangeFormInput}
                    onKeyDown={handleKeyPress}
                    onBlur={onBlurEmailInput}
                    onFocus={onFocusEmailInput}
                    type="text"
                    value={inputValue}
                />
                <UI.Icon className={styles.searchIcon} icon={UI.SVGIcon.Search} color="grey" size={"small"} />
            </div>
            {isEmailInputValid ? (
                <></>
            ) : (
                <p className={styles.EmailCCError}>{t("screens.tickets.action.email.incorrect_email")}</p>
            )}
            {displayEmailList ? emailSelectionList : undefined}
        </>
    );
};

export const EmailCCFormField = Sentry.withProfiler(observer(EmailCCFormFieldBase));
