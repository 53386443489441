import { GetAssemblyByID_assemblyitems } from "src/api/generated/GetAssemblyByID";
import { getDateWithLongMonthAndTime } from "src/utils/Date";
import { useTranslation } from "react-i18next";
import { InfoCard } from "src/components/infoCard/InfoCard";
import { useAssemblyInsertedEventSubscription } from "src/hooks/assembly/useAssemblyInsertedEventSubscription";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { RootStoreContext } from "src/stores/RootStore";

interface AssemblyInfoTileProps {
    assemblyDetails?: GetAssemblyByID_assemblyitems;
}

const AssemblyInfoTileBase = (props: AssemblyInfoTileProps) => {
    const { assemblyDetails } = props;
    const { t } = useTranslation();
    const { assemblyStore, notificationStore } = useContext(RootStoreContext);

    useEffect(() => {
        notificationStore.setNotificationId(assemblyDetails?.id);
        // Only re-render when assemblyDetails?.id change
        // eslint-disable-next-line
    }, [assemblyDetails?.id]);

    const publishedDate = getDateWithLongMonthAndTime(assemblyDetails?.publishdate);
    const eventDate = useAssemblyInsertedEventSubscription(assemblyDetails?.id, "");

    const assemblyEventInfoItems = [
        {
            title: t("screens.realestate.assembly.tile.published_at"),
            value: publishedDate !== undefined || publishedDate != null ? publishedDate : "-",
            loading: false
        },
        {
            title: t("screens.realestate.assembly.tile.notified_at"),
            value: eventDate !== undefined || eventDate != null ? eventDate : "-",
            loading: !assemblyStore.isAssemblyInsertedEventLoaded
        }
    ];

    notificationStore.setIsNewsOrAssemblyState(1);

    return <InfoCard infoItems={assemblyEventInfoItems} />;
};

export const AssemblyInfoTile = Sentry.withProfiler(observer(AssemblyInfoTileBase));
