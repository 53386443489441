import { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_ASSEMBLIES_BY_ID } from "src/api/assembly";
import { GetAssemblyByID, GetAssemblyByIDVariables } from "src/api/generated/GetAssemblyByID";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { Tiles } from "src/components";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { Route, selectRoute } from "src/config/routes";
import { withRouter, useParams } from "react-router";
import { AssemblyEditForm } from "./form/AssembliesEditForm";
import { ScreenWidth } from "src/stores/UIStore";
import styles from "./AssembliesEdit.module.css";
import { AssemblyInfoTile } from "./AssemblyInfoTile";
import { AssembliesActionCard } from "./AssembliesActionCard";

interface Params {
    realestateid: string;
    assemblyid: string;
}

const ManagerAssembliesEditBase = () => {
    const { realestateid, assemblyid } = useParams<Params>();
    const { t } = useTranslation();
    const { navStore, authStore, assemblyStore } = useContext(RootStoreContext);

    useEffect(() => {
        navStore.setBackbutton(
            selectRoute(Route.managerAssemblies, authStore.user?.role, { realestateid: realestateid }),
            t("screens.realestate.assemblies.title")
        );
    }, [authStore.user?.role, navStore, realestateid, t]);

    const { data, loading, error, refetch } = useQuery<GetAssemblyByID, GetAssemblyByIDVariables>(
        GET_ASSEMBLIES_BY_ID,
        {
            variables: {
                realestateid: realestateid,
                id: assemblyid
            }
        }
    );

    useEffect(() => {
        if (assemblyStore.showRefetchAssemblyData) {
            refetch();
            assemblyStore.setShowRefetchAssemblyData(false);
        }
    }, [assemblyStore.showRefetchAssemblyData, assemblyStore, refetch]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const assemblyDetails = data.assemblyitems.length > 0 ? data.assemblyitems[0] : undefined;
    assemblyStore.setAssemblyId(assemblyid);

    return (
        <UI.Container className="pt-4">
            <UI.Row>
                <UI.Col xl={9}>
                    <AssemblyEditForm
                        assemblyDetails={assemblyDetails}
                        assemblyStatus={data.assemblystates}
                        assemblyid={assemblyid}
                        realestateid={realestateid}
                        assemblyFiles={data.assemblyfiles}
                        agendaItemParents={data.agendaitem_parents}
                        refetchData={refetch}
                    />
                </UI.Col>
                {window.innerWidth >= ScreenWidth.XL ? (
                    <UI.Col xl={3}>
                        <UI.Card
                            title={t("screens.realestate.assembly.tile.title")}
                            className={`${styles.TileXl} ${styles.InfoItemsTile}`}
                        >
                            <AssemblyInfoTile assemblyDetails={assemblyDetails} />
                        </UI.Card>
                        <AssembliesActionCard
                            assemblyid={assemblyid}
                            assemblyDetails={assemblyDetails}
                            className={styles.TileXl}
                        />
                        <Tiles.ContactTile realestateid={realestateid} className={styles.TileXl} />
                    </UI.Col>
                ) : (
                    <UI.Col sm={12}>
                        <UI.Card title={t("screens.realestate.assembly.tile.title")} className={styles.InfoItemsTile}>
                            <AssemblyInfoTile assemblyDetails={assemblyDetails} />
                        </UI.Card>
                        <AssembliesActionCard assemblyid={assemblyid} assemblyDetails={assemblyDetails} />
                        <Tiles.ContactTile realestateid={realestateid} />
                    </UI.Col>
                )}
            </UI.Row>
        </UI.Container>
    );
};

export const ManagerAssembliesEdit = withRouter(Sentry.withProfiler(observer(ManagerAssembliesEditBase)));
