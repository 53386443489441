import React, { useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { withRouter, RouteComponentProps, useParams } from "react-router";
import { GET_MANAGER_NEWS } from "src/api/news";
import { useQuery } from "@apollo/client";
import { GetManagerNews, GetManagerNewsVariables } from "src/api/generated/GetManagerNews";
import * as Screens from "src/screens";
import { getLang } from "src/utils/i18n";
import { useTranslation } from "react-i18next";
import ManagerNewsList from "./list/ManagerNewsList";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { v4 as uuidv4 } from "uuid";
import { Route, selectRoute } from "src/config/routes";

import styles from "./ManagerNews.module.css";

interface Params {
    realestateid: string;
}

const ManagerNewsBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { realestateid } = useParams<Params>();
    const { uiStore, authStore } = useContext(RootStoreContext);

    const { data, loading, error, refetch } = useQuery<GetManagerNews, GetManagerNewsVariables>(GET_MANAGER_NEWS, {
        variables: {
            language: getLang(),
            realestateid: realestateid
        }
    });

    useEffect(() => {
        if (uiStore.hasNewNewsData) {
            refetch();
            uiStore.setHasNewNewsData(false);
        }
    }, [refetch, uiStore]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    return (
        <>
            <div className={styles.ManagerAddNewsButton}>
                <UI.Button
                    label={t("screens.realestate.dashboard.enter_new_news")}
                    to={selectRoute(Route.realestateNewsEdit, authStore.user?.role, {
                        realestateid: realestateid,
                        newsid: uuidv4()
                    })}
                />
            </div>
            <ManagerNewsList newsItems={data.newsitems} newsStatus={data.newsstatus} />
        </>
    );
};

export const ManagerNews = withRouter(Sentry.withProfiler(observer(ManagerNewsBase)));
