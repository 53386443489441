import { observable, action, makeObservable, computed } from "mobx";
import { INSERT_USER_RATING, UPDATE_USER_RATING_STATE } from "src/api/userRating";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { RootStore } from "src/stores/RootStore";
import { ApplicationType, UserRatingStates } from "src/types";
import { InsertUserRating, InsertUserRatingVariables } from "src/api/generated/InsertUserRating";
import { UpdateUserRatingstate, UpdateUserRatingstateVariables } from "src/api/generated/UpdateUserRatingstate";
import { Role } from "src/network/User";

export class UserRatingModalStore {
    rootStore: RootStore;

    isLoading = false;
    hasError = false;
    errorMessage: string | undefined = undefined;
    rating: number = 0;
    ratingState: UserRatingStates = UserRatingStates.INACTIVE;
    comment = "";
    doNotAskAgain = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            isLoading: observable,
            hasError: observable,
            errorMessage: observable,
            rating: observable,
            ratingState: observable,
            comment: observable,
            doNotAskAgain: observable,

            dismissModal: action,
            sendFeedback: action,
            deferFeedback: action,
            setComment: action,
            setRating: action,
            setRatingState: action,
            setIsLoading: action,

            isVisible: computed
        });
    }

    dismissModal = () => {
        this.isLoading = false;
        this.hasError = false;
        this.rating = 0;
        this.comment = "";
        this.ratingState = UserRatingStates.INACTIVE;
        this.errorMessage = undefined;
        this.doNotAskAgain = false;
    };

    sendFeedback = async () => {
        try {
            this.setIsLoading(true);
            const result = await apolloClientInstance.mutate<InsertUserRating, InsertUserRatingVariables>({
                mutation: INSERT_USER_RATING,
                variables: {
                    userid: this.rootStore.authStore.user?.userid,
                    rating: this.rating,
                    comment: this.comment,
                    system: ApplicationType.Portal
                }
            });
            if (!result.errors || result.errors.length === 0) {
                this.setRatingState(UserRatingStates.INACTIVE);
                this.dismissModal();
            }
        } catch (error) {
            console.error("error inserting user rating state: ", error);
        } finally {
            this.setIsLoading(false);
        }
    };

    deferFeedback = async () => {
        try {
            this.setIsLoading(true);
            const newState = this.doNotAskAgain ? UserRatingStates.INACTIVE : UserRatingStates.DEFERRED;
            const result = await apolloClientInstance.mutate<UpdateUserRatingstate, UpdateUserRatingstateVariables>({
                mutation: UPDATE_USER_RATING_STATE,
                variables: {
                    userid: this.rootStore.authStore.user?.userid,
                    state: newState
                }
            });
            if (!result.errors || result.errors.length === 0) {
                this.setRatingState(newState);
                this.dismissModal();
            }
        } catch (error) {
            console.error("error updating user rating state: ", error);
        } finally {
            this.setIsLoading(false);
        }
    };

    setComment = (comment: string) => {
        this.comment = comment;
    };

    setRating = (rating: number) => {
        this.rating = rating;
    };

    setRatingState = (state: UserRatingStates) => {
        this.ratingState = state;
    };

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };

    setDoNotAskAgain = (shouldAskAgain: boolean) => {
        this.doNotAskAgain = shouldAskAgain;
    };

    get isVisible(): boolean {
        // Momentan nur für Manager & Administrator anzeigen, später Owner und Coowner vllt.
        const hasUserRatingEnabledRole = this.rootStore.authStore.user?.availableRoles
            ? this.rootStore.authStore.user?.availableRoles?.includes(Role.MANAGER) ||
              this.rootStore.authStore.user?.availableRoles?.includes(Role.ADMINISTRATOR) ||
              this.rootStore.authStore.user?.availableRoles?.includes(Role.AUDITOR) ||
              this.rootStore.authStore.user?.availableRoles?.includes(Role.COOWNER) ||
              this.rootStore.authStore.user?.availableRoles?.includes(Role.OWNER)
            : false;
        return hasUserRatingEnabledRole && this.ratingState === UserRatingStates.ACTIVE;
    }
}
