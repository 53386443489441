import React, { useEffect, useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import * as Screens from "src/screens";
import {
    GetServiceDetailsByServiceType,
    GetServiceDetailsByServiceTypeVariables
} from "src/api/generated/GetServiceDetailsByServiceType";
import { GET_SERVICE_BY_SERVICETYPE } from "src/api/services";
import { ContactSales } from "src/components";
import { ServicesTiles, ServiceType } from "src/screens/services/ServicesTiles";

const ServicesFeaturesScreenBase = () => {
    const { navStore, authStore, uiStore, servicesStore } = useContext(RootStoreContext);
    const { refetchServicesData, setRefetchServicesData } = servicesStore;
    const { t } = useTranslation();

    useEffect(() => {
        navStore.setTitle("");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);
    }, [navStore, t, authStore.user?.role, uiStore.isSidebarActive]);

    const { data, loading, error, refetch } = useQuery<
        GetServiceDetailsByServiceType,
        GetServiceDetailsByServiceTypeVariables
    >(GET_SERVICE_BY_SERVICETYPE, {
        variables: {
            servicetype: ServiceType.FEATURE,
            language: "de"
        }
    });

    useEffect(() => {
        if (refetchServicesData) {
            setRefetchServicesData(false);
            refetch();
        }
    }, [setRefetchServicesData, refetchServicesData, refetch]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    return (
        <UI.Container className="pt-4">
            <UI.Title>{t("screens.services.features_and_integrations.features.title")}</UI.Title>
            <UI.Row>
                <ServicesTiles
                    customerServices={data.uccm_v_customerservices_tiles}
                    serviceType={ServiceType.FEATURE}
                />
                <ContactSales />
            </UI.Row>
        </UI.Container>
    );
};

export const ServicesFeaturesScreen = Sentry.withProfiler(observer(ServicesFeaturesScreenBase));
