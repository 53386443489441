import React, { useContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import { InvoiceWithCostType } from "src/screens/services/costsAndInvoices/currentMonth/ServicesCostsAndInvoicesCurrentMonth";
import { GetCustomerInvoicesByDate_costtype_enumeration as costTypeEnumeration } from "src/api/generated/GetCustomerInvoicesByDate";
import { useLocation } from "react-router-dom";
import { Route, selectRoute } from "src/config/routes";
import { useParams } from "react-router";
import { getCurrencyElement } from "src/utils/Currency";

interface InvoiceDetailsListProps {
    invoiceItems: InvoiceWithCostType[];
    costTypeEnumeration: costTypeEnumeration[];
}

enum COST_TYPE {
    USERS = 1,
    RENTALS_EMONITOR_VAR = 2,
    RENTALS_EMONITOR_FIX = 3
}

interface Params {
    year: string;
    month: string;
}

const InvoiceDetailsList = (props: InvoiceDetailsListProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore, authStore } = useContext(RootStoreContext);
    const { invoiceItems } = props;
    const { year, month } = useParams<Params>();

    const location = useLocation();

    const listIdentifier = "ServicesCostsAndInvoicesCurrentMonthList";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    const [costTypeEnumerationStatus, setCostTypeEnumerationStatus] =
        useState<Map<number | undefined, { label: string }>>();

    useEffect(() => {
        if (props.costTypeEnumeration) {
            setCostTypeEnumerationStatus(getEnumerationMap(props.costTypeEnumeration));
        }
    }, [props.costTypeEnumeration]);

    const getEnumerationMap = (enumerations: Array<costTypeEnumeration>) => {
        const enumerationMap = new Map();

        enumerations.forEach((enumeration) => {
            if (enumeration.key !== null) {
                enumerationMap.set(enumeration.key, {
                    label: enumeration.label
                });
            }
        });

        return enumerationMap;
    };

    const renderItem = useCallback(
        (invoiceDetail: InvoiceWithCostType, screenSize: ScreenSize) => {
            const { costType, count, costPerUnit, total, isTotalRow, customerInvoiceId, countUserInvoiced } =
                invoiceDetail;

            const costPerUnitValue = getCurrencyElement(costPerUnit);
            const totalValue = getCurrencyElement(total);

            let costTypeValue = costTypeEnumerationStatus?.get(costType)?.label;

            if (isTotalRow) {
                costTypeValue = t("screens.services.costs_and_invoices.list.total").toString().toLocaleUpperCase();
            }

            let listRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={3}
                                value={costTypeValue ?? ""}
                                className={isTotalRow ? "bold" : ""}
                            />
                            <UI.List.Cell key={"c-2"} colspan={1} value={count ?? ""} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={costPerUnitValue ?? ""} />
                            <UI.List.Cell
                                key={"c-4"}
                                colspan={1}
                                value={totalValue ?? ""}
                                className={isTotalRow ? "bold" : ""}
                            />
                        </UI.List.Row>
                    ];
                    break;

                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={3}
                                value={costTypeValue ?? ""}
                                className={isTotalRow ? "bold" : ""}
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1}
                                value={totalValue ?? ""}
                                className={isTotalRow ? "bold" : ""}
                            />
                        </UI.List.Row>
                    ];
                    break;
            }

            let rowLinkUrl: string | undefined = undefined;
            const onHistoryPath = location.pathname.includes(Route.servicesCostsAndInvoicesHistory);

            if (costType === COST_TYPE.USERS && customerInvoiceId && countUserInvoiced > 0) {
                rowLinkUrl = onHistoryPath
                    ? selectRoute(Route.servicesCostsAndInvoicesHistoryMonthInvoicedUserDetails, authStore.user?.role, {
                          customerinvoiceid: customerInvoiceId?.toString() ?? "",
                          year,
                          month
                      })
                    : selectRoute(Route.servicesCostsAndInvoicesCurrentMonthInvoicedUserDetails, authStore.user?.role, {
                          customerinvoiceid: customerInvoiceId?.toString() ?? ""
                      });
            }

            if (costType === COST_TYPE.RENTALS_EMONITOR_VAR) {
                rowLinkUrl = onHistoryPath
                    ? selectRoute(
                          Route.servicesCostsAndInvoicesHistoryMonthInvoicedRentalVariableDetails,
                          authStore.user?.role,
                          { year, month }
                      )
                    : selectRoute(
                          Route.servicesCostsAndInvoicesCurrentMonthInvoicedRentalVariableDetails,
                          authStore.user?.role
                      );
            }

            return <UI.List.Item key={costTypeValue} screenSize={screenSize} rows={listRows} to={rowLinkUrl} />;
        },
        [costTypeEnumerationStatus, t, location.pathname, authStore.user?.role, month, year]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={3}
                                value={t("screens.services.costs_and_invoices.list.position")}
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1}
                                value={t("screens.services.costs_and_invoices.list.count")}
                            />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={1}
                                value={t("screens.services.costs_and_invoices.list.amountperunit")}
                            />
                            <UI.List.Cell
                                key={"c-4"}
                                colspan={1}
                                value={t("screens.services.costs_and_invoices.list.totalmonth")}
                            />
                        </UI.List.Row>
                    ];
                    break;

                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={3}
                                value={t("screens.services.costs_and_invoices.list.position")}
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1}
                                value={t("screens.services.costs_and_invoices.list.total")}
                            />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} />;
        },
        [t]
    );

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={invoiceItems}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
        />
    );
};

export default observer(InvoiceDetailsList);
