import { action, computed, makeObservable, observable } from "mobx";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey } from "src/network/User";
import { RootStore } from "src/stores/RootStore";

export enum SERVICE_ACTIVATION_PROCESS {
    START_SERVICE_ACTIVATION,
    SERVICE_ACTIVATION_RESULT
}

export enum SERVICE_ACTIVATION_PROCESS_STEP {
    START_SERVICE_ACTIVATION,
    SERVICE_ACTIVATION_RESULT
}

export enum ACTIVATION_STATUS {
    NONE,
    ACTIVATING,
    SUCCESS,
    FAILURE
}

interface IProcessStep {
    stepNumber: number;
    stepType: SERVICE_ACTIVATION_PROCESS_STEP;
    next?: () => IProcessStep | null;
    prev?: () => IProcessStep | null;
    parent: IProcess | null;
}

interface IProcess {
    type: SERVICE_ACTIVATION_PROCESS;
    processSteps: IProcessStep[];
}

interface IServiceActivationProcess {
    stepNumber: number;
    stepType: SERVICE_ACTIVATION_PROCESS_STEP;
    processes: IProcess[];
}

export class ServicesActivationProgressModalStore {
    rootStore: RootStore;

    ACTIVATION_MODAL_MAX_NUMBER_OF_STEPS = 2;

    currentProcessType: SERVICE_ACTIVATION_PROCESS = SERVICE_ACTIVATION_PROCESS.START_SERVICE_ACTIVATION;
    currentStepType: SERVICE_ACTIVATION_PROCESS_STEP = SERVICE_ACTIVATION_PROCESS_STEP.START_SERVICE_ACTIVATION;
    currentServiceActivationStatus: ACTIVATION_STATUS = ACTIVATION_STATUS.NONE;
    isActivationProgressModalDisplayed = false;
    isServiceSuccessfullyActivated = false;
    costMonth = "";
    serviceActivationProcess: IServiceActivationProcess = {
        stepNumber: 1,
        stepType: SERVICE_ACTIVATION_PROCESS_STEP.START_SERVICE_ACTIVATION,
        processes: [
            {
                type: SERVICE_ACTIVATION_PROCESS.SERVICE_ACTIVATION_RESULT,
                processSteps: [
                    {
                        stepNumber: 2,
                        stepType: SERVICE_ACTIVATION_PROCESS_STEP.SERVICE_ACTIVATION_RESULT,
                        next: function () {
                            return this.parent ? this.parent.processSteps[1] : null;
                        },
                        prev: function () {
                            return this.parent ? this.parent.processSteps[0] : null;
                        },
                        parent: null
                    }
                ]
            }
        ]
    };

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        // Assign parents post object creation
        for (let process of this.serviceActivationProcess.processes) {
            for (let step of process.processSteps) {
                step.parent = process;
            }
        }

        makeObservable(this, {
            currentProcessType: observable,
            currentStepType: observable,
            currentServiceActivationStatus: observable,
            isActivationProgressModalDisplayed: observable,
            isServiceSuccessfullyActivated: observable,

            getProcess: action,
            resetServiceActivationModal: action,
            resetCurrentProcessType: action,
            setCurrentProcessType: action,
            setCurrentStepType: action,
            setCurrentServiceActivationStatus: action,
            setIsActivationProgressModalDisplayed: action,
            setIsServiceSuccessfullyActivated: action,
            setCostMonth: action,

            processes: computed,
            currentProcess: computed,
            currentStep: computed
        });
    }

    getProcess = (processType: SERVICE_ACTIVATION_PROCESS) => {
        return this.serviceActivationProcess.processes.find((process) => {
            return process.type === processType;
        })!;
    };

    resetServiceActivationModal = () => {
        this.resetCurrentProcessType();
        this.currentServiceActivationStatus = ACTIVATION_STATUS.NONE;
    };

    resetCurrentProcessType() {
        this.currentProcessType = SERVICE_ACTIVATION_PROCESS.SERVICE_ACTIVATION_RESULT;
        this.currentStepType = SERVICE_ACTIVATION_PROCESS_STEP.SERVICE_ACTIVATION_RESULT;
    }

    setCurrentProcessType = (processType: SERVICE_ACTIVATION_PROCESS) => {
        this.currentProcessType = processType;
        this.currentStepType = this.getProcess(processType).processSteps[0].stepType;
    };

    setCurrentStepType = (stepType: SERVICE_ACTIVATION_PROCESS_STEP) => {
        this.currentStepType = stepType;
    };

    setIsActivationProgressModalDisplayed = (isModalDisplayed: boolean) => {
        this.isActivationProgressModalDisplayed = isModalDisplayed;
    };

    setCurrentServiceActivationStatus = (status: ACTIVATION_STATUS) => {
        this.currentServiceActivationStatus = status;
    };
    setIsServiceSuccessfullyActivated = (isServiceSuccessfullyActivated: boolean) => {
        this.isServiceSuccessfullyActivated = isServiceSuccessfullyActivated;
    };

    /* COMPUTED */
    get processes() {
        return this.serviceActivationProcess.processes;
    }

    get currentProcess(): IProcess | undefined {
        return this.serviceActivationProcess.processes.find((process) => process.type === this.currentProcessType);
    }

    get currentStep(): IProcessStep | undefined {
        return this.currentProcess?.processSteps.find((step) => step.stepType === this.currentStepType);
    }

    activateServices = async (serviceid: string): Promise<boolean> => {
        this.setIsServiceSuccessfullyActivated(false);

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const requestBody = {
                conditions: {
                    licensedUnits: this.rootStore.navStore.customer.licencedunits || 0,
                    costMonth: this.costMonth
                }
            };

            const response = await fetch(NetworkConfig.activateService + serviceid, {
                method: "POST",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (response.status === 200) {
                this.setIsServiceSuccessfullyActivated(true);
                this.rootStore.servicesStore.setRefetchServicesData(true);
            } else {
                console.error("Error activate_service: ", response);
            }
        } catch (error) {
            console.error("Error while trying to call activate_service: ", error);
        }

        return this.isServiceSuccessfullyActivated;
    };

    getTicketingCostMonth = async (): Promise<string> => {
        let ticketingCostMonth = undefined;

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const requestBody = {
                licensedUnits: this.rootStore.navStore.customer.licencedunits || 0
            };

            const response = await fetch(NetworkConfig.getTicketingCostMonth, {
                method: "POST",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            const responseText = await response.text();

            if (response.status === 200) {
                ticketingCostMonth = responseText;
            } else {
                console.error("Error getticketingcost: ", response);
            }
        } catch (error) {
            console.error("Error while trying to call getticketingcost: ", error);
        }

        return ticketingCostMonth || "";
    };

    setCostMonth = () => {
        const costsMonth = this.getTicketingCostMonth();
        const costValue = "0";

        costsMonth.then((result) => {
            // Parse the JSON string to an object
            const resultObject = JSON.parse(result);

            const roundedCostValue = resultObject.cost.toFixed(2);
            this.costMonth = roundedCostValue.toString();
        });
        this.costMonth = costValue.toString();
    };
}
