import React, { useCallback, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { UI } from "@wwimmo/ui";
import { observer } from "mobx-react-lite";
import { GetAnnouncementHistory_announcements as announcements } from "src/api/generated/GetAnnouncementHistory";
import "src/components/featureAnnouncementBanner/FeatureAnnouncementBanner.css";

interface AnnouncementHistoryModalProperties {
    historyAnnouncements: announcements[];
    isModalDisplayed: boolean;
    onCloseAnnouncementModal: any;
}

const INITIALLY_DISPLAYED_ANNOUNCEMENTS = 10;
const MINIMUM_CHARACTERS_FOR_SEARCH = 3;

const AnnouncementHistoryModalBase = (props: AnnouncementHistoryModalProperties) => {
    const { t } = useTranslation();

    const [lastScrollPositionY, setLastSrollYPosition] = useState<number>(0);

    const [displayedAnnouncements, setDisplayedAnnouncements] = useState<Array<announcements>>(
        props.historyAnnouncements.length > INITIALLY_DISPLAYED_ANNOUNCEMENTS
            ? props.historyAnnouncements.slice(0, INITIALLY_DISPLAYED_ANNOUNCEMENTS)
            : props.historyAnnouncements
    );

    const filteredDisplayedAnnouncements = displayedAnnouncements.filter((displayedAccounement) => {
        return displayedAccounement.announcement.length > 0;
    });

    const [searchQuery, setSearchQuery] = useState<string>("");

    const handleScroll = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is100PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 100;
            const isScrollingDown = pixelsFromTop > lastScrollPositionY;
            const isSearchResultDisplayed = searchQuery.length > MINIMUM_CHARACTERS_FOR_SEARCH;

            if (is100PixelsFromBottom && isScrollingDown && !isSearchResultDisplayed) {
                const currentNumberOfDisplayedAnnouncements = displayedAnnouncements.length;
                const sliceEnd =
                    currentNumberOfDisplayedAnnouncements + INITIALLY_DISPLAYED_ANNOUNCEMENTS >
                    props.historyAnnouncements.length
                        ? props.historyAnnouncements.length
                        : currentNumberOfDisplayedAnnouncements + INITIALLY_DISPLAYED_ANNOUNCEMENTS;

                setDisplayedAnnouncements(props.historyAnnouncements.slice(0, sliceEnd));
            }

            setLastSrollYPosition(pixelsFromTop);
        },
        [lastScrollPositionY, displayedAnnouncements.length, props.historyAnnouncements, searchQuery.length]
    );

    const onSearch = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const currentSearchQuery = e.target.value;
            setSearchQuery(currentSearchQuery);

            if (currentSearchQuery.length >= MINIMUM_CHARACTERS_FOR_SEARCH && props.historyAnnouncements.length > 0) {
                const foundItems = props.historyAnnouncements.filter((historyAnnouncement) => {
                    const announcementContent =
                        historyAnnouncement.announcement.length > 0 ? historyAnnouncement.announcement[0].content : "";

                    return announcementContent.toLowerCase().includes(currentSearchQuery.toLowerCase());
                });

                setDisplayedAnnouncements([...foundItems]);
            } else {
                setDisplayedAnnouncements([...props.historyAnnouncements]);
            }
        },
        [props.historyAnnouncements]
    );

    return (
        <UI.Modal
            show={props.isModalDisplayed}
            onClose={props.onCloseAnnouncementModal}
            size="lg"
            isScrollable={true}
            onScroll={handleScroll}
        >
            <div style={{ width: "96%" }}>
                <UI.Input type="search" onChange={onSearch} placeholder={t("labels.search")} value={searchQuery} />
            </div>
            <div className="mb-4" />
            {filteredDisplayedAnnouncements.map((displayedAnnouncement, index) => {
                return (
                    <div key={`announcement-${index}`}>
                        <div
                            className="AnnouncmentModalContent"
                            dangerouslySetInnerHTML={{
                                __html: displayedAnnouncement.announcement[0].content ?? ""
                            }}
                        />
                        <hr />
                    </div>
                );
            })}
            <UI.Button
                className="AnnouncementModalButton"
                label={t("labels.ok").toUpperCase()}
                onClick={props.onCloseAnnouncementModal}
            />
        </UI.Modal>
    );
};

export const AnnouncementHistoryModal = Sentry.withProfiler(observer(AnnouncementHistoryModalBase));
