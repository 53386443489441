import React, { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { Route, selectRoute } from "src/config/routes";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { useQuery } from "@apollo/client";
import {
    GET_COMMON_REALESTATES,
    GET_COMMON_REALESTATES_COOWNER_IT2,
    GET_COMMON_REALESTATES_BY_OWNERID,
    GET_COMMON_REALESTATES_TENANT,
    GET_COMMON_REALESTATES_TENANT_IT2
} from "src/api/realestates";
import * as Screens from "src/screens";
import { NetworkConfig } from "src/network/NetworkConfig";
import {
    CommonRealestates_common_realestates,
    CommonRealestates_common_realestates_realestatefiles
} from "src/api/generated/CommonRealestates";
import {
    CommonRealestatesByOwnerId,
    CommonRealestatesByOwnerIdVariables
} from "src/api/generated/CommonRealestatesByOwnerId";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { runInAction } from "mobx";
import { ColorStyle } from "src/utils/Colors";
import { Role } from "src/network/User";
import { RealestatesListType } from "src/stores/UIStore";
import { ErpType } from "src/network/User";
import { getRoleByRoleId } from "src/network/User";
import { useRoleChanger } from "src/hooks/role-changer/useRoleChanger";
import styles from "./Realestates.module.css";

interface MatchParams {
    ownerid: string;
}

interface SortItem {
    value: string;
    label: string;
}

enum RealestateSortKey {
    MANDANT = "1",
    REALESTATE = "2"
}

export const RealestatesBase = (props: RouteComponentProps<MatchParams>) => {
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [currentSortLabel, setCurrentSortLabel] = useState<string>("");
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);

    const { loading, error, data } = useQuery<CommonRealestatesByOwnerId, CommonRealestatesByOwnerIdVariables>(
        props.match.params.ownerid
            ? GET_COMMON_REALESTATES_BY_OWNERID
            : authStore.user?.role === Role.TENANT && authStore.user.erpType === ErpType.IT2
            ? GET_COMMON_REALESTATES_TENANT_IT2
            : authStore.user?.role === Role.TENANT
            ? GET_COMMON_REALESTATES_TENANT
            : (authStore.user?.hasOnlyCoownerRole && authStore.user.erpType === ErpType.IT2) ||
              (authStore.user?.hasCoownerRole && authStore.user.erpType === ErpType.IT2)
            ? GET_COMMON_REALESTATES_COOWNER_IT2
            : GET_COMMON_REALESTATES,
        {
            variables: {
                ownerid: props.match.params.ownerid
            }
        }
    );

    const onChangeListType = useCallback(
        (listType: RealestatesListType) => () => {
            uiStore.setRealestatesViewType(listType);
        },
        [uiStore]
    );

    const [currentSortKey, setCurrentSortKey] = useState<RealestateSortKey>(RealestateSortKey.MANDANT);

    const sortRealestatesDropdownItems: SortItem[] = useMemo(() => {
        return [
            { value: RealestateSortKey.MANDANT, label: "Mandant" },
            { value: RealestateSortKey.REALESTATE, label: "Liegenschaft" }
        ];
    }, []);

    const { realestatesViewType } = uiStore;

    const { setActionButtonMiddle, setActionButtonRight, setActionButtonLeft } = navStore;

    useRoleChanger();

    useEffect(() => {
        const renderActionButton = (type: RealestatesListType, activeType: RealestatesListType) => {
            return (
                <UI.Icon
                    icon={type === RealestatesListType.GRID ? UI.SVGIcon.GridView : UI.SVGIcon.ListView}
                    color={ColorStyle(activeType === type ? "on-primary" : "primary400")}
                    onClick={onChangeListType(type)}
                />
            );
        };
        runInAction(() => {
            setActionButtonMiddle(renderActionButton(RealestatesListType.LIST, realestatesViewType));
            setActionButtonRight(renderActionButton(RealestatesListType.GRID, realestatesViewType));
        });
    }, [realestatesViewType, setActionButtonMiddle, setActionButtonRight, onChangeListType]);

    useEffect(() => {
        // Page Settings
        runInAction(() => {
            setActionButtonLeft(null);
        });

        if (
            data?.common_realestates.length === 1 &&
            (authStore.user?.role === Role.OWNER ||
                authStore.user?.role === Role.COOWNER ||
                authStore.user?.role === Role.AUDITOR ||
                authStore.user?.role === Role.TENANT)
        ) {
            navStore.setSingleRealEstate(true);
        } else {
            navStore.setSingleRealEstate(false);
        }
        if (props.match.params.ownerid && authStore.user) {
            runInAction(() => {
                navStore.setBackbutton(selectRoute(Route.owners, authStore.user?.role), t("screens.owners.title"));
                navStore.setCurrentOwnerId(props.match.params.ownerid);
                navStore.setTitle(t("screens.realestates.title"));
            });
        } else {
            runInAction(() => {
                navStore.setBackbutton(null);
                navStore.setCurrentOwnerId(null);
                navStore.setTitle(t("screens.realestates.title"));
            });
        }
    }, [
        uiStore,
        uiStore.realestatesViewType,
        navStore,
        authStore.user,
        t,
        data,
        props.match.params.ownerid,
        onChangeListType,
        setActionButtonLeft
    ]);

    const onChangeQueryInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    }, []);

    const getRealestateCompareValue = useCallback(
        (
            realestateA: CommonRealestates_common_realestates,
            realestateB: CommonRealestates_common_realestates,
            compareType: RealestateSortKey
        ) => {
            let valueRealestateA = "";
            let valueRealestateB = "";

            switch (compareType) {
                case RealestateSortKey.MANDANT:
                    valueRealestateA = realestateA.number;
                    valueRealestateB = realestateB.number;
                    break;
                case RealestateSortKey.REALESTATE:
                    valueRealestateA = realestateA.name;
                    valueRealestateB = realestateB.name;
                    break;
                default:
                    break;
            }

            if (valueRealestateA < valueRealestateB) {
                return -1;
            }

            if (valueRealestateA > valueRealestateB) {
                return 1;
            }

            return 0;
        },
        []
    );

    const onChangeSortType = useCallback(
        (sortValue: RealestateSortKey) => {
            setCurrentSortKey(sortValue);

            const newDropdownLabel = sortRealestatesDropdownItems.find(
                (sortItem: SortItem) => sortItem.value === sortValue
            )?.label;

            setCurrentSortLabel(newDropdownLabel ?? "");
        },
        [sortRealestatesDropdownItems]
    );

    const getRoleWithHighestPriority = useCallback((realestate: CommonRealestates_common_realestates): Role => {
        let roleWithHighestPriority: Role = Role.NONE;

        if (
            realestate.realestateaccesses &&
            realestate.realestateaccesses.length > 0 &&
            realestate.realestateaccesses[0].role
        ) {
            const roleNumberWithHighestPriority = realestate.realestateaccesses[0].role;
            roleWithHighestPriority = getRoleByRoleId(roleNumberWithHighestPriority);
        }

        return roleWithHighestPriority;
    }, []);

    if (loading) return <Screens.Loading fullscreen />;
    if (error) return <Screens.Error message={error.message} />;
    if (!data) return <Screens.Error message={t("error.nodata")} />;

    //Only one Realestate and the User is Owner or Coowner makes a redirect
    if (
        data.common_realestates.length === 1 &&
        (authStore.user?.role === Role.OWNER ||
            authStore.user?.role === Role.COOWNER ||
            authStore.user?.role === Role.TENANT)
    ) {
        const currentRole =
            authStore.user?.role === Role.TENANT
                ? Role.TENANT
                : authStore.user?.role === Role.OWNER
                ? Role.OWNER
                : Role.COOWNER;

        return (
            <Redirect to={selectRoute(Route.dashboard, currentRole, { realestateid: data.common_realestates[0].id })} />
        );
    }
    //Only one Realestate and the User is Auditor makes a redirect
    else if (data.common_realestates.length === 1 && authStore.user?.role === Role.AUDITOR) {
        return (
            <Redirect to={selectRoute(Route.finances, Role.AUDITOR, { realestateid: data.common_realestates[0].id })} />
        );
    }

    const renderThumbnail = (
        realestate: CommonRealestates_common_realestates,
        files: CommonRealestates_common_realestates_realestatefiles[],
        subtitle: string | undefined
    ) => {
        let thumbnail = null;

        const roleWithHighestPriority = getRoleWithHighestPriority(realestate);

        if (realestate.consolidation) {
            thumbnail = (
                <div className="d-flex align-items-center justify-content-center">
                    <UI.Icon
                        icon={UI.SVGIcon.Consolidated}
                        size={200}
                        circular
                        color={ColorStyle("grey")}
                        backgroundColor="#D2D5D9"
                    />
                </div>
            );
        } else {
            thumbnail = <UI.Thumbnail />;

            const thumbnailFile = files.find((file) => files.length > 0 && files[0].realestateid === realestate.id);
            if (thumbnailFile) {
                const thumbnailUrl = NetworkConfig.openThumbnailUrl + thumbnailFile.fileid;
                thumbnail = <UI.Thumbnail src={thumbnailUrl} />;
            }
        }

        if (authStore.user?.hasCoownerAuditorRole && authStore.user.erpType === ErpType.IT2) {
            if (realestate.realestateaccesses[0].role === 36) {
                return (
                    <UI.Card
                        title={realestate.name || undefined}
                        subtitle={subtitle ?? ""}
                        to={selectRoute(Route.finances, roleWithHighestPriority ?? authStore.user?.role, {
                            realestateid: realestate.id
                        })}
                        square
                        realestateNumber={realestate.number}
                    >
                        {thumbnail}
                    </UI.Card>
                );
            } else if (realestate.realestateaccesses[0].role === 32) {
                return (
                    <UI.Card
                        title={realestate.name || undefined}
                        subtitle={subtitle ?? ""}
                        to={selectRoute(Route.dashboard, roleWithHighestPriority, { realestateid: realestate.id })}
                        square
                        realestateNumber={realestate.number}
                    >
                        {thumbnail}
                    </UI.Card>
                );
            }
        }
        if (authStore.user?.role === Role.TENANT) {
            return (
                <UI.Card
                    title={realestate.name || undefined}
                    subtitle={subtitle ?? ""}
                    to={selectRoute(Route.dashboard, Role.TENANT, { realestateid: realestate.id })}
                    square
                    realestateNumber={realestate.number}
                >
                    {thumbnail}
                </UI.Card>
            );
        }
        if (authStore.user?.role === Role.AUDITOR) {
            return (
                <UI.Card
                    title={realestate.name || undefined}
                    subtitle={subtitle ?? ""}
                    to={selectRoute(Route.finances, roleWithHighestPriority ?? authStore.user?.role, {
                        realestateid: realestate.id
                    })}
                    square
                    realestateNumber={realestate.number}
                >
                    {thumbnail}
                </UI.Card>
            );
        } else {
            return (
                <UI.Card
                    title={realestate.name || undefined}
                    subtitle={subtitle ?? ""}
                    to={selectRoute(Route.dashboard, roleWithHighestPriority, { realestateid: realestate.id })}
                    square
                    realestateNumber={realestate.number}
                >
                    {thumbnail}
                </UI.Card>
            );
        }
    };

    const renderListItem = (realestate: CommonRealestates_common_realestates, subtitle: string | undefined) => {
        const roleWithHighestPriority = getRoleWithHighestPriority(realestate);

        return (
            <UI.Card
                title={realestate.name || undefined}
                subtitle={subtitle ?? ""}
                to={selectRoute(Route.dashboard, roleWithHighestPriority ?? authStore.user?.role, {
                    realestateid: realestate.id
                })}
                flex
                realestateNumber={realestate.number}
            />
        );
    };

    const searchInput = (
        <UI.Input
            type="search"
            placeholder={t("labels.search")}
            className={`${styles.realestateSearchInput} m-0`}
            onChange={onChangeQueryInput}
        />
    );

    const searchBarRimo =
        data.common_realestates.length > 10 ? (
            <UI.Row>
                <UI.Col
                    md={
                        uiStore.realestatesViewType === RealestatesListType.LIST
                            ? { span: 6, offset: 3 }
                            : { span: 12, offset: 0 }
                    }
                >
                    <UI.Card flex={true}>{searchInput}</UI.Card>
                </UI.Col>
            </UI.Row>
        ) : undefined;

    const searchBarIT2 = (
        <UI.Card flex={true}>
            <UI.Row>
                <UI.Col md={7}>{searchInput}</UI.Col>
                <UI.Col md={5}>
                    <div className={`mt-3 mt-md-0 d-flex align-items-center ${styles.dropdownContainer}`}>
                        <div className={`mr-3 ${styles.sortText}`}>{t("screens.realestates.sorted_by")}</div>
                        <div className={styles.sortDropdown}>
                            <UI.Dropdown
                                id="realestate-sort-dropdown"
                                items={sortRealestatesDropdownItems}
                                label={currentSortLabel}
                                defaultLabel={sortRealestatesDropdownItems[0].label}
                                onSelect={onChangeSortType}
                            />
                        </div>
                    </div>
                </UI.Col>
            </UI.Row>
        </UI.Card>
    );

    return (
        <UI.Container className="pt-md-4">
            {authStore.user?.erpType === ErpType.RIMO ? searchBarRimo : searchBarIT2}
            <UI.Row>
                {data.common_realestates
                    .filter(
                        (realestate) =>
                            realestate.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            realestate.number.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .sort((realestateA, realestateB) => {
                        if (authStore.user?.erpType === ErpType.RIMO) {
                            return getRealestateCompareValue(realestateA, realestateB, RealestateSortKey.REALESTATE);
                        } else {
                            return getRealestateCompareValue(realestateA, realestateB, currentSortKey);
                        }
                    })
                    .map((realestate) => {
                        let subtitle: string | undefined = undefined;

                        if (realestate.houses.length !== 0) {
                            subtitle = `${realestate.houses[0].zip ?? ""} ${realestate.houses[0].city ?? ""}`;
                        }

                        return uiStore.realestatesViewType === RealestatesListType.GRID ? (
                            <UI.Col key={realestate.id} sm="6" lg="4" xl="3" className="d-flex flex-column flex-grow-1">
                                {renderThumbnail(realestate, realestate.realestatefiles, subtitle)}
                            </UI.Col>
                        ) : (
                            <UI.Col key={realestate.id} md={{ span: 6, offset: 3 }}>
                                {renderListItem(realestate, subtitle)}
                            </UI.Col>
                        );
                    })}
            </UI.Row>
        </UI.Container>
    );
};

export const Realestates = Sentry.withProfiler(observer(RealestatesBase));
