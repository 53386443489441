import { UI } from "@wwimmo/ui";
import { useCallback, useState, useContext } from "react";
import styles from "./NotificationStatusModal.module.css";
import { useTranslation } from "react-i18next";
import { NotificationStatusModalList } from "./notificationStatusModalList/NotificationStatusModalList";
import { INotificationStatus } from "src/stores/NotificationStore";
import { RootStoreContext } from "src/stores/RootStore";

export const NotificationStatusModal = () => {
    const { t } = useTranslation();
    const { notificationStore } = useContext(RootStoreContext);
    const [isStatusNotiDisplayed, setIsStatusNotiDisplayed] = useState<boolean>(false);

    const onClickStatusNoti = useCallback(async (e: any) => {
        e.preventDefault();
        setIsStatusNotiDisplayed(true);
    }, []);

    const onCloseStatusNotiModal = useCallback(() => {
        setIsStatusNotiDisplayed(false);
    }, []);

    const notificationStates: INotificationStatus[] = [];

    return (
        <div className={styles.DivNotiButton}>
            <UI.Button
                className={notificationStore.isNewsOrAssemblyState === 0 ? styles.NewsNotiButton : styles.NotiButton}
                variant="outline-primary"
                label={t("screens.realestate.notifications.button_notification")}
                onClick={onClickStatusNoti}
            />
            <UI.Modal show={isStatusNotiDisplayed} onClose={onCloseStatusNotiModal} isScrollable={true} size="lg">
                <div>
                    <NotificationStatusModalList notificationStatusList={notificationStates} />
                </div>
                <div className={styles.DivModalNotiButton}>
                    <UI.Button
                        className={styles.ModalNotiButton}
                        label={t("screens.realestate.notifications.ok")}
                        onClick={onCloseStatusNotiModal}
                        variant="outline-primary"
                    />
                </div>
            </UI.Modal>
        </div>
    );
};
