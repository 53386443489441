import { NetworkConfig } from "src/network/NetworkConfig";

export interface GetCitiesParameters {
    zip?: string;
    city?: string;
}

export interface GetStreetsInCityParameters {
    zip: string;
    streetName?: string;
}

export interface GetStreetNumbersInStreetParameters {
    streetId: string;
    streetNumber?: string;
}

export const GetCities = async (getCityParameters: GetCitiesParameters) => {
    try {
        const cityQuery = getCityParameters.city ? `${getCityParameters.city}%` : "%";
        const zipQuery = getCityParameters.zip ? `${getCityParameters.zip}%` : "%";

        const response = await fetch(`${NetworkConfig.getCitiesUrl}?city=${cityQuery}&zip=${zipQuery}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const result = await response.json();
        if (response.ok) {
            return result.cities;
        } else {
            console.error(`Error getting cities: Error: ${result.error}, Code: ${result.code}`);
            return [];
        }
    } catch (err) {
        console.error("Fetch error occured: ", err);
        return [];
    }
};

export const GetCitiesByName = async (query: string) => {
    return await GetCities({ city: query });
};

export const GetCitiesByZip = async (query: string) => {
    return await GetCities({ zip: query });
};

export const GetStreetsInCity = async (getStreetsInCityParameters: GetStreetsInCityParameters) => {
    try {
        const streetNameQuery = getStreetsInCityParameters.streetName ?? "";

        const response = await fetch(
            `${NetworkConfig.getStreetsInCityUrl}?zip=${getStreetsInCityParameters.zip}&streetname=${streetNameQuery}%`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );

        const result = await response.json();

        if (response.ok) {
            const foundStreets = result.streets
                .map((street: any) => {
                    return {
                        id: street.id,
                        street: street.name
                    };
                })
                .sort((streetA: any, streetB: any) => {
                    if (streetA.street < streetB.street) {
                        return -1;
                    }

                    if (streetA.street > streetB.street) {
                        return 1;
                    }

                    return 0;
                });

            return foundStreets;
        } else {
            console.log(result);
            console.error(`Error getting streets: Error: ${result}`);
            return [];
        }
    } catch (err) {
        console.error("Fetch error occured: ", err);
        return [];
    }
};

export const GetStreetNumbersInStreet = async (streetid: string) => {
    try {
        const response = await fetch(`${NetworkConfig.getStreetNumbersInStreetUrl}?streetid=${streetid}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const result = await response.json();

        if (response.ok) {
            let foundStreetNumbers = result.streetnumbers[0].numbers;

            var collator = new Intl.Collator("de-ch", { numeric: true, sensitivity: "base" });
            foundStreetNumbers.sort(collator.compare);
            foundStreetNumbers = foundStreetNumbers.filter((number: string) => number !== "");

            return foundStreetNumbers;
        } else {
            console.log(result);
            console.error(`Error getting street numbers: Error: ${result}`);
            return [];
        }
    } catch (err) {
        console.error("Fetch error occured: ", err);
        return [];
    }
};
