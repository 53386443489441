import React, { useCallback, useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./AssignOrderModal.module.css";
import { startCase } from "lodash";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { ASSIGN_ORDER_PROCESS, ASSIGN_ORDER_PROCESS_STEP } from "src/stores/tickets/AssignOrderModalStore";
import { SelectServiceProvider } from "./selectServiceProvider/SelectServiceProvider";
import { ServiceOrderDescription } from "./serviceOrderDescription/ServiceOrderDescription";
import { YarowaDescription } from "./yarowaDescription/YarowaDescription";
import { YarowaSubmit } from "./yarowaSubmit/YarowaSubmit";
import { Service7000Submit } from "./service7000Submit/Service7000Submit";
import { Service7000Description } from "./service7000Description/Service7000Description";

interface AssignOrderModalProps {
    showModal: boolean;
    onClickClose: () => void;
}
export const AssignOrderModalBase = (props: AssignOrderModalProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore, assignOrderModalStore } = useContext(RootStoreContext);
    const { showModal, onClickClose } = props;

    const onDismissModal = useCallback(() => {
        onClickClose();
    }, [onClickClose]);

    const onClickProcess = useCallback(
        (processType: ASSIGN_ORDER_PROCESS) => () => {
            assignOrderModalStore.setCurrentProcessTypeAndTitle(processType);
        },
        [assignOrderModalStore]
    );

    const selectServiceProviderEntityContent = (
        <>
            <UI.Row className={"mt-2"}>
                <UI.Col xs={12} md={6}>
                    <UI.Button
                        className={"ticket-button mt-2"}
                        label={t("screens.tickets.modals.service_provider")}
                        onClick={onClickProcess(ASSIGN_ORDER_PROCESS.SERVICE_PROVIDER)}
                    ></UI.Button>
                </UI.Col>
                {navStore.hasFeatureYarowa ? (
                    <UI.Col xs={12} md={6}>
                        <UI.Button
                            className={"ticket-button mt-2"}
                            label={t("screens.tickets.modals.yarowa")}
                            onClick={onClickProcess(ASSIGN_ORDER_PROCESS.YAROWA)}
                        ></UI.Button>
                    </UI.Col>
                ) : undefined}
                {navStore.hasFeatureService7000 ? (
                    <UI.Col xs={12} md={6}>
                        <UI.Button
                            className={"ticket-button mt-2"}
                            label={t("screens.tickets.modals.service_7000")}
                            onClick={onClickProcess(ASSIGN_ORDER_PROCESS.SERVICE_7000)}
                        ></UI.Button>
                    </UI.Col>
                ) : undefined}
            </UI.Row>
            <UI.Spacer />
            <UI.Row className={"mt-2 d-flex justify-content-center"}>
                <UI.Col xs={12} md={6}>
                    <UI.Button
                        className={`ticket-button mt-2 ${styles.DiscardButton}`}
                        label={startCase(t("labels.cancel").toString())}
                        variant={"outline-primary"}
                        onClick={onDismissModal}
                    ></UI.Button>
                </UI.Col>
            </UI.Row>
        </>
    );

    const renderCurrentAssignOrderScreen = () => {
        switch (assignOrderModalStore.currentStepType) {
            case ASSIGN_ORDER_PROCESS_STEP.SELECT_SERVICE_PROVIDER_ENTITY:
                assignOrderModalStore.setCurrentStepTitle("");
                return selectServiceProviderEntityContent;
            case ASSIGN_ORDER_PROCESS_STEP.SELECT_SERVICE_PROVIDER:
                return <SelectServiceProvider />;
            case ASSIGN_ORDER_PROCESS_STEP.ENTER_SERVICE_PROVIDER_DETAILS:
                return <ServiceOrderDescription />;
            case ASSIGN_ORDER_PROCESS_STEP.ENTER_YAROWA_DETAILS:
                return <YarowaDescription />;
            case ASSIGN_ORDER_PROCESS_STEP.SUBMITTING_TO_YAROWA:
                return <YarowaSubmit />;
            case ASSIGN_ORDER_PROCESS_STEP.ENTER_SERVICE_7000_DETAILS:
                return <Service7000Description />;
            case ASSIGN_ORDER_PROCESS_STEP.SUBMITTING_TO_SERVICE_7000:
                return <Service7000Submit />;
        }
    };

    return (
        <UI.ProgressModal
            currentStep={assignOrderModalStore.currentStep?.stepNumber ?? 1}
            totalSteps={assignOrderModalStore.MAX_NUMBER_OF_STEPS}
            title={assignOrderModalStore.currentStepTitle}
            show={showModal}
            onDismissModal={onDismissModal}
            centered={uiStore.isMobile ? true : false}
            size={uiStore.isMobile ? undefined : "lg"}
        >
            {renderCurrentAssignOrderScreen()}
        </UI.ProgressModal>
    );
};

export const AssignOrderModal = Sentry.withProfiler(observer(AssignOrderModalBase));
