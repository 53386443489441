import React, { useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { GetAssemblyByID_agendaitems_agendaitemfiles } from "src/api/generated/GetAssemblyByID";
import { NetworkConfig } from "src/network/NetworkConfig";
import { ColorStyle } from "src/utils/Colors";
import { useMutation } from "@apollo/client";
import { DELETE_AGENDAITEM_FILE_MANAGER } from "src/api/assembly";
import {
    DeleteAgendaItemFileManager,
    DeleteAgendaItemFileManagerVariables
} from "src/api/generated/DeleteAgendaItemFileManager";
import { MessageType } from "src/components/notifications/Notifier";
import { AssemblyAgendaDocumentsListItem } from "./list-item/AssemblyAgendaDocumentsListItem";

interface AssemblyAgendaDocumentsProps {
    agendaItemFiles: GetAssemblyByID_agendaitems_agendaitemfiles[];
    agendaitemId: string;
}

const AssemblyAgendaDocumentsBase = (props: AssemblyAgendaDocumentsProps) => {
    const { t } = useTranslation();
    const { uiStore, assemblyStore } = useContext(RootStoreContext);

    const { agendaItemFiles, agendaitemId } = props;

    const printStatusMessage = useCallback(
        (message: string, msgType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: msgType,
                    autoClose: msgType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    const [deleteAgendaItemFileMutation] = useMutation<
        DeleteAgendaItemFileManager,
        DeleteAgendaItemFileManagerVariables
    >(DELETE_AGENDAITEM_FILE_MANAGER, {
        onCompleted: () => {
            printStatusMessage(t("screens.realestate.assembly.agendaitem_edit.remove_file_success"), MessageType.INFO);
            assemblyStore.setResetAgendaItems(true);
            assemblyStore.setShowRefetchAssemblyData(true);
            assemblyStore.setAgendaItemsHaveChanged();
        }
    });

    const onDeleteAgendaItemFile = useCallback(
        (fileid: string) => async (e: any) => {
            e.preventDefault();
            await deleteAgendaItemFileMutation({
                variables: {
                    fileid: fileid,
                    agendaitemid: agendaitemId
                }
            });
        },
        [deleteAgendaItemFileMutation, agendaitemId]
    );

    const agendaItemFileList = useCallback(() => {
        return agendaItemFiles.length > 0
            ? agendaItemFiles.map((agendaItemFile) => {
                  let assemblyFileLink = NetworkConfig.datafileUrl + agendaItemFile.fileid;
                  const deleteAssembylFileIcon = (
                      <UI.Icon
                          icon={UI.SVGIcon.Close}
                          color={ColorStyle("primary")}
                          size={12}
                          onClick={onDeleteAgendaItemFile(agendaItemFile.fileid)}
                      />
                  );
                  return (
                      <div key={agendaItemFile.fileid}>
                          <AssemblyAgendaDocumentsListItem
                              fileId={agendaItemFile.fileid}
                              fileName={agendaItemFile.file?.name}
                              additionalIcon={deleteAssembylFileIcon}
                              fileLink={assemblyFileLink}
                              isDisplayedInModal={false}
                          />
                      </div>
                  );
              })
            : undefined;
    }, [agendaItemFiles, onDeleteAgendaItemFile]);

    return <>{agendaItemFileList()}</>;
};

export const AssemblyAgendaDocuments = Sentry.withProfiler(observer(AssemblyAgendaDocumentsBase));
