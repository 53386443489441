import React, { useEffect, useContext, useState, useCallback, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { UI } from "@wwimmo/ui";
import { allColors, AllColors, Colors } from "src/utils/ThemeColors";
import { observer } from "mobx-react";
import "./Theming.css";
import { LogoUpload } from "src/components/theming/logoupload/LogoUpload";
import { Route, selectRoute } from "src/config/routes";
import { getCssRootValue, hexToHSL, HSLToHex, TransformColors } from "src/utils/Colors";
import { NetworkConfig } from "src/network/NetworkConfig";
import { runInAction } from "mobx";
import { Colorpicker } from "src/components/theming/colorpicker/Colorpicker";

const ThemingBase = () => {
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);
    const { t } = useTranslation();
    const [selectedKey, setSelectedKey] = useState<string>("custom");
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [reloadNotification, setReloadNotification] = useState<boolean>(false);
    const [isColorUnavailable, setIsColorUnavailable] = useState<boolean>(false);

    const [customColors, setCustomColors] = useState<Colors>({
        primary: "#58697a",
        primary100: "#58697a",
        primary400: "#343f49",
        primary900: "#232a30",
        additionalColor: "#606366",
        additionalColor2: "#00254d"
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        runInAction(() => {
            navStore.setTitle(t("screens.account.theming.title"));
            navStore.setActionButtonMiddle(null);
            navStore.setActionButtonRight(null);

            if (uiStore.isSidebarActive) {
                navStore.setBackbutton(null);
            } else {
                navStore.setBackbutton(selectRoute(Route.profile), t("screens.account.title").toString());
            }
        });

        const colors = [
            getCssRootValue("primary"),
            getCssRootValue("primary100"),
            getCssRootValue("primary400"),
            getCssRootValue("primary900"),
            getCssRootValue("additionalColor"),
            getCssRootValue("additionalColor2")
        ];
        for (let key of Object.keys(allColors)) {
            const k = key as keyof AllColors;
            if (
                colors[0] === allColors[k].primary.toLowerCase() &&
                colors[1] === allColors[k].primary100.toLowerCase() &&
                colors[2] === allColors[k].primary400.toLowerCase() &&
                colors[3] === allColors[k].primary900.toLowerCase()
            ) {
                setSelectedKey(key);
                break;
            } else {
                setCustomColors({
                    primary: colors[0],
                    primary100: colors[1],
                    primary400: colors[2],
                    primary900: colors[3],
                    additionalColor: colors[4],
                    additionalColor2: colors[5]
                });
            }
        }
    }, [navStore, uiStore, authStore.user, t]);

    const onFormChange = useCallback((event: any) => {
        setSelectedKey(event.currentTarget.value);
    }, []);

    const colorOptions: { key: keyof AllColors; colors: any }[] = useMemo(
        () =>
            Object.keys(allColors).map((key) => {
                const colors = allColors[key as keyof AllColors];
                return { key: key as keyof AllColors, colors };
            }),
        []
    );

    const onSave = useCallback(async () => {
        setIsUpdating(true);

        const colors =
            selectedKey === "custom" ? customColors : colorOptions.find((k) => k.key === selectedKey)?.colors;

        const options = {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + authStore.token,
                ContentType: "application/json"
            },
            body: JSON.stringify({
                primary: colors.primary,
                primary100: colors.primary100,
                primary400: colors.primary400,
                primary900: colors.primary900,
                additionalcolor: colors.additionalColor,
                additionalcolor2: colors.additionalColor2
            })
        };

        let request = NetworkConfig.brandAssets.uploadColors;

        try {
            const response = await fetch(request, options);

            if (response.status === 200) {
                setReloadNotification(true);
            } else if (response.status === 401) {
                setErrorMessage(t("error.401"));
            } else {
                const body = await response.json();
                setErrorMessage(`${t("screens.account.theming.error.upload")}: ${body.message ?? response.statusText}`);
            }
        } catch (error: any) {
            setErrorMessage(`${t("screens.account.theming.error.upload")}: ${error.message}`);
        } finally {
            setIsUpdating(false);
        }
        return () => {
            setIsUpdating(false);
        };
    }, [setIsUpdating, setReloadNotification, customColors, colorOptions, authStore.token, selectedKey, t]);

    const onChangeColorField = useCallback(
        (newColor: string) => {
            const hslColor = hexToHSL(newColor);

            setIsColorUnavailable(false);
            if (hslColor.l >= 0 && hslColor.l <= 15) {
                hslColor.l = 16;
                setIsColorUnavailable(true);
            } else if (hslColor.l >= 91 && hslColor.l <= 100) {
                hslColor.l = 90;
                setIsColorUnavailable(true);
            }

            const colors = TransformColors({ h: hslColor.h, s: hslColor.s, l: hslColor.l });
            const newColors = {
                primary: newColor,
                primary100: HSLToHex(colors[3].h, colors[3].s, colors[3].l),
                primary400: HSLToHex(colors[2].h, colors[2].s, colors[2].l),
                primary900: HSLToHex(colors[1].h, colors[1].s, colors[1].l),
                additionalColor: HSLToHex(colors[4].h, colors[4].s, colors[4].l),
                additionalColor2: HSLToHex(colors[5].h, colors[5].s, colors[5].l)
            };

            setCustomColors(newColors);
            setSelectedKey("custom");
        },
        [setSelectedKey, setCustomColors]
    );
    const renderPalette = (colors: Colors, custom?: boolean) => (
        <div className="Palette d-flex flex-row">
            <div className="Rectangle mr-2">
                {custom ? (
                    <React.Fragment>
                        <Colorpicker value={colors.primary} onChange={onChangeColorField} />
                    </React.Fragment>
                ) : (
                    <div style={{ background: colors.primary }} />
                )}
            </div>
            <div className="Rectangle">
                <div style={{ background: colors.primary900 }} />
            </div>
            <div className="Rectangle">
                <div style={{ background: colors.primary400 }} />
            </div>
            <div className="Rectangle">
                <div style={{ background: colors.primary100 }} />
            </div>
            {colors.additionalColor && (
                <div className="Rectangle">
                    <div style={{ background: colors.additionalColor }} />
                </div>
            )}
            {colors.additionalColor2 && (
                <div className="Rectangle">
                    <div style={{ background: colors.additionalColor2 }} />
                </div>
            )}
            <div className="Rectangle" style={{ height: 30 }}>
                <UI.Icon icon={UI.SVGIcon.Favicon} color={colors.primary} />
            </div>
        </div>
    );

    return (
        <UI.Container className="Theming pt-4">
            <UI.Row>
                <UI.Col sm="6" md={{ span: 6 }} lg={{ span: 4, offset: 2 }}>
                    <UI.Card title={t("screens.account.theming.logo").toString()}>
                        <LogoUpload />
                    </UI.Card>
                </UI.Col>
                <UI.Col sm="6" md="6" lg="4">
                    <UI.Card title={t("screens.account.theming.colors").toString()}>
                        <UI.Form>
                            {colorOptions.map(({ key, colors }) => (
                                <UI.Form.Check key={`option-${key}`} className="d-flex align-items-center mb-2">
                                    <UI.Form.Check.Input
                                        className="mt-0"
                                        type={"radio"}
                                        checked={selectedKey === key}
                                        value={key}
                                        onChange={onFormChange}
                                    />
                                    <UI.Form.Label className="Label m-0">{renderPalette(colors, false)}</UI.Form.Label>
                                </UI.Form.Check>
                            ))}
                            <UI.Form.Check key={"option-custom"} className="d-flex align-items-center mb-2">
                                <UI.Form.Check.Input
                                    className="mt-0"
                                    type={"radio"}
                                    checked={selectedKey === "custom"}
                                    value={"custom"}
                                    onChange={onFormChange}
                                />
                                <UI.Form.Label className="Label m-0">
                                    {renderPalette(
                                        {
                                            primary: customColors.primary,
                                            primary100: customColors.primary100,
                                            primary400: customColors.primary400,
                                            primary900: customColors.primary900,
                                            additionalColor: customColors.additionalColor,
                                            additionalColor2: customColors.additionalColor2
                                        },
                                        true
                                    )}
                                </UI.Form.Label>
                            </UI.Form.Check>

                            <UI.Button
                                label={t("labels.save")}
                                onClick={onSave}
                                disabled={isUpdating || isColorUnavailable}
                                className="mt-4"
                            />
                            {isColorUnavailable ? (
                                <UI.Alert variant="warning" className="mt-3">
                                    {t("screens.account.theming.unavailable_color")}
                                </UI.Alert>
                            ) : undefined}
                            {errorMessage ? (
                                <UI.Alert variant="warning" className="mt-3">
                                    {errorMessage}
                                </UI.Alert>
                            ) : undefined}
                            {reloadNotification && (
                                <UI.Alert variant="warning" className="mt-2">
                                    {
                                        // Keys windows: Ctrl + F5
                                        // Keys mac: Cmd + shift + R
                                    }
                                    {t("screens.account.theming.refresh_browser", {
                                        key:
                                            navigator.appVersion.indexOf("Win") !== -1 ? "Ctrl + F5" : "Cmd + Shift + R"
                                    })}
                                </UI.Alert>
                            )}
                        </UI.Form>
                    </UI.Card>
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};

export const Theming = Sentry.withProfiler(observer(ThemingBase));
