import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_TILE_INFORMATION } from "src/api/tiles";
import { GetTileInformation } from "src/api/generated/GetTileInformation";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { withRouter, RouteComponentProps } from "react-router";
import { selectRoute, Route } from "src/config/routes";
import { TileAdministrationList } from "./list/TileAdministrationList";

const TileAdministrationBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);

    const { data, loading, error } = useQuery<GetTileInformation>(GET_TILE_INFORMATION, {
        variables: {
            customerid: authStore.user?.customerid
        }
    });

    useEffect(() => {
        navStore.setTitle(t("screens.tile_administration.title"));
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);

        if (uiStore.isSidebarActive) {
            navStore.setBackbutton(null);
        } else {
            navStore.setBackbutton(selectRoute(Route.profile), t("screens.account.title").toString());
        }
    }, [navStore, t, uiStore.isSidebarActive]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    let activatedTiles: number[] = [];

    if (navStore.customer.activatedTiles) {
        activatedTiles = navStore.customer.activatedTiles.map((activatedTileId) => activatedTileId);
    }

    let calculatedTiles: number[] = [];

    data.aggregatedportaltiles.calculatedtiletypes.forEach((calculatedTile) => {
        if (calculatedTile.tiletype) {
            calculatedTiles.push(calculatedTile.tiletype);
        }
    });

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col md={12}>
                        <UI.Card>
                            <TileAdministrationList
                                tileTypes={data.tiletypes}
                                activatedTiles={activatedTiles}
                                calculatedTiles={calculatedTiles}
                            />
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const TileAdministration = withRouter(Sentry.withProfiler(observer(TileAdministrationBase)));
