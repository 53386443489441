import React, { useEffect, useContext, useState, useCallback } from "react";
import { RootStoreContext } from "src/stores/RootStore";
import { UI, ScreenSize } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import {
    GetManagerFilesByRealEstateId_file_categories as file_category,
    GetManagerFilesByRealEstateId_realestates_files as file_item,
    GetManagerFilesByRealEstateId_realestates as file_realestates
} from "src/api/generated/GetManagerFilesByRealEstateId";
import { NetworkConfig } from "src/network/NetworkConfig";
import { ColorStyle } from "src/utils/Colors";
import { observer } from "mobx-react-lite";
import { ScreenWidth } from "src/stores/UIStore";
import styles from "./DocumentsList.module.css";
import { v4 as uuidv4 } from "uuid";
import { DocumentFilter } from "src/screens/realestates/realestate/documents/filter/DocumentsFilter";

interface FileCategory {
    key: number;
    label: string;
}

enum FILE_CATEGORY {
    ASSEMBLY_DOCUMENTS = 4
}

interface DocumentsListProps {
    realestateFileItems: file_realestates[];
    fileCategories: file_category[];
}

interface SearchAndFilterArguments {
    searchQuery?: string;
    fileCategoryKey?: number;
    itemsToSearch?: file_item[];
}

export const DocumentsListBase = (props: DocumentsListProps) => {
    const { t } = useTranslation();
    const { navStore, uiStore, documentStore } = useContext(RootStoreContext);

    const [initialFileItems, setInitialFileItems] = useState<file_item[]>();
    const [displayedFileCategories, setDisplayedFileCategories] = useState<FileCategory[]>([]);
    const [activeFileCategory, setActiveFileCategory] = useState<FileCategory>();
    const [currentSearchQuery, setCurrentSearchQuery] = useState<string>("");
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);

    const getUpdatedDisplayedDocuments = useCallback(
        (searchAndFilterArguments: SearchAndFilterArguments, newActiveFileCategory: FileCategory): file_item[] => {
            const { searchQuery, itemsToSearch } = searchAndFilterArguments;

            const updatedDisplayedDocuments: file_item[] = [];
            const query = searchQuery?.toLocaleLowerCase();

            itemsToSearch?.forEach((fileItem) => {
                const { filedate, name, category: fileCategory } = fileItem;
                const fileName = name?.toLowerCase();
                const fileDate = format(new Date(filedate), "dd.MM.yyyy").toLocaleLowerCase();

                const isFileNameOrDateIncludedInQuery =
                    query !== null && query !== undefined
                        ? fileName?.includes(query) || fileDate.includes(query)
                        : false;

                if (
                    newActiveFileCategory &&
                    newActiveFileCategory.key === fileCategory &&
                    isFileNameOrDateIncludedInQuery
                ) {
                    updatedDisplayedDocuments.push(fileItem);
                } else if (
                    (!newActiveFileCategory || !newActiveFileCategory.key) &&
                    uiStore.isMobile &&
                    isFileNameOrDateIncludedInQuery
                ) {
                    updatedDisplayedDocuments.push(fileItem);
                } else if (
                    (!newActiveFileCategory || !newActiveFileCategory.key) &&
                    isFileNameOrDateIncludedInQuery &&
                    fileCategory === newActiveFileCategory.key
                ) {
                    updatedDisplayedDocuments.push(fileItem);
                }
            });

            return updatedDisplayedDocuments;
        },
        [uiStore.isMobile]
    );

    const getUpdatedDisplayedDocumentsAllCategories = useCallback(
        (searchAndFilterArguments: SearchAndFilterArguments): file_item[] => {
            const { searchQuery, itemsToSearch } = searchAndFilterArguments;

            const foundDocumentsAllCategories: file_item[] = [];

            const query = searchQuery?.toLocaleLowerCase();

            itemsToSearch?.forEach((fileItem) => {
                const { filedate, name } = fileItem;
                const fileName = name?.toLowerCase();
                const fileDate = format(new Date(filedate), "dd.MM.yyyy").toLocaleLowerCase();

                const isFileNameOrDateIncludedInQuery = query
                    ? fileName?.includes(query) || fileDate.includes(query)
                    : false;

                if (query && isFileNameOrDateIncludedInQuery) {
                    foundDocumentsAllCategories.push(fileItem);
                } else if (!query) {
                    foundDocumentsAllCategories.push(fileItem);
                }
            });

            return foundDocumentsAllCategories;
        },
        []
    );

    const getUpdatedDisplayedFileCategories = useCallback(
        (foundDocumentsAllCategories: file_item[]): FileCategory[] => {
            const fileCategories: FileCategory[] = [];
            const filteredFileCategories: FileCategory[] = [];

            props.fileCategories.forEach((fileCategory) => {
                if (navStore.isCondominium || fileCategory.key !== FILE_CATEGORY.ASSEMBLY_DOCUMENTS) {
                    fileCategories.push({ label: fileCategory.label || "-", key: fileCategory.key ?? 0 });
                }
            });

            fileCategories.forEach((fileCategory) => {
                foundDocumentsAllCategories.forEach((fileItem) => {
                    const alreadyExistingCategory = filteredFileCategories.find(
                        (filteredFileCategory) =>
                            filteredFileCategory.label === fileItem.categorylabel &&
                            filteredFileCategory.key === fileItem.category
                    );

                    if (!alreadyExistingCategory && fileCategory.label === fileItem.categorylabel) {
                        filteredFileCategories.push({ label: fileCategory.label, key: fileCategory.key });
                    }
                });
            });

            return filteredFileCategories;
        },
        [navStore.isCondominium, props.fileCategories]
    );

    const getActiveFileCategory = useCallback(
        (fileCategoryKey: number | undefined, filteredFileCategories: FileCategory[]): FileCategory => {
            let newActiveFileCategory = undefined;

            if (fileCategoryKey) {
                const fileCategory = filteredFileCategories.find(
                    (fileCategory) => fileCategory.key === fileCategoryKey
                );
                if (fileCategory) {
                    newActiveFileCategory = fileCategory;
                } else {
                    newActiveFileCategory = filteredFileCategories[0];
                }
            } else {
                newActiveFileCategory = filteredFileCategories[0];
            }

            return newActiveFileCategory;
        },
        []
    );

    const searchAndFilterDocuments = useCallback(
        (searchAndFilterArguments: SearchAndFilterArguments) => {
            const { fileCategoryKey } = searchAndFilterArguments;

            const updatedDisplayedDocumentsAllCategories =
                getUpdatedDisplayedDocumentsAllCategories(searchAndFilterArguments);
            const updatedFileCategories = getUpdatedDisplayedFileCategories(updatedDisplayedDocumentsAllCategories);
            const newActiveFileCategory = getActiveFileCategory(fileCategoryKey, updatedFileCategories);
            const updatedDisplayedDocuments = getUpdatedDisplayedDocuments(
                searchAndFilterArguments,
                newActiveFileCategory
            );

            documentStore.setDisplayedDocuments([...updatedDisplayedDocuments]);

            setDisplayedFileCategories(updatedFileCategories);

            if (uiStore.isMobile && !hasInitialized) {
                setActiveFileCategory({ key: -1, label: "" });
            } else {
                setActiveFileCategory(newActiveFileCategory);
            }
        },
        [
            getUpdatedDisplayedFileCategories,
            getActiveFileCategory,
            getUpdatedDisplayedDocuments,
            getUpdatedDisplayedDocumentsAllCategories,
            hasInitialized,
            uiStore.isMobile,
            documentStore
        ]
    );

    useEffect(() => {
        if (!hasInitialized) {
            const fileItems: file_item[] = [];

            props.realestateFileItems.forEach((realestate) => {
                fileItems.push(...realestate.files);
            });

            setInitialFileItems(fileItems);

            searchAndFilterDocuments({ searchQuery: "", itemsToSearch: fileItems });
            setHasInitialized(true);
        }
    }, [hasInitialized, searchAndFilterDocuments, props.realestateFileItems]);

    const onChangeQueryInput = useCallback(
        (e: any) => {
            const currentSearchQuery = e.target.value;
            setCurrentSearchQuery(currentSearchQuery);
            searchAndFilterDocuments({
                searchQuery: currentSearchQuery,
                fileCategoryKey: activeFileCategory?.key,
                itemsToSearch: initialFileItems
            });
        },
        [searchAndFilterDocuments, activeFileCategory?.key, initialFileItems]
    );

    const onTabNavigationClick = useCallback(
        (clickedFileCategoryKey: number) => async (e: any) => {
            searchAndFilterDocuments({
                searchQuery: currentSearchQuery,
                fileCategoryKey: clickedFileCategoryKey,
                itemsToSearch: initialFileItems
            });
        },
        [currentSearchQuery, searchAndFilterDocuments, initialFileItems]
    );

    const tabNavigationItem = useCallback(
        (fileCategoryKey: number, fileCategoryLabel: string) => {
            return (
                <UI.Nav.Item key={uuidv4()} className={styles.TabNavigationNavItem}>
                    <UI.Nav.Link
                        eventKey={fileCategoryKey}
                        className={styles.TabNavigationNavLink}
                        onClick={onTabNavigationClick(fileCategoryKey)}
                    >
                        <div className="d-flex align-items-center">
                            <UI.Icon
                                icon={UI.SVGIcon.Folders}
                                color={ColorStyle("primary")}
                                size="medium"
                                className={styles.TabNavigationNavLinkIcon}
                            />
                            <div className={styles.TabNavigationNavLinkText}>{fileCategoryLabel}</div>
                            <UI.Icon
                                icon={uiStore.isMobile ? UI.SVGIcon.ChevronDown : UI.SVGIcon.ChevronRight}
                                color={ColorStyle("primary")}
                                size="small"
                                className={styles.TabNavigationNavLinkChevron}
                            />
                        </div>
                    </UI.Nav.Link>
                </UI.Nav.Item>
            );
        },
        [onTabNavigationClick, uiStore.isMobile]
    );

    const renderListItem = useCallback(
        (item: file_item, screenSize: ScreenSize) => {
            const file = item;
            const listRows: any[] = [];

            if (file) {
                const date = format(new Date(file.filedate), "dd.MM.yyyy");

                // Check if documentStore.selectedYears exists
                if (documentStore.selectedYears && documentStore.selectedYears.length > 0) {
                    // Filter the item if the year matches any of the selected years
                    if (!documentStore.selectedYears.includes(new Date(file.filedate).getFullYear().toString())) {
                        return null; // Return null if the year doesn't match
                    }
                }

                let rows: React.ReactElement[] = [];

                switch (screenSize) {
                    case ScreenSize.DESKTOP:
                        rows = [
                            <UI.List.Row key={"row-1"}>
                                <UI.List.Cell key={"c-1"} colspan={2} value={file.name} />
                                <UI.List.Cell key={"c-3"} colspan={1} value={date} />
                            </UI.List.Row>
                        ];
                        break;
                    case ScreenSize.MOBILE:
                        rows = [
                            <UI.List.Row key={"row-1"}>
                                <UI.List.Cell key={"c-1"} colspan={2} value={file.name} className={styles.FileName} />
                            </UI.List.Row>,
                            <UI.List.Row key={"row-2"}>
                                <UI.List.Cell key={"c-2"} colspan={1} value={date} className="sublabel" />
                            </UI.List.Row>
                        ];
                        break;
                }

                listRows.push(rows);
            }

            let urlLink = NetworkConfig.datafileUrl + file?.fileid;

            const icon = (
                <a href={urlLink} className="link d-flex" rel="noopener noreferrer" target="_blank">
                    <UI.Icon icon={UI.SVGIcon.DocumentsNavBar} color={ColorStyle("primary")} />
                </a>
            );

            return (
                <UI.List.Item
                    key={`${file?.id}-${file?.category}`}
                    screenSize={screenSize}
                    rows={listRows}
                    icon={icon}
                />
            );
        },
        // rerender when documentStore.displayedDocuments changed
        // eslint-disable-next-line
        [documentStore.selectedYears]
    );

    const renderTabNavigationTabPane = useCallback(() => {
        if (activeFileCategory) {
            return (
                <UI.Tab.Pane key={uuidv4()} eventKey={activeFileCategory.key}>
                    <UI.List.BasicList
                        screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                        items={documentStore.displayedDocuments}
                        renderItem={renderListItem}
                        itemsPerPage={uiStore.isMobile ? 5 : 10}
                        emptyListMessage={t("screens.realestate.documents.no_documents_found").toString()}
                    />
                </UI.Tab.Pane>
            );
        } else {
            return <></>;
        }
    }, [activeFileCategory, renderListItem, t, uiStore.isMobile, documentStore]);

    const renderTabNavigationItemsList = useCallback(() => {
        const listRows: JSX.Element[] = [];

        if (displayedFileCategories) {
            displayedFileCategories.forEach((displayedFileCategory) => {
                if (uiStore.isMobile) {
                    listRows.push(
                        tabNavigationItem(displayedFileCategory.key, displayedFileCategory.label),
                        activeFileCategory?.key === displayedFileCategory.key ? (
                            <UI.Col key={displayedFileCategory.key} className={`pl-0 pr-0 ${styles.columnTabContent}`}>
                                <UI.Tab.Content>{renderTabNavigationTabPane()}</UI.Tab.Content>
                            </UI.Col>
                        ) : (
                            <div key={displayedFileCategory.key}></div>
                        )
                    );
                } else {
                    listRows.push(tabNavigationItem(displayedFileCategory.key, displayedFileCategory.label));
                }
            });
        }
        return listRows;
    }, [
        displayedFileCategories,
        activeFileCategory?.key,
        tabNavigationItem,
        renderTabNavigationTabPane,
        uiStore.isMobile
    ]);

    const TabContainer = useCallback(() => {
        return (
            <UI.Tab.Container activeKey={activeFileCategory?.key} transition={false}>
                {uiStore.isMobile ? (
                    <UI.Row>
                        <UI.Col>
                            <UI.Nav variant="pills" className={`flex-column ${styles.TabNavigationNav}`}>
                                {renderTabNavigationItemsList()}
                            </UI.Nav>
                        </UI.Col>
                    </UI.Row>
                ) : (
                    <UI.Row>
                        <UI.Col
                            sm={5}
                            className={styles.TabNavigationNavCol}
                            style={window.innerWidth >= ScreenWidth.SM ? undefined : { borderRight: "none" }}
                        >
                            <UI.Nav variant="pills" className={`flex-column ${styles.TabNavigationNav}`}>
                                {renderTabNavigationItemsList()}
                            </UI.Nav>
                        </UI.Col>
                        <UI.Col sm={7}>
                            <UI.Tab.Content>{renderTabNavigationTabPane()}</UI.Tab.Content>
                        </UI.Col>
                    </UI.Row>
                )}
            </UI.Tab.Container>
        );
    }, [renderTabNavigationItemsList, renderTabNavigationTabPane, uiStore.isMobile, activeFileCategory?.key]);

    return (
        <div className={`${styles.TabNavigationContainer} my-2`}>
            <UI.Input
                type="search"
                onChange={onChangeQueryInput}
                placeholder={t("labels.search")}
                value={currentSearchQuery}
            ></UI.Input>
            <DocumentFilter />
            <br />
            <TabContainer />
        </div>
    );
};

export const DocumentsList = Sentry.withProfiler(observer(DocumentsListBase));
