import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { Error } from "src/screens/error/Error";
import { UI } from "@wwimmo/ui";
import { Route, selectRoute } from "src/config/routes";
import { ORDER_LOADING_TYPE } from "src/stores/OrderStore";
import styles from "./OrderDetails.module.css";
import { OrderForm } from "./form/OrderForm";
import { TicketContactCard } from "src/screens/tickets/ticket/details/cards/TicketContactCard";
import { IIncidentPerson, INCIDENT_PERSON_ROLE } from "src/stores/TicketStore";
import { TicketLinkList } from "src/screens/orders/order/cards/ticketLinkList/TicketLinkList";
import { CloseOrderButton } from "src/screens/orders/order/actions/closeOrder/CloseOrderButton";
import { OpenInYarowaButton } from "src/screens/orders/order/actions/openInYarowa/OpenInYarowaButton";
import { RetrySubmissionButton } from "src/screens/orders/order/actions/retrySubmission/RetrySubmissionButton";
import { ScreenWidth } from "src/stores/UIStore";

interface Params {
    ticketnumber: string;
    ordernumber: string;
}

const OrderDetailsBase = () => {
    const { t } = useTranslation();
    const { navStore, ticketStore, uiStore, authStore, orderStore } = useContext(RootStoreContext);
    const { currentOrder } = orderStore;
    const { ticketnumber, ordernumber } = useParams<Params>();

    useEffect(() => {
        navStore.setTitle(uiStore.isMobile ? t("screens.tickets.ticket") : "");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);

        navStore.setBackbutton(
            selectRoute(Route.ticketDetails, authStore.user?.role, {
                ticketnumber: ticketnumber
            }),
            t("screens.tickets.ticket").toString()
        );
    }, [navStore, authStore, t, ticketStore, ticketnumber, uiStore.isMobile]);

    // Initialize Data for Order
    useEffect(() => {
        async function initializeOrderData() {
            await orderStore.init(ticketnumber, ordernumber);
        }
        initializeOrderData();
    }, [orderStore, ticketnumber, ordernumber]);

    if (orderStore.error) {
        return <Error message={orderStore.error.title} />;
    }

    if (!currentOrder) {
        return <></>;
    }

    const contractor =
        orderStore.currentOrder?.contractor?.id !== undefined ? orderStore.currentOrder?.contractor : undefined;

    const contractorIncidentPerson: IIncidentPerson[] = contractor
        ? [
              {
                  name1: contractor.name1 ?? "",
                  name2: contractor.name2,
                  email: contractor.email,
                  phone: contractor.phoneBusiness,
                  mobile: contractor.mobile,
                  role: INCIDENT_PERSON_ROLE.CONTRACTOR
              }
          ]
        : [];

    return (
        <UI.Container
            className={`pt-3 pt-md-5 ${styles.OrderDetailsContainer} ${
                !uiStore.isMobile && uiStore.isSidebarCollapsed ? styles.ExtraPadding : ""
            }`}
            fluid
        >
            <div className="px-2 px-md-0">
                <UI.Title>{t("screens.orders.order")}</UI.Title>
            </div>
            <UI.Row>
                <UI.Col sm={12} lg={9}>
                    {orderStore.loadingType === ORDER_LOADING_TYPE.ORDER ? (
                        <UI.RotatingSpinner noLogo size={50} className={styles.LoadingOrderSpinner} />
                    ) : (
                        <OrderForm />
                    )}
                </UI.Col>

                <UI.Col sm={12} lg={3}>
                    {/* Action Card */}
                    <UI.Card
                        title={t("screens.tickets.actions").toString()}
                        className={window.innerWidth <= ScreenWidth.MD ? "mt-2" : ""}
                        flex
                    >
                        <CloseOrderButton />
                        <RetrySubmissionButton />
                        <OpenInYarowaButton />
                    </UI.Card>
                    {/* Link zu Ticket */}
                    <TicketLinkList ticketNumber={ticketnumber} />
                    {/* Contact-Card */}
                    {contractor ? <TicketContactCard incidentPersons={contractorIncidentPerson} /> : undefined}
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};

export const OrderDetails = Sentry.withProfiler(observer(OrderDetailsBase));
