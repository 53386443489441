import { observable, makeObservable, action } from "mobx";
import { GetManagerFilesByRealEstateId_realestates_files as file_item } from "src/api/generated/GetManagerFilesByRealEstateId";
import { RootStore } from "src/stores/RootStore";

export class DocumentStore {
    rootStore: RootStore;
    displayedDocuments: file_item[] = [];
    uniqueYearsArray: string[] = [];
    selectedYears: string[] = [];
    isDocumentFilterModalOpen: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            displayedDocuments: observable,
            uniqueYearsArray: observable,
            selectedYears: observable,
            isDocumentFilterModalOpen: observable,

            setDisplayedDocuments: action,
            setUniqueYearsArray: action,
            setSelectedYears: action,
            setIsDocumentFilterModalOpen: action
        });
    }

    /* SETTERS */

    setDisplayedDocuments = (displayedDocuments: file_item[]) => {
        this.displayedDocuments = displayedDocuments;
    };

    setUniqueYearsArray = (uniqueYearsArray: string[]) => {
        this.uniqueYearsArray = uniqueYearsArray;
    };

    setSelectedYears = (selectedYears: string[]) => {
        this.selectedYears = selectedYears;
    };

    setIsDocumentFilterModalOpen = (isDocumentFilterModalOpen: boolean) => {
        this.isDocumentFilterModalOpen = isDocumentFilterModalOpen;
    };

    getYearsFromArray = (files: file_item[]) => {
        const newYearsArray: string[] = [];
        files.forEach((file) => {
            const year = new Date(file.filedate).getFullYear().toString();
            if (!newYearsArray.includes(year)) {
                newYearsArray.push(year);
            }
        });
        this.setUniqueYearsArray(newYearsArray);
        return newYearsArray;
    };
}
