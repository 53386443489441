import { gql } from "@apollo/client";

export const GET_CUSTOMER_INVOICE_HISTORY = gql`
    query GetCustomerInvoiceHistory {
        customerinvoices: ums_customerinvoices(order_by: { date: desc, costtype: asc }) {
            id
            date
            invoiced
            costtype
            count
            costperunit
        }
    }
`;

export const GET_CUSTOMER_INVOICES_BY_DATE = gql`
    query GetCustomerInvoicesByDate($date: date, $language: String = "de") {
        customerinvoices: ums_customerinvoices(order_by: { costtype: asc }, where: { date: { _eq: $date } }) {
            id
            date
            invoiced
            costtype
            count
            costperunit
            customerinvoiceusers_aggregate {
                aggregate {
                    count
                }
            }
        }
        costtype_enumeration: common_enumerations(
            where: { type: { _eq: 902 }, language: { _eq: $language } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;

export const GET_MONTHLY_COSTS = gql`
    query GetMonthlyCosts($language: String! = "de") {
        ums_v_customer_invoices_group_year_monthly(order_by: { year: desc }) {
            year
            customer_invoices(where: { language: { _eq: $language } }, order_by: { costtype: asc }) {
                sum
                date
                month
                costtype
                label
            }
        }
    }
`;
