import React, { useCallback, useContext, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import styles from "./EmailToFormField.module.css";
import { Patterns } from "src/utils/Patterns";

interface EmailToFormFieldProps {
    setValue: any;
    getValues: any;
}

const EmailToFormFieldBase = (props: EmailToFormFieldProps) => {
    const { t } = useTranslation();

    const { setValue, getValues } = props;

    const { ticketStore, manualEmailModalStore } = useContext(RootStoreContext);

    const [isEmailInputValid, setIsEmailInputValid] = useState<boolean>(true);
    const [inputValue, setInputValue] = useState<string>("");

    const checkIfEmailIsValid = useCallback(() => {
        const recipients = getValues("recipients");
        if (recipients === "") {
            if (inputValue && Patterns.EMAILADDRESS.test(inputValue)) {
                setValue("recipients", [inputValue]);
                setInputValue("");
                setIsEmailInputValid(true);
                manualEmailModalStore.setIsRecipientsEmpty(false);
            } else {
                setIsEmailInputValid(false);
            }
        }
    }, [setValue, inputValue, getValues, manualEmailModalStore]);

    const onChangeFormInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue("recipients", "");
            setInputValue(event.currentTarget.value);
        },
        [setValue]
    );

    const onBlurEmailInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            e.preventDefault();
            const recipients = getValues("recipients");

            if (recipients === "") {
                if (inputValue && Patterns.EMAILADDRESS.test(inputValue)) {
                    setValue("recipients", [inputValue]);
                    setInputValue("");
                    setIsEmailInputValid(true);
                    manualEmailModalStore.setIsRecipientsEmpty(false);
                } else {
                    setIsEmailInputValid(false);
                }
            }

            manualEmailModalStore.setDisplayEmailToList(false);
        },
        [getValues, inputValue, setValue, manualEmailModalStore]
    );

    const onFocusEmailInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            manualEmailModalStore.setDisplayEmailToList(true);
        },
        [manualEmailModalStore]
    );

    const onClickEmailItem = useCallback(
        (email: string) => async (e: any) => {
            e.preventDefault();

            setInputValue("");
            setIsEmailInputValid(true);
            setValue("recipients", [email]);
            manualEmailModalStore.setDisplayEmailToList(false);
            manualEmailModalStore.setIsRecipientsEmpty(false);
        },
        [setValue, manualEmailModalStore]
    );

    const emailFilterList =
        ticketStore.currentTicket?.incidentPersons
            ?.filter((incidentPerson) => incidentPerson.email)
            ?.map((incidentPerson) => {
                if (incidentPerson.email) {
                    return {
                        email: incidentPerson.email,
                        text: `${incidentPerson.name1} ${incidentPerson.name2}, ${incidentPerson.email} (${
                            manualEmailModalStore.incidentPersonEnums.find((item) => item.key === incidentPerson.role)
                                ?.label
                        })`
                    };
                }
                return null;
            }) || [];

    const emailSelectionList = (
        <ul
            id="email-dropdown-list"
            className={isEmailInputValid ? "dropdown-list emailTo" : "dropdown-list errorEmailTo"}
        >
            {emailFilterList.map((emailItem, index) => {
                return (
                    <li key={index}>
                        <div
                            className={"list-item email"}
                            id={`email-list-item-${index}`}
                            onMouseDown={onClickEmailItem(emailItem?.email || "")}
                            dangerouslySetInnerHTML={{
                                __html: emailItem?.text || ""
                            }}
                        />
                    </li>
                );
            })}
        </ul>
    );

    const handleKeyPress = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            manualEmailModalStore.setDisplayEmailToList(false);
            if (event.key === "Enter" || event.key === "Tab") {
                manualEmailModalStore.setDisplayEmailToList(false);
                checkIfEmailIsValid();
            }
        },
        [manualEmailModalStore, checkIfEmailIsValid]
    );

    const onClickRemoveEmail = useCallback(
        () => async () => {
            setValue("recipients", []);
            setInputValue("");
            manualEmailModalStore.setIsRecipientsEmpty(true);
            manualEmailModalStore.setDisplayEmailToList(false);
        },
        [setValue, manualEmailModalStore]
    );

    return (
        <>
            <p className={styles.EmailToTitle}>{t("labels.to")}</p>

            <div className={styles.EmailToInputDiv} tabIndex={0}>
                <div className={styles.EmailToItemWrapper}>
                    {getValues("recipients").length > 0 && getValues("recipients")[0] !== "" ? (
                        <div className={styles.EmailToItem} onClick={onClickRemoveEmail()}>
                            {manualEmailModalStore.isRecipientsEmpty ? undefined : <>{getValues("recipients")}</>}

                            <UI.Icon
                                className={"ml-1"}
                                icon={UI.SVGIcon.Close}
                                size={13}
                                onClick={onClickRemoveEmail()}
                            />
                        </div>
                    ) : undefined}
                    <input
                        className={`flex-fill ${styles.Input}`}
                        onChange={onChangeFormInput}
                        onKeyDown={handleKeyPress}
                        onBlur={onBlurEmailInput}
                        onFocus={onFocusEmailInput}
                        type="text"
                        value={inputValue}
                    />
                </div>
                <UI.Icon icon={UI.SVGIcon.Search} color="grey" size={"small"} />
            </div>
            {isEmailInputValid ? (
                <></>
            ) : (
                <p className={styles.EmailToError}>{t("screens.tickets.action.email.incorrect_email")}</p>
            )}
            {manualEmailModalStore.displayEmailToList ? emailSelectionList : undefined}
        </>
    );
};

export const EmailToFormField = Sentry.withProfiler(observer(EmailToFormFieldBase));
