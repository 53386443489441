import { useContext, useCallback, useState } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { withRouter } from "react-router";
import styles from "./AssembliesEdit.module.css";
import { NetworkConfig } from "src/network/NetworkConfig";
import { GetAssemblyByID_assemblyitems } from "src/api/generated/GetAssemblyByID";
import { getRoleKey } from "src/network/User";
import { getDate } from "src/utils/Date";
import { MessageType } from "src/components/notifications/Notifier";
import { ASSEMBLY_STATE } from "./form/AssembliesEditForm";
import { FORM_STATUS } from "src/utils/Form";

interface AssembliesActionCardProps {
    assemblyid: string;
    assemblyDetails?: GetAssemblyByID_assemblyitems;
}

const AssembliesActionCardBase = (props: AssembliesActionCardProps) => {
    const { assemblyid, assemblyDetails } = props;
    const { t } = useTranslation();
    const { uiStore, authStore, assemblyStore } = useContext(RootStoreContext);
    const [isDownloadAgendaItemFiles, setIsDownloadAgendaItemFiles] = useState<boolean>(false);

    const isNotificationButtonActive =
        assemblyDetails?.state !== undefined && assemblyDetails?.state === Number(ASSEMBLY_STATE.ACTIVE);

    assemblyStore.setHasActiveCoowners(assemblyDetails?.count_coowners.aggregate?.count!);

    const printStatusMessage = useCallback(
        (message: string | React.ReactNode, messageType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: messageType,
                    autoClose: messageType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    const getDefaultFileName = useCallback(
        (date: string) => {
            return `${t("screens.realestate.assembly.default_assembly_pdf_name", {
                date: date?.toString().replaceAll(".", "_")
            })}`;
        },
        [t]
    );

    const replaceAssemblyTitle = useCallback(
        (fileName: string, assemblyTitle: string | undefined) => {
            return fileName.replace(
                "assemblytitle",
                assemblyTitle ? assemblyTitle.replaceAll(" ", "_") : t("screens.realestate.assemblies.title")
            );
        },
        [t]
    );

    const onDownloadAgendaItemDocuments = useCallback(async () => {
        setIsDownloadAgendaItemFiles(true);
        if (authStore.user && assemblyid) {
            const accessToken = authStore.token;
            const tokenType = "Bearer";
            const role = authStore.user?.role;

            try {
                const fetchResult = await fetch(`${NetworkConfig.downloadFileVersion2}/${assemblyid}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    try {
                        const date = assemblyDetails?.date ? getDate(assemblyDetails?.date) : "";
                        let fileName = getDefaultFileName(date ?? "");
                        fileName = replaceAssemblyTitle(fileName, assemblyDetails?.title);
                        // start download file
                        let fileBlob = await fetchResult.blob();
                        const objUrl = window.URL.createObjectURL(fileBlob);
                        const a = document.createElement("a");
                        a.href = objUrl;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    } catch (error) {
                        printStatusMessage(
                            t("screens.realestate.assembly.error.assembly_file_download_error"),
                            MessageType.ERROR
                        );
                    }
                } else {
                    printStatusMessage(
                        t("screens.realestate.assembly.error.assembly_file_download_error"),
                        MessageType.ERROR
                    );
                }
            } catch (fetchError) {
                setIsDownloadAgendaItemFiles(false);
                printStatusMessage(
                    t("screens.realestate.assembly.error.assembly_file_download_error"),
                    MessageType.ERROR
                );
            }
        }
        setIsDownloadAgendaItemFiles(false);
    }, [
        authStore.token,
        authStore.user,
        t,
        printStatusMessage,
        assemblyDetails?.date,
        assemblyDetails?.title,
        assemblyid,
        getDefaultFileName,
        replaceAssemblyTitle
    ]);

    const onNotifyAssembly = useCallback(async () => {
        if (assemblyStore.hasActiveCoowners && authStore.user && authStore.token) {
            const accessToken = authStore.token;
            const tokenType = authStore.tokenType;
            const role = authStore.user?.role;
            const requestBody = {
                assemblyid: assemblyid
            };
            try {
                const fetchResult = await fetch(`${NetworkConfig.insertNotificationUrl}`, {
                    method: "PUT",
                    body: JSON.stringify(requestBody),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    assemblyStore.setIsAssemblyInsertedEventLoaded(false);
                    assemblyStore.setShowRefetchAssemblyData(true);
                } else {
                    const returnValue = await fetchResult.json();
                    printStatusMessage(
                        t("screens.realestate.news_preview.save_notification_error", {
                            errorMessage: returnValue.message
                        }),
                        MessageType.ERROR
                    );
                }
            } catch (error: any) {
                printStatusMessage(
                    t("screens.realestate.news_preview.save_notification_error", {
                        errorMessage: error.message
                    }),
                    MessageType.ERROR
                );
            }
        }
    }, [assemblyid, authStore, printStatusMessage, t, assemblyStore]);

    return (
        <UI.Card
            title={t("screens.realestate.assembly.actions")}
            className={`${styles.TileXl} ${styles.InfoItemsTile}`}
        >
            {isDownloadAgendaItemFiles || assemblyStore.isAssemblyPDFExists === false ? (
                <UI.RotatingSpinner noLogo={true} size={25} />
            ) : (
                <UI.Button
                    disabled={false}
                    onClick={onDownloadAgendaItemDocuments}
                    label={t("screens.realestate.assembly.download_agendaPdf")}
                />
            )}
            <UI.ConfirmationDialog
                key="notificationAssemblyDialog"
                buttonText={t("labels.notification")}
                modalTitle={t("labels.notification")}
                confirmationQuestion={
                    assemblyStore.hasActiveCoowners
                        ? t("screens.realestate.assembly.create_notification")
                        : t("screens.realestate.assembly.no_coowners_by_create_notification")
                }
                inProgressText={t("screens.realestate.assembly.create_notification_in_progress")}
                onConfirmation={onNotifyAssembly}
                isModalNegationButtonHidden={!assemblyStore.hasActiveCoowners}
                modalConfirmationButtonLabel={assemblyStore.hasActiveCoowners ? undefined : "Ok"}
                isButtonDisabled={
                    !assemblyStore.isAssemblyInsertedEventLoaded ||
                    !isNotificationButtonActive ||
                    assemblyStore.assembliesEditFormStatus === FORM_STATUS.SAVING_FORM ||
                    !(!assemblyStore.assembliesEditFormHasDirtyFields && !assemblyStore.agendaItemsHaveChanged)
                }
            />
        </UI.Card>
    );
};

export const AssembliesActionCard = withRouter(Sentry.withProfiler(observer(AssembliesActionCardBase)));
