import { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { ManualEmailModal } from "src/screens/tickets/ticket/details/actions/manualEmail/ManualEmailModal";
import { ManualEmailButton } from "src/screens/tickets/ticket/details/actions/manualEmail/ManualEmailButton";
import { RootStoreContext } from "src/stores/RootStore";
import { TICKET_STATE } from "src/stores/TicketStore";

const ManualEmailButtonAndModalBase = () => {
    const { ticketStore, manualEmailModalStore } = useContext(RootStoreContext);

    const onCloseManualEmailModal = useCallback(() => {
        manualEmailModalStore.setIsModalDisplayed(false);
        manualEmailModalStore.setIsActivationProgressModalDisplayed(false);
    }, [manualEmailModalStore]);

    const onClickManualEmailButton = useCallback(() => {
        manualEmailModalStore.setIsModalDisplayed(true);
        manualEmailModalStore.setIsActivationProgressModalDisplayed(true);
    }, [manualEmailModalStore]);

    return ticketStore.currentTicket?.state === TICKET_STATE.NEW ||
        ticketStore.currentTicket?.state === TICKET_STATE.ASSIGNED ||
        ticketStore.currentTicket?.state === TICKET_STATE.COMISSIONED ? (
        <>
            <ManualEmailModal
                showModal={manualEmailModalStore.isModalDisplayed}
                onClickClose={onCloseManualEmailModal}
            />
            <ManualEmailButton onClick={onClickManualEmailButton} />
        </>
    ) : (
        <></>
    );
};

export const ManualEmailButtonAndModal = Sentry.withProfiler(observer(ManualEmailButtonAndModalBase));
