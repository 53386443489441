import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { ScreenSize, UI } from "@wwimmo/ui";
import { Route, selectRoute } from "src/config/routes";
import { ORDER_LOADING_TYPE } from "src/stores/OrderStore";

interface TicketLinkListProps {
    ticketNumber: string;
}

const TicketLinkListBase = (props: TicketLinkListProps) => {
    const { t } = useTranslation();
    const { uiStore, authStore, orderStore } = useContext(RootStoreContext);

    const { ticketNumber } = props;

    const renderItem = useCallback(
        (item: { ticketNumber: string; ticketTitle: string }, screenSize: ScreenSize) => {
            const listRows: any[] = [];

            let rows: React.ReactElement[] = [];

            rows = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={1} value={item.ticketNumber} />
                    <UI.List.Cell key={"c-2"} colspan={3} value={item.ticketTitle} />
                </UI.List.Row>
            ];

            listRows.push(rows);

            return (
                <UI.List.Item
                    key={1}
                    screenSize={screenSize}
                    rows={listRows}
                    to={selectRoute(Route.ticketDetails, authStore.user?.role, {
                        ticketnumber: ticketNumber
                    })}
                />
            );
        },
        [authStore.user?.role, ticketNumber]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            const headerRows: React.ReactElement[] = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={1} value={`${t("labels.number_abr")}.`} />
                    <UI.List.Cell key={"c-2"} colspan={3} value={t("labels.designation")} />
                </UI.List.Row>
            ];

            return <UI.List.Header rows={headerRows} />;
        },
        [t]
    );

    return (
        <UI.Card title={t("screens.tickets.ticket").toString()} flex>
            {orderStore.loadingType === ORDER_LOADING_TYPE.INITIALIZING ? (
                <UI.RotatingSpinner />
            ) : (
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={[
                        {
                            ticketNumber: orderStore.currentOrderTicket?.number,
                            ticketTitle: orderStore.currentOrderTicket?.title
                        }
                    ]}
                    renderItem={renderItem}
                    renderHeader={renderHeader}
                />
            )}
        </UI.Card>
    );
};

export const TicketLinkList = Sentry.withProfiler(observer(TicketLinkListBase));
