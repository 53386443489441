import { useCallback, useState } from "react";

const NO_LIST_ITEM_SELECTED = -1;

export interface ISelectionListKeyHandlerProps {
    listName: string;
    totalNumberOfDisplayedItems: number;
    setIsListDisplayed: any;
    isListDisplayed: boolean;
    onSelectItem: any;
    resetSearchQuery: any;
}

export const useSelectionListKeyHandler = (selectionListKeyHandlerProps: ISelectionListKeyHandlerProps) => {
    const [activeListItemIndex, setActiveListItemIndex] = useState<number>(NO_LIST_ITEM_SELECTED);

    const {
        listName,
        totalNumberOfDisplayedItems,
        setIsListDisplayed,
        isListDisplayed,
        onSelectItem,
        resetSearchQuery
    } = selectionListKeyHandlerProps;

    const unfocusAllFields = useCallback(() => {
        var tmp = document.createElement("input");
        document.body.appendChild(tmp);
        tmp.focus();
        document.body.removeChild(tmp);
    }, []);

    const handleKeyArrowDown = useCallback((listName: string, numberOfListItems: number) => {
        setActiveListItemIndex((oldIndexValue) => {
            let newIndexValue = 0;

            if (oldIndexValue < numberOfListItems - 1) {
                newIndexValue = oldIndexValue + 1;
            } else {
                newIndexValue = numberOfListItems - 1;
            }

            const listElement = document.getElementById(`${listName}-dropdown-list`);
            const previousElement = document.getElementById(`${listName}-list-item-${oldIndexValue}`);
            const currentElement = document.getElementById(`${listName}-list-item-${newIndexValue}`);

            previousElement?.classList.remove("active");
            currentElement?.classList.add("active");

            const listHeight = listElement?.offsetHeight;
            const currentElementHeight = currentElement?.offsetHeight;
            const currentElementOffsetTop = currentElement?.offsetTop;

            if (
                typeof listHeight === "number" &&
                typeof currentElementHeight === "number" &&
                typeof currentElementOffsetTop === "number"
            ) {
                const pixelsToScroll = listHeight - currentElementHeight - currentElementOffsetTop;

                if (pixelsToScroll < 0) {
                    if (Math.abs(pixelsToScroll) > currentElementHeight) {
                        listElement?.scrollBy(0, currentElementHeight);
                    } else {
                        listElement?.scrollBy(0, Math.abs(pixelsToScroll));
                    }
                }
            }

            return newIndexValue;
        });
    }, []);

    const handleKeyArrowUp = useCallback((listName: string) => {
        setActiveListItemIndex((oldIndexValue) => {
            let newIndexValue = 0;

            if (oldIndexValue !== 0) {
                newIndexValue = oldIndexValue - 1;
            }

            const listElement = document.getElementById(`${listName}-dropdown-list`);
            const previousElement = document.getElementById(`${listName}-list-item-${oldIndexValue}`);
            const currentElement = document.getElementById(`${listName}-list-item-${newIndexValue}`);

            previousElement?.classList.remove("active");
            currentElement?.classList.add("active");

            const zipListScrollTop = listElement?.scrollTop;
            const currentElementHeight = currentElement?.offsetHeight;
            const currentElementOffsetTop = currentElement?.offsetTop;

            if (
                typeof zipListScrollTop === "number" &&
                typeof currentElementHeight === "number" &&
                typeof currentElementOffsetTop === "number"
            ) {
                const pixelsToScroll = currentElementOffsetTop - zipListScrollTop;

                if (pixelsToScroll < 0) {
                    if (Math.abs(pixelsToScroll) > currentElementHeight) {
                        listElement?.scrollBy(0, -currentElementHeight);
                    } else {
                        listElement?.scrollBy(0, pixelsToScroll);
                    }
                }
            }

            return newIndexValue;
        });
    }, []);

    const onKeyDownFunction = useCallback(
        async (e: any) => {
            if (isListDisplayed && (e.key === "Tab" || e.key === "Enter")) {
                e.preventDefault();

                if (activeListItemIndex === NO_LIST_ITEM_SELECTED) {
                    resetSearchQuery();
                } else {
                    onSelectItem(activeListItemIndex);
                }

                setIsListDisplayed(false);
                setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
                unfocusAllFields();
            } else if (isListDisplayed && e.key === "ArrowDown") {
                handleKeyArrowDown(listName, totalNumberOfDisplayedItems);
            } else if (isListDisplayed && e.key === "ArrowUp") {
                handleKeyArrowUp(listName);
            }
        },
        [
            handleKeyArrowDown,
            handleKeyArrowUp,
            activeListItemIndex,
            isListDisplayed,
            unfocusAllFields,
            listName,
            onSelectItem,
            setIsListDisplayed,
            totalNumberOfDisplayedItems,
            resetSearchQuery
        ]
    );

    return { onKeyDownFunction, activeListItemIndex, setActiveListItemIndex, NO_LIST_ITEM_SELECTED };
};
