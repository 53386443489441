import styles from "./AssemblyAgendaDocumentsListItem.module.css";

interface AssemblyDocumentsListItemProps {
    fileId?: any;
    fileName?: string;
    fileLink: string;
    additionalIcon: any;
    isDisplayedInModal: boolean;
}

export const AssemblyAgendaDocumentsListItem = (props: AssemblyDocumentsListItemProps) => {
    const { fileId, fileName, fileLink, additionalIcon, isDisplayedInModal } = props;

    return (
        <div className={styles.ListItem} key={fileId}>
            <a
                href={fileLink}
                className={`${styles.DocumentInfoItemsContainer} link-underline`}
                rel="noopener noreferrer"
                target="_blank"
            >
                <div className={styles.FileDetailsRow}>
                    <div className={isDisplayedInModal ? styles.FileNameModal : styles.FileName}>{fileName}</div>
                </div>
            </a>
            <div className={styles.AdditionalIcon}>{additionalIcon}</div>
        </div>
    );
};
