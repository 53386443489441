import { useEffect, useContext } from "react";
import { getValidRole, isValidRole, Role } from "src/network/User";
import { RootStoreContext } from "src/stores/RootStore";

export const useRoleChanger = () => {
    const { authStore, uiStore } = useContext(RootStoreContext);

    useEffect(() => {
        const roleFromUrlString: string = window.location.pathname.split("/")[1];
        const roleFromUrl: Role = getValidRole(roleFromUrlString);

        if (
            isValidRole(roleFromUrlString) &&
            authStore.user?.availableRoles.includes(roleFromUrl) &&
            roleFromUrl !== authStore.user.role
        ) {
            authStore.changeRole(roleFromUrl);
            uiStore.setRerenderNavigationBar(true);
        }
    }, [authStore, uiStore]);
};
