import React, { useContext, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import { ServicesCostsAndInvoicesHistoryListItem } from "./ServicesCostsAndInvoicesHistoryListItem";
import { InvoiceDetail } from "src/types";
import styles from "./ServicesCostsAndInvoicesHistoryList.module.css";

interface ServicesCostsAndInvoicesHistoryListProps {
    invoiceDetailsList: InvoiceDetail[];
}

const ServicesCostsAndInvoicesHistoryList = (props: ServicesCostsAndInvoicesHistoryListProps) => {
    const { t } = useTranslation();
    const { uiStore, servicesStore, navStore } = useContext(RootStoreContext);

    const listIdentifier = "ServicesCostsAndInvoicesHistoryList";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    useEffect(() => {
        servicesStore.setInvoiceDetails(props.invoiceDetailsList);
        // This useEffect should only run once
        // eslint-disable-next-line
    }, []);

    const listParameters = navStore.getListMapValue(listIdentifier);

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(listIdentifier, {
                scrollPosition: window.pageYOffset,
                paginationPage: currentPage
            });
        },
        [navStore, listIdentifier]
    );

    const onToggleHeader = useCallback(() => {
        servicesStore.toggleAllInvoicesSelected();
    }, [servicesStore]);

    const renderItem = useCallback((invoiceDetail: InvoiceDetail, screenSize: ScreenSize) => {
        return (
            <ServicesCostsAndInvoicesHistoryListItem
                key={invoiceDetail.yearAndMonth}
                invoiceDetail={invoiceDetail}
                screenSize={screenSize}
            />
        );
    }, []);

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            const checkBoxHeader = (
                <UI.Checkbox
                    name={"chk-areAllInvoiceDetailsSelected"}
                    onChange={onToggleHeader}
                    key={"chk-areAllInvoiceDetailsSelected"}
                    checked={servicesStore.isEveryInvoiceSelected}
                />
            );

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"r-1"}>
                            <div className={`${styles.checkBoxHeaderContainer}`}>{checkBoxHeader}</div>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={3}
                                value={t("screens.services.costs_and_invoices.list.month")}
                                className={styles.monthHeaderCell}
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1}
                                value={t("screens.services.costs_and_invoices.list.amount")}
                            />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={1}
                                value={t("screens.services.costs_and_invoices.list.invoiced")}
                            />
                        </UI.List.Row>
                    ];
                    break;

                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"r-1"}>
                            <div className={`${styles.checkBoxHeaderContainer}`}>{checkBoxHeader}</div>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={2}
                                value={t("screens.services.costs_and_invoices.list.month")}
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1}
                                value={t("screens.services.costs_and_invoices.list.amount")}
                            />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={1}
                                value={t("screens.services.costs_and_invoices.list.invoiced")}
                            />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} />;
        },
        [t, onToggleHeader, servicesStore.isEveryInvoiceSelected]
    );

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={servicesStore.invoiceDetails}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={12}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
        />
    );
};

export default observer(ServicesCostsAndInvoicesHistoryList);
