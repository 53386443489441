import { useContext, useEffect, useCallback, useState } from "react";
import * as Sentry from "@sentry/react";
import { Tiles } from "src/components";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { RouteComponentProps } from "react-router";
import {
    GetTilesByScreen,
    GetTilesByScreenVariables,
    GetTilesByScreen_portal_tilescreens
} from "src/api/generated/GetTilesByScreen";
import { GET_TILES_BY_SCREEN, ScreenType } from "src/api/tiles";
import {
    GetDashboardRealestateInfo,
    GetDashboardRealestateInfoVariables
} from "src/api/generated/GetDashboardRealestateInfo";
import { GET_DASHBOARD_REALESTATE_INFO } from "src/api/realestate";
import { useQuery } from "@apollo/client";
import * as Screens from "src/screens";
import { Route, selectRoute } from "src/config/routes";
import { getLang } from "src/utils/i18n";
import { observer } from "mobx-react-lite";
import { dateFormat } from "src/utils/Date";
import { getName } from "src/utils/PersonName";
import { InfoTileInformation } from "src/components/tiles/realestateinfo/RealestateInfoTile";
import styles from "./Dashboard.module.css";
import { ScreenWidth } from "src/stores/UIStore";
import {
    GetManagerFilesByRealEstateId,
    GetManagerFilesByRealEstateIdVariables,
    GetManagerFilesByRealEstateId_realestates_files as file_item
} from "src/api/generated/GetManagerFilesByRealEstateId";
import {
    GET_MANAGER_FILES_BY_REALESTATEID,
    GET_OWNER_FILES_BY_REALESTATEID,
    GET_COOWNER_FILES_BY_REALESTATEID,
    GET_COOWNER_FILES_BY_REALESTATEID_IT2
} from "src/api/documents";
import { Coordinates } from "src/components/tiles/location/Location";
import { ManagerNews } from "src/components/news/manager/ManagerNews";
import { ErpType, Role } from "src/network/User";

interface MatchParams {
    realestateid: string;
    ownerId: string;
}

const DashboardBase = (props: RouteComponentProps<MatchParams>) => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);
    const [hasCoordinatesForAddress, setHasCoordinatesForAddress] = useState<boolean>(false);
    const [hasLoadedCoordinates, setHasLoadedCoordinates] = useState<boolean>(false);
    const [coordinates, setCoordinates] = useState<Coordinates>({
        lat: 0,
        lng: 0,
        N: 0,
        E: 0
    });
    const realestateid = props.match.params.realestateid;

    const fetchCoordinates = (searchText: string) => {
        const sanitizedSearchText = searchText.trim().replace(/\s+/g, "%20");
        return fetch(
            `https://api3.geo.admin.ch/rest/services/ech/SearchServer?sr=2056&searchText=${sanitizedSearchText}&lang=de&type=locations`
        ).then(async (result) => {
            const json = await result.json();
            if (json.results.length > 0) {
                const attrs = json.results[0]?.attrs;
                if (attrs) {
                    return {
                        lat: attrs.lat,
                        lng: attrs.lon,
                        E: attrs.y,
                        N: attrs.x
                    } as Coordinates;
                } else {
                    return undefined;
                }
            }
        });
    };

    useEffect(() => {
        if (navStore.currentOwnerId) {
            if (navStore.singleRealestate) {
                navStore.setBackbutton(
                    selectRoute(Route.owners, authStore.portalRoleWithHighestPriority ?? authStore.user?.role),
                    t("screens.owners.title")
                );
            } else {
                navStore.setBackbutton(
                    selectRoute(
                        Route.realestatesOwner,
                        authStore.portalRoleWithHighestPriority ?? authStore.user?.role,
                        {
                            ownerid: navStore.currentOwnerId
                        }
                    ),
                    t("screens.realestates.title")
                );
            }
        } else {
            navStore.setBackbutton(
                selectRoute(Route.realestates, authStore.portalRoleWithHighestPriority ?? authStore.user?.role),
                t("screens.realestates.title")
            );
        }
    }, [authStore.user, navStore, t, authStore.portalRoleWithHighestPriority, uiStore.sidebarWidth, uiStore.isMobile]);

    const {
        loading: tile_loading,
        error: tile_error,
        data: tile_data
    } = useQuery<GetTilesByScreen, GetTilesByScreenVariables>(GET_TILES_BY_SCREEN, {
        variables: {
            realestateid: realestateid,
            screen: ScreenType.DASHBOARD,
            lang: getLang(),
            tiletypes: navStore.customer.activatedTiles
        }
    });

    const currentDate = `${dateFormat(new Date(), "yyyy-MM-dd")}T00:00:00.000Z`;

    const {
        loading: info_loading,
        error: info_error,
        data: info_data
    } = useQuery<GetDashboardRealestateInfo, GetDashboardRealestateInfoVariables>(GET_DASHBOARD_REALESTATE_INFO, {
        variables: { realestateid: realestateid, today: currentDate }
    });

    const {
        loading: latestDocuments_loading,
        error: latestDocuments_error,
        data: latestDocuments_data
    } = useQuery<GetManagerFilesByRealEstateId, GetManagerFilesByRealEstateIdVariables>(
        authStore.user?.role === Role.MANAGER
            ? GET_MANAGER_FILES_BY_REALESTATEID
            : authStore.user?.role === Role.OWNER
            ? GET_OWNER_FILES_BY_REALESTATEID
            : authStore.user?.hasCoownerRole && authStore.user.erpType === ErpType.IT2
            ? GET_COOWNER_FILES_BY_REALESTATEID_IT2
            : GET_COOWNER_FILES_BY_REALESTATEID,
        {
            variables: {
                realestateid: props.match.params.realestateid,
                limit: 5,
                lang: getLang()
            }
        }
    );

    const onRowClick = useCallback(() => {
        navStore.setBackbutton(
            selectRoute(Route.dashboard, authStore.user?.role, {
                realestateid: realestateid
            }),
            t("screens.realestate.dashboard.title")
        );
    }, [navStore, t, authStore.user?.role, realestateid]);

    if (tile_loading || info_loading || latestDocuments_loading) {
        return <Screens.Loading />;
    }
    if (tile_error || info_error || latestDocuments_error) {
        if (tile_error) {
            return <Screens.Error message={tile_error.message} networkError={tile_error.networkError} />;
        }

        if (info_error) {
            return <Screens.Error message={info_error.message} networkError={info_error.networkError} />;
        }

        if (latestDocuments_error) {
            return (
                <Screens.Error
                    message={latestDocuments_error.message}
                    networkError={latestDocuments_error.networkError}
                />
            );
        }
    }

    if (!tile_data || !info_data || !latestDocuments_data || info_data.realestate_info.length <= 0) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const realestateInfo = info_data.realestate_info[0];
    const house = realestateInfo.houses[0];

    if (!realestateInfo.consolidation && !hasLoadedCoordinates) {
        const streetWithHouseNr = house?.street ?? "";
        const houseNr = house?.housenumber ?? "";
        const zip = house?.zip ?? "";
        const city = house?.city ?? "";
        const street = houseNr && houseNr.length > 0 ? `${streetWithHouseNr} ${houseNr}` : `${streetWithHouseNr}`;
        fetchCoordinates(`${street} ${zip} ${city}`).then((coordResult) => {
            if (coordResult) {
                setHasLoadedCoordinates(true);
                setCoordinates({
                    lat: coordResult.lat,
                    lng: coordResult.lng,
                    E: coordResult.E,
                    N: coordResult.N
                });
                setHasCoordinatesForAddress(true);
            } else {
                setHasLoadedCoordinates(true);
                setHasCoordinatesForAddress(false);
            }
        });
    }

    const constructionYear = realestateInfo.constructionyear.aggregate?.min?.constructionyear;
    const lastRenovation = realestateInfo.lastrenovation.aggregate?.max?.lastrenovation;
    const egid = house ? house.egid : undefined;

    const address = house ? (
        <>
            <span key={"address-1"}>
                {house.street ?? ""} {house.housenumber ?? ""}
            </span>
            <span key={"address-2"}>{`${house.zip ?? ""} ${house.city ?? ""}`}</span>
        </>
    ) : undefined;

    const owner = realestateInfo.owner?.person;
    const ownerAddress = owner?.current_address[0];

    const ownerInfo = (
        <>
            <span key={"owner-name"} className={styles.InfoText}>
                {getName(owner?.name1, owner?.name2, owner?.legal)}
            </span>
            <span key={"owner-address"} className={styles.InfoText}>
                {`${ownerAddress?.zip ?? ""} ${ownerAddress?.city ?? ""}`}
            </span>
        </>
    );

    let ownerLink = undefined;

    if (owner && owner.id) {
        ownerLink = selectRoute(Route.ownerPerson, authStore.user?.role, {
            realestateid: realestateid,
            ownerid: owner?.id
        });
    }

    let realestatePersonsLink = undefined;
    let realestateAppliancesLink = undefined;
    let realestateMaintenancesLink = undefined;

    if (navStore.hasOptionRealestatePersons) {
        realestatePersonsLink = selectRoute(Route.realestatePersons, authStore.user?.role, {
            realestateid: realestateid
        });
    }
    if (navStore.hasCustomerOptionAppliances) {
        realestateAppliancesLink = selectRoute(Route.realestateAppliances, authStore.user?.role, {
            realestateid: realestateid
        });
    }
    if (navStore.hasOptionMaintenances) {
        realestateMaintenancesLink = selectRoute(Route.realestateMaintenances, authStore.user?.role, {
            realestateid: realestateid
        });
    }

    const realestateListItems: InfoTileInformation[] = [
        { label: t("screens.realestate.dashboard.number"), value: realestateInfo.number },
        { label: t("screens.realestate.dashboard.construction_year"), value: constructionYear },
        { label: t("screens.realestate.dashboard.last_renovation"), value: lastRenovation },
        { label: t("screens.realestate.dashboard.address"), value: address, className: "multi-line" },
        {
            label: realestateInfo.condominium
                ? t("screens.realestate.dashboard.community")
                : t("screens.realestate.dashboard.owner"),
            value: ownerInfo,
            className: "multi-line",
            to: ownerLink
        },
        { label: t("screens.realestate.dashboard.egid"), value: egid },
        {
            label: realestateInfo.consolidation
                ? t("screens.realestate.dashboard.realestatepersons_short")
                : t("screens.realestate.dashboard.realestatepersons"),
            to: realestatePersonsLink,
            spansTwoColumns: true
        },
        {
            label: realestateInfo.consolidation
                ? t("screens.realestate.dashboard.appliances_short")
                : t("screens.realestate.dashboard.appliances"),
            to: realestateAppliancesLink,
            spansTwoColumns: true,
            onClick: onRowClick
        },
        {
            label: realestateInfo.consolidation
                ? t("screens.realestate.dashboard.maintenances_short")
                : t("screens.realestate.dashboard.maintenances"),
            to: realestateMaintenancesLink,
            spansTwoColumns: true,
            onClick: onRowClick
        }
    ];

    if (authStore.user?.role === Role.MANAGER) {
        realestateListItems.push(
            ...[
                {
                    label: t("screens.realestate.dashboard.realestateusers"),
                    to: selectRoute(Route.realestateUsers, authStore.user?.role, {
                        realestateid: realestateid
                    }),
                    spansTwoColumns: true
                }
            ]
        );
    }

    let infoItemCount = 0;

    realestateListItems.forEach((item) => {
        if (item.value || item.to) {
            infoItemCount += 1;
        }
    });

    const tileCols: JSX.Element[] = [];

    let hasLatestRealestateFiles = false;
    const fileItems: file_item[] = [];

    latestDocuments_data.realestates.forEach((realestate) => {
        if (realestate.files.length > 0) {
            hasLatestRealestateFiles = true;
            fileItems.push(...realestate.files);
        }
    });

    if (!realestateInfo.condominium && hasLatestRealestateFiles) {
        tileCols.push(
            <UI.Col
                xs="12"
                sm={6}
                lg={infoItemCount > 4 ? 6 : 4}
                xl={infoItemCount > 4 ? 4 : 3}
                key="tile-latestdocuments"
            >
                <Tiles.LatestDocumentsTile
                    title={t("screens.realestate.dashboard.latest_documents")}
                    documents={fileItems}
                    className={styles.contactTile}
                />
            </UI.Col>
        );
    }

    if (!realestateInfo.consolidation && !realestateInfo.condominium && hasCoordinatesForAddress) {
        tileCols.push(
            <UI.Col xs="12" sm={6} lg={infoItemCount > 4 ? 6 : 4} xl={infoItemCount > 4 ? 4 : 3} key="tile-location">
                <Tiles.Location className={styles.contactTile} coordinates={coordinates} />
            </UI.Col>
        );
    }

    if (!realestateInfo.condominium) {
        tile_data.portal_tilescreens.forEach((tilescreen: GetTilesByScreen_portal_tilescreens, index) => {
            tileCols.push(
                <UI.Col
                    xs="12"
                    sm={6}
                    lg={infoItemCount > 4 ? 6 : 4}
                    xl={infoItemCount > 4 ? 4 : 3}
                    key={`tile-${index}`}
                >
                    {tilescreen.tile && <Tiles.Tile data={tilescreen.tile} />}
                </UI.Col>
            );
        });
    }

    tileCols.push(
        <UI.Col xs="12" sm={6} lg={infoItemCount > 4 ? 6 : 4} xl={infoItemCount > 4 ? 4 : 3} key="tile-contact">
            <Tiles.ContactTile realestateid={realestateid} className={styles.contactTile} />
        </UI.Col>
    );

    const realestateInfoTile = (
        <Tiles.RealestateInfoTile
            realestateListItems={realestateListItems}
            name={realestateInfo.name}
            infoItemCount={realestateInfo.condominium ? 1 : infoItemCount}
            calculateHeight={realestateInfo.condominium ? false : true}
        />
    );

    const infoTileRequiresAdditionalHeight = infoItemCount > 4;

    return (
        <UI.Container className="pt-4">
            <UI.Title>{t("screens.realestate.dashboard.title")}</UI.Title>
            <UI.Row>
                {realestateInfo.condominium ? (
                    <>
                        <UI.Col lg="4" xl="3">
                            {realestateInfoTile}
                        </UI.Col>
                        <UI.Col lg="8" xl="6">
                            <UI.Card title={t("screens.realestate.dashboard.news")}>
                                <ManagerNews />
                            </UI.Card>
                        </UI.Col>
                        <UI.Col lg="12" xl="3">
                            <Tiles.ContactTile realestateid={realestateid} className={styles.contactTile} />
                        </UI.Col>
                    </>
                ) : infoTileRequiresAdditionalHeight && window.innerWidth >= ScreenWidth.LG ? (
                    <>
                        <UI.Col sm="6" lg="4" xl="3" key={"realestate-info-tile"}>
                            {realestateInfoTile}
                        </UI.Col>
                        <UI.Col sm="6" lg="8" xl="9" key={"realestate-tiles"}>
                            <UI.Row>{tileCols}</UI.Row>
                        </UI.Col>
                    </>
                ) : (
                    <UI.Col key={"realestate-tiles"}>
                        <UI.Row>
                            <UI.Col
                                sm={infoTileRequiresAdditionalHeight ? "12" : "6"}
                                lg="4"
                                xl="3"
                                key={"realestate-info-tile"}
                            >
                                {realestateInfoTile}
                            </UI.Col>
                            {tileCols}
                        </UI.Row>
                    </UI.Col>
                )}
            </UI.Row>
        </UI.Container>
    );
};

export const Dashboard = Sentry.withProfiler(observer(DashboardBase));
