import { observable, action, makeObservable } from "mobx";
import { RootStore } from "./RootStore";

export interface INotificationStatus {
    name: string;
    email?: string;
    date?: string;
    state: number;
}

export class NotificationStore {
    rootStore: RootStore;
    @observable notificationId: number | undefined;
    @observable isNewsOrAssemblyState: number | undefined;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @action
    setNotificationId = (notificationId: number) => {
        this.notificationId = notificationId;
    };

    @action
    setIsNewsOrAssemblyState = (isNewsOrAssemblyState: number) => {
        this.isNewsOrAssemblyState = isNewsOrAssemblyState;
    };
}
