import React, { useContext } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { Ticket } from "src/stores/TicketStore";
import styles from "./OrderForm.module.css";
import { ScreenWidth } from "src/stores/UIStore";

const OrderFormBase = () => {
    const { t } = useTranslation();
    const { orderStore } = useContext(RootStoreContext);

    const contractor =
        orderStore.currentOrder?.contractor?.id !== undefined ? orderStore.currentOrder?.contractor : undefined;

    const contractorName = `${contractor?.name1 ?? ""} ${contractor?.name2 ?? ""}`.trim();
    const contractorZipAndCity = `${contractor?.zip} ${contractor?.city}`.trim();

    return (
        <UI.Form className={styles.OrderForm}>
            {/* Order-Number / Creation-Date / Title */}
            <UI.Row className={`mt-2 ${styles.TitleRow}`}>
                <UI.Col lg={9}>
                    <div>
                        <div className={styles.OrderNumber}>
                            {orderStore.currentOrder?.number
                                ? orderStore.currentOrder?.number.toString().padStart(5, "0")
                                : ""}{" "}
                            {orderStore.currentOrder?.title ? ` - ${orderStore.currentOrder?.title}` : ""}
                        </div>
                        <div className={styles.CreationDate}>
                            {orderStore.currentOrder?.date && Ticket.formatDate(orderStore.currentOrder.date)}
                        </div>
                    </div>
                </UI.Col>
            </UI.Row>

            {/* Status / Typ */}
            <UI.Row>
                <UI.Col lg={4} className={window.innerWidth <= ScreenWidth.MD ? "mt-2" : ""}>
                    <UI.Input
                        label={t("screens.tickets.form.state").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={t(`screens.orders.state.${orderStore.currentOrder?.state}`).toString()}
                    />
                </UI.Col>
                <UI.Col lg={4}>
                    <UI.Input
                        label={t("screens.tickets.list.type").toString()}
                        type="text"
                        className={styles.FormFieldSelect}
                        disabled={true}
                        value={t(
                            `screens.orders.contractor.type.${orderStore.currentOrder?.contractor?.type}`
                        ).toString()}
                    />
                </UI.Col>
            </UI.Row>

            {/* Auftrag */}
            <div className="mt-3">
                <span className={styles.FormSectionTitle}>{t("screens.orders.order")}</span>
            </div>
            <UI.Row className="mb-3">
                <UI.Col lg={8}>
                    <div className="form-label my-3">{t("labels.description").toString()}</div>
                    <span>{orderStore.currentOrder?.instruction}</span>
                </UI.Col>
            </UI.Row>
            <hr className="my-4" />
            {/* Dienstleister */}
            {contractor ? (
                <>
                    <div className="my-3">
                        <span className={styles.FormSectionTitle}>{t("screens.tickets.modals.service_provider")}</span>
                    </div>
                    <UI.Row className="mb-3">
                        <UI.Col lg={4}>
                            <div className="form-label mb-2">{contractorName}</div>
                            {contractor.street ? <div>{contractor.street}</div> : undefined}
                            {contractorZipAndCity.length > 0 ? <div>{contractorZipAndCity}</div> : undefined}
                        </UI.Col>
                        <UI.Col lg={4}>
                            {contractor.email ? (
                                <>
                                    <div
                                        className={`form-label mb-2 ${
                                            window.innerWidth <= ScreenWidth.MD ? "mt-2" : ""
                                        }`}
                                    >
                                        {t("screens.tickets.form.person.email").toString()}
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div>{contractor.email}</div>
                                        <UI.Icon icon={UI.SVGIcon.Mail} />
                                    </div>
                                </>
                            ) : undefined}
                            {contractor.mobile ? (
                                <>
                                    <div className="form-label mt-2 mb-2">
                                        {t("screens.tickets.form.person.mobile").toString()}
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div>{contractor.mobile}</div>
                                        <UI.Icon icon={UI.SVGIcon.Device} />
                                    </div>
                                </>
                            ) : undefined}
                        </UI.Col>
                    </UI.Row>
                </>
            ) : undefined}
        </UI.Form>
    );
};

export const OrderForm = Sentry.withProfiler(observer(OrderFormBase));
