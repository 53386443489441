import { gql } from "@apollo/client";

export const GET_REALESTATE_USERS = gql`
    query GetRealestateUsers($realestateid: uuid!, $lang: String = "de") {
        common_v_realestates_users(where: { realestateid: { _eq: $realestateid } }) {
            name
            email
            state
            lastlogin
            registeredat
            userinserted
            login_audit: audits(order_by: { timestamp: desc }, where: { event: { _in: [10, 30] } }, limit: 1) {
                timestamp
                __typename
            }
            registered_audit: audits(order_by: { timestamp: desc }, where: { event: { _eq: 60 } }, limit: 1) {
                timestamp
                __typename
            }
            activated_audit: audits(order_by: { timestamp: desc }, where: { event: { _eq: 70 } }, limit: 1) {
                timestamp
                __typename
            }
            blocked_audit: audits(order_by: { timestamp: desc }, where: { event: { _eq: 90 } }, limit: 1) {
                timestamp
                __typename
            }
            roles(distinct_on: role, order_by: { role: asc }) {
                role
            }
        }
        userrolesdefinition: common_enumerations(
            where: { language: { _eq: $lang }, type: { _eq: 91 } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;
