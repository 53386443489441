import React, { useCallback, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_INVOICE_HISTORY } from "src/api/invoice";
import { GetCustomerInvoiceHistory } from "src/api/generated/GetCustomerInvoiceHistory";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { withRouter, RouteComponentProps } from "react-router";
import ServicesCostsAndInvoicesHistoryList from "./ServicesCostsAndInvoicesHistoryList";
import { NetworkConfig } from "src/network/NetworkConfig";
import { MessageType } from "src/components/notifications/Notifier";
import { getRoleKey } from "src/network/User";
import styles from "./ServicesCostsAndInvoicesHistory.module.css";
import { InvoiceDetail } from "src/types";

const ServicesCostsAndInvoicesHistoryBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore, servicesStore } = useContext(RootStoreContext);

    const { data, loading, error } = useQuery<GetCustomerInvoiceHistory>(GET_CUSTOMER_INVOICE_HISTORY);
    const [isInvoicesDownloading, setIsInvoicesDownloading] = useState<boolean>(false);

    const printStatusMessage = useCallback(
        (message: string | React.ReactNode, messageType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: messageType,
                    autoClose: messageType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    useEffect(() => {
        navStore.setTitle("");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);

        navStore.setBackbutton(null);
    }, [navStore, t, authStore.user?.role, uiStore.isSidebarActive]);

    const onDownloadAssemblyDocuments = useCallback(async () => {
        setIsInvoicesDownloading(true);
        if (servicesStore.isAnyInvoiceSelected && authStore.user && data) {
            const accessToken = authStore.token;
            const tokenType = "Bearer";
            const role = authStore.user?.role;
            try {
                const fetchResult = await fetch(`${NetworkConfig.downloadCustomerInvoicedFiles}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    },
                    body: JSON.stringify({
                        invoiceDetailsSelected: servicesStore.invoicesSelected
                    })
                });
                if (fetchResult.status === 200) {
                    try {
                        const contentDisposition = fetchResult.headers.get("Content-Disposition");
                        // prettier-ignore
                        let fileName = contentDisposition?.split("filename=")[1]?.replaceAll("\"", "");
                        let fileBlob = await fetchResult.blob();
                        const objUrl = window.URL.createObjectURL(fileBlob);
                        const a = document.createElement("a");
                        a.href = objUrl;
                        a.download = fileName ?? t("screens.services.costs_and_invoices.overview.costs");
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    } catch (error) {
                        printStatusMessage(
                            t("screens.realestate.assembly.error.assembly_file_download_error"),
                            MessageType.ERROR
                        );
                        setIsInvoicesDownloading(false);
                    }
                } else {
                    printStatusMessage(
                        t("screens.realestate.assembly.error.assembly_file_download_error"),
                        MessageType.ERROR
                    );
                }
            } catch (fetchError) {
                setIsInvoicesDownloading(false);
                printStatusMessage(
                    t("screens.realestate.assembly.error.assembly_file_download_error"),
                    MessageType.ERROR
                );
            }
        }
        setIsInvoicesDownloading(false);
    }, [authStore, data, printStatusMessage, t, servicesStore.isAnyInvoiceSelected, servicesStore.invoicesSelected]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    const downloadDocumentButton = (
        <div>
            {isInvoicesDownloading ? (
                <UI.RotatingSpinner noLogo={true} size={25} />
            ) : (
                <UI.Button
                    onClick={onDownloadAssemblyDocuments}
                    label={"Download"}
                    disabled={!servicesStore.isAnyInvoiceSelected}
                    className={`${styles.downloadInvoiceButton}`}
                />
            )}
        </div>
    );

    let customerInvoicesObjects: { [key: string]: InvoiceDetail } = {};

    data.customerinvoices.forEach((customerInvoice) => {
        const count = customerInvoice.count ?? 0;

        if (!customerInvoicesObjects.hasOwnProperty(customerInvoice.date)) {
            customerInvoicesObjects[customerInvoice.date] = {
                id: customerInvoice.id,
                yearAndMonth: customerInvoice.date,
                amount: count * customerInvoice.costperunit,
                invoiced: customerInvoice.invoiced,
                selected: false
            };
        } else {
            customerInvoicesObjects[customerInvoice.date].amount += count * customerInvoice.costperunit;
        }
    });

    const invoiceDetails: InvoiceDetail[] = Object.values(customerInvoicesObjects).sort(
        (invoiceDetailA, invoiceDetailB) => {
            return invoiceDetailB.yearAndMonth.localeCompare(invoiceDetailA.yearAndMonth);
        }
    );

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col xl={{ span: 10 }} md={{ span: 10 }} sm={{ span: 10 }}>
                        <UI.Title>{t("screens.services.costs_and_invoices.history.title")}</UI.Title>
                    </UI.Col>
                    <UI.Col xl={{ span: 2 }} md={{ span: 2 }} sm={{ span: 2 }}>
                        {downloadDocumentButton}
                    </UI.Col>
                </UI.Row>
                <UI.Row>
                    <UI.Col md={12}>
                        <UI.Card>
                            <div>
                                <ServicesCostsAndInvoicesHistoryList invoiceDetailsList={invoiceDetails} />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const ServicesCostsAndInvoicesHistory = withRouter(
    Sentry.withProfiler(observer(ServicesCostsAndInvoicesHistoryBase))
);
