import { useQuery } from "@apollo/client";
import { AssemblyEventState, AssemblyEventStateVariables } from "src/api/generated/AssemblyEventState";
import { GET_ASSEMBLY_NOTIFICATION_STATE_BY_ASSEMBLYID } from "src/api/assembly";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { INotificationStatus } from "src/stores/NotificationStore";
import { RootStoreContext } from "src/stores/RootStore";

type GetDataAssemblyNotificationResult =
    | { loading: true }
    | { error: string; networkError?: any }
    | INotificationStatus[]
    | [];

export const GetDataAssemblyNotification = (): GetDataAssemblyNotificationResult => {
    const { notificationStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const { data, loading, error } = useQuery<AssemblyEventState, AssemblyEventStateVariables>(
        GET_ASSEMBLY_NOTIFICATION_STATE_BY_ASSEMBLYID,
        {
            variables: {
                assemblyid: notificationStore.notificationId
            }
        }
    );

    if (loading) {
        return { loading: true };
    }

    if (error) {
        return { error: error.message, networkError: error.networkError };
    }

    if (!data) {
        return { error: t("error.nodata").toString() };
    }

    if (data.AssemblyNotificationState && data.AssemblyNotificationState.length > 0) {
        const notificationStates: INotificationStatus[] = data.AssemblyNotificationState.map((item) => ({
            name: item.name || "",
            email: item.email || "",
            date: item.datetime || "",
            state: item.state || 0
        }));
        return notificationStates;
    } else {
        return [];
    }
};

export default GetDataAssemblyNotification;
