import React, { useContext, useCallback, useEffect, useState } from "react";
import {
    GetMaintenances_maintenances as maintenance,
    GetMaintenances_maintenancestatus as maintenancestatus
} from "src/api/generated/GetMaintenances";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { Route, selectRoute } from "src/config/routes";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize, DropdownItem } from "@wwimmo/ui";
import { useParams } from "react-router";
import * as Screens from "src/screens";
import { dateFormat } from "src/utils/Date";

interface MaintenancesListProps {
    maintenances: maintenance[];
    maintenancestatus: maintenancestatus[];
}

interface Params {
    realestateid: string;
    unitid: string;
    applianceid: string;
}

enum STATUS {
    ALL = "0"
}

const MaintenancesList = (props: MaintenancesListProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore, authStore } = useContext(RootStoreContext);
    const { realestateid, unitid, applianceid } = useParams<Params>();

    let listIdentifier = "";

    if (unitid) {
        listIdentifier = applianceid ? `maintenanceslist-${unitid}${applianceid}` : `maintenanceslist-${unitid}`;
    } else {
        listIdentifier = applianceid
            ? `maintenanceslist-${realestateid}${applianceid}`
            : `maintenanceslist-${realestateid}`;
    }

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    let currentSearchQuery: string | undefined = undefined;

    if (listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    }

    let currentFilterQuery: string | undefined = undefined;

    if (listParameters && listParameters.filterValue) {
        currentFilterQuery = listParameters.filterValue;
    }

    const [displayedMaintenances, setDisplayedMaintenances] = useState<maintenance[]>([]);
    const [maintenancestatus, setMaintenancestatus] = useState<Map<number | null, { label: string }>>();
    const [filterDropdownItems, setFilterDropdownItems] = useState<Array<DropdownItem>>([]);

    useEffect(() => {
        if (props.maintenancestatus) {
            setMaintenancestatus(getEnumerationMap(props.maintenancestatus));
        }
    }, [props.maintenancestatus]);

    const filterPersonsAndSetSearchQuery = useCallback(
        (searchQuery: string = "", statusId: string = STATUS.ALL) => {
            if (props.maintenances) {
                let foundMaintenances = props.maintenances.filter((maintenance) => {
                    const person = maintenance.craftsman;
                    const appliance = maintenance.appliance;

                    return (
                        person?.name1?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        person?.name2?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        maintenance.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        appliance?.name?.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                });

                if (statusId !== STATUS.ALL) {
                    foundMaintenances = foundMaintenances.filter((maintenance) => {
                        return maintenance.status === Number(statusId) - 1;
                    });
                }

                setDisplayedMaintenances([...foundMaintenances]);
            }
        },
        [props.maintenances]
    );

    const onRowClick = useCallback(() => {
        navStore.setListMapValue(
            listIdentifier,
            listParameters
                ? { ...listParameters, scrollPosition: window.pageYOffset }
                : { scrollPosition: window.pageYOffset }
        );

        navStore.setBackbutton(
            selectRoute(unitid ? Route.unitMaintenances : Route.realestateMaintenances, authStore.user?.role, {
                realestateid: realestateid,
                unitid: unitid
            }),
            unitid ? t("screens.unit.maintenances") : t("screens.realestate.dashboard.maintenances")
        );
    }, [navStore, t, authStore.user?.role, realestateid, unitid, listIdentifier, listParameters]);

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listIdentifier, listParameters]
    );

    const handleSearchInput = useCallback(
        ({ searchQuery, filterItemValue }) => {
            filterPersonsAndSetSearchQuery(searchQuery, filterItemValue);

            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: searchQuery, filterValue: filterItemValue }
                    : { scrollPosition: 0, searchQuery: searchQuery, filterValue: filterItemValue }
            );
        },
        [filterPersonsAndSetSearchQuery, navStore, listIdentifier, listParameters]
    );

    const getEnumerationMap = (enumerations: Array<maintenancestatus>) => {
        const enumerationMap = new Map();

        enumerations.forEach((enumeration) => {
            if (enumeration.key !== null) {
                enumerationMap.set(enumeration.key, {
                    label: enumeration.label
                });
            }
        });

        return enumerationMap;
    };

    const getFilterItems = (enumerations: Array<maintenancestatus>) => {
        const filterDropdownItemsArray: DropdownItem[] = [];

        filterDropdownItemsArray.push({
            value: STATUS.ALL,
            label: "Alle"
        });

        enumerations.forEach((enumeration) => {
            if (enumeration.key !== null) {
                filterDropdownItemsArray.push({
                    value: String(enumeration.key + 1),
                    label: enumeration.label ?? ""
                });
            }
        });

        return filterDropdownItemsArray;
    };

    useEffect(() => {
        if (props.maintenancestatus) {
            setFilterDropdownItems(getFilterItems(props.maintenancestatus));
        }
    }, [props.maintenancestatus]);

    const onChangeFilter = useCallback(
        ({ searchQuery, filterItemValue }) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, filterValue: filterItemValue, searchQuery: searchQuery }
                    : { filterValue: filterItemValue, searchQuery: searchQuery }
            );

            filterPersonsAndSetSearchQuery(searchQuery, filterItemValue);
        },
        [filterPersonsAndSetSearchQuery, navStore, listIdentifier, listParameters]
    );

    const getDate = (input: string) => {
        if (input) {
            return dateFormat(new Date(input), "dd.MM.yyyy");
        }
    };

    const renderItem = useCallback(
        (maintenance: maintenance, screenSize: ScreenSize) => {
            const craftsman = maintenance.craftsman;
            const maintenanceStatus = maintenancestatus?.get(maintenance.status)?.label;

            let listRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={0.5} value={getDate(maintenance.date)} />
                            <UI.List.Cell key={"c-2"} colspan={1.25} value={maintenance.name ?? ""} />
                            <UI.List.Cell key={"c-3"} colspan={0.75} value={maintenanceStatus} />
                            <UI.List.Cell key={"c-4"} colspan={1} value={maintenance.appliance?.name ?? ""} />
                            <UI.List.Cell
                                key={"c-5"}
                                colspan={1}
                                value={`${craftsman?.name1 ?? ""} ${craftsman?.name2 ?? ""}`}
                            />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-2"} colspan={1} value={maintenance.name ?? ""} />
                            <UI.List.Cell key={"c-4"} colspan={0.75} value={maintenance.appliance?.name ?? ""} />
                        </UI.List.Row>
                    ];
                    break;
            }

            const link = selectRoute(
                unitid ? Route.unitMaintenance : Route.realestateMaintenance,
                authStore.user?.role,
                {
                    realestateid: realestateid,
                    unitid: unitid,
                    maintenanceid: maintenance.id
                }
            );

            return (
                <UI.List.Item
                    key={maintenance.id}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                    onClick={onRowClick}
                />
            );
        },
        [onRowClick, authStore.user?.role, realestateid, maintenancestatus, unitid]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={0.5} value={t("screens.realestate.maintenance.date")} />
                            <UI.List.Cell key={"c-2"} colspan={1.25} value={t("screens.realestate.maintenance.name")} />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={0.75}
                                value={t("screens.realestate.maintenance.status")}
                            />
                            <UI.List.Cell
                                key={"c-4"}
                                colspan={1}
                                value={t("screens.realestate.maintenance.appliance")}
                            />
                            <UI.List.Cell
                                key={"c-5"}
                                colspan={1}
                                value={t("screens.realestate.maintenance.craftsman")}
                            />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={t("screens.realestate.maintenance.name")} />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={0.75}
                                value={t("screens.realestate.maintenance.appliance")}
                            />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} sticky={true} top={130} />;
        },
        [t]
    );

    if (displayedMaintenances === [] || filterDropdownItems.length === 0) {
        return <Screens.Loading />;
    }

    const initialFilterItem = filterDropdownItems.find((item) => item.value === currentFilterQuery);

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={displayedMaintenances}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={25}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
            onSearch={handleSearchInput}
            initialSearchQuery={currentSearchQuery}
            filterItems={filterDropdownItems}
            initialFilterItem={initialFilterItem}
            onChangeFilter={onChangeFilter}
        />
    );
};

export default observer(MaintenancesList);
