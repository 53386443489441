/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * select columns of table "common.appliances"
 */
export enum common_appliances_select_column {
  id = "id",
  lastofficialinspection = "lastofficialinspection",
  lastrevision = "lastrevision",
  manufacturer = "manufacturer",
  model = "model",
  name = "name",
  nextofficialinspection = "nextofficialinspection",
  nextrevision = "nextrevision",
  outofservicedate = "outofservicedate",
  productionnumber = "productionnumber",
  purchasedate = "purchasedate",
  realestateid = "realestateid",
  remarks = "remarks",
  serialnumber = "serialnumber",
  servicecompanyid = "servicecompanyid",
  serviceconditions = "serviceconditions",
  serviceend = "serviceend",
  servicestart = "servicestart",
  unitid = "unitid",
}

/**
 * select columns of table "common.conditions"
 */
export enum common_conditions_select_column {
  consumerpriceindex = "consumerpriceindex",
  costincreaseindex = "costincreaseindex",
  flatratefee = "flatratefee",
  grossrent = "grossrent",
  id = "id",
  maturities = "maturities",
  netrent = "netrent",
  paymentonaccount = "paymentonaccount",
  rentincreasereserveamount = "rentincreasereserveamount",
  rentincreasereservetext = "rentincreasereservetext",
  tenancyid = "tenancyid",
  validfrom = "validfrom",
  vat = "vat",
}

/**
 * select columns of table "common.datafilelinks"
 */
export enum common_datafilelinks_select_column {
  datafileid = "datafileid",
  foreignlinkentity = "foreignlinkentity",
  foreignlinkid = "foreignlinkid",
  id = "id",
  linktype = "linktype",
  roleid = "roleid",
  updated = "updated",
  visible = "visible",
}

/**
 * select "common_datafilelinks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "common.datafilelinks"
 */
export enum common_datafilelinks_select_column_common_datafilelinks_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "common_datafilelinks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "common.datafilelinks"
 */
export enum common_datafilelinks_select_column_common_datafilelinks_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * select columns of table "common.datafiles"
 */
export enum common_datafiles_select_column {
  customerid = "customerid",
  deleted = "deleted",
  extension = "extension",
  externallink = "externallink",
  filedate = "filedate",
  hash = "hash",
  id = "id",
  mimetype = "mimetype",
  name = "name",
  origin = "origin",
  thumbnailid = "thumbnailid",
}

/**
 * select columns of table "common.houses"
 */
export enum common_houses_select_column {
  city = "city",
  constructionyear = "constructionyear",
  customerid = "customerid",
  egid = "egid",
  housenumber = "housenumber",
  id = "id",
  lastrenovation = "lastrenovation",
  number = "number",
  realestateid = "realestateid",
  street = "street",
  zip = "zip",
}

/**
 * select columns of table "common.maintenances"
 */
export enum common_maintenances_select_column {
  applianceid = "applianceid",
  bookingid = "bookingid",
  cost = "cost",
  craftsmanid = "craftsmanid",
  craftsmanreference = "craftsmanreference",
  date = "date",
  id = "id",
  name = "name",
  realestateid = "realestateid",
  remarks = "remarks",
  status = "status",
  unitid = "unitid",
  workfrom = "workfrom",
  workuntil = "workuntil",
}

/**
 * select columns of table "common.managements"
 */
export enum common_managements_select_column {
  id = "id",
  personid = "personid",
}

/**
 * select columns of table "common.owners"
 */
export enum common_owners_select_column {
  id = "id",
  personid = "personid",
}

/**
 * unique or primary key constraints on table "common.persons"
 */
export enum common_persons_constraint {
  PK_persons = "PK_persons",
}

/**
 * select columns of table "common.persons"
 */
export enum common_persons_select_column {
  birthdate = "birthdate",
  civilstate = "civilstate",
  customerid = "customerid",
  email = "email",
  gender = "gender",
  hometown = "hometown",
  id = "id",
  language = "language",
  legal = "legal",
  mobile = "mobile",
  name1 = "name1",
  name2 = "name2",
  nationality = "nationality",
  origin = "origin",
  phonebusiness = "phonebusiness",
  phoneprivate = "phoneprivate",
  remarks = "remarks",
  salutation = "salutation",
  salutationletter = "salutationletter",
}

/**
 * select "common_persons_aggregate_bool_exp_bool_and_arguments_columns" columns of table "common.persons"
 */
export enum common_persons_select_column_common_persons_aggregate_bool_exp_bool_and_arguments_columns {
  legal = "legal",
}

/**
 * select "common_persons_aggregate_bool_exp_bool_or_arguments_columns" columns of table "common.persons"
 */
export enum common_persons_select_column_common_persons_aggregate_bool_exp_bool_or_arguments_columns {
  legal = "legal",
}

/**
 * update columns of table "common.persons"
 */
export enum common_persons_update_column {
  email = "email",
  id = "id",
  mobile = "mobile",
  phonebusiness = "phonebusiness",
  phoneprivate = "phoneprivate",
}

/**
 * select columns of table "common.realestateaccess"
 */
export enum common_realestateaccess_select_column {
  accesslevel = "accesslevel",
  id = "id",
  realestateid = "realestateid",
  role = "role",
  userid = "userid",
}

/**
 * select columns of table "common.realestatecontacts"
 */
export enum common_realestatecontacts_select_column {
  accountantemail = "accountantemail",
  accountantmobile = "accountantmobile",
  accountantname = "accountantname",
  accountantphone = "accountantphone",
  facilityemail = "facilityemail",
  facilitymobile = "facilitymobile",
  facilityname = "facilityname",
  facilityphone = "facilityphone",
  id = "id",
  manageremail = "manageremail",
  managermobile = "managermobile",
  managername = "managername",
  managerphone = "managerphone",
  realestateid = "realestateid",
}

/**
 * select columns of table "common.realestatefeatures"
 */
export enum common_realestatefeatures_select_column {
  feature = "feature",
  id = "id",
  realestateid = "realestateid",
}

/**
 * select columns of table "common.realestatepersons"
 */
export enum common_realestatepersons_select_column {
  id = "id",
  personid = "personid",
  realestateid = "realestateid",
  role = "role",
}

/**
 * select columns of table "common.realestates"
 */
export enum common_realestates_select_column {
  condominium = "condominium",
  consolidation = "consolidation",
  consolidationid = "consolidationid",
  id = "id",
  managementid = "managementid",
  name = "name",
  number = "number",
  ownerid = "ownerid",
}

/**
 * select "common_realestates_aggregate_bool_exp_bool_and_arguments_columns" columns of table "common.realestates"
 */
export enum common_realestates_select_column_common_realestates_aggregate_bool_exp_bool_and_arguments_columns {
  condominium = "condominium",
  consolidation = "consolidation",
}

/**
 * select "common_realestates_aggregate_bool_exp_bool_or_arguments_columns" columns of table "common.realestates"
 */
export enum common_realestates_select_column_common_realestates_aggregate_bool_exp_bool_or_arguments_columns {
  condominium = "condominium",
  consolidation = "consolidation",
}

/**
 * select columns of table "common.realestateusers"
 */
export enum common_realestateusers_select_column {
  id = "id",
  realestateid = "realestateid",
  role = "role",
  userid = "userid",
}

/**
 * select columns of table "common.tenancypersons"
 */
export enum common_tenancypersons_select_column {
  id = "id",
  personid = "personid",
  realestateid = "realestateid",
  role = "role",
  tenancyid = "tenancyid",
}

/**
 * select columns of table "common.tenancys"
 */
export enum common_tenancys_select_column {
  deposit = "deposit",
  id = "id",
  leaseend = "leaseend",
  leasestart = "leasestart",
  minimumrentdate = "minimumrentdate",
  movein = "movein",
  moveout = "moveout",
  noticemonths = "noticemonths",
  noticeperiodowner = "noticeperiodowner",
  noticeperiodtenant = "noticeperiodtenant",
  realestateid = "realestateid",
  tenantid = "tenantid",
  type = "type",
  unitid = "unitid",
  vacancy = "vacancy",
  vattaxed = "vattaxed",
}

/**
 * select "common_tenancys_aggregate_bool_exp_bool_and_arguments_columns" columns of table "common.tenancys"
 */
export enum common_tenancys_select_column_common_tenancys_aggregate_bool_exp_bool_and_arguments_columns {
  deposit = "deposit",
  vacancy = "vacancy",
  vattaxed = "vattaxed",
}

/**
 * select "common_tenancys_aggregate_bool_exp_bool_or_arguments_columns" columns of table "common.tenancys"
 */
export enum common_tenancys_select_column_common_tenancys_aggregate_bool_exp_bool_or_arguments_columns {
  deposit = "deposit",
  vacancy = "vacancy",
  vattaxed = "vattaxed",
}

/**
 * select columns of table "common.tenantaccess"
 */
export enum common_tenantaccess_select_column {
  role = "role",
  tenantid = "tenantid",
  userid = "userid",
}

/**
 * select columns of table "common.tenants"
 */
export enum common_tenants_select_column {
  accountbalance = "accountbalance",
  id = "id",
  name = "name",
}

/**
 * select columns of table "common.units"
 */
export enum common_units_select_column {
  category = "category",
  ewid = "ewid",
  floor = "floor",
  hash = "hash",
  houseid = "houseid",
  id = "id",
  name = "name",
  number = "number",
  otoid = "otoid",
  realestateid = "realestateid",
  rooms = "rooms",
  space = "space",
  utilization = "utilization",
  valuequota = "valuequota",
}

/**
 * select "common_units_aggregate_bool_exp_avg_arguments_columns" columns of table "common.units"
 */
export enum common_units_select_column_common_units_aggregate_bool_exp_avg_arguments_columns {
  rooms = "rooms",
  space = "space",
  valuequota = "valuequota",
}

/**
 * select "common_units_aggregate_bool_exp_corr_arguments_columns" columns of table "common.units"
 */
export enum common_units_select_column_common_units_aggregate_bool_exp_corr_arguments_columns {
  rooms = "rooms",
  space = "space",
  valuequota = "valuequota",
}

/**
 * select "common_units_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "common.units"
 */
export enum common_units_select_column_common_units_aggregate_bool_exp_covar_samp_arguments_columns {
  rooms = "rooms",
  space = "space",
  valuequota = "valuequota",
}

/**
 * select "common_units_aggregate_bool_exp_max_arguments_columns" columns of table "common.units"
 */
export enum common_units_select_column_common_units_aggregate_bool_exp_max_arguments_columns {
  rooms = "rooms",
  space = "space",
  valuequota = "valuequota",
}

/**
 * select "common_units_aggregate_bool_exp_min_arguments_columns" columns of table "common.units"
 */
export enum common_units_select_column_common_units_aggregate_bool_exp_min_arguments_columns {
  rooms = "rooms",
  space = "space",
  valuequota = "valuequota",
}

/**
 * select "common_units_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "common.units"
 */
export enum common_units_select_column_common_units_aggregate_bool_exp_stddev_samp_arguments_columns {
  rooms = "rooms",
  space = "space",
  valuequota = "valuequota",
}

/**
 * select "common_units_aggregate_bool_exp_sum_arguments_columns" columns of table "common.units"
 */
export enum common_units_select_column_common_units_aggregate_bool_exp_sum_arguments_columns {
  rooms = "rooms",
  space = "space",
  valuequota = "valuequota",
}

/**
 * select "common_units_aggregate_bool_exp_var_samp_arguments_columns" columns of table "common.units"
 */
export enum common_units_select_column_common_units_aggregate_bool_exp_var_samp_arguments_columns {
  rooms = "rooms",
  space = "space",
  valuequota = "valuequota",
}

/**
 * select columns of table "common.v_coowners_by_realestateaccess"
 */
export enum common_v_coowners_by_realestateaccess_select_column {
  accesslevel = "accesslevel",
  email = "email",
  id = "id",
  realestateid = "realestateid",
  role = "role",
  userid = "userid",
}

/**
 * select columns of table "common.v_tenants_by_realestateaccess"
 */
export enum common_v_tenants_by_realestateaccess_select_column {
  accesslevel = "accesslevel",
  email = "email",
  id = "id",
  realestateid = "realestateid",
  role = "role",
  userid = "userid",
}

/**
 * unique or primary key constraints on table "cond.agendaitems"
 */
export enum cond_agendaitems_constraint {
  PK_agendaitems = "PK_agendaitems",
}

/**
 * unique or primary key constraints on table "cond.agendaitems_mls"
 */
export enum cond_agendaitems_mls_constraint {
  PK_agendaitems_mls = "PK_agendaitems_mls",
  UNIQUE_agendaitem_agendaitem_mls = "UNIQUE_agendaitem_agendaitem_mls",
}

/**
 * select columns of table "cond.agendaitems_mls"
 */
export enum cond_agendaitems_mls_select_column {
  agendaitemid = "agendaitemid",
  description = "description",
  id = "id",
  language = "language",
  text = "text",
}

/**
 * update columns of table "cond.agendaitems_mls"
 */
export enum cond_agendaitems_mls_update_column {
  agendaitemid = "agendaitemid",
  description = "description",
  id = "id",
  language = "language",
  text = "text",
}

/**
 * select columns of table "cond.agendaitems"
 */
export enum cond_agendaitems_select_column {
  assemblyid = "assemblyid",
  id = "id",
  parentagendaitemid = "parentagendaitemid",
  sort = "sort",
}

/**
 * update columns of table "cond.agendaitems"
 */
export enum cond_agendaitems_update_column {
  assemblyid = "assemblyid",
  parentagendaitemid = "parentagendaitemid",
  sort = "sort",
}

/**
 * unique or primary key constraints on table "cond.agendaitemtemplates"
 */
export enum cond_agendaitemtemplates_constraint {
  PK_agendaitemtemplates = "PK_agendaitemtemplates",
}

/**
 * unique or primary key constraints on table "cond.agendaitemtemplates_mls"
 */
export enum cond_agendaitemtemplates_mls_constraint {
  PK_agendaitemtemplates_mls = "PK_agendaitemtemplates_mls",
  UNIQUE_agendaitemtemplate_mls = "UNIQUE_agendaitemtemplate_mls",
}

/**
 * select columns of table "cond.agendaitemtemplates_mls"
 */
export enum cond_agendaitemtemplates_mls_select_column {
  agendaitemtemplateid = "agendaitemtemplateid",
  description = "description",
  id = "id",
  language = "language",
  text = "text",
}

/**
 * update columns of table "cond.agendaitemtemplates_mls"
 */
export enum cond_agendaitemtemplates_mls_update_column {
  description = "description",
  language = "language",
  text = "text",
}

/**
 * select columns of table "cond.agendaitemtemplates"
 */
export enum cond_agendaitemtemplates_select_column {
  id = "id",
  parentagendaitemtemplateid = "parentagendaitemtemplateid",
  sort = "sort",
}

/**
 * update columns of table "cond.agendaitemtemplates"
 */
export enum cond_agendaitemtemplates_update_column {
  parentagendaitemtemplateid = "parentagendaitemtemplateid",
  sort = "sort",
}

/**
 * unique or primary key constraints on table "cond.assemblys"
 */
export enum cond_assemblys_constraint {
  PK_assemblys = "PK_assemblys",
}

/**
 * select columns of table "cond.assemblys"
 */
export enum cond_assemblys_select_column {
  city = "city",
  date = "date",
  housenumber = "housenumber",
  id = "id",
  locationdetail = "locationdetail",
  motiondeadline = "motiondeadline",
  publishdate = "publishdate",
  realestateid = "realestateid",
  remarks = "remarks",
  state = "state",
  street = "street",
  title = "title",
  zip = "zip",
}

/**
 * update columns of table "cond.assemblys"
 */
export enum cond_assemblys_update_column {
  city = "city",
  date = "date",
  housenumber = "housenumber",
  locationdetail = "locationdetail",
  motiondeadline = "motiondeadline",
  realestateid = "realestateid",
  remarks = "remarks",
  state = "state",
  street = "street",
  title = "title",
  zip = "zip",
}

/**
 * unique or primary key constraints on table "cond.assemblyuseraudits"
 */
export enum cond_assemblyuseraudits_constraint {
  PK_assemblyuseraudits = "PK_assemblyuseraudits",
  assemblyuseraudits_customerid_userid_assemblyid = "assemblyuseraudits_customerid_userid_assemblyid",
}

/**
 * select columns of table "cond.assemblyuseraudits"
 */
export enum cond_assemblyuseraudits_select_column {
  assemblyid = "assemblyid",
  event = "event",
  id = "id",
  timestamp = "timestamp",
  userid = "userid",
}

/**
 * update columns of table "cond.assemblyuseraudits"
 */
export enum cond_assemblyuseraudits_update_column {
  assemblyid = "assemblyid",
  event = "event",
  id = "id",
  timestamp = "timestamp",
  userid = "userid",
}

/**
 * select columns of table "cond.v_getagendaitemtexts"
 */
export enum cond_v_getagendaitemtexts_select_column {
  assemblyid = "assemblyid",
  description = "description",
  id = "id",
  language = "language",
  parentagendaitemid = "parentagendaitemid",
  sort = "sort",
  text = "text",
}

/**
 * select columns of table "cred.creditors"
 */
export enum cred_creditors_select_column {
  customerid = "customerid",
  hash = "hash",
  id = "id",
  name = "name",
  personid = "personid",
}

/**
 * unique or primary key constraints on table "cred.invoices"
 */
export enum cred_invoices_constraint {
  PK_invoices = "PK_invoices",
  invoices_customer_number_unique = "invoices_customer_number_unique",
}

/**
 * select columns of table "cred.invoices"
 */
export enum cred_invoices_select_column {
  amount = "amount",
  creditorid = "creditorid",
  creditorinfo = "creditorinfo",
  customerid = "customerid",
  date = "date",
  duedate = "duedate",
  id = "id",
  incidentid = "incidentid",
  insertuserid = "insertuserid",
  invoiceinfo = "invoiceinfo",
  invoicenumber = "invoicenumber",
  number = "number",
  orderid = "orderid",
  origin = "origin",
  paiddate = "paiddate",
  paymentaccountid = "paymentaccountid",
  paymentinfo = "paymentinfo",
  payoutaccountid = "payoutaccountid",
  realestateid = "realestateid",
  rejectedcomment = "rejectedcomment",
  state = "state",
  type = "type",
  unitid = "unitid",
  workflowinstance = "workflowinstance",
}

/**
 * update columns of table "cred.invoices"
 */
export enum cred_invoices_update_column {
  amount = "amount",
  creditorid = "creditorid",
  creditorinfo = "creditorinfo",
  date = "date",
  duedate = "duedate",
  incidentid = "incidentid",
  insertuserid = "insertuserid",
  invoiceinfo = "invoiceinfo",
  invoicenumber = "invoicenumber",
  number = "number",
  orderid = "orderid",
  origin = "origin",
  paiddate = "paiddate",
  paymentaccountid = "paymentaccountid",
  paymentinfo = "paymentinfo",
  payoutaccountid = "payoutaccountid",
  realestateid = "realestateid",
  rejectedcomment = "rejectedcomment",
  state = "state",
  type = "type",
  unitid = "unitid",
  workflowinstance = "workflowinstance",
}

/**
 * select columns of table "cred.paymentaccounts"
 */
export enum cred_paymentaccounts_select_column {
  creditorid = "creditorid",
  customerid = "customerid",
  hash = "hash",
  iban = "iban",
  id = "id",
  name = "name",
  type = "type",
}

/**
 * unique or primary key constraints on table "cred.workflowusers"
 */
export enum cred_workflowusers_constraint {
  PK_workflowusers = "PK_workflowusers",
}

/**
 * select columns of table "cred.workflowusers"
 */
export enum cred_workflowusers_select_column {
  customerid = "customerid",
  id = "id",
  invoiceid = "invoiceid",
  role = "role",
  signaturestamp = "signaturestamp",
  userid = "userid",
}

/**
 * update columns of table "cred.workflowusers"
 */
export enum cred_workflowusers_update_column {
  id = "id",
  invoiceid = "invoiceid",
  role = "role",
  signaturestamp = "signaturestamp",
  userid = "userid",
}

/**
 * select columns of table "ec.component_mls"
 */
export enum ec_component_mls_select_column {
  componentid = "componentid",
  hint = "hint",
  id = "id",
  language = "language",
  title = "title",
}

/**
 * select columns of table "ec.components"
 */
export enum ec_components_select_column {
  icon = "icon",
  id = "id",
  parameters = "parameters",
  parentid = "parentid",
  sort = "sort",
  type = "type",
}

/**
 * select columns of table "ec.notifications"
 */
export enum ec_notifications_select_column {
  component = "component",
  componentid = "componentid",
  contactid = "contactid",
  contacttype = "contacttype",
  customattributes = "customattributes",
  customerid = "customerid",
  date = "date",
  forwardingtype = "forwardingtype",
  hash = "hash",
  houseid = "houseid",
  id = "id",
  language = "language",
  message = "message",
  number = "number",
  originalmessage = "originalmessage",
  realestateid = "realestateid",
  remarks = "remarks",
  state = "state",
  tenancyid = "tenancyid",
  tenantid = "tenantid",
  token = "token",
  type = "type",
  unitid = "unitid",
}

/**
 * select columns of table "file.accountlistfiles"
 */
export enum file_accountlistfiles_select_column {
  accountlistid = "accountlistid",
  fileid = "fileid",
  id = "id",
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * select "file_accountlistfiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.accountlistfiles"
 */
export enum file_accountlistfiles_select_column_file_accountlistfiles_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_accountlistfiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.accountlistfiles"
 */
export enum file_accountlistfiles_select_column_file_accountlistfiles_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * unique or primary key constraints on table "file.agendaitemfiles"
 */
export enum file_agendaitemfiles_constraint {
  PK_agendaitemfiles = "PK_agendaitemfiles",
}

/**
 * select columns of table "file.agendaitemfiles"
 */
export enum file_agendaitemfiles_select_column {
  agendaitemid = "agendaitemid",
  fileid = "fileid",
  id = "id",
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * select "file_agendaitemfiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.agendaitemfiles"
 */
export enum file_agendaitemfiles_select_column_file_agendaitemfiles_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_agendaitemfiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.agendaitemfiles"
 */
export enum file_agendaitemfiles_select_column_file_agendaitemfiles_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * update columns of table "file.agendaitemfiles"
 */
export enum file_agendaitemfiles_update_column {
  agendaitemid = "agendaitemid",
  fileid = "fileid",
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * unique or primary key constraints on table "file.assemblyfiles"
 */
export enum file_assemblyfiles_constraint {
  PK_assemblyfiles = "PK_assemblyfiles",
}

/**
 * select columns of table "file.assemblyfiles"
 */
export enum file_assemblyfiles_select_column {
  assemblyid = "assemblyid",
  fileid = "fileid",
  id = "id",
  roleid = "roleid",
  system = "system",
  type = "type",
  visible = "visible",
}

/**
 * select "file_assemblyfiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.assemblyfiles"
 */
export enum file_assemblyfiles_select_column_file_assemblyfiles_aggregate_bool_exp_bool_and_arguments_columns {
  system = "system",
  visible = "visible",
}

/**
 * select "file_assemblyfiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.assemblyfiles"
 */
export enum file_assemblyfiles_select_column_file_assemblyfiles_aggregate_bool_exp_bool_or_arguments_columns {
  system = "system",
  visible = "visible",
}

/**
 * update columns of table "file.assemblyfiles"
 */
export enum file_assemblyfiles_update_column {
  assemblyid = "assemblyid",
  fileid = "fileid",
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * select columns of table "file.bookingfiles"
 */
export enum file_bookingfiles_select_column {
  bookingid = "bookingid",
  fileid = "fileid",
  id = "id",
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * select "file_bookingfiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.bookingfiles"
 */
export enum file_bookingfiles_select_column_file_bookingfiles_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_bookingfiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.bookingfiles"
 */
export enum file_bookingfiles_select_column_file_bookingfiles_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * select columns of table "file.customerfiles"
 */
export enum file_customerfiles_select_column {
  fileid = "fileid",
  id = "id",
  roleid = "roleid",
  type = "type",
  updated = "updated",
  visible = "visible",
}

/**
 * select "file_customerfiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.customerfiles"
 */
export enum file_customerfiles_select_column_file_customerfiles_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_customerfiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.customerfiles"
 */
export enum file_customerfiles_select_column_file_customerfiles_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * select columns of table "file.dmsconnections"
 */
export enum file_dmsconnections_select_column {
  customerid = "customerid",
  dmstype = "dmstype",
  extradata = "extradata",
  hostname = "hostname",
  id = "id",
  password = "password",
  username = "username",
}

/**
 * unique or primary key constraints on table "file.files"
 */
export enum file_files_constraint {
  PK_files = "PK_files",
}

/**
 * select columns of table "file.files"
 */
export enum file_files_select_column {
  connectionid = "connectionid",
  customerid = "customerid",
  extension = "extension",
  externallink = "externallink",
  filedate = "filedate",
  hash = "hash",
  id = "id",
  mimetype = "mimetype",
  name = "name",
  origin = "origin",
  thumbnailid = "thumbnailid",
}

/**
 * update columns of table "file.files"
 */
export enum file_files_update_column {
  extension = "extension",
  externallink = "externallink",
  filedate = "filedate",
  hash = "hash",
  mimetype = "mimetype",
  name = "name",
}

/**
 * select columns of table "file.inboundfiles"
 */
export enum file_inboundfiles_select_column {
  customerid = "customerid",
  embedded = "embedded",
  fileid = "fileid",
  id = "id",
  inboundid = "inboundid",
  inserted = "inserted",
  insertuser = "insertuser",
  roleid = "roleid",
  type = "type",
  updated = "updated",
  updateuser = "updateuser",
  visible = "visible",
}

/**
 * select "file_inboundfiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.inboundfiles"
 */
export enum file_inboundfiles_select_column_file_inboundfiles_aggregate_bool_exp_bool_and_arguments_columns {
  embedded = "embedded",
  visible = "visible",
}

/**
 * select "file_inboundfiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.inboundfiles"
 */
export enum file_inboundfiles_select_column_file_inboundfiles_aggregate_bool_exp_bool_or_arguments_columns {
  embedded = "embedded",
  visible = "visible",
}

/**
 * unique or primary key constraints on table "file.incidentfiles"
 */
export enum file_incidentfiles_constraint {
  PK_incidentfiles = "PK_incidentfiles",
}

/**
 * select columns of table "file.incidentfiles"
 */
export enum file_incidentfiles_select_column {
  fileid = "fileid",
  id = "id",
  incidentid = "incidentid",
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * select "file_incidentfiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.incidentfiles"
 */
export enum file_incidentfiles_select_column_file_incidentfiles_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_incidentfiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.incidentfiles"
 */
export enum file_incidentfiles_select_column_file_incidentfiles_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * update columns of table "file.incidentfiles"
 */
export enum file_incidentfiles_update_column {
  fileid = "fileid",
  id = "id",
  incidentid = "incidentid",
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * unique or primary key constraints on table "file.invoicefiles"
 */
export enum file_invoicefiles_constraint {
  PK_invoicefiles = "PK_invoicefiles",
}

/**
 * select columns of table "file.invoicefiles"
 */
export enum file_invoicefiles_select_column {
  fileid = "fileid",
  id = "id",
  invoiceid = "invoiceid",
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * select "file_invoicefiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.invoicefiles"
 */
export enum file_invoicefiles_select_column_file_invoicefiles_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_invoicefiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.invoicefiles"
 */
export enum file_invoicefiles_select_column_file_invoicefiles_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * update columns of table "file.invoicefiles"
 */
export enum file_invoicefiles_update_column {
  fileid = "fileid",
  invoiceid = "invoiceid",
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * select columns of table "file.managementfiles"
 */
export enum file_managementfiles_select_column {
  fileid = "fileid",
  id = "id",
  managementid = "managementid",
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * select "file_managementfiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.managementfiles"
 */
export enum file_managementfiles_select_column_file_managementfiles_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_managementfiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.managementfiles"
 */
export enum file_managementfiles_select_column_file_managementfiles_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * unique or primary key constraints on table "file.newsfiles"
 */
export enum file_newsfiles_constraint {
  PK_newsfiles = "PK_newsfiles",
}

/**
 * select columns of table "file.newsfiles"
 */
export enum file_newsfiles_select_column {
  fileid = "fileid",
  id = "id",
  newsid = "newsid",
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * select "file_newsfiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.newsfiles"
 */
export enum file_newsfiles_select_column_file_newsfiles_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_newsfiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.newsfiles"
 */
export enum file_newsfiles_select_column_file_newsfiles_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * update columns of table "file.newsfiles"
 */
export enum file_newsfiles_update_column {
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * unique or primary key constraints on table "file.orderfiles"
 */
export enum file_orderfiles_constraint {
  PK_orderfiles = "PK_orderfiles",
}

/**
 * select columns of table "file.orderfiles"
 */
export enum file_orderfiles_select_column {
  fileid = "fileid",
  id = "id",
  orderid = "orderid",
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * select "file_orderfiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.orderfiles"
 */
export enum file_orderfiles_select_column_file_orderfiles_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_orderfiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.orderfiles"
 */
export enum file_orderfiles_select_column_file_orderfiles_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * update columns of table "file.orderfiles"
 */
export enum file_orderfiles_update_column {
  fileid = "fileid",
  id = "id",
  orderid = "orderid",
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * select columns of table "file.realestatefiles"
 */
export enum file_realestatefiles_select_column {
  fileid = "fileid",
  id = "id",
  realestateid = "realestateid",
  roleid = "roleid",
  type = "type",
  visible = "visible",
}

/**
 * select "file_realestatefiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.realestatefiles"
 */
export enum file_realestatefiles_select_column_file_realestatefiles_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_realestatefiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.realestatefiles"
 */
export enum file_realestatefiles_select_column_file_realestatefiles_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * select columns of table "file.tenantbookingfiles"
 */
export enum file_tenantbookingfiles_select_column {
  fileid = "fileid",
  id = "id",
  roleid = "roleid",
  tenantbookingid = "tenantbookingid",
  type = "type",
  visible = "visible",
}

/**
 * select "file_tenantbookingfiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.tenantbookingfiles"
 */
export enum file_tenantbookingfiles_select_column_file_tenantbookingfiles_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_tenantbookingfiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.tenantbookingfiles"
 */
export enum file_tenantbookingfiles_select_column_file_tenantbookingfiles_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * select columns of table "file.tenantfiles"
 */
export enum file_tenantfiles_select_column {
  fileid = "fileid",
  id = "id",
  roleid = "roleid",
  tenantid = "tenantid",
  type = "type",
  visible = "visible",
}

/**
 * select "file_tenantfiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.tenantfiles"
 */
export enum file_tenantfiles_select_column_file_tenantfiles_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_tenantfiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.tenantfiles"
 */
export enum file_tenantfiles_select_column_file_tenantfiles_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * select columns of table "file.unitfiles"
 */
export enum file_unitfiles_select_column {
  fileid = "fileid",
  id = "id",
  roleid = "roleid",
  type = "type",
  unitid = "unitid",
  visible = "visible",
}

/**
 * select "file_unitfiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.unitfiles"
 */
export enum file_unitfiles_select_column_file_unitfiles_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_unitfiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.unitfiles"
 */
export enum file_unitfiles_select_column_file_unitfiles_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * select columns of table "file.v_bookingfiles_files"
 */
export enum file_v_bookingfiles_files_select_column {
  bookingfileid = "bookingfileid",
  bookingid = "bookingid",
  customerid = "customerid",
  extension = "extension",
  externallink = "externallink",
  filedate = "filedate",
  fileid = "fileid",
  hash = "hash",
  mimetype = "mimetype",
  name = "name",
  origin = "origin",
  roleid = "roleid",
  thumbnailid = "thumbnailid",
  type = "type",
}

/**
 * select columns of table "file.v_coowner_files_overview"
 */
export enum file_v_coowner_files_overview_select_column {
  category = "category",
  categorylabel = "categorylabel",
  customerid = "customerid",
  extension = "extension",
  externallink = "externallink",
  filedate = "filedate",
  fileid = "fileid",
  id = "id",
  language = "language",
  mimetype = "mimetype",
  name = "name",
  origin = "origin",
  realestateid = "realestateid",
  roleid = "roleid",
  thumbnailid = "thumbnailid",
  userid = "userid",
  visible = "visible",
}

/**
 * select "file_v_coowner_files_overview_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.v_coowner_files_overview"
 */
export enum file_v_coowner_files_overview_select_column_file_v_coowner_files_overview_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_v_coowner_files_overview_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.v_coowner_files_overview"
 */
export enum file_v_coowner_files_overview_select_column_file_v_coowner_files_overview_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * select columns of table "file.v_files_overview_coowner"
 */
export enum file_v_files_overview_coowner_select_column {
  category = "category",
  categorylabel = "categorylabel",
  customerid = "customerid",
  extension = "extension",
  externallink = "externallink",
  filedate = "filedate",
  fileid = "fileid",
  id = "id",
  language = "language",
  mimetype = "mimetype",
  name = "name",
  origin = "origin",
  realestateid = "realestateid",
  roleid = "roleid",
  thumbnailid = "thumbnailid",
  type = "type",
  userid = "userid",
  visible = "visible",
}

/**
 * select "file_v_files_overview_coowner_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.v_files_overview_coowner"
 */
export enum file_v_files_overview_coowner_select_column_file_v_files_overview_coowner_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_v_files_overview_coowner_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.v_files_overview_coowner"
 */
export enum file_v_files_overview_coowner_select_column_file_v_files_overview_coowner_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * select columns of table "file.v_files_overview_manager"
 */
export enum file_v_files_overview_manager_select_column {
  category = "category",
  categorylabel = "categorylabel",
  customerid = "customerid",
  extension = "extension",
  externallink = "externallink",
  filedate = "filedate",
  fileid = "fileid",
  id = "id",
  language = "language",
  mimetype = "mimetype",
  name = "name",
  origin = "origin",
  realestateid = "realestateid",
  roleid = "roleid",
  thumbnailid = "thumbnailid",
  type = "type",
  userid = "userid",
  visible = "visible",
}

/**
 * select "file_v_files_overview_manager_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.v_files_overview_manager"
 */
export enum file_v_files_overview_manager_select_column_file_v_files_overview_manager_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_v_files_overview_manager_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.v_files_overview_manager"
 */
export enum file_v_files_overview_manager_select_column_file_v_files_overview_manager_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * select columns of table "file.v_files_overview_owner"
 */
export enum file_v_files_overview_owner_select_column {
  category = "category",
  categorylabel = "categorylabel",
  customerid = "customerid",
  extension = "extension",
  externallink = "externallink",
  filedate = "filedate",
  fileid = "fileid",
  id = "id",
  language = "language",
  mimetype = "mimetype",
  name = "name",
  origin = "origin",
  realestateid = "realestateid",
  roleid = "roleid",
  thumbnailid = "thumbnailid",
  type = "type",
  userid = "userid",
  visible = "visible",
}

/**
 * select "file_v_files_overview_owner_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.v_files_overview_owner"
 */
export enum file_v_files_overview_owner_select_column_file_v_files_overview_owner_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_v_files_overview_owner_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.v_files_overview_owner"
 */
export enum file_v_files_overview_owner_select_column_file_v_files_overview_owner_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * select columns of table "file.v_manager_files_overview"
 */
export enum file_v_manager_files_overview_select_column {
  category = "category",
  categorylabel = "categorylabel",
  customerid = "customerid",
  extension = "extension",
  externallink = "externallink",
  filedate = "filedate",
  fileid = "fileid",
  id = "id",
  language = "language",
  mimetype = "mimetype",
  name = "name",
  origin = "origin",
  realestateid = "realestateid",
  roleid = "roleid",
  thumbnailid = "thumbnailid",
  userid = "userid",
  visible = "visible",
}

/**
 * select "file_v_manager_files_overview_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.v_manager_files_overview"
 */
export enum file_v_manager_files_overview_select_column_file_v_manager_files_overview_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_v_manager_files_overview_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.v_manager_files_overview"
 */
export enum file_v_manager_files_overview_select_column_file_v_manager_files_overview_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * select columns of table "file.v_owner_files_overview"
 */
export enum file_v_owner_files_overview_select_column {
  category = "category",
  categorylabel = "categorylabel",
  customerid = "customerid",
  extension = "extension",
  externallink = "externallink",
  filedate = "filedate",
  fileid = "fileid",
  id = "id",
  language = "language",
  mimetype = "mimetype",
  name = "name",
  origin = "origin",
  realestateid = "realestateid",
  roleid = "roleid",
  thumbnailid = "thumbnailid",
  userid = "userid",
  visible = "visible",
}

/**
 * select "file_v_owner_files_overview_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.v_owner_files_overview"
 */
export enum file_v_owner_files_overview_select_column_file_v_owner_files_overview_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_v_owner_files_overview_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.v_owner_files_overview"
 */
export enum file_v_owner_files_overview_select_column_file_v_owner_files_overview_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * select columns of table "file.v_realestatefiles_files"
 */
export enum file_v_realestatefiles_files_select_column {
  customerid = "customerid",
  extension = "extension",
  externallink = "externallink",
  filedate = "filedate",
  fileid = "fileid",
  hash = "hash",
  mimetype = "mimetype",
  name = "name",
  origin = "origin",
  realestatefileid = "realestatefileid",
  realestateid = "realestateid",
  roleid = "roleid",
  thumbnailid = "thumbnailid",
  type = "type",
}

/**
 * select columns of table "file.v_tenant_files_overview"
 */
export enum file_v_tenant_files_overview_select_column {
  category = "category",
  categorylabel = "categorylabel",
  customerid = "customerid",
  extension = "extension",
  externallink = "externallink",
  filedate = "filedate",
  fileid = "fileid",
  id = "id",
  language = "language",
  mimetype = "mimetype",
  name = "name",
  origin = "origin",
  realestateid = "realestateid",
  roleid = "roleid",
  thumbnailid = "thumbnailid",
  userid = "userid",
  visible = "visible",
}

/**
 * select "file_v_tenant_files_overview_aggregate_bool_exp_bool_and_arguments_columns" columns of table "file.v_tenant_files_overview"
 */
export enum file_v_tenant_files_overview_select_column_file_v_tenant_files_overview_aggregate_bool_exp_bool_and_arguments_columns {
  visible = "visible",
}

/**
 * select "file_v_tenant_files_overview_aggregate_bool_exp_bool_or_arguments_columns" columns of table "file.v_tenant_files_overview"
 */
export enum file_v_tenant_files_overview_select_column_file_v_tenant_files_overview_aggregate_bool_exp_bool_or_arguments_columns {
  visible = "visible",
}

/**
 * select columns of table "mail.emaillinks"
 */
export enum mail_emaillinks_select_column {
  emailid = "emailid",
  foreignlinkentity = "foreignlinkentity",
  foreignlinkid = "foreignlinkid",
  id = "id",
}

/**
 * select columns of table "mail.emaillogs"
 */
export enum mail_emaillogs_select_column {
  deliveryattempts = "deliveryattempts",
  emailrecipientid = "emailrecipientid",
  event = "event",
  id = "id",
  message = "message",
  messageid = "messageid",
  timestamp = "timestamp",
  type = "type",
}

/**
 * select columns of table "mail.emailprofiles"
 */
export enum mail_emailprofiles_select_column {
  authenticationtype = "authenticationtype",
  customerid = "customerid",
  displayname = "displayname",
  emailaddress = "emailaddress",
  hostname = "hostname",
  id = "id",
  password = "password",
  port = "port",
  replyemailaddress = "replyemailaddress",
  tcpsecurityoptions = "tcpsecurityoptions",
  userid = "userid",
  username = "username",
}

/**
 * select columns of table "mail.emailrecipients"
 */
export enum mail_emailrecipients_select_column {
  emailaddress = "emailaddress",
  emaildisplayname = "emaildisplayname",
  emailid = "emailid",
  id = "id",
  type = "type",
}

/**
 * select columns of table "mail.emails"
 */
export enum mail_emails_select_column {
  body = "body",
  bodyformat = "bodyformat",
  emailtype = "emailtype",
  from = "from",
  id = "id",
  inserted = "inserted",
  state = "state",
  subject = "subject",
  trackingid = "trackingid",
  userid = "userid",
}

/**
 * unique or primary key constraints on table "news.news"
 */
export enum news_news_constraint {
  PK_news = "PK_news",
}

/**
 * unique or primary key constraints on table "news.news_mls"
 */
export enum news_news_mls_constraint {
  PK_news_mls = "PK_news_mls",
}

/**
 * select columns of table "news.news_mls"
 */
export enum news_news_mls_select_column {
  id = "id",
  language = "language",
  lead = "lead",
  newsid = "newsid",
  text = "text",
  title = "title",
}

/**
 * update columns of table "news.news_mls"
 */
export enum news_news_mls_update_column {
  language = "language",
  lead = "lead",
  text = "text",
  title = "title",
}

/**
 * select columns of table "news.news"
 */
export enum news_news_select_column {
  author = "author",
  id = "id",
  inserted = "inserted",
  state = "state",
  timestamp = "timestamp",
}

/**
 * update columns of table "news.news"
 */
export enum news_news_update_column {
  author = "author",
  state = "state",
  timestamp = "timestamp",
}

/**
 * unique or primary key constraints on table "news.newsrealestates"
 */
export enum news_newsrealestates_constraint {
  PK_newsrealestates = "PK_newsrealestates",
}

/**
 * select columns of table "news.newsrealestates"
 */
export enum news_newsrealestates_select_column {
  id = "id",
  newsid = "newsid",
  realestateid = "realestateid",
}

/**
 * update columns of table "news.newsrealestates"
 */
export enum news_newsrealestates_update_column {
  newsid = "newsid",
  realestateid = "realestateid",
}

/**
 * select columns of table "news.newsuseraudits"
 */
export enum news_newsuseraudits_select_column {
  event = "event",
  id = "id",
  newsid = "newsid",
  timestamp = "timestamp",
  userid = "userid",
}

/**
 * unique or primary key constraints on table "news.newsusers"
 */
export enum news_newsusers_constraint {
  PK_newsusers = "PK_newsusers",
}

/**
 * select columns of table "news.newsusers"
 */
export enum news_newsusers_select_column {
  id = "id",
  newsid = "newsid",
  userid = "userid",
}

/**
 * placeholder for update columns of table "news.newsusers" (current role has no relevant permissions)
 */
export enum news_newsusers_update_column {
  _PLACEHOLDER = "_PLACEHOLDER",
}

/**
 * unique or primary key constraints on table "noti.assemblyevents"
 */
export enum noti_assemblyevents_constraint {
  PK_assemblyevents = "PK_assemblyevents",
}

/**
 * select columns of table "noti.assemblyevents"
 */
export enum noti_assemblyevents_select_column {
  assemblyid = "assemblyid",
  eventid = "eventid",
  id = "id",
  inserted = "inserted",
}

/**
 * update columns of table "noti.assemblyevents"
 */
export enum noti_assemblyevents_update_column {
  assemblyid = "assemblyid",
  eventid = "eventid",
}

/**
 * unique or primary key constraints on table "noti.eventaudits"
 */
export enum noti_eventaudits_constraint {
  PK_eventaudits = "PK_eventaudits",
}

/**
 * select columns of table "noti.eventaudits"
 */
export enum noti_eventaudits_select_column {
  event = "event",
  eventid = "eventid",
  id = "id",
  timestamp = "timestamp",
}

/**
 * placeholder for update columns of table "noti.eventaudits" (current role has no relevant permissions)
 */
export enum noti_eventaudits_update_column {
  _PLACEHOLDER = "_PLACEHOLDER",
}

/**
 * unique or primary key constraints on table "noti.events"
 */
export enum noti_events_constraint {
  PK_events = "PK_events",
}

/**
 * update columns of table "noti.events"
 */
export enum noti_events_update_column {
  state = "state",
}

/**
 * unique or primary key constraints on table "noti.fileevents"
 */
export enum noti_fileevents_constraint {
  PK_fileevents = "PK_fileevents",
}

/**
 * select columns of table "noti.fileevents"
 */
export enum noti_fileevents_select_column {
  eventid = "eventid",
  fileid = "fileid",
  id = "id",
}

/**
 * update columns of table "noti.fileevents"
 */
export enum noti_fileevents_update_column {
  eventid = "eventid",
  fileid = "fileid",
}

/**
 * unique or primary key constraints on table "noti.newsevents"
 */
export enum noti_newsevents_constraint {
  PK_newsevents = "PK_newsevents",
}

/**
 * select columns of table "noti.newsevents"
 */
export enum noti_newsevents_select_column {
  eventid = "eventid",
  id = "id",
  inserted = "inserted",
  newsid = "newsid",
}

/**
 * update columns of table "noti.newsevents"
 */
export enum noti_newsevents_update_column {
  eventid = "eventid",
  newsid = "newsid",
}

/**
 * select columns of table "portal.accountlists"
 */
export enum portal_accountlists_select_column {
  enddate = "enddate",
  id = "id",
  realestateid = "realestateid",
  sortdate = "sortdate",
  startdate = "startdate",
  state = "state",
  subtitle = "subtitle",
  syncdate = "syncdate",
  title = "title",
  type = "type",
}

/**
 * select columns of table "portal.accounts"
 */
export enum portal_accounts_select_column {
  accountlistid = "accountlistid",
  credit = "credit",
  debit = "debit",
  id = "id",
  number = "number",
  parentaccountid = "parentaccountid",
  secondarysort = "secondarysort",
  sort = "sort",
  title = "title",
  type = "type",
}

/**
 * select "portal_accounts_aggregate_bool_exp_avg_arguments_columns" columns of table "portal.accounts"
 */
export enum portal_accounts_select_column_portal_accounts_aggregate_bool_exp_avg_arguments_columns {
  credit = "credit",
  debit = "debit",
}

/**
 * select "portal_accounts_aggregate_bool_exp_corr_arguments_columns" columns of table "portal.accounts"
 */
export enum portal_accounts_select_column_portal_accounts_aggregate_bool_exp_corr_arguments_columns {
  credit = "credit",
  debit = "debit",
}

/**
 * select "portal_accounts_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "portal.accounts"
 */
export enum portal_accounts_select_column_portal_accounts_aggregate_bool_exp_covar_samp_arguments_columns {
  credit = "credit",
  debit = "debit",
}

/**
 * select "portal_accounts_aggregate_bool_exp_max_arguments_columns" columns of table "portal.accounts"
 */
export enum portal_accounts_select_column_portal_accounts_aggregate_bool_exp_max_arguments_columns {
  credit = "credit",
  debit = "debit",
}

/**
 * select "portal_accounts_aggregate_bool_exp_min_arguments_columns" columns of table "portal.accounts"
 */
export enum portal_accounts_select_column_portal_accounts_aggregate_bool_exp_min_arguments_columns {
  credit = "credit",
  debit = "debit",
}

/**
 * select "portal_accounts_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "portal.accounts"
 */
export enum portal_accounts_select_column_portal_accounts_aggregate_bool_exp_stddev_samp_arguments_columns {
  credit = "credit",
  debit = "debit",
}

/**
 * select "portal_accounts_aggregate_bool_exp_sum_arguments_columns" columns of table "portal.accounts"
 */
export enum portal_accounts_select_column_portal_accounts_aggregate_bool_exp_sum_arguments_columns {
  credit = "credit",
  debit = "debit",
}

/**
 * select "portal_accounts_aggregate_bool_exp_var_samp_arguments_columns" columns of table "portal.accounts"
 */
export enum portal_accounts_select_column_portal_accounts_aggregate_bool_exp_var_samp_arguments_columns {
  credit = "credit",
  debit = "debit",
}

/**
 * select columns of table "portal.bookings"
 */
export enum portal_bookings_select_column {
  accountid = "accountid",
  cancellation = "cancellation",
  contraaccountid = "contraaccountid",
  credit = "credit",
  date = "date",
  debit = "debit",
  documentnumber = "documentnumber",
  id = "id",
  quantity = "quantity",
  text = "text",
}

/**
 * select "portal_bookings_aggregate_bool_exp_avg_arguments_columns" columns of table "portal.bookings"
 */
export enum portal_bookings_select_column_portal_bookings_aggregate_bool_exp_avg_arguments_columns {
  credit = "credit",
  debit = "debit",
  quantity = "quantity",
}

/**
 * select "portal_bookings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "portal.bookings"
 */
export enum portal_bookings_select_column_portal_bookings_aggregate_bool_exp_bool_and_arguments_columns {
  cancellation = "cancellation",
}

/**
 * select "portal_bookings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "portal.bookings"
 */
export enum portal_bookings_select_column_portal_bookings_aggregate_bool_exp_bool_or_arguments_columns {
  cancellation = "cancellation",
}

/**
 * select "portal_bookings_aggregate_bool_exp_corr_arguments_columns" columns of table "portal.bookings"
 */
export enum portal_bookings_select_column_portal_bookings_aggregate_bool_exp_corr_arguments_columns {
  credit = "credit",
  debit = "debit",
  quantity = "quantity",
}

/**
 * select "portal_bookings_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "portal.bookings"
 */
export enum portal_bookings_select_column_portal_bookings_aggregate_bool_exp_covar_samp_arguments_columns {
  credit = "credit",
  debit = "debit",
  quantity = "quantity",
}

/**
 * select "portal_bookings_aggregate_bool_exp_max_arguments_columns" columns of table "portal.bookings"
 */
export enum portal_bookings_select_column_portal_bookings_aggregate_bool_exp_max_arguments_columns {
  credit = "credit",
  debit = "debit",
  quantity = "quantity",
}

/**
 * select "portal_bookings_aggregate_bool_exp_min_arguments_columns" columns of table "portal.bookings"
 */
export enum portal_bookings_select_column_portal_bookings_aggregate_bool_exp_min_arguments_columns {
  credit = "credit",
  debit = "debit",
  quantity = "quantity",
}

/**
 * select "portal_bookings_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "portal.bookings"
 */
export enum portal_bookings_select_column_portal_bookings_aggregate_bool_exp_stddev_samp_arguments_columns {
  credit = "credit",
  debit = "debit",
  quantity = "quantity",
}

/**
 * select "portal_bookings_aggregate_bool_exp_sum_arguments_columns" columns of table "portal.bookings"
 */
export enum portal_bookings_select_column_portal_bookings_aggregate_bool_exp_sum_arguments_columns {
  credit = "credit",
  debit = "debit",
  quantity = "quantity",
}

/**
 * select "portal_bookings_aggregate_bool_exp_var_samp_arguments_columns" columns of table "portal.bookings"
 */
export enum portal_bookings_select_column_portal_bookings_aggregate_bool_exp_var_samp_arguments_columns {
  credit = "credit",
  debit = "debit",
  quantity = "quantity",
}

/**
 * select columns of table "portal.tiledatasets_mls"
 */
export enum portal_tiledatasets_mls_select_column {
  id = "id",
  label = "label",
  language = "language",
  subtitle = "subtitle",
  tiledatasetid = "tiledatasetid",
}

/**
 * select columns of table "portal.tiledatasets"
 */
export enum portal_tiledatasets_select_column {
  id = "id",
  sort = "sort",
  tileid = "tileid",
}

/**
 * select columns of table "portal.tiledatavalues_mls"
 */
export enum portal_tiledatavalues_mls_select_column {
  grouptitle = "grouptitle",
  hint = "hint",
  id = "id",
  label = "label",
  language = "language",
  tiledatavalueid = "tiledatavalueid",
}

/**
 * select columns of table "portal.tiledatavalues"
 */
export enum portal_tiledatavalues_select_column {
  group = "group",
  id = "id",
  sort = "sort",
  tiledatasetid = "tiledatasetid",
  value = "value",
  valuetype = "valuetype",
}

/**
 * select "portal_tiledatavalues_aggregate_bool_exp_avg_arguments_columns" columns of table "portal.tiledatavalues"
 */
export enum portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_avg_arguments_columns {
  value = "value",
}

/**
 * select "portal_tiledatavalues_aggregate_bool_exp_corr_arguments_columns" columns of table "portal.tiledatavalues"
 */
export enum portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_corr_arguments_columns {
  value = "value",
}

/**
 * select "portal_tiledatavalues_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "portal.tiledatavalues"
 */
export enum portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_covar_samp_arguments_columns {
  value = "value",
}

/**
 * select "portal_tiledatavalues_aggregate_bool_exp_max_arguments_columns" columns of table "portal.tiledatavalues"
 */
export enum portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_max_arguments_columns {
  value = "value",
}

/**
 * select "portal_tiledatavalues_aggregate_bool_exp_min_arguments_columns" columns of table "portal.tiledatavalues"
 */
export enum portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_min_arguments_columns {
  value = "value",
}

/**
 * select "portal_tiledatavalues_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "portal.tiledatavalues"
 */
export enum portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_stddev_samp_arguments_columns {
  value = "value",
}

/**
 * select "portal_tiledatavalues_aggregate_bool_exp_sum_arguments_columns" columns of table "portal.tiledatavalues"
 */
export enum portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_sum_arguments_columns {
  value = "value",
}

/**
 * select "portal_tiledatavalues_aggregate_bool_exp_var_samp_arguments_columns" columns of table "portal.tiledatavalues"
 */
export enum portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_var_samp_arguments_columns {
  value = "value",
}

/**
 * select columns of table "portal.tiles_mls"
 */
export enum portal_tiles_mls_select_column {
  id = "id",
  language = "language",
  tileid = "tileid",
  title = "title",
}

/**
 * select columns of table "portal.tiles"
 */
export enum portal_tiles_select_column {
  charttype = "charttype",
  customerid = "customerid",
  id = "id",
  realestateid = "realestateid",
  tiletype = "tiletype",
}

/**
 * select columns of table "portal.tilescreens"
 */
export enum portal_tilescreens_select_column {
  id = "id",
  screen = "screen",
  sort = "sort",
  tileid = "tileid",
}

/**
 * select columns of table "portal.v_tenancys_today"
 */
export enum portal_v_tenancys_today_select_column {
  accountbalance = "accountbalance",
  conditionid = "conditionid",
  consumerpriceindex = "consumerpriceindex",
  costincreaseindex = "costincreaseindex",
  customerid = "customerid",
  flatratefee = "flatratefee",
  floor = "floor",
  grossrent = "grossrent",
  houseid = "houseid",
  interestrateindex = "interestrateindex",
  leaseend = "leaseend",
  leasestart = "leasestart",
  maturities = "maturities",
  minimumrentdate = "minimumrentdate",
  name = "name",
  netrent = "netrent",
  noticemonths = "noticemonths",
  noticeperiodowner = "noticeperiodowner",
  noticeperiodtenant = "noticeperiodtenant",
  number = "number",
  ownerid = "ownerid",
  paymentonaccount = "paymentonaccount",
  rentincreasereserveamount = "rentincreasereserveamount",
  rentincreasereservetext = "rentincreasereservetext",
  rentsqmyear = "rentsqmyear",
  rolename = "rolename",
  rooms = "rooms",
  space = "space",
  tenancyid = "tenancyid",
  tenancytype = "tenancytype",
  tenantid = "tenantid",
  tenantname = "tenantname",
  tenantpersonid = "tenantpersonid",
  unitid = "unitid",
  unittype = "unittype",
  userid = "userid",
  vacancy = "vacancy",
  validfrom = "validfrom",
  vat = "vat",
  vattaxed = "vattaxed",
}

/**
 * select "portal_v_tenancys_today_aggregate_bool_exp_avg_arguments_columns" columns of table "portal.v_tenancys_today"
 */
export enum portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_avg_arguments_columns {
  interestrateindex = "interestrateindex",
  rooms = "rooms",
  space = "space",
}

/**
 * select "portal_v_tenancys_today_aggregate_bool_exp_bool_and_arguments_columns" columns of table "portal.v_tenancys_today"
 */
export enum portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_bool_and_arguments_columns {
  vacancy = "vacancy",
  vattaxed = "vattaxed",
}

/**
 * select "portal_v_tenancys_today_aggregate_bool_exp_bool_or_arguments_columns" columns of table "portal.v_tenancys_today"
 */
export enum portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_bool_or_arguments_columns {
  vacancy = "vacancy",
  vattaxed = "vattaxed",
}

/**
 * select "portal_v_tenancys_today_aggregate_bool_exp_corr_arguments_columns" columns of table "portal.v_tenancys_today"
 */
export enum portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_corr_arguments_columns {
  interestrateindex = "interestrateindex",
  rooms = "rooms",
  space = "space",
}

/**
 * select "portal_v_tenancys_today_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "portal.v_tenancys_today"
 */
export enum portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_covar_samp_arguments_columns {
  interestrateindex = "interestrateindex",
  rooms = "rooms",
  space = "space",
}

/**
 * select "portal_v_tenancys_today_aggregate_bool_exp_max_arguments_columns" columns of table "portal.v_tenancys_today"
 */
export enum portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_max_arguments_columns {
  interestrateindex = "interestrateindex",
  rooms = "rooms",
  space = "space",
}

/**
 * select "portal_v_tenancys_today_aggregate_bool_exp_min_arguments_columns" columns of table "portal.v_tenancys_today"
 */
export enum portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_min_arguments_columns {
  interestrateindex = "interestrateindex",
  rooms = "rooms",
  space = "space",
}

/**
 * select "portal_v_tenancys_today_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "portal.v_tenancys_today"
 */
export enum portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_stddev_samp_arguments_columns {
  interestrateindex = "interestrateindex",
  rooms = "rooms",
  space = "space",
}

/**
 * select "portal_v_tenancys_today_aggregate_bool_exp_sum_arguments_columns" columns of table "portal.v_tenancys_today"
 */
export enum portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_sum_arguments_columns {
  interestrateindex = "interestrateindex",
  rooms = "rooms",
  space = "space",
}

/**
 * select "portal_v_tenancys_today_aggregate_bool_exp_var_samp_arguments_columns" columns of table "portal.v_tenancys_today"
 */
export enum portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_var_samp_arguments_columns {
  interestrateindex = "interestrateindex",
  rooms = "rooms",
  space = "space",
}

/**
 * select columns of table "rent.rentalinvoices"
 */
export enum rent_rentalinvoices_select_column {
  date = "date",
  id = "id",
  rentalid = "rentalid",
}

/**
 * select columns of table "rent.rentals"
 */
export enum rent_rentals_select_column {
  completiondate = "completiondate",
  id = "id",
  in_tenancyid = "in_tenancyid",
  out_tenancyid = "out_tenancyid",
  realestateid = "realestateid",
  rentaldate = "rentaldate",
  rentalplatform = "rentalplatform",
  state = "state",
  unitid = "unitid",
}

/**
 * unique or primary key constraints on table "ticket.incidentcomments"
 */
export enum ticket_incidentcomments_constraint {
  PK_incidentcomments = "PK_incidentcomments",
}

/**
 * select columns of table "ticket.incidentcomments"
 */
export enum ticket_incidentcomments_select_column {
  customerid = "customerid",
  id = "id",
  incidentid = "incidentid",
  inserted = "inserted",
  language = "language",
  text = "text",
  userid = "userid",
}

/**
 * update columns of table "ticket.incidentcomments"
 */
export enum ticket_incidentcomments_update_column {
  incidentid = "incidentid",
  language = "language",
  text = "text",
  userid = "userid",
}

/**
 * unique or primary key constraints on table "ticket.incidentpersons"
 */
export enum ticket_incidentpersons_constraint {
  PK_incidentpersons = "PK_incidentpersons",
}

/**
 * select columns of table "ticket.incidentpersons"
 */
export enum ticket_incidentpersons_select_column {
  customerid = "customerid",
  id = "id",
  incidentid = "incidentid",
  personid = "personid",
  role = "role",
}

/**
 * update columns of table "ticket.incidentpersons"
 */
export enum ticket_incidentpersons_update_column {
  incidentid = "incidentid",
  personid = "personid",
  role = "role",
}

/**
 * unique or primary key constraints on table "ticket.incidents"
 */
export enum ticket_incidents_constraint {
  PK_incidents = "PK_incidents",
  UNIQUE_number_indidents = "UNIQUE_number_indidents",
}

/**
 * unique or primary key constraints on table "ticket.incidents_mls"
 */
export enum ticket_incidents_mls_constraint {
  PK_incidents_mls = "PK_incidents_mls",
}

/**
 * select columns of table "ticket.incidents_mls"
 */
export enum ticket_incidents_mls_select_column {
  altcomponent = "altcomponent",
  customerid = "customerid",
  description = "description",
  id = "id",
  incidentid = "incidentid",
  language = "language",
  title = "title",
}

/**
 * update columns of table "ticket.incidents_mls"
 */
export enum ticket_incidents_mls_update_column {
  altcomponent = "altcomponent",
  description = "description",
  incidentid = "incidentid",
  language = "language",
  title = "title",
}

/**
 * update columns of table "ticket.incidents"
 */
export enum ticket_incidents_update_column {
  applianceid = "applianceid",
  appliancetype = "appliancetype",
  assigneduserid = "assigneduserid",
  componentid = "componentid",
  date = "date",
  houseid = "houseid",
  key = "key",
  manufacturer = "manufacturer",
  nameplate = "nameplate",
  notificationaddress = "notificationaddress",
  notificationid = "notificationid",
  number = "number",
  origin = "origin",
  realestateid = "realestateid",
  roomtype = "roomtype",
  serialnumber = "serialnumber",
  state = "state",
  tenancyid = "tenancyid",
  tenantid = "tenantid",
  type = "type",
  unitid = "unitid",
  workflowinstance = "workflowinstance",
}

/**
 * unique or primary key constraints on table "ticket.ordercomments"
 */
export enum ticket_ordercomments_constraint {
  PK_ordercomments = "PK_ordercomments",
}

/**
 * select columns of table "ticket.ordercomments"
 */
export enum ticket_ordercomments_select_column {
  customerid = "customerid",
  id = "id",
  inserted = "inserted",
  language = "language",
  orderid = "orderid",
  text = "text",
  userid = "userid",
}

/**
 * update columns of table "ticket.ordercomments"
 */
export enum ticket_ordercomments_update_column {
  customerid = "customerid",
  language = "language",
  orderid = "orderid",
  text = "text",
  userid = "userid",
}

/**
 * unique or primary key constraints on table "ticket.orders"
 */
export enum ticket_orders_constraint {
  PK_orders = "PK_orders",
  UNIQUE_number = "UNIQUE_number",
  incidents_customer_number_unique = "incidents_customer_number_unique",
  order_customer_number_unique = "order_customer_number_unique",
}

/**
 * unique or primary key constraints on table "ticket.orders_mls"
 */
export enum ticket_orders_mls_constraint {
  PK_orders_mls = "PK_orders_mls",
}

/**
 * select columns of table "ticket.orders_mls"
 */
export enum ticket_orders_mls_select_column {
  customerid = "customerid",
  id = "id",
  instruction = "instruction",
  language = "language",
  orderid = "orderid",
  title = "title",
}

/**
 * update columns of table "ticket.orders_mls"
 */
export enum ticket_orders_mls_update_column {
  instruction = "instruction",
  language = "language",
  orderid = "orderid",
  title = "title",
}

/**
 * select columns of table "ticket.orders"
 */
export enum ticket_orders_select_column {
  billingaddress = "billingaddress",
  billingemail = "billingemail",
  contractorid = "contractorid",
  contractortype = "contractortype",
  craft = "craft",
  customerid = "customerid",
  date = "date",
  id = "id",
  incidentid = "incidentid",
  number = "number",
  primarycontact = "primarycontact",
  primarycontacttype = "primarycontacttype",
  state = "state",
  workflowinstance = "workflowinstance",
}

/**
 * update columns of table "ticket.orders"
 */
export enum ticket_orders_update_column {
  billingaddress = "billingaddress",
  billingemail = "billingemail",
  contractorid = "contractorid",
  contractortype = "contractortype",
  craft = "craft",
  date = "date",
  number = "number",
  primarycontact = "primarycontact",
  primarycontacttype = "primarycontacttype",
  state = "state",
}

/**
 * select columns of table "ticket.v_incident_history"
 */
export enum ticket_v_incident_history_select_column {
  comment_language = "comment_language",
  comment_text = "comment_text",
  comment_userid = "comment_userid",
  comment_username = "comment_username",
  customerid = "customerid",
  email_body = "email_body",
  email_recipients = "email_recipients",
  email_sender = "email_sender",
  email_subject = "email_subject",
  email_type = "email_type",
  entityid = "entityid",
  history_user = "history_user",
  history_username = "history_username",
  incidentid = "incidentid",
  inserted = "inserted",
  new_values = "new_values",
  old_values = "old_values",
  operation = "operation",
  orderid = "orderid",
  source = "source",
  tablename = "tablename",
  type = "type",
}

/**
 * select columns of table "ums.audits"
 */
export enum ums_audits_select_column {
  clientid = "clientid",
  event = "event",
  reason = "reason",
  timestamp = "timestamp",
  userid = "userid",
}

/**
 * select columns of table "ums.connections"
 */
export enum ums_connections_select_column {
  category = "category",
  customerid = "customerid",
  extradata = "extradata",
  hostname = "hostname",
  id = "id",
  password = "password",
  type = "type",
  username = "username",
}

/**
 * select columns of table "ums.customerfeatures"
 */
export enum ums_customerfeatures_select_column {
  feature = "feature",
}

/**
 * select columns of table "ums.customerinvoiceusers"
 */
export enum ums_customerinvoiceusers_select_column {
  customerinvoiceid = "customerinvoiceid",
  email = "email",
  id = "id",
  name1 = "name1",
  name2 = "name2",
  realestates = "realestates",
  registrationcode = "registrationcode",
  roles = "roles",
  updateuser = "updateuser",
  userid = "userid",
}

/**
 * select columns of table "ums.customeroptions"
 */
export enum ums_customeroptions_select_column {
  id = "id",
  option = "option",
  value = "value",
}

/**
 * select columns of table "ums.customersettings"
 */
export enum ums_customersettings_select_column {
  default = "default",
  id = "id",
  key = "key",
  value = "value",
  valuetype = "valuetype",
}

/**
 * select columns of table "ums.demoportalsettings"
 */
export enum ums_demoportalsettings_select_column {
  contactperson = "contactperson",
  email = "email",
  id = "id",
  language = "language",
  mobile = "mobile",
  onlinecontactemail = "onlinecontactemail",
  phone = "phone",
  text1 = "text1",
  text1default = "text1default",
  text2 = "text2",
  text2default = "text2default",
}

/**
 * select columns of table "ums.userroles"
 */
export enum ums_userroles_select_column {
  customerid = "customerid",
  id = "id",
  inserted = "inserted",
  insertuser = "insertuser",
  referenceid = "referenceid",
  role = "role",
  system = "system",
  updated = "updated",
  updateuser = "updateuser",
  userid = "userid",
}

/**
 * select "ums_userroles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ums.userroles"
 */
export enum ums_userroles_select_column_ums_userroles_aggregate_bool_exp_bool_and_arguments_columns {
  system = "system",
}

/**
 * select "ums_userroles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ums.userroles"
 */
export enum ums_userroles_select_column_ums_userroles_aggregate_bool_exp_bool_or_arguments_columns {
  system = "system",
}

/**
 * select columns of table "ums.users"
 */
export enum ums_users_select_column {
  activated = "activated",
  activationemailcounter = "activationemailcounter",
  cidaasid = "cidaasid",
  cidaasstate = "cidaasstate",
  customerid = "customerid",
  email = "email",
  emailverified = "emailverified",
  hash = "hash",
  id = "id",
  inserted = "inserted",
  insertuser = "insertuser",
  name1 = "name1",
  name2 = "name2",
  ratingdate = "ratingdate",
  ratingdateportal = "ratingdateportal",
  ratingstate = "ratingstate",
  ratingstateportal = "ratingstateportal",
  rccreatedat = "rccreatedat",
  registered = "registered",
  registereddate = "registereddate",
  registrationcode = "registrationcode",
  state = "state",
  system = "system",
  updated = "updated",
  updateuser = "updateuser",
}

/**
 * select "ums_users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ums.users"
 */
export enum ums_users_select_column_ums_users_aggregate_bool_exp_bool_and_arguments_columns {
  activated = "activated",
  emailverified = "emailverified",
  registered = "registered",
  system = "system",
}

/**
 * select "ums_users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ums.users"
 */
export enum ums_users_select_column_ums_users_aggregate_bool_exp_bool_or_arguments_columns {
  activated = "activated",
  emailverified = "emailverified",
  registered = "registered",
  system = "system",
}

/**
 * select columns of table "ums.usersettings"
 */
export enum ums_usersettings_select_column {
  default = "default",
  id = "id",
  key = "key",
  userid = "userid",
  value = "value",
  valuetype = "valuetype",
}

/**
 * select columns of table "ums.v_users_email"
 */
export enum ums_v_users_email_select_column {
  customerid = "customerid",
  email = "email",
  registeredat = "registeredat",
  userid = "userid",
}

/**
 * Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'.
 */
export interface Boolean_comparison_exp {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: boolean[] | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: boolean[] | null;
}

/**
 * Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'.
 */
export interface Int_comparison_exp {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

/**
 * Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _iregex?: string | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: string[] | null;
  _niregex?: string | null;
  _nlike?: string | null;
  _nregex?: string | null;
  _nsimilar?: string | null;
  _regex?: string | null;
  _similar?: string | null;
}

/**
 * Boolean expression to filter rows from the table "common.addresses". All fields are combined with a logical 'AND'.
 */
export interface common_addresses_bool_exp {
  _and?: common_addresses_bool_exp[] | null;
  _not?: common_addresses_bool_exp | null;
  _or?: common_addresses_bool_exp[] | null;
  city?: String_comparison_exp | null;
  country?: String_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  housenumber?: String_comparison_exp | null;
  person?: common_persons_bool_exp | null;
  street?: String_comparison_exp | null;
  validfrom?: timestamp_comparison_exp | null;
  zip?: String_comparison_exp | null;
}

export interface common_appliances_aggregate_bool_exp {
  count?: common_appliances_aggregate_bool_exp_count | null;
}

export interface common_appliances_aggregate_bool_exp_count {
  arguments?: common_appliances_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_appliances_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.appliances". All fields are combined with a logical 'AND'.
 */
export interface common_appliances_bool_exp {
  _and?: common_appliances_bool_exp[] | null;
  _not?: common_appliances_bool_exp | null;
  _or?: common_appliances_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  lastofficialinspection?: timestamp_comparison_exp | null;
  lastrevision?: timestamp_comparison_exp | null;
  maintenances?: common_maintenances_bool_exp | null;
  maintenances_aggregate?: common_maintenances_aggregate_bool_exp | null;
  manufacturer?: String_comparison_exp | null;
  model?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  nextofficialinspection?: timestamp_comparison_exp | null;
  nextrevision?: timestamp_comparison_exp | null;
  outofservicedate?: timestamp_comparison_exp | null;
  person?: common_persons_bool_exp | null;
  productionnumber?: String_comparison_exp | null;
  purchasedate?: timestamp_comparison_exp | null;
  realestateid?: uuid_comparison_exp | null;
  remarks?: String_comparison_exp | null;
  serialnumber?: String_comparison_exp | null;
  servicecompanyid?: uuid_comparison_exp | null;
  serviceconditions?: String_comparison_exp | null;
  serviceend?: timestamp_comparison_exp | null;
  servicestart?: timestamp_comparison_exp | null;
  unit?: common_units_bool_exp | null;
  unitid?: uuid_comparison_exp | null;
}

export interface common_conditions_aggregate_bool_exp {
  count?: common_conditions_aggregate_bool_exp_count | null;
}

export interface common_conditions_aggregate_bool_exp_count {
  arguments?: common_conditions_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_conditions_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.conditions". All fields are combined with a logical 'AND'.
 */
export interface common_conditions_bool_exp {
  _and?: common_conditions_bool_exp[] | null;
  _not?: common_conditions_bool_exp | null;
  _or?: common_conditions_bool_exp[] | null;
  consumerpriceindex?: timestamp_comparison_exp | null;
  costincreaseindex?: timestamp_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  flatratefee?: numeric_comparison_exp | null;
  grossrent?: numeric_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  maturities?: Int_comparison_exp | null;
  netrent?: numeric_comparison_exp | null;
  paymentonaccount?: numeric_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  rentincreasereserveamount?: numeric_comparison_exp | null;
  rentincreasereservetext?: String_comparison_exp | null;
  tenancy?: common_tenancys_bool_exp | null;
  tenancyid?: uuid_comparison_exp | null;
  validfrom?: timestamp_comparison_exp | null;
  vat?: numeric_comparison_exp | null;
}

export interface common_datafilelinks_aggregate_bool_exp {
  bool_and?: common_datafilelinks_aggregate_bool_exp_bool_and | null;
  bool_or?: common_datafilelinks_aggregate_bool_exp_bool_or | null;
  count?: common_datafilelinks_aggregate_bool_exp_count | null;
}

export interface common_datafilelinks_aggregate_bool_exp_bool_and {
  arguments: common_datafilelinks_select_column_common_datafilelinks_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: common_datafilelinks_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface common_datafilelinks_aggregate_bool_exp_bool_or {
  arguments: common_datafilelinks_select_column_common_datafilelinks_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: common_datafilelinks_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface common_datafilelinks_aggregate_bool_exp_count {
  arguments?: common_datafilelinks_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_datafilelinks_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.datafilelinks". All fields are combined with a logical 'AND'.
 */
export interface common_datafilelinks_bool_exp {
  _and?: common_datafilelinks_bool_exp[] | null;
  _not?: common_datafilelinks_bool_exp | null;
  _or?: common_datafilelinks_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  datafile?: common_datafiles_bool_exp | null;
  datafileid?: uuid_comparison_exp | null;
  foreignlinkentity?: String_comparison_exp | null;
  foreignlinkid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  linktype?: Int_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  updated?: timestamp_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface common_datafiles_aggregate_bool_exp {
  count?: common_datafiles_aggregate_bool_exp_count | null;
}

export interface common_datafiles_aggregate_bool_exp_count {
  arguments?: common_datafiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_datafiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.datafiles". All fields are combined with a logical 'AND'.
 */
export interface common_datafiles_bool_exp {
  _and?: common_datafiles_bool_exp[] | null;
  _not?: common_datafiles_bool_exp | null;
  _or?: common_datafiles_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  datafilelinks?: common_datafilelinks_bool_exp | null;
  datafilelinks_aggregate?: common_datafilelinks_aggregate_bool_exp | null;
  deleted?: timestamp_comparison_exp | null;
  extension?: String_comparison_exp | null;
  externallink?: String_comparison_exp | null;
  filedate?: timestamp_comparison_exp | null;
  hash?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  mimetype?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  origin?: Int_comparison_exp | null;
  thumbnailid?: uuid_comparison_exp | null;
}

export interface common_houses_aggregate_bool_exp {
  count?: common_houses_aggregate_bool_exp_count | null;
}

export interface common_houses_aggregate_bool_exp_count {
  arguments?: common_houses_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_houses_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.houses". All fields are combined with a logical 'AND'.
 */
export interface common_houses_bool_exp {
  _and?: common_houses_bool_exp[] | null;
  _not?: common_houses_bool_exp | null;
  _or?: common_houses_bool_exp[] | null;
  city?: String_comparison_exp | null;
  constructionyear?: Int_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  egid?: String_comparison_exp | null;
  housenumber?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  lastrenovation?: Int_comparison_exp | null;
  number?: String_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  street?: String_comparison_exp | null;
  tenancys_today?: portal_v_tenancys_today_bool_exp | null;
  tenancys_today_aggregate?: portal_v_tenancys_today_aggregate_bool_exp | null;
  units?: common_units_bool_exp | null;
  units_aggregate?: common_units_aggregate_bool_exp | null;
  zip?: String_comparison_exp | null;
}

export interface common_maintenances_aggregate_bool_exp {
  count?: common_maintenances_aggregate_bool_exp_count | null;
}

export interface common_maintenances_aggregate_bool_exp_count {
  arguments?: common_maintenances_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_maintenances_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.maintenances". All fields are combined with a logical 'AND'.
 */
export interface common_maintenances_bool_exp {
  _and?: common_maintenances_bool_exp[] | null;
  _not?: common_maintenances_bool_exp | null;
  _or?: common_maintenances_bool_exp[] | null;
  appliance?: common_appliances_bool_exp | null;
  applianceid?: uuid_comparison_exp | null;
  booking?: portal_bookings_bool_exp | null;
  bookingid?: uuid_comparison_exp | null;
  cost?: numeric_comparison_exp | null;
  craftsmanid?: uuid_comparison_exp | null;
  craftsmanreference?: String_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  date?: timestamp_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  person?: common_persons_bool_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  remarks?: String_comparison_exp | null;
  status?: Int_comparison_exp | null;
  unit?: common_units_bool_exp | null;
  unitid?: uuid_comparison_exp | null;
  workfrom?: timestamp_comparison_exp | null;
  workuntil?: timestamp_comparison_exp | null;
}

export interface common_managements_aggregate_bool_exp {
  count?: common_managements_aggregate_bool_exp_count | null;
}

export interface common_managements_aggregate_bool_exp_count {
  arguments?: common_managements_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_managements_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.managements". All fields are combined with a logical 'AND'.
 */
export interface common_managements_bool_exp {
  _and?: common_managements_bool_exp[] | null;
  _not?: common_managements_bool_exp | null;
  _or?: common_managements_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  managementfiles?: file_managementfiles_bool_exp | null;
  managementfiles_aggregate?: file_managementfiles_aggregate_bool_exp | null;
  person?: common_persons_bool_exp | null;
  personid?: uuid_comparison_exp | null;
  realestates?: common_realestates_bool_exp | null;
  realestates_aggregate?: common_realestates_aggregate_bool_exp | null;
}

export interface common_owners_aggregate_bool_exp {
  count?: common_owners_aggregate_bool_exp_count | null;
}

export interface common_owners_aggregate_bool_exp_count {
  arguments?: common_owners_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_owners_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.owners". All fields are combined with a logical 'AND'.
 */
export interface common_owners_bool_exp {
  _and?: common_owners_bool_exp[] | null;
  _not?: common_owners_bool_exp | null;
  _or?: common_owners_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  person?: common_persons_bool_exp | null;
  personid?: uuid_comparison_exp | null;
  realestates?: common_realestates_bool_exp | null;
  realestates_aggregate?: common_realestates_aggregate_bool_exp | null;
}

export interface common_persons_aggregate_bool_exp {
  bool_and?: common_persons_aggregate_bool_exp_bool_and | null;
  bool_or?: common_persons_aggregate_bool_exp_bool_or | null;
  count?: common_persons_aggregate_bool_exp_count | null;
}

export interface common_persons_aggregate_bool_exp_bool_and {
  arguments: common_persons_select_column_common_persons_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: common_persons_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface common_persons_aggregate_bool_exp_bool_or {
  arguments: common_persons_select_column_common_persons_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: common_persons_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface common_persons_aggregate_bool_exp_count {
  arguments?: common_persons_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_persons_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.persons". All fields are combined with a logical 'AND'.
 */
export interface common_persons_bool_exp {
  _and?: common_persons_bool_exp[] | null;
  _not?: common_persons_bool_exp | null;
  _or?: common_persons_bool_exp[] | null;
  addresses?: common_addresses_bool_exp | null;
  appliances?: common_appliances_bool_exp | null;
  appliances_aggregate?: common_appliances_aggregate_bool_exp | null;
  birthdate?: timestamp_comparison_exp | null;
  civilstate?: Int_comparison_exp | null;
  creditors?: cred_creditors_bool_exp | null;
  creditors_aggregate?: cred_creditors_aggregate_bool_exp | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  email?: String_comparison_exp | null;
  gender?: Int_comparison_exp | null;
  hometown?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  legal?: Boolean_comparison_exp | null;
  maintenances?: common_maintenances_bool_exp | null;
  maintenances_aggregate?: common_maintenances_aggregate_bool_exp | null;
  managements?: common_managements_bool_exp | null;
  managements_aggregate?: common_managements_aggregate_bool_exp | null;
  mobile?: String_comparison_exp | null;
  name1?: String_comparison_exp | null;
  name2?: String_comparison_exp | null;
  nationality?: String_comparison_exp | null;
  origin?: Int_comparison_exp | null;
  owners?: common_owners_bool_exp | null;
  owners_aggregate?: common_owners_aggregate_bool_exp | null;
  phonebusiness?: String_comparison_exp | null;
  phoneprivate?: String_comparison_exp | null;
  realestatepersons?: common_realestatepersons_bool_exp | null;
  realestatepersons_aggregate?: common_realestatepersons_aggregate_bool_exp | null;
  remarks?: String_comparison_exp | null;
  salutation?: String_comparison_exp | null;
  salutationletter?: String_comparison_exp | null;
  tenancypersons?: common_tenancypersons_bool_exp | null;
  tenancypersons_aggregate?: common_tenancypersons_aggregate_bool_exp | null;
  tenants?: common_tenants_bool_exp | null;
  tenants_aggregate?: common_tenants_aggregate_bool_exp | null;
}

/**
 * input type for inserting data into table "common.persons"
 */
export interface common_persons_insert_input {
  email?: string | null;
  id?: any | null;
  mobile?: string | null;
  phonebusiness?: string | null;
  phoneprivate?: string | null;
}

/**
 * input type for inserting object relation for remote table "common.persons"
 */
export interface common_persons_obj_rel_insert_input {
  data: common_persons_insert_input;
  on_conflict?: common_persons_on_conflict | null;
}

/**
 * on_conflict condition type for table "common.persons"
 */
export interface common_persons_on_conflict {
  constraint: common_persons_constraint;
  update_columns: common_persons_update_column[];
  where?: common_persons_bool_exp | null;
}

/**
 * input type for updating data in table "common.persons"
 */
export interface common_persons_set_input {
  email?: string | null;
  id?: any | null;
  mobile?: string | null;
  phonebusiness?: string | null;
  phoneprivate?: string | null;
}

export interface common_realestateaccess_aggregate_bool_exp {
  count?: common_realestateaccess_aggregate_bool_exp_count | null;
}

export interface common_realestateaccess_aggregate_bool_exp_count {
  arguments?: common_realestateaccess_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_realestateaccess_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.realestateaccess". All fields are combined with a logical 'AND'.
 */
export interface common_realestateaccess_bool_exp {
  _and?: common_realestateaccess_bool_exp[] | null;
  _not?: common_realestateaccess_bool_exp | null;
  _or?: common_realestateaccess_bool_exp[] | null;
  accesslevel?: Int_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  role?: Int_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  useremails?: ums_v_users_email_bool_exp | null;
  useremails_aggregate?: ums_v_users_email_aggregate_bool_exp | null;
  userid?: uuid_comparison_exp | null;
}

export interface common_realestatecontacts_aggregate_bool_exp {
  count?: common_realestatecontacts_aggregate_bool_exp_count | null;
}

export interface common_realestatecontacts_aggregate_bool_exp_count {
  arguments?: common_realestatecontacts_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_realestatecontacts_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.realestatecontacts". All fields are combined with a logical 'AND'.
 */
export interface common_realestatecontacts_bool_exp {
  _and?: common_realestatecontacts_bool_exp[] | null;
  _not?: common_realestatecontacts_bool_exp | null;
  _or?: common_realestatecontacts_bool_exp[] | null;
  accountantemail?: String_comparison_exp | null;
  accountantmobile?: String_comparison_exp | null;
  accountantname?: String_comparison_exp | null;
  accountantphone?: String_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  facilityemail?: String_comparison_exp | null;
  facilitymobile?: String_comparison_exp | null;
  facilityname?: String_comparison_exp | null;
  facilityphone?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  manageremail?: String_comparison_exp | null;
  managermobile?: String_comparison_exp | null;
  managername?: String_comparison_exp | null;
  managerphone?: String_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
}

export interface common_realestatefeatures_aggregate_bool_exp {
  count?: common_realestatefeatures_aggregate_bool_exp_count | null;
}

export interface common_realestatefeatures_aggregate_bool_exp_count {
  arguments?: common_realestatefeatures_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_realestatefeatures_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.realestatefeatures". All fields are combined with a logical 'AND'.
 */
export interface common_realestatefeatures_bool_exp {
  _and?: common_realestatefeatures_bool_exp[] | null;
  _not?: common_realestatefeatures_bool_exp | null;
  _or?: common_realestatefeatures_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  feature?: Int_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
}

export interface common_realestatepersons_aggregate_bool_exp {
  count?: common_realestatepersons_aggregate_bool_exp_count | null;
}

export interface common_realestatepersons_aggregate_bool_exp_count {
  arguments?: common_realestatepersons_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_realestatepersons_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.realestatepersons". All fields are combined with a logical 'AND'.
 */
export interface common_realestatepersons_bool_exp {
  _and?: common_realestatepersons_bool_exp[] | null;
  _not?: common_realestatepersons_bool_exp | null;
  _or?: common_realestatepersons_bool_exp[] | null;
  appliances?: common_appliances_bool_exp | null;
  appliances_aggregate?: common_appliances_aggregate_bool_exp | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  maintenances?: common_maintenances_bool_exp | null;
  maintenances_aggregate?: common_maintenances_aggregate_bool_exp | null;
  person?: common_persons_bool_exp | null;
  personid?: uuid_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  role?: Int_comparison_exp | null;
}

export interface common_realestates_aggregate_bool_exp {
  bool_and?: common_realestates_aggregate_bool_exp_bool_and | null;
  bool_or?: common_realestates_aggregate_bool_exp_bool_or | null;
  count?: common_realestates_aggregate_bool_exp_count | null;
}

export interface common_realestates_aggregate_bool_exp_bool_and {
  arguments: common_realestates_select_column_common_realestates_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: common_realestates_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface common_realestates_aggregate_bool_exp_bool_or {
  arguments: common_realestates_select_column_common_realestates_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: common_realestates_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface common_realestates_aggregate_bool_exp_count {
  arguments?: common_realestates_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_realestates_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.realestates". All fields are combined with a logical 'AND'.
 */
export interface common_realestates_bool_exp {
  _and?: common_realestates_bool_exp[] | null;
  _not?: common_realestates_bool_exp | null;
  _or?: common_realestates_bool_exp[] | null;
  accountlists?: portal_accountlists_bool_exp | null;
  accountlists_aggregate?: portal_accountlists_aggregate_bool_exp | null;
  appliances?: common_appliances_bool_exp | null;
  appliances_aggregate?: common_appliances_aggregate_bool_exp | null;
  assemblys?: cond_assemblys_bool_exp | null;
  assemblys_aggregate?: cond_assemblys_aggregate_bool_exp | null;
  conditions?: common_conditions_bool_exp | null;
  conditions_aggregate?: common_conditions_aggregate_bool_exp | null;
  condominium?: Boolean_comparison_exp | null;
  consolidation?: Boolean_comparison_exp | null;
  consolidationid?: uuid_comparison_exp | null;
  coownerfiles?: file_v_files_overview_coowner_bool_exp | null;
  coownerfiles_aggregate?: file_v_files_overview_coowner_aggregate_bool_exp | null;
  coownerfilesoverview?: file_v_coowner_files_overview_bool_exp | null;
  coownerfilesoverview_aggregate?: file_v_coowner_files_overview_aggregate_bool_exp | null;
  coowners?: common_v_coowners_by_realestateaccess_bool_exp | null;
  coowners_aggregate?: common_v_coowners_by_realestateaccess_aggregate_bool_exp | null;
  customer?: ums_customers_bool_exp | null;
  datafilelinks?: common_datafilelinks_bool_exp | null;
  datafilelinks_aggregate?: common_datafilelinks_aggregate_bool_exp | null;
  houses?: common_houses_bool_exp | null;
  houses_aggregate?: common_houses_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  maintenances?: common_maintenances_bool_exp | null;
  maintenances_aggregate?: common_maintenances_aggregate_bool_exp | null;
  management?: common_managements_bool_exp | null;
  managementid?: uuid_comparison_exp | null;
  managerfiles?: file_v_files_overview_manager_bool_exp | null;
  managerfiles_aggregate?: file_v_files_overview_manager_aggregate_bool_exp | null;
  managerfilesoverview?: file_v_manager_files_overview_bool_exp | null;
  managerfilesoverview_aggregate?: file_v_manager_files_overview_aggregate_bool_exp | null;
  name?: String_comparison_exp | null;
  newsrealestates?: news_newsrealestates_bool_exp | null;
  newsrealestates_aggregate?: news_newsrealestates_aggregate_bool_exp | null;
  number?: String_comparison_exp | null;
  owner?: common_owners_bool_exp | null;
  ownerfiles?: file_v_files_overview_owner_bool_exp | null;
  ownerfiles_aggregate?: file_v_files_overview_owner_aggregate_bool_exp | null;
  ownerfilesoverview?: file_v_owner_files_overview_bool_exp | null;
  ownerfilesoverview_aggregate?: file_v_owner_files_overview_aggregate_bool_exp | null;
  ownerid?: uuid_comparison_exp | null;
  realestateaccesses?: common_realestateaccess_bool_exp | null;
  realestateaccesses_aggregate?: common_realestateaccess_aggregate_bool_exp | null;
  realestatecontacts?: common_realestatecontacts_bool_exp | null;
  realestatecontacts_aggregate?: common_realestatecontacts_aggregate_bool_exp | null;
  realestatefeatures?: common_realestatefeatures_bool_exp | null;
  realestatefeatures_aggregate?: common_realestatefeatures_aggregate_bool_exp | null;
  realestatefiles?: file_realestatefiles_bool_exp | null;
  realestatefiles_aggregate?: file_realestatefiles_aggregate_bool_exp | null;
  realestatepersons?: common_realestatepersons_bool_exp | null;
  realestatepersons_aggregate?: common_realestatepersons_aggregate_bool_exp | null;
  realestateusers?: common_realestateusers_bool_exp | null;
  realestateusers_aggregate?: common_realestateusers_aggregate_bool_exp | null;
  tenancypersons?: common_tenancypersons_bool_exp | null;
  tenancypersons_aggregate?: common_tenancypersons_aggregate_bool_exp | null;
  tenancys?: common_tenancys_bool_exp | null;
  tenancys_aggregate?: common_tenancys_aggregate_bool_exp | null;
  tenantfilesoverview?: file_v_tenant_files_overview_bool_exp | null;
  tenantfilesoverview_aggregate?: file_v_tenant_files_overview_aggregate_bool_exp | null;
  tenants?: common_v_tenants_by_realestateaccess_bool_exp | null;
  tenants_aggregate?: common_v_tenants_by_realestateaccess_aggregate_bool_exp | null;
  tiles?: portal_tiles_bool_exp | null;
  tiles_aggregate?: portal_tiles_aggregate_bool_exp | null;
  units?: common_units_bool_exp | null;
  units_aggregate?: common_units_aggregate_bool_exp | null;
  v_realestatefiles?: file_v_realestatefiles_files_bool_exp | null;
  v_realestatefiles_aggregate?: file_v_realestatefiles_files_aggregate_bool_exp | null;
}

export interface common_realestateusers_aggregate_bool_exp {
  count?: common_realestateusers_aggregate_bool_exp_count | null;
}

export interface common_realestateusers_aggregate_bool_exp_count {
  arguments?: common_realestateusers_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_realestateusers_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.realestateusers". All fields are combined with a logical 'AND'.
 */
export interface common_realestateusers_bool_exp {
  _and?: common_realestateusers_bool_exp[] | null;
  _not?: common_realestateusers_bool_exp | null;
  _or?: common_realestateusers_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  emailuser?: ums_v_users_email_bool_exp | null;
  id?: uuid_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  role?: Int_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  userid?: uuid_comparison_exp | null;
}

export interface common_tenancypersons_aggregate_bool_exp {
  count?: common_tenancypersons_aggregate_bool_exp_count | null;
}

export interface common_tenancypersons_aggregate_bool_exp_count {
  arguments?: common_tenancypersons_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_tenancypersons_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.tenancypersons". All fields are combined with a logical 'AND'.
 */
export interface common_tenancypersons_bool_exp {
  _and?: common_tenancypersons_bool_exp[] | null;
  _not?: common_tenancypersons_bool_exp | null;
  _or?: common_tenancypersons_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  person?: common_persons_bool_exp | null;
  personid?: uuid_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  role?: Int_comparison_exp | null;
  tenancy?: common_tenancys_bool_exp | null;
  tenancyid?: uuid_comparison_exp | null;
}

export interface common_tenancys_aggregate_bool_exp {
  bool_and?: common_tenancys_aggregate_bool_exp_bool_and | null;
  bool_or?: common_tenancys_aggregate_bool_exp_bool_or | null;
  count?: common_tenancys_aggregate_bool_exp_count | null;
}

export interface common_tenancys_aggregate_bool_exp_bool_and {
  arguments: common_tenancys_select_column_common_tenancys_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: common_tenancys_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface common_tenancys_aggregate_bool_exp_bool_or {
  arguments: common_tenancys_select_column_common_tenancys_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: common_tenancys_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface common_tenancys_aggregate_bool_exp_count {
  arguments?: common_tenancys_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_tenancys_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.tenancys". All fields are combined with a logical 'AND'.
 */
export interface common_tenancys_bool_exp {
  _and?: common_tenancys_bool_exp[] | null;
  _not?: common_tenancys_bool_exp | null;
  _or?: common_tenancys_bool_exp[] | null;
  conditions?: common_conditions_bool_exp | null;
  conditions_aggregate?: common_conditions_aggregate_bool_exp | null;
  customer?: ums_customers_bool_exp | null;
  deposit?: Boolean_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  leaseend?: timestamp_comparison_exp | null;
  leasestart?: timestamp_comparison_exp | null;
  minimumrentdate?: timestamp_comparison_exp | null;
  movein?: timestamp_comparison_exp | null;
  moveout?: timestamp_comparison_exp | null;
  noticemonths?: Int_comparison_exp | null;
  noticeperiodowner?: Int_comparison_exp | null;
  noticeperiodtenant?: Int_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  rentalsInTenancys?: rent_rentals_bool_exp | null;
  rentalsInTenancys_aggregate?: rent_rentals_aggregate_bool_exp | null;
  rentalsOutTenancys?: rent_rentals_bool_exp | null;
  rentalsOutTenancys_aggregate?: rent_rentals_aggregate_bool_exp | null;
  tenancypersons?: common_tenancypersons_bool_exp | null;
  tenancypersons_aggregate?: common_tenancypersons_aggregate_bool_exp | null;
  tenant?: common_tenants_bool_exp | null;
  tenantid?: uuid_comparison_exp | null;
  type?: Int_comparison_exp | null;
  unit?: common_units_bool_exp | null;
  unitid?: uuid_comparison_exp | null;
  vacancy?: Boolean_comparison_exp | null;
  vattaxed?: Boolean_comparison_exp | null;
}

export interface common_tenantaccess_aggregate_bool_exp {
  count?: common_tenantaccess_aggregate_bool_exp_count | null;
}

export interface common_tenantaccess_aggregate_bool_exp_count {
  arguments?: common_tenantaccess_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_tenantaccess_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.tenantaccess". All fields are combined with a logical 'AND'.
 */
export interface common_tenantaccess_bool_exp {
  _and?: common_tenantaccess_bool_exp[] | null;
  _not?: common_tenantaccess_bool_exp | null;
  _or?: common_tenantaccess_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  role?: Int_comparison_exp | null;
  tenant?: common_tenants_bool_exp | null;
  tenantid?: uuid_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  userid?: uuid_comparison_exp | null;
}

export interface common_tenants_aggregate_bool_exp {
  count?: common_tenants_aggregate_bool_exp_count | null;
}

export interface common_tenants_aggregate_bool_exp_count {
  arguments?: common_tenants_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_tenants_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.tenants". All fields are combined with a logical 'AND'.
 */
export interface common_tenants_bool_exp {
  _and?: common_tenants_bool_exp[] | null;
  _not?: common_tenants_bool_exp | null;
  _or?: common_tenants_bool_exp[] | null;
  accountbalance?: numeric_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  notifications?: ec_notifications_bool_exp | null;
  notifications_aggregate?: ec_notifications_aggregate_bool_exp | null;
  person?: common_persons_bool_exp | null;
  tenancys?: common_tenancys_bool_exp | null;
  tenancys_aggregate?: common_tenancys_aggregate_bool_exp | null;
  tenantaccesses?: common_tenantaccess_bool_exp | null;
  tenantaccesses_aggregate?: common_tenantaccess_aggregate_bool_exp | null;
  tenantbookings?: portal_tenantbookings_bool_exp | null;
  tenantfiles?: file_tenantfiles_bool_exp | null;
  tenantfiles_aggregate?: file_tenantfiles_aggregate_bool_exp | null;
}

export interface common_units_aggregate_bool_exp {
  avg?: common_units_aggregate_bool_exp_avg | null;
  corr?: common_units_aggregate_bool_exp_corr | null;
  count?: common_units_aggregate_bool_exp_count | null;
  covar_samp?: common_units_aggregate_bool_exp_covar_samp | null;
  max?: common_units_aggregate_bool_exp_max | null;
  min?: common_units_aggregate_bool_exp_min | null;
  stddev_samp?: common_units_aggregate_bool_exp_stddev_samp | null;
  sum?: common_units_aggregate_bool_exp_sum | null;
  var_samp?: common_units_aggregate_bool_exp_var_samp | null;
}

export interface common_units_aggregate_bool_exp_avg {
  arguments: common_units_select_column_common_units_aggregate_bool_exp_avg_arguments_columns;
  distinct?: boolean | null;
  filter?: common_units_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface common_units_aggregate_bool_exp_corr {
  arguments: common_units_aggregate_bool_exp_corr_arguments;
  distinct?: boolean | null;
  filter?: common_units_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface common_units_aggregate_bool_exp_corr_arguments {
  X: common_units_select_column_common_units_aggregate_bool_exp_corr_arguments_columns;
  Y: common_units_select_column_common_units_aggregate_bool_exp_corr_arguments_columns;
}

export interface common_units_aggregate_bool_exp_count {
  arguments?: common_units_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_units_bool_exp | null;
  predicate: Int_comparison_exp;
}

export interface common_units_aggregate_bool_exp_covar_samp {
  arguments: common_units_aggregate_bool_exp_covar_samp_arguments;
  distinct?: boolean | null;
  filter?: common_units_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface common_units_aggregate_bool_exp_covar_samp_arguments {
  X: common_units_select_column_common_units_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: common_units_select_column_common_units_aggregate_bool_exp_covar_samp_arguments_columns;
}

export interface common_units_aggregate_bool_exp_max {
  arguments: common_units_select_column_common_units_aggregate_bool_exp_max_arguments_columns;
  distinct?: boolean | null;
  filter?: common_units_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface common_units_aggregate_bool_exp_min {
  arguments: common_units_select_column_common_units_aggregate_bool_exp_min_arguments_columns;
  distinct?: boolean | null;
  filter?: common_units_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface common_units_aggregate_bool_exp_stddev_samp {
  arguments: common_units_select_column_common_units_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: boolean | null;
  filter?: common_units_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface common_units_aggregate_bool_exp_sum {
  arguments: common_units_select_column_common_units_aggregate_bool_exp_sum_arguments_columns;
  distinct?: boolean | null;
  filter?: common_units_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface common_units_aggregate_bool_exp_var_samp {
  arguments: common_units_select_column_common_units_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: boolean | null;
  filter?: common_units_bool_exp | null;
  predicate: float8_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.units". All fields are combined with a logical 'AND'.
 */
export interface common_units_bool_exp {
  _and?: common_units_bool_exp[] | null;
  _not?: common_units_bool_exp | null;
  _or?: common_units_bool_exp[] | null;
  appliances?: common_appliances_bool_exp | null;
  appliances_aggregate?: common_appliances_aggregate_bool_exp | null;
  category?: Int_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  ewid?: String_comparison_exp | null;
  floor?: Int_comparison_exp | null;
  hash?: String_comparison_exp | null;
  house?: common_houses_bool_exp | null;
  houseid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  maintenances?: common_maintenances_bool_exp | null;
  maintenances_aggregate?: common_maintenances_aggregate_bool_exp | null;
  name?: String_comparison_exp | null;
  number?: Int_comparison_exp | null;
  otoid?: String_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  rental?: rent_rentals_bool_exp | null;
  rental_aggregate?: rent_rentals_aggregate_bool_exp | null;
  rentals?: rent_rentals_bool_exp | null;
  rentals_aggregate?: rent_rentals_aggregate_bool_exp | null;
  rooms?: float8_comparison_exp | null;
  space?: float8_comparison_exp | null;
  tenancys?: common_tenancys_bool_exp | null;
  tenancys_aggregate?: common_tenancys_aggregate_bool_exp | null;
  tenancys_today?: portal_v_tenancys_today_bool_exp | null;
  tenancys_today_aggregate?: portal_v_tenancys_today_aggregate_bool_exp | null;
  unitfiles?: file_unitfiles_bool_exp | null;
  unitfiles_aggregate?: file_unitfiles_aggregate_bool_exp | null;
  utilization?: Int_comparison_exp | null;
  valuequota?: float8_comparison_exp | null;
}

export interface common_v_coowners_by_realestateaccess_aggregate_bool_exp {
  count?: common_v_coowners_by_realestateaccess_aggregate_bool_exp_count | null;
}

export interface common_v_coowners_by_realestateaccess_aggregate_bool_exp_count {
  arguments?: common_v_coowners_by_realestateaccess_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_v_coowners_by_realestateaccess_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.v_coowners_by_realestateaccess". All fields are combined with a logical 'AND'.
 */
export interface common_v_coowners_by_realestateaccess_bool_exp {
  _and?: common_v_coowners_by_realestateaccess_bool_exp[] | null;
  _not?: common_v_coowners_by_realestateaccess_bool_exp | null;
  _or?: common_v_coowners_by_realestateaccess_bool_exp[] | null;
  accesslevel?: Int_comparison_exp | null;
  email?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  realestateid?: uuid_comparison_exp | null;
  role?: Int_comparison_exp | null;
  userid?: uuid_comparison_exp | null;
}

export interface common_v_tenants_by_realestateaccess_aggregate_bool_exp {
  count?: common_v_tenants_by_realestateaccess_aggregate_bool_exp_count | null;
}

export interface common_v_tenants_by_realestateaccess_aggregate_bool_exp_count {
  arguments?: common_v_tenants_by_realestateaccess_select_column[] | null;
  distinct?: boolean | null;
  filter?: common_v_tenants_by_realestateaccess_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "common.v_tenants_by_realestateaccess". All fields are combined with a logical 'AND'.
 */
export interface common_v_tenants_by_realestateaccess_bool_exp {
  _and?: common_v_tenants_by_realestateaccess_bool_exp[] | null;
  _not?: common_v_tenants_by_realestateaccess_bool_exp | null;
  _or?: common_v_tenants_by_realestateaccess_bool_exp[] | null;
  accesslevel?: Int_comparison_exp | null;
  email?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  realestateid?: uuid_comparison_exp | null;
  role?: Int_comparison_exp | null;
  userid?: uuid_comparison_exp | null;
}

export interface cond_agendaitems_aggregate_bool_exp {
  count?: cond_agendaitems_aggregate_bool_exp_count | null;
}

export interface cond_agendaitems_aggregate_bool_exp_count {
  arguments?: cond_agendaitems_select_column[] | null;
  distinct?: boolean | null;
  filter?: cond_agendaitems_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "cond.agendaitems"
 */
export interface cond_agendaitems_arr_rel_insert_input {
  data: cond_agendaitems_insert_input[];
  on_conflict?: cond_agendaitems_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "cond.agendaitems". All fields are combined with a logical 'AND'.
 */
export interface cond_agendaitems_bool_exp {
  _and?: cond_agendaitems_bool_exp[] | null;
  _not?: cond_agendaitems_bool_exp | null;
  _or?: cond_agendaitems_bool_exp[] | null;
  agendaitem?: cond_agendaitems_bool_exp | null;
  agendaitemfiles?: file_agendaitemfiles_bool_exp | null;
  agendaitemfiles_aggregate?: file_agendaitemfiles_aggregate_bool_exp | null;
  agendaitems?: cond_agendaitems_bool_exp | null;
  agendaitems_aggregate?: cond_agendaitems_aggregate_bool_exp | null;
  agendaitems_mls?: cond_agendaitems_mls_bool_exp | null;
  agendaitems_mls_aggregate?: cond_agendaitems_mls_aggregate_bool_exp | null;
  assembly?: cond_assemblys_bool_exp | null;
  assemblyid?: uuid_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  parentagendaitemid?: uuid_comparison_exp | null;
  sort?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "cond.agendaitems"
 */
export interface cond_agendaitems_insert_input {
  agendaitem?: cond_agendaitems_obj_rel_insert_input | null;
  agendaitemfiles?: file_agendaitemfiles_arr_rel_insert_input | null;
  agendaitems?: cond_agendaitems_arr_rel_insert_input | null;
  agendaitems_mls?: cond_agendaitems_mls_arr_rel_insert_input | null;
  assembly?: cond_assemblys_obj_rel_insert_input | null;
  assemblyid?: any | null;
  id?: any | null;
  parentagendaitemid?: any | null;
  sort?: number | null;
}

export interface cond_agendaitems_mls_aggregate_bool_exp {
  count?: cond_agendaitems_mls_aggregate_bool_exp_count | null;
}

export interface cond_agendaitems_mls_aggregate_bool_exp_count {
  arguments?: cond_agendaitems_mls_select_column[] | null;
  distinct?: boolean | null;
  filter?: cond_agendaitems_mls_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "cond.agendaitems_mls"
 */
export interface cond_agendaitems_mls_arr_rel_insert_input {
  data: cond_agendaitems_mls_insert_input[];
  on_conflict?: cond_agendaitems_mls_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "cond.agendaitems_mls". All fields are combined with a logical 'AND'.
 */
export interface cond_agendaitems_mls_bool_exp {
  _and?: cond_agendaitems_mls_bool_exp[] | null;
  _not?: cond_agendaitems_mls_bool_exp | null;
  _or?: cond_agendaitems_mls_bool_exp[] | null;
  agendaitem?: cond_agendaitems_bool_exp | null;
  agendaitemid?: uuid_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  text?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "cond.agendaitems_mls"
 */
export interface cond_agendaitems_mls_insert_input {
  agendaitem?: cond_agendaitems_obj_rel_insert_input | null;
  agendaitemid?: any | null;
  description?: string | null;
  id?: any | null;
  language?: string | null;
  text?: string | null;
}

/**
 * on_conflict condition type for table "cond.agendaitems_mls"
 */
export interface cond_agendaitems_mls_on_conflict {
  constraint: cond_agendaitems_mls_constraint;
  update_columns: cond_agendaitems_mls_update_column[];
  where?: cond_agendaitems_mls_bool_exp | null;
}

/**
 * input type for inserting object relation for remote table "cond.agendaitems"
 */
export interface cond_agendaitems_obj_rel_insert_input {
  data: cond_agendaitems_insert_input;
  on_conflict?: cond_agendaitems_on_conflict | null;
}

/**
 * on_conflict condition type for table "cond.agendaitems"
 */
export interface cond_agendaitems_on_conflict {
  constraint: cond_agendaitems_constraint;
  update_columns: cond_agendaitems_update_column[];
  where?: cond_agendaitems_bool_exp | null;
}

export interface cond_agendaitemtemplates_aggregate_bool_exp {
  count?: cond_agendaitemtemplates_aggregate_bool_exp_count | null;
}

export interface cond_agendaitemtemplates_aggregate_bool_exp_count {
  arguments?: cond_agendaitemtemplates_select_column[] | null;
  distinct?: boolean | null;
  filter?: cond_agendaitemtemplates_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "cond.agendaitemtemplates"
 */
export interface cond_agendaitemtemplates_arr_rel_insert_input {
  data: cond_agendaitemtemplates_insert_input[];
  on_conflict?: cond_agendaitemtemplates_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "cond.agendaitemtemplates". All fields are combined with a logical 'AND'.
 */
export interface cond_agendaitemtemplates_bool_exp {
  _and?: cond_agendaitemtemplates_bool_exp[] | null;
  _not?: cond_agendaitemtemplates_bool_exp | null;
  _or?: cond_agendaitemtemplates_bool_exp[] | null;
  agendaitemtemplates_mls?: cond_agendaitemtemplates_mls_bool_exp | null;
  agendaitemtemplates_mls_aggregate?: cond_agendaitemtemplates_mls_aggregate_bool_exp | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  parentagendaitemtemplate?: cond_agendaitemtemplates_bool_exp | null;
  parentagendaitemtemplateid?: uuid_comparison_exp | null;
  sort?: Int_comparison_exp | null;
  subagendaitemtemplates?: cond_agendaitemtemplates_bool_exp | null;
  subagendaitemtemplates_aggregate?: cond_agendaitemtemplates_aggregate_bool_exp | null;
}

/**
 * input type for inserting data into table "cond.agendaitemtemplates"
 */
export interface cond_agendaitemtemplates_insert_input {
  agendaitemtemplates_mls?: cond_agendaitemtemplates_mls_arr_rel_insert_input | null;
  id?: any | null;
  parentagendaitemtemplate?: cond_agendaitemtemplates_obj_rel_insert_input | null;
  parentagendaitemtemplateid?: any | null;
  sort?: number | null;
  subagendaitemtemplates?: cond_agendaitemtemplates_arr_rel_insert_input | null;
}

export interface cond_agendaitemtemplates_mls_aggregate_bool_exp {
  count?: cond_agendaitemtemplates_mls_aggregate_bool_exp_count | null;
}

export interface cond_agendaitemtemplates_mls_aggregate_bool_exp_count {
  arguments?: cond_agendaitemtemplates_mls_select_column[] | null;
  distinct?: boolean | null;
  filter?: cond_agendaitemtemplates_mls_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "cond.agendaitemtemplates_mls"
 */
export interface cond_agendaitemtemplates_mls_arr_rel_insert_input {
  data: cond_agendaitemtemplates_mls_insert_input[];
  on_conflict?: cond_agendaitemtemplates_mls_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "cond.agendaitemtemplates_mls". All fields are combined with a logical 'AND'.
 */
export interface cond_agendaitemtemplates_mls_bool_exp {
  _and?: cond_agendaitemtemplates_mls_bool_exp[] | null;
  _not?: cond_agendaitemtemplates_mls_bool_exp | null;
  _or?: cond_agendaitemtemplates_mls_bool_exp[] | null;
  agendaitemtemplate?: cond_agendaitemtemplates_bool_exp | null;
  agendaitemtemplateid?: uuid_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  description?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  text?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "cond.agendaitemtemplates_mls"
 */
export interface cond_agendaitemtemplates_mls_insert_input {
  agendaitemtemplate?: cond_agendaitemtemplates_obj_rel_insert_input | null;
  agendaitemtemplateid?: any | null;
  description?: string | null;
  id?: any | null;
  language?: string | null;
  text?: string | null;
}

/**
 * on_conflict condition type for table "cond.agendaitemtemplates_mls"
 */
export interface cond_agendaitemtemplates_mls_on_conflict {
  constraint: cond_agendaitemtemplates_mls_constraint;
  update_columns: cond_agendaitemtemplates_mls_update_column[];
  where?: cond_agendaitemtemplates_mls_bool_exp | null;
}

/**
 * input type for inserting object relation for remote table "cond.agendaitemtemplates"
 */
export interface cond_agendaitemtemplates_obj_rel_insert_input {
  data: cond_agendaitemtemplates_insert_input;
  on_conflict?: cond_agendaitemtemplates_on_conflict | null;
}

/**
 * on_conflict condition type for table "cond.agendaitemtemplates"
 */
export interface cond_agendaitemtemplates_on_conflict {
  constraint: cond_agendaitemtemplates_constraint;
  update_columns: cond_agendaitemtemplates_update_column[];
  where?: cond_agendaitemtemplates_bool_exp | null;
}

export interface cond_assemblys_aggregate_bool_exp {
  count?: cond_assemblys_aggregate_bool_exp_count | null;
}

export interface cond_assemblys_aggregate_bool_exp_count {
  arguments?: cond_assemblys_select_column[] | null;
  distinct?: boolean | null;
  filter?: cond_assemblys_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cond.assemblys". All fields are combined with a logical 'AND'.
 */
export interface cond_assemblys_bool_exp {
  _and?: cond_assemblys_bool_exp[] | null;
  _not?: cond_assemblys_bool_exp | null;
  _or?: cond_assemblys_bool_exp[] | null;
  agendaitems?: cond_agendaitems_bool_exp | null;
  agendaitems_aggregate?: cond_agendaitems_aggregate_bool_exp | null;
  agendaitemtexts?: cond_v_getagendaitemtexts_bool_exp | null;
  agendaitemtexts_aggregate?: cond_v_getagendaitemtexts_aggregate_bool_exp | null;
  assemblyevents?: noti_assemblyevents_bool_exp | null;
  assemblyevents_aggregate?: noti_assemblyevents_aggregate_bool_exp | null;
  assemblyfiles?: file_assemblyfiles_bool_exp | null;
  assemblyfiles_aggregate?: file_assemblyfiles_aggregate_bool_exp | null;
  assemblyuseraudits?: cond_assemblyuseraudits_bool_exp | null;
  assemblyuseraudits_aggregate?: cond_assemblyuseraudits_aggregate_bool_exp | null;
  city?: String_comparison_exp | null;
  coowners?: common_v_coowners_by_realestateaccess_bool_exp | null;
  coowners_aggregate?: common_v_coowners_by_realestateaccess_aggregate_bool_exp | null;
  customer?: ums_customers_bool_exp | null;
  date?: timestamp_comparison_exp | null;
  housenumber?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  locationdetail?: String_comparison_exp | null;
  motiondeadline?: date_comparison_exp | null;
  publishdate?: timestamp_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  remarks?: String_comparison_exp | null;
  state?: Int_comparison_exp | null;
  street?: String_comparison_exp | null;
  title?: String_comparison_exp | null;
  zip?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "cond.assemblys"
 */
export interface cond_assemblys_insert_input {
  agendaitems?: cond_agendaitems_arr_rel_insert_input | null;
  assemblyevents?: noti_assemblyevents_arr_rel_insert_input | null;
  assemblyfiles?: file_assemblyfiles_arr_rel_insert_input | null;
  assemblyuseraudits?: cond_assemblyuseraudits_arr_rel_insert_input | null;
  city?: string | null;
  date?: any | null;
  housenumber?: string | null;
  id?: any | null;
  locationdetail?: string | null;
  motiondeadline?: any | null;
  realestateid?: any | null;
  remarks?: string | null;
  state?: number | null;
  street?: string | null;
  title?: string | null;
  zip?: string | null;
}

/**
 * input type for inserting object relation for remote table "cond.assemblys"
 */
export interface cond_assemblys_obj_rel_insert_input {
  data: cond_assemblys_insert_input;
  on_conflict?: cond_assemblys_on_conflict | null;
}

/**
 * on_conflict condition type for table "cond.assemblys"
 */
export interface cond_assemblys_on_conflict {
  constraint: cond_assemblys_constraint;
  update_columns: cond_assemblys_update_column[];
  where?: cond_assemblys_bool_exp | null;
}

export interface cond_assemblyuseraudits_aggregate_bool_exp {
  count?: cond_assemblyuseraudits_aggregate_bool_exp_count | null;
}

export interface cond_assemblyuseraudits_aggregate_bool_exp_count {
  arguments?: cond_assemblyuseraudits_select_column[] | null;
  distinct?: boolean | null;
  filter?: cond_assemblyuseraudits_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "cond.assemblyuseraudits"
 */
export interface cond_assemblyuseraudits_arr_rel_insert_input {
  data: cond_assemblyuseraudits_insert_input[];
  on_conflict?: cond_assemblyuseraudits_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "cond.assemblyuseraudits". All fields are combined with a logical 'AND'.
 */
export interface cond_assemblyuseraudits_bool_exp {
  _and?: cond_assemblyuseraudits_bool_exp[] | null;
  _not?: cond_assemblyuseraudits_bool_exp | null;
  _or?: cond_assemblyuseraudits_bool_exp[] | null;
  assembly?: cond_assemblys_bool_exp | null;
  assemblyid?: uuid_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  event?: Int_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  timestamp?: timestamp_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  userid?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "cond.assemblyuseraudits"
 */
export interface cond_assemblyuseraudits_insert_input {
  assembly?: cond_assemblys_obj_rel_insert_input | null;
  assemblyid?: any | null;
  event?: number | null;
  id?: any | null;
  timestamp?: any | null;
  userid?: any | null;
}

/**
 * on_conflict condition type for table "cond.assemblyuseraudits"
 */
export interface cond_assemblyuseraudits_on_conflict {
  constraint: cond_assemblyuseraudits_constraint;
  update_columns: cond_assemblyuseraudits_update_column[];
  where?: cond_assemblyuseraudits_bool_exp | null;
}

export interface cond_v_getagendaitemtexts_aggregate_bool_exp {
  count?: cond_v_getagendaitemtexts_aggregate_bool_exp_count | null;
}

export interface cond_v_getagendaitemtexts_aggregate_bool_exp_count {
  arguments?: cond_v_getagendaitemtexts_select_column[] | null;
  distinct?: boolean | null;
  filter?: cond_v_getagendaitemtexts_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cond.v_getagendaitemtexts". All fields are combined with a logical 'AND'.
 */
export interface cond_v_getagendaitemtexts_bool_exp {
  _and?: cond_v_getagendaitemtexts_bool_exp[] | null;
  _not?: cond_v_getagendaitemtexts_bool_exp | null;
  _or?: cond_v_getagendaitemtexts_bool_exp[] | null;
  agendaitemfiles?: file_agendaitemfiles_bool_exp | null;
  agendaitemfiles_aggregate?: file_agendaitemfiles_aggregate_bool_exp | null;
  assemblyid?: uuid_comparison_exp | null;
  description?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  parentagendaitemid?: uuid_comparison_exp | null;
  sort?: Int_comparison_exp | null;
  subagendaitemtext?: cond_v_getagendaitemtexts_bool_exp | null;
  subagendaitemtext_aggregate?: cond_v_getagendaitemtexts_aggregate_bool_exp | null;
  text?: String_comparison_exp | null;
}

export interface cred_creditors_aggregate_bool_exp {
  count?: cred_creditors_aggregate_bool_exp_count | null;
}

export interface cred_creditors_aggregate_bool_exp_count {
  arguments?: cred_creditors_select_column[] | null;
  distinct?: boolean | null;
  filter?: cred_creditors_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cred.creditors". All fields are combined with a logical 'AND'.
 */
export interface cred_creditors_bool_exp {
  _and?: cred_creditors_bool_exp[] | null;
  _not?: cred_creditors_bool_exp | null;
  _or?: cred_creditors_bool_exp[] | null;
  customerid?: uuid_comparison_exp | null;
  hash?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  invoices?: cred_invoices_bool_exp | null;
  invoices_aggregate?: cred_invoices_aggregate_bool_exp | null;
  name?: String_comparison_exp | null;
  paymentaccounts?: cred_paymentaccounts_bool_exp | null;
  paymentaccounts_aggregate?: cred_paymentaccounts_aggregate_bool_exp | null;
  person?: common_persons_bool_exp | null;
  personid?: uuid_comparison_exp | null;
}

export interface cred_invoices_aggregate_bool_exp {
  count?: cred_invoices_aggregate_bool_exp_count | null;
}

export interface cred_invoices_aggregate_bool_exp_count {
  arguments?: cred_invoices_select_column[] | null;
  distinct?: boolean | null;
  filter?: cred_invoices_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cred.invoices". All fields are combined with a logical 'AND'.
 */
export interface cred_invoices_bool_exp {
  _and?: cred_invoices_bool_exp[] | null;
  _not?: cred_invoices_bool_exp | null;
  _or?: cred_invoices_bool_exp[] | null;
  amount?: numeric_comparison_exp | null;
  creditor?: cred_creditors_bool_exp | null;
  creditorid?: uuid_comparison_exp | null;
  creditorinfo?: jsonb_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  date?: date_comparison_exp | null;
  duedate?: date_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  incident?: ticket_incidents_bool_exp | null;
  incidentid?: uuid_comparison_exp | null;
  insertuserid?: uuid_comparison_exp | null;
  insertuserinfo?: ums_users_bool_exp | null;
  invoicefiles?: file_invoicefiles_bool_exp | null;
  invoicefiles_aggregate?: file_invoicefiles_aggregate_bool_exp | null;
  invoiceinfo?: jsonb_comparison_exp | null;
  invoicenumber?: String_comparison_exp | null;
  number?: Int_comparison_exp | null;
  order?: ticket_orders_bool_exp | null;
  orderid?: uuid_comparison_exp | null;
  origin?: Int_comparison_exp | null;
  paiddate?: date_comparison_exp | null;
  paymentaccount?: cred_paymentaccounts_bool_exp | null;
  paymentaccountid?: uuid_comparison_exp | null;
  paymentinfo?: jsonb_comparison_exp | null;
  payoutaccountid?: uuid_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  rejectedcomment?: String_comparison_exp | null;
  state?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
  unit?: common_units_bool_exp | null;
  unitid?: uuid_comparison_exp | null;
  v_creditor?: cred_v_creditors_bool_exp | null;
  v_incident?: cred_v_tickets_bool_exp | null;
  v_order?: cred_v_orders_bool_exp | null;
  workflowinstance?: uuid_comparison_exp | null;
  workflowusers?: cred_workflowusers_bool_exp | null;
  workflowusers_aggregate?: cred_workflowusers_aggregate_bool_exp | null;
}

/**
 * input type for inserting data into table "cred.invoices"
 */
export interface cred_invoices_insert_input {
  amount?: any | null;
  creditorid?: any | null;
  creditorinfo?: any | null;
  date?: any | null;
  duedate?: any | null;
  id?: any | null;
  incident?: ticket_incidents_obj_rel_insert_input | null;
  incidentid?: any | null;
  insertuserid?: any | null;
  invoicefiles?: file_invoicefiles_arr_rel_insert_input | null;
  invoiceinfo?: any | null;
  invoicenumber?: string | null;
  number?: number | null;
  order?: ticket_orders_obj_rel_insert_input | null;
  orderid?: any | null;
  origin?: number | null;
  paiddate?: any | null;
  paymentaccountid?: any | null;
  paymentinfo?: any | null;
  payoutaccountid?: any | null;
  realestateid?: any | null;
  state?: number | null;
  type?: number | null;
  unitid?: any | null;
  workflowinstance?: any | null;
  workflowusers?: cred_workflowusers_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "cred.invoices"
 */
export interface cred_invoices_obj_rel_insert_input {
  data: cred_invoices_insert_input;
  on_conflict?: cred_invoices_on_conflict | null;
}

/**
 * on_conflict condition type for table "cred.invoices"
 */
export interface cred_invoices_on_conflict {
  constraint: cred_invoices_constraint;
  update_columns: cred_invoices_update_column[];
  where?: cred_invoices_bool_exp | null;
}

export interface cred_paymentaccounts_aggregate_bool_exp {
  count?: cred_paymentaccounts_aggregate_bool_exp_count | null;
}

export interface cred_paymentaccounts_aggregate_bool_exp_count {
  arguments?: cred_paymentaccounts_select_column[] | null;
  distinct?: boolean | null;
  filter?: cred_paymentaccounts_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cred.paymentaccounts". All fields are combined with a logical 'AND'.
 */
export interface cred_paymentaccounts_bool_exp {
  _and?: cred_paymentaccounts_bool_exp[] | null;
  _not?: cred_paymentaccounts_bool_exp | null;
  _or?: cred_paymentaccounts_bool_exp[] | null;
  creditor?: cred_creditors_bool_exp | null;
  creditorid?: uuid_comparison_exp | null;
  customerid?: uuid_comparison_exp | null;
  hash?: String_comparison_exp | null;
  iban?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  invoices?: cred_invoices_bool_exp | null;
  invoices_aggregate?: cred_invoices_aggregate_bool_exp | null;
  name?: String_comparison_exp | null;
  type?: Int_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "cred.v_creditors". All fields are combined with a logical 'AND'.
 */
export interface cred_v_creditors_bool_exp {
  _and?: cred_v_creditors_bool_exp[] | null;
  _not?: cred_v_creditors_bool_exp | null;
  _or?: cred_v_creditors_bool_exp[] | null;
  address_origin?: Int_comparison_exp | null;
  city?: String_comparison_exp | null;
  creditorid?: uuid_comparison_exp | null;
  creditorname?: String_comparison_exp | null;
  customerid?: uuid_comparison_exp | null;
  email?: String_comparison_exp | null;
  gender?: Int_comparison_exp | null;
  housenumber?: String_comparison_exp | null;
  iban?: String_comparison_exp | null;
  legal?: Boolean_comparison_exp | null;
  mobile?: String_comparison_exp | null;
  name1?: String_comparison_exp | null;
  name2?: String_comparison_exp | null;
  paymentaccounts?: cred_paymentaccounts_bool_exp | null;
  paymentaccounts_aggregate?: cred_paymentaccounts_aggregate_bool_exp | null;
  person_origin?: Int_comparison_exp | null;
  personid?: uuid_comparison_exp | null;
  phonebusiness?: String_comparison_exp | null;
  phoneprivate?: String_comparison_exp | null;
  salutation?: String_comparison_exp | null;
  street?: String_comparison_exp | null;
  zip?: String_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "cred.v_orders". All fields are combined with a logical 'AND'.
 */
export interface cred_v_orders_bool_exp {
  _and?: cred_v_orders_bool_exp[] | null;
  _not?: cred_v_orders_bool_exp | null;
  _or?: cred_v_orders_bool_exp[] | null;
  contractorid?: uuid_comparison_exp | null;
  contractortype?: Int_comparison_exp | null;
  customerid?: uuid_comparison_exp | null;
  date?: String_comparison_exp | null;
  date_long?: String_comparison_exp | null;
  incident?: cred_v_tickets_bool_exp | null;
  incidentid?: uuid_comparison_exp | null;
  instruction?: String_comparison_exp | null;
  name1?: String_comparison_exp | null;
  name2?: String_comparison_exp | null;
  number?: String_comparison_exp | null;
  orderid?: uuid_comparison_exp | null;
  realestateid?: uuid_comparison_exp | null;
  title?: String_comparison_exp | null;
  unitid?: uuid_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "cred.v_tickets". All fields are combined with a logical 'AND'.
 */
export interface cred_v_tickets_bool_exp {
  _and?: cred_v_tickets_bool_exp[] | null;
  _not?: cred_v_tickets_bool_exp | null;
  _or?: cred_v_tickets_bool_exp[] | null;
  customerid?: uuid_comparison_exp | null;
  date?: String_comparison_exp | null;
  date_long?: String_comparison_exp | null;
  incidentid?: uuid_comparison_exp | null;
  number?: String_comparison_exp | null;
  realestateid?: uuid_comparison_exp | null;
  title?: String_comparison_exp | null;
  unitid?: uuid_comparison_exp | null;
}

export interface cred_workflowusers_aggregate_bool_exp {
  count?: cred_workflowusers_aggregate_bool_exp_count | null;
}

export interface cred_workflowusers_aggregate_bool_exp_count {
  arguments?: cred_workflowusers_select_column[] | null;
  distinct?: boolean | null;
  filter?: cred_workflowusers_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "cred.workflowusers"
 */
export interface cred_workflowusers_arr_rel_insert_input {
  data: cred_workflowusers_insert_input[];
  on_conflict?: cred_workflowusers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "cred.workflowusers". All fields are combined with a logical 'AND'.
 */
export interface cred_workflowusers_bool_exp {
  _and?: cred_workflowusers_bool_exp[] | null;
  _not?: cred_workflowusers_bool_exp | null;
  _or?: cred_workflowusers_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  invoice?: cred_invoices_bool_exp | null;
  invoiceid?: uuid_comparison_exp | null;
  role?: Int_comparison_exp | null;
  signaturestamp?: timestamp_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  userid?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "cred.workflowusers"
 */
export interface cred_workflowusers_insert_input {
  id?: any | null;
  invoice?: cred_invoices_obj_rel_insert_input | null;
  invoiceid?: any | null;
  role?: number | null;
  signaturestamp?: any | null;
  userid?: any | null;
}

/**
 * on_conflict condition type for table "cred.workflowusers"
 */
export interface cred_workflowusers_on_conflict {
  constraint: cred_workflowusers_constraint;
  update_columns: cred_workflowusers_update_column[];
  where?: cred_workflowusers_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'.
 */
export interface date_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

export interface ec_component_mls_aggregate_bool_exp {
  count?: ec_component_mls_aggregate_bool_exp_count | null;
}

export interface ec_component_mls_aggregate_bool_exp_count {
  arguments?: ec_component_mls_select_column[] | null;
  distinct?: boolean | null;
  filter?: ec_component_mls_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ec.component_mls". All fields are combined with a logical 'AND'.
 */
export interface ec_component_mls_bool_exp {
  _and?: ec_component_mls_bool_exp[] | null;
  _not?: ec_component_mls_bool_exp | null;
  _or?: ec_component_mls_bool_exp[] | null;
  component?: ec_components_bool_exp | null;
  componentid?: uuid_comparison_exp | null;
  hint?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  title?: String_comparison_exp | null;
}

export interface ec_components_aggregate_bool_exp {
  count?: ec_components_aggregate_bool_exp_count | null;
}

export interface ec_components_aggregate_bool_exp_count {
  arguments?: ec_components_select_column[] | null;
  distinct?: boolean | null;
  filter?: ec_components_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ec.components". All fields are combined with a logical 'AND'.
 */
export interface ec_components_bool_exp {
  _and?: ec_components_bool_exp[] | null;
  _not?: ec_components_bool_exp | null;
  _or?: ec_components_bool_exp[] | null;
  component_mls?: ec_component_mls_bool_exp | null;
  component_mls_aggregate?: ec_component_mls_aggregate_bool_exp | null;
  components?: ec_components_bool_exp | null;
  components_aggregate?: ec_components_aggregate_bool_exp | null;
  icon?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  notifications?: ec_notifications_bool_exp | null;
  notifications_aggregate?: ec_notifications_aggregate_bool_exp | null;
  parameters?: jsonb_comparison_exp | null;
  parent?: ec_components_bool_exp | null;
  parentid?: uuid_comparison_exp | null;
  sort?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
}

export interface ec_notifications_aggregate_bool_exp {
  count?: ec_notifications_aggregate_bool_exp_count | null;
}

export interface ec_notifications_aggregate_bool_exp_count {
  arguments?: ec_notifications_select_column[] | null;
  distinct?: boolean | null;
  filter?: ec_notifications_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ec.notifications". All fields are combined with a logical 'AND'.
 */
export interface ec_notifications_bool_exp {
  _and?: ec_notifications_bool_exp[] | null;
  _not?: ec_notifications_bool_exp | null;
  _or?: ec_notifications_bool_exp[] | null;
  component?: String_comparison_exp | null;
  componentByComponentid?: ec_components_bool_exp | null;
  componentid?: uuid_comparison_exp | null;
  contactid?: uuid_comparison_exp | null;
  contacttype?: Int_comparison_exp | null;
  customattributes?: jsonb_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  date?: timestamp_comparison_exp | null;
  forwardingtype?: Int_comparison_exp | null;
  hash?: String_comparison_exp | null;
  house?: common_houses_bool_exp | null;
  houseid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  message?: String_comparison_exp | null;
  number?: Int_comparison_exp | null;
  originalmessage?: jsonb_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  remarks?: String_comparison_exp | null;
  state?: Int_comparison_exp | null;
  tenancy?: common_tenancys_bool_exp | null;
  tenancyid?: uuid_comparison_exp | null;
  tenant?: common_tenants_bool_exp | null;
  tenantid?: uuid_comparison_exp | null;
  token?: String_comparison_exp | null;
  type?: Int_comparison_exp | null;
  unit?: common_units_bool_exp | null;
  unitid?: uuid_comparison_exp | null;
}

export interface file_accountlistfiles_aggregate_bool_exp {
  bool_and?: file_accountlistfiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_accountlistfiles_aggregate_bool_exp_bool_or | null;
  count?: file_accountlistfiles_aggregate_bool_exp_count | null;
}

export interface file_accountlistfiles_aggregate_bool_exp_bool_and {
  arguments: file_accountlistfiles_select_column_file_accountlistfiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_accountlistfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_accountlistfiles_aggregate_bool_exp_bool_or {
  arguments: file_accountlistfiles_select_column_file_accountlistfiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_accountlistfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_accountlistfiles_aggregate_bool_exp_count {
  arguments?: file_accountlistfiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_accountlistfiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.accountlistfiles". All fields are combined with a logical 'AND'.
 */
export interface file_accountlistfiles_bool_exp {
  _and?: file_accountlistfiles_bool_exp[] | null;
  _not?: file_accountlistfiles_bool_exp | null;
  _or?: file_accountlistfiles_bool_exp[] | null;
  accountlist?: portal_accountlists_bool_exp | null;
  accountlistid?: uuid_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_agendaitemfiles_aggregate_bool_exp {
  bool_and?: file_agendaitemfiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_agendaitemfiles_aggregate_bool_exp_bool_or | null;
  count?: file_agendaitemfiles_aggregate_bool_exp_count | null;
}

export interface file_agendaitemfiles_aggregate_bool_exp_bool_and {
  arguments: file_agendaitemfiles_select_column_file_agendaitemfiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_agendaitemfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_agendaitemfiles_aggregate_bool_exp_bool_or {
  arguments: file_agendaitemfiles_select_column_file_agendaitemfiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_agendaitemfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_agendaitemfiles_aggregate_bool_exp_count {
  arguments?: file_agendaitemfiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_agendaitemfiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "file.agendaitemfiles"
 */
export interface file_agendaitemfiles_arr_rel_insert_input {
  data: file_agendaitemfiles_insert_input[];
  on_conflict?: file_agendaitemfiles_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "file.agendaitemfiles". All fields are combined with a logical 'AND'.
 */
export interface file_agendaitemfiles_bool_exp {
  _and?: file_agendaitemfiles_bool_exp[] | null;
  _not?: file_agendaitemfiles_bool_exp | null;
  _or?: file_agendaitemfiles_bool_exp[] | null;
  agendaitem?: cond_agendaitems_bool_exp | null;
  agendaitemid?: uuid_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

/**
 * input type for inserting data into table "file.agendaitemfiles"
 */
export interface file_agendaitemfiles_insert_input {
  agendaitem?: cond_agendaitems_obj_rel_insert_input | null;
  agendaitemid?: any | null;
  file?: file_files_obj_rel_insert_input | null;
  fileid?: any | null;
  id?: any | null;
  roleid?: number | null;
  type?: number | null;
  visible?: boolean | null;
}

/**
 * on_conflict condition type for table "file.agendaitemfiles"
 */
export interface file_agendaitemfiles_on_conflict {
  constraint: file_agendaitemfiles_constraint;
  update_columns: file_agendaitemfiles_update_column[];
  where?: file_agendaitemfiles_bool_exp | null;
}

export interface file_assemblyfiles_aggregate_bool_exp {
  bool_and?: file_assemblyfiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_assemblyfiles_aggregate_bool_exp_bool_or | null;
  count?: file_assemblyfiles_aggregate_bool_exp_count | null;
}

export interface file_assemblyfiles_aggregate_bool_exp_bool_and {
  arguments: file_assemblyfiles_select_column_file_assemblyfiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_assemblyfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_assemblyfiles_aggregate_bool_exp_bool_or {
  arguments: file_assemblyfiles_select_column_file_assemblyfiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_assemblyfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_assemblyfiles_aggregate_bool_exp_count {
  arguments?: file_assemblyfiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_assemblyfiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "file.assemblyfiles"
 */
export interface file_assemblyfiles_arr_rel_insert_input {
  data: file_assemblyfiles_insert_input[];
  on_conflict?: file_assemblyfiles_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "file.assemblyfiles". All fields are combined with a logical 'AND'.
 */
export interface file_assemblyfiles_bool_exp {
  _and?: file_assemblyfiles_bool_exp[] | null;
  _not?: file_assemblyfiles_bool_exp | null;
  _or?: file_assemblyfiles_bool_exp[] | null;
  assembly?: cond_assemblys_bool_exp | null;
  assemblyid?: uuid_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  system?: Boolean_comparison_exp | null;
  type?: Int_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

/**
 * input type for inserting data into table "file.assemblyfiles"
 */
export interface file_assemblyfiles_insert_input {
  assembly?: cond_assemblys_obj_rel_insert_input | null;
  assemblyid?: any | null;
  file?: file_files_obj_rel_insert_input | null;
  fileid?: any | null;
  id?: any | null;
  roleid?: number | null;
  system?: boolean | null;
  type?: number | null;
  visible?: boolean | null;
}

/**
 * on_conflict condition type for table "file.assemblyfiles"
 */
export interface file_assemblyfiles_on_conflict {
  constraint: file_assemblyfiles_constraint;
  update_columns: file_assemblyfiles_update_column[];
  where?: file_assemblyfiles_bool_exp | null;
}

export interface file_bookingfiles_aggregate_bool_exp {
  bool_and?: file_bookingfiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_bookingfiles_aggregate_bool_exp_bool_or | null;
  count?: file_bookingfiles_aggregate_bool_exp_count | null;
}

export interface file_bookingfiles_aggregate_bool_exp_bool_and {
  arguments: file_bookingfiles_select_column_file_bookingfiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_bookingfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_bookingfiles_aggregate_bool_exp_bool_or {
  arguments: file_bookingfiles_select_column_file_bookingfiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_bookingfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_bookingfiles_aggregate_bool_exp_count {
  arguments?: file_bookingfiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_bookingfiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.bookingfiles". All fields are combined with a logical 'AND'.
 */
export interface file_bookingfiles_bool_exp {
  _and?: file_bookingfiles_bool_exp[] | null;
  _not?: file_bookingfiles_bool_exp | null;
  _or?: file_bookingfiles_bool_exp[] | null;
  booking?: portal_bookings_bool_exp | null;
  bookingid?: uuid_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_customerfiles_aggregate_bool_exp {
  bool_and?: file_customerfiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_customerfiles_aggregate_bool_exp_bool_or | null;
  count?: file_customerfiles_aggregate_bool_exp_count | null;
}

export interface file_customerfiles_aggregate_bool_exp_bool_and {
  arguments: file_customerfiles_select_column_file_customerfiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_customerfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_customerfiles_aggregate_bool_exp_bool_or {
  arguments: file_customerfiles_select_column_file_customerfiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_customerfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_customerfiles_aggregate_bool_exp_count {
  arguments?: file_customerfiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_customerfiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.customerfiles". All fields are combined with a logical 'AND'.
 */
export interface file_customerfiles_bool_exp {
  _and?: file_customerfiles_bool_exp[] | null;
  _not?: file_customerfiles_bool_exp | null;
  _or?: file_customerfiles_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
  updated?: timestamp_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_dmsconnections_aggregate_bool_exp {
  count?: file_dmsconnections_aggregate_bool_exp_count | null;
}

export interface file_dmsconnections_aggregate_bool_exp_count {
  arguments?: file_dmsconnections_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_dmsconnections_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.dmsconnections". All fields are combined with a logical 'AND'.
 */
export interface file_dmsconnections_bool_exp {
  _and?: file_dmsconnections_bool_exp[] | null;
  _not?: file_dmsconnections_bool_exp | null;
  _or?: file_dmsconnections_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  dmstype?: Int_comparison_exp | null;
  extradata?: jsonb_comparison_exp | null;
  hostname?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  password?: String_comparison_exp | null;
  username?: String_comparison_exp | null;
}

export interface file_files_aggregate_bool_exp {
  count?: file_files_aggregate_bool_exp_count | null;
}

export interface file_files_aggregate_bool_exp_count {
  arguments?: file_files_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_files_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.files". All fields are combined with a logical 'AND'.
 */
export interface file_files_bool_exp {
  _and?: file_files_bool_exp[] | null;
  _not?: file_files_bool_exp | null;
  _or?: file_files_bool_exp[] | null;
  accountlistfiles?: file_accountlistfiles_bool_exp | null;
  accountlistfiles_aggregate?: file_accountlistfiles_aggregate_bool_exp | null;
  agendaitemfiles?: file_agendaitemfiles_bool_exp | null;
  agendaitemfiles_aggregate?: file_agendaitemfiles_aggregate_bool_exp | null;
  assemblyfiles?: file_assemblyfiles_bool_exp | null;
  assemblyfiles_aggregate?: file_assemblyfiles_aggregate_bool_exp | null;
  bookingfiles?: file_bookingfiles_bool_exp | null;
  bookingfiles_aggregate?: file_bookingfiles_aggregate_bool_exp | null;
  connectionid?: uuid_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  customerfiles?: file_customerfiles_bool_exp | null;
  customerfiles_aggregate?: file_customerfiles_aggregate_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  extension?: String_comparison_exp | null;
  externallink?: String_comparison_exp | null;
  filedate?: timestamp_comparison_exp | null;
  fileevents?: noti_fileevents_bool_exp | null;
  fileevents_aggregate?: noti_fileevents_aggregate_bool_exp | null;
  hash?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  inboundfiles?: file_inboundfiles_bool_exp | null;
  inboundfiles_aggregate?: file_inboundfiles_aggregate_bool_exp | null;
  incidentfiles?: file_incidentfiles_bool_exp | null;
  incidentfiles_aggregate?: file_incidentfiles_aggregate_bool_exp | null;
  invoicefiles?: file_invoicefiles_bool_exp | null;
  invoicefiles_aggregate?: file_invoicefiles_aggregate_bool_exp | null;
  managementfiles?: file_managementfiles_bool_exp | null;
  managementfiles_aggregate?: file_managementfiles_aggregate_bool_exp | null;
  mimetype?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  newsfiles?: file_newsfiles_bool_exp | null;
  newsfiles_aggregate?: file_newsfiles_aggregate_bool_exp | null;
  orderfiles?: file_orderfiles_bool_exp | null;
  orderfiles_aggregate?: file_orderfiles_aggregate_bool_exp | null;
  origin?: Int_comparison_exp | null;
  realestatefiles?: file_realestatefiles_bool_exp | null;
  realestatefiles_aggregate?: file_realestatefiles_aggregate_bool_exp | null;
  tenantbookingfiles?: file_tenantbookingfiles_bool_exp | null;
  tenantbookingfiles_aggregate?: file_tenantbookingfiles_aggregate_bool_exp | null;
  tenantfiles?: file_tenantfiles_bool_exp | null;
  tenantfiles_aggregate?: file_tenantfiles_aggregate_bool_exp | null;
  thumbnailid?: uuid_comparison_exp | null;
  unitfiles?: file_unitfiles_bool_exp | null;
  unitfiles_aggregate?: file_unitfiles_aggregate_bool_exp | null;
}

/**
 * input type for inserting data into table "file.files"
 */
export interface file_files_insert_input {
  agendaitemfiles?: file_agendaitemfiles_arr_rel_insert_input | null;
  assemblyfiles?: file_assemblyfiles_arr_rel_insert_input | null;
  extension?: string | null;
  externallink?: string | null;
  filedate?: any | null;
  fileevents?: noti_fileevents_arr_rel_insert_input | null;
  hash?: string | null;
  id?: any | null;
  incidentfiles?: file_incidentfiles_arr_rel_insert_input | null;
  invoicefiles?: file_invoicefiles_arr_rel_insert_input | null;
  mimetype?: string | null;
  name?: string | null;
  newsfiles?: file_newsfiles_arr_rel_insert_input | null;
  orderfiles?: file_orderfiles_arr_rel_insert_input | null;
  origin?: number | null;
  thumbnailid?: any | null;
}

/**
 * input type for inserting object relation for remote table "file.files"
 */
export interface file_files_obj_rel_insert_input {
  data: file_files_insert_input;
  on_conflict?: file_files_on_conflict | null;
}

/**
 * on_conflict condition type for table "file.files"
 */
export interface file_files_on_conflict {
  constraint: file_files_constraint;
  update_columns: file_files_update_column[];
  where?: file_files_bool_exp | null;
}

export interface file_inboundfiles_aggregate_bool_exp {
  bool_and?: file_inboundfiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_inboundfiles_aggregate_bool_exp_bool_or | null;
  count?: file_inboundfiles_aggregate_bool_exp_count | null;
}

export interface file_inboundfiles_aggregate_bool_exp_bool_and {
  arguments: file_inboundfiles_select_column_file_inboundfiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_inboundfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_inboundfiles_aggregate_bool_exp_bool_or {
  arguments: file_inboundfiles_select_column_file_inboundfiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_inboundfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_inboundfiles_aggregate_bool_exp_count {
  arguments?: file_inboundfiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_inboundfiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.inboundfiles". All fields are combined with a logical 'AND'.
 */
export interface file_inboundfiles_bool_exp {
  _and?: file_inboundfiles_bool_exp[] | null;
  _not?: file_inboundfiles_bool_exp | null;
  _or?: file_inboundfiles_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  embedded?: Boolean_comparison_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  inboundid?: uuid_comparison_exp | null;
  inserted?: timestamp_comparison_exp | null;
  insertuser?: String_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
  updated?: timestamp_comparison_exp | null;
  updateuser?: String_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_incidentfiles_aggregate_bool_exp {
  bool_and?: file_incidentfiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_incidentfiles_aggregate_bool_exp_bool_or | null;
  count?: file_incidentfiles_aggregate_bool_exp_count | null;
}

export interface file_incidentfiles_aggregate_bool_exp_bool_and {
  arguments: file_incidentfiles_select_column_file_incidentfiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_incidentfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_incidentfiles_aggregate_bool_exp_bool_or {
  arguments: file_incidentfiles_select_column_file_incidentfiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_incidentfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_incidentfiles_aggregate_bool_exp_count {
  arguments?: file_incidentfiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_incidentfiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "file.incidentfiles"
 */
export interface file_incidentfiles_arr_rel_insert_input {
  data: file_incidentfiles_insert_input[];
  on_conflict?: file_incidentfiles_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "file.incidentfiles". All fields are combined with a logical 'AND'.
 */
export interface file_incidentfiles_bool_exp {
  _and?: file_incidentfiles_bool_exp[] | null;
  _not?: file_incidentfiles_bool_exp | null;
  _or?: file_incidentfiles_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  incident?: ticket_incidents_bool_exp | null;
  incidentid?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

/**
 * input type for inserting data into table "file.incidentfiles"
 */
export interface file_incidentfiles_insert_input {
  file?: file_files_obj_rel_insert_input | null;
  fileid?: any | null;
  id?: any | null;
  incident?: ticket_incidents_obj_rel_insert_input | null;
  incidentid?: any | null;
  roleid?: number | null;
  type?: number | null;
  visible?: boolean | null;
}

/**
 * on_conflict condition type for table "file.incidentfiles"
 */
export interface file_incidentfiles_on_conflict {
  constraint: file_incidentfiles_constraint;
  update_columns: file_incidentfiles_update_column[];
  where?: file_incidentfiles_bool_exp | null;
}

export interface file_invoicefiles_aggregate_bool_exp {
  bool_and?: file_invoicefiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_invoicefiles_aggregate_bool_exp_bool_or | null;
  count?: file_invoicefiles_aggregate_bool_exp_count | null;
}

export interface file_invoicefiles_aggregate_bool_exp_bool_and {
  arguments: file_invoicefiles_select_column_file_invoicefiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_invoicefiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_invoicefiles_aggregate_bool_exp_bool_or {
  arguments: file_invoicefiles_select_column_file_invoicefiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_invoicefiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_invoicefiles_aggregate_bool_exp_count {
  arguments?: file_invoicefiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_invoicefiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "file.invoicefiles"
 */
export interface file_invoicefiles_arr_rel_insert_input {
  data: file_invoicefiles_insert_input[];
  on_conflict?: file_invoicefiles_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "file.invoicefiles". All fields are combined with a logical 'AND'.
 */
export interface file_invoicefiles_bool_exp {
  _and?: file_invoicefiles_bool_exp[] | null;
  _not?: file_invoicefiles_bool_exp | null;
  _or?: file_invoicefiles_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  invoice?: cred_invoices_bool_exp | null;
  invoiceid?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

/**
 * input type for inserting data into table "file.invoicefiles"
 */
export interface file_invoicefiles_insert_input {
  file?: file_files_obj_rel_insert_input | null;
  fileid?: any | null;
  id?: any | null;
  invoice?: cred_invoices_obj_rel_insert_input | null;
  invoiceid?: any | null;
  roleid?: number | null;
  type?: number | null;
  visible?: boolean | null;
}

/**
 * on_conflict condition type for table "file.invoicefiles"
 */
export interface file_invoicefiles_on_conflict {
  constraint: file_invoicefiles_constraint;
  update_columns: file_invoicefiles_update_column[];
  where?: file_invoicefiles_bool_exp | null;
}

export interface file_managementfiles_aggregate_bool_exp {
  bool_and?: file_managementfiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_managementfiles_aggregate_bool_exp_bool_or | null;
  count?: file_managementfiles_aggregate_bool_exp_count | null;
}

export interface file_managementfiles_aggregate_bool_exp_bool_and {
  arguments: file_managementfiles_select_column_file_managementfiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_managementfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_managementfiles_aggregate_bool_exp_bool_or {
  arguments: file_managementfiles_select_column_file_managementfiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_managementfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_managementfiles_aggregate_bool_exp_count {
  arguments?: file_managementfiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_managementfiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.managementfiles". All fields are combined with a logical 'AND'.
 */
export interface file_managementfiles_bool_exp {
  _and?: file_managementfiles_bool_exp[] | null;
  _not?: file_managementfiles_bool_exp | null;
  _or?: file_managementfiles_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  management?: common_managements_bool_exp | null;
  managementid?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_newsfiles_aggregate_bool_exp {
  bool_and?: file_newsfiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_newsfiles_aggregate_bool_exp_bool_or | null;
  count?: file_newsfiles_aggregate_bool_exp_count | null;
}

export interface file_newsfiles_aggregate_bool_exp_bool_and {
  arguments: file_newsfiles_select_column_file_newsfiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_newsfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_newsfiles_aggregate_bool_exp_bool_or {
  arguments: file_newsfiles_select_column_file_newsfiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_newsfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_newsfiles_aggregate_bool_exp_count {
  arguments?: file_newsfiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_newsfiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "file.newsfiles"
 */
export interface file_newsfiles_arr_rel_insert_input {
  data: file_newsfiles_insert_input[];
  on_conflict?: file_newsfiles_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "file.newsfiles". All fields are combined with a logical 'AND'.
 */
export interface file_newsfiles_bool_exp {
  _and?: file_newsfiles_bool_exp[] | null;
  _not?: file_newsfiles_bool_exp | null;
  _or?: file_newsfiles_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  news?: news_news_bool_exp | null;
  newsid?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

/**
 * input type for inserting data into table "file.newsfiles"
 */
export interface file_newsfiles_insert_input {
  file?: file_files_obj_rel_insert_input | null;
  fileid?: any | null;
  id?: any | null;
  news?: news_news_obj_rel_insert_input | null;
  newsid?: any | null;
  roleid?: number | null;
  type?: number | null;
  visible?: boolean | null;
}

/**
 * on_conflict condition type for table "file.newsfiles"
 */
export interface file_newsfiles_on_conflict {
  constraint: file_newsfiles_constraint;
  update_columns: file_newsfiles_update_column[];
  where?: file_newsfiles_bool_exp | null;
}

export interface file_orderfiles_aggregate_bool_exp {
  bool_and?: file_orderfiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_orderfiles_aggregate_bool_exp_bool_or | null;
  count?: file_orderfiles_aggregate_bool_exp_count | null;
}

export interface file_orderfiles_aggregate_bool_exp_bool_and {
  arguments: file_orderfiles_select_column_file_orderfiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_orderfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_orderfiles_aggregate_bool_exp_bool_or {
  arguments: file_orderfiles_select_column_file_orderfiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_orderfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_orderfiles_aggregate_bool_exp_count {
  arguments?: file_orderfiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_orderfiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "file.orderfiles"
 */
export interface file_orderfiles_arr_rel_insert_input {
  data: file_orderfiles_insert_input[];
  on_conflict?: file_orderfiles_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "file.orderfiles". All fields are combined with a logical 'AND'.
 */
export interface file_orderfiles_bool_exp {
  _and?: file_orderfiles_bool_exp[] | null;
  _not?: file_orderfiles_bool_exp | null;
  _or?: file_orderfiles_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  order?: ticket_orders_bool_exp | null;
  orderid?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

/**
 * input type for inserting data into table "file.orderfiles"
 */
export interface file_orderfiles_insert_input {
  file?: file_files_obj_rel_insert_input | null;
  fileid?: any | null;
  id?: any | null;
  order?: ticket_orders_obj_rel_insert_input | null;
  orderid?: any | null;
  roleid?: number | null;
  type?: number | null;
  visible?: boolean | null;
}

/**
 * on_conflict condition type for table "file.orderfiles"
 */
export interface file_orderfiles_on_conflict {
  constraint: file_orderfiles_constraint;
  update_columns: file_orderfiles_update_column[];
  where?: file_orderfiles_bool_exp | null;
}

export interface file_realestatefiles_aggregate_bool_exp {
  bool_and?: file_realestatefiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_realestatefiles_aggregate_bool_exp_bool_or | null;
  count?: file_realestatefiles_aggregate_bool_exp_count | null;
}

export interface file_realestatefiles_aggregate_bool_exp_bool_and {
  arguments: file_realestatefiles_select_column_file_realestatefiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_realestatefiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_realestatefiles_aggregate_bool_exp_bool_or {
  arguments: file_realestatefiles_select_column_file_realestatefiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_realestatefiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_realestatefiles_aggregate_bool_exp_count {
  arguments?: file_realestatefiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_realestatefiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.realestatefiles". All fields are combined with a logical 'AND'.
 */
export interface file_realestatefiles_bool_exp {
  _and?: file_realestatefiles_bool_exp[] | null;
  _not?: file_realestatefiles_bool_exp | null;
  _or?: file_realestatefiles_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_tenantbookingfiles_aggregate_bool_exp {
  bool_and?: file_tenantbookingfiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_tenantbookingfiles_aggregate_bool_exp_bool_or | null;
  count?: file_tenantbookingfiles_aggregate_bool_exp_count | null;
}

export interface file_tenantbookingfiles_aggregate_bool_exp_bool_and {
  arguments: file_tenantbookingfiles_select_column_file_tenantbookingfiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_tenantbookingfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_tenantbookingfiles_aggregate_bool_exp_bool_or {
  arguments: file_tenantbookingfiles_select_column_file_tenantbookingfiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_tenantbookingfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_tenantbookingfiles_aggregate_bool_exp_count {
  arguments?: file_tenantbookingfiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_tenantbookingfiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.tenantbookingfiles". All fields are combined with a logical 'AND'.
 */
export interface file_tenantbookingfiles_bool_exp {
  _and?: file_tenantbookingfiles_bool_exp[] | null;
  _not?: file_tenantbookingfiles_bool_exp | null;
  _or?: file_tenantbookingfiles_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  tenantbooking?: portal_tenantbookings_bool_exp | null;
  tenantbookingid?: uuid_comparison_exp | null;
  type?: Int_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_tenantfiles_aggregate_bool_exp {
  bool_and?: file_tenantfiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_tenantfiles_aggregate_bool_exp_bool_or | null;
  count?: file_tenantfiles_aggregate_bool_exp_count | null;
}

export interface file_tenantfiles_aggregate_bool_exp_bool_and {
  arguments: file_tenantfiles_select_column_file_tenantfiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_tenantfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_tenantfiles_aggregate_bool_exp_bool_or {
  arguments: file_tenantfiles_select_column_file_tenantfiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_tenantfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_tenantfiles_aggregate_bool_exp_count {
  arguments?: file_tenantfiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_tenantfiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.tenantfiles". All fields are combined with a logical 'AND'.
 */
export interface file_tenantfiles_bool_exp {
  _and?: file_tenantfiles_bool_exp[] | null;
  _not?: file_tenantfiles_bool_exp | null;
  _or?: file_tenantfiles_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  tenant?: common_tenants_bool_exp | null;
  tenantid?: uuid_comparison_exp | null;
  type?: Int_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_unitfiles_aggregate_bool_exp {
  bool_and?: file_unitfiles_aggregate_bool_exp_bool_and | null;
  bool_or?: file_unitfiles_aggregate_bool_exp_bool_or | null;
  count?: file_unitfiles_aggregate_bool_exp_count | null;
}

export interface file_unitfiles_aggregate_bool_exp_bool_and {
  arguments: file_unitfiles_select_column_file_unitfiles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_unitfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_unitfiles_aggregate_bool_exp_bool_or {
  arguments: file_unitfiles_select_column_file_unitfiles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_unitfiles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_unitfiles_aggregate_bool_exp_count {
  arguments?: file_unitfiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_unitfiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.unitfiles". All fields are combined with a logical 'AND'.
 */
export interface file_unitfiles_bool_exp {
  _and?: file_unitfiles_bool_exp[] | null;
  _not?: file_unitfiles_bool_exp | null;
  _or?: file_unitfiles_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
  unit?: common_units_bool_exp | null;
  unitid?: uuid_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_v_bookingfiles_files_aggregate_bool_exp {
  count?: file_v_bookingfiles_files_aggregate_bool_exp_count | null;
}

export interface file_v_bookingfiles_files_aggregate_bool_exp_count {
  arguments?: file_v_bookingfiles_files_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_v_bookingfiles_files_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.v_bookingfiles_files". All fields are combined with a logical 'AND'.
 */
export interface file_v_bookingfiles_files_bool_exp {
  _and?: file_v_bookingfiles_files_bool_exp[] | null;
  _not?: file_v_bookingfiles_files_bool_exp | null;
  _or?: file_v_bookingfiles_files_bool_exp[] | null;
  bookingfileid?: uuid_comparison_exp | null;
  bookingid?: uuid_comparison_exp | null;
  customerid?: uuid_comparison_exp | null;
  extension?: String_comparison_exp | null;
  externallink?: String_comparison_exp | null;
  filedate?: timestamp_comparison_exp | null;
  fileid?: uuid_comparison_exp | null;
  hash?: String_comparison_exp | null;
  mimetype?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  origin?: Int_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  thumbnailid?: uuid_comparison_exp | null;
  type?: Int_comparison_exp | null;
}

export interface file_v_coowner_files_overview_aggregate_bool_exp {
  bool_and?: file_v_coowner_files_overview_aggregate_bool_exp_bool_and | null;
  bool_or?: file_v_coowner_files_overview_aggregate_bool_exp_bool_or | null;
  count?: file_v_coowner_files_overview_aggregate_bool_exp_count | null;
}

export interface file_v_coowner_files_overview_aggregate_bool_exp_bool_and {
  arguments: file_v_coowner_files_overview_select_column_file_v_coowner_files_overview_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_v_coowner_files_overview_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_v_coowner_files_overview_aggregate_bool_exp_bool_or {
  arguments: file_v_coowner_files_overview_select_column_file_v_coowner_files_overview_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_v_coowner_files_overview_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_v_coowner_files_overview_aggregate_bool_exp_count {
  arguments?: file_v_coowner_files_overview_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_v_coowner_files_overview_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.v_coowner_files_overview". All fields are combined with a logical 'AND'.
 */
export interface file_v_coowner_files_overview_bool_exp {
  _and?: file_v_coowner_files_overview_bool_exp[] | null;
  _not?: file_v_coowner_files_overview_bool_exp | null;
  _or?: file_v_coowner_files_overview_bool_exp[] | null;
  category?: Int_comparison_exp | null;
  categorylabel?: String_comparison_exp | null;
  customerid?: uuid_comparison_exp | null;
  extension?: String_comparison_exp | null;
  externallink?: String_comparison_exp | null;
  filedate?: timestamp_comparison_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  mimetype?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  origin?: Int_comparison_exp | null;
  realestateid?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  thumbnailid?: uuid_comparison_exp | null;
  userid?: uuid_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_v_files_overview_coowner_aggregate_bool_exp {
  bool_and?: file_v_files_overview_coowner_aggregate_bool_exp_bool_and | null;
  bool_or?: file_v_files_overview_coowner_aggregate_bool_exp_bool_or | null;
  count?: file_v_files_overview_coowner_aggregate_bool_exp_count | null;
}

export interface file_v_files_overview_coowner_aggregate_bool_exp_bool_and {
  arguments: file_v_files_overview_coowner_select_column_file_v_files_overview_coowner_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_v_files_overview_coowner_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_v_files_overview_coowner_aggregate_bool_exp_bool_or {
  arguments: file_v_files_overview_coowner_select_column_file_v_files_overview_coowner_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_v_files_overview_coowner_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_v_files_overview_coowner_aggregate_bool_exp_count {
  arguments?: file_v_files_overview_coowner_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_v_files_overview_coowner_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.v_files_overview_coowner". All fields are combined with a logical 'AND'.
 */
export interface file_v_files_overview_coowner_bool_exp {
  _and?: file_v_files_overview_coowner_bool_exp[] | null;
  _not?: file_v_files_overview_coowner_bool_exp | null;
  _or?: file_v_files_overview_coowner_bool_exp[] | null;
  category?: Int_comparison_exp | null;
  categorylabel?: String_comparison_exp | null;
  customerid?: uuid_comparison_exp | null;
  extension?: String_comparison_exp | null;
  externallink?: String_comparison_exp | null;
  filedate?: timestamp_comparison_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  mimetype?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  origin?: Int_comparison_exp | null;
  realestateid?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  thumbnailid?: uuid_comparison_exp | null;
  type?: Int_comparison_exp | null;
  userid?: uuid_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_v_files_overview_manager_aggregate_bool_exp {
  bool_and?: file_v_files_overview_manager_aggregate_bool_exp_bool_and | null;
  bool_or?: file_v_files_overview_manager_aggregate_bool_exp_bool_or | null;
  count?: file_v_files_overview_manager_aggregate_bool_exp_count | null;
}

export interface file_v_files_overview_manager_aggregate_bool_exp_bool_and {
  arguments: file_v_files_overview_manager_select_column_file_v_files_overview_manager_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_v_files_overview_manager_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_v_files_overview_manager_aggregate_bool_exp_bool_or {
  arguments: file_v_files_overview_manager_select_column_file_v_files_overview_manager_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_v_files_overview_manager_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_v_files_overview_manager_aggregate_bool_exp_count {
  arguments?: file_v_files_overview_manager_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_v_files_overview_manager_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.v_files_overview_manager". All fields are combined with a logical 'AND'.
 */
export interface file_v_files_overview_manager_bool_exp {
  _and?: file_v_files_overview_manager_bool_exp[] | null;
  _not?: file_v_files_overview_manager_bool_exp | null;
  _or?: file_v_files_overview_manager_bool_exp[] | null;
  category?: Int_comparison_exp | null;
  categorylabel?: String_comparison_exp | null;
  customerid?: uuid_comparison_exp | null;
  extension?: String_comparison_exp | null;
  externallink?: String_comparison_exp | null;
  filedate?: timestamp_comparison_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  mimetype?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  origin?: Int_comparison_exp | null;
  realestateid?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  thumbnailid?: uuid_comparison_exp | null;
  type?: Int_comparison_exp | null;
  userid?: uuid_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_v_files_overview_owner_aggregate_bool_exp {
  bool_and?: file_v_files_overview_owner_aggregate_bool_exp_bool_and | null;
  bool_or?: file_v_files_overview_owner_aggregate_bool_exp_bool_or | null;
  count?: file_v_files_overview_owner_aggregate_bool_exp_count | null;
}

export interface file_v_files_overview_owner_aggregate_bool_exp_bool_and {
  arguments: file_v_files_overview_owner_select_column_file_v_files_overview_owner_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_v_files_overview_owner_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_v_files_overview_owner_aggregate_bool_exp_bool_or {
  arguments: file_v_files_overview_owner_select_column_file_v_files_overview_owner_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_v_files_overview_owner_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_v_files_overview_owner_aggregate_bool_exp_count {
  arguments?: file_v_files_overview_owner_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_v_files_overview_owner_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.v_files_overview_owner". All fields are combined with a logical 'AND'.
 */
export interface file_v_files_overview_owner_bool_exp {
  _and?: file_v_files_overview_owner_bool_exp[] | null;
  _not?: file_v_files_overview_owner_bool_exp | null;
  _or?: file_v_files_overview_owner_bool_exp[] | null;
  category?: Int_comparison_exp | null;
  categorylabel?: String_comparison_exp | null;
  customerid?: uuid_comparison_exp | null;
  extension?: String_comparison_exp | null;
  externallink?: String_comparison_exp | null;
  filedate?: timestamp_comparison_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  mimetype?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  origin?: Int_comparison_exp | null;
  realestateid?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  thumbnailid?: uuid_comparison_exp | null;
  type?: Int_comparison_exp | null;
  userid?: uuid_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_v_manager_files_overview_aggregate_bool_exp {
  bool_and?: file_v_manager_files_overview_aggregate_bool_exp_bool_and | null;
  bool_or?: file_v_manager_files_overview_aggregate_bool_exp_bool_or | null;
  count?: file_v_manager_files_overview_aggregate_bool_exp_count | null;
}

export interface file_v_manager_files_overview_aggregate_bool_exp_bool_and {
  arguments: file_v_manager_files_overview_select_column_file_v_manager_files_overview_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_v_manager_files_overview_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_v_manager_files_overview_aggregate_bool_exp_bool_or {
  arguments: file_v_manager_files_overview_select_column_file_v_manager_files_overview_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_v_manager_files_overview_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_v_manager_files_overview_aggregate_bool_exp_count {
  arguments?: file_v_manager_files_overview_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_v_manager_files_overview_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.v_manager_files_overview". All fields are combined with a logical 'AND'.
 */
export interface file_v_manager_files_overview_bool_exp {
  _and?: file_v_manager_files_overview_bool_exp[] | null;
  _not?: file_v_manager_files_overview_bool_exp | null;
  _or?: file_v_manager_files_overview_bool_exp[] | null;
  category?: Int_comparison_exp | null;
  categorylabel?: String_comparison_exp | null;
  customerid?: uuid_comparison_exp | null;
  extension?: String_comparison_exp | null;
  externallink?: String_comparison_exp | null;
  filedate?: timestamp_comparison_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  mimetype?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  origin?: Int_comparison_exp | null;
  realestateid?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  thumbnailid?: uuid_comparison_exp | null;
  userid?: uuid_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_v_owner_files_overview_aggregate_bool_exp {
  bool_and?: file_v_owner_files_overview_aggregate_bool_exp_bool_and | null;
  bool_or?: file_v_owner_files_overview_aggregate_bool_exp_bool_or | null;
  count?: file_v_owner_files_overview_aggregate_bool_exp_count | null;
}

export interface file_v_owner_files_overview_aggregate_bool_exp_bool_and {
  arguments: file_v_owner_files_overview_select_column_file_v_owner_files_overview_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_v_owner_files_overview_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_v_owner_files_overview_aggregate_bool_exp_bool_or {
  arguments: file_v_owner_files_overview_select_column_file_v_owner_files_overview_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_v_owner_files_overview_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_v_owner_files_overview_aggregate_bool_exp_count {
  arguments?: file_v_owner_files_overview_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_v_owner_files_overview_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.v_owner_files_overview". All fields are combined with a logical 'AND'.
 */
export interface file_v_owner_files_overview_bool_exp {
  _and?: file_v_owner_files_overview_bool_exp[] | null;
  _not?: file_v_owner_files_overview_bool_exp | null;
  _or?: file_v_owner_files_overview_bool_exp[] | null;
  category?: Int_comparison_exp | null;
  categorylabel?: String_comparison_exp | null;
  customerid?: uuid_comparison_exp | null;
  extension?: String_comparison_exp | null;
  externallink?: String_comparison_exp | null;
  filedate?: timestamp_comparison_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  mimetype?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  origin?: Int_comparison_exp | null;
  realestateid?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  thumbnailid?: uuid_comparison_exp | null;
  userid?: uuid_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

export interface file_v_realestatefiles_files_aggregate_bool_exp {
  count?: file_v_realestatefiles_files_aggregate_bool_exp_count | null;
}

export interface file_v_realestatefiles_files_aggregate_bool_exp_count {
  arguments?: file_v_realestatefiles_files_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_v_realestatefiles_files_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.v_realestatefiles_files". All fields are combined with a logical 'AND'.
 */
export interface file_v_realestatefiles_files_bool_exp {
  _and?: file_v_realestatefiles_files_bool_exp[] | null;
  _not?: file_v_realestatefiles_files_bool_exp | null;
  _or?: file_v_realestatefiles_files_bool_exp[] | null;
  customerid?: uuid_comparison_exp | null;
  extension?: String_comparison_exp | null;
  externallink?: String_comparison_exp | null;
  filedate?: timestamp_comparison_exp | null;
  fileid?: uuid_comparison_exp | null;
  hash?: String_comparison_exp | null;
  mimetype?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  origin?: Int_comparison_exp | null;
  realestatefileid?: uuid_comparison_exp | null;
  realestateid?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  thumbnailid?: uuid_comparison_exp | null;
  type?: Int_comparison_exp | null;
}

export interface file_v_tenant_files_overview_aggregate_bool_exp {
  bool_and?: file_v_tenant_files_overview_aggregate_bool_exp_bool_and | null;
  bool_or?: file_v_tenant_files_overview_aggregate_bool_exp_bool_or | null;
  count?: file_v_tenant_files_overview_aggregate_bool_exp_count | null;
}

export interface file_v_tenant_files_overview_aggregate_bool_exp_bool_and {
  arguments: file_v_tenant_files_overview_select_column_file_v_tenant_files_overview_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: file_v_tenant_files_overview_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_v_tenant_files_overview_aggregate_bool_exp_bool_or {
  arguments: file_v_tenant_files_overview_select_column_file_v_tenant_files_overview_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: file_v_tenant_files_overview_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface file_v_tenant_files_overview_aggregate_bool_exp_count {
  arguments?: file_v_tenant_files_overview_select_column[] | null;
  distinct?: boolean | null;
  filter?: file_v_tenant_files_overview_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "file.v_tenant_files_overview". All fields are combined with a logical 'AND'.
 */
export interface file_v_tenant_files_overview_bool_exp {
  _and?: file_v_tenant_files_overview_bool_exp[] | null;
  _not?: file_v_tenant_files_overview_bool_exp | null;
  _or?: file_v_tenant_files_overview_bool_exp[] | null;
  category?: Int_comparison_exp | null;
  categorylabel?: String_comparison_exp | null;
  customerid?: uuid_comparison_exp | null;
  extension?: String_comparison_exp | null;
  externallink?: String_comparison_exp | null;
  filedate?: timestamp_comparison_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  mimetype?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  origin?: Int_comparison_exp | null;
  realestateid?: uuid_comparison_exp | null;
  roleid?: Int_comparison_exp | null;
  thumbnailid?: uuid_comparison_exp | null;
  userid?: uuid_comparison_exp | null;
  visible?: Boolean_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'.
 */
export interface float8_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'.
 */
export interface json_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

export interface jsonb_cast_exp {
  String?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'.
 */
export interface jsonb_comparison_exp {
  _cast?: jsonb_cast_exp | null;
  _contained_in?: any | null;
  _contains?: any | null;
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _has_key?: string | null;
  _has_keys_all?: string[] | null;
  _has_keys_any?: string[] | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

export interface mail_emaillinks_aggregate_bool_exp {
  count?: mail_emaillinks_aggregate_bool_exp_count | null;
}

export interface mail_emaillinks_aggregate_bool_exp_count {
  arguments?: mail_emaillinks_select_column[] | null;
  distinct?: boolean | null;
  filter?: mail_emaillinks_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "mail.emaillinks". All fields are combined with a logical 'AND'.
 */
export interface mail_emaillinks_bool_exp {
  _and?: mail_emaillinks_bool_exp[] | null;
  _not?: mail_emaillinks_bool_exp | null;
  _or?: mail_emaillinks_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  email?: mail_emails_bool_exp | null;
  emailid?: uuid_comparison_exp | null;
  foreignlinkentity?: String_comparison_exp | null;
  foreignlinkid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
}

export interface mail_emaillogs_aggregate_bool_exp {
  count?: mail_emaillogs_aggregate_bool_exp_count | null;
}

export interface mail_emaillogs_aggregate_bool_exp_count {
  arguments?: mail_emaillogs_select_column[] | null;
  distinct?: boolean | null;
  filter?: mail_emaillogs_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "mail.emaillogs". All fields are combined with a logical 'AND'.
 */
export interface mail_emaillogs_bool_exp {
  _and?: mail_emaillogs_bool_exp[] | null;
  _not?: mail_emaillogs_bool_exp | null;
  _or?: mail_emaillogs_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  deliveryattempts?: Int_comparison_exp | null;
  emailrecipient?: mail_emailrecipients_bool_exp | null;
  emailrecipientid?: uuid_comparison_exp | null;
  event?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  message?: String_comparison_exp | null;
  messageid?: String_comparison_exp | null;
  timestamp?: timestamp_comparison_exp | null;
  type?: String_comparison_exp | null;
}

export interface mail_emailprofiles_aggregate_bool_exp {
  count?: mail_emailprofiles_aggregate_bool_exp_count | null;
}

export interface mail_emailprofiles_aggregate_bool_exp_count {
  arguments?: mail_emailprofiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: mail_emailprofiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "mail.emailprofiles". All fields are combined with a logical 'AND'.
 */
export interface mail_emailprofiles_bool_exp {
  _and?: mail_emailprofiles_bool_exp[] | null;
  _not?: mail_emailprofiles_bool_exp | null;
  _or?: mail_emailprofiles_bool_exp[] | null;
  authenticationtype?: Int_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  displayname?: String_comparison_exp | null;
  emailaddress?: String_comparison_exp | null;
  hostname?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  password?: String_comparison_exp | null;
  port?: Int_comparison_exp | null;
  replyemailaddress?: String_comparison_exp | null;
  tcpsecurityoptions?: Int_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  userid?: uuid_comparison_exp | null;
  username?: String_comparison_exp | null;
}

export interface mail_emailrecipients_aggregate_bool_exp {
  count?: mail_emailrecipients_aggregate_bool_exp_count | null;
}

export interface mail_emailrecipients_aggregate_bool_exp_count {
  arguments?: mail_emailrecipients_select_column[] | null;
  distinct?: boolean | null;
  filter?: mail_emailrecipients_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "mail.emailrecipients". All fields are combined with a logical 'AND'.
 */
export interface mail_emailrecipients_bool_exp {
  _and?: mail_emailrecipients_bool_exp[] | null;
  _not?: mail_emailrecipients_bool_exp | null;
  _or?: mail_emailrecipients_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  email?: mail_emails_bool_exp | null;
  emailaddress?: String_comparison_exp | null;
  emaildisplayname?: String_comparison_exp | null;
  emailid?: uuid_comparison_exp | null;
  emaillogs?: mail_emaillogs_bool_exp | null;
  emaillogs_aggregate?: mail_emaillogs_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  type?: Int_comparison_exp | null;
}

export interface mail_emails_aggregate_bool_exp {
  count?: mail_emails_aggregate_bool_exp_count | null;
}

export interface mail_emails_aggregate_bool_exp_count {
  arguments?: mail_emails_select_column[] | null;
  distinct?: boolean | null;
  filter?: mail_emails_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "mail.emails". All fields are combined with a logical 'AND'.
 */
export interface mail_emails_bool_exp {
  _and?: mail_emails_bool_exp[] | null;
  _not?: mail_emails_bool_exp | null;
  _or?: mail_emails_bool_exp[] | null;
  attachments?: common_datafilelinks_bool_exp | null;
  attachments_aggregate?: common_datafilelinks_aggregate_bool_exp | null;
  body?: String_comparison_exp | null;
  bodyformat?: Int_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  emaillinks?: mail_emaillinks_bool_exp | null;
  emaillinks_aggregate?: mail_emaillinks_aggregate_bool_exp | null;
  emailrecipients?: mail_emailrecipients_bool_exp | null;
  emailrecipients_aggregate?: mail_emailrecipients_aggregate_bool_exp | null;
  emailtype?: Int_comparison_exp | null;
  from?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  inserted?: timestamp_comparison_exp | null;
  state?: Int_comparison_exp | null;
  subject?: String_comparison_exp | null;
  trackingid?: String_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  userid?: uuid_comparison_exp | null;
}

export interface news_news_aggregate_bool_exp {
  count?: news_news_aggregate_bool_exp_count | null;
}

export interface news_news_aggregate_bool_exp_count {
  arguments?: news_news_select_column[] | null;
  distinct?: boolean | null;
  filter?: news_news_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "news.news". All fields are combined with a logical 'AND'.
 */
export interface news_news_bool_exp {
  _and?: news_news_bool_exp[] | null;
  _not?: news_news_bool_exp | null;
  _or?: news_news_bool_exp[] | null;
  author?: uuid_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  inserted?: timestamp_comparison_exp | null;
  news_mls?: news_news_mls_bool_exp | null;
  news_mls_aggregate?: news_news_mls_aggregate_bool_exp | null;
  newsevents?: noti_newsevents_bool_exp | null;
  newsevents_aggregate?: noti_newsevents_aggregate_bool_exp | null;
  newsfiles?: file_newsfiles_bool_exp | null;
  newsfiles_aggregate?: file_newsfiles_aggregate_bool_exp | null;
  newsrealestates?: news_newsrealestates_bool_exp | null;
  newsrealestates_aggregate?: news_newsrealestates_aggregate_bool_exp | null;
  newsuseraudits?: news_newsuseraudits_bool_exp | null;
  newsuseraudits_aggregate?: news_newsuseraudits_aggregate_bool_exp | null;
  newsusers?: news_newsusers_bool_exp | null;
  newsusers_aggregate?: news_newsusers_aggregate_bool_exp | null;
  state?: Int_comparison_exp | null;
  timestamp?: timestamp_comparison_exp | null;
}

/**
 * input type for inserting data into table "news.news"
 */
export interface news_news_insert_input {
  author?: any | null;
  id?: any | null;
  news_mls?: news_news_mls_arr_rel_insert_input | null;
  newsevents?: noti_newsevents_arr_rel_insert_input | null;
  newsfiles?: file_newsfiles_arr_rel_insert_input | null;
  newsrealestates?: news_newsrealestates_arr_rel_insert_input | null;
  newsusers?: news_newsusers_arr_rel_insert_input | null;
  state?: number | null;
  timestamp?: any | null;
}

export interface news_news_mls_aggregate_bool_exp {
  count?: news_news_mls_aggregate_bool_exp_count | null;
}

export interface news_news_mls_aggregate_bool_exp_count {
  arguments?: news_news_mls_select_column[] | null;
  distinct?: boolean | null;
  filter?: news_news_mls_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "news.news_mls"
 */
export interface news_news_mls_arr_rel_insert_input {
  data: news_news_mls_insert_input[];
  on_conflict?: news_news_mls_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "news.news_mls". All fields are combined with a logical 'AND'.
 */
export interface news_news_mls_bool_exp {
  _and?: news_news_mls_bool_exp[] | null;
  _not?: news_news_mls_bool_exp | null;
  _or?: news_news_mls_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  lead?: String_comparison_exp | null;
  news?: news_news_bool_exp | null;
  newsid?: uuid_comparison_exp | null;
  text?: String_comparison_exp | null;
  title?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "news.news_mls"
 */
export interface news_news_mls_insert_input {
  id?: any | null;
  language?: string | null;
  lead?: string | null;
  news?: news_news_obj_rel_insert_input | null;
  newsid?: any | null;
  text?: string | null;
  title?: string | null;
}

/**
 * on_conflict condition type for table "news.news_mls"
 */
export interface news_news_mls_on_conflict {
  constraint: news_news_mls_constraint;
  update_columns: news_news_mls_update_column[];
  where?: news_news_mls_bool_exp | null;
}

/**
 * input type for inserting object relation for remote table "news.news"
 */
export interface news_news_obj_rel_insert_input {
  data: news_news_insert_input;
  on_conflict?: news_news_on_conflict | null;
}

/**
 * on_conflict condition type for table "news.news"
 */
export interface news_news_on_conflict {
  constraint: news_news_constraint;
  update_columns: news_news_update_column[];
  where?: news_news_bool_exp | null;
}

export interface news_newsrealestates_aggregate_bool_exp {
  count?: news_newsrealestates_aggregate_bool_exp_count | null;
}

export interface news_newsrealestates_aggregate_bool_exp_count {
  arguments?: news_newsrealestates_select_column[] | null;
  distinct?: boolean | null;
  filter?: news_newsrealestates_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "news.newsrealestates"
 */
export interface news_newsrealestates_arr_rel_insert_input {
  data: news_newsrealestates_insert_input[];
  on_conflict?: news_newsrealestates_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "news.newsrealestates". All fields are combined with a logical 'AND'.
 */
export interface news_newsrealestates_bool_exp {
  _and?: news_newsrealestates_bool_exp[] | null;
  _not?: news_newsrealestates_bool_exp | null;
  _or?: news_newsrealestates_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  news?: news_news_bool_exp | null;
  newsid?: uuid_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "news.newsrealestates"
 */
export interface news_newsrealestates_insert_input {
  id?: any | null;
  news?: news_news_obj_rel_insert_input | null;
  newsid?: any | null;
  realestateid?: any | null;
}

/**
 * on_conflict condition type for table "news.newsrealestates"
 */
export interface news_newsrealestates_on_conflict {
  constraint: news_newsrealestates_constraint;
  update_columns: news_newsrealestates_update_column[];
  where?: news_newsrealestates_bool_exp | null;
}

export interface news_newsuseraudits_aggregate_bool_exp {
  count?: news_newsuseraudits_aggregate_bool_exp_count | null;
}

export interface news_newsuseraudits_aggregate_bool_exp_count {
  arguments?: news_newsuseraudits_select_column[] | null;
  distinct?: boolean | null;
  filter?: news_newsuseraudits_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "news.newsuseraudits". All fields are combined with a logical 'AND'.
 */
export interface news_newsuseraudits_bool_exp {
  _and?: news_newsuseraudits_bool_exp[] | null;
  _not?: news_newsuseraudits_bool_exp | null;
  _or?: news_newsuseraudits_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  event?: Int_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  news?: news_news_bool_exp | null;
  newsid?: uuid_comparison_exp | null;
  timestamp?: timestamp_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  userid?: uuid_comparison_exp | null;
}

export interface news_newsusers_aggregate_bool_exp {
  count?: news_newsusers_aggregate_bool_exp_count | null;
}

export interface news_newsusers_aggregate_bool_exp_count {
  arguments?: news_newsusers_select_column[] | null;
  distinct?: boolean | null;
  filter?: news_newsusers_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "news.newsusers"
 */
export interface news_newsusers_arr_rel_insert_input {
  data: news_newsusers_insert_input[];
  on_conflict?: news_newsusers_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "news.newsusers". All fields are combined with a logical 'AND'.
 */
export interface news_newsusers_bool_exp {
  _and?: news_newsusers_bool_exp[] | null;
  _not?: news_newsusers_bool_exp | null;
  _or?: news_newsusers_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  news?: news_news_bool_exp | null;
  newsid?: uuid_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  userid?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "news.newsusers"
 */
export interface news_newsusers_insert_input {
  id?: any | null;
  news?: news_news_obj_rel_insert_input | null;
  newsid?: any | null;
  userid?: any | null;
}

/**
 * on_conflict condition type for table "news.newsusers"
 */
export interface news_newsusers_on_conflict {
  constraint: news_newsusers_constraint;
  update_columns: news_newsusers_update_column[];
  where?: news_newsusers_bool_exp | null;
}

export interface noti_assemblyevents_aggregate_bool_exp {
  count?: noti_assemblyevents_aggregate_bool_exp_count | null;
}

export interface noti_assemblyevents_aggregate_bool_exp_count {
  arguments?: noti_assemblyevents_select_column[] | null;
  distinct?: boolean | null;
  filter?: noti_assemblyevents_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "noti.assemblyevents"
 */
export interface noti_assemblyevents_arr_rel_insert_input {
  data: noti_assemblyevents_insert_input[];
  on_conflict?: noti_assemblyevents_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "noti.assemblyevents". All fields are combined with a logical 'AND'.
 */
export interface noti_assemblyevents_bool_exp {
  _and?: noti_assemblyevents_bool_exp[] | null;
  _not?: noti_assemblyevents_bool_exp | null;
  _or?: noti_assemblyevents_bool_exp[] | null;
  assembly?: cond_assemblys_bool_exp | null;
  assemblyid?: uuid_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  event?: noti_events_bool_exp | null;
  eventid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  inserted?: timestamp_comparison_exp | null;
}

/**
 * input type for inserting data into table "noti.assemblyevents"
 */
export interface noti_assemblyevents_insert_input {
  assembly?: cond_assemblys_obj_rel_insert_input | null;
  assemblyid?: any | null;
  event?: noti_events_obj_rel_insert_input | null;
  eventid?: any | null;
  id?: any | null;
}

/**
 * on_conflict condition type for table "noti.assemblyevents"
 */
export interface noti_assemblyevents_on_conflict {
  constraint: noti_assemblyevents_constraint;
  update_columns: noti_assemblyevents_update_column[];
  where?: noti_assemblyevents_bool_exp | null;
}

export interface noti_eventaudits_aggregate_bool_exp {
  count?: noti_eventaudits_aggregate_bool_exp_count | null;
}

export interface noti_eventaudits_aggregate_bool_exp_count {
  arguments?: noti_eventaudits_select_column[] | null;
  distinct?: boolean | null;
  filter?: noti_eventaudits_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "noti.eventaudits"
 */
export interface noti_eventaudits_arr_rel_insert_input {
  data: noti_eventaudits_insert_input[];
  on_conflict?: noti_eventaudits_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "noti.eventaudits". All fields are combined with a logical 'AND'.
 */
export interface noti_eventaudits_bool_exp {
  _and?: noti_eventaudits_bool_exp[] | null;
  _not?: noti_eventaudits_bool_exp | null;
  _or?: noti_eventaudits_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  event?: Int_comparison_exp | null;
  eventByCustomeridEventid?: noti_events_bool_exp | null;
  eventid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  timestamp?: timestamp_comparison_exp | null;
}

/**
 * input type for inserting data into table "noti.eventaudits"
 */
export interface noti_eventaudits_insert_input {
  event?: number | null;
  eventByCustomeridEventid?: noti_events_obj_rel_insert_input | null;
  eventid?: any | null;
  id?: any | null;
  timestamp?: any | null;
}

/**
 * on_conflict condition type for table "noti.eventaudits"
 */
export interface noti_eventaudits_on_conflict {
  constraint: noti_eventaudits_constraint;
  update_columns: noti_eventaudits_update_column[];
  where?: noti_eventaudits_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "noti.events". All fields are combined with a logical 'AND'.
 */
export interface noti_events_bool_exp {
  _and?: noti_events_bool_exp[] | null;
  _not?: noti_events_bool_exp | null;
  _or?: noti_events_bool_exp[] | null;
  assemblyevents?: noti_assemblyevents_bool_exp | null;
  assemblyevents_aggregate?: noti_assemblyevents_aggregate_bool_exp | null;
  customer?: ums_customers_bool_exp | null;
  eventaudits?: noti_eventaudits_bool_exp | null;
  eventaudits_aggregate?: noti_eventaudits_aggregate_bool_exp | null;
  fileevents?: noti_fileevents_bool_exp | null;
  fileevents_aggregate?: noti_fileevents_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  newsevents?: noti_newsevents_bool_exp | null;
  newsevents_aggregate?: noti_newsevents_aggregate_bool_exp | null;
  state?: Int_comparison_exp | null;
  type?: Int_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  userid?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "noti.events"
 */
export interface noti_events_insert_input {
  assemblyevents?: noti_assemblyevents_arr_rel_insert_input | null;
  eventaudits?: noti_eventaudits_arr_rel_insert_input | null;
  fileevents?: noti_fileevents_arr_rel_insert_input | null;
  id?: any | null;
  newsevents?: noti_newsevents_arr_rel_insert_input | null;
  state?: number | null;
  type?: number | null;
  userid?: any | null;
}

/**
 * input type for inserting object relation for remote table "noti.events"
 */
export interface noti_events_obj_rel_insert_input {
  data: noti_events_insert_input;
  on_conflict?: noti_events_on_conflict | null;
}

/**
 * on_conflict condition type for table "noti.events"
 */
export interface noti_events_on_conflict {
  constraint: noti_events_constraint;
  update_columns: noti_events_update_column[];
  where?: noti_events_bool_exp | null;
}

export interface noti_fileevents_aggregate_bool_exp {
  count?: noti_fileevents_aggregate_bool_exp_count | null;
}

export interface noti_fileevents_aggregate_bool_exp_count {
  arguments?: noti_fileevents_select_column[] | null;
  distinct?: boolean | null;
  filter?: noti_fileevents_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "noti.fileevents"
 */
export interface noti_fileevents_arr_rel_insert_input {
  data: noti_fileevents_insert_input[];
  on_conflict?: noti_fileevents_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "noti.fileevents". All fields are combined with a logical 'AND'.
 */
export interface noti_fileevents_bool_exp {
  _and?: noti_fileevents_bool_exp[] | null;
  _not?: noti_fileevents_bool_exp | null;
  _or?: noti_fileevents_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  event?: noti_events_bool_exp | null;
  eventid?: uuid_comparison_exp | null;
  file?: file_files_bool_exp | null;
  fileid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "noti.fileevents"
 */
export interface noti_fileevents_insert_input {
  event?: noti_events_obj_rel_insert_input | null;
  eventid?: any | null;
  file?: file_files_obj_rel_insert_input | null;
  fileid?: any | null;
  id?: any | null;
}

/**
 * on_conflict condition type for table "noti.fileevents"
 */
export interface noti_fileevents_on_conflict {
  constraint: noti_fileevents_constraint;
  update_columns: noti_fileevents_update_column[];
  where?: noti_fileevents_bool_exp | null;
}

export interface noti_newsevents_aggregate_bool_exp {
  count?: noti_newsevents_aggregate_bool_exp_count | null;
}

export interface noti_newsevents_aggregate_bool_exp_count {
  arguments?: noti_newsevents_select_column[] | null;
  distinct?: boolean | null;
  filter?: noti_newsevents_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "noti.newsevents"
 */
export interface noti_newsevents_arr_rel_insert_input {
  data: noti_newsevents_insert_input[];
  on_conflict?: noti_newsevents_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "noti.newsevents". All fields are combined with a logical 'AND'.
 */
export interface noti_newsevents_bool_exp {
  _and?: noti_newsevents_bool_exp[] | null;
  _not?: noti_newsevents_bool_exp | null;
  _or?: noti_newsevents_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  event?: noti_events_bool_exp | null;
  eventid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  inserted?: timestamp_comparison_exp | null;
  news?: news_news_bool_exp | null;
  newsid?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "noti.newsevents"
 */
export interface noti_newsevents_insert_input {
  event?: noti_events_obj_rel_insert_input | null;
  eventid?: any | null;
  id?: any | null;
  inserted?: any | null;
  news?: news_news_obj_rel_insert_input | null;
  newsid?: any | null;
}

/**
 * on_conflict condition type for table "noti.newsevents"
 */
export interface noti_newsevents_on_conflict {
  constraint: noti_newsevents_constraint;
  update_columns: noti_newsevents_update_column[];
  where?: noti_newsevents_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'.
 */
export interface numeric_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

export interface portal_accountlists_aggregate_bool_exp {
  count?: portal_accountlists_aggregate_bool_exp_count | null;
}

export interface portal_accountlists_aggregate_bool_exp_count {
  arguments?: portal_accountlists_select_column[] | null;
  distinct?: boolean | null;
  filter?: portal_accountlists_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "portal.accountlists". All fields are combined with a logical 'AND'.
 */
export interface portal_accountlists_bool_exp {
  _and?: portal_accountlists_bool_exp[] | null;
  _not?: portal_accountlists_bool_exp | null;
  _or?: portal_accountlists_bool_exp[] | null;
  accountlistfiles?: file_accountlistfiles_bool_exp | null;
  accountlistfiles_aggregate?: file_accountlistfiles_aggregate_bool_exp | null;
  accounts?: portal_accounts_bool_exp | null;
  accounts_aggregate?: portal_accounts_aggregate_bool_exp | null;
  customer?: ums_customers_bool_exp | null;
  enddate?: timestamp_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  sortdate?: timestamp_comparison_exp | null;
  startdate?: timestamp_comparison_exp | null;
  state?: Int_comparison_exp | null;
  subtitle?: String_comparison_exp | null;
  syncdate?: timestamp_comparison_exp | null;
  title?: String_comparison_exp | null;
  type?: Int_comparison_exp | null;
}

export interface portal_accounts_aggregate_bool_exp {
  avg?: portal_accounts_aggregate_bool_exp_avg | null;
  corr?: portal_accounts_aggregate_bool_exp_corr | null;
  count?: portal_accounts_aggregate_bool_exp_count | null;
  covar_samp?: portal_accounts_aggregate_bool_exp_covar_samp | null;
  max?: portal_accounts_aggregate_bool_exp_max | null;
  min?: portal_accounts_aggregate_bool_exp_min | null;
  stddev_samp?: portal_accounts_aggregate_bool_exp_stddev_samp | null;
  sum?: portal_accounts_aggregate_bool_exp_sum | null;
  var_samp?: portal_accounts_aggregate_bool_exp_var_samp | null;
}

export interface portal_accounts_aggregate_bool_exp_avg {
  arguments: portal_accounts_select_column_portal_accounts_aggregate_bool_exp_avg_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_accounts_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_accounts_aggregate_bool_exp_corr {
  arguments: portal_accounts_aggregate_bool_exp_corr_arguments;
  distinct?: boolean | null;
  filter?: portal_accounts_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_accounts_aggregate_bool_exp_corr_arguments {
  X: portal_accounts_select_column_portal_accounts_aggregate_bool_exp_corr_arguments_columns;
  Y: portal_accounts_select_column_portal_accounts_aggregate_bool_exp_corr_arguments_columns;
}

export interface portal_accounts_aggregate_bool_exp_count {
  arguments?: portal_accounts_select_column[] | null;
  distinct?: boolean | null;
  filter?: portal_accounts_bool_exp | null;
  predicate: Int_comparison_exp;
}

export interface portal_accounts_aggregate_bool_exp_covar_samp {
  arguments: portal_accounts_aggregate_bool_exp_covar_samp_arguments;
  distinct?: boolean | null;
  filter?: portal_accounts_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_accounts_aggregate_bool_exp_covar_samp_arguments {
  X: portal_accounts_select_column_portal_accounts_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: portal_accounts_select_column_portal_accounts_aggregate_bool_exp_covar_samp_arguments_columns;
}

export interface portal_accounts_aggregate_bool_exp_max {
  arguments: portal_accounts_select_column_portal_accounts_aggregate_bool_exp_max_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_accounts_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_accounts_aggregate_bool_exp_min {
  arguments: portal_accounts_select_column_portal_accounts_aggregate_bool_exp_min_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_accounts_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_accounts_aggregate_bool_exp_stddev_samp {
  arguments: portal_accounts_select_column_portal_accounts_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_accounts_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_accounts_aggregate_bool_exp_sum {
  arguments: portal_accounts_select_column_portal_accounts_aggregate_bool_exp_sum_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_accounts_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_accounts_aggregate_bool_exp_var_samp {
  arguments: portal_accounts_select_column_portal_accounts_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_accounts_bool_exp | null;
  predicate: float8_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "portal.accounts". All fields are combined with a logical 'AND'.
 */
export interface portal_accounts_bool_exp {
  _and?: portal_accounts_bool_exp[] | null;
  _not?: portal_accounts_bool_exp | null;
  _or?: portal_accounts_bool_exp[] | null;
  account?: portal_accounts_bool_exp | null;
  accountlist?: portal_accountlists_bool_exp | null;
  accountlistid?: uuid_comparison_exp | null;
  accounts?: portal_accounts_bool_exp | null;
  accounts_aggregate?: portal_accounts_aggregate_bool_exp | null;
  bookings?: portal_bookings_bool_exp | null;
  bookingsByContraaccountidCustomerid?: portal_bookings_bool_exp | null;
  bookingsByContraaccountidCustomerid_aggregate?: portal_bookings_aggregate_bool_exp | null;
  bookings_aggregate?: portal_bookings_aggregate_bool_exp | null;
  credit?: float8_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  debit?: float8_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  number?: String_comparison_exp | null;
  parentaccountid?: uuid_comparison_exp | null;
  secondarysort?: Int_comparison_exp | null;
  sort?: Int_comparison_exp | null;
  title?: String_comparison_exp | null;
  type?: Int_comparison_exp | null;
}

export interface portal_bookings_aggregate_bool_exp {
  avg?: portal_bookings_aggregate_bool_exp_avg | null;
  bool_and?: portal_bookings_aggregate_bool_exp_bool_and | null;
  bool_or?: portal_bookings_aggregate_bool_exp_bool_or | null;
  corr?: portal_bookings_aggregate_bool_exp_corr | null;
  count?: portal_bookings_aggregate_bool_exp_count | null;
  covar_samp?: portal_bookings_aggregate_bool_exp_covar_samp | null;
  max?: portal_bookings_aggregate_bool_exp_max | null;
  min?: portal_bookings_aggregate_bool_exp_min | null;
  stddev_samp?: portal_bookings_aggregate_bool_exp_stddev_samp | null;
  sum?: portal_bookings_aggregate_bool_exp_sum | null;
  var_samp?: portal_bookings_aggregate_bool_exp_var_samp | null;
}

export interface portal_bookings_aggregate_bool_exp_avg {
  arguments: portal_bookings_select_column_portal_bookings_aggregate_bool_exp_avg_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_bookings_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_bookings_aggregate_bool_exp_bool_and {
  arguments: portal_bookings_select_column_portal_bookings_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_bookings_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface portal_bookings_aggregate_bool_exp_bool_or {
  arguments: portal_bookings_select_column_portal_bookings_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_bookings_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface portal_bookings_aggregate_bool_exp_corr {
  arguments: portal_bookings_aggregate_bool_exp_corr_arguments;
  distinct?: boolean | null;
  filter?: portal_bookings_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_bookings_aggregate_bool_exp_corr_arguments {
  X: portal_bookings_select_column_portal_bookings_aggregate_bool_exp_corr_arguments_columns;
  Y: portal_bookings_select_column_portal_bookings_aggregate_bool_exp_corr_arguments_columns;
}

export interface portal_bookings_aggregate_bool_exp_count {
  arguments?: portal_bookings_select_column[] | null;
  distinct?: boolean | null;
  filter?: portal_bookings_bool_exp | null;
  predicate: Int_comparison_exp;
}

export interface portal_bookings_aggregate_bool_exp_covar_samp {
  arguments: portal_bookings_aggregate_bool_exp_covar_samp_arguments;
  distinct?: boolean | null;
  filter?: portal_bookings_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_bookings_aggregate_bool_exp_covar_samp_arguments {
  X: portal_bookings_select_column_portal_bookings_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: portal_bookings_select_column_portal_bookings_aggregate_bool_exp_covar_samp_arguments_columns;
}

export interface portal_bookings_aggregate_bool_exp_max {
  arguments: portal_bookings_select_column_portal_bookings_aggregate_bool_exp_max_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_bookings_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_bookings_aggregate_bool_exp_min {
  arguments: portal_bookings_select_column_portal_bookings_aggregate_bool_exp_min_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_bookings_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_bookings_aggregate_bool_exp_stddev_samp {
  arguments: portal_bookings_select_column_portal_bookings_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_bookings_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_bookings_aggregate_bool_exp_sum {
  arguments: portal_bookings_select_column_portal_bookings_aggregate_bool_exp_sum_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_bookings_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_bookings_aggregate_bool_exp_var_samp {
  arguments: portal_bookings_select_column_portal_bookings_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_bookings_bool_exp | null;
  predicate: float8_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "portal.bookings". All fields are combined with a logical 'AND'.
 */
export interface portal_bookings_bool_exp {
  _and?: portal_bookings_bool_exp[] | null;
  _not?: portal_bookings_bool_exp | null;
  _or?: portal_bookings_bool_exp[] | null;
  account?: portal_accounts_bool_exp | null;
  accountByContraaccountidCustomerid?: portal_accounts_bool_exp | null;
  accountid?: uuid_comparison_exp | null;
  bookingfiles?: file_bookingfiles_bool_exp | null;
  bookingfiles_aggregate?: file_bookingfiles_aggregate_bool_exp | null;
  bookingsdatafiles?: common_datafilelinks_bool_exp | null;
  bookingsdatafiles_aggregate?: common_datafilelinks_aggregate_bool_exp | null;
  cancellation?: Boolean_comparison_exp | null;
  contraaccountid?: uuid_comparison_exp | null;
  credit?: float8_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  date?: timestamp_comparison_exp | null;
  debit?: float8_comparison_exp | null;
  documentnumber?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  maintenances?: common_maintenances_bool_exp | null;
  maintenances_aggregate?: common_maintenances_aggregate_bool_exp | null;
  quantity?: float8_comparison_exp | null;
  text?: String_comparison_exp | null;
  v_bookingfiles?: file_v_bookingfiles_files_bool_exp | null;
  v_bookingfiles_aggregate?: file_v_bookingfiles_files_aggregate_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "portal.tenantbookings". All fields are combined with a logical 'AND'.
 */
export interface portal_tenantbookings_bool_exp {
  _and?: portal_tenantbookings_bool_exp[] | null;
  _not?: portal_tenantbookings_bool_exp | null;
  _or?: portal_tenantbookings_bool_exp[] | null;
  cancellation?: Boolean_comparison_exp | null;
  credit?: float8_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  date?: timestamp_comparison_exp | null;
  debit?: float8_comparison_exp | null;
  documentnumber?: String_comparison_exp | null;
  duedate?: date_comparison_exp | null;
  hash?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  quantity?: float8_comparison_exp | null;
  tenant?: common_tenants_bool_exp | null;
  tenantbookingfiles?: file_tenantbookingfiles_bool_exp | null;
  tenantbookingfiles_aggregate?: file_tenantbookingfiles_aggregate_bool_exp | null;
  tenantid?: uuid_comparison_exp | null;
  text?: String_comparison_exp | null;
  type?: Int_comparison_exp | null;
}

export interface portal_tiledatasets_aggregate_bool_exp {
  count?: portal_tiledatasets_aggregate_bool_exp_count | null;
}

export interface portal_tiledatasets_aggregate_bool_exp_count {
  arguments?: portal_tiledatasets_select_column[] | null;
  distinct?: boolean | null;
  filter?: portal_tiledatasets_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "portal.tiledatasets". All fields are combined with a logical 'AND'.
 */
export interface portal_tiledatasets_bool_exp {
  _and?: portal_tiledatasets_bool_exp[] | null;
  _not?: portal_tiledatasets_bool_exp | null;
  _or?: portal_tiledatasets_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  sort?: Int_comparison_exp | null;
  tile?: portal_tiles_bool_exp | null;
  tiledatasets_mls?: portal_tiledatasets_mls_bool_exp | null;
  tiledatasets_mls_aggregate?: portal_tiledatasets_mls_aggregate_bool_exp | null;
  tiledatavalues?: portal_tiledatavalues_bool_exp | null;
  tiledatavalues_aggregate?: portal_tiledatavalues_aggregate_bool_exp | null;
  tileid?: uuid_comparison_exp | null;
}

export interface portal_tiledatasets_mls_aggregate_bool_exp {
  count?: portal_tiledatasets_mls_aggregate_bool_exp_count | null;
}

export interface portal_tiledatasets_mls_aggregate_bool_exp_count {
  arguments?: portal_tiledatasets_mls_select_column[] | null;
  distinct?: boolean | null;
  filter?: portal_tiledatasets_mls_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "portal.tiledatasets_mls". All fields are combined with a logical 'AND'.
 */
export interface portal_tiledatasets_mls_bool_exp {
  _and?: portal_tiledatasets_mls_bool_exp[] | null;
  _not?: portal_tiledatasets_mls_bool_exp | null;
  _or?: portal_tiledatasets_mls_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  label?: String_comparison_exp | null;
  language?: String_comparison_exp | null;
  subtitle?: String_comparison_exp | null;
  tiledataset?: portal_tiledatasets_bool_exp | null;
  tiledatasetid?: uuid_comparison_exp | null;
}

export interface portal_tiledatavalues_aggregate_bool_exp {
  avg?: portal_tiledatavalues_aggregate_bool_exp_avg | null;
  corr?: portal_tiledatavalues_aggregate_bool_exp_corr | null;
  count?: portal_tiledatavalues_aggregate_bool_exp_count | null;
  covar_samp?: portal_tiledatavalues_aggregate_bool_exp_covar_samp | null;
  max?: portal_tiledatavalues_aggregate_bool_exp_max | null;
  min?: portal_tiledatavalues_aggregate_bool_exp_min | null;
  stddev_samp?: portal_tiledatavalues_aggregate_bool_exp_stddev_samp | null;
  sum?: portal_tiledatavalues_aggregate_bool_exp_sum | null;
  var_samp?: portal_tiledatavalues_aggregate_bool_exp_var_samp | null;
}

export interface portal_tiledatavalues_aggregate_bool_exp_avg {
  arguments: portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_avg_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_tiledatavalues_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_tiledatavalues_aggregate_bool_exp_corr {
  arguments: portal_tiledatavalues_aggregate_bool_exp_corr_arguments;
  distinct?: boolean | null;
  filter?: portal_tiledatavalues_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_tiledatavalues_aggregate_bool_exp_corr_arguments {
  X: portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_corr_arguments_columns;
  Y: portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_corr_arguments_columns;
}

export interface portal_tiledatavalues_aggregate_bool_exp_count {
  arguments?: portal_tiledatavalues_select_column[] | null;
  distinct?: boolean | null;
  filter?: portal_tiledatavalues_bool_exp | null;
  predicate: Int_comparison_exp;
}

export interface portal_tiledatavalues_aggregate_bool_exp_covar_samp {
  arguments: portal_tiledatavalues_aggregate_bool_exp_covar_samp_arguments;
  distinct?: boolean | null;
  filter?: portal_tiledatavalues_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_tiledatavalues_aggregate_bool_exp_covar_samp_arguments {
  X: portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_covar_samp_arguments_columns;
}

export interface portal_tiledatavalues_aggregate_bool_exp_max {
  arguments: portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_max_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_tiledatavalues_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_tiledatavalues_aggregate_bool_exp_min {
  arguments: portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_min_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_tiledatavalues_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_tiledatavalues_aggregate_bool_exp_stddev_samp {
  arguments: portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_tiledatavalues_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_tiledatavalues_aggregate_bool_exp_sum {
  arguments: portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_sum_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_tiledatavalues_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_tiledatavalues_aggregate_bool_exp_var_samp {
  arguments: portal_tiledatavalues_select_column_portal_tiledatavalues_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_tiledatavalues_bool_exp | null;
  predicate: float8_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "portal.tiledatavalues". All fields are combined with a logical 'AND'.
 */
export interface portal_tiledatavalues_bool_exp {
  _and?: portal_tiledatavalues_bool_exp[] | null;
  _not?: portal_tiledatavalues_bool_exp | null;
  _or?: portal_tiledatavalues_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  group?: Int_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  sort?: Int_comparison_exp | null;
  tiledataset?: portal_tiledatasets_bool_exp | null;
  tiledatasetid?: uuid_comparison_exp | null;
  tiledatavalues_mls?: portal_tiledatavalues_mls_bool_exp | null;
  tiledatavalues_mls_aggregate?: portal_tiledatavalues_mls_aggregate_bool_exp | null;
  value?: float8_comparison_exp | null;
  valuetype?: Int_comparison_exp | null;
}

export interface portal_tiledatavalues_mls_aggregate_bool_exp {
  count?: portal_tiledatavalues_mls_aggregate_bool_exp_count | null;
}

export interface portal_tiledatavalues_mls_aggregate_bool_exp_count {
  arguments?: portal_tiledatavalues_mls_select_column[] | null;
  distinct?: boolean | null;
  filter?: portal_tiledatavalues_mls_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "portal.tiledatavalues_mls". All fields are combined with a logical 'AND'.
 */
export interface portal_tiledatavalues_mls_bool_exp {
  _and?: portal_tiledatavalues_mls_bool_exp[] | null;
  _not?: portal_tiledatavalues_mls_bool_exp | null;
  _or?: portal_tiledatavalues_mls_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  grouptitle?: String_comparison_exp | null;
  hint?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  label?: String_comparison_exp | null;
  language?: String_comparison_exp | null;
  tiledatavalue?: portal_tiledatavalues_bool_exp | null;
  tiledatavalueid?: uuid_comparison_exp | null;
}

export interface portal_tiles_aggregate_bool_exp {
  count?: portal_tiles_aggregate_bool_exp_count | null;
}

export interface portal_tiles_aggregate_bool_exp_count {
  arguments?: portal_tiles_select_column[] | null;
  distinct?: boolean | null;
  filter?: portal_tiles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "portal.tiles". All fields are combined with a logical 'AND'.
 */
export interface portal_tiles_bool_exp {
  _and?: portal_tiles_bool_exp[] | null;
  _not?: portal_tiles_bool_exp | null;
  _or?: portal_tiles_bool_exp[] | null;
  charttype?: Int_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  tiledatasets?: portal_tiledatasets_bool_exp | null;
  tiledatasets_aggregate?: portal_tiledatasets_aggregate_bool_exp | null;
  tiles_mls?: portal_tiles_mls_bool_exp | null;
  tiles_mls_aggregate?: portal_tiles_mls_aggregate_bool_exp | null;
  tilescreens?: portal_tilescreens_bool_exp | null;
  tilescreens_aggregate?: portal_tilescreens_aggregate_bool_exp | null;
  tiletype?: Int_comparison_exp | null;
}

export interface portal_tiles_mls_aggregate_bool_exp {
  count?: portal_tiles_mls_aggregate_bool_exp_count | null;
}

export interface portal_tiles_mls_aggregate_bool_exp_count {
  arguments?: portal_tiles_mls_select_column[] | null;
  distinct?: boolean | null;
  filter?: portal_tiles_mls_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "portal.tiles_mls". All fields are combined with a logical 'AND'.
 */
export interface portal_tiles_mls_bool_exp {
  _and?: portal_tiles_mls_bool_exp[] | null;
  _not?: portal_tiles_mls_bool_exp | null;
  _or?: portal_tiles_mls_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  tile?: portal_tiles_bool_exp | null;
  tileid?: uuid_comparison_exp | null;
  title?: String_comparison_exp | null;
}

export interface portal_tilescreens_aggregate_bool_exp {
  count?: portal_tilescreens_aggregate_bool_exp_count | null;
}

export interface portal_tilescreens_aggregate_bool_exp_count {
  arguments?: portal_tilescreens_select_column[] | null;
  distinct?: boolean | null;
  filter?: portal_tilescreens_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "portal.tilescreens". All fields are combined with a logical 'AND'.
 */
export interface portal_tilescreens_bool_exp {
  _and?: portal_tilescreens_bool_exp[] | null;
  _not?: portal_tilescreens_bool_exp | null;
  _or?: portal_tilescreens_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  screen?: Int_comparison_exp | null;
  sort?: Int_comparison_exp | null;
  tile?: portal_tiles_bool_exp | null;
  tileid?: uuid_comparison_exp | null;
}

export interface portal_v_tenancys_today_aggregate_bool_exp {
  avg?: portal_v_tenancys_today_aggregate_bool_exp_avg | null;
  bool_and?: portal_v_tenancys_today_aggregate_bool_exp_bool_and | null;
  bool_or?: portal_v_tenancys_today_aggregate_bool_exp_bool_or | null;
  corr?: portal_v_tenancys_today_aggregate_bool_exp_corr | null;
  count?: portal_v_tenancys_today_aggregate_bool_exp_count | null;
  covar_samp?: portal_v_tenancys_today_aggregate_bool_exp_covar_samp | null;
  max?: portal_v_tenancys_today_aggregate_bool_exp_max | null;
  min?: portal_v_tenancys_today_aggregate_bool_exp_min | null;
  stddev_samp?: portal_v_tenancys_today_aggregate_bool_exp_stddev_samp | null;
  sum?: portal_v_tenancys_today_aggregate_bool_exp_sum | null;
  var_samp?: portal_v_tenancys_today_aggregate_bool_exp_var_samp | null;
}

export interface portal_v_tenancys_today_aggregate_bool_exp_avg {
  arguments: portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_avg_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_v_tenancys_today_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_v_tenancys_today_aggregate_bool_exp_bool_and {
  arguments: portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_v_tenancys_today_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface portal_v_tenancys_today_aggregate_bool_exp_bool_or {
  arguments: portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_v_tenancys_today_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface portal_v_tenancys_today_aggregate_bool_exp_corr {
  arguments: portal_v_tenancys_today_aggregate_bool_exp_corr_arguments;
  distinct?: boolean | null;
  filter?: portal_v_tenancys_today_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_v_tenancys_today_aggregate_bool_exp_corr_arguments {
  X: portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_corr_arguments_columns;
  Y: portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_corr_arguments_columns;
}

export interface portal_v_tenancys_today_aggregate_bool_exp_count {
  arguments?: portal_v_tenancys_today_select_column[] | null;
  distinct?: boolean | null;
  filter?: portal_v_tenancys_today_bool_exp | null;
  predicate: Int_comparison_exp;
}

export interface portal_v_tenancys_today_aggregate_bool_exp_covar_samp {
  arguments: portal_v_tenancys_today_aggregate_bool_exp_covar_samp_arguments;
  distinct?: boolean | null;
  filter?: portal_v_tenancys_today_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_v_tenancys_today_aggregate_bool_exp_covar_samp_arguments {
  X: portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_covar_samp_arguments_columns;
}

export interface portal_v_tenancys_today_aggregate_bool_exp_max {
  arguments: portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_max_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_v_tenancys_today_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_v_tenancys_today_aggregate_bool_exp_min {
  arguments: portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_min_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_v_tenancys_today_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_v_tenancys_today_aggregate_bool_exp_stddev_samp {
  arguments: portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_v_tenancys_today_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_v_tenancys_today_aggregate_bool_exp_sum {
  arguments: portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_sum_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_v_tenancys_today_bool_exp | null;
  predicate: float8_comparison_exp;
}

export interface portal_v_tenancys_today_aggregate_bool_exp_var_samp {
  arguments: portal_v_tenancys_today_select_column_portal_v_tenancys_today_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: boolean | null;
  filter?: portal_v_tenancys_today_bool_exp | null;
  predicate: float8_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "portal.v_tenancys_today". All fields are combined with a logical 'AND'.
 */
export interface portal_v_tenancys_today_bool_exp {
  _and?: portal_v_tenancys_today_bool_exp[] | null;
  _not?: portal_v_tenancys_today_bool_exp | null;
  _or?: portal_v_tenancys_today_bool_exp[] | null;
  accountbalance?: numeric_comparison_exp | null;
  conditionid?: uuid_comparison_exp | null;
  consumerpriceindex?: timestamp_comparison_exp | null;
  costincreaseindex?: timestamp_comparison_exp | null;
  customerid?: uuid_comparison_exp | null;
  flatratefee?: numeric_comparison_exp | null;
  floor?: Int_comparison_exp | null;
  grossrent?: numeric_comparison_exp | null;
  houseid?: uuid_comparison_exp | null;
  interestrateindex?: float8_comparison_exp | null;
  leaseend?: timestamp_comparison_exp | null;
  leasestart?: timestamp_comparison_exp | null;
  maturities?: Int_comparison_exp | null;
  minimumrentdate?: timestamp_comparison_exp | null;
  name?: String_comparison_exp | null;
  netrent?: numeric_comparison_exp | null;
  noticemonths?: Int_comparison_exp | null;
  noticeperiodowner?: Int_comparison_exp | null;
  noticeperiodtenant?: Int_comparison_exp | null;
  number?: String_comparison_exp | null;
  ownerid?: uuid_comparison_exp | null;
  paymentonaccount?: numeric_comparison_exp | null;
  rentalsInTenancys?: rent_rentals_bool_exp | null;
  rentalsInTenancys_aggregate?: rent_rentals_aggregate_bool_exp | null;
  rentalsOutTenancys?: rent_rentals_bool_exp | null;
  rentalsOutTenancys_aggregate?: rent_rentals_aggregate_bool_exp | null;
  rentincreasereserveamount?: numeric_comparison_exp | null;
  rentincreasereservetext?: String_comparison_exp | null;
  rentsqmyear?: numeric_comparison_exp | null;
  rolename?: String_comparison_exp | null;
  rooms?: float8_comparison_exp | null;
  space?: float8_comparison_exp | null;
  tenancyid?: uuid_comparison_exp | null;
  tenancypersons?: common_tenancypersons_bool_exp | null;
  tenancypersons_aggregate?: common_tenancypersons_aggregate_bool_exp | null;
  tenancytype?: Int_comparison_exp | null;
  tenantid?: uuid_comparison_exp | null;
  tenantname?: String_comparison_exp | null;
  tenantpersonid?: uuid_comparison_exp | null;
  unitid?: uuid_comparison_exp | null;
  unittype?: Int_comparison_exp | null;
  userid?: uuid_comparison_exp | null;
  vacancy?: Boolean_comparison_exp | null;
  validfrom?: timestamp_comparison_exp | null;
  vat?: numeric_comparison_exp | null;
  vattaxed?: Boolean_comparison_exp | null;
}

export interface rent_rentalinvoices_aggregate_bool_exp {
  count?: rent_rentalinvoices_aggregate_bool_exp_count | null;
}

export interface rent_rentalinvoices_aggregate_bool_exp_count {
  arguments?: rent_rentalinvoices_select_column[] | null;
  distinct?: boolean | null;
  filter?: rent_rentalinvoices_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "rent.rentalinvoices". All fields are combined with a logical 'AND'.
 */
export interface rent_rentalinvoices_bool_exp {
  _and?: rent_rentalinvoices_bool_exp[] | null;
  _not?: rent_rentalinvoices_bool_exp | null;
  _or?: rent_rentalinvoices_bool_exp[] | null;
  date?: date_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  rental?: rent_rentals_bool_exp | null;
  rentalid?: uuid_comparison_exp | null;
}

export interface rent_rentals_aggregate_bool_exp {
  count?: rent_rentals_aggregate_bool_exp_count | null;
}

export interface rent_rentals_aggregate_bool_exp_count {
  arguments?: rent_rentals_select_column[] | null;
  distinct?: boolean | null;
  filter?: rent_rentals_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "rent.rentals". All fields are combined with a logical 'AND'.
 */
export interface rent_rentals_bool_exp {
  _and?: rent_rentals_bool_exp[] | null;
  _not?: rent_rentals_bool_exp | null;
  _or?: rent_rentals_bool_exp[] | null;
  completiondate?: timestamp_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  in_tenancyid?: uuid_comparison_exp | null;
  out_tenancyid?: uuid_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  rentaldate?: timestamp_comparison_exp | null;
  rentalinvoices?: rent_rentalinvoices_bool_exp | null;
  rentalinvoices_aggregate?: rent_rentalinvoices_aggregate_bool_exp | null;
  rentalplatform?: Int_comparison_exp | null;
  state?: Int_comparison_exp | null;
  tenancy?: common_tenancys_bool_exp | null;
  tenancyByCustomeridOutTenancyid?: common_tenancys_bool_exp | null;
  unit?: common_units_bool_exp | null;
  unitid?: uuid_comparison_exp | null;
}

export interface ticket_incidentcomments_aggregate_bool_exp {
  count?: ticket_incidentcomments_aggregate_bool_exp_count | null;
}

export interface ticket_incidentcomments_aggregate_bool_exp_count {
  arguments?: ticket_incidentcomments_select_column[] | null;
  distinct?: boolean | null;
  filter?: ticket_incidentcomments_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "ticket.incidentcomments"
 */
export interface ticket_incidentcomments_arr_rel_insert_input {
  data: ticket_incidentcomments_insert_input[];
  on_conflict?: ticket_incidentcomments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket.incidentcomments". All fields are combined with a logical 'AND'.
 */
export interface ticket_incidentcomments_bool_exp {
  _and?: ticket_incidentcomments_bool_exp[] | null;
  _not?: ticket_incidentcomments_bool_exp | null;
  _or?: ticket_incidentcomments_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  incident?: ticket_incidents_bool_exp | null;
  incidentid?: uuid_comparison_exp | null;
  inserted?: timestamp_comparison_exp | null;
  language?: String_comparison_exp | null;
  text?: String_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  userid?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket.incidentcomments"
 */
export interface ticket_incidentcomments_insert_input {
  incident?: ticket_incidents_obj_rel_insert_input | null;
  incidentid?: any | null;
  language?: string | null;
  text?: string | null;
  userid?: any | null;
}

/**
 * on_conflict condition type for table "ticket.incidentcomments"
 */
export interface ticket_incidentcomments_on_conflict {
  constraint: ticket_incidentcomments_constraint;
  update_columns: ticket_incidentcomments_update_column[];
  where?: ticket_incidentcomments_bool_exp | null;
}

export interface ticket_incidentpersons_aggregate_bool_exp {
  count?: ticket_incidentpersons_aggregate_bool_exp_count | null;
}

export interface ticket_incidentpersons_aggregate_bool_exp_count {
  arguments?: ticket_incidentpersons_select_column[] | null;
  distinct?: boolean | null;
  filter?: ticket_incidentpersons_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "ticket.incidentpersons"
 */
export interface ticket_incidentpersons_arr_rel_insert_input {
  data: ticket_incidentpersons_insert_input[];
  on_conflict?: ticket_incidentpersons_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket.incidentpersons". All fields are combined with a logical 'AND'.
 */
export interface ticket_incidentpersons_bool_exp {
  _and?: ticket_incidentpersons_bool_exp[] | null;
  _not?: ticket_incidentpersons_bool_exp | null;
  _or?: ticket_incidentpersons_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  incident?: ticket_incidents_bool_exp | null;
  incidentid?: uuid_comparison_exp | null;
  person?: common_persons_bool_exp | null;
  personid?: uuid_comparison_exp | null;
  role?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket.incidentpersons"
 */
export interface ticket_incidentpersons_insert_input {
  id?: any | null;
  incident?: ticket_incidents_obj_rel_insert_input | null;
  incidentid?: any | null;
  person?: common_persons_obj_rel_insert_input | null;
  personid?: any | null;
  role?: number | null;
}

/**
 * on_conflict condition type for table "ticket.incidentpersons"
 */
export interface ticket_incidentpersons_on_conflict {
  constraint: ticket_incidentpersons_constraint;
  update_columns: ticket_incidentpersons_update_column[];
  where?: ticket_incidentpersons_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ticket.incidents". All fields are combined with a logical 'AND'.
 */
export interface ticket_incidents_bool_exp {
  _and?: ticket_incidents_bool_exp[] | null;
  _not?: ticket_incidents_bool_exp | null;
  _or?: ticket_incidents_bool_exp[] | null;
  appliance?: common_appliances_bool_exp | null;
  applianceid?: uuid_comparison_exp | null;
  appliancetype?: Int_comparison_exp | null;
  assigneduseremail?: ums_v_users_email_bool_exp | null;
  assigneduserid?: uuid_comparison_exp | null;
  component?: ec_components_bool_exp | null;
  componentid?: uuid_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  date?: date_comparison_exp | null;
  history?: ticket_v_incident_history_bool_exp | null;
  history_aggregate?: ticket_v_incident_history_aggregate_bool_exp | null;
  house?: common_houses_bool_exp | null;
  houseid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  incidentcomments?: ticket_incidentcomments_bool_exp | null;
  incidentcomments_aggregate?: ticket_incidentcomments_aggregate_bool_exp | null;
  incidentfiles?: file_incidentfiles_bool_exp | null;
  incidentfiles_aggregate?: file_incidentfiles_aggregate_bool_exp | null;
  incidentpersons?: ticket_incidentpersons_bool_exp | null;
  incidentpersons_aggregate?: ticket_incidentpersons_aggregate_bool_exp | null;
  incidents_mls?: ticket_incidents_mls_bool_exp | null;
  incidents_mls_aggregate?: ticket_incidents_mls_aggregate_bool_exp | null;
  key?: jsonb_comparison_exp | null;
  manufacturer?: String_comparison_exp | null;
  nameplate?: jsonb_comparison_exp | null;
  notification?: ec_notifications_bool_exp | null;
  notificationaddress?: jsonb_comparison_exp | null;
  notificationid?: uuid_comparison_exp | null;
  number?: Int_comparison_exp | null;
  orders?: ticket_orders_bool_exp | null;
  orders_aggregate?: ticket_orders_aggregate_bool_exp | null;
  origin?: Int_comparison_exp | null;
  realestate?: common_realestates_bool_exp | null;
  realestateid?: uuid_comparison_exp | null;
  roomtype?: Int_comparison_exp | null;
  serialnumber?: String_comparison_exp | null;
  state?: Int_comparison_exp | null;
  tenancy?: common_tenancys_bool_exp | null;
  tenancyid?: uuid_comparison_exp | null;
  tenant?: common_tenants_bool_exp | null;
  tenantid?: uuid_comparison_exp | null;
  type?: Int_comparison_exp | null;
  unit?: common_units_bool_exp | null;
  unitid?: uuid_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  workflowinstance?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket.incidents"
 */
export interface ticket_incidents_insert_input {
  applianceid?: any | null;
  appliancetype?: number | null;
  assigneduserid?: any | null;
  componentid?: any | null;
  date?: any | null;
  houseid?: any | null;
  id?: any | null;
  incidentcomments?: ticket_incidentcomments_arr_rel_insert_input | null;
  incidentfiles?: file_incidentfiles_arr_rel_insert_input | null;
  incidentpersons?: ticket_incidentpersons_arr_rel_insert_input | null;
  incidents_mls?: ticket_incidents_mls_arr_rel_insert_input | null;
  key?: any | null;
  manufacturer?: string | null;
  nameplate?: any | null;
  notificationaddress?: any | null;
  notificationid?: any | null;
  number?: number | null;
  orders?: ticket_orders_arr_rel_insert_input | null;
  origin?: number | null;
  realestateid?: any | null;
  roomtype?: number | null;
  serialnumber?: string | null;
  state?: number | null;
  tenancyid?: any | null;
  tenantid?: any | null;
  type?: number | null;
  unitid?: any | null;
  workflowinstance?: any | null;
}

export interface ticket_incidents_mls_aggregate_bool_exp {
  count?: ticket_incidents_mls_aggregate_bool_exp_count | null;
}

export interface ticket_incidents_mls_aggregate_bool_exp_count {
  arguments?: ticket_incidents_mls_select_column[] | null;
  distinct?: boolean | null;
  filter?: ticket_incidents_mls_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "ticket.incidents_mls"
 */
export interface ticket_incidents_mls_arr_rel_insert_input {
  data: ticket_incidents_mls_insert_input[];
  on_conflict?: ticket_incidents_mls_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket.incidents_mls". All fields are combined with a logical 'AND'.
 */
export interface ticket_incidents_mls_bool_exp {
  _and?: ticket_incidents_mls_bool_exp[] | null;
  _not?: ticket_incidents_mls_bool_exp | null;
  _or?: ticket_incidents_mls_bool_exp[] | null;
  altcomponent?: String_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  description?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  incident?: ticket_incidents_bool_exp | null;
  incidentid?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  title?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket.incidents_mls"
 */
export interface ticket_incidents_mls_insert_input {
  altcomponent?: string | null;
  description?: string | null;
  id?: any | null;
  incident?: ticket_incidents_obj_rel_insert_input | null;
  incidentid?: any | null;
  language?: string | null;
  title?: string | null;
}

/**
 * on_conflict condition type for table "ticket.incidents_mls"
 */
export interface ticket_incidents_mls_on_conflict {
  constraint: ticket_incidents_mls_constraint;
  update_columns: ticket_incidents_mls_update_column[];
  where?: ticket_incidents_mls_bool_exp | null;
}

/**
 * input type for inserting object relation for remote table "ticket.incidents"
 */
export interface ticket_incidents_obj_rel_insert_input {
  data: ticket_incidents_insert_input;
  on_conflict?: ticket_incidents_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket.incidents"
 */
export interface ticket_incidents_on_conflict {
  constraint: ticket_incidents_constraint;
  update_columns: ticket_incidents_update_column[];
  where?: ticket_incidents_bool_exp | null;
}

export interface ticket_ordercomments_aggregate_bool_exp {
  count?: ticket_ordercomments_aggregate_bool_exp_count | null;
}

export interface ticket_ordercomments_aggregate_bool_exp_count {
  arguments?: ticket_ordercomments_select_column[] | null;
  distinct?: boolean | null;
  filter?: ticket_ordercomments_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "ticket.ordercomments"
 */
export interface ticket_ordercomments_arr_rel_insert_input {
  data: ticket_ordercomments_insert_input[];
  on_conflict?: ticket_ordercomments_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket.ordercomments". All fields are combined with a logical 'AND'.
 */
export interface ticket_ordercomments_bool_exp {
  _and?: ticket_ordercomments_bool_exp[] | null;
  _not?: ticket_ordercomments_bool_exp | null;
  _or?: ticket_ordercomments_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  inserted?: timestamp_comparison_exp | null;
  language?: String_comparison_exp | null;
  order?: ticket_orders_bool_exp | null;
  orderid?: uuid_comparison_exp | null;
  text?: String_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  userid?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket.ordercomments"
 */
export interface ticket_ordercomments_insert_input {
  language?: string | null;
  order?: ticket_orders_obj_rel_insert_input | null;
  orderid?: any | null;
  text?: string | null;
  userid?: any | null;
}

/**
 * on_conflict condition type for table "ticket.ordercomments"
 */
export interface ticket_ordercomments_on_conflict {
  constraint: ticket_ordercomments_constraint;
  update_columns: ticket_ordercomments_update_column[];
  where?: ticket_ordercomments_bool_exp | null;
}

export interface ticket_orders_aggregate_bool_exp {
  count?: ticket_orders_aggregate_bool_exp_count | null;
}

export interface ticket_orders_aggregate_bool_exp_count {
  arguments?: ticket_orders_select_column[] | null;
  distinct?: boolean | null;
  filter?: ticket_orders_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "ticket.orders"
 */
export interface ticket_orders_arr_rel_insert_input {
  data: ticket_orders_insert_input[];
  on_conflict?: ticket_orders_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket.orders". All fields are combined with a logical 'AND'.
 */
export interface ticket_orders_bool_exp {
  _and?: ticket_orders_bool_exp[] | null;
  _not?: ticket_orders_bool_exp | null;
  _or?: ticket_orders_bool_exp[] | null;
  billingaddress?: jsonb_comparison_exp | null;
  billingemail?: String_comparison_exp | null;
  contractorid?: uuid_comparison_exp | null;
  contractortype?: Int_comparison_exp | null;
  craft?: Int_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  date?: date_comparison_exp | null;
  history?: ticket_v_order_history_bool_exp | null;
  id?: uuid_comparison_exp | null;
  incident?: ticket_incidents_bool_exp | null;
  incidentid?: uuid_comparison_exp | null;
  number?: Int_comparison_exp | null;
  ordercomments?: ticket_ordercomments_bool_exp | null;
  ordercomments_aggregate?: ticket_ordercomments_aggregate_bool_exp | null;
  orderfiles?: file_orderfiles_bool_exp | null;
  orderfiles_aggregate?: file_orderfiles_aggregate_bool_exp | null;
  orders_mls?: ticket_orders_mls_bool_exp | null;
  orders_mls_aggregate?: ticket_orders_mls_aggregate_bool_exp | null;
  person?: common_persons_bool_exp | null;
  primarycontact?: uuid_comparison_exp | null;
  primarycontacttype?: Int_comparison_exp | null;
  state?: Int_comparison_exp | null;
  workflowinstance?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket.orders"
 */
export interface ticket_orders_insert_input {
  billingaddress?: any | null;
  billingemail?: string | null;
  contractorid?: any | null;
  contractortype?: number | null;
  craft?: number | null;
  date?: any | null;
  id?: any | null;
  incident?: ticket_incidents_obj_rel_insert_input | null;
  incidentid?: any | null;
  number?: number | null;
  ordercomments?: ticket_ordercomments_arr_rel_insert_input | null;
  orderfiles?: file_orderfiles_arr_rel_insert_input | null;
  orders_mls?: ticket_orders_mls_arr_rel_insert_input | null;
  person?: common_persons_obj_rel_insert_input | null;
  primarycontact?: any | null;
  primarycontacttype?: number | null;
  state?: number | null;
  workflowinstance?: any | null;
}

export interface ticket_orders_mls_aggregate_bool_exp {
  count?: ticket_orders_mls_aggregate_bool_exp_count | null;
}

export interface ticket_orders_mls_aggregate_bool_exp_count {
  arguments?: ticket_orders_mls_select_column[] | null;
  distinct?: boolean | null;
  filter?: ticket_orders_mls_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "ticket.orders_mls"
 */
export interface ticket_orders_mls_arr_rel_insert_input {
  data: ticket_orders_mls_insert_input[];
  on_conflict?: ticket_orders_mls_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "ticket.orders_mls". All fields are combined with a logical 'AND'.
 */
export interface ticket_orders_mls_bool_exp {
  _and?: ticket_orders_mls_bool_exp[] | null;
  _not?: ticket_orders_mls_bool_exp | null;
  _or?: ticket_orders_mls_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  instruction?: String_comparison_exp | null;
  language?: String_comparison_exp | null;
  order?: ticket_orders_bool_exp | null;
  orderid?: uuid_comparison_exp | null;
  title?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "ticket.orders_mls"
 */
export interface ticket_orders_mls_insert_input {
  id?: any | null;
  instruction?: string | null;
  language?: string | null;
  order?: ticket_orders_obj_rel_insert_input | null;
  orderid?: any | null;
  title?: string | null;
}

/**
 * on_conflict condition type for table "ticket.orders_mls"
 */
export interface ticket_orders_mls_on_conflict {
  constraint: ticket_orders_mls_constraint;
  update_columns: ticket_orders_mls_update_column[];
  where?: ticket_orders_mls_bool_exp | null;
}

/**
 * input type for inserting object relation for remote table "ticket.orders"
 */
export interface ticket_orders_obj_rel_insert_input {
  data: ticket_orders_insert_input;
  on_conflict?: ticket_orders_on_conflict | null;
}

/**
 * on_conflict condition type for table "ticket.orders"
 */
export interface ticket_orders_on_conflict {
  constraint: ticket_orders_constraint;
  update_columns: ticket_orders_update_column[];
  where?: ticket_orders_bool_exp | null;
}

export interface ticket_v_incident_history_aggregate_bool_exp {
  count?: ticket_v_incident_history_aggregate_bool_exp_count | null;
}

export interface ticket_v_incident_history_aggregate_bool_exp_count {
  arguments?: ticket_v_incident_history_select_column[] | null;
  distinct?: boolean | null;
  filter?: ticket_v_incident_history_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ticket.v_incident_history". All fields are combined with a logical 'AND'.
 */
export interface ticket_v_incident_history_bool_exp {
  _and?: ticket_v_incident_history_bool_exp[] | null;
  _not?: ticket_v_incident_history_bool_exp | null;
  _or?: ticket_v_incident_history_bool_exp[] | null;
  comment_language?: String_comparison_exp | null;
  comment_text?: String_comparison_exp | null;
  comment_userid?: uuid_comparison_exp | null;
  comment_username?: String_comparison_exp | null;
  customerid?: uuid_comparison_exp | null;
  email?: mail_emails_bool_exp | null;
  email_body?: String_comparison_exp | null;
  email_recipients?: String_comparison_exp | null;
  email_sender?: String_comparison_exp | null;
  email_subject?: String_comparison_exp | null;
  email_type?: Int_comparison_exp | null;
  entityid?: uuid_comparison_exp | null;
  history_user?: String_comparison_exp | null;
  history_username?: String_comparison_exp | null;
  inboundfiles?: file_inboundfiles_bool_exp | null;
  inboundfiles_aggregate?: file_inboundfiles_aggregate_bool_exp | null;
  incident?: ticket_incidents_bool_exp | null;
  incidentid?: uuid_comparison_exp | null;
  inserted?: timestamp_comparison_exp | null;
  new_values?: jsonb_comparison_exp | null;
  old_values?: jsonb_comparison_exp | null;
  operation?: String_comparison_exp | null;
  orderid?: uuid_comparison_exp | null;
  outboundemailrecipients?: mail_emailrecipients_bool_exp | null;
  outboundemailrecipients_aggregate?: mail_emailrecipients_aggregate_bool_exp | null;
  source?: String_comparison_exp | null;
  tablename?: String_comparison_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ticket.v_order_history". All fields are combined with a logical 'AND'.
 */
export interface ticket_v_order_history_bool_exp {
  _and?: ticket_v_order_history_bool_exp[] | null;
  _not?: ticket_v_order_history_bool_exp | null;
  _or?: ticket_v_order_history_bool_exp[] | null;
  comment_language?: String_comparison_exp | null;
  comment_text?: String_comparison_exp | null;
  comment_userid?: uuid_comparison_exp | null;
  comment_username?: String_comparison_exp | null;
  customerid?: uuid_comparison_exp | null;
  email_body?: String_comparison_exp | null;
  email_recipients?: String_comparison_exp | null;
  email_sender?: String_comparison_exp | null;
  email_subject?: String_comparison_exp | null;
  email_type?: Int_comparison_exp | null;
  entityid?: uuid_comparison_exp | null;
  history_user?: String_comparison_exp | null;
  history_username?: String_comparison_exp | null;
  incidentid?: uuid_comparison_exp | null;
  inserted?: timestamp_comparison_exp | null;
  new_values?: jsonb_comparison_exp | null;
  old_values?: jsonb_comparison_exp | null;
  operation?: String_comparison_exp | null;
  order?: ticket_orders_bool_exp | null;
  orderid?: uuid_comparison_exp | null;
  source?: String_comparison_exp | null;
  tablename?: String_comparison_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'.
 */
export interface timestamp_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * input type for inserting data into table "uccm.announcementaudits"
 */
export interface uccm_announcementaudits_insert_input {
  announcementid?: any | null;
  event?: number | null;
}

export interface ums_audits_aggregate_bool_exp {
  count?: ums_audits_aggregate_bool_exp_count | null;
}

export interface ums_audits_aggregate_bool_exp_count {
  arguments?: ums_audits_select_column[] | null;
  distinct?: boolean | null;
  filter?: ums_audits_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ums.audits". All fields are combined with a logical 'AND'.
 */
export interface ums_audits_bool_exp {
  _and?: ums_audits_bool_exp[] | null;
  _not?: ums_audits_bool_exp | null;
  _or?: ums_audits_bool_exp[] | null;
  clientid?: String_comparison_exp | null;
  event?: Int_comparison_exp | null;
  reason?: String_comparison_exp | null;
  timestamp?: timestamp_comparison_exp | null;
  userid?: uuid_comparison_exp | null;
}

export interface ums_connections_aggregate_bool_exp {
  count?: ums_connections_aggregate_bool_exp_count | null;
}

export interface ums_connections_aggregate_bool_exp_count {
  arguments?: ums_connections_select_column[] | null;
  distinct?: boolean | null;
  filter?: ums_connections_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ums.connections". All fields are combined with a logical 'AND'.
 */
export interface ums_connections_bool_exp {
  _and?: ums_connections_bool_exp[] | null;
  _not?: ums_connections_bool_exp | null;
  _or?: ums_connections_bool_exp[] | null;
  category?: Int_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  extradata?: jsonb_comparison_exp | null;
  hostname?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  password?: String_comparison_exp | null;
  type?: Int_comparison_exp | null;
  username?: String_comparison_exp | null;
}

export interface ums_customerfeatures_aggregate_bool_exp {
  count?: ums_customerfeatures_aggregate_bool_exp_count | null;
}

export interface ums_customerfeatures_aggregate_bool_exp_count {
  arguments?: ums_customerfeatures_select_column[] | null;
  distinct?: boolean | null;
  filter?: ums_customerfeatures_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ums.customerfeatures". All fields are combined with a logical 'AND'.
 */
export interface ums_customerfeatures_bool_exp {
  _and?: ums_customerfeatures_bool_exp[] | null;
  _not?: ums_customerfeatures_bool_exp | null;
  _or?: ums_customerfeatures_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  feature?: Int_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ums.customerinvoices". All fields are combined with a logical 'AND'.
 */
export interface ums_customerinvoices_bool_exp {
  _and?: ums_customerinvoices_bool_exp[] | null;
  _not?: ums_customerinvoices_bool_exp | null;
  _or?: ums_customerinvoices_bool_exp[] | null;
  costperunit?: numeric_comparison_exp | null;
  costtype?: Int_comparison_exp | null;
  count?: Int_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  customerinvoiceusers?: ums_customerinvoiceusers_bool_exp | null;
  customerinvoiceusers_aggregate?: ums_customerinvoiceusers_aggregate_bool_exp | null;
  date?: date_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  invoiced?: date_comparison_exp | null;
}

export interface ums_customerinvoiceusers_aggregate_bool_exp {
  count?: ums_customerinvoiceusers_aggregate_bool_exp_count | null;
}

export interface ums_customerinvoiceusers_aggregate_bool_exp_count {
  arguments?: ums_customerinvoiceusers_select_column[] | null;
  distinct?: boolean | null;
  filter?: ums_customerinvoiceusers_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ums.customerinvoiceusers". All fields are combined with a logical 'AND'.
 */
export interface ums_customerinvoiceusers_bool_exp {
  _and?: ums_customerinvoiceusers_bool_exp[] | null;
  _not?: ums_customerinvoiceusers_bool_exp | null;
  _or?: ums_customerinvoiceusers_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  customerinvoice?: ums_customerinvoices_bool_exp | null;
  customerinvoiceid?: uuid_comparison_exp | null;
  email?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name1?: String_comparison_exp | null;
  name2?: String_comparison_exp | null;
  realestates?: jsonb_comparison_exp | null;
  registrationcode?: uuid_comparison_exp | null;
  roles?: json_comparison_exp | null;
  updateuser?: String_comparison_exp | null;
  userid?: uuid_comparison_exp | null;
}

export interface ums_customeroptions_aggregate_bool_exp {
  count?: ums_customeroptions_aggregate_bool_exp_count | null;
}

export interface ums_customeroptions_aggregate_bool_exp_count {
  arguments?: ums_customeroptions_select_column[] | null;
  distinct?: boolean | null;
  filter?: ums_customeroptions_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ums.customeroptions". All fields are combined with a logical 'AND'.
 */
export interface ums_customeroptions_bool_exp {
  _and?: ums_customeroptions_bool_exp[] | null;
  _not?: ums_customeroptions_bool_exp | null;
  _or?: ums_customeroptions_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  id?: uuid_comparison_exp | null;
  option?: Int_comparison_exp | null;
  value?: Int_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "ums.customers". All fields are combined with a logical 'AND'.
 */
export interface ums_customers_bool_exp {
  _and?: ums_customers_bool_exp[] | null;
  _not?: ums_customers_bool_exp | null;
  _or?: ums_customers_bool_exp[] | null;
  accountlists?: portal_accountlists_bool_exp | null;
  accountlists_aggregate?: portal_accountlists_aggregate_bool_exp | null;
  accounts?: portal_accounts_bool_exp | null;
  accounts_aggregate?: portal_accounts_aggregate_bool_exp | null;
  active?: Boolean_comparison_exp | null;
  addresses?: common_addresses_bool_exp | null;
  apiversion?: float8_comparison_exp | null;
  bookings?: portal_bookings_bool_exp | null;
  bookings_aggregate?: portal_bookings_aggregate_bool_exp | null;
  conditions?: common_conditions_bool_exp | null;
  conditions_aggregate?: common_conditions_aggregate_bool_exp | null;
  connections?: ums_connections_bool_exp | null;
  connections_aggregate?: ums_connections_aggregate_bool_exp | null;
  customerfeatures?: ums_customerfeatures_bool_exp | null;
  customerfeatures_aggregate?: ums_customerfeatures_aggregate_bool_exp | null;
  customerfiles?: file_customerfiles_bool_exp | null;
  customerfiles_aggregate?: file_customerfiles_aggregate_bool_exp | null;
  customerinvoices?: ums_customerinvoices_bool_exp | null;
  customersettings?: ums_customersettings_bool_exp | null;
  customersettings_aggregate?: ums_customersettings_aggregate_bool_exp | null;
  datafilelinks?: common_datafilelinks_bool_exp | null;
  datafilelinks_aggregate?: common_datafilelinks_aggregate_bool_exp | null;
  datafiles?: common_datafiles_bool_exp | null;
  datafiles_aggregate?: common_datafiles_aggregate_bool_exp | null;
  demoportalsettings?: ums_demoportalsettings_bool_exp | null;
  demoportalsettings_aggregate?: ums_demoportalsettings_aggregate_bool_exp | null;
  dmsconnections?: file_dmsconnections_bool_exp | null;
  dmsconnections_aggregate?: file_dmsconnections_aggregate_bool_exp | null;
  dns?: Boolean_comparison_exp | null;
  email?: String_comparison_exp | null;
  emaillinks?: mail_emaillinks_bool_exp | null;
  emaillinks_aggregate?: mail_emaillinks_aggregate_bool_exp | null;
  emaillogs?: mail_emaillogs_bool_exp | null;
  emaillogs_aggregate?: mail_emaillogs_aggregate_bool_exp | null;
  emailprofiles?: mail_emailprofiles_bool_exp | null;
  emailprofiles_aggregate?: mail_emailprofiles_aggregate_bool_exp | null;
  emailrecipients?: mail_emailrecipients_bool_exp | null;
  emailrecipients_aggregate?: mail_emailrecipients_aggregate_bool_exp | null;
  emails?: mail_emails_bool_exp | null;
  emails_aggregate?: mail_emails_aggregate_bool_exp | null;
  erp?: Int_comparison_exp | null;
  files?: file_files_bool_exp | null;
  files_aggregate?: file_files_aggregate_bool_exp | null;
  houses?: common_houses_bool_exp | null;
  houses_aggregate?: common_houses_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  licencedunits?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  news?: news_news_bool_exp | null;
  news_aggregate?: news_news_aggregate_bool_exp | null;
  number?: String_comparison_exp | null;
  options?: ums_customeroptions_bool_exp | null;
  options_aggregate?: ums_customeroptions_aggregate_bool_exp | null;
  owners?: common_owners_bool_exp | null;
  owners_aggregate?: common_owners_aggregate_bool_exp | null;
  partner?: Int_comparison_exp | null;
  persons?: common_persons_bool_exp | null;
  persons_aggregate?: common_persons_aggregate_bool_exp | null;
  realestatecontacts?: common_realestatecontacts_bool_exp | null;
  realestatecontacts_aggregate?: common_realestatecontacts_aggregate_bool_exp | null;
  realestates?: common_realestates_bool_exp | null;
  realestates_aggregate?: common_realestates_aggregate_bool_exp | null;
  remarks?: String_comparison_exp | null;
  subdomain?: String_comparison_exp | null;
  tenancypersons?: common_tenancypersons_bool_exp | null;
  tenancypersons_aggregate?: common_tenancypersons_aggregate_bool_exp | null;
  tenancys?: common_tenancys_bool_exp | null;
  tenancys_aggregate?: common_tenancys_aggregate_bool_exp | null;
  tenants?: common_tenants_bool_exp | null;
  tenants_aggregate?: common_tenants_aggregate_bool_exp | null;
  tiledatasets?: portal_tiledatasets_bool_exp | null;
  tiledatasets_aggregate?: portal_tiledatasets_aggregate_bool_exp | null;
  tiledatasets_mls?: portal_tiledatasets_mls_bool_exp | null;
  tiledatasets_mls_aggregate?: portal_tiledatasets_mls_aggregate_bool_exp | null;
  tiledatavalues?: portal_tiledatavalues_bool_exp | null;
  tiledatavalues_aggregate?: portal_tiledatavalues_aggregate_bool_exp | null;
  tiledatavalues_mls?: portal_tiledatavalues_mls_bool_exp | null;
  tiledatavalues_mls_aggregate?: portal_tiledatavalues_mls_aggregate_bool_exp | null;
  tiles?: portal_tiles_bool_exp | null;
  tiles_aggregate?: portal_tiles_aggregate_bool_exp | null;
  tiles_mls?: portal_tiles_mls_bool_exp | null;
  tiles_mls_aggregate?: portal_tiles_mls_aggregate_bool_exp | null;
  tilescreens?: portal_tilescreens_bool_exp | null;
  tilescreens_aggregate?: portal_tilescreens_aggregate_bool_exp | null;
  type?: Int_comparison_exp | null;
  units?: common_units_bool_exp | null;
  units_aggregate?: common_units_aggregate_bool_exp | null;
  userroles?: ums_userroles_bool_exp | null;
  userroles_aggregate?: ums_userroles_aggregate_bool_exp | null;
  users?: ums_users_bool_exp | null;
  users_aggregate?: ums_users_aggregate_bool_exp | null;
  usersettings?: ums_usersettings_bool_exp | null;
  usersettings_aggregate?: ums_usersettings_aggregate_bool_exp | null;
}

export interface ums_customersettings_aggregate_bool_exp {
  count?: ums_customersettings_aggregate_bool_exp_count | null;
}

export interface ums_customersettings_aggregate_bool_exp_count {
  arguments?: ums_customersettings_select_column[] | null;
  distinct?: boolean | null;
  filter?: ums_customersettings_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ums.customersettings". All fields are combined with a logical 'AND'.
 */
export interface ums_customersettings_bool_exp {
  _and?: ums_customersettings_bool_exp[] | null;
  _not?: ums_customersettings_bool_exp | null;
  _or?: ums_customersettings_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  default?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  key?: String_comparison_exp | null;
  value?: String_comparison_exp | null;
  valuetype?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "ums.customersettings"
 */
export interface ums_customersettings_insert_input {
  default?: string | null;
  id?: any | null;
  key?: string | null;
  value?: string | null;
  valuetype?: number | null;
}

export interface ums_demoportalsettings_aggregate_bool_exp {
  count?: ums_demoportalsettings_aggregate_bool_exp_count | null;
}

export interface ums_demoportalsettings_aggregate_bool_exp_count {
  arguments?: ums_demoportalsettings_select_column[] | null;
  distinct?: boolean | null;
  filter?: ums_demoportalsettings_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ums.demoportalsettings". All fields are combined with a logical 'AND'.
 */
export interface ums_demoportalsettings_bool_exp {
  _and?: ums_demoportalsettings_bool_exp[] | null;
  _not?: ums_demoportalsettings_bool_exp | null;
  _or?: ums_demoportalsettings_bool_exp[] | null;
  contactperson?: String_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  email?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  language?: String_comparison_exp | null;
  mobile?: String_comparison_exp | null;
  onlinecontactemail?: String_comparison_exp | null;
  phone?: String_comparison_exp | null;
  text1?: String_comparison_exp | null;
  text1default?: String_comparison_exp | null;
  text2?: String_comparison_exp | null;
  text2default?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "ums.demoportalsettings"
 */
export interface ums_demoportalsettings_insert_input {
  contactperson?: string | null;
  email?: string | null;
  id?: any | null;
  language?: string | null;
  mobile?: string | null;
  onlinecontactemail?: string | null;
  phone?: string | null;
  text1?: string | null;
  text2?: string | null;
}

export interface ums_userroles_aggregate_bool_exp {
  bool_and?: ums_userroles_aggregate_bool_exp_bool_and | null;
  bool_or?: ums_userroles_aggregate_bool_exp_bool_or | null;
  count?: ums_userroles_aggregate_bool_exp_count | null;
}

export interface ums_userroles_aggregate_bool_exp_bool_and {
  arguments: ums_userroles_select_column_ums_userroles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: ums_userroles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface ums_userroles_aggregate_bool_exp_bool_or {
  arguments: ums_userroles_select_column_ums_userroles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: ums_userroles_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface ums_userroles_aggregate_bool_exp_count {
  arguments?: ums_userroles_select_column[] | null;
  distinct?: boolean | null;
  filter?: ums_userroles_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ums.userroles". All fields are combined with a logical 'AND'.
 */
export interface ums_userroles_bool_exp {
  _and?: ums_userroles_bool_exp[] | null;
  _not?: ums_userroles_bool_exp | null;
  _or?: ums_userroles_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  inserted?: timestamp_comparison_exp | null;
  insertuser?: String_comparison_exp | null;
  referenceid?: uuid_comparison_exp | null;
  role?: Int_comparison_exp | null;
  system?: Boolean_comparison_exp | null;
  updated?: timestamp_comparison_exp | null;
  updateuser?: String_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  userid?: uuid_comparison_exp | null;
}

export interface ums_users_aggregate_bool_exp {
  bool_and?: ums_users_aggregate_bool_exp_bool_and | null;
  bool_or?: ums_users_aggregate_bool_exp_bool_or | null;
  count?: ums_users_aggregate_bool_exp_count | null;
}

export interface ums_users_aggregate_bool_exp_bool_and {
  arguments: ums_users_select_column_ums_users_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: ums_users_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface ums_users_aggregate_bool_exp_bool_or {
  arguments: ums_users_select_column_ums_users_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: ums_users_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface ums_users_aggregate_bool_exp_count {
  arguments?: ums_users_select_column[] | null;
  distinct?: boolean | null;
  filter?: ums_users_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ums.users". All fields are combined with a logical 'AND'.
 */
export interface ums_users_bool_exp {
  _and?: ums_users_bool_exp[] | null;
  _not?: ums_users_bool_exp | null;
  _or?: ums_users_bool_exp[] | null;
  activated?: Boolean_comparison_exp | null;
  activationemailcounter?: Int_comparison_exp | null;
  assemblyuseraudits?: cond_assemblyuseraudits_bool_exp | null;
  assemblyuseraudits_aggregate?: cond_assemblyuseraudits_aggregate_bool_exp | null;
  audits?: ums_audits_bool_exp | null;
  audits_aggregate?: ums_audits_aggregate_bool_exp | null;
  cidaasid?: String_comparison_exp | null;
  cidaasstate?: String_comparison_exp | null;
  customer?: ums_customers_bool_exp | null;
  customerid?: uuid_comparison_exp | null;
  email?: String_comparison_exp | null;
  emailprofiles?: mail_emailprofiles_bool_exp | null;
  emailprofiles_aggregate?: mail_emailprofiles_aggregate_bool_exp | null;
  emails?: mail_emails_bool_exp | null;
  emails_aggregate?: mail_emails_aggregate_bool_exp | null;
  emailverified?: Boolean_comparison_exp | null;
  hash?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  inserted?: timestamp_comparison_exp | null;
  insertuser?: String_comparison_exp | null;
  name1?: String_comparison_exp | null;
  name2?: String_comparison_exp | null;
  newsusers?: news_newsusers_bool_exp | null;
  newsusers_aggregate?: news_newsusers_aggregate_bool_exp | null;
  ratingdate?: timestamp_comparison_exp | null;
  ratingdateportal?: timestamp_comparison_exp | null;
  ratingstate?: Int_comparison_exp | null;
  ratingstateportal?: Int_comparison_exp | null;
  rccreatedat?: timestamp_comparison_exp | null;
  registered?: Boolean_comparison_exp | null;
  registereddate?: timestamp_comparison_exp | null;
  registrationcode?: uuid_comparison_exp | null;
  state?: Int_comparison_exp | null;
  system?: Boolean_comparison_exp | null;
  updated?: timestamp_comparison_exp | null;
  updateuser?: String_comparison_exp | null;
  userroles?: ums_userroles_bool_exp | null;
  userroles_aggregate?: ums_userroles_aggregate_bool_exp | null;
  usersettings?: ums_usersettings_bool_exp | null;
  usersettings_aggregate?: ums_usersettings_aggregate_bool_exp | null;
  v_users?: ums_v_users_bool_exp | null;
}

export interface ums_usersettings_aggregate_bool_exp {
  count?: ums_usersettings_aggregate_bool_exp_count | null;
}

export interface ums_usersettings_aggregate_bool_exp_count {
  arguments?: ums_usersettings_select_column[] | null;
  distinct?: boolean | null;
  filter?: ums_usersettings_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ums.usersettings". All fields are combined with a logical 'AND'.
 */
export interface ums_usersettings_bool_exp {
  _and?: ums_usersettings_bool_exp[] | null;
  _not?: ums_usersettings_bool_exp | null;
  _or?: ums_usersettings_bool_exp[] | null;
  customer?: ums_customers_bool_exp | null;
  default?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  key?: String_comparison_exp | null;
  user?: ums_users_bool_exp | null;
  userid?: uuid_comparison_exp | null;
  value?: String_comparison_exp | null;
  valuetype?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "ums.usersettings"
 */
export interface ums_usersettings_insert_input {
  default?: string | null;
  id?: any | null;
  key?: string | null;
  value?: string | null;
  valuetype?: number | null;
}

/**
 * Boolean expression to filter rows from the table "ums.v_users". All fields are combined with a logical 'AND'.
 */
export interface ums_v_users_bool_exp {
  _and?: ums_v_users_bool_exp[] | null;
  _not?: ums_v_users_bool_exp | null;
  _or?: ums_v_users_bool_exp[] | null;
  email?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  users?: ums_users_bool_exp | null;
}

export interface ums_v_users_email_aggregate_bool_exp {
  count?: ums_v_users_email_aggregate_bool_exp_count | null;
}

export interface ums_v_users_email_aggregate_bool_exp_count {
  arguments?: ums_v_users_email_select_column[] | null;
  distinct?: boolean | null;
  filter?: ums_v_users_email_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "ums.v_users_email". All fields are combined with a logical 'AND'.
 */
export interface ums_v_users_email_bool_exp {
  _and?: ums_v_users_email_bool_exp[] | null;
  _not?: ums_v_users_email_bool_exp | null;
  _or?: ums_v_users_email_bool_exp[] | null;
  customerid?: uuid_comparison_exp | null;
  email?: String_comparison_exp | null;
  registeredat?: timestamp_comparison_exp | null;
  userid?: uuid_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'.
 */
export interface uuid_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
