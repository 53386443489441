import React, { useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ColorStyle } from "src/utils/Colors";
import { RootStoreContext } from "src/stores/RootStore";
import { TakeOverTicketButton } from "src/screens/tickets/ticket/details/actions/takeOverTicket/TakeOverTicketButton";
import { AssignTicketButtonAndModal } from "src/screens/tickets/ticket/details/actions/assignTicket/AssignTicketButtonAndModal";

const TicketWorkflowCardBase = () => {
    const { t } = useTranslation();
    const { ticketStore } = useContext(RootStoreContext);

    const assignedUser = ticketStore.currentTicket?.assignedUser ?? undefined;
    const assignedUserName = assignedUser
        ? `${assignedUser.name1 ?? ""} ${
              assignedUser.name2 && assignedUser.name2.trim() !== assignedUser.name1.trim() ? assignedUser.name2 : ""
          }`.trim()
        : "-";

    return (
        <UI.Card title={t("screens.tickets.workflow.title").toString()} flex>
            <div className="d-flex align-items-center">
                <UI.Icon icon={UI.SVGIcon.Profile} color={ColorStyle("primary")} className="mr-1" />
                <div>{assignedUserName}</div>
            </div>
            <TakeOverTicketButton />
            <AssignTicketButtonAndModal />
        </UI.Card>
    );
};

export const TicketWorkflowCard = Sentry.withProfiler(observer(TicketWorkflowCardBase));
