import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react";
import { useQuery } from "@apollo/client";
import { GET_UMS_CUSTOMER, GET_UMS_CUSTOMER_BASIC } from "src/api/customer";
import { GetUmsCustomer } from "src/api/generated/GetUmsCustomer";
import { Loading } from "src/screens";
import { Error, ErrorEmptyData } from "src/screens";
import { ErpType } from "src/network/User";
import { runInAction } from "mobx";
import { GetUserAudits } from "src/api/generated/GetUserAudits";
import { GET_USER_AUDITS } from "src/api/audits";
import { dateFormat } from "src/utils/Date";
import { useTranslation } from "react-i18next";
import { CustomerRoutes } from "./CustomerRoutes";
import { Role } from "src/network/User";
import { Customer as ICustomer } from "src/types";

export enum CustomerType {
    WW = 1,
    EXTENSO = 9
}

const CustomerBase = (props: React.HTMLProps<HTMLDivElement>) => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore, ticketStore } = useContext(RootStoreContext);

    const { data, loading, error } = useQuery<GetUmsCustomer>(
        authStore.user?.role === Role.MANAGER ||
            authStore.user?.role === Role.OWNER ||
            authStore.user?.role === Role.COOWNER ||
            authStore.user?.role === Role.AUDITOR ||
            authStore.user?.role === Role.TENANT
            ? GET_UMS_CUSTOMER
            : GET_UMS_CUSTOMER_BASIC,
        {
            variables: {
                userid: authStore.user?.userid
            }
        }
    );
    const { data: auditData, loading: auditLoading, error: auditError } = useQuery<GetUserAudits>(GET_USER_AUDITS);

    useEffect(() => {
        if (data && data.demomode && data.demomode.length >= 0) {
            uiStore.setIsDemoModeTicketingActive(data?.demomode[0].value === "true" ? true : false);
        }
    }, [data, uiStore]);

    useEffect(() => {
        if (
            uiStore.shouldShowLoginNotification &&
            auditData &&
            auditData.ums_audits.length === 2 &&
            data &&
            data.ums_customers.length > 0
        ) {
            runInAction(() => {
                uiStore.enqueueSnackbar({
                    content: `${t("labels.welcome_back", { username: navStore.customer.userName })}!`,
                    options: {
                        type: "info"
                    }
                });
                uiStore.enqueueSnackbar({
                    content: t("labels.last_login", {
                        date: dateFormat(new Date(auditData.ums_audits[1].timestamp), "dd.MM.yyyy HH:mm:ss")
                    }),
                    options: {
                        type: "info"
                    }
                });
                uiStore.setShouldShowLoginNotification(false);
            });
        }
    }, [uiStore, uiStore.shouldShowLoginNotification, auditData, t, navStore, loading, data]);

    useEffect(() => {
        if (data && data.ums_customers.length !== 0) {
            const customer = data.ums_customers[0];

            const name1 = customer.users[0].name1.trim();
            const name2 = customer.users[0].name2 ? customer.users[0].name2.trim() : "";
            const userName = `${name1} ${name2}`;

            let activatedTiles: number[] = [];

            if (customer.tileconfigurations.length > 0 && customer.tileconfigurations[0].value) {
                const customerTileConfigurations = JSON.parse(customer.tileconfigurations[0].value);

                for (let [tileId, isTileActive] of Object.entries(customerTileConfigurations)) {
                    if (isTileActive) {
                        activatedTiles.push(Number(tileId));
                    }
                }
            }

            let credNumberOfVisas = undefined;
            if (customer.cred_numberofvisa.length > 0 && customer.cred_numberofvisa[0].value) {
                credNumberOfVisas = Number(customer.cred_numberofvisa[0].value);
            }

            const customerObject: ICustomer = {
                name: customer.name || "",
                userName,
                email: customer.email || "",
                options: customer.options,
                features: customer.customerfeatures,
                activatedTiles,
                partner: customer.partner,
                apiversion: customer.apiversion,
                licencedunits: customer.licencedunits
            };

            if (credNumberOfVisas) {
                customerObject.settings = {
                    cred: {
                        numberOfVisas: credNumberOfVisas
                    }
                };
            }

            runInAction(() => {
                navStore.setCustomer(customerObject);

                authStore.setErp(customer.erp as ErpType);
            });
        }
    }, [data, navStore, authStore, ticketStore]);

    useEffect(() => {
        if (
            (authStore.user?.role === Role.MANAGER || authStore.user?.role === Role.ECADMIN) &&
            data &&
            data.ums_customers.length !== 0 &&
            data.ums_customers[0].users &&
            data.ums_customers[0].users.length > 0
        ) {
            const user = data.ums_customers[0].users[0];

            navStore.setUser({
                isSidebarCollapsedUserSetting:
                    user.isSidebarCollapsed && user.isSidebarCollapsed.length > 0
                        ? user.isSidebarCollapsed[0]
                        : undefined,
                invoicePaneWidthUserSetting:
                    user.invoicePaneWidth && user.invoicePaneWidth.length > 0 ? user.invoicePaneWidth[0] : undefined
            });
        }
    }, [authStore.user?.role, data, navStore, uiStore]);

    if (error || auditError) {
        return <Error message={error ? error.message : auditError ? auditError.message : undefined} />;
    }
    if (loading || auditLoading) {
        return <Loading fullscreen />;
    }
    if (data && data.ums_customers && data.ums_customers.length !== 0) {
        return <CustomerRoutes />;
    } else {
        return <ErrorEmptyData message="No Customer Found" />;
    }
};
export const Customer = observer(CustomerBase);
