import { runInAction } from "mobx";
import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { useForm } from "react-hook-form";
import { Patterns } from "src/utils/Patterns";
import { Loading } from "src/screens/loading/Loading";
import { AuthWrapper } from "src/screens/auth/AuthWrapper";
import { Route } from "src/config/routes";
import { Error } from "src/screens/error/Error";
import i18next from "i18next";

enum ACTIVATIONSTATES {
    PENDING = 0,
    ACTIVE = 1,
    DONE = 2,
    MISSING_CODE = 3
}

interface ActivationForm {
    username: string;
    code: string;
}

export const Activate = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        setValue,

        formState: { errors }
    } = useForm<ActivationForm>({
        shouldUnregister: false
    });
    const { navStore, authStore } = useContext(RootStoreContext);
    const [activationState, setActivationState] = useState<ACTIVATIONSTATES>(ACTIVATIONSTATES.PENDING);
    const [error, setError] = useState<Error | undefined>(undefined);

    useEffect(() => {
        runInAction(() => {
            navStore.setTitle(t("screens.auth.activate.title"));
            navStore.setBackbutton(null);
        });
        const search = new URLSearchParams(props.location.search);
        const code = search.get("code");
        const username = search.get("username");
        if (code && code.length > 0) {
            setValue("code", code);
        } else {
            setActivationState(ACTIVATIONSTATES.MISSING_CODE);
        }
        if (code && code.length > 0) {
            setValue("username", username === null ? "" : username);
        }
    }, [navStore, t, setValue, props.location.search]);

    const onSubmit = (formData: ActivationForm) => {
        setActivationState(ACTIVATIONSTATES.ACTIVE);
        authStore.authClient
            .activate(formData.username, formData.code)
            .catch((error) => {
                error.message = i18next.exists(`error.${error.message}`)
                    ? t(`error.${error.message}`)
                    : t("screens.auth.register.default_error");
                setError(error);
            })
            .finally(() => setActivationState(ACTIVATIONSTATES.DONE));
    };

    if (activationState === ACTIVATIONSTATES.MISSING_CODE) {
        return (
            <AuthWrapper>
                <Error message={t("screens.auth.activate.error_no_code")} />
            </AuthWrapper>
        );
    } else if (activationState === ACTIVATIONSTATES.ACTIVE) {
        return (
            <AuthWrapper>
                <Loading noLogo noBackgroundChange />
            </AuthWrapper>
        );
    } else if (activationState === ACTIVATIONSTATES.DONE) {
        if (!error) {
            return (
                <AuthWrapper>
                    <UI.Container className="flex-grow-1 d-flex justify-content-center align-items-center flex-column">
                        <UI.Row>
                            <UI.Col>
                                <Trans>screens.auth.activate.success</Trans>
                            </UI.Col>
                        </UI.Row>
                        <UI.Row className="mt-2">
                            <UI.Col>
                                <Link to={Route.login}>
                                    <Trans>screens.auth.login_link</Trans>
                                </Link>
                            </UI.Col>
                        </UI.Row>
                    </UI.Container>
                </AuthWrapper>
            );
        }
    }

    return (
        <AuthWrapper>
            <UI.Form onSubmit={handleSubmit(onSubmit)}>
                <UI.FormGroup className="mb-3">
                    <UI.Form.Label>
                        <Trans>screens.auth.emailaddress</Trans>
                    </UI.Form.Label>
                    <UI.Form.Control
                        {...register("username", { required: true, pattern: Patterns.EMAILADDRESS })}
                        type="email"
                        readOnly={true}
                        isInvalid={errors.username !== undefined}
                        placeholder={t("screens.auth.emailaddress_placeholder")}
                    />
                </UI.FormGroup>
                <UI.FormGroup className="mb-3">
                    <UI.Form.Control name="code" type="text" hidden={true} readOnly={true} />
                </UI.FormGroup>
                <UI.Button type="submit" label={t("screens.auth.activate.submit")} />
                {error && (
                    <UI.Alert variant="danger" className="mt-2">
                        {error.message ? error.message : error}
                    </UI.Alert>
                )}
            </UI.Form>
        </AuthWrapper>
    );
};
