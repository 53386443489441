import { gql } from "@apollo/client";

const REALESTATE_FRAGMENT = gql`
    fragment realestate_details on common_realestates {
        consolidation
        id
        name
        number
        condominium
        houses {
            city
            zip
        }
        realestatefiles: v_realestatefiles(
            where: { type: { _eq: 311 } }
            order_by: { filedate: desc, fileid: desc }
            distinct_on: fileid
            limit: 1
        ) {
            fileid
            realestateid
            id: realestatefileid
        }
        realestateaccesses(
            where: { role: { _gte: 30 }, _and: { role: { _lt: 40 } } }
            distinct_on: role
            order_by: { role: asc }
        ) {
            role
        }
    }
`;

export const GET_COMMON_REALESTATES = gql`
    ${REALESTATE_FRAGMENT}
    query CommonRealestates {
        common_realestates(order_by: { name: asc }) {
            ...realestate_details
        }
    }
`;

export const GET_COMMON_REALESTATES_OWNER = gql`
    ${REALESTATE_FRAGMENT}
    query CommonRealestatesOwner {
        common_realestates(order_by: { name: asc }, where: { condominium: { _eq: false } }) {
            ...realestate_details
        }
    }
`;

export const GET_COMMON_REALESTATES_COOWNER_IT2 = gql`
    ${REALESTATE_FRAGMENT}
    query CommonRealestatesCoownerIT2 {
        common_realestates(order_by: { name: asc }, where: { consolidationid: { _is_null: true } }) {
            ...realestate_details
        }
    }
`;

export const GET_COMMON_REALESTATES_BY_OWNERID = gql`
    ${REALESTATE_FRAGMENT}
    query CommonRealestatesByOwnerId($ownerid: uuid) {
        common_realestates(where: { ownerid: { _eq: $ownerid } }, order_by: { name: asc }) {
            ...realestate_details
        }
    }
`;

export const GET_COMMON_REALESTATEFEATURE_TENANT = gql`
    query GetRealestateFeatureTenant($realestateid: uuid!) {
        common_realestatefeatures_aggregate(
            where: { realestateid: { _eq: $realestateid }, _and: { feature: { _eq: 33 } } }
        ) {
            aggregate {
                count
            }
        }
    }
`;

const REALESTATE_TENANT_FRAGMENT = gql`
    fragment realestate_tenant_details on common_realestates {
        consolidation
        id
        name
        number
        condominium
        houses {
            city
            zip
        }
        realestatefiles: v_realestatefiles(
            where: { type: { _eq: 311 } }
            order_by: { filedate: desc, fileid: desc }
            distinct_on: fileid
            limit: 1
        ) {
            fileid
            realestateid
            id: realestatefileid
        }
        realestateaccesses(where: { role: { _eq: 33 } }, distinct_on: role, order_by: { role: asc }) {
            role
        }
    }
`;

export const GET_COMMON_REALESTATES_TENANT = gql`
    ${REALESTATE_TENANT_FRAGMENT}
    query CommonRealestatesTenant {
        common_realestates(order_by: { name: asc }, where: { _and: { condominium: { _eq: false } } }) {
            ...realestate_tenant_details
        }
    }
`;

export const GET_COMMON_REALESTATES_TENANT_IT2 = gql`
    ${REALESTATE_TENANT_FRAGMENT}
    query CommonRealestatesTenantIT2 {
        common_realestates(
            order_by: { name: asc }
            where: { consolidationid: { _is_null: true }, _and: { condominium: { _eq: false } } }
        ) {
            ...realestate_tenant_details
        }
    }
`;
