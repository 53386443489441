import React, { useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import * as Screens from "src/screens";
import { GET_MONTHLY_COSTS } from "src/api/invoice";
import { GetMonthlyCosts } from "src/api/generated/GetMonthlyCosts";
import {
    GetTilesByScreen_portal_tilescreens_tile,
    GetTilesByScreen_portal_tilescreens_tile_tiledatasets,
    GetTilesByScreen_portal_tilescreens_tile_tiledatasets_tiledatavalues,
    GetTilesByScreen_portal_tilescreens_tile_tiles_mls
} from "src/api/generated/GetTilesByScreen";
import { v4 as uuidv4 } from "uuid";
import { ChartDataType, ChartType } from "src/components/tiles/Tile";
import { convert } from "src/utils/Convert";
import { Tiles } from "src/components/tiles";
import { RootStoreContext } from "src/stores/RootStore";

const MonthlyCostsChartBase = () => {
    const { t } = useTranslation();
    const { uiStore } = useContext(RootStoreContext);
    uiStore.setIsLegendDisplayed(false);

    const { data, loading, error } = useQuery<GetMonthlyCosts>(GET_MONTHLY_COSTS, {});

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    const getChartDataMonthlyCosts = () => {
        let tile_tiledatasets: GetTilesByScreen_portal_tilescreens_tile_tiledatasets[] = [];
        let count: any = 1;

        data!.ums_v_customer_invoices_group_year_monthly.forEach((entry) => {
            let tile_tiledatasetvalues: GetTilesByScreen_portal_tilescreens_tile_tiledatasets_tiledatavalues[] = [];

            entry.customer_invoices.forEach((invoices) => {
                tile_tiledatasetvalues.push({
                    id: uuidv4(),
                    sort: 1,
                    value: Number.parseFloat(invoices.sum).toFixed(2),
                    group: invoices.costtype,
                    valuetype: ChartDataType.AMOUNT,
                    tiledatavalues_mls: [
                        {
                            label: convert.getShortMonthName(invoices.month - 1),
                            hint: invoices.label
                        }
                    ]
                } as GetTilesByScreen_portal_tilescreens_tile_tiledatasets_tiledatavalues);
            });

            tile_tiledatasets.push({
                id: uuidv4(),
                sort: count++,
                tiledatasets_mls: [
                    {
                        subtitle: "",
                        label: "" + entry.year
                    }
                ],
                tiledatavalues: tile_tiledatasetvalues
            } as GetTilesByScreen_portal_tilescreens_tile_tiledatasets);
        });

        const tileDataset: GetTilesByScreen_portal_tilescreens_tile = {
            charttype: ChartType.STACKED_AREA,
            realestateid: uuidv4(),
            id: uuidv4(),
            tiles_mls: [
                {
                    title: t("screens.services.costs_and_invoices.overview.monthly_costs")
                }
            ] as GetTilesByScreen_portal_tilescreens_tile_tiles_mls[],
            tiledatasets: tile_tiledatasets
        } as GetTilesByScreen_portal_tilescreens_tile;

        return tileDataset;
    };

    return (
        <>
            {getChartDataMonthlyCosts()?.tiledatasets.length > 0 ? (
                <UI.Col sm={6} lg={4} xl={3}>
                    <Tiles.Tile data={getChartDataMonthlyCosts()} />
                </UI.Col>
            ) : undefined}
        </>
    );
};

export const MonthlyCostsChart = Sentry.withProfiler(observer(MonthlyCostsChartBase));
