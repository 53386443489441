import { gql } from "@apollo/client";

export const GET_BOOKINGS_BY_ACCOUNTID = gql`
    query GetBookingsByAccountId($accountid: uuid!) {
        portal_accounts(where: { id: { _eq: $accountid } }) {
            number
            title
            credit
            debit
            bookings(order_by: { date: desc }, where: { cancellation: { _eq: false } }) {
                date
                debit
                credit
                text
                quantity
                documentnumber
                bookingfiles: v_bookingfiles(order_by: { filedate: desc, fileid: desc }, distinct_on: fileid) {
                    bookingfileid
                    fileid
                    name
                    filedate
                }
            }
        }
    }
`;

export const GET_TENANT_BOOKINGS_BY_USER = gql`
    query GetTenantBookingsByUser($realestateid: uuid!) {
        tenants: portal_v_tenantbookings_by_realestate(
            where: { realestateid: { _eq: $realestateid } }
            distinct_on: tenantid
        ) {
            tenantid
            name
            accountbalance
        }
        tenantbookings: portal_v_tenantbookings_by_realestate(where: { realestateid: { _eq: $realestateid } }) {
            tenantid
            tenantbookingid
            date
            duedate
            documentnumber
            cancellation
            credit
            debit
            quantity
            text
            tenantbookingfiles(order_by: { file: { filedate: desc }, fileid: desc }) {
                id
                fileid
            }
        }
    }
`;

export const GET_TENANT_BOOKINGS_BY_USER_IT2 = gql`
    query GetTenantBookingsByUserIT2($realestateid: uuid!) {
        tenants: portal_v_tenantbookings_by_realestate(
            where: { _or: [{ realestateid: { _eq: $realestateid } }, { consolidationid: { _eq: $realestateid } }] }
            distinct_on: tenantid
        ) {
            tenantid
            name
            accountbalance
        }
        tenantbookings: portal_v_tenantbookings_by_realestate(
            where: { _or: [{ realestateid: { _eq: $realestateid } }, { consolidationid: { _eq: $realestateid } }] }
        ) {
            tenantid
            tenantbookingid
            date
            duedate
            documentnumber
            cancellation
            credit
            debit
            quantity
            text
            tenantbookingfiles(order_by: { file: { filedate: desc }, fileid: desc }) {
                id
                fileid
            }
        }
    }
`;
