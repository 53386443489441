import { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { CloseTicketButton } from "src/screens/tickets/ticket/details/actions/closeTicket/CloseTicketButton";
import { ConfirmationModal } from "src/components/modals/ConfirmationModal";
import { TICKET_STATE } from "src/stores/TicketStore";

const CloseTicketButtonAndModalBase = () => {
    const { ticketStore, closeTicketModalStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const onClickCloseButton = useCallback(async () => {
        const isTicketReadyToBeClosed = await ticketStore.checkCurrentTicketReadyToComplete();

        if (isTicketReadyToBeClosed) {
            closeTicketModalStore.setIsModalDisplayed(true);
        }
    }, [ticketStore, closeTicketModalStore]);

    const onCloseTicket = useCallback(
        (notifyPerson: boolean) => async (): Promise<boolean> => {
            closeTicketModalStore.setIsModalDisplayed(false);
            const hasSuccessfullyCloseTicket = await ticketStore.closeCurrentTicket(notifyPerson);

            return hasSuccessfullyCloseTicket;
        },
        [ticketStore, closeTicketModalStore]
    );

    return ticketStore.currentTicket?.state !== TICKET_STATE.COMPLETED &&
        ticketStore.currentTicket?.state !== TICKET_STATE.ARCHIVED ? (
        <>
            <ConfirmationModal
                modalTitle={t("screens.tickets.action.close.close_ticket")}
                modalSize="lg"
                confirmationQuestion={t("screens.tickets.action.close.inform_notifier_about_close")}
                inProgressText={`${t("screens.tickets.action.close.in_progress").toString()}...`}
                isModalDisplayed={closeTicketModalStore.isModalDisplayed}
                setIsModalDisplayed={closeTicketModalStore.setIsModalDisplayed}
                onConfirmation={onCloseTicket(true)}
                onNegation={onCloseTicket(false)}
                closeAfterConfirmation={true}
                closeAfterNegation={false}
                processFinishedMessage={t("screens.tickets.action.close.success").toString()}
                processErrordMessage={t("screens.tickets.action.close.failure").toString()}
            />
            <CloseTicketButton onClick={onClickCloseButton} />
        </>
    ) : (
        <></>
    );
};

export const CloseTicketButtonAndModal = Sentry.withProfiler(observer(CloseTicketButtonAndModalBase));
