import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_REALESTATE_USERS } from "src/api/realestateUsers";
import { GetRealestateUsers } from "src/api/generated/GetRealestateUsers";
import RealestateUsersList from "src/screens/realestates/realestate/users/list/RealestateUsersList";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { withRouter, RouteComponentProps, useParams } from "react-router";
import { selectRoute, Route } from "src/config/routes";
import classes from "./RealestateUsers.module.css";
import { getLang } from "src/utils/i18n";

interface Params {
    realestateid: string;
}

const RealestateUsersBase = (props: RouteComponentProps) => {
    const { realestateid } = useParams<Params>();
    const { t } = useTranslation();
    const { navStore, authStore } = useContext(RootStoreContext);

    const { data, loading, error } = useQuery<GetRealestateUsers>(GET_REALESTATE_USERS, {
        variables: {
            realestateid: realestateid,
            lang: getLang()
        }
    });

    useEffect(() => {
        navStore.setBackbutton(
            selectRoute(Route.dashboard, authStore.user?.role, { realestateid: realestateid }),
            t("screens.realestate.dashboard.title")
        );
    });

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    const title = t("screens.realestate.realestateusers.title");

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <div>
                    <h1>{title}</h1>
                </div>
                <UI.Row>
                    <UI.Col md={12}>
                        <UI.Card>
                            <div className={classes.customerList}>
                                <RealestateUsersList
                                    realestateusers={data.common_v_realestates_users}
                                    userrolesDefinition={data.userrolesdefinition}
                                    displayUserStatusRows={true}
                                />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const RealestateUsers = withRouter(Sentry.withProfiler(observer(RealestateUsersBase)));
