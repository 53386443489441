import React, { useContext, useEffect, useCallback, useState } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_BOOKINGS_BY_ACCOUNTID } from "src/api/bookings";
import { UI, ScreenSize } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { withRouter, RouteComponentProps, useParams } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import "src/screens/realestates/realestate/finances/Finances.css";
import * as Screens from "src/screens";
import { Route, selectRoute } from "src/config/routes";
import {
    GetBookingsByAccountId_portal_accounts_bookings,
    GetBookingsByAccountId,
    GetBookingsByAccountIdVariables,
    GetBookingsByAccountId_portal_accounts_bookings_bookingfiles as bookingfiles
} from "src/api/generated/GetBookingsByAccountId";
import { dateFormat, getDate } from "src/utils/Date";
import { NetworkConfig } from "src/network/NetworkConfig";
import { runInAction } from "mobx";
import { ColorStyle } from "src/utils/Colors";
import { ScreenWithContactTiles } from "src/components/screenWithTiles/screenWithContactTiles/ScreenWithContactTile";
import { observer } from "mobx-react-lite";
import { ScreenType } from "src/api/tiles";
import styles from "./Account.module.css";
import { getRoleKey, Role } from "src/network/User";
import { getCurrencyElement } from "src/utils/Currency";

interface MatchParams {
    accountid: string;
    realestateid: string;
    accountlistid: string;
    unitid: string;
    maintenanceid: string;
    applianceid: string;
}

interface AccountItem {
    key?: any;
    date?: string;
    text?: string;
    debit?: any;
    credit?: any;
    isTitle?: boolean;
    icon?: any;
}

const AccountBase = (props: RouteComponentProps<MatchParams>) => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);
    const { realestateid, unitid, applianceid, maintenanceid, accountlistid } = useParams<MatchParams>();
    const iconSize = UI.IconSize.default;
    const [modalBookingFiles, setModalBookingFiles] = useState<bookingfiles[]>([]);
    const [displaySelectBookingFilesModal, setDisplaySelectBookingFilesModal] = useState<boolean>(false);

    useEffect(() => {
        runInAction(() => {
            navStore.resetActionButtons();
            if (accountlistid) {
                navStore.setBackbutton(
                    selectRoute(Route.finances, authStore.user?.role, {
                        realestateid: realestateid,
                        accountlistid: accountlistid
                    }),
                    t("screens.realestate.finances.title")
                );
            } else if (realestateid && unitid && applianceid && maintenanceid) {
                navStore.setBackbutton(
                    selectRoute(Route.unitApplianceMaintenance, authStore.user?.role, {
                        realestateid: realestateid,
                        unitid: unitid,
                        applianceid: applianceid,
                        maintenanceid: maintenanceid
                    }),
                    t("screens.realestate.maintenance.title")
                );
            } else if (realestateid && unitid && maintenanceid) {
                navStore.setBackbutton(
                    selectRoute(Route.unitMaintenance, authStore.user?.role, {
                        realestateid: realestateid,
                        unitid: unitid,
                        maintenanceid: maintenanceid
                    }),
                    t("screens.realestate.maintenance.title")
                );
            } else if (realestateid && applianceid && maintenanceid) {
                navStore.setBackbutton(
                    selectRoute(Route.realestateApplianceMaintenance, authStore.user?.role, {
                        realestateid: realestateid,
                        applianceid: applianceid,
                        maintenanceid: maintenanceid
                    }),
                    t("screens.realestate.maintenance.title")
                );
            } else if (realestateid && maintenanceid) {
                navStore.setBackbutton(
                    selectRoute(Route.realestateMaintenance, authStore.user?.role, {
                        realestateid: realestateid,
                        maintenanceid: maintenanceid
                    }),
                    t("screens.realestate.maintenance.title")
                );
            }
        });
    });

    const { loading, error, data } = useQuery<GetBookingsByAccountId, GetBookingsByAccountIdVariables>(
        GET_BOOKINGS_BY_ACCOUNTID,
        {
            variables: { accountid: props.match.params.accountid },
            context: {
                headers: {
                    "x-hasura-role": authStore.user?.hasCoownerAuditorRole
                        ? getRoleKey(Role.AUDITOR)
                        : getRoleKey(authStore.user?.role)
                }
            }
        }
    );

    useEffect(() => {
        if (data) {
            navStore.setTitle(data.portal_accounts[0].number + " - " + data.portal_accounts[0].title);
        }
    }, [data, navStore, t]);

    const renderItem = useCallback((item: AccountItem, screenSize: ScreenSize) => {
        let listRows: React.ReactElement[] = [];

        switch (screenSize) {
            case ScreenSize.DESKTOP:
                if (item.isTitle) {
                    listRows = [
                        <UI.List.Row key={"r-1"} className="bold">
                            <UI.List.Cell key={"c-1"} colspan={1} value={item.date} />
                            <UI.List.Cell key={"c-2"} colspan={3} value={item.text} className="uppercase" />
                            <UI.List.Cell key={"c-3"} colspan={1} value={item.credit} className="text-right" />
                            <UI.List.Cell key={"c-4"} colspan={1} value={item.debit} className="text-right" />
                        </UI.List.Row>
                    ];
                } else {
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={item.date} />
                            <UI.List.Cell key={"c-2"} colspan={3} value={item.text} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={item.debit} className="text-right" />
                            <UI.List.Cell key={"c-4"} colspan={1} value={item.credit} className="text-right" />
                        </UI.List.Row>
                    ];
                }
                break;
            case ScreenSize.MOBILE:
                if (item.isTitle) {
                    listRows = [
                        <UI.List.Row key={"r-1"} className="bold">
                            <UI.List.Cell key={"c-1"} colspan={1} value={item.text} className="uppercase" />
                            <UI.List.Cell key={"c-3"} colspan={1} value={item.credit} className="text-right" />
                            <UI.List.Cell key={"c-2"} colspan={1} value={item.debit} className="text-right" />
                        </UI.List.Row>
                    ];
                } else {
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={item.date} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={item.debit} className="text-right" />
                            <UI.List.Cell key={"c-3"} colspan={1} value={item.credit} className="text-right" />
                        </UI.List.Row>,
                        <UI.List.Row key={"r-2"}>
                            <UI.List.Cell key={"c-4"} colspan={1} value={item.text} className="sublabel" />
                        </UI.List.Row>
                    ];
                }

                break;
        }

        return (
            <UI.List.Item
                key={item.key}
                screenSize={screenSize}
                rows={listRows}
                icon={item.icon}
                className={item.isTitle ? "border-bottom" : ""}
            />
        );
    }, []);

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            const bookingDate = t("screens.realestate.finances.booking_date");
            const description = t("screens.realestate.finances.booking_description");
            const debit = t("screens.realestate.finances.debit");
            const credit = t("screens.realestate.finances.credit");

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={bookingDate} />
                            <UI.List.Cell key={"c-2"} colspan={3} value={description} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={debit} className="text-right" />
                            <UI.List.Cell key={"c-4"} colspan={1} value={credit} className="text-right" />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={bookingDate} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={debit} className="text-right" />
                            <UI.List.Cell key={"c-3"} colspan={1} value={credit} className="text-right" />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header key={bookingDate} rows={headerRows} />;
        },
        [t]
    );

    const getDocumentLinkIcon = useCallback((fileId?: any) => {
        let documentLinkIcon = undefined;

        if (fileId) {
            let urlLink = NetworkConfig.datafileUrl + fileId;
            documentLinkIcon = (
                <a href={urlLink} className="link" rel="noopener noreferrer d-flex" target="_blank">
                    <UI.Icon icon={UI.SVGIcon.Documents} color={ColorStyle("primary")} />
                </a>
            );
        }

        return documentLinkIcon;
    }, []);

    const renderBookingFileItem = useCallback(
        (bookingFile: bookingfiles, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];

            const file = bookingFile;
            const fileDate = getDate(file?.filedate);

            const documentLinkIcon = getDocumentLinkIcon(bookingFile.fileid);

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={file?.name} />
                            <UI.List.Cell key={"c-2"} colspan={0.4} value={fileDate} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={file?.name} />
                        </UI.List.Row>,
                        <UI.List.Row key={"r-2"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={fileDate} className="sublabel" />
                        </UI.List.Row>
                    ];
                    break;
            }

            return (
                <UI.List.Item
                    key={bookingFile.fileid}
                    screenSize={screenSize}
                    rows={listRows}
                    icon={documentLinkIcon}
                />
            );
        },
        [getDocumentLinkIcon]
    );

    const openDocumentSelectModal = useCallback(
        (bookingFiles: bookingfiles[]) => () => {
            setModalBookingFiles(bookingFiles);
            setDisplaySelectBookingFilesModal(true);
        },
        []
    );

    const onCloseSelectBookingFilesModal = useCallback(() => {
        setDisplaySelectBookingFilesModal(false);
    }, []);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }
    if (data.portal_accounts === null) {
        return <Screens.Error message={t("error.emptydata")} />;
    }
    if (data.portal_accounts[0].bookings.length === 0) {
        return <Screens.ErrorEmptyData message={t("error.emptydata")} />;
    }

    const totalCredit = data.portal_accounts[0].credit;
    const totalDebit = data.portal_accounts[0].debit;

    const bookingItems: AccountItem[] = [];

    bookingItems.push({
        key: "saldo-row",
        isTitle: true,
        text: t("labels.total"),
        credit: getCurrencyElement(totalCredit),
        debit: getCurrencyElement(totalDebit),
        icon: <span style={{ width: iconSize }} />
    });

    data.portal_accounts[0].bookings.forEach((booking: GetBookingsByAccountId_portal_accounts_bookings, index) => {
        const date = dateFormat(new Date(booking.date), "dd.MM.yyyy") || "";

        const getAccountDocumentsIcon = (bookingFiles: bookingfiles[]) => {
            let accountDocumentIcon = undefined;

            if (bookingFiles.length === 1 && bookingFiles[0].fileid) {
                accountDocumentIcon = getDocumentLinkIcon(bookingFiles[0].fileid);
            } else if (bookingFiles.length > 1) {
                accountDocumentIcon = (
                    <UI.Icon
                        icon={UI.SVGIcon.Documents}
                        color={ColorStyle("primary")}
                        onClick={openDocumentSelectModal(bookingFiles)}
                    />
                );
            } else {
                accountDocumentIcon = <span style={{ width: iconSize }} />;
            }

            return accountDocumentIcon;
        };

        bookingItems.push({
            key: index,
            date: date,
            text: booking.text,
            debit: getCurrencyElement(booking.debit),
            credit: getCurrencyElement(booking.credit),
            icon: getAccountDocumentsIcon(booking.bookingfiles)
        });
    });

    const selectBookingFilesModal = (
        <UI.Modal show={displaySelectBookingFilesModal} onClose={onCloseSelectBookingFilesModal} isScrollable={true}>
            <div>
                <h2 className={`${styles.DocumentsModalTitle} mt-2 mb-3`}>{t("screens.realestate.documents.title")}</h2>
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={modalBookingFiles}
                    renderItem={renderBookingFileItem}
                />
            </div>
            <UI.Button
                className="AnnouncementModalButton"
                label={t("labels.cancel")}
                onClick={onCloseSelectBookingFilesModal}
                variant="link"
            />
        </UI.Modal>
    );

    const accountScreenContent = (
        <UI.Card>
            {selectBookingFilesModal}
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={bookingItems}
                renderItem={renderItem}
                renderHeader={renderHeader}
            />
        </UI.Card>
    );

    const title = data.portal_accounts[0].number + " - " + data.portal_accounts[0].title;

    return (
        <ScreenWithContactTiles
            realestateid={props.match.params.realestateid}
            screenId={ScreenType.ACCOUNT}
            title={title}
        >
            {accountScreenContent}
        </ScreenWithContactTiles>
    );
};

export const Account = Sentry.withProfiler(withRouter(observer(AccountBase)));
