export enum INVOICE_STATE {
    NEW = 10,
    READY_FOR_ACCOUNTING = 20,
    READY_FOR_VISA = 30,
    VISA_1_OK = 41,
    VISA_2_OK = 42,
    VISA_3_OK = 43,
    FULLY_SIGHTED = 50,
    SENT_TO_ERP = 60,
    PAID = 90,
    REJECTED = 95,
    CANCELLED = 99
}

export enum INVOICE_LIST_STATE {
    NEW = 10,
    TO_APPROVE, // States 20 - 49
    PAY, // States 50 - 60
    PAID = 90,
    REJECTED = 95,
    CANCELLED = 99,
    ALL
}

export enum INVOICE_TYPE {
    INVOICE = 1,
    BILL = 2
}

export enum INVOICE_ORIGIN {
    MANUAL = 1,
    SCAN = 2,
    EMAIL = 3,
    TICKET = 4,
    DMS = 5
}

export enum INVOICE_PROPERTY {
    ID = "id",
    ORIGIN = "origin",
    WORKFLOW_INSTANCE = "workflowInstance",
    NUMBER = "number",
    INVOICE_NUMBER = "invoiceNumber",
    AMOUNT = "amount",
    DATE = "date",
    DUE_DATE = "dueDate",
    PAID_DATE = "paidDate",
    TITLE = "title",
    STATE = "state",
    INVOICE_TYPE = "invoiceType",
    REALESTATE = "realestate",
    REALESTATE_ID = "realestateId",
    REALESTATE_THUMBNAIL_FILEID = "realestateThumbnailFileId",
    UNIT = "unit",
    ASSIGNED_USER = "assignedUser",
    CREDITOR = "creditor",
    PAYMENT_ACCOUNT_ID = "paymentaccountid",
    PAYOUT_ACCOUNT_ID = "payoutaccountid",
    PAYMENT_INFO = "paymentInfo",
    PAYMENT_ACCOUNT = "paymentAccount",
    TICKET = "ticket",
    ORDER = "order",
    INVOICE_FILE = "invoiceFile",
    CREDITOR_INFO = "creditorInfo",
    INSERT_USER_ID = "insertUserId",
    INVOICE_INFO = "invoiceInfo",
    WORKFLOW_USERS = "workflowUsers"
}

export enum INVOICE_PAYMENT_INFO_PROPERTY {
    IBAN = "iban",
    TYPE = "type",
    REFERENCE = "reference"
}

export enum INVOICE_CREDITOR_INFO_PROPERTY {
    NAME = "name",
    STREET = "street",
    HOUSNUMBER = "housnumber",
    ZIP = "zip",
    CITY = "city",
    COUNTRY = "country",
    UID = "uid"
}

export enum INVOICE_FIELD_TYPE {
    GENERAL,
    PAYMENT_INFO,
    CREDITOR_INFO,
    INVOICE_INFO
}

export enum SORT {
    DATE_NEWEST = "dateNewest",
    DATE_OLDEST = "dateOldest"
}

export enum WORKFLOWUSER_ROLE {
    NONE = 0,
    VISA_1 = 1,
    VISA_2 = 2,
    VISA_3 = 3,
    ACCOUNTANT = 4,
    REJECTED_TO = 9,
    CANCELLED_BY = 10
}

export enum LOADING_TYPE {
    NONE,
    LOADING_INVOICE_DETAILS,
    SAVING_INVOICE,
    REALESTATE_THUMBNAIL,
    TENANT_INFORMATION,
    OBJECT_UNITS
}

export enum INVOICE_FILE_TYPE {
    INVOICE = 600
}

export enum INVOICE_FILE_STATE {
    NONE,
    NEW,
    LOADED_FROM_DB,
    HAS_CHANGED,
    WAS_DELETED
}
