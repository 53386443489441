import React, { useEffect, useState, RefObject } from "react";
import ReactDOM from "react-dom";

interface TooltipProps {
    children: React.ReactNode;
    targetRef: RefObject<HTMLElement>;
    classes?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ children, targetRef, classes }) => {
    const [show, setShow] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        const handleMouseEnter = () => {
            if (targetRef.current) {
                const rect = targetRef.current.getBoundingClientRect();
                setPosition({
                    top: rect.bottom,
                    left: rect.left
                });
                setShow(true);
            }
        };

        const handleMouseLeave = () => {
            setShow(false);
        };

        const targetNode = targetRef.current;
        if (targetNode) {
            targetNode.addEventListener("mouseenter", handleMouseEnter);
            targetNode.addEventListener("mouseleave", handleMouseLeave);
        }

        return () => {
            if (targetNode) {
                targetNode.removeEventListener("mouseenter", handleMouseEnter);
                targetNode.removeEventListener("mouseleave", handleMouseLeave);
            }
        };
    }, [targetRef]); // Dependency on targetRef, it should be stable across renders

    return show
        ? ReactDOM.createPortal(
              <div
                  className={classes}
                  style={{
                      position: "fixed",
                      top: position.top,
                      left: position.left,
                      backgroundColor: "white",
                      border: "1px solid black",
                      padding: "10px",
                      zIndex: 1050
                  }}
              >
                  {children}
              </div>,
              document.body
          )
        : null;
};
