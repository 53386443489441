import { observer } from "mobx-react";
import React, { useContext, useCallback } from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { Auth } from "src/screens/auth";
import { RootStoreContext } from "src/stores/RootStore";

export const PrivateRouteBase = ({ children, component, ...rest }: RouteProps) => {
    const { authStore } = useContext(RootStoreContext);

    const renderComponent = useCallback(
        () => (authStore.isLoggedIn === true ? children : <Redirect to="/" />),
        [authStore.isLoggedIn, children]
    );

    if (!authStore.isLoggedIn) {
        authStore.setEntryPath(`${window.location.pathname}${window.location.search}`);
        authStore.setEntryUri(window.location.href);

        return <Route {...rest} component={Auth.Login} />;
    }

    const routerComponent = component ? component : undefined;
    if (routerComponent) {
        return <Route {...rest} component={routerComponent} />;
    } else {
        return <Route {...rest} render={renderComponent} />;
    }
};

export const PrivateRoute = observer(PrivateRouteBase);
