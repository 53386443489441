import React, { useEffect } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";

interface ErrorEmptyDataProps {
    message: string;
}

const ErrorEmptyDataBase = (props: ErrorEmptyDataProps) => {
    useEffect(() => {
        Sentry.captureMessage("404 - Empty data", (scope) =>
            scope.setExtras({ url: window.location.href, message: props.message })
        );
    });

    return (
        <UI.Container className="pt-4">
            <UI.Row>
                <UI.Col className="text-center mt-4 pt-4">
                    <UI.Card>{props.message}</UI.Card>
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};

export const ErrorEmptyData = Sentry.withProfiler(ErrorEmptyDataBase);
