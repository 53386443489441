import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { UI } from "@wwimmo/ui";
import { withRouter } from "react-router-dom";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import AppStoreBadge from "src/assets/images/store-badges/app_store_badge.png";
import MicrosoftStoreBadge from "src/assets/images/store-badges/microsoft_store_badge.png";
import GooglePlayBadge from "src/assets/images/store-badges/google_play_badge.png";
import { ErpType } from "src/network/User";
import styles from "./InspectionAppHint.module.css";

const IT2_GOOGLE_PLAYSTORE_URL = "https://play.google.com/store/apps/details?id=ch.wwimmo.inspection.immotop";
const R5_GOOGLE_PLAYSTORE_URL = "https://play.google.com/store/apps/details?id=ch.wwimmo.inspection.rimo";
const FAIRWALTER_GOOGLE_PLAYSTORE_URL = "https://play.google.com/store/apps/details?id=ch.wwimmo.inspection.fairwalter";

const IT2_MICROSOFT_STORE_URL = "https://www.microsoft.com/store/apps/9pk4z59d5jdc";
const R5_MICROSOFT_STORE_URL = "https://www.microsoft.com/store/apps/9n32f74bkdnl";
const FAIRWALTER_MICROSOFT_STORE_URL = "https://www.microsoft.com/store/apps/9PK4Z59D5JDC";

const IT2_APPLE_APP_STORE_URL = "https://apps.apple.com/ch/app/immotop2-abnahme/id1496358864";
const R5_APPLE_APP_STORE_URL = "https://apps.apple.com/ch/app/rimo-r5-abnahme/id1500396099";
const FAIRWALTER_APPLE_APP_STORE_URL = "https://apps.apple.com/ch/app/fairwalter-abnahme/id6450003275";

export const InspectionAppHintBase = () => {
    const { uiStore, navStore, authStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const erpType = authStore.user?.erpType;

    useEffect(() => {
        runInAction(() => {
            uiStore.setShowSearchAppbarButton(false);
            uiStore.setShowProfileAppbarButton(false);

            navStore.setTitle(
                erpType === ErpType.IT2
                    ? t("screens.inspectionhint.title_it2")
                    : erpType === ErpType.FAIRWALTER
                    ? t("screens.inspectionhint.title_fairwalter")
                    : t("screens.inspectionhint.title_r5")
            );

            navStore.setBackbutton(null);

            navStore.setActionButtonMiddle(null);
            navStore.setActionButtonRight(null);
            navStore.setActionButtonLeft(null);
        });
        return () => {
            uiStore.setShowSearchAppbarButton(true);
            uiStore.setShowProfileAppbarButton(true);
        };
    });

    return (
        <UI.Container className="pt-4 mt-4">
            <UI.Row>
                <UI.Col xs={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1 }} xl={{ span: 6, offset: 3 }}>
                    <UI.Card>
                        <div className="inspection-hint-container mt-3">
                            <p>{t("screens.inspectionhint.hint")}</p>
                            <UI.Row className="mt-4">
                                <UI.Col sm={4} className={styles.StoreBadgeItem}>
                                    <a
                                        href={
                                            erpType === ErpType.IT2
                                                ? IT2_GOOGLE_PLAYSTORE_URL
                                                : erpType === ErpType.FAIRWALTER
                                                ? FAIRWALTER_GOOGLE_PLAYSTORE_URL
                                                : R5_GOOGLE_PLAYSTORE_URL
                                        }
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <img
                                            className={styles.StoreBadge}
                                            src={GooglePlayBadge}
                                            alt={"Google PlayStore Badge"}
                                        />
                                    </a>
                                </UI.Col>
                                <UI.Col sm={4} className={styles.StoreBadgeItem}>
                                    <a
                                        href={
                                            erpType === ErpType.IT2
                                                ? IT2_MICROSOFT_STORE_URL
                                                : erpType === ErpType.FAIRWALTER
                                                ? FAIRWALTER_MICROSOFT_STORE_URL
                                                : R5_MICROSOFT_STORE_URL
                                        }
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <img
                                            className={styles.StoreBadge}
                                            src={MicrosoftStoreBadge}
                                            alt={"Microsoft Store Badge"}
                                        />
                                    </a>
                                </UI.Col>
                                <UI.Col sm={4} className={styles.StoreBadgeItem}>
                                    <a
                                        href={
                                            erpType === ErpType.IT2
                                                ? IT2_APPLE_APP_STORE_URL
                                                : erpType === ErpType.FAIRWALTER
                                                ? FAIRWALTER_APPLE_APP_STORE_URL
                                                : R5_APPLE_APP_STORE_URL
                                        }
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <img
                                            className={styles.StoreBadge}
                                            src={AppStoreBadge}
                                            alt={"Apple App Store Badge"}
                                        />
                                    </a>
                                </UI.Col>
                            </UI.Row>
                        </div>
                    </UI.Card>
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};
export const InspectionAppHint = Sentry.withProfiler(withRouter(observer(InspectionAppHintBase)));
