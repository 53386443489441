import React, { useCallback, useState } from "react";

export enum IMAGE_STATE {
    INIT = 0,
    LOADED = 1,
    ERROR = 2
}

export enum IMAGE_ORIENTATION {
    PORTRAIT,
    LANDSCAPE
}

const MIN_IMAGE_DIMENSION = 250;

export const useNewsImage = (imageUrl: string) => {
    const [imageState, setImageState] = useState<IMAGE_STATE>(IMAGE_STATE.INIT);
    const [imageOrientation, setImageOrientation] = useState<IMAGE_ORIENTATION>(IMAGE_ORIENTATION.PORTRAIT);
    const [imageContainerMinHeight, setImageContainerMinHeight] = useState<number | undefined>(undefined);

    const onImageLoaded = useCallback((e: any) => {
        const originalImageHeight = e.target.naturalHeight;
        const originalImageWidth = e.target.naturalWidth;

        if (originalImageWidth > originalImageHeight) {
            setImageOrientation(IMAGE_ORIENTATION.LANDSCAPE);
            const minImageHeight = (MIN_IMAGE_DIMENSION / originalImageWidth) * originalImageHeight;
            setImageContainerMinHeight(Number(minImageHeight));
        } else {
            setImageOrientation(IMAGE_ORIENTATION.PORTRAIT);
            setImageContainerMinHeight(MIN_IMAGE_DIMENSION);
        }

        setImageState(IMAGE_STATE.LOADED);
    }, []);

    const onError = useCallback(() => {
        setImageState(IMAGE_STATE.ERROR);
    }, []);

    const image = <img onLoad={onImageLoaded} onError={onError} src={imageUrl} alt="preview" />;

    return {
        image,
        imageState,
        imageOrientation,
        imageContainerMinHeight
    };
};
