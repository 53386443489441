import { ToastContent, ToastOptions } from "react-toastify";
import { GetAssemblyByID_agendaitem_parents_agendaitemfiles } from "./api/generated/GetAssemblyByID";
import {
    GetUmsCustomer_ums_customers_options,
    GetUmsCustomer_ums_customers_customerfeatures,
    GetUmsCustomer_ums_customers_users_isSidebarCollapsed,
    GetUmsCustomer_ums_customers_users_invoicePaneWidth
} from "./api/generated/GetUmsCustomer";

export interface BackButton {
    label: string;
    to: string;
}

export interface Customer {
    name: string;
    userName: string;
    email: string;
    options: GetUmsCustomer_ums_customers_options[];
    features?: GetUmsCustomer_ums_customers_customerfeatures[];
    activatedTiles?: number[];
    partner?: number;
    apiversion?: number;
    licencedunits?: number;
    settings?: ICustomerSettings;
}

export interface ICustomerSettings {
    cred?: {
        numberOfVisas?: number;
    };
}

export interface User {
    isSidebarCollapsedUserSetting?: GetUmsCustomer_ums_customers_users_isSidebarCollapsed;
    invoicePaneWidthUserSetting?: GetUmsCustomer_ums_customers_users_invoicePaneWidth;
}

export interface ActionButtons {
    left: React.ReactNode | null;
    middle: React.ReactNode | null;
    right: React.ReactNode | null;
}

export interface Notification {
    content: ToastContent;
    options?: ToastOptions;
    key?: number;
}

export interface Announcement {
    id?: string;
    teaser?: string;
    content?: string;
}

export interface AgendaItemChild {
    id: string;
    sort: number;
    text: string;
    description: string;
    isNew?: boolean;
    agendaitemfiles: GetAssemblyByID_agendaitem_parents_agendaitemfiles[];
}
export interface AgendaItem {
    id: string;
    sort: number;
    text: string;
    description: string;
    isNew?: boolean;
    agendaitemfiles: GetAssemblyByID_agendaitem_parents_agendaitemfiles[];
    agendaitemchild?: AgendaItemChild[];
}

export interface InvoiceDetail {
    id: string;
    yearAndMonth: string;
    amount: number;
    invoiced: string;
    selected?: boolean;
}

export enum ApplicationType {
    R5 = 1,
    IT2 = 2,
    InspectionApp = 3,
    Portal = 4,
    EasyContact = 5
}

export enum UserRatingStates {
    INACTIVE = 0,
    ACTIVE = 1,
    DEFERRED = 2,
    DEACTIVATED = 9
}
