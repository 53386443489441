import { UI } from "@wwimmo/ui";
import styles from "./InfoCard.module.css";
import { NotificationStatusModal } from "src/components/infoCard/notificationStatus/NotificationStatusModal";

export interface InfoCardProps {
    infoItems: { title: string; value: any; loading?: boolean }[];
    infoCardClassNames?: string;
}

export const InfoCard = (props: InfoCardProps) => {
    const { infoItems, infoCardClassNames } = props;

    return (
        <div className={infoCardClassNames ?? ""}>
            {infoItems.map((infoItem, index) => (
                <div className={`${styles.InfoItem} info-item`} key={`info-item-${index}`}>
                    <div className={`${styles.InfoItemTitle} info-item-title`}>{infoItem.title}</div>
                    <div className={`${styles.InfoItemValue} info-item-value`}>
                        {infoItem.value}
                        {infoItem.loading !== undefined && infoItem.loading === true ? (
                            <UI.RotatingSpinner className={styles.ImageLoadSpinner} size={10} noLogo />
                        ) : undefined}
                    </div>
                </div>
            ))}
            <NotificationStatusModal />
        </div>
    );
};
