import React from "react";
import { formatCurrency } from "src/utils/Currency";
import { ColorStyle } from "src/utils/Colors";
import { ChartDataType } from "src/components/tiles/Tile";
import { useTranslation } from "react-i18next";

interface ComparsionProps {
    data: ComparsionData;
}

interface ComparsionData {
    labels: string[];
    datasets: {
        label: string;
        data: number;
        valuetype: ChartDataType;
    }[];
}

export const Comparsion = (props: ComparsionProps) => {
    const { t } = useTranslation();

    if (props.data.datasets.length === 0) {
        return (
            <div className="Comparsion">
                <div className="small mx-auto mt-3">{t("error.emptydata")}</div>
            </div>
        );
    }
    if (props.data.datasets.length === 3) {
        const getFormat = (data: number, type: ChartDataType, prefix: boolean = false) => {
            let value = String(data);
            if (type === ChartDataType.AMOUNT) {
                value = formatCurrency(data);
            }
            if (type === ChartDataType.PERCENTAGE) {
                value = String(data) + "%";
            }
            return (prefix ? (data > 0 ? "+" : "") : "") + value;
        };

        const getValuesByIndex = (index: number) => {
            return props.data.datasets[index];
        };

        const values = {
            left: {
                label: getValuesByIndex(0).label,
                value: getFormat(getValuesByIndex(0).data, getValuesByIndex(0).valuetype)
            },
            right: {
                label: getValuesByIndex(1).label,
                value: getFormat(getValuesByIndex(1).data, getValuesByIndex(1).valuetype)
            },
            comparsionValue: getFormat(getValuesByIndex(2).data, getValuesByIndex(2).valuetype, true)
        };

        return (
            <div className="Comparsion">
                <div className="ComparsionItems d-flex flex-row justify-content-between">
                    <div className="ComparsionItem">
                        <h3 style={{ color: ColorStyle("secondary") }}>{values.left.label}</h3>
                        <h2
                            style={{
                                color: ColorStyle("secondary")
                            }}
                        >
                            {values.left.value}
                        </h2>
                    </div>
                    <div className="ComparsionItem">
                        <h3 style={{ color: ColorStyle("secondary") }}>{values.right.label}</h3>
                        <h2
                            style={{
                                color: ColorStyle("secondary")
                            }}
                        >
                            {values.right.value}
                        </h2>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <h1>{values.comparsionValue}</h1>
                </div>
            </div>
        );
    } else {
        return (
            <div className="small mx-auto mt-3">
                {t("error.invalid-data")}: 3 Datensätze erwartet, erhalten: {props.data.datasets.length}
            </div>
        );
    }
};
