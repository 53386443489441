import React, { useEffect, useState, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { getLang } from "src/utils/i18n";
import * as Screens from "src/screens";
import { GET_CUSTOMER_INVOICES_BY_DATE } from "src/api/invoice";
import {
    GetCustomerInvoicesByDate as customerinvoices,
    GetCustomerInvoicesByDate_costtype_enumeration as consttypeEnumeration
} from "src/api/generated/GetCustomerInvoicesByDate";
import { Tiles } from "src/components";
import { ChartDataType } from "src/components/tiles/Tile";
import { dateFormat, getDateWithMonthAndYear } from "src/utils/Date";
import { contrastColor, getCssRootValue } from "src/utils/Colors";
import { v4 as uuidv4 } from "uuid";
import { getDate } from "src/utils/Date";

interface PieHint {
    title: string;
    label: string;
}
interface PieData {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string[];
        textColor: string[];
        hint?: PieHint[];
    }[];
    valuetype: ChartDataType;
}

export const getDateForHasuraQuery = (timestamp: string) => {
    if (timestamp) {
        return dateFormat(new Date(timestamp), "yyyy-MM-dd");
    }
};

const colors = [getCssRootValue("primary"), getCssRootValue("primary100"), getCssRootValue("primary400")];

enum COST_TYPE {
    USERS = 1,
    EMONITOR_VAR = 2,
    EMONITOR_FIX = 3
}

const CostofCurrentMonthChartBase = () => {
    const { t } = useTranslation();
    const [pieCostOfCurrentMonth, setPieCostOfCurrentMonth] = useState<PieData>();

    const currentDate = new Date();
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).toString();

    const [costTypeLabel, setCostTypeLabel] = useState<Map<number | null, { label: string }>>();

    const { data, loading, error } = useQuery<customerinvoices>(GET_CUSTOMER_INVOICES_BY_DATE, {
        variables: {
            language: getLang(),
            date: getDateForHasuraQuery(lastDayOfMonth)
        }
    });

    useEffect(() => {
        if (data) {
            setCostTypeLabel(getEnumerationMap(data.costtype_enumeration));
        }
    }, [data]);

    const getEnumerationMap = (enumerations: Array<consttypeEnumeration>) => {
        const enumerationMap = new Map();
        enumerations.forEach((enumeration) => {
            if (enumeration.key !== null) {
                enumerationMap.set(enumeration.key, {
                    label: enumeration.label
                });
            }
        });

        return enumerationMap;
    };

    const getCostOfCurrentMonthPieData = useCallback(() => {
        let totalCostsPerCostType: any[] = [];
        const invoiceLabels: any[] = [];
        let userCost = 0;
        let emonitorCost = 0;
        let totalCosts: any = 0;

        data!.customerinvoices.forEach((invoice) => {
            totalCosts += invoice.costperunit * invoice.count!;
            if (invoice.costtype === COST_TYPE.EMONITOR_FIX || invoice.costtype === COST_TYPE.EMONITOR_VAR) {
                emonitorCost += Number(invoice.costperunit * invoice.count!);
            } else if (invoice.costtype === COST_TYPE.USERS) {
                userCost = Number(invoice.costperunit * invoice.count!);
                invoiceLabels.push(costTypeLabel?.get(invoice.costtype)?.label);
            }
        });
        totalCostsPerCostType = [userCost.toFixed(2), emonitorCost.toFixed(2)];
        invoiceLabels.push(t("screens.services.costs_and_invoices.overview.costtype_emonitor"));

        const totalCostsPerCostTypeInPercent = totalCostsPerCostType.map((costsPerUnit) => {
            return Number(((costsPerUnit / totalCosts) * 100).toFixed(0));
        });
        const costsPerUnitHints = [
            { label: totalCostsPerCostType[0], title: invoiceLabels[0] },
            { label: totalCostsPerCostType[1], title: invoiceLabels[1] }
        ];

        const pieData: PieData = {
            labels: invoiceLabels,
            datasets: [
                {
                    backgroundColor: [colors[0], colors[1], colors[2]],
                    data: totalCostsPerCostTypeInPercent,
                    label: uuidv4(),
                    textColor: [contrastColor(colors[0]), contrastColor(colors[1]), contrastColor(colors[2])],
                    hint: costsPerUnitHints
                }
            ],
            valuetype: 1
        };
        return pieData;
    }, [costTypeLabel, data, t]);

    useEffect(() => {
        if (data) {
            if (data.customerinvoices.length > 0) {
                setPieCostOfCurrentMonth(getCostOfCurrentMonthPieData);
            }
        }
    }, [data, getCostOfCurrentMonthPieData]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    return (
        <>
            {pieCostOfCurrentMonth ? (
                <UI.Col sm={6} lg={4} xl={3}>
                    <UI.Card
                        title={`${t("screens.services.costs_and_invoices.overview.costs")} ${getDateWithMonthAndYear(
                            currentDate.toString()
                        )}`}
                        square
                        subtitle={`${t("screens.services.costs_and_invoices.overview.subtitle")} ${getDate(Date())}`}
                    >
                        <Tiles.Pie data={pieCostOfCurrentMonth} />
                    </UI.Card>
                </UI.Col>
            ) : undefined}
        </>
    );
};

export const CostofCurrentMonthChart = Sentry.withProfiler(observer(CostofCurrentMonthChartBase));
