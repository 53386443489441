import React, { useCallback, useContext, useEffect, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { Trans, useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { ScreenSize, UI } from "@wwimmo/ui";
import { TICKET_STATE, Ticket } from "src/stores/TicketStore";
import { FilterStateType } from "src/stores/tickets/TicketListStore";
import { TicketListRowDesktop } from "./TicketListRowDesktop";
import { TicketListRowMobile } from "./TicketListRowMobile";
import styles from "./TicketsList.module.css";
import { Route, selectRoute } from "src/config/routes";
import { useQuery } from "@apollo/client";
import {
    GetRealestateUserAndAccessData,
    GetRealestateUserAndAccessData_realestateUsersAndAccess
} from "src/api/generated/GetRealestateUserAndAccessData";
import { GET_REALESTATEUSER_AND_ACCESS } from "src/api/ticket";
import { SCREEN_SIZE } from "src/stores/UIStore";

interface TicketListProps {
    onFilterChange: (id: FilterStateType) => void;
}

const TicketsListBase = (props: TicketListProps) => {
    const { onFilterChange } = props;
    const { t } = useTranslation();
    const { uiStore, ticketListStore, authStore, ticketStore } = useContext(RootStoreContext);

    const { data } = useQuery<GetRealestateUserAndAccessData, GetRealestateUserAndAccessData_realestateUsersAndAccess>(
        GET_REALESTATEUSER_AND_ACCESS,
        {}
    );

    useEffect(() => {
        ticketListStore.setRealestateUserData(data?.realestateUsersAndAccess || []);
    }, [ticketListStore, data?.realestateUsersAndAccess]);

    const getColumnWidths = useCallback(() => {
        const columnWidthsDesktop = [0.04, 0.04, 0.1, 0.18, 0.15, 0.18, 0.18, 0.125];
        const columnWidthsLaptopL = [0, 0.06, 0.1, 0.18, 0.15, 0.18, 0.18, 0.125];
        const columnWidthsLaptop = [0, 0.08, 0.1, 0.2, 0.22, 0.26, 0.18, 0];
        const columnWidthsTablet = [0, 0.1, 0.1, 0.28, 0.27, 0.26, 0.18, 0];

        return uiStore.currentScreenSize > SCREEN_SIZE.LAPTOP_L
            ? columnWidthsDesktop
            : uiStore.currentScreenSize === SCREEN_SIZE.LAPTOP_L
            ? columnWidthsLaptopL
            : uiStore.currentScreenSize === SCREEN_SIZE.LAPTOP
            ? columnWidthsLaptop
            : columnWidthsTablet;
    }, [uiStore.currentScreenSize]);

    const columnWidths = getColumnWidths();

    const renderItem = useCallback(
        (ticket: Ticket, screenSize: ScreenSize) => {
            const columnWidths = getColumnWidths();

            const listRows = [
                screenSize === ScreenSize.MOBILE ? (
                    <TicketListRowMobile ticket={ticket} key={`row-${ticket.number}`} />
                ) : (
                    <TicketListRowDesktop ticket={ticket} key={`row-${ticket.number}`} widths={columnWidths} />
                )
            ];
            return (
                <UI.List.Item
                    key={`item-${ticket.number}`}
                    screenSize={screenSize}
                    rows={listRows}
                    className={styles.ListItem}
                    isClickableItemWithoutChevron
                    to={
                        ticket.number
                            ? selectRoute(Route.ticketDetails, authStore.user?.role, {
                                  ticketnumber: String(ticket.number)
                              })
                            : Route.tickets
                    }
                />
            );
        },
        [authStore.user?.role, getColumnWidths]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            if (screenSize === ScreenSize.MOBILE) {
                return <></>;
            }
            const rows = [
                <UI.List.Cell
                    className={`${uiStore.currentScreenSize <= SCREEN_SIZE.LAPTOP_L ? "d-none" : "d-flex"}`}
                    key="type"
                    colspan={columnWidths[0]}
                    value=""
                    truncate
                />,
                <UI.List.Cell
                    key="nr"
                    colspan={columnWidths[1]}
                    value={<Trans>screens.tickets.list.nr</Trans>}
                    truncate
                />,
                <UI.List.Cell
                    key="date"
                    colspan={columnWidths[2]}
                    value={<Trans>screens.tickets.list.date</Trans>}
                    truncate
                />,
                <UI.List.Cell
                    key="label"
                    colspan={columnWidths[3]}
                    value={<Trans>screens.tickets.list.label</Trans>}
                    truncate
                />,
                <UI.List.Cell
                    key="realestate"
                    colspan={columnWidths[4]}
                    value={<Trans>screens.tickets.list.realestate</Trans>}
                    truncate
                />,
                <UI.List.Cell
                    className={`${uiStore.currentScreenSize < SCREEN_SIZE.LAPTOP ? "d-none" : "d-flex"}`}
                    key="incidentpersons"
                    colspan={columnWidths[5]}
                    value={<Trans>screens.tickets.list.incidentpersons</Trans>}
                    truncate
                />,
                <UI.List.Cell
                    key="assigned"
                    colspan={columnWidths[6]}
                    value={<Trans>screens.tickets.list.assigned</Trans>}
                    truncate
                />,
                <UI.List.Cell
                    key="status"
                    className={`${uiStore.currentScreenSize <= SCREEN_SIZE.LAPTOP ? "d-none" : "d-flex"}`}
                    colspan={columnWidths[7]}
                    value={<Trans>screens.tickets.list.status</Trans>}
                    truncate
                />
            ];
            return <UI.List.Header rows={rows} className="p-0" />;
        },
        [columnWidths, uiStore.currentScreenSize]
    );

    const stats = ticketListStore.ticketStats;

    const onTabsChange = useCallback(
        (id: string) => {
            onFilterChange(id as FilterStateType);
        },
        [onFilterChange]
    );
    const onSelectDropdown = useCallback(
        (id: string) => {
            onFilterChange(id as FilterStateType);
        },
        [onFilterChange]
    );

    const filterStateItems = useMemo(
        () => [
            {
                label: `${t("easyticket.states.new")} ${
                    ticketStore.initialLoadAllTicketsCompleted ? `(${stats.new})` : ""
                }`,
                id: TICKET_STATE.NEW
            },
            {
                label: `${t("easyticket.states.assigned")} ${
                    ticketStore.initialLoadAllTicketsCompleted ? `(${stats.assigned})` : ""
                }`,
                id: TICKET_STATE.ASSIGNED
            },
            {
                label: `${t("easyticket.states.comissioned")} ${
                    ticketStore.initialLoadAllTicketsCompleted ? `(${stats.comissioned})` : ""
                }`,
                id: TICKET_STATE.COMISSIONED
            },
            {
                label: `${t("easyticket.states.completed")} ${
                    ticketStore.initialLoadAllTicketsCompleted ? `(${stats.completed})` : ""
                }`,
                id: TICKET_STATE.COMPLETED
            },
            {
                label: `${t("labels.all")} ${
                    ticketStore.initialLoadAllTicketsCompleted ? `(${stats.total.filtered})` : ""
                }`,
                id: "All"
            }
        ],
        [t, stats, ticketStore.initialLoadAllTicketsCompleted]
    );

    return (
        <UI.Card flex={true}>
            {uiStore.isMobile ? (
                <UI.Dropdown
                    id="sort"
                    onSelect={onSelectDropdown}
                    items={filterStateItems.map((item) => ({ label: item.label, value: item.id }))}
                    label={filterStateItems.find((item) => item.id === ticketListStore.ticketFilter.state)?.label ?? ""}
                    className="mb-2"
                />
            ) : (
                <div className={styles.Tabbar}>
                    <UI.Tabs
                        selectedId={ticketListStore.ticketFilter.state}
                        onTabChange={onTabsChange}
                        contents={filterStateItems.map((item) => ({ ...item }))}
                        nowrap
                    ></UI.Tabs>
                </div>
            )}
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={ticketListStore.filteredTickets.filter((ticket) => {
                    if (ticketListStore.ticketFilter.state === "All") {
                        return true;
                    } else {
                        return ticket.state === ticketListStore.ticketFilter.state;
                    }
                })}
                renderItem={renderItem}
                renderHeader={renderHeader}
                itemsPerPage={20}
                emptyListMessage={
                    ticketStore.tickets.length === 0
                        ? t("screens.tickets.list.tickets_are_loading").toString()
                        : t("screens.tickets.list.no_tickets_found").toString()
                }
            />
        </UI.Card>
    );
};

export const TicketsList = Sentry.withProfiler(observer(TicketsListBase));
