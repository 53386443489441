import React, { useCallback, useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { withRouter, RouteComponentProps, useHistory } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import { selectRoute, Route } from "src/config/routes";
import { GET_DEMO_PORTAL_SETTINGS, GET_STWEG_DEMO_DATA_BY_SUBDOMAIN } from "src/api/demoPortal";
import { GetDemoPortalSettings, GetDemoPortalSettingsVariables } from "src/api/generated/GetDemoPortalSettings";
import { useQuery } from "@apollo/client";
import * as Screens from "src/screens";
import { getLang } from "src/utils/i18n";
import { PortalDemoFormSettings } from "./form/PortalDemoSettingsForm";
import styles from "./PortalDemoSettings.module.css";
import { ContactItem, ContactItemRow } from "src/components/contact/contactitem/ContactItem";
import {
    GetStwegDemoDataBySubdomain,
    GetStwegDemoDataBySubdomainVariables
} from "src/api/generated/GetStwegDemoDataBySubdomain";
import { ContactItemType } from "src/screens/portalDemo/StwegDemoPage";
import { ScreenWidth } from "src/stores/UIStore";

const PortalDemoSettingsBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);
    const history = useHistory();

    const host = window.location.host;
    const isLocal = host.indexOf("localhost") === 0;
    const subdomain = isLocal ? "dev" : window.location.host.split(".")[0];
    const {
        data: stwegData,
        loading: stwegDataLoading,
        error: stwegDataError,
        refetch: refetchsStweg
    } = useQuery<GetStwegDemoDataBySubdomain, GetStwegDemoDataBySubdomainVariables>(GET_STWEG_DEMO_DATA_BY_SUBDOMAIN, {
        variables: {
            subdomain: subdomain,
            language: getLang()
        }
    });

    const { data, loading, error, refetch } = useQuery<GetDemoPortalSettings, GetDemoPortalSettingsVariables>(
        GET_DEMO_PORTAL_SETTINGS,
        {
            variables: {
                lang: getLang()
            }
        }
    );

    useEffect(() => {
        navStore.setTitle(t("sidebar.settings.portal_demo"));
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);

        if (uiStore.isSidebarActive) {
            navStore.setBackbutton(null);
        } else {
            navStore.setBackbutton(selectRoute(Route.profile), t("screens.account.title").toString());
        }
    }, [navStore, t, authStore.user?.role, uiStore.isSidebarActive]);

    const onClickOnlineContact = useCallback(() => {
        window.open(`https://${subdomain}.wwportal.ch/ec/start`, "_blank");
    }, [subdomain]);

    const onClickDisplayDemoStwegPortal = useCallback(
        (e: any) => {
            e.preventDefault();
            const newTab = window.open(Route.stwegDemoPage, "_blank");
            if (newTab) {
                newTab.focus();
            } else {
                history.push(Route.stwegDemoPage);
            }
        },
        [history]
    );

    const onClickCopyDemoPortalLink = useCallback(() => {
        const copyToClipboard = `https://${subdomain}.wwportal.ch/demo/stweg`;
        navigator.clipboard.writeText(copyToClipboard);
    }, [subdomain]);

    const onClickCopyLoginLink = useCallback(() => {
        const copyToClipboard = `https://${subdomain}.wwportal.ch/ec/process/5030e670-5630-3acc-2857-eb9790920da0/5/5030e670-5630-3acc-2857-eb9790920da1?loginrequest=true`;
        navigator.clipboard.writeText(copyToClipboard);
    }, [subdomain]);

    if (loading || stwegDataLoading) {
        return <Screens.Loading />;
    }
    if (error || stwegDataError) {
        return (
            <Screens.Error
                message={error?.message || stwegDataError?.message}
                networkError={error?.networkError || stwegDataError?.networkError}
            />
        );
    }
    if (!data || !stwegData) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const content: Array<ContactItemRow> = [
        { type: ContactItemType.PHONE, content: stwegData?.ums_v_demoportalsettings[0]?.phone },
        { type: ContactItemType.DEVICE, content: stwegData?.ums_v_demoportalsettings[0]?.mobile },
        { type: ContactItemType.MAIL, content: stwegData?.ums_v_demoportalsettings[0]?.email }
    ];

    const renderContactAndLinkCards = () => {
        return (
            <React.Fragment>
                <UI.Card className={styles.card} title="Links">
                    <UI.Button
                        className={styles.displayButton}
                        label={t("screens.portalDemo.display")}
                        onClick={onClickDisplayDemoStwegPortal}
                    />
                    <UI.Button
                        className={styles.copyButton}
                        label={"Link Portal Demo kopieren"}
                        onClick={onClickCopyDemoPortalLink}
                    />
                    <UI.Button
                        className={styles.copyButton}
                        label={"Link Registrierungsanfrage kopieren"}
                        onClick={onClickCopyLoginLink}
                    />
                </UI.Card>
                <UI.Card className={styles.card} title="Kontakt">
                    <UI.Button
                        className={styles.onlineContactButton}
                        label={t("screens.portalDemo.online_contact")!}
                        onClick={onClickOnlineContact}
                    />
                    <div className={styles.contactPerson}>{t("screens.portalDemo.contactperson")}</div>
                    <ContactItem name={stwegData?.ums_v_demoportalsettings[0]?.contactperson!} contacts={content} />
                </UI.Card>
            </React.Fragment>
        );
    };

    return (
        <UI.Container className="pt-4">
            <UI.Row>
                <UI.Col xl={9}>
                    <UI.Title>{t("sidebar.settings.portal_demo")}</UI.Title>
                </UI.Col>
            </UI.Row>
            <UI.Row>
                <UI.Col xl={9} className="position-relative">
                    <PortalDemoFormSettings
                        portalDemoSettings={data.ums_demoportalsettings[0]}
                        refetchPortalDemoSettings={refetch}
                        refetchStwegDemoData={refetchsStweg}
                    />
                </UI.Col>
                {window.innerWidth >= ScreenWidth.XL ? (
                    <UI.Col xl={3}>{renderContactAndLinkCards()}</UI.Col>
                ) : (
                    <UI.Col sm={12}>{renderContactAndLinkCards()}</UI.Col>
                )}
            </UI.Row>
        </UI.Container>
    );
};

export const PortalDemoSettings = withRouter(Sentry.withProfiler(observer(PortalDemoSettingsBase)));
