import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavigationbarItem } from "src/components/header/navigationbar/NavigationbarItem";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import "./Navigationbar.css";
import { Route, selectRoute } from "src/config/routes";
import { ErpType } from "src/network/User";
import { UI } from "@wwimmo/ui";
import { Role } from "src/network/User";
import { useLocation } from "react-router-dom";

interface NavigationbarProps {
    realestateId?: string;
    isConsolidation?: boolean;
    isCondominium?: boolean;
    navigationbarType: NAVIGATIONBAR_TYPE;
    ticketNumber?: string;
    orderNumber?: string;
    invoiceNumber?: string;
    isRealestatefeatureTenant?: boolean;
}

export enum NAVIGATIONBAR_TYPE {
    PORTAL,
    SERVICES_COSTS_AND_INVOICES,
    SERVICES_FEATURES_AND_INTEGRATIONS,
    TICKET,
    ORDER,
    INVOICE
}

interface INavigationLink {
    to: string;
    label?: string;
    icon: any;
    isDisabled?: boolean;
}

const NavigationbarBase = (props: NavigationbarProps) => {
    const { authStore, uiStore, navStore } = useContext(RootStoreContext);
    const { t } = useTranslation();
    const location = useLocation();
    const [rerenderComponent, setRerenderComponent] = useState<boolean>(false);

    useEffect(() => {
        if (uiStore.rerenderNavigationBar) {
            setRerenderComponent(!rerenderComponent);
            uiStore.setRerenderNavigationBar(false);
        }
    }, [uiStore, uiStore.rerenderNavigationBar, rerenderComponent]);

    const { navigationbarType, ticketNumber, orderNumber, invoiceNumber, isRealestatefeatureTenant } = props;

    let navLinks: INavigationLink[] = [];

    switch (navigationbarType) {
        case NAVIGATIONBAR_TYPE.PORTAL:
            navLinks = props.realestateId
                ? [
                      {
                          to: selectRoute(Route.dashboard, authStore.user?.role, { realestateid: props.realestateId }),
                          label: t("screens.realestate.dashboard.title").toString(),
                          icon: UI.SVGIcon.Dashboard
                      },
                      ...(props.isCondominium === false && navStore.hasFeatureTenant && isRealestatefeatureTenant
                          ? [
                                {
                                    to: selectRoute(Route.news, authStore.user?.role, {
                                        realestateid: props.realestateId
                                    }),
                                    label: t("screens.realestate.news_preview.title").toString(),
                                    icon: UI.SVGIcon.News
                                }
                            ]
                          : []),
                      ...(props.isConsolidation === false
                          ? [
                                {
                                    to: selectRoute(Route.units, authStore.user?.role, {
                                        realestateid: props.realestateId
                                    }),
                                    label: props.isCondominium
                                        ? t("screens.realestate.units.co_title").toString()
                                        : t("screens.realestate.units.title").toString(),
                                    icon: UI.SVGIcon.HouseList
                                }
                            ]
                          : []),
                      ...(props.isCondominium === true && authStore.user?.role === Role.MANAGER
                          ? [
                                {
                                    to: selectRoute(Route.managerAssemblies, authStore.user?.role, {
                                        realestateid: props.realestateId ?? ""
                                    }),
                                    label: t("screens.realestate.assemblies.title").toString(),
                                    icon: UI.SVGIcon.Assembly
                                }
                            ]
                          : []),
                      ...(!(authStore.user?.erpType === ErpType.IT2 && props.isConsolidation === false)
                          ? [
                                {
                                    to: selectRoute(Route.finances, authStore.user?.role, {
                                        realestateid: props.realestateId
                                    }),
                                    label: t("screens.realestate.finances.title").toString(),
                                    icon: UI.SVGIcon.Finances
                                }
                            ]
                          : []),
                      {
                          to: selectRoute(Route.documents, authStore.user?.role, { realestateid: props.realestateId }),
                          label: t("screens.realestate.documents.title").toString(),
                          icon: UI.SVGIcon.DocumentsNavBar
                      }
                  ]
                : [
                      {
                          to: selectRoute(Route.owners, authStore.user?.role),
                          label: t("screens.owners.title").toString(),
                          icon: UI.SVGIcon.Owners
                      },
                      {
                          to: selectRoute(Route.realestates, authStore.user?.role),
                          label: t("screens.realestates.title").toString(),
                          icon: UI.SVGIcon.Realestates
                      }
                  ];

            if (authStore.user?.role === Role.COOWNER) {
                navLinks = [
                    {
                        to: selectRoute(Route.dashboard, authStore.user?.role, {
                            realestateid: props.realestateId ?? ""
                        }),
                        label: t("screens.realestate.dashboard.title").toString(),
                        icon: UI.SVGIcon.Dashboard
                    },
                    {
                        to: selectRoute(Route.documents, authStore.user?.role, {
                            realestateid: props.realestateId ?? ""
                        }),
                        label: t("screens.realestate.documents.title").toString(),
                        icon: UI.SVGIcon.DocumentsNavBar
                    },
                    {
                        to: selectRoute(Route.coownerAssemblies, authStore.user?.role, {
                            realestateid: props.realestateId ?? ""
                        }),
                        label: t("screens.realestate.assemblies.title").toString(),
                        icon: UI.SVGIcon.Assembly
                    },
                    {
                        to: selectRoute(Route.coownerAccount, authStore.user?.role, {
                            realestateid: props.realestateId ?? ""
                        }),
                        label: t("screens.realestate.my_account.title").toString(),
                        icon: UI.SVGIcon.Myaccount
                    }
                ];
                if (authStore.user.hasCoownerAuditorRole) {
                    navLinks = [
                        ...navLinks,
                        {
                            to: selectRoute(Route.finances, Role.COOWNER, { realestateid: props.realestateId ?? "" }),
                            label: t("screens.realestate.finances.title").toString(),
                            icon: UI.SVGIcon.Finances
                        }
                    ];
                }
            } else if (authStore.user?.role === Role.AUDITOR) {
                navLinks = [
                    {
                        to: selectRoute(Route.finances, authStore.user?.role, {
                            realestateid: props.realestateId!
                        }),
                        label: t("screens.realestate.finances.title").toString(),
                        icon: UI.SVGIcon.Finances
                    }
                ];
            } else if (authStore.user?.role === Role.TENANT) {
                navLinks = [
                    {
                        to: selectRoute(Route.dashboard, authStore.user?.role, {
                            realestateid: props.realestateId ?? ""
                        }),
                        label: t("screens.realestate.dashboard.title").toString(),
                        icon: UI.SVGIcon.Dashboard
                    },
                    {
                        to: selectRoute(Route.documents, authStore.user?.role, {
                            realestateid: props.realestateId ?? ""
                        }),
                        label: t("screens.realestate.documents.title").toString(),
                        icon: UI.SVGIcon.DocumentsNavBar
                    },
                    {
                        to: selectRoute(Route.tenantAccount, authStore.user?.role, {
                            realestateid: props.realestateId ?? ""
                        }),
                        label: t("screens.realestate.my_account.title").toString(),
                        icon: UI.SVGIcon.Myaccount
                    }
                ];
            }
            break;

        case NAVIGATIONBAR_TYPE.SERVICES_COSTS_AND_INVOICES:
            navLinks = [
                {
                    to: selectRoute(Route.servicesCostsAndInvoicesOverview, authStore.user?.role),
                    label: t("screens.services.costs_and_invoices.overview.title").toString(),
                    icon: UI.SVGIcon.Dashboard
                },
                {
                    to: selectRoute(Route.servicesCostsAndInvoicesCurrentMonth, authStore.user?.role),
                    label: t("screens.services.costs_and_invoices.current_month.title").toString(),
                    icon: UI.SVGIcon.Finances
                },
                {
                    to: selectRoute(Route.servicesCostsAndInvoicesHistory, authStore.user?.role),
                    label: t("screens.services.costs_and_invoices.history.title").toString(),
                    icon: UI.SVGIcon.Time
                }
            ];
            break;

        case NAVIGATIONBAR_TYPE.SERVICES_FEATURES_AND_INTEGRATIONS:
            navLinks = [
                {
                    to: selectRoute(Route.servicesFeatures, authStore.user?.role),
                    label: t("screens.services.features_and_integrations.features.title").toString(),
                    icon: UI.SVGIcon.PuzzlePiece
                },
                {
                    to: selectRoute(Route.servicesIntegrations, authStore.user?.role),
                    label: t("screens.services.features_and_integrations.integrations.nav_title").toString(),
                    icon: UI.SVGIcon.Plug
                }
            ];
            break;

        case NAVIGATIONBAR_TYPE.TICKET:
            navLinks = [
                {
                    to: selectRoute(Route.ticketDetails, authStore.user?.role, {
                        ticketnumber: ticketNumber ?? ""
                    }),
                    icon: UI.SVGIcon.Task
                },
                {
                    to: selectRoute(Route.ticketHistory, authStore.user?.role, {
                        ticketnumber: ticketNumber ?? ""
                    }),
                    icon: UI.SVGIcon.Time
                }
            ];
            break;

        case NAVIGATIONBAR_TYPE.ORDER:
            navLinks = [
                {
                    to: selectRoute(Route.ticketOrderDetails, authStore.user?.role, {
                        ticketnumber: ticketNumber ?? "",
                        ordernumber: orderNumber ?? ""
                    }),
                    icon: UI.SVGIcon.Task
                },
                {
                    to: selectRoute(Route.ticketOrderDetails, authStore.user?.role, {
                        ticketnumber: ticketNumber ?? "",
                        ordernumber: orderNumber ?? ""
                    }),
                    icon: UI.SVGIcon.Time,
                    isDisabled: true
                }
            ];
            break;

        case NAVIGATIONBAR_TYPE.INVOICE:
            navLinks = [
                {
                    to: selectRoute(Route.invoiceDetails, authStore.user?.role, {
                        invoicenumber: invoiceNumber ?? ""
                    }),
                    icon: UI.SVGIcon.Task
                }
                // ,
                // {
                //     to: selectRoute(Route.ticketHistory, authStore.user?.role, {
                //         ticketnumber: ticketNumber ?? ""
                //     }),
                //     icon: UI.SVGIcon.Time
                // }
            ];
            break;
    }

    const isDemoModeAndTicketDetailsPageActive =
        uiStore.isDemoModeTicketingActive && location.pathname.includes(Route.ticket);

    return (
        <>
            <UI.Nav
                className={`Navigationbar ${isDemoModeAndTicketDetailsPageActive ? "TicketingDemoBanner" : ""} ${
                    !uiStore.showNavigationBar ? "hide" : ""
                }`}
                style={{
                    height: `${uiStore.navigationBarHeight}px`,
                    top: !isDemoModeAndTicketDetailsPageActive ? `${uiStore.getHeaderHeight()}px` : undefined
                }}
            >
                <UI.Container>
                    <UI.Row className="NavigationbarContainer">
                        {navLinks.map((navLink, index) => (
                            <NavigationbarItem
                                key={`item-${index}`}
                                label={navLink.label ?? ""}
                                to={navLink.to}
                                icon={navLink.icon}
                                isCondominium={props.isCondominium ?? false}
                                isDisabled={navLink.isDisabled ?? false}
                                exact={
                                    navigationbarType === NAVIGATIONBAR_TYPE.TICKET ||
                                    navigationbarType === NAVIGATIONBAR_TYPE.ORDER
                                }
                            />
                        ))}
                    </UI.Row>
                </UI.Container>
            </UI.Nav>
        </>
    );
};

export const Navigationbar = observer(NavigationbarBase);
