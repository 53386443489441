import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import {
    GetNewsSelectionFilesManager,
    GetNewsSelectionFilesManagerVariables
} from "src/api/generated/GetNewsSelectionFilesManager";
import * as Screens from "src/screens";
import { useTranslation } from "react-i18next";
import { UI } from "@wwimmo/ui";
import { GET_NEWS_SELECTION_FILES_MANAGER, GET_NEWS_SELECTION_FILES_MANAGER_IT2 } from "src/api/news";
import { AddNewsDocumentsModalList } from "./list/AddNewsDocumentsModalList";
import { getDate } from "src/utils/Date";
import styles from "./AddNewsDocumentsModal.module.css";
import { RootStoreContext } from "src/stores/RootStore";
import { ErpType } from "src/network/User";

interface Params {
    realestateid: string;
}

export interface NewsAddDocumentsListFile {
    fileId?: string;
    fileName?: string;
    fileDate?: string;
    fileType?: number;
}

const AddNewsDocumentsModalBase = () => {
    const { t } = useTranslation();
    const { realestateid } = useParams<Params>();
    const { authStore, navStore, uiStore } = useContext(RootStoreContext);

    const variablesRequest =
        authStore.user?.erpType === ErpType.IT2 && navStore.consolidationId
            ? {
                  realestateid: realestateid,
                  consolidationid: navStore.consolidationId
              }
            : {
                  realestateid: realestateid
              };

    const { data, loading, error } = useQuery<GetNewsSelectionFilesManager, GetNewsSelectionFilesManagerVariables>(
        authStore.user?.erpType === ErpType.IT2 && navStore.consolidationId
            ? GET_NEWS_SELECTION_FILES_MANAGER_IT2
            : GET_NEWS_SELECTION_FILES_MANAGER,
        { variables: variablesRequest }
    );

    const onCloseAddDocumentsModal = useCallback(() => {
        uiStore.setIsAddNewsDocumentClicked(false);
    }, [uiStore]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const fileItems: NewsAddDocumentsListFile[] = [];

    if (data.files.length > 0) {
        data.files.forEach((file: any) => {
            const fileItem =
                file.accountlistfiles.length > 0
                    ? file.accountlistfiles[0]
                    : file.realestatefiles.length > 0
                    ? file.realestatefiles[0]
                    : undefined;

            if (fileItem) {
                let fileId = fileItem.file?.id;
                let fileName = fileItem.file?.name;
                let fileDate = getDate(fileItem.file?.filedate);
                let fileType = fileItem.type;

                fileItems.push({
                    fileId,
                    fileName,
                    fileDate,
                    fileType
                });
            }
        });
    }

    return (
        <UI.Modal
            show={uiStore.isAddNewsDocumentClicked}
            onClose={onCloseAddDocumentsModal}
            isScrollable={true}
            className={styles.AddDocumentsModal}
        >
            <AddNewsDocumentsModalList files={fileItems} />
            <div className="text-center">
                <UI.Button
                    className={`${styles.AddDocumentsModalButton} mt-3`}
                    label={t("labels.cancel")}
                    onClick={onCloseAddDocumentsModal}
                    variant="link"
                />
            </div>
        </UI.Modal>
    );
};

export const AddNewsDocumentsModal = Sentry.withProfiler(observer(AddNewsDocumentsModalBase));
