import { UI } from "@wwimmo/ui";
import { ChangeEvent, useCallback, useState, useContext } from "react";
import styles from "./ImageSelector.module.css";
import { v4 as uuidv4 } from "uuid";
import Resizer from "react-image-file-resizer";
import { useTranslation } from "react-i18next";
import { IMAGE_TYPE, Ticket, ITicketImage, TICKET_IMAGE_STATE } from "src/stores/TicketStore";
import { RootStoreContext } from "src/stores/RootStore";
import { ColorStyle } from "src/utils/Colors";
import { observer } from "mobx-react";
import * as Sentry from "@sentry/react";
import { ImageSelectorItemModal } from "./modal/ImageSelectorItemModal";

interface ImageSelectorItemProps {
    label: string;
    image?: ITicketImage;
    updateTicketImagePropertyFunction: (newValue: any, fileId?: string) => void;
    imageType: IMAGE_TYPE;
    disabled?: boolean;
}
interface FileHTMLInputEvent extends ChangeEvent {
    target: HTMLInputElement & EventTarget;
}
const dataTypes = ["image/jpeg", "image/png"];
const extensions = [".jpg", ".jpeg", ".png"];

const MAX_IMAGE_DIMENSION = {
    WIDTH: 640,
    HEIGHT: 640
};

const ImageSelectorItemBase = (props: ImageSelectorItemProps) => {
    const { t } = useTranslation();

    const { ticketStore } = useContext(RootStoreContext);

    const { image, updateTicketImagePropertyFunction, imageType, disabled } = props;

    const [hasImageSelectionError, setHasImageSelectionError] = useState<boolean>(false);
    const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
    const [isImageDeleting, setIsImageDeleting] = useState<boolean>(false);
    const [hadErrorLoadingImage, setHadErrorLoadingImage] = useState<boolean>(false);
    const [isImageModalDisplayed, setIsImageModalDisplayed] = useState<boolean>(false);

    const resizeImageFile = useCallback((file: File): Promise<string> => {
        return new Promise(async (resolve) => {
            const mimeTypeParts = file.type.split("/");

            Resizer.imageFileResizer(
                file,
                MAX_IMAGE_DIMENSION.WIDTH,
                MAX_IMAGE_DIMENSION.HEIGHT,
                mimeTypeParts.length > 1 ? mimeTypeParts[1] : "jpeg",
                100, // image quality
                0, // rotation
                (uri) => {
                    if (typeof uri === "string") {
                        resolve(uri);
                    } else {
                        resolve("");
                    }
                },
                "base64"
            );
        });
    }, []);

    const onHandleChangeInput = useCallback(
        async (event: FileHTMLInputEvent) => {
            if (event?.target?.files) {
                const files = Array.from(event.target.files);

                if (files.length > 0) {
                    const file: File = files[0];
                    const fileUrl = URL.createObjectURL(file);

                    const ticketImage: ITicketImage = {};

                    ticketImage.fileId = uuidv4();
                    ticketImage.incidentFileId = uuidv4();
                    ticketImage.name = file.name;
                    ticketImage.url = fileUrl;
                    ticketImage.type = imageType;
                    ticketImage.mimetype = file.type;
                    ticketImage.extension = file.name.split(".").pop();
                    ticketImage.fileDate = Ticket.formatDateForImage(new Date(file.lastModified));
                    ticketImage.state = TICKET_IMAGE_STATE.HAS_CHANGED;

                    let imageBase64String: string;

                    // resize and load image
                    const isImage = dataTypes.includes(file.type);

                    if (isImage) {
                        setHasImageSelectionError(false);

                        const resizedImageDataURL = await resizeImageFile(file);

                        if (resizedImageDataURL) {
                            imageBase64String = resizedImageDataURL.split(",")[1];
                        } else {
                            imageBase64String = fileUrl.split(",")[1];
                            if (!imageBase64String) console.error("error in image processing");
                        }

                        if (image) {
                            ticketImage.base64String = imageBase64String;
                        }
                    } else {
                        setHasImageSelectionError(true);
                    }

                    updateTicketImagePropertyFunction(ticketImage);
                }
            }
        },
        [resizeImageFile, imageType, image, updateTicketImagePropertyFunction]
    );

    const onDeleteImage = useCallback(async () => {
        setIsImageDeleting(true);
        await ticketStore.currentTicket?.deleteDamageImage(image?.fileId);

        const ticketImage: ITicketImage = {};

        ticketImage.fileId = undefined;
        ticketImage.incidentFileId = undefined;
        ticketImage.url = undefined;
        ticketImage.base64String = undefined;
        ticketImage.name = undefined;
        ticketImage.mimetype = undefined;
        ticketImage.extension = undefined;
        ticketImage.state = TICKET_IMAGE_STATE.NEW;

        updateTicketImagePropertyFunction(ticketImage, image?.fileId);

        setIsImageDeleting(false);
    }, [image, ticketStore.currentTicket, updateTicketImagePropertyFunction]);

    const onImageLoad = useCallback(() => {
        setIsImageLoading(false);
    }, []);

    const onClickImage = useCallback(
        (e: any) => {
            const hasClickedOnDeleteIcon = e.target.localName === "path";

            if (!hasClickedOnDeleteIcon && image?.url && !hadErrorLoadingImage) {
                setIsImageModalDisplayed(true);
            }
        },
        [hadErrorLoadingImage, image?.url]
    );

    const onImageError = useCallback(() => {
        setHadErrorLoadingImage(true);
        setIsImageLoading(false);
    }, []);

    const imageElementToIndicateLoadingStatus = (
        <img src={image?.url} alt="" onLoad={onImageLoad} onError={onImageError} style={{ display: "none" }} />
    );

    const toggleDisplayImageModal = useCallback(() => {
        setIsImageModalDisplayed((isDisplayed) => !isDisplayed);
    }, []);

    return (
        <>
            <ImageSelectorItemModal
                image={image}
                isDisplayed={isImageModalDisplayed}
                toggleDisplayImageModal={toggleDisplayImageModal}
            />
            <li
                className={`${styles.ImageSelectorItem} ${hasImageSelectionError ? styles.ImageSelectionError : ""} ${
                    disabled && !image?.url ? styles.ImageSelectorItemDisabled : ""
                } ${image?.url && !hadErrorLoadingImage ? styles.EnlargeImageCursor : ""}`}
                style={{ backgroundImage: image?.url && !isImageDeleting ? `url(${image?.url})` : "none" }}
                onClick={onClickImage}
            >
                {(isImageLoading && image?.url) || isImageDeleting ? (
                    <UI.RotatingSpinner noLogo={true} size={25} />
                ) : undefined}
                {imageElementToIndicateLoadingStatus}
                {hadErrorLoadingImage ? (
                    <div className={styles.ImageLoadingErrorContainer}>
                        <UI.Icon
                            icon={UI.SVGIcon.CloseCircle}
                            color={ColorStyle("danger")}
                            size={40}
                            className="mb-1"
                        />
                        <span>{t("screens.tickets.form.notification.image_loading_error")}</span>
                    </div>
                ) : undefined}
                {!image?.url ? (
                    <>
                        <span className={styles.ImageSelectorItemLabel}>{props.label}</span>
                        <UI.Icon style={{ marginTop: 14 }} color="var(--grey)" icon={UI.SVGIcon.Camera} />
                        <input
                            className={styles.ImageSelectorFile}
                            type="file"
                            onChange={onHandleChangeInput}
                            accept={dataTypes.concat(extensions).join(",")}
                        />
                    </>
                ) : (
                    <div className={`${styles.ImageSelectorItemDelete} ${disabled ? styles.Disabled : ""}`}>
                        <UI.Icon onClick={onDeleteImage} color="var(--grey)" icon={UI.SVGIcon.Trash} />
                    </div>
                )}
            </li>
            {hasImageSelectionError ? (
                <p className={styles.ImageSelectorErrorMessage}>
                    {t("screens.tickets.form.notification.image_selection_error")}
                </p>
            ) : undefined}
        </>
    );
};

export const ImageSelectorItem = Sentry.withProfiler(observer(ImageSelectorItemBase));
