import React, { useEffect, useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { CostofCurrentMonthChart } from "./CostofCurrentMonthChart";
import { MonthlyCostsChart } from "./MonthlyCostsChart";

const ServicesCostsAndInvoicesOverviewBase = () => {
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    useEffect(() => {
        navStore.setTitle("");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);
    }, [navStore, t, authStore.user?.role, uiStore.isSidebarActive]);

    return (
        <UI.Container className="pt-4">
            <UI.Title>{t("screens.services.costs_and_invoices.overview.title")}</UI.Title>
            <UI.Row>
                <MonthlyCostsChart />
                <CostofCurrentMonthChart />
            </UI.Row>
        </UI.Container>
    );
};

export const ServicesCostsAndInvoicesOverview = Sentry.withProfiler(observer(ServicesCostsAndInvoicesOverviewBase));
