import React, { useContext, useCallback, FunctionComponent } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { ControllerRenderProps, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPSERT_CUSTOMERSETTING } from "src/api/customer";
import { UpsertCustomersetting, UpsertCustomersettingVariables } from "src/api/generated/UpsertCustomersetting";
import { MessageType } from "src/components/notifications/Notifier";
import { ums_customersettings_insert_input } from "src/api/generated/globalTypes";
import styles from "./TicketingEmailSettingsForm.module.css";
import { GetTicketingSettings as TicketingSettings } from "src/api/generated/GetTicketingSettings";
import { Controller } from "react-hook-form";

type TicketingEmailSettingsFormProps = {
    ticketingSettings: TicketingSettings;
    refetchTicketingSettings: any;
};

interface TicketingEmailSettingsFormFields {
    sendEmailEcAndTicketing: boolean;
    demoMode: boolean;
    incidentNotifyReportBody: string;
    incidentSendConfirmationEmailBody: string;
    orderNotifyReporterBody: string;
}

interface EmailText {
    id: any;
    value: string | null;
}

const TicketingEmailSettingsFormBase: FunctionComponent<TicketingEmailSettingsFormProps> = (props) => {
    const { t } = useTranslation();

    const { uiStore, navStore } = useContext(RootStoreContext);

    const { refetchTicketingSettings, ticketingSettings } = props;

    const [upsertCustomersettingMutation, { loading: isUpsertCustomersettingMutationLoading }] = useMutation<
        UpsertCustomersetting,
        UpsertCustomersettingVariables
    >(UPSERT_CUSTOMERSETTING, {
        onCompleted: () => {
            uiStore.printStatusMessage(t("screens.easycontact_settings.success_save_message"), MessageType.INFO);
        }
    });

    const sendEmailEcAndTicketing = props.ticketingSettings.sendemailecandticketing[0]?.value ?? false;
    const demoMode = uiStore.isDemoModeTicketingActive;

    let incidentNotifyReportBody = "";
    let incidentSendConfirmationEmailBody = "";
    let orderNotifyReporterBody = "";

    const getTextFormattedForTextArea = (emailTextDBValue: EmailText[]) => {
        if (emailTextDBValue.length > 0 && emailTextDBValue[0].value) {
            return emailTextDBValue[0].value.replaceAll("<br>", "\n");
        } else {
            return "";
        }
    };

    incidentNotifyReportBody = getTextFormattedForTextArea(ticketingSettings.incidentnotifyreportbody);
    incidentSendConfirmationEmailBody = getTextFormattedForTextArea(
        ticketingSettings.incidentsendconfirmationemailbody
    );
    orderNotifyReporterBody = getTextFormattedForTextArea(ticketingSettings.ordernotifyreporterbody);

    // TODO: Nach Implementation von '#8113 Ticket Einstellungen Mailtexte ohne HTML Tags' wieder aktivieren
    // const { register, handleSubmit, formState, reset, control } = useForm<TicketingEmailSettingsFormFields>({
    const { handleSubmit, formState, reset, control } = useForm<TicketingEmailSettingsFormFields>({
        mode: "onBlur",
        defaultValues: {
            incidentNotifyReportBody: incidentNotifyReportBody ?? "",
            incidentSendConfirmationEmailBody: incidentSendConfirmationEmailBody ?? "",
            orderNotifyReporterBody: orderNotifyReporterBody ?? "",
            sendEmailEcAndTicketing: sendEmailEcAndTicketing === "true" ? true : false,
            demoMode: demoMode
        }
    });

    // TODO: Nach Implementation von '#8113 Ticket Einstellungen Mailtexte ohne HTML Tags' wieder aktivieren
    // const { errors } = formState;

    const resetForm = useCallback(() => {
        const sendEmailEcAndTicketing = ticketingSettings.sendemailecandticketing[0].value;
        const demoMode = ticketingSettings.demomode[0].value;
        const incidentNotifyReportBody = getTextFormattedForTextArea(ticketingSettings.incidentnotifyreportbody);
        const incidentSendConfirmationEmailBody = getTextFormattedForTextArea(
            ticketingSettings.incidentsendconfirmationemailbody
        );
        const orderNotifyReporterBody = getTextFormattedForTextArea(ticketingSettings.ordernotifyreporterbody);

        reset({
            incidentNotifyReportBody: incidentNotifyReportBody ?? "",
            incidentSendConfirmationEmailBody: incidentSendConfirmationEmailBody ?? "",
            orderNotifyReporterBody: orderNotifyReporterBody ?? "",
            sendEmailEcAndTicketing: sendEmailEcAndTicketing === "true" ? true : false,
            demoMode: demoMode === "true" ? true : false
        });
    }, [reset, ticketingSettings]);

    const { dirtyFields } = formState;

    const handleFormSubmit = useCallback(
        async (formData: TicketingEmailSettingsFormFields) => {
            try {
                const insertInput: ums_customersettings_insert_input[] = [];

                if (dirtyFields.demoMode) {
                    insertInput.push({
                        key: "TICKET.DEMOMODE",
                        value: formData.demoMode.toString(),
                        valuetype: 3
                    });
                }

                if (dirtyFields.sendEmailEcAndTicketing) {
                    insertInput.push({
                        key: "EC.SEND_EMAIL_IN_EC_AND_TICKETING",
                        value: formData.sendEmailEcAndTicketing.toString(),
                        valuetype: 1
                    });
                }

                if (dirtyFields.incidentNotifyReportBody) {
                    insertInput.push({
                        key: "TICKET.INCIDENTNOTIFYREPORTERBODY",
                        value: formData.incidentNotifyReportBody.replaceAll("\n", "<br>"),
                        valuetype: 3
                    });
                }

                if (dirtyFields.incidentSendConfirmationEmailBody) {
                    insertInput.push({
                        key: "TICKET.INCIDENTSENDCONFIRMATIONEMAILBODY",
                        value: formData.incidentSendConfirmationEmailBody.replaceAll("\n", "<br>"),
                        valuetype: 3
                    });
                }

                if (dirtyFields.orderNotifyReporterBody) {
                    insertInput.push({
                        key: "EC.TICKET.ORDERNOTIFYREPORTERBODY",
                        value: formData.orderNotifyReporterBody.replaceAll("\n", "<br>"),
                        valuetype: 3
                    });
                }

                await upsertCustomersettingMutation({
                    variables: {
                        objects: insertInput
                    }
                });

                refetchTicketingSettings();

                reset(
                    { ...formData },
                    {
                        keepDirty: false,
                        keepDefaultValues: false,
                        keepValues: false
                    }
                );
            } catch (error: any) {
                uiStore.printStatusMessage(
                    `${t("screens.easycontact_settings.failure_save_message")}: ${error.message}`,
                    MessageType.ERROR
                );
            }
        },
        [reset, upsertCustomersettingMutation, uiStore, t, dirtyFields, refetchTicketingSettings]
    );

    const renderDemoModeSwitch = useCallback(
        ({
            field: { onChange, value }
        }: {
            field: ControllerRenderProps<TicketingEmailSettingsFormFields, "demoMode">;
        }) => (
            <UI.Switch
                label={t("screens.ticketing_settings.email_form.demo_mode", {
                    customerEmailAddress: navStore.customer.email
                }).toString()}
                labelPosition="after"
                checked={value}
                onToggle={onChange}
            />
        ),
        [t, navStore.customer.email]
    );

    const renderSendEmailEcAndTicketingSwitch = useCallback(
        ({
            field: { onChange, onBlur, value, name, ref }
        }: {
            field: ControllerRenderProps<TicketingEmailSettingsFormFields, "sendEmailEcAndTicketing">;
        }) => (
            <UI.Switch
                label={t("screens.ticketing_settings.email_form.send_email_ec_and_ticketing").toString()}
                labelPosition="after"
                checked={value}
                onToggle={onChange}
            />
        ),
        [t]
    );

    const demoModeSwitch = <Controller name="demoMode" control={control} render={renderDemoModeSwitch} />;
    const sendEmailEcAndTicketingSwitch = (
        <Controller name="sendEmailEcAndTicketing" control={control} render={renderSendEmailEcAndTicketingSwitch} />
    );

    return (
        <UI.Form>
            {demoModeSwitch}
            {sendEmailEcAndTicketingSwitch}

            {/*
            TODO: Nach Implementation von '#8113 Ticket Einstellungen Mailtexte ohne HTML Tags' wieder aktivieren
            <UI.Input
                label={t("screens.ticketing_settings.email_form.incident_notify_reporter").toString()}
                {...register("incidentNotifyReportBody")}
                as="textarea"
                type="text"
                className={`${dirtyFields.incidentNotifyReportBody ? styles.changed : ""} ${styles.EmailTextArea}`}
                errorMsg={errors.incidentNotifyReportBody ? errors.incidentNotifyReportBody.message : undefined}
            />

            <UI.Input
                label={t("screens.ticketing_settings.email_form.incident_close_confirmation").toString()}
                {...register("incidentSendConfirmationEmailBody")}
                as="textarea"
                type="text"
                className={`${dirtyFields.incidentSendConfirmationEmailBody ? styles.changed : ""} ${
                    styles.EmailTextArea
                }`}
                errorMsg={
                    errors.incidentSendConfirmationEmailBody
                        ? errors.incidentSendConfirmationEmailBody.message
                        : undefined
                }
            />

            <UI.Input
                label={t("screens.ticketing_settings.email_form.order_notify_reporter").toString()}
                {...register("orderNotifyReporterBody")}
                as="textarea"
                type="text"
                className={`${dirtyFields.orderNotifyReporterBody ? styles.changed : ""} ${styles.EmailTextArea}`}
                errorMsg={
                    errors.incidentSendConfirmationEmailBody
                        ? errors.incidentSendConfirmationEmailBody.message
                        : undefined
                }
            />
            */}

            <div className="mt-4">
                <UI.Button
                    type="submit"
                    label={isUpsertCustomersettingMutationLoading ? t("labels.is_saving") : t("labels.save")}
                    disabled={!formState.isDirty}
                    onClick={handleSubmit(handleFormSubmit)}
                    className={styles.FormButton}
                />
                <UI.Button
                    label={t("labels.reset")}
                    disabled={!formState.isDirty}
                    onClick={resetForm}
                    className={`${styles.FormButton} ml-sm-2 mt-2 mt-sm-0`}
                />
            </div>
        </UI.Form>
    );
};

export const TicketingEmailSettingsForm = Sentry.withProfiler(TicketingEmailSettingsFormBase);
