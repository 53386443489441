import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { Contact } from "src/components";

interface ContactTileParams {
    realestateid: string;
    className?: string;
    square?: boolean;
}

export const ContactTile = (props: ContactTileParams) => {
    const { t } = useTranslation();

    return (
        <UI.Card title={t("screens.contact.title")} flex className={props.className} square={props.square}>
            {props.realestateid && <Contact realestateid={props.realestateid} />}
        </UI.Card>
    );
};
