import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { ColorStyle } from "src/utils/Colors";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { useMutation } from "@apollo/client";
import { ums_customersettings_insert_input } from "src/api/generated/globalTypes";
import { Route } from "src/config/routes";
import { useLocation } from "react-router-dom";
import "./DemoModeBanner.css";
import { UpsertCustomersetting, UpsertCustomersettingVariables } from "src/api/generated/UpsertCustomersetting";
import { UPSERT_CUSTOMERSETTING } from "src/api/customer";
import { MessageType } from "src/components/notifications/Notifier";
import { Role } from "src/network/User";
import { useHistory } from "react-router-dom";

const DemoModeBannerBase = () => {
    const { t } = useTranslation();
    const { authStore, uiStore, navStore } = useContext(RootStoreContext);
    const location = useLocation();
    const history = useHistory();

    const [upsertCustomersettingMutation] = useMutation<UpsertCustomersetting, UpsertCustomersettingVariables>(
        UPSERT_CUSTOMERSETTING,
        {
            onCompleted: () => {
                uiStore.printStatusMessage(
                    `${t("screens.tickets.demo_mode.successfull_deactivaion")}`,
                    MessageType.SUCCESS
                );
                uiStore.setIsDemoModeTicketingActive(false);
                history.push(Route.ticketingsettings);
            }
        }
    );

    const onClickDeactivateDemoModeTicketing = useCallback(async () => {
        if (authStore.user?.availableRoles.includes(Role.ADMINISTRATOR)) {
            if (uiStore.isDemoModeTicketingActive) {
                try {
                    const insertInput: ums_customersettings_insert_input[] = [];
                    insertInput.push({
                        key: "TICKET.DEMOMODE",
                        value: "false",
                        valuetype: 3
                    });

                    await upsertCustomersettingMutation({
                        variables: {
                            objects: insertInput
                        }
                    });
                } catch (error: any) {
                    uiStore.setIsDemoModeTicketingActive(true);

                    uiStore.printStatusMessage(
                        `${t("screens.tickets.demo_mode.error_deactivation")}: ${error.message}`,
                        MessageType.ERROR
                    );
                }
            }
        } else {
            uiStore.printStatusMessage(
                `${t("screens.tickets.demo_mode.permission_admin")}`,
                MessageType.WARNING,
                10000
            );
        }
    }, [uiStore, upsertCustomersettingMutation, authStore.user?.availableRoles, t]);

    return (
        <>
            {uiStore.isDemoModeTicketingActive &&
            (location.pathname.includes(Route.newTicket) ||
                location.pathname.includes(Route.tickets) ||
                location.pathname.includes(Route.ticket)) ? (
                <>
                    <div className="DemoModeBanner container-fluid">
                        <UI.Row>
                            <UI.Col xl={9} sm={9} className="d-flex align-items-center mb-4 mb-lg-0">
                                <UI.Icon
                                    className="DemoModeInfoIcon"
                                    icon={UI.SVGIcon.Information}
                                    color={ColorStyle("white")}
                                />
                                <div
                                    className="DemoModeText"
                                    dangerouslySetInnerHTML={{
                                        __html: t("screens.tickets.demo_mode.info_text", {
                                            customerEmail: navStore.customer.email
                                        }).toString()
                                    }}
                                />
                            </UI.Col>
                            <UI.Col xl={3} sm={3} className="d-flex align-items-center justify-content-end">
                                <button onClick={onClickDeactivateDemoModeTicketing} className="DemoModeButton">
                                    {t("screens.tickets.demo_mode.button_deactivate")}
                                </button>
                            </UI.Col>
                        </UI.Row>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export const DemoModeBanner = Sentry.withProfiler(observer(DemoModeBannerBase));
