import React, { useContext } from "react";
import * as Sentry from "@sentry/react";
import { Switch, withRouter } from "react-router";
import { Header, PrivateRoute } from "src/components/";
import { observer } from "mobx-react-lite";
import { Route, getParams, getRoute } from "src/config/routes";
import * as Screens from "src/screens";
import { NAVIGATIONBAR_TYPE } from "src/components/header/navigationbar/Navigationbar";
import { RootStoreContext } from "src/stores/RootStore";
import "./Order.css";

export const OrderBase = () => {
    const { uiStore, authStore } = useContext(RootStoreContext);

    const role = authStore.user?.role;
    const currentPage = role ? getRoute(window.location.pathname, role) : undefined;

    let params: { [key: string]: string } = {};

    if (currentPage === Route.ticketOrderDetails) {
        params = getParams(Route.ticketOrderDetails, role);
    }

    const ticketNumber = params["ticketnumber"];
    const orderNumber = params["ordernumber"];

    return (
        <React.Fragment>
            {uiStore.isMobile ? (
                <Header.Navigationbar
                    navigationbarType={NAVIGATIONBAR_TYPE.ORDER}
                    ticketNumber={ticketNumber}
                    orderNumber={orderNumber}
                />
            ) : undefined}

            <Switch>
                <PrivateRoute
                    key={Route.manager + Route.ticketOrderDetails}
                    path={Route.manager + Route.ticketOrderDetails}
                    exact
                    component={Screens.OrderDetails}
                />
                <PrivateRoute component={Screens.ErrorNotFound} />
            </Switch>
        </React.Fragment>
    );
};

export const Order = Sentry.withProfiler(withRouter(observer(OrderBase)));
