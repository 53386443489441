import React, { useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import {
    GET_MANAGER_FILES_BY_REALESTATEID,
    GET_OWNER_FILES_BY_REALESTATEID,
    GET_COOWNER_FILES_BY_REALESTATEID,
    GET_COOWNER_FILES_BY_REALESTATEID_IT2,
    GET_TENANT_FILES_BY_REALESTATEID,
    GET_TENANT_FILES_BY_REALESTATEID_IT2
} from "src/api/documents";
import { useParams, withRouter } from "react-router";
import {
    GetManagerFilesByRealEstateId,
    GetManagerFilesByRealEstateIdVariables
} from "src/api/generated/GetManagerFilesByRealEstateId";
import { getLang } from "src/utils/i18n";
import * as Screens from "src/screens";
import { Route, selectRoute } from "src/config/routes";
import { ScreenType } from "src/api/tiles";
import { ScreenWithTiles } from "src/components/screenWithTiles/ScreenWithTiles";
import { observer } from "mobx-react-lite";
import { ErpType, Role } from "src/network/User";
import { DocumentsList } from "./list/DocumentsList";
import { UI } from "@wwimmo/ui";
import styles from "./Documents.module.css";

interface Params {
    realestateid: string;
    ownerId: string;
}

export const DocumentsBase = () => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);
    const { realestateid } = useParams<Params>();

    useEffect(() => {
        if (navStore.currentOwnerId) {
            if (navStore.singleRealestate) {
                navStore.setBackbutton(
                    selectRoute(Route.owners, authStore.portalRoleWithHighestPriority ?? authStore.user?.role),
                    t("screens.owners.title")
                );
            } else {
                navStore.setBackbutton(
                    selectRoute(
                        Route.realestatesOwner,
                        authStore.portalRoleWithHighestPriority ?? authStore.user?.role,
                        { ownerid: navStore.currentOwnerId }
                    ),
                    t("screens.realestates.title")
                );
            }
        } else {
            navStore.setBackbutton(
                selectRoute(Route.realestates, authStore.portalRoleWithHighestPriority ?? authStore.user?.role),
                t("screens.realestates.title")
            );
        }
    }, [
        authStore.portalRoleWithHighestPriority,
        authStore.user?.role,
        t,
        navStore,
        uiStore.sidebarWidth,
        uiStore.isMobile
    ]);

    const { loading, error, data } = useQuery<GetManagerFilesByRealEstateId, GetManagerFilesByRealEstateIdVariables>(
        authStore.user?.role === Role.MANAGER
            ? GET_MANAGER_FILES_BY_REALESTATEID
            : authStore.user?.role === Role.OWNER
            ? GET_OWNER_FILES_BY_REALESTATEID
            : authStore.user?.role === Role.TENANT && authStore.user.erpType === ErpType.IT2
            ? GET_TENANT_FILES_BY_REALESTATEID_IT2
            : authStore.user?.role === Role.TENANT
            ? GET_TENANT_FILES_BY_REALESTATEID
            : authStore.user?.hasCoownerRole && authStore.user.erpType === ErpType.IT2
            ? GET_COOWNER_FILES_BY_REALESTATEID_IT2
            : GET_COOWNER_FILES_BY_REALESTATEID,

        {
            variables: {
                realestateid: realestateid,
                lang: getLang()
            }
        }
    );

    if (loading) {
        return <Screens.Loading />;
    }

    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }

    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    let hasDocuments = false;

    data.realestates.forEach((realestate) => {
        if (realestate.files.length > 0) {
            hasDocuments = true;
        }
    });

    return (
        <ScreenWithTiles
            title={t("screens.realestate.documents.title")}
            screenId={ScreenType.DOCUMENTS}
            realestateid={realestateid}
        >
            <UI.Card flex={true}>
                {hasDocuments ? (
                    <DocumentsList realestateFileItems={data.realestates} fileCategories={data.file_categories} />
                ) : (
                    <div className={styles.noData}>{t("screens.realestate.documents.no_documents")}</div>
                )}
            </UI.Card>
        </ScreenWithTiles>
    );
};

export const Documents = Sentry.withProfiler(withRouter(observer(DocumentsBase)));
