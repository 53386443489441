import { UI } from "@wwimmo/ui";
import { useState, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./TicketActionMobileButton.module.css";
import { useTranslation } from "react-i18next";
import { AssignOrderButtonAndModal } from "src/components/ticketing/AssignOrderButtonAndModal";
import { CloseTicketButtonAndModal } from "src/components/ticketing/CloseTicketButtonAndModal";

const TicketActionMobileButtonBase = () => {
    const [showActionSelectorModal, setShowActionSelectorModal] = useState<boolean>(false);
    const { t } = useTranslation();

    const handleShowActionSelectorModal = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setShowActionSelectorModal(true);
    }, []);

    const handleDisposeActionSelectorModal = useCallback(() => {
        setShowActionSelectorModal(false);
    }, []);

    return (
        <>
            {showActionSelectorModal ? (
                <UI.Modal
                    title={t("screens.tickets.actions").toString()}
                    show={showActionSelectorModal}
                    onClose={handleDisposeActionSelectorModal}
                    centered={true}
                    className={styles.ActionSelectorModal}
                >
                    <AssignOrderButtonAndModal />
                    <div className="mb-2" />
                    <CloseTicketButtonAndModal />
                </UI.Modal>
            ) : null}

            <UI.Button
                size="sm"
                label={<UI.SVGIcon.ArrowDown viewBox="0 0 32 34" transform="scale(3) " />}
                onClick={handleShowActionSelectorModal}
                className={styles.OpenActionsButton}
            ></UI.Button>
        </>
    );
};

export const TicketActionMobileButton = Sentry.withProfiler(observer(TicketActionMobileButtonBase));
