import { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { AssignOrderModal } from "src/screens/tickets/ticket/details/actions/assignOrder/AssignOrderModal";
import { AssignOrderButton } from "src/screens/tickets/ticket/details/actions/assignOrder/AssignOrderButton";
import { RootStoreContext } from "src/stores/RootStore";
import { TICKET_STATE } from "src/stores/TicketStore";

const AssignOrderButtonAndModalBase = () => {
    const { ticketStore, assignOrderModalStore } = useContext(RootStoreContext);

    const onCloseAssignOrderModal = useCallback(() => {
        assignOrderModalStore.setIsModalDisplayed(false);
    }, [assignOrderModalStore]);

    const onClickAssignOrderButton = useCallback(() => {
        const ticketReadyForAssignOrderAction = ticketStore.currentTicket?.validateTicketReadyForAssignOrderAction();

        if (ticketReadyForAssignOrderAction) {
            assignOrderModalStore.setIsModalDisplayed(true);
        }
    }, [ticketStore.currentTicket, assignOrderModalStore]);

    return ticketStore.currentTicket?.state === TICKET_STATE.NEW ||
        ticketStore.currentTicket?.state === TICKET_STATE.ASSIGNED ||
        ticketStore.currentTicket?.state === TICKET_STATE.COMISSIONED ? (
        <>
            <AssignOrderModal
                showModal={assignOrderModalStore.isModalDisplayed}
                onClickClose={onCloseAssignOrderModal}
            />
            <AssignOrderButton onClick={onClickAssignOrderButton} />
        </>
    ) : (
        <></>
    );
};

export const AssignOrderButtonAndModal = Sentry.withProfiler(observer(AssignOrderButtonAndModalBase));
