import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { AgendaItem } from "src/types";
import { AssemblyAgendaItems } from "src/components/assemblies/agenda/list/AssemblyAgendaItems";
import styles from "./AgendaTemplateForm.module.css";
import { useMutation } from "@apollo/client";
import { UPSERT_AGENDAITEMS_TEMPLATES } from "src/api/agenda";
import {
    UpsertAgendaItemsTemplates,
    UpsertAgendaItemsTemplatesVariables
} from "src/api/generated/UpsertAgendaItemsTemplates";
import { MessageType } from "src/components/notifications/Notifier";

interface AgendaTemplateFormProps {
    agendaItems: AgendaItem[];
}

const AgendaTemplateFormBase = (props: AgendaTemplateFormProps) => {
    const { t } = useTranslation();
    const { assemblyStore, uiStore } = useContext(RootStoreContext);

    const printStatusMessage = useCallback(
        (message: string, msgType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: msgType,
                    autoClose: msgType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    const onClickAddAgenda = useCallback(
        async (e: any) => {
            e.preventDefault();
            assemblyStore.setIsAddNewAgendaItem(true);
        },
        [assemblyStore]
    );

    const onResetForm = useCallback(
        (e: any) => {
            e.preventDefault();

            assemblyStore.setUnsavedAgendaItems([]);

            if (assemblyStore.agendaItemsHaveChanged) {
                assemblyStore.setResetAgendaItems(true);
            }
        },
        [assemblyStore]
    );

    const [upsertAgendaItemTemplate] = useMutation<UpsertAgendaItemsTemplates, UpsertAgendaItemsTemplatesVariables>(
        UPSERT_AGENDAITEMS_TEMPLATES,
        {
            onCompleted: () => {
                printStatusMessage(t("screens.realestate.assembly.agendatemplate_save_success"), MessageType.INFO);
                assemblyStore.setResetAgendaItems(true);
                assemblyStore.setShowRefetchAssemblyData(true);
                assemblyStore.setOriginalAgendaItems(assemblyStore.agendaItems);
                assemblyStore.setAgendaItemsHaveChanged();
            }
        }
    );

    const onClickSaveAgendaTemplate = useCallback(
        async (e: any) => {
            e.preventDefault();

            if (assemblyStore.agendaItemsHaveChanged) {
                assemblyStore.createInsertAgendaitemsTemplate();
                await upsertAgendaItemTemplate({
                    variables: {
                        objects: assemblyStore.insertAgendaitemsTemplates,
                        agendaitemIds: assemblyStore.deletedAgendaItemIds
                    }
                });
            }
        },
        [assemblyStore, upsertAgendaItemTemplate]
    );

    return (
        <UI.Row>
            <UI.Col md={10}>
                <UI.Card>
                    <div className={styles.AssemblyAddAgendaModalContainer}>
                        <div className={styles.Title}>{t("sidebar.settings.agenda_template")}</div>
                        <div className={styles.AgendaAddButton}>
                            <UI.Button onClick={onClickAddAgenda} label={"+"} />
                        </div>
                    </div>
                    <UI.Col md={11}>
                        <AssemblyAgendaItems initialAgendaItems={props.agendaItems} />
                    </UI.Col>
                </UI.Card>
            </UI.Col>
            <UI.Col className={styles.ColButtons}>
                <UI.Button
                    disabled={!assemblyStore.agendaItemsHaveChanged}
                    onClick={onClickSaveAgendaTemplate}
                    label={t("labels.save")}
                    id="assembly-submit-button"
                    className="AssemblyFormButton"
                />
                <UI.Button
                    label={t("labels.discard")}
                    disabled={!assemblyStore.agendaItemsHaveChanged}
                    onClick={onResetForm}
                    className={"AssemblyFormButton DiscardButton ml-sm-2 mt-2 mt-sm-0"}
                    variant="outline-primary"
                />
            </UI.Col>
        </UI.Row>
    );
};

export const AgendaTemplateForm = Sentry.withProfiler(observer(AgendaTemplateFormBase));
