import React, { useEffect, useContext, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import "src/screens/errornotfound/ErrorNotFound.css";
import { useTranslation, Trans } from "react-i18next";
import ImageMove from "src/assets/images/HG_0109.jpg";
import { Link } from "react-router-dom";
import { RootStoreContext } from "src/stores/RootStore";
import * as Sentry from "@sentry/react";

const ErrorNotFoundBase = () => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);

    useEffect(() => {
        navStore.setTitle("404");
        Sentry.captureMessage("404 - Page not found", (scope) => scope.setExtras({ url: window.location.href }));
    });

    const onHistoryBack = useCallback(() => {
        window.history.back();
    }, []);

    return (
        <UI.Container
            fluid
            style={{
                backgroundImage: `url(${ImageMove})`,
                minHeight: `calc(100vh - ${uiStore.headerHeight}px)`
            }}
            className="ErrorNotFound pt-4"
        >
            <UI.Row>
                <UI.Col lg={{ span: 6, offset: 3 }}>
                    <UI.Card className="text-center">{t("error.404-body")}</UI.Card>
                </UI.Col>
            </UI.Row>
            <UI.Row>
                <UI.Col lg={{ span: 6, offset: 3 }}>
                    <UI.Card flex className="justify-content-center text-center flex-fill ">
                        {authStore.isLoggedIn && (
                            <UI.Button
                                variant="link"
                                className="mb-3"
                                label={t("labels.goback")}
                                onClick={onHistoryBack}
                            />
                        )}
                        <Link to="/">
                            <Trans>labels.to_startpage</Trans>
                        </Link>
                    </UI.Card>
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};

export const ErrorNotFound = Sentry.withProfiler(ErrorNotFoundBase);
