import React, { useEffect, useContext, useCallback, useState } from "react";
import * as Sentry from "@sentry/react";
import { RouteComponentProps, withRouter, useParams } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { UI, ScreenSize } from "@wwimmo/ui";
import { GET_MAINTENANCE_DETAILS } from "src/api/maintenance";
import {
    GetMaintenanceDetails,
    GetMaintenanceDetailsVariables,
    GetMaintenanceDetails_maintenancestatus as maintenancestatus
} from "src/api/generated/GetMaintenanceDetails";
import { dateFormat } from "src/utils/Date";
import { formatCurrency, getCurrencyElement } from "src/utils/Currency";
import { Route, selectRoute } from "src/config/routes";
import * as Screens from "src/screens";
import { ScreenWithTiles } from "src/components/screenWithTiles/ScreenWithTiles";
import { observer } from "mobx-react-lite";
import { ScreenType } from "src/api/tiles";
import { NetworkConfig } from "src/network/NetworkConfig";
import { ColorStyle } from "src/utils/Colors";
import styles from "./Maintenance.module.css";

interface Params {
    realestateid: string;
    unitid: string;
    applianceid: string;
    maintenanceid: string;
}

interface MaintenanceItem {
    label?: string;
    value?: any;
    to?: string;
    className?: string;
    onClick?: any;
}

interface BookingItem {
    key?: any;
    date?: string;
    text?: string;
    debit?: any;
    credit?: any;
    isTitle?: boolean;
    icon?: any;
    isSingleColumnRow?: boolean;
    to?: string;
}

const MaintenanceBase = (props: RouteComponentProps<Params>) => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);
    const { realestateid, unitid, applianceid, maintenanceid } = useParams<Params>();
    const [maintenancestatus, setMaintenancestatus] = useState<Map<number | null, { label: string }>>();
    const iconSize = UI.IconSize.default;

    const { loading, error, data } = useQuery<GetMaintenanceDetails, GetMaintenanceDetailsVariables>(
        GET_MAINTENANCE_DETAILS,
        {
            variables: { maintenanceid: maintenanceid }
        }
    );

    useEffect(() => {
        if (data?.maintenancestatus) {
            setMaintenancestatus(getEnumerationMap(data?.maintenancestatus));
        }
    }, [data?.maintenancestatus]);

    const getEnumerationMap = (enumerations: Array<maintenancestatus>) => {
        const enumerationMap = new Map();

        enumerations.forEach((enumeration) => {
            if (enumeration.key !== null) {
                enumerationMap.set(enumeration.key, {
                    label: enumeration.label
                });
            }
        });

        return enumerationMap;
    };

    useEffect(() => {
        navStore.resetActionButtons();

        if (realestateid && unitid && applianceid && maintenanceid) {
            navStore.setBackbutton(
                selectRoute(Route.unitAppliance, authStore.user?.role, {
                    realestateid: realestateid,
                    unitid: unitid,
                    applianceid: applianceid
                }),
                t("screens.realestate.appliance.title")
            );
        } else if (realestateid && unitid && applianceid) {
            navStore.setBackbutton(
                selectRoute(Route.unitApplianceMaintenances, authStore.user?.role, {
                    realestateid: realestateid,
                    unitid: unitid,
                    applianceid: applianceid
                }),
                t("screens.realestate.maintenances.title")
            );
        } else if (realestateid && applianceid && maintenanceid) {
            navStore.setBackbutton(
                selectRoute(Route.realestateAppliance, authStore.user?.role, {
                    realestateid: realestateid,
                    applianceid: applianceid
                }),
                t("screens.realestate.appliance.title")
            );
        } else if (realestateid && applianceid) {
            navStore.setBackbutton(
                selectRoute(Route.realestateApplianceMaintenances, authStore.user?.role, {
                    realestateid: realestateid,
                    applianceid: applianceid
                }),
                t("screens.realestate.maintenances.title")
            );
        } else if (realestateid && unitid) {
            navStore.setBackbutton(
                selectRoute(Route.unitMaintenances, authStore.user?.role, {
                    realestateid: realestateid,
                    unitid: unitid
                }),
                t("screens.realestate.maintenances.title")
            );
        } else {
            navStore.setBackbutton(
                selectRoute(Route.realestateMaintenances, authStore.user?.role, {
                    realestateid: realestateid
                }),
                t("screens.realestate.maintenances.title")
            );
        }
    }, [t, authStore.user, navStore, realestateid, unitid, data, applianceid, maintenanceid]);

    const renderItem = useCallback((item: MaintenanceItem, screenSize: ScreenSize) => {
        const listRows: any[] = [];

        if (item.value) {
            let rows: React.ReactElement[] = [];

            rows = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={1} value={item.label} />
                    <UI.List.Cell
                        key={"c-2"}
                        colspan={screenSize === ScreenSize.DESKTOP ? 2 : 1}
                        value={item.value}
                        className={item.className ?? ""}
                    />
                </UI.List.Row>
            ];

            listRows.push(rows);

            return (
                <UI.List.Item
                    key={item.label}
                    screenSize={screenSize}
                    rows={listRows}
                    to={item.to}
                    onClick={item.onClick}
                />
            );
        }
    }, []);

    const renderBookingItem = useCallback(
        (item: BookingItem, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    if (item.isTitle) {
                        listRows = [
                            <UI.List.Row key={"r-1"} className="bold">
                                <UI.List.Cell key={"c-1"} colspan={1} value={item.date} />
                                <UI.List.Cell key={"c-2"} colspan={3} value={item.text} className="uppercase" />
                                <UI.List.Cell key={"c-3"} colspan={1} value={item.credit} className="text-right" />
                                <UI.List.Cell key={"c-4"} colspan={1} value={item.debit} className="text-right" />
                            </UI.List.Row>
                        ];
                    } else {
                        if (item.isSingleColumnRow) {
                            listRows = [
                                <UI.List.Row key={"r-1"}>
                                    <UI.List.Cell
                                        key={"c-1"}
                                        colspan={3}
                                        value={`${t("screens.realestate.maintenance.to_account")}: ${item.text}`}
                                    />
                                </UI.List.Row>
                            ];
                        } else {
                            listRows = [
                                <UI.List.Row key={"r-1"}>
                                    <UI.List.Cell key={"c-1"} colspan={1} value={item.date} />
                                    <UI.List.Cell key={"c-2"} colspan={3} value={item.text} />
                                    <UI.List.Cell key={"c-3"} colspan={1} value={item.debit} className="text-right" />
                                    <UI.List.Cell key={"c-4"} colspan={1} value={item.credit} className="text-right" />
                                </UI.List.Row>
                            ];
                        }
                    }
                    break;
                case ScreenSize.MOBILE:
                    if (item.isTitle) {
                        listRows = [
                            <UI.List.Row key={"r-1"} className="bold">
                                <UI.List.Cell key={"c-1"} colspan={1} value={item.text} className="uppercase" />
                                <UI.List.Cell key={"c-3"} colspan={1} value={item.credit} className="text-right" />
                                <UI.List.Cell key={"c-2"} colspan={1} value={item.debit} className="text-right" />
                            </UI.List.Row>
                        ];
                    } else {
                        if (item.isSingleColumnRow) {
                            listRows = [
                                <UI.List.Row key={"r-1"}>
                                    <UI.List.Cell
                                        key={"c-1"}
                                        colspan={1}
                                        value={`${t("screens.realestate.maintenance.to_account")}: ${item.text}`}
                                    />
                                </UI.List.Row>
                            ];
                        } else {
                            listRows = [
                                <UI.List.Row key={"r-1"}>
                                    <UI.List.Cell key={"c-1"} colspan={1} value={item.date} />
                                    <UI.List.Cell key={"c-2"} colspan={1} value={item.debit} className="text-right" />
                                    <UI.List.Cell key={"c-3"} colspan={1} value={item.credit} className="text-right" />
                                </UI.List.Row>,
                                <UI.List.Row key={"r-2"}>
                                    <UI.List.Cell key={"c-4"} colspan={1} value={item.text} className="sublabel" />
                                </UI.List.Row>
                            ];
                        }
                    }

                    break;
            }

            return (
                <UI.List.Item
                    key={item.key}
                    screenSize={screenSize}
                    rows={listRows}
                    icon={item.icon}
                    className={item.isTitle ? "border-bottom" : ""}
                    to={item.to}
                />
            );
        },
        [t]
    );

    const renderBookingHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            const bookingDate = t("screens.realestate.finances.booking_date");
            const description = t("screens.realestate.finances.booking_description");
            const debit = t("screens.realestate.finances.debit");
            const credit = t("screens.realestate.finances.credit");

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={bookingDate} />
                            <UI.List.Cell key={"c-2"} colspan={3} value={description} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={debit} className="text-right" />
                            <UI.List.Cell key={"c-4"} colspan={1} value={credit} className="text-right" />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={bookingDate} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={debit} className="text-right" />
                            <UI.List.Cell key={"c-3"} colspan={1} value={credit} className="text-right" />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header key={bookingDate} rows={headerRows} />;
        },
        [t]
    );

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data || data.common_maintenances.length === 0) {
        return <Screens.ErrorEmptyData message={t("error.nodata")} />;
    }

    const l = (key: string) => {
        return t("screens.realestate.maintenance." + key);
    };

    const getDate = (input: string) => {
        if (input) {
            return dateFormat(new Date(input), "dd.MM.yyyy");
        }
    };

    const maintenance = data.common_maintenances[0];

    const craftsman = maintenance.craftsman;
    const craftsmanName = craftsman ? `${craftsman?.name1 ?? ""} ${craftsman?.name2 ?? ""}` : undefined;

    let craftsmanLink = undefined;

    if (craftsman?.id) {
        if (unitid) {
            craftsmanLink = selectRoute(
                applianceid ? Route.unitApplianceMaintenanceCraftsman : Route.unitMaintenanceCraftsman,
                authStore.user?.role,
                {
                    realestateid: realestateid,
                    unitid: unitid,
                    applianceid: applianceid,
                    maintenanceid: maintenanceid,
                    craftsmanid: craftsman.id
                }
            );
        } else {
            craftsmanLink = selectRoute(
                applianceid ? Route.realestateApplianceMaintenanceCraftsman : Route.realestateMaintenanceCraftsman,
                authStore.user?.role,
                {
                    realestateid: realestateid,
                    unitid: unitid,
                    craftsmanid: craftsman.id,
                    maintenanceid: maintenanceid,
                    applianceid: applianceid
                }
            );
        }
    }

    let applianceLink = undefined;

    if (maintenance.appliance?.id) {
        applianceLink = selectRoute(unitid ? Route.unitAppliance : Route.realestateAppliance, authStore.user?.role, {
            realestateid: realestateid,
            unitid: unitid,
            applianceid: maintenance.appliance?.id
        });
    }

    const unit = maintenance.unit;
    const unitName = unit ? unit.name ?? "-" : undefined;

    let unitLink = undefined;

    if (unit?.id) {
        unitLink = selectRoute(Route.unit, authStore.user?.role, {
            realestateid: realestateid,
            unitid: unit.id
        });
    }

    const maintenanceStatus = maintenancestatus?.get(maintenance.status)?.label;

    let applianceName = undefined;

    if (maintenance.appliance) {
        const appliance = maintenance.appliance;

        applianceName = `${appliance.name ?? ""}${
            appliance.manufacturer ? (appliance.name ? `, ${appliance.manufacturer}` : appliance.manufacturer) : ""
        }`;
    }

    const maintenanceDetails: Array<MaintenanceItem> = [
        { label: l("status"), value: maintenanceStatus },
        { label: l("date"), value: getDate(maintenance.date) },
        {
            label: t("screens.unit.object"),
            value: unitName,
            className: styles.DisplayAllText,
            to: unitLink
        },
        {
            label: l("appliance"),
            value: applianceName,
            className: styles.DisplayAllText,
            to: applianceLink
        },
        { label: l("subject"), value: maintenance.name },
        { label: l("workfrom"), value: getDate(maintenance.workfrom) },
        { label: l("workuntil"), value: getDate(maintenance.workuntil) },
        {
            label: l("craftsman"),
            value: craftsmanName,
            className: styles.DisplayAllText,
            to: craftsmanLink
        },
        { label: l("costs"), value: `CHF ${formatCurrency(maintenance.cost)}` },
        { label: l("craftsmanreference"), value: maintenance.craftsmanreference },
        { label: l("remarks"), value: maintenance.remarks, className: styles.DisplayAllText }
    ];

    const getBookingFileDownloadLink = (bookingDataFileId: string | undefined) => {
        if (bookingDataFileId) {
            let urlLink = NetworkConfig.datafileUrl + bookingDataFileId;
            return (
                <a href={urlLink} className="link" rel="noopener noreferrer d-flex" target="_blank">
                    <UI.Icon icon={UI.SVGIcon.Documents} color={ColorStyle("primary")} />
                </a>
            );
        } else {
            //spacer
            return <span style={{ width: iconSize }} />;
        }
    };

    let bookingDetails: Array<BookingItem> | undefined = undefined;

    if (navStore.hasOptionMaintenances) {
        const bookingData = maintenance.booking;

        if (bookingData) {
            const date = dateFormat(new Date(bookingData.date), "dd.MM.yyyy") || "";

            let accountLink = undefined;

            if (bookingData.accountid) {
                if (unitid) {
                    accountLink = selectRoute(
                        applianceid ? Route.unitApplianceMaintenanceAccount : Route.unitMaintenanceAccount,
                        authStore.user?.role,
                        {
                            realestateid: realestateid,
                            unitid: unitid,
                            applianceid: applianceid,
                            maintenanceid: maintenanceid,
                            accountid: bookingData.accountid
                        }
                    );
                } else {
                    accountLink = selectRoute(
                        applianceid ? Route.realestateApplianceMaintenanceAccount : Route.realestateMaintenanceAccount,
                        authStore.user?.role,
                        {
                            realestateid: realestateid,
                            unitid: unitid,
                            accountid: bookingData.accountid,
                            maintenanceid: maintenanceid,
                            applianceid: applianceid
                        }
                    );
                }
            }

            bookingDetails = [
                {
                    key: "booking-details",
                    date: date,
                    text: bookingData.text,
                    debit: getCurrencyElement(bookingData.debit),
                    credit: getCurrencyElement(bookingData.credit),
                    icon: getBookingFileDownloadLink(
                        (bookingData.bookingfiles.length !== 0 && bookingData.bookingfiles[0].fileid) || undefined
                    )
                },
                {
                    key: "booking-details-account-link",
                    text: `${bookingData.account?.number} - ${bookingData.account?.title}`,
                    to: accountLink,
                    isSingleColumnRow: true
                }
            ];
        }
    }

    const maintenanceDetailList = (
        <UI.Card flex>
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={maintenanceDetails}
                renderItem={renderItem}
                title={l("title")}
            />
            {bookingDetails ? (
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={bookingDetails}
                    renderItem={renderBookingItem}
                    renderHeader={renderBookingHeader}
                    title={t("screens.realestate.maintenance.accounting")}
                />
            ) : undefined}
        </UI.Card>
    );

    const titleName = `${maintenance.date ? getDate(maintenance.date) : ""} ${
        maintenance.name ? (maintenance.date ? `- ${maintenance.name}` : maintenance.name) : ""
    }`;

    return (
        <ScreenWithTiles title={titleName} screenId={ScreenType.MAINTENANCE} realestateid={realestateid}>
            {maintenanceDetailList}
        </ScreenWithTiles>
    );
};
export const Maintenance = Sentry.withProfiler(withRouter(observer(MaintenanceBase)));
