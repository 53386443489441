import { useEffect, useState } from "react";
import { NetworkConfig } from "src/network/NetworkConfig";
import { useHistory } from "react-router-dom";
import { Route } from "src/config/routes";

export const useSystemHealthCheck = () => {
    const history = useHistory();
    const [hasSystemHealthBeenChecked, setHasSystemHealthBeenChecked] = useState<boolean>(false);

    useEffect(() => {
        if (!hasSystemHealthBeenChecked) {
            const checkSystemHealth = async () => {
                if (!NetworkConfig.isMaintenanceActive) {
                    try {
                        const getSystemHealthResponse = await fetch(NetworkConfig.systemHealthUrl, { method: "GET" });

                        if (getSystemHealthResponse.status !== 200) {
                            setHasSystemHealthBeenChecked(true);
                            history.push(Route.systemHealthError);
                        }
                    } catch (error) {
                        setHasSystemHealthBeenChecked(true);
                        history.push(Route.systemHealthError);
                    }
                }
            };

            checkSystemHealth();
            setHasSystemHealthBeenChecked(true);
        }
    }, [hasSystemHealthBeenChecked, history]);
};
