import { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { AssemblyAgendaItems } from "./list/AssemblyAgendaItems";
import { RootStoreContext } from "src/stores/RootStore";
import styles from "./AssemblyAgenda.module.css";
import { AgendaItem, AgendaItemChild } from "src/types";
import { GetAssemblyByID_agendaitem_parents } from "src/api/generated/GetAssemblyByID";
import { CopyAssemblyAgendaButton } from "./modal/CopyAssemblyAgendaButton";
import { useQuery } from "@apollo/client";
import * as Screens from "src/screens";
import { GET_OLD_ASSEMBLY_WITH_GENERAL_DOCUMENTS } from "src/api/customer";
import { GetOldAssemblyWithGeneralDocuments } from "src/api/generated/GetOldAssemblyWithGeneralDocuments";

interface AssembliesAgendaProps {
    assemblyid: string;
    realestateid: string;
    assemblydate: string;
    agendaItemParents: GetAssemblyByID_agendaitem_parents[];
    refetchAgendaItems: any;
    assemblyFilesExists: boolean;
}

const AssemblyAgendaBase = (props: AssembliesAgendaProps) => {
    const { t } = useTranslation();

    const { assemblyStore } = useContext(RootStoreContext);

    const { agendaItemParents, refetchAgendaItems } = props;

    assemblyStore.setIsAssemblyFilesExists(props.assemblyFilesExists);

    const { data, loading, error } = useQuery<GetOldAssemblyWithGeneralDocuments>(
        GET_OLD_ASSEMBLY_WITH_GENERAL_DOCUMENTS
    );

    const onClickAddAgenda = useCallback(
        async (e: any) => {
            e.preventDefault();
            assemblyStore.setAssemblyId(props.assemblyid);
            assemblyStore.setIsAddNewAgendaItem(true);
        },
        [assemblyStore, props.assemblyid]
    );

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }

    const initalAgendaItems: AgendaItem[] = agendaItemParents.map(
        ({ id, sort, agendaitems_mls, agendaitemfiles, agendaitem_children }) => {
            const initalAgendaItemsChildren = agendaitem_children.map(
                ({ id, sort, agendaitems_mls, agendaitemfiles }) => {
                    const agendaItem: AgendaItemChild = {
                        id: id,
                        sort: sort,
                        text: agendaitems_mls[0].text ?? "",
                        agendaitemfiles: agendaitemfiles,
                        description: agendaitems_mls[0].description ?? ""
                    };
                    return agendaItem;
                }
            );
            const agendaItem: AgendaItem = {
                id: id,
                sort: sort,
                text: agendaitems_mls[0].text ?? "",
                agendaitemfiles: agendaitemfiles,
                agendaitemchild: initalAgendaItemsChildren,
                description: agendaitems_mls[0].description ?? ""
            };

            return agendaItem;
        }
    );

    assemblyStore.setOriginalAgendaItems(initalAgendaItems);
    assemblyStore.setResetAgendaItems(true);

    if (data?.oldAssemblyWithGeneralDocuments && data?.oldAssemblyWithGeneralDocuments.length > 0) {
        const booleanValue: boolean = JSON.parse(data.oldAssemblyWithGeneralDocuments[0].value || "false");
        assemblyStore.setOldAssemblyWithGeneralDocuments(booleanValue);
        if (assemblyStore.oldAssemblyWithGeneralDocuments) {
            assemblyStore.setIsAssemblyFilesExists(true);
        }
    }

    return (
        <div className={`${styles.AssemblyAddAgendaModalContainer}`}>
            <div className={styles.AgendaButtons}>
                {assemblyStore.isAssemblyFilesExists ? undefined : (
                    <div className={styles.AgendaTitle}>{t("screens.realestate.assembly.agenda")}</div>
                )}

                <div className={styles.CopyAgendaItemsButtonContainer}>
                    <CopyAssemblyAgendaButton
                        assemblyid={props.assemblyid}
                        realestateid={props.realestateid}
                        refetchAgendaItems={refetchAgendaItems}
                        assemblydate={props.assemblydate}
                    />
                </div>
                <div className={styles.AgendaAddButton}>
                    <UI.Button onClick={onClickAddAgenda} label={"+"} />
                </div>
            </div>
            <AssemblyAgendaItems initialAgendaItems={initalAgendaItems} />
        </div>
    );
};

export const AssemblyAgenda = Sentry.withProfiler(observer(AssemblyAgendaBase));
