import { gql } from "@apollo/client";

export const GET_ANNOUNCEMENTS = gql`
    query GetAnnouncements($customerid: uuid!, $userid: uuid!, $language: String = "de") {
        announcements: uccm_get_active_announcements(
            args: { pcustomerid: $customerid, pproduct: 2, puserid: $userid }
        ) {
            id
            details: announcements_mls(where: { language: { _eq: $language } }) {
                content
                teaser
            }
        }
    }
`;

export const GET_ANNOUNCEMENT_HISTORY = gql`
    query GetAnnouncementHistory($apiversion: float8!, $userroles: [Int!]!, $targetoptions: [Int!]!) {
        announcements: uccm_announcements(
            order_by: { enddate: desc }
            where: {
                targetproduct: { _eq: 2 }
                state: { _in: [20, 30] }
                apiversionfrom: { _lte: $apiversion }
                announcementroles: { role: { _in: $userroles } }
                _or: [{ targetoption: { _in: $targetoptions } }, { targetoption: { _is_null: true } }]
            }
        ) {
            enddate
            id
            announcement: announcements_mls {
                content
            }
        }
    }
`;

export const INSERT_ANNOUNCEMENT_AUDIT = gql`
    mutation InsertAnnouncementAudit($objects: [uccm_announcementaudits_insert_input!]!) {
        insertedannouncementaucit: insert_uccm_announcementaudits(objects: $objects) {
            affected_rows
        }
    }
`;
