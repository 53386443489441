export const isUUID = (uuid: string) => {
    let s = uuid.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$");
    if (s === null) {
        return false;
    }
    return true;
};

export const isNumeric = (n: any) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

export enum ScreenSize {
    MOBILE = "mobile",
    TABLET = "tablet",
    DESKTOP = "desktop"
}

function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export const boldenMatchedSubstring = (substring: string, stringToCheck: string, htmlTagClassName?: string) => {
    if (substring === "") {
        return stringToCheck;
    }

    const escapedSubstring = escapeRegExp(substring);
    const regex = new RegExp(escapedSubstring, "gi");

    const matchedString = stringToCheck.match(regex);

    if (!matchedString) {
        return stringToCheck;
    }

    const formattedString = stringToCheck.replace(regex, (match) => {
        return `<strong class="bold ${htmlTagClassName ?? ""}">${match}</strong>`;
    });

    return formattedString;
};
