import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./TagSearch.module.css";
import { UI } from "@wwimmo/ui";
import { ColorStyle } from "src/utils/Colors";
import { SearchTag } from "src/stores/tickets/TicketListStore";
import { Tag } from "src/components/tag/Tag";
import { ISearchTag } from "src/stores/krediflow/InvoiceTypes";
interface TagSearchProps {
    value: string;
    searchTags: SearchTag[] | ISearchTag[];
    onChangeInput: (value: string) => void;
    updateTagStatusById: (tagId: string, status: boolean) => void;
    reset: () => void;
}
export const TagSearch = (props: TagSearchProps) => {
    const { value, searchTags, updateTagStatusById, onChangeInput, reset } = props;
    const ref = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const inactiveTagContainerRef = useRef<HTMLDivElement>(null);
    const inputContainerRef = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [inactiveTagsContainerHeight, setInactiveTagsContainerHeight] = useState<number>(42);
    const [inputContainerHeight, setInputContainerHeight] = useState<number>(30);
    const [inputValue, setInputValue] = useState<string>(value);
    const [highlightedTags, setHighlightedTags] = useState<string[]>([]);
    const tags = useMemo(() => {
        return {
            active: searchTags.filter((searchTag) => searchTag.active),
            inactive: searchTags.filter((searchTag) => !searchTag.active)
        };
    }, [searchTags]);

    useEffect(() => {
        const highlightedTagIds: string[] = [];
        tags.inactive.forEach((tag) => {
            if (inputValue.length > 3 && tag.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())) {
                highlightedTagIds.push(tag.id);
            }
        });
        setHighlightedTags(highlightedTagIds);
    }, [tags.inactive, inputValue]);

    const onChangeFormInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(event.currentTarget.value);
            onChangeInput(event.currentTarget.value);
        },
        [onChangeInput]
    );

    const updateHeights = useCallback(() => {
        if (inactiveTagContainerRef) {
            setInactiveTagsContainerHeight((inactiveTagContainerRef.current?.offsetHeight ?? 32) + 12);
        }
        if (inputContainerRef) {
            setInputContainerHeight(inputContainerRef.current?.offsetHeight ?? 30);
        }
    }, [inputContainerRef, inactiveTagContainerRef]);

    useEffect(() => {
        updateHeights();
    }, [updateHeights, tags]);

    useEffect(() => {
        const onWindowResize = () => {
            updateHeights();
        };
        window.addEventListener("resize", onWindowResize);
        return () => window.removeEventListener("resize", onWindowResize);
    }, [updateHeights]);

    const onExpandSearch = useCallback(() => {
        inputRef.current?.focus();
        setExpanded(true);
    }, [inputRef]);

    const onRetractSearch = useCallback(() => {
        inputRef.current?.blur();
        setExpanded(false);
    }, [inputRef]);

    const handleClickOutside = useCallback(
        (event: MouseEvent | TouchEvent) => {
            if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
                onRetractSearch();
            }
            return () => {
                document.removeEventListener("click", handleClickOutside, true);
            };
        },
        [ref, onRetractSearch]
    );

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
    }, [handleClickOutside]);

    const onClearSearch = useCallback(() => {
        setInputValue("");
        onChangeInput("");
        setExpanded(false);
        inputRef.current?.blur();
        reset();
    }, [onChangeInput, reset]);

    const onRemoveTag = useCallback(
        (id: string) => {
            updateTagStatusById(id, false);
            if (inputRef) {
                inputRef.current?.focus();
            }
        },
        [updateTagStatusById]
    );

    const onAddTag = useCallback(
        (id: string) => {
            if (inputRef) {
                inputRef.current?.focus();
            }
            if (highlightedTags.includes(id)) {
                setInputValue("");
                onChangeInput("");
            }
            updateTagStatusById(id, true);
        },
        [updateTagStatusById, highlightedTags, inputRef, onChangeInput]
    );

    const onKeyPress = useCallback(
        (event: any) => {
            if (event.key === "Enter") {
                onRetractSearch();
            } else if (event.key === "Escape") {
                onRetractSearch();
            } else if (event.key === "Backspace" && inputValue === "" && tags.active.length !== 0) {
                updateTagStatusById(tags.active[tags.active.length - 1].id, false);
            } else {
                onExpandSearch();
            }
        },
        [onRetractSearch, inputValue, tags, updateTagStatusById, onExpandSearch]
    );

    return (
        <div className={styles.TagSearchContainer} style={{ height: inputContainerHeight + 12 }} ref={ref}>
            <div
                className={`${styles.TagSearch} ${expanded ? styles.TagSearchExpanded : ""}`}
                style={{
                    height: expanded
                        ? inputContainerHeight + 12 + inactiveTagsContainerHeight
                        : inputContainerHeight + 12
                }}
            >
                <div style={{ height: inputContainerHeight, marginTop: 3 }} className="d-flex align-items-center pr-1">
                    <UI.Icon
                        icon={UI.SVGIcon.Search}
                        className="mr-1"
                        color={ColorStyle("gray")}
                        size="small"
                        onClick={onExpandSearch}
                    />
                    <div className={styles.ActiveTagContainer} ref={inputContainerRef}>
                        {tags.active.map((tag) => (
                            <Tag
                                key={tag.id}
                                id={tag.id}
                                className={styles.ActiveTag}
                                label={tag.label}
                                remove={onRemoveTag}
                            />
                        ))}
                        <input
                            ref={inputRef}
                            className={`flex-fill ${styles.Input}`}
                            onChange={onChangeFormInput}
                            onClick={onExpandSearch}
                            onKeyDown={onKeyPress}
                            type="text"
                            value={inputValue}
                        />
                    </div>
                    <div
                        className={styles.CloseContainer}
                        style={{ width: tags.active.length !== 0 || inputValue !== "" ? 32 : 0 }}
                    >
                        <UI.Icon className="ml-1" icon={UI.SVGIcon.Close} size="small" onClick={onClearSearch} />
                    </div>
                </div>
                <div ref={inactiveTagContainerRef} className={styles.TagContainer}>
                    {tags.inactive.map((tag) => (
                        <Tag
                            key={tag.id}
                            id={tag.id}
                            label={tag.label}
                            add={onAddTag}
                            highlighted={
                                inputValue.length > 3 &&
                                tag.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
                            }
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
