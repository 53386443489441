import { gql } from "@apollo/client";

export const GET_MAINTENANCE_DETAILS = gql`
    query GetMaintenanceDetails($maintenanceid: uuid!, $lang: String = "de") {
        common_maintenances(where: { id: { _eq: $maintenanceid } }) {
            cost
            craftsmanreference
            date
            id
            name
            remarks
            status
            workfrom
            workuntil
            appliance {
                id
                name
                manufacturer
            }
            craftsman: person {
                id
                name1
                name2
            }
            unit {
                id
                name
            }
            booking {
                accountid
                date
                debit
                credit
                text
                quantity
                documentnumber
                bookingfiles {
                    id
                    fileid
                }
                account {
                    number
                    title
                }
            }
        }

        maintenancestatus: common_enumerations(
            where: { type: { _eq: 14 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;
