import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_TENANT_BOOKINGS_AND_FILES } from "src/api/tenant";
import {
    GetTenantBookingsAndFiles,
    GetTenantBookingsAndFilesVariables
} from "src/api/generated/GetTenantBookingsAndFiles";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { Route, selectRoute } from "src/config/routes";
import { withRouter, RouteComponentProps, useParams } from "react-router";
import { ScreenWithTiles } from "src/components/screenWithTiles/ScreenWithTiles";
import { ScreenType } from "src/api/tiles";
import TenantAccountBookingList from "./account-booking-list/TenantAccountBookingList";
import TenantDocumentList from "./document-list/TenantDocumentList";

interface Params {
    realestateid: string;
    unitid: string;
    tenantid: string;
}

const TenantBase = (props: RouteComponentProps) => {
    const { realestateid, unitid, tenantid } = useParams<Params>();

    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);

    useEffect(() => {
        navStore.setTitle(uiStore.tenantItemName!);
        navStore.setBackbutton(
            selectRoute(Route.unit, authStore.user?.role, { realestateid: realestateid, unitid: unitid }),
            t("screens.unit.title")
        );
    }, [unitid, authStore.user?.role, navStore, t, realestateid, uiStore.tenantItemName]);

    const { data, loading, error } = useQuery<GetTenantBookingsAndFiles, GetTenantBookingsAndFilesVariables>(
        GET_TENANT_BOOKINGS_AND_FILES,
        {
            variables: {
                tenantid: tenantid
            }
        }
    );

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const tenantBookingsAndFiles =
        data.tenant_bookings_and_files.length > 0 ? data.tenant_bookings_and_files[0] : undefined;

    return (
        <ScreenWithTiles title={""} screenId={ScreenType.TENANT} realestateid={realestateid}>
            <UI.Card title={t("screens.realestate.finances.account")}>
                <TenantAccountBookingList
                    tenantBookings={tenantBookingsAndFiles ? tenantBookingsAndFiles.tenantbookings : []}
                    accountBalance={tenantBookingsAndFiles ? tenantBookingsAndFiles.accountbalance : undefined}
                />
            </UI.Card>
            <UI.Card title={t("screens.realestate.documents.title")}>
                <TenantDocumentList tenantFiles={tenantBookingsAndFiles ? tenantBookingsAndFiles.tenantfiles : []} />
            </UI.Card>
        </ScreenWithTiles>
    );
};

export const Tenant = withRouter(Sentry.withProfiler(observer(TenantBase)));
