import React, { useContext } from "react";
import * as Sentry from "@sentry/react";
import { Switch, withRouter } from "react-router";
import { Header, PrivateRoute } from "src/components/";
import { observer } from "mobx-react-lite";
import { Route } from "src/config/routes";
import * as Screens from "src/screens";
import { RootStoreContext } from "src/stores/RootStore";
import { SIDEBAR_MENU_PATH } from "src/stores/UIStore";
import { NAVIGATIONBAR_TYPE } from "src/components/header/navigationbar/Navigationbar";

export const ServicesRoutesBase = () => {
    const { uiStore } = useContext(RootStoreContext);

    return (
        <React.Fragment>
            {uiStore.currentSidebarMenuPath === SIDEBAR_MENU_PATH.SERVICES_COSTS_AND_INVOICES ? (
                <Header.Navigationbar navigationbarType={NAVIGATIONBAR_TYPE.SERVICES_COSTS_AND_INVOICES} />
            ) : uiStore.currentSidebarMenuPath === SIDEBAR_MENU_PATH.SERVICES_FEATURE_AND_INTEGRATION ? (
                <Header.Navigationbar navigationbarType={NAVIGATIONBAR_TYPE.SERVICES_FEATURES_AND_INTEGRATIONS} />
            ) : (
                <></>
            )}
            <div style={{ height: 52 }} />
            <Switch>
                <PrivateRoute
                    key={Route.manager + Route.servicesCostsAndInvoicesCurrentMonth}
                    path={Route.manager + Route.servicesCostsAndInvoicesCurrentMonth}
                    exact
                    component={Screens.ServicesCostsAndInvoicesCurrentMonth}
                />
                <PrivateRoute
                    key={Route.manager + Route.servicesCostsAndInvoicesHistory}
                    path={Route.manager + Route.servicesCostsAndInvoicesHistory}
                    exact
                    component={Screens.ServicesCostsAndInvoicesHistory}
                />
                <PrivateRoute
                    key={Route.manager + Route.servicesCostsAndInvoicesHistoryInvoiceDetailsMonth}
                    path={Route.manager + Route.servicesCostsAndInvoicesHistoryInvoiceDetailsMonth}
                    exact
                    component={Screens.ServicesCostsAndInvoicesHistoryInvoiceDetails}
                />
                <PrivateRoute
                    key={Route.manager + Route.servicesCostsAndInvoicesOverview}
                    path={Route.manager + Route.servicesCostsAndInvoicesOverview}
                    exact
                    component={Screens.ServicesCostsAndInvoicesOverview}
                />
                <PrivateRoute
                    key={Route.manager + Route.servicesCostsAndInvoicesCurrentMonthInvoicedUserDetails}
                    path={Route.manager + Route.servicesCostsAndInvoicesCurrentMonthInvoicedUserDetails}
                    exact
                    component={Screens.InvoicedUserDetails}
                />
                <PrivateRoute
                    key={Route.manager + Route.servicesCostsAndInvoicesHistoryMonthInvoicedUserDetails}
                    path={Route.manager + Route.servicesCostsAndInvoicesHistoryMonthInvoicedUserDetails}
                    exact
                    component={Screens.InvoicedUserDetails}
                />
                <PrivateRoute
                    key={Route.manager + Route.servicesCostsAndInvoicesCurrentMonthInvoicedRentalVariableDetails}
                    path={Route.manager + Route.servicesCostsAndInvoicesCurrentMonthInvoicedRentalVariableDetails}
                    exact
                    component={Screens.InvoicedRentalVariableDetails}
                />
                <PrivateRoute
                    key={Route.manager + Route.servicesCostsAndInvoicesHistoryMonthInvoicedRentalVariableDetails}
                    path={Route.manager + Route.servicesCostsAndInvoicesHistoryMonthInvoicedRentalVariableDetails}
                    exact
                    component={Screens.InvoicedRentalVariableDetails}
                />
                <PrivateRoute
                    key={Route.manager + Route.servicesFeatures}
                    path={Route.manager + Route.servicesFeatures}
                    exact
                    component={Screens.ServicesFeaturesScreen}
                />
                <PrivateRoute
                    key={Route.manager + Route.servicesIntegrations}
                    path={Route.manager + Route.servicesIntegrations}
                    exact
                    component={Screens.ServicesIntegrationsScreen}
                />
                <PrivateRoute component={Screens.ErrorNotFound} />
            </Switch>
        </React.Fragment>
    );
};

export const ServicesRoutes = Sentry.withProfiler(withRouter(observer(ServicesRoutesBase)));
