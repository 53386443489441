import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import BackgroundImage from "src/assets/images/HG_0101.jpg";
import styles from "./ManagerDashboard.module.css";

const ManagerDashboardBase = () => {
    const { t } = useTranslation();
    const { navStore } = useContext(RootStoreContext);

    useEffect(() => {
        navStore.setTitle("");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);
    }, [navStore]);

    return (
        <UI.Container
            fluid
            style={{
                backgroundImage: `url(${BackgroundImage})`
            }}
            className={styles.DashboardBackgroundImage}
        >
            <div className={styles.WelcomeText}>{t("labels.welcome", { username: navStore.customer.userName })}</div>
        </UI.Container>
    );
};

export const ManagerDashboard = Sentry.withProfiler(observer(ManagerDashboardBase));
