import React, { useContext, useCallback, FunctionComponent, useState, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import {
    GetNewsDetailsById_newsitems as newsitem,
    GetNewsDetailsById_newsstatus as newsstatus
} from "src/api/generated/GetNewsDetailsById";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { useForm } from "react-hook-form";
import { MessageType } from "src/components/notifications/Notifier";
import { DELETE_NEWSFILE_BY_FILEID, NewsState } from "src/api/news";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey } from "src/network/User";
import { isEmpty } from "lodash";
import { useMutation } from "@apollo/client";
import { DELETE_NEWS_BY_ID } from "src/api/news";
import { DeleteNewsById, DeleteNewsByIdVariables } from "src/api/generated/DeleteNewsById";
import { DeleteNewsFileByFileId, DeleteNewsFileByFileIdVariables } from "src/api/generated/DeleteNewsFileByFileId";
import { GetNewsDetailsById_newsitems_newsfileimage as db_newsfileimage } from "src/api/generated/GetNewsDetailsById";
import { useHistory } from "react-router-dom";
import { Route, selectRoute } from "src/config/routes";
import { getLang } from "src/utils/i18n";
import {
    getHtmlStringFromStringArray,
    getStringArrayFromHtmlString,
    getStringWithNewlinesFromHtmlString
} from "src/utils/News";
import { NewsPreview } from "src/components/news/preview/NewsPreview";
import { useDropzone } from "react-dropzone";
import { resizeImageFile, fileToBase64 } from "src/utils/Image";
import { useSaveFormByShortcut } from "src/hooks/form/useSaveFormByShortcut";
import { FORM_STATUS } from "src/utils/Form";
import { useSaveUnsavedChangesInForm } from "src/hooks/save-unsaved-changes-in-form/useSaveUnsavedChangesInForm";
import styles from "./NewsEditForm.module.css";
import { AddNewsDocumentsModal } from "./modal/AddNewsDocumentsModal";
import { ColorStyle } from "src/utils/Colors";

type NewsEditFormProps = {
    newsDetails?: newsitem;
    newsStatus: newsstatus[];
    customerid?: string;
    newsid: string;
    realestateid: string;
    refetchData: any;
};

interface NewsEditFormFields {
    title: string;
    lead: string;
    text: string;
    status: number;
}

const NewsEditFormBase: FunctionComponent<NewsEditFormProps> = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { uiStore, authStore, navStore } = useContext(RootStoreContext);
    const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);

    const [dbNewsFileImage, setDbNewsFileImage] = useState<db_newsfileimage | undefined>(
        props.newsDetails && props.newsDetails?.newsfileimage.length > 0
            ? props.newsDetails.newsfileimage[0]
            : undefined
    );

    useEffect(() => {
        uiStore.setDbNewsFiles(
            props.newsDetails && props.newsDetails?.newsfiles.length > 0 ? props.newsDetails.newsfiles : []
        );
    }, [props.newsDetails, uiStore]);

    const [imageUrl, setImageUrl] = useState<string>(
        dbNewsFileImage ? NetworkConfig.openThumbnailUrl + dbNewsFileImage.file?.id : ""
    );
    const [hasFileChanges, setHasFileChanges] = useState<boolean>(false);

    const onFileDrop = useCallback(
        async (acceptedFiles: any) => {
            setSelectedFiles([...selectedFiles, ...acceptedFiles]);
            const file: File = acceptedFiles[0];
            const fileUrl = URL.createObjectURL(file);
            setImageUrl(fileUrl);

            setHasFileChanges(true);
        },
        [selectedFiles]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onFileDrop,
        accept: {
            "image/jpeg": [".jpeg", ".jpg"],
            "image/png": [".png"],
            "image/gif": [".gif"]
        },
        maxFiles: 1
    });

    const hasDbNewsFileImage = props.newsDetails && props.newsDetails?.newsfileimage.length > 0 ? true : false;
    const hasDbNewsFile = props.newsDetails && props.newsDetails?.newsfiles.length > 0 ? true : false;
    const { newsid, realestateid, refetchData } = props;

    const [isNewNewsEntry, setIsNewNewsEntry] = useState<boolean>(props.newsDetails ? false : true);
    const [currentStatus, setCurrentStatus] = useState<FORM_STATUS>(FORM_STATUS.NONE);
    const [previewTitle, setPreviewTitle] = useState<string>(props?.newsDetails?.news[0].title ?? "");
    const [previewLead, setPreviewLead] = useState<string>(props?.newsDetails?.news[0].lead ?? "");
    const [previewText, setPreviewText] = useState<Array<string>>(
        props?.newsDetails?.news[0].text ? getStringArrayFromHtmlString(props?.newsDetails?.news[0].text) : [""]
    );

    const [deleteNewsByIdMutation] = useMutation<DeleteNewsById, DeleteNewsByIdVariables>(DELETE_NEWS_BY_ID, {
        onCompleted: () => {
            printStatusMessage(t("screens.realestate.news_edit.delete_success"), MessageType.INFO);
        }
    });

    const [deleteNewsFileByFileIdMutation] = useMutation<DeleteNewsFileByFileId, DeleteNewsFileByFileIdVariables>(
        DELETE_NEWSFILE_BY_FILEID,
        {
            onCompleted: () => {
                printStatusMessage(t("screens.realestate.news_edit.delete_newsfiles"), MessageType.INFO);

                refetchData();
            }
        }
    );

    const printStatusMessage = useCallback(
        (message: string, msgType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: msgType,
                    autoClose: msgType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    const { register, handleSubmit, formState, reset, watch } = useForm<NewsEditFormFields>({
        defaultValues: {
            title: props?.newsDetails?.news[0].title ?? "",
            lead: props?.newsDetails?.news[0].lead ?? "",
            text: props?.newsDetails?.news[0].text
                ? getStringWithNewlinesFromHtmlString(props?.newsDetails?.news[0].text)
                : "",
            status: props.newsDetails?.state ?? 10
        }
    });
    const { dirtyFields, isDirty } = formState;

    const refSubmitButtom = useRef<HTMLButtonElement>(null);

    const saveForm = useCallback(() => {
        refSubmitButtom?.current?.click();
    }, []);

    const { saveFormModal, closeSaveFormModalAndGoBack } = useSaveUnsavedChangesInForm({
        saveFormFunction: saveForm,
        currentFormStatus: currentStatus,
        isFormDirty: isDirty
    });

    useSaveFormByShortcut({ saveFormFunction: saveForm, isFormDirty: isDirty });

    const watchTitle = watch("title");
    const watchLead = watch("lead");
    const watchText = watch("text");

    useEffect(() => {
        setPreviewTitle(watchTitle);
    }, [watchTitle]);

    useEffect(() => {
        setPreviewLead(watchLead);
    }, [watchLead]);

    useEffect(() => {
        const textLines = watchText.split("\n");
        setPreviewText(textLines);
    }, [watchText]);

    const onRemoveImageFile = useCallback(() => {
        const newFiles = [...selectedFiles];
        newFiles.splice(0, 1);
        setSelectedFiles(newFiles);
        setImageUrl("");

        if (hasDbNewsFileImage) {
            setHasFileChanges(true);
        } else {
            setHasFileChanges(false);
        }

        setDbNewsFileImage(undefined);
    }, [selectedFiles, hasDbNewsFileImage]);

    const onDeleteFile = useCallback(
        (fileId: any) => async () => {
            if (fileId) {
                await deleteNewsFileByFileIdMutation({
                    variables: {
                        fileid: fileId
                    }
                });

                if (props.newsDetails && props.newsDetails?.newsfiles.length > 0) {
                    const filteredNewsFiles = props.newsDetails.newsfiles.filter((file) => file.file?.id !== fileId);
                    uiStore.setDbNewsFiles(filteredNewsFiles);
                }
            }
        },
        [deleteNewsFileByFileIdMutation, props.newsDetails, uiStore]
    );

    const onResetForm = useCallback(() => {
        if (props.newsDetails && props.newsDetails.news.length !== 0) {
            const news = props.newsDetails.news[0];
            const { state } = props.newsDetails;
            const { title, lead, text } = news;

            reset({
                title: title ?? "",
                lead: lead ?? "",
                text: text ? getStringWithNewlinesFromHtmlString(text) : "",
                status: state ?? 10
            });

            setPreviewTitle(title ?? "");
            setPreviewLead(lead ?? "");
            setPreviewText(text ? getStringArrayFromHtmlString(text) : [""]);
        } else {
            reset({
                title: "",
                lead: "",
                text: "",
                status: 10
            });

            setPreviewTitle("");
            setPreviewLead("");
            setPreviewText([""]);
        }

        if (hasDbNewsFileImage && !dbNewsFileImage) {
            setDbNewsFileImage(
                props.newsDetails && props.newsDetails?.newsfileimage.length > 0
                    ? props.newsDetails.newsfileimage[0]
                    : undefined
            );
            setImageUrl(
                props.newsDetails && props.newsDetails?.newsfileimage.length > 0
                    ? NetworkConfig.openThumbnailUrl + props.newsDetails.newsfileimage[0].file?.id
                    : ""
            );
        }
        if (hasDbNewsFile && !uiStore.dbNewsFiles) {
            uiStore.setDbNewsFiles(
                props.newsDetails && props.newsDetails?.newsfiles.length > 0 ? props.newsDetails.newsfileimage : []
            );
        }

        if (selectedFiles.length > 0) {
            onRemoveImageFile();
        }

        setHasFileChanges(false);
    }, [
        reset,
        props.newsDetails,
        onRemoveImageFile,
        selectedFiles.length,
        dbNewsFileImage,
        hasDbNewsFileImage,
        hasDbNewsFile,
        uiStore
    ]);

    const onDeleteNews = useCallback(async () => {
        if (isNewNewsEntry) {
            history.replace(selectRoute(Route.dashboard, authStore.user?.role, { realestateid: realestateid }));
        } else {
            await deleteNewsByIdMutation({
                variables: {
                    newsid: newsid
                }
            });

            uiStore.setHasNewNewsData(true);
            history.replace(selectRoute(Route.dashboard, authStore.user?.role, { realestateid: realestateid }));
        }
    }, [authStore.user?.role, history, realestateid, deleteNewsByIdMutation, isNewNewsEntry, newsid, uiStore]);

    const handleFormSubmit = useCallback(
        async (formData: NewsEditFormFields) => {
            setCurrentStatus(FORM_STATUS.SAVING_FORM);

            if (authStore.user && authStore.token) {
                const accessToken = authStore.token;
                const tokenType = authStore.tokenType;
                const role = authStore.user?.role;

                let imageBase64String: string | undefined = undefined;
                let fileName: string | undefined = undefined;

                if (selectedFiles.length > 0) {
                    fileName = selectedFiles[0].name;
                    const file: File = selectedFiles[0];
                    const fileUrl = await fileToBase64(file);

                    let resizedImageDataURL = undefined;

                    if (file.type !== "image/gif") {
                        resizedImageDataURL = await resizeImageFile(file);
                    }

                    if (resizedImageDataURL) {
                        imageBase64String = resizedImageDataURL.split(",")[1];
                    } else {
                        imageBase64String = fileUrl.split(",")[1];
                        if (!imageBase64String) {
                            console.error("error in image processing");
                        }
                    }
                }

                const state = formData.status
                    ? Number(formData.status)
                    : props.newsDetails?.state
                    ? props.newsDetails?.state
                    : Number(NewsState.Published);

                const requestBody = {
                    id: newsid,
                    realestateid: realestateid,
                    state: state,
                    language: getLang(),
                    title: formData.title,
                    lead: formData.lead,
                    text: getHtmlStringFromStringArray(previewText),
                    filechanged: hasFileChanges,
                    file: imageBase64String ?? undefined,
                    filename: imageBase64String ? fileName : undefined,
                    role: navStore.isCondominium ? 32 : 33
                };

                let submitWasSuccessfull = false;

                try {
                    const fetchResult = await fetch(`${NetworkConfig.upsertNewsUrl}`, {
                        method: "PUT",
                        body: JSON.stringify(requestBody),
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `${tokenType} ${accessToken}`,
                            "x-hasura-role": getRoleKey(role)
                        }
                    });

                    if (fetchResult.status === 200) {
                        printStatusMessage(t("screens.realestate.news_edit.save_success"), MessageType.INFO);
                        refetchData();
                        submitWasSuccessfull = true;
                    } else {
                        const returnValue = await fetchResult.json();
                        printStatusMessage(
                            t("screens.realestate.news_edit.save_error", {
                                errorMessage: returnValue.message
                            }),
                            MessageType.ERROR
                        );
                    }

                    if (isNewNewsEntry) {
                        uiStore.setHasNewNewsData(true);
                        setIsNewNewsEntry(false);
                    }

                    reset(
                        {
                            ...formData,
                            status: state
                        },
                        {
                            keepDirty: false,
                            keepDefaultValues: false,
                            keepValues: false
                        }
                    );
                } catch (error: any) {
                    printStatusMessage(
                        t("screens.realestate.news_edit.save_error", {
                            errorMessage: error.message
                        }),
                        MessageType.ERROR
                    );
                } finally {
                    setCurrentStatus(FORM_STATUS.NONE);
                    setHasFileChanges(false);
                }

                if (submitWasSuccessfull) {
                    closeSaveFormModalAndGoBack();
                }

                if (submitWasSuccessfull && Number(formData.status) === NewsState.Published) {
                    history.replace(
                        selectRoute(Route.realestateNewsPreview, authStore.user?.role, {
                            realestateid: realestateid,
                            newsid: newsid
                        })
                    );
                }
            }
        },
        [
            reset,
            printStatusMessage,
            authStore.token,
            authStore.tokenType,
            authStore.user,
            previewText,
            newsid,
            realestateid,
            refetchData,
            isNewNewsEntry,
            uiStore,
            t,
            selectedFiles,
            hasFileChanges,
            history,
            props.newsDetails?.state,
            closeSaveFormModalAndGoBack,
            navStore.isCondominium
        ]
    );

    const newsEditForm = (
        <UI.Container className={styles.EditFormContainer}>
            {saveFormModal}
            <UI.Form>
                <UI.Row>
                    <UI.Col>
                        <UI.Input
                            label={t("screens.realestate.news_edit.title").toString()}
                            {...register("title")}
                            type="text"
                            autoComplete="off"
                            className={dirtyFields.title ? styles.changed : ""}
                        />
                    </UI.Col>
                    <UI.Col>
                        <UI.Input
                            label={t("screens.realestate.news_edit.status").toString()}
                            {...register("status")}
                            as="select"
                            className={dirtyFields.status ? styles.changed : ""}
                            disabled={
                                isNewNewsEntry
                                    ? false
                                    : props.newsDetails?.state && props.newsDetails?.state === NewsState.Editing
                                    ? false
                                    : true
                            }
                        >
                            <option value={NewsState.Editing}>{t("screens.realestate.news_edit.editing")}</option>
                            <option value={NewsState.Published}>{t("screens.realestate.news_edit.published")}</option>
                        </UI.Input>
                    </UI.Col>
                </UI.Row>
                <UI.Row>
                    <UI.Col>
                        <UI.Input
                            label={t("screens.realestate.news_edit.lead").toString()}
                            {...register("lead")}
                            type="text"
                            as="textarea"
                            autoComplete="off"
                            className={`${dirtyFields.lead ? styles.changed : ""} ${styles.LeadTextArea}`}
                        />
                    </UI.Col>
                </UI.Row>
                <UI.Row>
                    <UI.Col>
                        <UI.Input
                            label={t("screens.realestate.news_edit.text").toString()}
                            {...register("text")}
                            type="text"
                            as="textarea"
                            autoComplete="off"
                            className={`${dirtyFields.text ? styles.changed : ""} ${styles.TextTextArea}`}
                        />
                    </UI.Col>
                </UI.Row>
            </UI.Form>
        </UI.Container>
    );

    const fileSelection = (
        <div className={styles.FilesDropZoneContainer}>
            {selectedFiles.length > 0 || dbNewsFileImage ? undefined : (
                <div {...getRootProps({ className: `${styles.FilesDropZone}` })}>
                    <input {...getInputProps()} />
                    <div>Bild hinzufügen</div>
                </div>
            )}

            {selectedFiles.length > 0 || dbNewsFileImage ? (
                <div className={styles.CurrentlySelectedFileContainer}>
                    <div>
                        {selectedFiles.length > 0
                            ? selectedFiles[0].name
                            : dbNewsFileImage
                            ? dbNewsFileImage.file?.name
                            : undefined}
                    </div>
                    <UI.Icon
                        size={20}
                        onClick={onRemoveImageFile}
                        icon={UI.SVGIcon.Close}
                        className={styles.RemoveFileIcon}
                    />
                </div>
            ) : undefined}
        </div>
    );

    const newsFileList = (
        <div className={styles.NewsFileSelectionContainer}>
            {uiStore.dbNewsFiles.map((file, index) => (
                <div className={styles.NewsFilesItem} key={file.file?.id + index}>
                    <a
                        key={file.file?.id}
                        href={NetworkConfig.datafileUrl + file.file?.id}
                        className={`${styles.DocumentInfoItemsContainer} link-underline`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <UI.Icon
                            icon={UI.SVGIcon.Documents}
                            color={ColorStyle("white")}
                            size="small"
                            className={styles.DocumentIcon}
                        />
                        <div className={styles.FileName}>{file.file?.name}</div>
                    </a>
                    <UI.Icon
                        size={16}
                        onClick={onDeleteFile(file.file?.id)}
                        icon={UI.SVGIcon.Close}
                        className={styles.RemoveFileIcon}
                        color={ColorStyle("white")}
                    />
                </div>
            ))}
        </div>
    );

    const onClickOpenDocumentModal = useCallback(() => {
        uiStore.setIsAddNewsDocumentClicked(true);
    }, [uiStore]);

    return (
        <React.Fragment>
            <UI.Container className="pt-4" fluid>
                <UI.Row>
                    <UI.Col sm="12" lg="6" className={styles.EditFormCol}>
                        {newsEditForm}
                        {fileSelection}

                        <div
                            className={props.newsDetails ? styles.AddNewsDocument : styles.AddNewsDocumentDisable}
                            onClick={props.newsDetails ? onClickOpenDocumentModal : undefined}
                        >
                            <UI.Icon icon={UI.SVGIcon.Add} size={14} className={styles.IconAddNewsDocument} />
                            {props.newsDetails
                                ? t("screens.realestate.news_edit.add_documents")
                                : t("screens.realestate.news_edit.add_documents_before_save")}
                        </div>
                        {uiStore.isAddNewsDocumentClicked ? <AddNewsDocumentsModal /> : undefined}
                    </UI.Col>
                    <UI.Col sm="12" lg="6" className={`${styles.EditPreviewCol} mt-sm-4 mt-lg-0`}>
                        <NewsPreview
                            title={previewTitle}
                            lead={previewLead}
                            date={props.newsDetails?.timestamp}
                            text={previewText}
                            imageUrl={imageUrl}
                            className={styles.NewsPreviewStyles}
                        />

                        {newsFileList}
                    </UI.Col>
                </UI.Row>
                <UI.Row>
                    <UI.Col className="my-3">
                        <UI.Button
                            ref={refSubmitButtom}
                            type="submit"
                            label={currentStatus === FORM_STATUS.SAVING_FORM ? t("labels.is_saving") : t("labels.save")}
                            disabled={
                                (isEmpty(dirtyFields) && !hasFileChanges) || currentStatus === FORM_STATUS.SAVING_FORM
                            }
                            onClick={handleSubmit(handleFormSubmit)}
                            className={`${styles.NewsFormButton}`}
                        />
                        <UI.Button
                            label={t("labels.discard")}
                            disabled={
                                (isEmpty(dirtyFields) && !hasFileChanges) || currentStatus === FORM_STATUS.SAVING_FORM
                            }
                            onClick={onResetForm}
                            className={`${styles.NewsFormButton} ${styles.DiscardButton} ml-sm-2 mt-2 mt-sm-0`}
                            variant="outline-primary"
                        />
                        {props.newsDetails?.state && props.newsDetails?.state === NewsState.Editing ? (
                            <UI.ConfirmationDialog
                                key="customerDeleteDialog"
                                buttonText={t("labels.delete")}
                                modalTitle={t("labels.delete")}
                                confirmationQuestion={t("screens.realestate.news_edit.delete_confirmation")}
                                inProgressText={t("screens.realestate.news_edit.delete_in_progress")}
                                onConfirmation={onDeleteNews}
                                buttonClasses={`${styles.NewsFormButton} ${styles.DeleteButton} ml-sm-2 mt-2 mt-sm-0`}
                                isDangerousOperation
                            />
                        ) : undefined}
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const NewsEditForm = Sentry.withProfiler(observer(NewsEditFormBase));
