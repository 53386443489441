import React, { useCallback, useState } from "react";
import { UI } from "@wwimmo/ui";
import { Tiles } from "src/components/tiles";
import {
    GetTilesByScreen_portal_tilescreens_tile as tile,
    GetTilesByScreen_portal_tilescreens_tile_tiledatasets as tiledatasets
} from "src/api/generated/GetTilesByScreen";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./Tile.css";
import { contrastColor, getCssRootValue } from "src/utils/Colors";
import { round } from "lodash";
import { formatCurrency } from "src/utils/Currency";
import { isNumeric } from "src/utils/Common";
import { PieHint } from "./charts/Pie";

import i18n from "src/utils/i18n";

export interface TileProps {
    title?: string;
    subtitle?: string;
    data: tile;
}

export enum ChartDataType {
    VALUE = 0,
    PERCENTAGE = 1,
    AMOUNT = 2,
    DAYS = 3
}

export enum ChartType {
    COMPARSION = 1,
    DONUT = 2,
    PIE = 3,
    LINE = 4,
    H_BAR = 5,
    V_BAR = 6,
    GENERICLIST = 7,
    STACKED_AREA = 8
}
const getHint = (array: any) => {
    let unformattedHintStr = array?.length > 0 ? array[0].hint : "";

    if (isNumeric(unformattedHintStr)) {
        return formatCurrency(parseFloat(unformattedHintStr));
    } else {
        return unformattedHintStr;
    }
};
const getLabel = (array: any) => {
    return array && array.length > 0 ? array[0].label : "";
};
const getTitle = (array: any) => {
    return array && array.length > 0 ? array[0].title : "";
};
const getSubtitle = (array: any) => {
    return array && array.length > 0 ? array[0].subtitle : "";
};
const getValueType = (array: any): ChartDataType => {
    return array && array.length > 0 ? (array[0].valuetype as ChartDataType) : ChartDataType.AMOUNT;
};
export const formatValue = (value: number, type: ChartDataType): string => {
    switch (type) {
        case ChartDataType.PERCENTAGE:
            return String(value) + "%";
        case ChartDataType.AMOUNT:
            return formatCurrency(value) + " " + i18n.t("others.units.chf");
        case ChartDataType.DAYS:
            return String(value) + "  " + i18n.t("others.units.days");
        default:
            return String(value);
    }
};
export const getLabelOptions = (
    type: ChartDataType
): { render: "percentage" | "value" | "label" | ((args: any) => string) } => {
    switch (type) {
        case ChartDataType.PERCENTAGE:
            return { render: "percentage" };
        case ChartDataType.DAYS:
            return {
                render: (args: any) => {
                    const value = args.value as number | 0;
                    return formatValue(value, ChartDataType.DAYS);
                }
            };
        case ChartDataType.AMOUNT:
            return {
                render: (args: any) => {
                    const value = args.value as number | 0;
                    return formatValue(value, ChartDataType.AMOUNT);
                }
            };
        default:
            return { render: "label" };
    }
};

export const Tile = (props: TileProps) => {
    Chart.plugins.register(ChartDataLabels);

    const [height, setHeight] = useState<number>(0);
    const [subtitle, setSubtitle] = useState<string>("");

    const newSubtitle = useCallback(
        (newSubtitle: string) => {
            setSubtitle(newSubtitle);
        },
        [setSubtitle]
    );

    const ref = useCallback((node) => {
        if (node !== null) {
            setHeight(node.getBoundingClientRect().height);
        }
    }, []);
    const colors = [
        getCssRootValue("primary"),
        getCssRootValue("primary100"),
        getCssRootValue("primary400"),
        getCssRootValue("primary900"),
        getCssRootValue("additionalColor"),
        getCssRootValue("additionalColor2")
    ];

    Chart.defaults.global.plugins = { labels: false };

    const comparsion = (data: tiledatasets) => {
        return {
            labels: [],
            datasets: data.tiledatavalues.map((d) => {
                return {
                    label: getLabel(d.tiledatavalues_mls),
                    data: roundNumberOrAny(d.value),
                    valuetype: d.valuetype || ChartDataType.AMOUNT
                };
            })
        };
    };

    const doughnut = (data: tiledatasets) => {
        let tiledatavalues = [...data.tiledatavalues];

        if (tiledatavalues.length === 1) {
            const rest = { ...data.tiledatavalues[0], value: 100 - data.tiledatavalues[0].value };
            tiledatavalues = [...data.tiledatavalues, rest];
        }
        return {
            labels: tiledatavalues.map((value) => getHint(value.tiledatavalues_mls)),
            datasets: [
                {
                    label: props.data.id,
                    data: tiledatavalues.map((value) => value.value),
                    backgroundColor: colors
                }
            ],
            centerText: formatValue(roundNumberOrAny(tiledatavalues[0].value), ChartDataType.PERCENTAGE)
        };
    };

    const pie = (data: tiledatasets) => {
        return {
            labels: data.tiledatavalues.map((value) => {
                const label = getLabel(value.tiledatavalues_mls);
                return label;
            }),
            datasets: [
                {
                    label: props.data.id,
                    data: data.tiledatavalues.map((value) => roundNumberOrAny(value.value)),
                    backgroundColor: colors,
                    textColor: colors.map((color) => contrastColor(color)),
                    hint: data.tiledatavalues.map((value) => {
                        const hintTitle = getLabel(value.tiledatavalues_mls);
                        const hint = getHint(value.tiledatavalues_mls);

                        const pieHint: PieHint = { title: hintTitle, label: hint };

                        return pieHint;
                    })
                }
            ],
            valuetype: getValueType(data.tiledatavalues)
        };
    };

    const line = (data: tiledatasets) => {
        return {
            labels: data.tiledatavalues.map((value) => {
                return value.tiledatavalues_mls.length !== 0 ? value.tiledatavalues_mls[0].label || "-" : "-";
            }),
            datasets: [
                {
                    label: props.data.id,
                    data: data.tiledatavalues.map((value) => roundNumberOrAny(value.value))
                }
            ],
            valuetype: getValueType(data.tiledatavalues)
        };
    };

    const stackedarea = (data: tiledatasets) => {
        let legendLabels: string[] = [];
        let hint: string[] = [];

        if (data.tiledatasets_mls && data.tiledatasets_mls.length > 0) {
            if (data.tiledatasets_mls[0].label) {
                legendLabels.push(...data.tiledatasets_mls[0].label.split(";"));
            }
        }

        const groupMapValues: Map<number, Array<number>> = new Map();
        const groupMapLabels: Map<number, Array<string>> = new Map();

        if (data.tiledatavalues.length > 0) {
            data.tiledatavalues.forEach((tiledatavalue) => {
                if (tiledatavalue.group !== null) {
                    if (groupMapValues.has(tiledatavalue.group)) {
                        groupMapValues.get(tiledatavalue.group)?.push(tiledatavalue.value);
                        if (tiledatavalue.tiledatavalues_mls.length > 0 && tiledatavalue.tiledatavalues_mls[0].label) {
                            groupMapLabels.get(tiledatavalue.group)?.push(tiledatavalue.tiledatavalues_mls[0].label);
                        }
                    } else {
                        groupMapValues.set(tiledatavalue.group, [tiledatavalue.value]);
                        if (tiledatavalue.tiledatavalues_mls.length > 0 && tiledatavalue.tiledatavalues_mls[0].label) {
                            groupMapLabels.set(tiledatavalue.group, [tiledatavalue.tiledatavalues_mls[0].label]);
                        }
                    }
                }
                if (
                    tiledatavalue.tiledatavalues_mls[0].hint !== null &&
                    !hint.find((h) => h === tiledatavalue.tiledatavalues_mls[0].hint)
                ) {
                    hint.push(tiledatavalue.tiledatavalues_mls[0].hint + "");
                }
            });
        }

        const unsortedGroupArrayValues = Array.from(groupMapValues);
        const sortedGroupArrayValues = unsortedGroupArrayValues.sort(([groupNumber1], [groupNumber2]) => {
            if (groupNumber1 < groupNumber2) {
                return -1;
            } else if (groupNumber1 > groupNumber2) {
                return 1;
            } else {
                return 0;
            }
        });

        const datasets = sortedGroupArrayValues.map((sortedGroup, index) => {
            return {
                label: legendLabels[index],
                hint: hint[index],
                data: sortedGroup[1],
                pointRadius: 0,
                fill: true,
                backgroundColor: colors[index],
                borderWidth: 0
            };
        });

        const unsortedGroupArrayLabels = Array.from(groupMapLabels);
        const sortedGroupArrayLabels = unsortedGroupArrayLabels.sort(([groupNumber1], [groupNumber2]) => {
            if (groupNumber1 < groupNumber2) {
                return -1;
            } else if (groupNumber1 > groupNumber2) {
                return 1;
            } else {
                return 0;
            }
        });

        const xAxisLabels = sortedGroupArrayLabels.length > 1 ? sortedGroupArrayLabels[0][1] : [""];

        return {
            labels: xAxisLabels,
            datasets: datasets,
            valuetype: getValueType(data.tiledatavalues)
        };
    };

    const bar = (ds: tiledatasets) => {
        return {
            labels: ds.tiledatavalues.map((value) => getLabel(value.tiledatavalues_mls)),
            datasets: [
                {
                    label: ds.id,
                    data: ds.tiledatavalues.map((value) => roundNumberOrAny(value.value))
                }
            ],
            valuetype: getValueType(ds.tiledatavalues)
        };
    };

    const genericList = (tiledataset: tiledatasets) => {
        let label = "";
        let subtitle = "";

        if (tiledataset.tiledatasets_mls.length > 0 && tiledataset.tiledatasets_mls[0].label) {
            label = tiledataset.tiledatasets_mls[0].label;
        }

        if (tiledataset.tiledatasets_mls.length > 0 && tiledataset.tiledatasets_mls[0].subtitle) {
            subtitle = tiledataset.tiledatasets_mls[0].subtitle;
        }

        let headerRows: Array<string[] | undefined> = [];
        let listRows: Array<string[] | undefined> = [];
        let listColumns: string[] | undefined = [];

        if (tiledataset.tiledatavalues.length > 1) {
            tiledataset.tiledatavalues.forEach((tiledatavalue, index) => {
                if (tiledatavalue.tiledatavalues_mls.length > 0) {
                    if (index === 0) {
                        headerRows?.push(tiledatavalue.tiledatavalues_mls[0].label?.split(";"));
                        listColumns = tiledatavalue.tiledatavalues_mls[0].hint?.split(";");
                    } else {
                        listRows?.push(tiledatavalue.tiledatavalues_mls[0].label?.split(";"));
                    }
                }
            });
        }

        let numberOfColumns = 0;

        if (listRows.length > 0 && listRows[0]) {
            numberOfColumns = listRows[0].length;
        }

        const genericListDataObject = {
            label,
            subtitle,
            headerRows,
            listRows,
            listColumns,
            numberOfColumns
        };

        return genericListDataObject;
    };

    const chart = (type: number, tiledataset: tiledatasets) => {
        switch (type) {
            case ChartType.COMPARSION:
                return <Tiles.Comparsion data={comparsion(tiledataset)} />;
            case ChartType.DONUT:
                return <Tiles.Doughnut data={doughnut(tiledataset)} />;
            case ChartType.PIE:
                return <Tiles.Pie data={pie(tiledataset)} />;
            case ChartType.LINE:
                return <Tiles.Line data={line(tiledataset)} colors={colors} />;
            case ChartType.H_BAR:
                return <Tiles.HorizontalBar data={bar(tiledataset)} colors={colors} />;
            case ChartType.V_BAR:
                return <Tiles.VerticalBar data={bar(tiledataset)} colors={colors} />;
            case ChartType.GENERICLIST:
                return <Tiles.GenericList height={height} data={genericList(tiledataset)} />;
            case ChartType.STACKED_AREA:
                return <Tiles.StackedArea data={stackedarea(tiledataset)} colors={colors} />;
            default:
                return <span>default</span>;
        }
    };

    const tabs = (type: number, tiledatasets: tiledatasets[]) => {
        if (tiledatasets.length <= 1) {
            return chart(type, tiledatasets[0]);
        }

        const contents = tiledatasets.map((tiledataset) => {
            const chartElement = chart(type, tiledataset);
            return {
                label: getLabel(tiledataset.tiledatasets_mls),
                subtitle: getSubtitle(tiledataset.tiledatasets_mls),
                content: chartElement
            };
        });
        return <UI.Tabs contents={contents} updateSubtitle={newSubtitle} />;
    };

    const roundNumberOrAny = (input: any | null, decimal: number = 2): number => {
        return !isNaN(input) ? round(input, decimal) : input;
    };

    let st = subtitle;
    if (st === "" && props.data.tiledatasets.length === 1) {
        st = getSubtitle(props.data.tiledatasets[0].tiledatasets_mls);
    }

    return (
        <div ref={ref}>
            <UI.Card title={props.title || getTitle(props.data.tiles_mls)} square subtitle={st}>
                {tabs(props.data.charttype || 0, props.data.tiledatasets)}
            </UI.Card>
        </div>
    );
};
