import React, { useContext, useRef, useCallback } from "react";
import { UI, ScreenSize } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { ScreenWidth } from "src/stores/UIStore";
import styles from "./RealestateInfoTile.module.css";

interface DashboardTileProps {
    realestateListItems: InfoTileInformation[];
    name: string;
    infoItemCount: number;
    calculateHeight?: boolean;
}

export interface InfoTileInformation {
    to?: string;
    label?: string;
    value?: any;
    className?: string;
    spansTwoColumns?: boolean;
    onClick?: any;
}

export const RealestateInfoTile = (props: DashboardTileProps) => {
    const { uiStore } = useContext(RootStoreContext);

    const realestateInfoContainer = useRef<HTMLDivElement>(null);

    const renderItem = useCallback((item: InfoTileInformation, screenSize: ScreenSize) => {
        if (item.value || item.to) {
            let listRows: React.ReactElement[] = [];

            if (item.spansTwoColumns) {
                listRows = [
                    <UI.List.Row key={"r-1"}>
                        <UI.List.Cell key={"c-1"} colspan={2} value={item.label} />
                    </UI.List.Row>
                ];
            } else {
                listRows = [
                    <UI.List.Row key={"r-1"}>
                        <UI.List.Cell key={"c-1"} colspan={1.2} value={item.label} />
                        <UI.List.Cell key={"c-2"} colspan={2} value={item.value} className={styles.InfoValue} />
                    </UI.List.Row>
                ];
            }

            return (
                <UI.List.Item
                    key={item.label}
                    screenSize={screenSize}
                    rows={listRows}
                    className={item.className ?? ""}
                    to={item.to}
                    onClick={item.onClick}
                />
            );
        }
    }, []);

    const realestateDetailList = (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={props.realestateListItems}
            renderItem={renderItem}
        />
    );

    const tileContainerWidth = realestateInfoContainer.current?.offsetWidth ?? 0;

    const tileContainerHeightMultiplier = Math.ceil(props.infoItemCount / 4);
    const tileContainerHeight = tileContainerWidth * tileContainerHeightMultiplier;

    const tileContainerStyle =
        !uiStore.isMobile && window.innerWidth >= ScreenWidth.LG
            ? {
                  backgroundColor: "white",
                  height: tileContainerHeight
              }
            : undefined;

    const paddingElements =
        window.innerWidth >= ScreenWidth.LG
            ? Array.from(Array(tileContainerHeightMultiplier - 1).keys()).map((_, i) => (
                  <div key={`padding-${i}`} style={{ backgroundColor: "white" }} className={"pb-4"} />
              ))
            : undefined;

    return (
        <>
            <div ref={realestateInfoContainer} style={tileContainerStyle}>
                <UI.Card
                    title={props.name}
                    square={!props.calculateHeight ? false : tileContainerHeightMultiplier > 1 ? false : true}
                >
                    {realestateDetailList}
                </UI.Card>
            </div>
            {uiStore.isMobile ? undefined : paddingElements}
        </>
    );
};
