import React, { useContext, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { ColorStyle } from "src/utils/Colors";
import { RootStoreContext } from "src/stores/RootStore";
import { AssignInvoiceModal } from "src/screens/creditors/creditor/details/actions/assignInvoice/modal/AssignInvoiceModal";
import { WORKFLOWUSER_ROLE } from "src/stores/krediflow/InvoiceEnums";
import { ASSIGN_INVOICE_STATE } from "src/stores/krediflow/AssignInvoiceStore";
import { useTranslation } from "react-i18next";
import styles from "./AssignInvoiceToWorkflowUser.module.css";

interface AssignInvoiceToWorkflowUserProps {
    workflowUserRole: WORKFLOWUSER_ROLE;
}

const AssignInvoiceToWorkflowUserBase = (props: AssignInvoiceToWorkflowUserProps) => {
    const { invoiceStore, assignInvoiceStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const { workflowUserRole } = props;

    const displayAssignInvoiceModal = useCallback(() => {
        assignInvoiceStore.setIsModalDisplayed(true, workflowUserRole);
    }, [assignInvoiceStore, workflowUserRole]);

    const assignedWorkflowUser =
        invoiceStore.currentInvoice &&
        invoiceStore.currentInvoice.workflowUsers &&
        invoiceStore.currentInvoice.workflowUsers.find((user) => user.role === workflowUserRole);

    const assignedUserName =
        assignedWorkflowUser && assignedWorkflowUser.user
            ? `${assignedWorkflowUser.user.name1 ?? ""} ${
                  assignedWorkflowUser.user.name2 &&
                  assignedWorkflowUser.user.name2.trim() !== assignedWorkflowUser.user.name1.trim()
                      ? assignedWorkflowUser.user.name2
                      : ""
              }`.trim()
            : "-";

    const title =
        workflowUserRole === WORKFLOWUSER_ROLE.VISA_1
            ? `${t("screens.kredi_flow.visa")} 1`
            : workflowUserRole === WORKFLOWUSER_ROLE.VISA_2
            ? `${t("screens.kredi_flow.visa")} 2`
            : workflowUserRole === WORKFLOWUSER_ROLE.VISA_3
            ? `${t("screens.kredi_flow.visa")} 3`
            : t("screens.kredi_flow.accountant");

    return (
        <div className="mb-2">
            <div>{title}</div>
            <div className="d-flex align-items-center">
                {assignedWorkflowUser ? (
                    <>
                        <UI.Icon icon={UI.SVGIcon.Profile} color={ColorStyle("primary")} className="mr-1" />
                        <div>{assignedUserName}</div>
                        <UI.Icon
                            icon={UI.SVGIcon.Edit}
                            color={ColorStyle("primary")}
                            size={25}
                            className="ml-auto"
                            onClick={displayAssignInvoiceModal}
                        />
                    </>
                ) : (
                    <UI.Button
                        label={t("screens.tickets.action.assign_ticket.assign")}
                        onClick={displayAssignInvoiceModal}
                        disabled={
                            invoiceStore.isEditing ||
                            assignInvoiceStore.assignInvoiceState === ASSIGN_INVOICE_STATE.ASSIGNING
                        }
                        className={`mt-2 ${styles.AssignButton}`}
                    />
                )}
                <AssignInvoiceModal workflowUserRole={workflowUserRole} />
            </div>
        </div>
    );
};

export const AssignInvoiceToWorkflowUser = Sentry.withProfiler(observer(AssignInvoiceToWorkflowUserBase));
