import { UI } from "@wwimmo/ui";

export const formatCurrency = (input: number): string => {
    return input ? String(input.toFixed(2)).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'") : "0.00";
};

export const getCurrencyElement = (value: number | undefined | null) => {
    if (value !== null && value !== undefined) {
        return <UI.Currency value={value} />;
    } else {
        return undefined;
    }
};
