import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { COMMON_REQUEST_NOTIFICATION_PROPERTY } from "src/stores/TicketStore";
import styles from "./CommonRequestNotificationFormFields.module.css";
import "./GeneralFormStyles.css";

const CommonRequestNotificationFormFieldsBase = () => {
    const { t } = useTranslation();
    const { ticketStore } = useContext(RootStoreContext);

    const onChangeDamageNotificationField = useCallback(
        (commonRequestNotificationProperty: COMMON_REQUEST_NOTIFICATION_PROPERTY) =>
            (e: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = e.target.value;

                if (ticketStore.currentTicket) {
                    ticketStore.currentTicket.updateCommonRequestNotificationProperty(
                        commonRequestNotificationProperty,
                        newValue
                    );
                }
            },
        [ticketStore.currentTicket]
    );

    const onBlurDescriptionField = useCallback(
        (e: any) => {
            ticketStore.currentTicket?.validateCommonRequestNotificationDetails();
        },
        [ticketStore.currentTicket]
    );

    return (
        <div className={`${styles.FormContainer} ${!ticketStore.isEditing ? "view-only-container" : ""}`}>
            <div className="p-2">
                {/* DESCRITION ROW */}
                <UI.Row>
                    <UI.Col>
                        {ticketStore.isEditing ? (
                            <UI.Input
                                label={`${t("screens.tickets.form.notification.description").toString()} *`}
                                type="text"
                                as="textarea"
                                autoComplete="off"
                                value={
                                    ticketStore.currentTicket &&
                                    ticketStore.currentTicket.commonRequestNotificationDetails
                                        ? ticketStore.currentTicket.commonRequestNotificationDetails?.description ?? ""
                                        : ""
                                }
                                onChange={onChangeDamageNotificationField(
                                    COMMON_REQUEST_NOTIFICATION_PROPERTY.DESCRIPTION
                                )}
                                onBlur={onBlurDescriptionField}
                                errorMsg={ticketStore.currentTicket?.errors.commonRequestDescription ?? undefined}
                                disabled={!ticketStore.isEditing}
                                className={styles.DescriptionTextArea}
                            />
                        ) : (
                            <>
                                <div className="title">{t("screens.tickets.form.notification.description")}</div>
                                <div className="mb-2 mt-3 pl-0">
                                    {ticketStore.currentTicket &&
                                    ticketStore.currentTicket.commonRequestNotificationDetails
                                        ? ticketStore.currentTicket.commonRequestNotificationDetails?.description ?? ""
                                        : ""}
                                </div>
                            </>
                        )}
                    </UI.Col>
                </UI.Row>
            </div>
        </div>
    );
};

export const CommonRequestNotificationFormFields = Sentry.withProfiler(
    observer(CommonRequestNotificationFormFieldsBase)
);
