import * as Sentry from "@sentry/react";
import i18next from "i18next";
import { Customer } from "src/types";

const resetPlaceholder = (id: string, placeholder: string) => {
    const input = document.getElementById(id);
    input && input.setAttribute("placeholder", placeholder);
};

export const submitUserFeedback = (customer: Customer) => {
    Sentry.captureMessage("Feedback");
    Sentry.showReportDialog({
        user: {
            email: customer.email,
            name: customer.name
        },
        title: i18next.t("error.feedback.title"),
        subtitle: i18next.t("error.feedback.subtitle"),
        subtitle2: "",
        lang: i18next.language,
        labelComments: i18next.t("error.feedback.comment"),
        labelClose: i18next.t("labels.close"),
        labelSubmit: i18next.t("labels.submit"),
        successMessage: i18next.t("error.feedback.success_message"),
        onLoad: () => {
            resetPlaceholder("id_comments", "");
            resetPlaceholder("id_name", "Martin Muster");
            resetPlaceholder("id_email", "martin.muster@beispiel.com");
        }
    });
};
