import { observable, action, makeObservable, computed } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { IOrder, ITicket } from "./InvoiceTypes";
import {
    GET_ORDER_DATA,
    GET_ORDER_DATA_BY_CREDITOR_ID,
    GET_ORDER_DATA_BY_INCIDENT_ID,
    GET_ORDER_DATA_BY_REALESTATEID,
    GET_ORDERS_WITH_QUERY
} from "src/api/cred";
import {
    GetOrdersWithQuery,
    GetOrdersWithQuery_orders,
    GetOrdersWithQueryVariables
} from "src/api/generated/GetOrdersWithQuery";
import {
    GetOrderDataByRealestateId,
    GetOrderDataByRealestateIdVariables
} from "src/api/generated/GetOrderDataByRealestateId";
import {
    GetOrderDataByIncidentId,
    GetOrderDataByIncidentIdVariables
} from "src/api/generated/GetOrderDataByIncidentId";
import {
    GetOrderDataByCreditorId,
    GetOrderDataByCreditorIdVariables
} from "src/api/generated/GetOrderDataByCreditorId";
import { GetOrderData } from "src/api/generated/GetOrderData";
import { CONTRACTOR_TYPE } from "src/stores/OrderStore";
import i18next from "i18next";

export enum ORDER_SEARCH_FILTER_TYPE {
    REALESTATE,
    CREDITOR,
    TICKET
}

export interface IOrderSearchFilter {
    byRealestate: boolean;
    byCreditor: boolean;
    byTicket: boolean;
}

export class OrderSearchStore {
    rootStore: RootStore;

    orderData: IOrder[] = [];
    currentOrderSearchQuery: string = "";
    selectedOrderQueryString: string | undefined = undefined;
    isSearchingOrderData: boolean = false;
    orderSearchFilter: IOrderSearchFilter = {
        byRealestate: false,
        byCreditor: false,
        byTicket: false
    };
    triggerFocusOnOrderInput: boolean = false;
    isSearchingOrdersInBackendByQuery: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            orderData: observable,
            triggerFocusOnOrderInput: observable,
            currentOrderSearchQuery: observable,
            selectedOrderQueryString: observable,
            orderSearchFilter: observable,
            isSearchingOrderData: observable,
            isSearchingOrdersInBackendByQuery: observable,

            init: action,
            loadOrderData: action,
            searchOrdersInBackendByQuery: action,
            searchOrdersByRealestate: action,
            searchOrdersByTicket: action,
            searchOrdersByCreditor: action,
            getMappedAndSortedOrderData: action,
            setCurrentOrderSearchQuery: action,
            setSelectedOrderQueryString: action,
            setTriggerFocusOnOrderInput: action,
            setOrderData: action,
            setOrderSearchFilter: action,
            setOrderSearchFilterRealestate: action,
            setOrderSearchFilterTicket: action,
            setOrderSearchFilterCreditor: action,
            setIsSearchingOrderData: action,
            setIsSearchingTicketsInBackendByQuery: action,
            toggleOrderSearchFilterRealestate: action,
            toggleOrderSearchFilterCreditor: action,
            toggleOrderSearchFilterTicket: action,
            resetOrderSearchFilter: action,

            filteredOrderData: computed
        });
    }

    init = async () => {
        await this.loadOrderData();
    };

    searchOrdersInBackendByQuery = async (searchQuery: string) => {
        this.setIsSearchingOrderData(true);

        const { data }: { data: GetOrdersWithQuery } = await apolloClientInstance.query<
            GetOrdersWithQuery,
            GetOrdersWithQueryVariables
        >({
            query: GET_ORDERS_WITH_QUERY,
            variables: {
                searchQuery: `%${searchQuery}%`
            }
        });

        const orderData: IOrder[] = this.getMappedAndSortedOrderData(data.orders);

        this.setOrderData(orderData);

        this.setIsSearchingOrderData(false);
    };

    searchOrdersByRealestate = async () => {
        this.setIsSearchingOrderData(true);

        const { data }: { data: GetOrderDataByRealestateId } = await apolloClientInstance.query<
            GetOrderDataByRealestateId,
            GetOrderDataByRealestateIdVariables
        >({
            query: GET_ORDER_DATA_BY_REALESTATEID,
            variables: {
                realestateid: this.rootStore.invoiceStore.currentInvoice?.realestate?.id
            }
        });

        const orderData: ITicket[] = this.getMappedAndSortedOrderData(data.orders);

        this.setOrderData(orderData);

        this.setIsSearchingOrderData(false);
    };

    searchOrdersByTicket = async () => {
        this.setIsSearchingOrderData(true);

        const { data }: { data: GetOrderDataByIncidentId } = await apolloClientInstance.query<
            GetOrderDataByIncidentId,
            GetOrderDataByIncidentIdVariables
        >({
            query: GET_ORDER_DATA_BY_INCIDENT_ID,
            variables: {
                incidentid: this.rootStore.invoiceStore.currentInvoice?.ticket?.id
            }
        });

        const ticketData: ITicket[] = this.getMappedAndSortedOrderData(data.orders);

        this.setOrderData(ticketData);

        this.setIsSearchingOrderData(false);
    };

    searchOrdersByCreditor = async () => {
        this.setIsSearchingOrderData(true);

        const { data }: { data: GetOrderDataByCreditorId } = await apolloClientInstance.query<
            GetOrderDataByCreditorId,
            GetOrderDataByCreditorIdVariables
        >({
            query: GET_ORDER_DATA_BY_CREDITOR_ID,
            variables: {
                creditorid: this.rootStore.invoiceStore.currentInvoice?.creditor?.id
            }
        });

        const ticketData: ITicket[] = this.getMappedAndSortedOrderData(data.orders);

        this.setOrderData(ticketData);

        this.setIsSearchingOrderData(false);
    };

    loadOrderData = async () => {
        this.setIsSearchingOrderData(true);

        const { data }: { data: GetOrderData } = await apolloClientInstance.query<GetOrderData>({
            query: GET_ORDER_DATA
        });

        const orderData: IOrder[] = this.getMappedAndSortedOrderData(data.orders);

        if (orderData.length > 1000) {
            this.setIsSearchingTicketsInBackendByQuery(true);
        } else {
            this.setIsSearchingTicketsInBackendByQuery(false);
        }

        this.setOrderData(orderData);

        this.setIsSearchingOrderData(false);
    };

    getMappedAndSortedOrderData = (orders: GetOrdersWithQuery_orders[]): IOrder[] => {
        const orderData: IOrder[] = orders.map((order) => {
            return {
                id: order.orderid ?? undefined,
                realestateid: order.realestateid ?? undefined,
                date: order.date ?? undefined,
                date_long: order.date_long ?? undefined,
                number: order.number ?? undefined,
                title: order.title ?? undefined,
                instruction: order.instruction ?? undefined,
                contractorId: order.contractorid ?? undefined,
                contractorName1: order.name1 ?? undefined,
                contractorName2: order.name2 ?? undefined,
                contractorType: order.contractortype ?? undefined,
                ticket: order.ticket
                    ? this.rootStore.invoiceStore.getTicketInfoObjectFromGetInvoiceByNumberQueryCreditorResult(
                          order.ticket
                      )
                    : undefined
            };
        });

        // Sorting tickets by number
        orderData.sort((orderA, orderB) => {
            if (orderA.number === null && orderB.number !== null) {
                return 1;
            }

            if (orderA.number !== null && orderB.number === null) {
                return -1;
            }

            const orderANumber = orderA.number !== null ? Number(orderA.number) : null;
            const orderBNumber = orderB.number !== null ? Number(orderB.number) : null;

            if (orderANumber === null && orderBNumber !== null) {
                return 1;
            }

            if (orderANumber !== null && orderBNumber === null) {
                return -1;
            }

            if (orderANumber! < orderBNumber!) {
                return 1;
            }

            if (orderANumber! > orderBNumber!) {
                return -1;
            }

            return 0;
        });

        return orderData;
    };

    getFormattedOrderFieldValue = (order: IOrder): string => {
        let contractorName = `${order.contractorName1} ${order.contractorName2 ?? ""}`.trim();

        if (order.contractorType === CONTRACTOR_TYPE.SERVICE_7000 || order.contractorType === CONTRACTOR_TYPE.YAROWA) {
            contractorName = i18next.t(`screens.orders.contractor.type.${order.contractorType}`).toString();
        }

        return `${order.date}, ${contractorName} [${order.number}]`;
    };

    // Setters
    setIsSearchingOrderData = (isSearchingOrderData: boolean) => {
        this.isSearchingOrderData = isSearchingOrderData;
    };

    setCurrentOrderSearchQuery = (currentOrderSearchQuery: string) => {
        this.currentOrderSearchQuery = currentOrderSearchQuery;
    };

    setSelectedOrderQueryString = (selectedOrderQueryString: string | undefined) => {
        this.selectedOrderQueryString = selectedOrderQueryString;
    };

    setTriggerFocusOnOrderInput = (triggerFocusOnOrderInput: boolean) => {
        this.triggerFocusOnOrderInput = triggerFocusOnOrderInput;
    };

    setOrderData = (orderData: IOrder[]) => {
        this.orderData = orderData;
    };

    setOrderSearchFilter = (orderSearchFilter: IOrderSearchFilter) => {
        this.orderSearchFilter = orderSearchFilter;
    };

    setOrderSearchFilterRealestate = (filterByRealestate: boolean) => {
        this.orderSearchFilter.byRealestate = filterByRealestate;
    };

    setOrderSearchFilterCreditor = (filterByCreditor: boolean) => {
        this.orderSearchFilter.byCreditor = filterByCreditor;
    };

    setOrderSearchFilterTicket = (filterByTicket: boolean) => {
        this.orderSearchFilter.byTicket = filterByTicket;
    };

    resetOrderSearchFilter = () => {
        this.orderSearchFilter = {
            byRealestate: false,
            byCreditor: false,
            byTicket: false
        };
    };

    toggleOrderSearchFilterRealestate = () => {
        this.orderSearchFilter.byRealestate = !this.orderSearchFilter.byRealestate;
    };

    toggleOrderSearchFilterCreditor = () => {
        this.orderSearchFilter.byCreditor = !this.orderSearchFilter.byCreditor;
    };

    toggleOrderSearchFilterTicket = () => {
        this.orderSearchFilter.byTicket = !this.orderSearchFilter.byTicket;
    };

    setIsSearchingTicketsInBackendByQuery = (isSearchingTicketsInBackendByQuery: boolean) => {
        this.isSearchingOrdersInBackendByQuery = isSearchingTicketsInBackendByQuery;
    };

    get filteredOrderData(): IOrder[] {
        let filteredOrderData: IOrder[] = [...this.orderData];

        if (this.orderSearchFilter.byRealestate) {
            const filteredTicketDataByRealestate = this.orderData.filter((order) => {
                return order.realestateid === this.rootStore.invoiceStore.currentInvoice?.realestate?.id;
            });

            filteredOrderData = [...filteredTicketDataByRealestate];
        }

        if (this.orderSearchFilter.byTicket) {
            const filteredTicketDataByTicket = this.orderData.filter((order) => {
                return order.ticket?.id === this.rootStore.invoiceStore.currentInvoice?.ticket?.id;
            });

            filteredOrderData = [...filteredTicketDataByTicket];
        }

        if (this.orderSearchFilter.byCreditor) {
            const filteredTicketDataByTicket = this.orderData.filter((order) => {
                return order.contractorId === this.rootStore.invoiceStore.currentInvoice?.creditor?.personId;
            });

            filteredOrderData = [...filteredTicketDataByTicket];
        }

        if (!this.isSearchingOrdersInBackendByQuery) {
            const searchQuery = this.currentOrderSearchQuery ?? "";

            if (this.currentOrderSearchQuery === this.selectedOrderQueryString) {
                return filteredOrderData;
            }

            if (searchQuery === "") {
                return filteredOrderData;
            }

            const filteredOrderDataBySearchQuery = filteredOrderData.filter((order) => {
                const contractorName = `${order.contractorName1} ${order.contractorName2 ?? ""}`.trim();

                return (
                    order.number?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    order.date_long?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    contractorName?.toLowerCase().includes(searchQuery.toLowerCase())
                );
            });

            return filteredOrderDataBySearchQuery;
        } else {
            return filteredOrderData;
        }
    }
}
