import { gql } from "@apollo/client";

const APPLIANCES_FRAGMENTS = gql`
    fragment appliance_details on common_appliances {
        id
        manufacturer
        model
        name
        servicecompany: person {
            name1
            name2
        }
    }
`;

export const GET_REALESTATE_APPLIANCES = gql`
    ${APPLIANCES_FRAGMENTS}
    query GetRealestateAppliances($realestateid: uuid!, $isunitidnull: Boolean) {
        appliances: common_appliances(
            where: {
                realestateid: { _eq: $realestateid }
                unitid: { _is_null: $isunitidnull }
                _or: [{ outofservicedate: { _gt: "now()" } }, { outofservicedate: { _is_null: true } }]
            }
        ) {
            ...appliance_details
        }
    }
`;

export const GET_UNIT_APPLIANCES = gql`
    ${APPLIANCES_FRAGMENTS}
    query GetUnitAppliances($realestateid: uuid!, $unitid: uuid, $isunitidnull: Boolean) {
        appliances: common_appliances(
            where: {
                realestateid: { _eq: $realestateid }
                unitid: { _eq: $unitid, _is_null: $isunitidnull }
                _or: [{ outofservicedate: { _gt: "now()" } }, { outofservicedate: { _is_null: true } }]
            }
        ) {
            ...appliance_details
        }
    }
`;
