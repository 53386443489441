import { gql } from "@apollo/client";

export const GET_CONTACT_INFORMATION_BY_REALESTATEID = gql`
    query GetContactInformationByRealestateId($realestateid: uuid!) {
        common_realestatecontacts(where: { realestateid: { _eq: $realestateid } }) {
            facilityemail
            facilitymobile
            facilityname
            facilityphone
            manageremail
            managermobile
            managername
            managerphone
            accountantemail
            accountantmobile
            accountantname
            accountantphone
        }

        house: common_houses(
            where: { realestateid: { _eq: $realestateid } }
            order_by: { housenumber: asc }
            limit: 1
        ) {
            id
        }

        coownertentant: portal_v_tenants_by_coowner_userid(where: { realestateid: { _eq: $realestateid } }, limit: 1) {
            tenantid
        }

        tentant: portal_v_tenants_by_tenant_userid(where: { realestateid: { _eq: $realestateid } }, limit: 1) {
            tenantid
        }
    }
`;
