import React, { useContext, useState, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { GetServiceDetailsByServiceType_uccm_v_customerservices_tiles as customerservice } from "src/api/generated/GetServiceDetailsByServiceType";
import "./ServicesInfoModal.css";
import { ColorStyle } from "src/utils/Colors";
import contacts from "src/components/contactsales/ContactSales.json";
import { ContactItemSales, ContactItemRow } from "src/components/contactsales/contactitemsales/ContactItemSales";
import { CustomerType } from "src/components/customer/Customer";

export enum ContactItemType {
    PHONE,
    MAIL
}

interface ServicesInfoModalProperties {
    serviceType: number;
    customerService?: customerservice;
}

const ServicesInfoModalBase = (props: ServicesInfoModalProperties) => {
    const { customerService } = props;
    const { navStore, servicesActivationProgressModalStore, servicesInfoModalStore } = useContext(RootStoreContext);
    const { setIsActivationProgressModalDisplayed } = servicesActivationProgressModalStore;
    const { isServiceInfoModalDisplayed, setIsServiceInfoModalDisplayed } = servicesInfoModalStore;
    const { t } = useTranslation();

    const [isActivePriceTab, setIsActivePriceTab] = useState<boolean>(true);
    const [isActiveContactTab, setIsActiveContactTab] = useState<boolean>(true);

    const onCloseModal = useCallback(() => {
        setIsServiceInfoModalDisplayed(false);
    }, [setIsServiceInfoModalDisplayed]);

    const onClickOpenServiceActivationModal = useCallback(() => {
        setIsServiceInfoModalDisplayed(false);
        setIsActivationProgressModalDisplayed(true);
    }, [setIsActivationProgressModalDisplayed, setIsServiceInfoModalDisplayed]);

    const content = customerService ? customerService.content : "";
    const contactSales = contacts.contactSales;

    const contactWW: Array<ContactItemRow> = [
        { type: ContactItemType.PHONE, content: contactSales[0]?.tel || null },
        { type: ContactItemType.MAIL, content: contactSales[0]?.email || null }
    ];
    const contactExtenso: Array<{ type: ContactItemType; content: string | null }> = [
        { type: ContactItemType.PHONE, content: contactSales[1]?.tel || null },
        { type: ContactItemType.MAIL, content: contactSales[1]?.email || null }
    ];

    const ContactSales = () => {
        return (
            <>
                {navStore.customer.partner === CustomerType.WW ? (
                    <>
                        <div className="ContactSalesName">{contactSales[0].company}</div>
                        <div className="ContactSalesTitle">{contactSales[0].department}</div>
                        <ContactItemSales name={contactSales[0].company} contacts={contactWW} />
                    </>
                ) : navStore.customer.partner === CustomerType.EXTENSO ? (
                    <>
                        <div className="ContactSalesName">{contactSales[1].company}</div>
                        <div className="ContactSalesTitle">{contactSales[1].department}</div>
                        <ContactItemSales name={contactSales[0].company} contacts={contactExtenso} />
                    </>
                ) : undefined}
            </>
        );
    };

    const onClickPriceTab = useCallback(() => {
        setIsActivePriceTab(!isActivePriceTab);
    }, [isActivePriceTab]);

    const onClickContactTab = useCallback(() => {
        setIsActiveContactTab(!isActiveContactTab);
    }, [isActiveContactTab]);

    const PriceAndContacts = () => {
        return (
            <UI.Tab.Container defaultActiveKey="" transition={false}>
                <UI.Row>
                    <UI.Col>
                        <UI.Nav variant="pills" className="flex-column TabNavigationNav">
                            {customerService?.chargeable ? (
                                <>
                                    <UI.Nav.Item className="TabNavigationNavItem">
                                        <UI.Nav.Link
                                            eventKey="price"
                                            className="TabNavigationNavLink"
                                            active={isActivePriceTab ? false : true}
                                            onClick={onClickPriceTab}
                                        >
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="TabNavigationNavLinkText">
                                                    {t("screens.services.features_and_integrations.features.price")}
                                                </div>
                                                <UI.Icon
                                                    icon={
                                                        isActivePriceTab
                                                            ? UI.SVGIcon.ChevronUpThin
                                                            : UI.SVGIcon.ChevronDownThin
                                                    }
                                                    color={ColorStyle("primary")}
                                                    size="medium"
                                                    className="NavLinkIcon"
                                                />
                                            </div>
                                        </UI.Nav.Link>
                                    </UI.Nav.Item>
                                    <UI.Tab.Content>
                                        <UI.Tab.Pane eventKey={isActivePriceTab ? "price" : ""} className="TabPane">
                                            <div className="TabPanePrice">
                                                <div>{customerService.price}</div>
                                                {customerService.pricelink === "" ? (
                                                    <></>
                                                ) : (
                                                    <iframe
                                                        title="PriceIFrame"
                                                        src={`${customerService.pricelink}`}
                                                        height={180}
                                                        style={{ border: "0" }}
                                                    ></iframe>
                                                )}
                                            </div>
                                        </UI.Tab.Pane>
                                    </UI.Tab.Content>
                                </>
                            ) : (
                                <></>
                            )}
                            <UI.Nav.Item className="TabNavigationNavItem">
                                <UI.Nav.Link
                                    eventKey="contact"
                                    className="TabNavigationNavLink"
                                    active={isActiveContactTab ? false : true}
                                    onClick={onClickContactTab}
                                >
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="TabNavigationNavLinkText">
                                            {t("screens.services.features_and_integrations.features.contact")}
                                        </div>
                                        <UI.Icon
                                            icon={
                                                isActiveContactTab
                                                    ? UI.SVGIcon.ChevronUpThin
                                                    : UI.SVGIcon.ChevronDownThin
                                            }
                                            color={ColorStyle("primary")}
                                            size="medium"
                                            className="NavLinkIcon"
                                        />
                                    </div>
                                </UI.Nav.Link>
                                <UI.Tab.Content>
                                    <UI.Tab.Pane eventKey={isActiveContactTab ? "contact" : ""} className="TabPane">
                                        <ContactSales />
                                    </UI.Tab.Pane>
                                </UI.Tab.Content>
                            </UI.Nav.Item>
                        </UI.Nav>
                        {customerService?.enableable && !customerService?.activated ? (
                            <div className="ServiceActivatedButtonContainer">
                                <UI.Button
                                    label={t("screens.services.features_and_integrations.go_to_activation")}
                                    className="ServiceActivatedButton"
                                    onClick={onClickOpenServiceActivationModal}
                                />
                            </div>
                        ) : undefined}
                    </UI.Col>
                </UI.Row>
            </UI.Tab.Container>
        );
    };

    return (
        <UI.Modal show={isServiceInfoModalDisplayed} onClose={onCloseModal} size="lg" isScrollable={true}>
            <div className="FeatureAndIntegrationModalContent" dangerouslySetInnerHTML={{ __html: content ?? "" }} />

            <PriceAndContacts />
        </UI.Modal>
    );
};

export const ServicesInfoModal = Sentry.withProfiler(observer(ServicesInfoModalBase));
