import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_EASYCONTACT_INFORMATION } from "src/api/customer";
import { GetEasyContactInformation } from "src/api/generated/GetEasyContactInformation";
import { EasyContactSettingsForm } from "src/screens/easycontactsettings/form/EasyContactSettingsForm";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { withRouter, RouteComponentProps } from "react-router";
import { selectRoute, Route } from "src/config/routes";
import styles from "./EasyContactSettings.module.css";

const EasyContactSettingsBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore, uiStore } = useContext(RootStoreContext);

    const { data, loading, error, refetch } = useQuery<GetEasyContactInformation>(GET_EASYCONTACT_INFORMATION);

    useEffect(() => {
        navStore.setTitle(t("screens.account.easycontact_settings"));
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);

        if (uiStore.isSidebarActive) {
            navStore.setBackbutton(null);
        } else {
            navStore.setBackbutton(selectRoute(Route.profile), t("screens.account.title").toString());
        }
    }, [navStore, t, uiStore.isSidebarActive]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col md={12}>
                        <UI.Card
                            title={t("screens.account.easycontact_settings").toString()}
                            className={styles.EasyContactSettingsFormContainer}
                        >
                            <div>
                                <EasyContactSettingsForm
                                    easyContactDetails={data}
                                    refetchEasyContactDetails={refetch}
                                />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const EasyContactSettings = withRouter(Sentry.withProfiler(observer(EasyContactSettingsBase)));
