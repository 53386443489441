import { useCallback, useContext, useEffect, useState } from "react";
import { SUBCRIPTION_GET_LAST_INSERTED_NEWS_EVENT } from "src/api/news";
import {
    GetLastNewsEventById,
    GetLastNewsEventByIdVariables,
    GetLastNewsEventById_noti_newsevents
} from "src/api/generated/GetLastNewsEventById";
import { getDateWithLongMonthAndTime } from "src/utils/Date";
import { RootStoreContext } from "src/stores/RootStore";
import { MessageType } from "src/components/notifications/Notifier";
import { useTranslation } from "react-i18next";
import { apolloClientInstance } from "src/network/apolloClientInstance";

export const useNewsInsertedEventSubscription = (newsid: string, initialValue?: string) => {
    const { t } = useTranslation();
    const [insertedValueState, setInsertedValueState] = useState<string>(initialValue ?? "-");
    const [newsEventState, setNewsEventState] = useState<GetLastNewsEventById_noti_newsevents[]>([]);
    const { uiStore } = useContext(RootStoreContext);

    const printStatusMessage = useCallback(
        (message: string, msgType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: msgType,
                    autoClose: msgType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    if (newsid && newsid !== "") {
        apolloClientInstance
            .subscribe<GetLastNewsEventById, GetLastNewsEventByIdVariables>({
                query: SUBCRIPTION_GET_LAST_INSERTED_NEWS_EVENT,
                variables: { newsid: newsid }
            })
            .subscribe({
                next: ({ data }) => {
                    if (data?.noti_newsevents && data.noti_newsevents.length > 0) {
                        setNewsEventState(data.noti_newsevents);
                    }
                },
                error: (err) => {
                    console.error("SUBCRIPTION_GET_LAST_INSERTED_NEWS_EVENT subscription error:", err);
                }
            });
    }

    useEffect(() => {
        if (newsEventState && newsEventState.length > 0) {
            setInsertedValueState(newsEventState[0].inserted);
            if (uiStore.isNewsInsertedEventLoaded === false) {
                printStatusMessage(t("screens.realestate.news_preview.save_notification_success"), MessageType.INFO);
                uiStore.setIsNewsInsertedEventLoaded(true);
            }
        }
    }, [newsEventState, uiStore, printStatusMessage, t]);

    return insertedValueState !== "-" ? getDateWithLongMonthAndTime(insertedValueState) : insertedValueState;
};
