import { gql } from "@apollo/client";

/* FRAGMENTS */
export const ORDER_DETAIL = gql`
    fragment order_detail on ticket_orders {
        id
        workflowinstance
        number
        state
        date
        contractortype
        craft
        orders_mls(where: { language: { _eq: $language } }) {
            title
            instruction
        }
        person {
            id
            name1
            name2
            email
            mobile
            phonebusiness
            phoneprivate
            addresses(order_by: { validfrom: desc }, limit: 1) {
                street
                housenumber
                zip
                city
            }
        }
        incident {
            id
            number
        }
    }
`;

export const GET_ALL_ORDERS_OF_TICKET = gql`
    ${ORDER_DETAIL}
    query GetAllOrdersOfTicket($ticketNumber: Int, $language: String = "de") {
        orders: ticket_orders(where: { incident: { number: { _eq: $ticketNumber } } }, order_by: { number: asc }) {
            ...order_detail
        }
    }
`;

export const GET_ORDER_BY_NUMBER = gql`
    ${ORDER_DETAIL}
    query GetOrderByNumber($ticketNumber: Int, $orderNumber: Int, $language: String = "de") {
        orders: ticket_orders(where: { incident: { number: { _eq: $ticketNumber } }, number: { _eq: $orderNumber } }) {
            ...order_detail
        }
    }
`;

export const GET_ORDER_URL_FOR_YAROWA = gql`
    query GetOrderUrl($id: uuid!) {
        ticket_orders(where: { id: { _eq: $id } }) {
            id
            customer {
                connection: connections(where: { type: { _eq: 5 } }) {
                    extradata
                }
            }
            location: incident {
                id
                house {
                    zip
                    city
                }
            }
        }
    }
`;
