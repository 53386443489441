import React, { useCallback, useContext, useEffect, useState } from "react";
import { AssemblyAgendaItem } from "./list-item/AssemblyAgendaItem";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RootStoreContext } from "src/stores/RootStore";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import { AgendaItem, AgendaItemChild } from "src/types";
import styles from "./AssemblyAgendaItems.module.css";
import { AddAgendaDocumentsModal } from "./add-documents-modal/AddAgendaDocumentsModal";

interface AssemblyAgendaItemProps {
    initialAgendaItems: AgendaItem[];
}

const AssemblyAgendaItemsBase = (props: AssemblyAgendaItemProps) => {
    const { assemblyStore } = useContext(RootStoreContext);
    const { initialAgendaItems } = props;

    const [agendaItems, setAgendaItems] = useState<AgendaItem[]>(initialAgendaItems);
    const [newAgendaItemIndex, setNewAgendaItemIndex] = useState<number>();

    useEffect(() => {
        if (assemblyStore.isCopyAgendaItemsActive) {
            const currentAgendaItems = cloneDeep(toJS(assemblyStore.originalAgendaItems));
            const unsavedAgendaItems = cloneDeep(toJS(assemblyStore.unsavedAgendaItems));

            if (unsavedAgendaItems && unsavedAgendaItems.length > 0) {
                let currentAgendaItemsLength = currentAgendaItems.length;

                const sortedUnsavedAgendaItems = unsavedAgendaItems.map((unsaveItem) => {
                    currentAgendaItemsLength++;
                    return {
                        id: unsaveItem.id,
                        text: unsaveItem.text,
                        description: unsaveItem.description,
                        isNew: true,
                        sort: currentAgendaItemsLength,
                        agendaitemfiles: unsaveItem.agendaitemfiles
                    } as AgendaItem;
                });

                assemblyStore.setUnsavedAgendaItems(sortedUnsavedAgendaItems);
                assemblyStore.setAgendaItemsHaveChanged();
            }
            assemblyStore.setIsCopyAgendaItemsActive(false);
        }
    }, [assemblyStore.resetAgendaItems, assemblyStore, agendaItems.length]);

    useEffect(() => {
        if (assemblyStore.isCopyAgendaItemsActive) {
            const currentAgendaItems = cloneDeep(toJS(assemblyStore.originalAgendaItems));
            const unsavedAgendaItems = cloneDeep(toJS(assemblyStore.unsavedAgendaItems));

            if (unsavedAgendaItems && unsavedAgendaItems.length > 0) {
                let currentAgendaItemsLength = currentAgendaItems.length;
                if (unsavedAgendaItems.length > 0) {
                    const sortedUnsavedAgendaItems = unsavedAgendaItems.map((unsaveItem) => {
                        currentAgendaItemsLength++;
                        return {
                            id: unsaveItem.id,
                            text: unsaveItem.text,
                            description: unsaveItem.description,
                            isNew: true,
                            sort: currentAgendaItemsLength,
                            agendaitemfiles: unsaveItem.agendaitemfiles
                        } as AgendaItem;
                    });

                    assemblyStore.setUnsavedAgendaItems(sortedUnsavedAgendaItems);
                    assemblyStore.setAgendaItemsHaveChanged();
                }
            }
            assemblyStore.setIsCopyAgendaItemsActive(false);
        }
    }, [assemblyStore.resetAgendaItems, assemblyStore, agendaItems.length]);

    useEffect(() => {
        if (assemblyStore.resetAgendaItems) {
            const updatedAgendaItems = cloneDeep(toJS(assemblyStore.originalAgendaItems));
            const unsavedAgendaItems = cloneDeep(toJS(assemblyStore.unsavedAgendaItems));

            if (unsavedAgendaItems) {
                const concatedAgendaItems = updatedAgendaItems.concat(unsavedAgendaItems);

                assemblyStore.setCurrentAgendaItems(concatedAgendaItems);
                setAgendaItems(assemblyStore.agendaItems);
            } else {
                assemblyStore.setCurrentAgendaItems(updatedAgendaItems);
            }

            setAgendaItems(assemblyStore.agendaItems);
            assemblyStore.setAgendaItemsHaveChanged();
            assemblyStore.setResetAgendaItems(false);
            assemblyStore.setfocusedAgendaItemIdText("");
            assemblyStore.setfocusedAgendaItemIdDescription("");
        }
    }, [assemblyStore.resetAgendaItems, assemblyStore, agendaItems.length]);

    useEffect(() => {
        if (assemblyStore.isAddNewAgendaItem) {
            const agendaItemId = uuidv4();
            assemblyStore.setfocusedAgendaItemIdText(agendaItemId);

            const newAgendaItem: AgendaItem = {
                id: agendaItemId,
                sort: newAgendaItemIndex ? newAgendaItemIndex + 1 : agendaItems.length + 1,
                text: "",
                isNew: true,
                agendaitemfiles: [],
                agendaitemchild: [],
                description: ""
            };

            const updatedAgendaItems = [...agendaItems];
            updatedAgendaItems.splice(
                newAgendaItemIndex! >= 0 ? newAgendaItemIndex! + 1 : agendaItems.length + 1,
                0,
                newAgendaItem
            );

            assemblyStore.setCurrentAgendaItems(updatedAgendaItems);
            setAgendaItems(assemblyStore.agendaItems);

            assemblyStore.setAgendaItemsHaveChanged();
            assemblyStore.setIsAddNewAgendaItem(false);
        }
    }, [assemblyStore.isAddNewAgendaItem, assemblyStore, agendaItems, newAgendaItemIndex]);

    const onDeleteAgendaItem = useCallback(
        (deleteAgendaId: number) => () => {
            assemblyStore.addDeletedAgendaItemId(deleteAgendaId.toString());

            const updatedAgendaItems = agendaItems.map((agendaItem) => {
                const updatedChildItems = agendaItem.agendaitemchild
                    ? agendaItem.agendaitemchild.filter((child) => child.id !== deleteAgendaId.toString())
                    : [];

                return {
                    ...agendaItem,
                    agendaitemchild: updatedChildItems
                };
            });

            const filteredAgendaItems = updatedAgendaItems.filter((agendaItem) => {
                return agendaItem.id !== deleteAgendaId.toString();
            });

            const deletedAgendaItemIds: string[] = [];
            agendaItems.forEach((agendaitem, index) => {
                if (
                    assemblyStore.focusedAgendaItemIdText === agendaitem.id ||
                    assemblyStore.focusedAgendaItemIdDescription === agendaitem.id
                ) {
                    deletedAgendaItemIds.push(agendaitem.id);
                    assemblyStore.addDeletedAgendaItemId(agendaitem.id);
                    const unsavedAgendaItems = cloneDeep(toJS(assemblyStore.unsavedAgendaItems));
                    const index = unsavedAgendaItems?.findIndex((item) => item.id === agendaitem.id);
                    if (index) {
                        unsavedAgendaItems?.splice(index, 1);
                    }
                    if (unsavedAgendaItems) {
                        assemblyStore.setUnsavedAgendaItems(unsavedAgendaItems);
                    }
                }
            });

            setAgendaItems(filteredAgendaItems);
            assemblyStore.setCurrentAgendaItems(filteredAgendaItems);
            assemblyStore.setAgendaItemsHaveChanged();
        },
        [assemblyStore, agendaItems]
    );

    const onChangeParentAgendaItemText = useCallback(
        (agendaItemIndex: number) => (e: any) => {
            const newAgendaItemText = e.target.value;
            if (agendaItemIndex <= agendaItems.length - 1) {
                const updatedAgendaItems = cloneDeep(toJS(agendaItems));
                updatedAgendaItems[agendaItemIndex].text = newAgendaItemText;

                assemblyStore.setCurrentAgendaItems(updatedAgendaItems);
                setAgendaItems(assemblyStore.agendaItems);
                assemblyStore.setAgendaItemsHaveChanged();
            }
        },
        [assemblyStore, agendaItems]
    );

    const onChangeChildAgendaItemText = useCallback(
        (agendaItemIndex: number, agendaItemChildId: string) => (e: any) => {
            const newChildAgendaItemText = e.target.value;

            const updatedAgendaItems = cloneDeep(toJS(agendaItems));

            const agendaItem = updatedAgendaItems[agendaItemIndex];
            if (agendaItem.agendaitemchild) {
                const childIndex = agendaItem.agendaitemchild.findIndex((child) => child.id === agendaItemChildId);

                if (childIndex !== -1) {
                    const updatedChild = { ...agendaItem.agendaitemchild[childIndex], text: newChildAgendaItemText };
                    agendaItem.agendaitemchild[childIndex] = updatedChild;
                }
            }
            assemblyStore.setCurrentAgendaItems(updatedAgendaItems);
            setAgendaItems(assemblyStore.agendaItems);
            assemblyStore.setAgendaItemsHaveChanged();
        },
        [agendaItems, assemblyStore]
    );

    const onFocusAgendaItemText = useCallback(
        (agendaItemId: string) => (e: any) => {
            assemblyStore.setfocusedAgendaItemIdText(agendaItemId);
            assemblyStore.setfocusedAgendaItemIdDescription("");
            assemblyStore.setIsNewDescription("");
        },
        [assemblyStore]
    );

    const onFocusAgendaItemDescription = useCallback(
        (agendaItemId: string) => (e: any) => {
            assemblyStore.setfocusedAgendaItemIdDescription(agendaItemId);
            assemblyStore.setfocusedAgendaItemIdText("");
            assemblyStore.setIsNewDescription(agendaItemId);
        },
        [assemblyStore]
    );

    const handleDrop = useCallback(
        (droppedItem: any) => {
            // Ignore drop outside droppable container
            if (!droppedItem.destination) return;
            const updatedAgendaItems = cloneDeep(toJS(assemblyStore.agendaItems));

            // Remove dragged item
            const [draggedItem] = updatedAgendaItems.splice(droppedItem.source.index, 1);

            // Insert dragged item
            updatedAgendaItems.splice(droppedItem.destination.index, 0, draggedItem);

            // Set the correct 'sort' number for the agendaItems
            updatedAgendaItems.forEach((agendaItem: any, index: number) => (agendaItem.sort = index + 1));

            // Update State + Mirror in AssemblyStore
            assemblyStore.setCurrentAgendaItems(updatedAgendaItems);
            setAgendaItems(assemblyStore.agendaItems);

            assemblyStore.setAgendaItemsHaveChanged();
        },
        [assemblyStore]
    );

    const onAddNewParentItem = useCallback(
        (parentItemIndex: number) => () => {
            setNewAgendaItemIndex(parentItemIndex);
            assemblyStore.setIsAddNewAgendaItem(true);
        },
        [assemblyStore]
    );

    const onAddNewChildItem = useCallback(
        (childItemIndex: number, parentItemIndex: number) => () => {
            const agendaItemId = uuidv4();
            assemblyStore.setfocusedAgendaItemIdText(agendaItemId);

            const newAgendaItemChild: AgendaItemChild = {
                id: agendaItemId,
                sort: childItemIndex + 1,
                text: "",
                isNew: true,
                agendaitemfiles: [],
                description: ""
            };

            const updatedAgendaItems = [...agendaItems];
            updatedAgendaItems[parentItemIndex].agendaitemchild?.splice(childItemIndex + 1, 0, newAgendaItemChild);

            assemblyStore.setCurrentAgendaItems(updatedAgendaItems);
            setAgendaItems(assemblyStore.agendaItems);

            assemblyStore.setAgendaItemsHaveChanged();
        },
        [assemblyStore, agendaItems]
    );

    let alreadyAddedAssemblyFileIds: string[] = [];

    const onAddAgendaItemDescription = useCallback(
        (agendaItemId: string) => (e: any) => {
            assemblyStore.setIsNewDescription(agendaItemId);
        },
        [assemblyStore]
    );

    const onChangeAgendaItemDescription = useCallback(
        (agendaItemIndex: number, agendaItemChildId: string, isChildItem: string) => (e: any) => {
            const newAgendaItemDescription = e.target.value;
            const updatedAgendaItems = cloneDeep(toJS(agendaItems));
            if (isChildItem) {
                const agendaItem = updatedAgendaItems[agendaItemIndex];
                if (agendaItem.agendaitemchild) {
                    const childIndex = agendaItem.agendaitemchild.findIndex((child) => child.id === agendaItemChildId);

                    if (childIndex !== -1) {
                        const updatedChild = {
                            ...agendaItem.agendaitemchild[childIndex],
                            description: newAgendaItemDescription
                        };
                        agendaItem.agendaitemchild[childIndex] = updatedChild;
                    }
                }
            } else {
                if (agendaItemIndex <= agendaItems.length - 1) {
                    updatedAgendaItems[agendaItemIndex].description = newAgendaItemDescription;
                }
            }

            assemblyStore.setCurrentAgendaItems(updatedAgendaItems);
            setAgendaItems(assemblyStore.agendaItems);
            assemblyStore.setAgendaItemsHaveChanged();
        },
        [assemblyStore, agendaItems]
    );

    const handleDropUntertraktanden = useCallback(
        (droppedItem: any) => {
            // Ignore drop outside droppable container
            if (!droppedItem.destination) return;

            const updatedAgendaItems = cloneDeep(toJS(assemblyStore.agendaItems));
            // Find the parent agenda item index in updatedAgendaItems
            let parentAgendaItemIndex = -1;
            for (let parentIndex = 0; parentIndex < updatedAgendaItems.length; parentIndex++) {
                const agendaItem = updatedAgendaItems[parentIndex];
                if (agendaItem.agendaitemchild) {
                    for (let childIndex = 0; childIndex < agendaItem.agendaitemchild.length; childIndex++) {
                        const childItem = agendaItem.agendaitemchild[childIndex];
                        if (childItem.id === droppedItem.draggableId) {
                            // Found the parent agenda item index
                            parentAgendaItemIndex = parentIndex;
                            break;
                        }
                    }
                }
            }

            if (parentAgendaItemIndex !== -1) {
                const parentAgendaItem = updatedAgendaItems[parentAgendaItemIndex]; // Retrieve parentAgendaItem using parentAgendaItemIndex

                if (parentAgendaItem && parentAgendaItem.agendaitemchild) {
                    // Check if parentAgendaItem and agendaitemchild are not undefined
                    // Find the dropped agendaItemChild within the parent agenda item
                    const droppedItemIndex = parentAgendaItem.agendaitemchild.findIndex(
                        (childItem) => childItem.id === droppedItem.draggableId
                    );

                    if (droppedItemIndex !== -1) {
                        // Remove dragged item from its original position
                        const [draggedItem] = parentAgendaItem.agendaitemchild.splice(droppedItemIndex, 1);

                        // Insert dragged item into the new position
                        parentAgendaItem.agendaitemchild.splice(droppedItem.destination.index, 0, draggedItem);

                        // Update sort numbers if needed
                        parentAgendaItem.agendaitemchild.forEach((childItem, index) => (childItem.sort = index + 1));

                        // Find and replace parentAgendaItem in updatedAgendaItems
                        updatedAgendaItems[parentAgendaItemIndex] = parentAgendaItem;

                        // Update state or store with the updated agenda items
                        assemblyStore.setCurrentAgendaItems(updatedAgendaItems);
                        setAgendaItems(updatedAgendaItems);

                        // Trigger any additional actions if needed
                        assemblyStore.setAgendaItemsHaveChanged();
                    }
                }
            }
        },
        [assemblyStore, setAgendaItems]
    );

    const Untertraktanden = (e: any) => {
        const agendaItemsChildren = e.agendaItemsChildren as AgendaItem[];

        return (
            <DragDropContext onDragEnd={handleDropUntertraktanden}>
                <Droppable droppableId="untertraktanden-list">
                    {(provided) => (
                        <div className="untertraktanden-list" {...provided.droppableProps} ref={provided.innerRef}>
                            {agendaItemsChildren.map((agendaItemChild, itemIndex) => {
                                return (
                                    <Draggable
                                        key={agendaItemChild.id}
                                        draggableId={agendaItemChild.id}
                                        index={itemIndex}
                                    >
                                        {(provided) => (
                                            <div
                                                className={styles.AgendaChildItem}
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                            >
                                                <AssemblyAgendaItem
                                                    itemIndex={e.parentItemIndex}
                                                    childItemIndex={itemIndex}
                                                    itemId={agendaItemChild.id}
                                                    itemText={agendaItemChild.text}
                                                    itemDescription={agendaItemChild.description || ""}
                                                    isNew={agendaItemChild.isNew ?? false}
                                                    onDeleteAgendaItem={onDeleteAgendaItem}
                                                    onChangeAgendaItemText={onChangeChildAgendaItemText}
                                                    onChangeAgendaItemDescription={onChangeAgendaItemDescription}
                                                    onFocusAgendaItemText={onFocusAgendaItemText}
                                                    onFocusAgendaItemDescription={onFocusAgendaItemDescription}
                                                    agendaItem={agendaItemChild}
                                                    isChildItem={true}
                                                    onAddNewChildItem={onAddNewChildItem}
                                                    onAddAgendaItemDescription={onAddAgendaItemDescription}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    return (
        <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="list-container">
                {(provided) => (
                    <div className="list-container" {...provided.droppableProps} ref={provided.innerRef}>
                        {agendaItems.map((agendaItem, itemIndex) => {
                            return (
                                <Draggable key={agendaItem.id} draggableId={agendaItem.id} index={itemIndex}>
                                    {(provided) => (
                                        <div
                                            className={styles.AgendaItem}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <AssemblyAgendaItem
                                                itemIndex={itemIndex}
                                                itemId={agendaItem.id}
                                                itemText={agendaItem.text}
                                                itemDescription={agendaItem.description}
                                                isNew={agendaItem.isNew ?? false}
                                                onDeleteAgendaItem={onDeleteAgendaItem}
                                                onChangeAgendaItemText={onChangeParentAgendaItemText}
                                                onChangeAgendaItemDescription={onChangeAgendaItemDescription}
                                                onFocusAgendaItemText={onFocusAgendaItemText}
                                                onFocusAgendaItemDescription={onFocusAgendaItemDescription}
                                                agendaItem={agendaItem}
                                                isChildItem={false}
                                                onAddNewChildItem={onAddNewChildItem}
                                                onAddNewParentItem={onAddNewParentItem}
                                                onAddAgendaItemDescription={onAddAgendaItemDescription}
                                                provided={provided}
                                            />

                                            {agendaItem.agendaitemchild && agendaItem.agendaitemchild?.length > 0 ? (
                                                <Untertraktanden
                                                    agendaItemsChildren={agendaItem.agendaitemchild}
                                                    parentItemIndex={itemIndex}
                                                />
                                            ) : undefined}
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}
                        {assemblyStore.isAgendaItemTemplate === false ? (
                            <AddAgendaDocumentsModal alreadyAddedAssemblyFileIds={alreadyAddedAssemblyFileIds} />
                        ) : undefined}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export const AssemblyAgendaItems = Sentry.withProfiler(observer(AssemblyAgendaItemsBase));
