import { gql } from "@apollo/client";

export const GET_TENANT_BOOKINGS_AND_FILES = gql`
    query GetTenantBookingsAndFiles($tenantid: uuid) {
        tenant_bookings_and_files: common_tenants(where: { id: { _eq: $tenantid } }) {
            accountbalance
            tenantbookings(order_by: { date: desc }) {
                id
                debit
                credit
                text
                date
                tenantbookingfiles {
                    id
                    fileid
                    file {
                        filedate
                        name
                    }
                }
            }
            tenantfiles(order_by: { file: { filedate: desc } }) {
                id
                fileid
                file {
                    filedate
                    name
                }
            }
        }
    }
`;
