import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { INVOICE_LIST_STATE, INVOICE_STATE, INVOICE_TYPE, SORT } from "./InvoiceEnums";
import { IInvoiceFilter, ISearchTag } from "./InvoiceTypes";
import { GetRealestateUserAndAccessData_realestateUsersAndAccess } from "src/api/generated/GetRealestateUserAndAccessData";
import { SCREEN_SIZE } from "src/stores/UIStore";
import { formatDate } from "./Utils";
import { format } from "date-fns";
import i18next from "i18next";

export const getInvoiceStateById = (stateId: number): INVOICE_STATE => {
    switch (stateId) {
        case 10:
            return INVOICE_STATE.NEW;
        case 20:
            return INVOICE_STATE.READY_FOR_ACCOUNTING;
        case 30:
            return INVOICE_STATE.READY_FOR_VISA;
        case 41:
            return INVOICE_STATE.VISA_1_OK;
        case 42:
            return INVOICE_STATE.VISA_2_OK;
        case 43:
            return INVOICE_STATE.VISA_3_OK;
        case 50:
            return INVOICE_STATE.FULLY_SIGHTED;
        case 60:
            return INVOICE_STATE.SENT_TO_ERP;
        case 90:
            return INVOICE_STATE.PAID;
        case 95:
            return INVOICE_STATE.REJECTED;
        case 99:
            return INVOICE_STATE.CANCELLED;
        default:
            return INVOICE_STATE.NEW;
    }
};

export class InvoiceListStore {
    rootStore: RootStore;

    invoiceFilter: IInvoiceFilter = {
        state: INVOICE_LIST_STATE.NEW,
        sort: SORT.DATE_NEWEST,
        query: "",
        types: [],
        // showOwn: true,
        showOnlyMyInvoices: false,
        showOnlyInvoicesFromMyRealestates: false
    };

    searchTags: ISearchTag[] = [
        {
            label: i18next.t("screens.kredi_flow.list.tags.only_mine"),
            id: "SHOW_ONLY_MY_OWN_INVOICES",
            active: this.invoiceFilter.showOnlyMyInvoices
        },
        {
            label: i18next.t("screens.kredi_flow.list.tags.my_realestates"),
            id: "SHOW_ONLY_INVOICES_FROM_MY_REALESTATES",
            active: false
        },
        { label: i18next.t("screens.kredi_flow.invoice_type.1"), id: "2", active: false, type: INVOICE_TYPE.INVOICE },
        { label: i18next.t("screens.kredi_flow.invoice_type.2"), id: "3", active: false, type: INVOICE_TYPE.BILL }
    ];

    realestateUserData: GetRealestateUserAndAccessData_realestateUsersAndAccess[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            invoiceFilter: observable,
            realestateUserData: observable,
            searchTags: observable,

            setFilter: action,
            clearFilter: action,
            setTag: action,
            setRealestateUserData: action,

            invoiceStats: computed,
            filteredInvoices: computed,
            invoices: computed
        });
    }

    convertInvoiceStateToInvoiceListState = (state: INVOICE_STATE): INVOICE_LIST_STATE => {
        const invoiceStateNumber = Number(state);
        let convertedInvoiceListFilterState = INVOICE_LIST_STATE.ALL;

        switch (true) {
            case invoiceStateNumber === INVOICE_STATE.NEW:
                convertedInvoiceListFilterState = INVOICE_LIST_STATE.NEW;
                break;
            case invoiceStateNumber >= INVOICE_STATE.READY_FOR_ACCOUNTING &&
                invoiceStateNumber < INVOICE_STATE.FULLY_SIGHTED:
                convertedInvoiceListFilterState = INVOICE_LIST_STATE.TO_APPROVE;
                break;
            case invoiceStateNumber >= INVOICE_STATE.FULLY_SIGHTED && invoiceStateNumber <= INVOICE_STATE.SENT_TO_ERP:
                convertedInvoiceListFilterState = INVOICE_LIST_STATE.PAY;
                break;
            case invoiceStateNumber === INVOICE_STATE.PAID:
                convertedInvoiceListFilterState = INVOICE_LIST_STATE.PAID;
                break;
            case invoiceStateNumber === INVOICE_STATE.REJECTED:
                convertedInvoiceListFilterState = INVOICE_LIST_STATE.REJECTED;
                break;
            case invoiceStateNumber === INVOICE_STATE.CANCELLED:
                convertedInvoiceListFilterState = INVOICE_LIST_STATE.CANCELLED;
                break;
        }

        return convertedInvoiceListFilterState;
    };

    clearFilter = () => {
        this.searchTags = [...this.searchTags].map((t) => {
            return { ...t, active: false };
        });

        this.setFilter({
            state: INVOICE_LIST_STATE.NEW,
            sort: SORT.DATE_NEWEST,
            query: "",
            types: [],
            showOnlyMyInvoices: false,
            showOnlyInvoicesFromMyRealestates: false
        });
    };

    setTag = (id: string, active: boolean) => {
        const newSearchTags: ISearchTag[] = [...this.searchTags].map((searchTag) => {
            if (searchTag.id === id) {
                return { ...searchTag, active: active };
            }
            return searchTag;
        });

        const activeSearchTags: INVOICE_TYPE[] = [];

        newSearchTags.forEach((tag) => {
            if (tag.active && tag.type) {
                activeSearchTags.push(tag.type);
            }
        });

        this.searchTags = newSearchTags;

        this.setFilter({
            ...this.invoiceFilter,
            types: activeSearchTags,
            showOnlyMyInvoices:
                newSearchTags.find((searchTag) => searchTag.id === "SHOW_ONLY_MY_OWN_INVOICES")?.active ?? false,
            showOnlyInvoicesFromMyRealestates:
                newSearchTags.find((st) => st.id === "SHOW_ONLY_INVOICES_FROM_MY_REALESTATES")?.active ?? false
        });
    };

    // Setters
    setFilter = (newFilter: IInvoiceFilter) => {
        this.invoiceFilter = newFilter;
    };

    setRealestateUserData = (realestateUserData: GetRealestateUserAndAccessData_realestateUsersAndAccess[]) => {
        this.realestateUserData = realestateUserData;
    };

    // Computed values
    get invoices() {
        return this.rootStore.invoiceStore.invoices;
    }

    get invoiceStats() {
        const allInvoices = [...this.filteredInvoices];

        const getNumberOfTicketsByState = (invoiceListState: INVOICE_LIST_STATE) => {
            switch (invoiceListState) {
                case INVOICE_LIST_STATE.TO_APPROVE:
                    return allInvoices.filter(
                        (invoice) =>
                            invoice.state !== undefined &&
                            invoice.state >= INVOICE_STATE.READY_FOR_ACCOUNTING &&
                            invoice.state < INVOICE_STATE.FULLY_SIGHTED
                    ).length;
                case INVOICE_LIST_STATE.PAY:
                    return allInvoices.filter(
                        (invoice) =>
                            invoice.state !== undefined &&
                            invoice.state >= INVOICE_STATE.FULLY_SIGHTED &&
                            invoice.state <= INVOICE_STATE.SENT_TO_ERP
                    ).length;
                default:
                    return allInvoices.filter(
                        (invoice) => invoice.state !== undefined && Number(invoice.state) === Number(invoiceListState)
                    ).length;
            }
        };

        return {
            new: getNumberOfTicketsByState(INVOICE_LIST_STATE.NEW),
            toApprove: getNumberOfTicketsByState(INVOICE_LIST_STATE.TO_APPROVE),
            pay: getNumberOfTicketsByState(INVOICE_LIST_STATE.PAY),
            paid: getNumberOfTicketsByState(INVOICE_LIST_STATE.PAID),
            rejected: getNumberOfTicketsByState(INVOICE_LIST_STATE.REJECTED),
            cancelled: getNumberOfTicketsByState(INVOICE_LIST_STATE.CANCELLED),
            total: { total: this.invoices.length, filtered: allInvoices.length }
        };
    }

    get filteredInvoices() {
        const filter = this.invoiceFilter;
        return this.invoices
            .filter((invoice) => {
                if (filter.query === "") {
                    return true;
                }

                const date =
                    this.rootStore.uiStore.currentScreenSize > SCREEN_SIZE.LAPTOP
                        ? formatDate(invoice.date ?? format(new Date(), "d.M.yy"))
                        : formatDate(invoice.date ?? format(new Date(), "d.M.yy"), true);

                const dueDate =
                    this.rootStore.uiStore.currentScreenSize > SCREEN_SIZE.LAPTOP
                        ? formatDate(invoice.dueDate ?? format(new Date(), "d.M.yy"))
                        : formatDate(invoice.dueDate ?? format(new Date(), "d.M.yy"), true);

                const searchString = [
                    invoice.number ?? "",
                    date,
                    dueDate,
                    invoice.creditor?.name ?? "",
                    invoice.invoiceNumber ?? "",
                    invoice.amount?.toString() ?? "",
                    invoice.realestate?.name ?? "",
                    invoice.realestate?.city ?? "",
                    invoice.realestate?.zip ?? "",
                    invoice.assignedUser?.name1 ?? "",
                    invoice.assignedUser?.name2 ?? ""
                ]
                    .join("")
                    .toLowerCase();

                return searchString.search(filter.query.toLowerCase()) !== -1;
            })

            .filter((invoice) => {
                if (filter.showOnlyMyInvoices) {
                    //TODO: Filter realestatesuser as well
                    return invoice.assignedUser?.id === this.rootStore.authStore.user?.userid;
                }
                return true;
            })
            .filter((invoice) => {
                if (filter.showOnlyInvoicesFromMyRealestates) {
                    return this.realestateUserData.some((user) => user.realestateid === invoice.realestate?.id);
                }
                return true;
            })
            .filter((invoice) => {
                if (filter.types.length === 0) {
                    return true;
                }

                if (invoice.invoiceType) {
                    return filter.types.includes(invoice.invoiceType);
                }

                return false;
            })

            .sort((lhs, rhs) => {
                if (filter.sort === SORT.DATE_OLDEST) {
                    return (lhs.number ?? 0) - (rhs.number ?? 0);
                } else if (filter.sort === SORT.DATE_NEWEST) {
                    return (rhs.number ?? 0) - (lhs.number ?? 0);
                } else {
                    return 0;
                }
            });
    }
}
