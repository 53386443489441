import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useSelectionListKeyHandler } from "src/hooks/ticket/selection-list-key-handler/useSelectionListKeyHandler";
import { boldenMatchedSubstring } from "src/utils/Common";
import "./GeneralInvoiceFormStyles.css";
import { IPaymentAccount } from "src/stores/krediflow/InvoiceTypes";
import { INVOICE_PAYMENT_INFO_PROPERTY, INVOICE_PROPERTY } from "src/stores/krediflow/InvoiceEnums";
import styles from "./InvoiceDetailFormFields.module.css";
import { formatDate, formatReferenceNumber } from "src/stores/krediflow/Utils";
import { friendlyFormatIBAN } from "ibantools";
import { SCREEN_SIZE } from "src/stores/UIStore";

const InvoiceDetailFormFieldsBase = () => {
    const { t } = useTranslation();
    const { ticketStore, uiStore, creditorSearchStore, invoiceStore, paymentAccountSearchStore } =
        useContext(RootStoreContext);

    const [displayPaymentAccountList, setDisplayPaymentAccountList] = useState<boolean>(false);
    const [lastScrollPositionYPaymentAccountItems, setLastSrollYPositionPaymentAccountItems] = useState<number>(0);

    const paymentAccountInput = useRef<HTMLInputElement>(null);

    const resetSearchQuery = useCallback(() => {
        paymentAccountSearchStore.setCurrentSelectedIBAN(paymentAccountSearchStore.currentSelectedIBAN ?? "");
    }, [paymentAccountSearchStore]);

    useEffect(() => {
        const handleClickOutsidePaymentAccountInput = (event: any) => {
            if (
                displayPaymentAccountList &&
                paymentAccountInput.current &&
                !paymentAccountInput.current.contains(event.target) &&
                !(event.target.classList.contains("list-item") && event.target.classList.contains("paymentaccount")) &&
                !(
                    event.target.classList.contains("highlighted-text") &&
                    event.target.classList.contains("paymentaccount")
                ) &&
                !event.target.classList.contains("paymentaccount-list-item-span") &&
                !event.target.classList.contains("paymentaccount-list-item-line-1") &&
                !event.target.classList.contains("paymentaccount-list-item-line-2") &&
                !event.target?.parentElement?.parentElement?.parentElement.classList?.contains("IconSVG")
            ) {
                setDisplayPaymentAccountList(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutsidePaymentAccountInput);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsidePaymentAccountInput);
        };
    }, [
        displayPaymentAccountList,
        ticketStore,
        resetSearchQuery,
        creditorSearchStore.currentCreditorSearchQuery,
        creditorSearchStore.selectedCreditorQueryString,
        invoiceStore.currentInvoice?.isCreditorSelected
    ]);

    useEffect(() => {
        if (paymentAccountSearchStore.triggerFocusOnPaymentAccountInput) {
            paymentAccountInput.current?.focus();
            paymentAccountSearchStore.setTriggerFocusOnPaymentAccountInput(false);
        }
        // This useEffect only listens to changes in contactPersonSearchStore.triggerFocusOnPersonInput
        // eslint-disable-next-line
    }, [paymentAccountSearchStore.triggerFocusOnPaymentAccountInput]);

    const onSelectPaymentAccountItem = useCallback(
        (activeListItemIndex: number) => {
            const selectedPaymentAccountItem =
                paymentAccountSearchStore.filteredPaymentAccountData[activeListItemIndex];

            paymentAccountSearchStore.updateInvoiceStoreWithSelectedPaymentAccount(selectedPaymentAccountItem);

            paymentAccountSearchStore.setCurrentSelectedIBAN(selectedPaymentAccountItem.iban ?? "");
        },
        [paymentAccountSearchStore]
    );

    const { onKeyDownFunction, setActiveListItemIndex, NO_LIST_ITEM_SELECTED } = useSelectionListKeyHandler({
        listName: "paymentaccount",
        totalNumberOfDisplayedItems: invoiceStore.numberOfDisplayedItems,
        setIsListDisplayed: setDisplayPaymentAccountList,
        isListDisplayed: displayPaymentAccountList,
        onSelectItem: onSelectPaymentAccountItem,
        resetSearchQuery: resetSearchQuery
    });

    const resetAmountOfItemsDisplayedInPaymentAccountList = useCallback(() => {
        setLastSrollYPositionPaymentAccountItems(0);
        invoiceStore.setNumberOfDisplayedItems(invoiceStore.sliceSizeOfDisplayedItems);
    }, [invoiceStore]);

    const onChangeIBANInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            resetAmountOfItemsDisplayedInPaymentAccountList();

            const inputIBAN = e.target.value;

            paymentAccountSearchStore.setCurrentSelectedIBAN(inputIBAN);
            paymentAccountSearchStore.updateInvoiceStoreWithManuallyEnteredIBAN(inputIBAN);
        },

        [paymentAccountSearchStore, resetAmountOfItemsDisplayedInPaymentAccountList]
    );

    const onBlurIBANInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            resetAmountOfItemsDisplayedInPaymentAccountList();

            let inputIBAN = e.target.value;

            // Remove all whitespaces that might have been entered
            inputIBAN = inputIBAN.replace(/\s+/g, "");

            if (inputIBAN) {
                paymentAccountSearchStore.updateInvoiceStoreWithManuallyEnteredIBAN(inputIBAN);
            }
        },
        [resetAmountOfItemsDisplayedInPaymentAccountList, paymentAccountSearchStore]
    );

    const onFocusPaymentAccountInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (paymentAccountInput.current) {
                paymentAccountInput.current.select();
            }

            resetAmountOfItemsDisplayedInPaymentAccountList();
            setDisplayPaymentAccountList(true);
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
        },
        [resetAmountOfItemsDisplayedInPaymentAccountList, setActiveListItemIndex, NO_LIST_ITEM_SELECTED]
    );

    const onClickPaymentAccountItem = useCallback(
        (paymentAccount: IPaymentAccount) => async (e: any) => {
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
            setDisplayPaymentAccountList(false);

            paymentAccountSearchStore.updateInvoiceStoreWithSelectedPaymentAccount(paymentAccount);
        },
        [setActiveListItemIndex, NO_LIST_ITEM_SELECTED, paymentAccountSearchStore]
    );

    const onScrollPaymentAccountList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYPaymentAccountItems;

            const hasMoreResulsToDisplay =
                invoiceStore.numberOfDisplayedItems < paymentAccountSearchStore.filteredPaymentAccountData.length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                invoiceStore.setNumberOfDisplayedItems(
                    invoiceStore.numberOfDisplayedItems + invoiceStore.sliceSizeOfDisplayedItems
                );
            }

            setLastSrollYPositionPaymentAccountItems(pixelsFromTop);
        },
        [
            lastScrollPositionYPaymentAccountItems,
            paymentAccountSearchStore.filteredPaymentAccountData.length,
            invoiceStore
        ]
    );

    const getSpanElementWithFormattedText = useCallback(
        (stringToFormat: string) => {
            const formattedString =
                paymentAccountSearchStore.currentSelectedIBAN !== ""
                    ? boldenMatchedSubstring(
                          paymentAccountSearchStore.currentSelectedIBAN ?? "",
                          stringToFormat,
                          "highlighted-text paymentaccount"
                      )
                    : stringToFormat;

            return (
                <span
                    className="paymentaccount-list-item-span"
                    dangerouslySetInnerHTML={{
                        __html: formattedString
                    }}
                />
            );
        },
        [paymentAccountSearchStore.currentSelectedIBAN]
    );

    const onChangeInvoiceFormField = useCallback(
        (invoiceProperty: INVOICE_PROPERTY) => (e: React.ChangeEvent<HTMLInputElement>) => {
            let newValue: any = e.target.value;

            if (invoiceProperty === INVOICE_PROPERTY.AMOUNT) {
                newValue = Number(newValue);
            }
            if (invoiceStore.currentInvoice) {
                invoiceStore.currentInvoice.updateProperty(invoiceProperty, newValue);
            }
        },
        [invoiceStore.currentInvoice]
    );

    const onChangePaymentInfoFormField = useCallback(
        (paymentInfoProperty: INVOICE_PAYMENT_INFO_PROPERTY) => (e: React.ChangeEvent<HTMLInputElement>) => {
            let newValue: any = e.target.value;

            if (invoiceStore.currentInvoice) {
                invoiceStore.currentInvoice.updatePaymentInfoProperty(paymentInfoProperty, newValue);
            }
        },
        [invoiceStore.currentInvoice]
    );

    const onBlurInvoiceReferenceInput = useCallback(() => {
        invoiceStore.currentInvoice?.validate();
    }, [invoiceStore.currentInvoice]);

    const paymentAccountSelectionList = (
        <div id="paymentaccount-dropdown-list-container">
            <div
                className={`${styles.PaymentAccountSelectionListSearchIndicator} ${
                    !paymentAccountSearchStore.isSearchingPaymentAccountData &&
                    !(
                        paymentAccountSearchStore.paymentAccountData.length === 0 &&
                        !paymentAccountSearchStore.isSearchingPaymentAccountData
                    )
                        ? styles.NoBorderBottom
                        : ""
                }`}
            >
                {paymentAccountSearchStore.isSearchingPaymentAccountData ? (
                    <UI.RotatingSpinner noLogo size={30} className={styles.SearchingPaymentAccountSpinner} />
                ) : undefined}
            </div>
            {paymentAccountSearchStore.isSearchingPaymentAccountData ? undefined : (
                <ul
                    id="paymentaccount-dropdown-list"
                    className={"dropdown-list paymentaccount"}
                    onScroll={onScrollPaymentAccountList}
                >
                    {paymentAccountSearchStore.filteredPaymentAccountData
                        .slice(0, invoiceStore.numberOfDisplayedItems)
                        .map((paymentAccount, index) => {
                            const formattedPaymentAccountNameSpan = getSpanElementWithFormattedText(
                                paymentAccount.name ?? ""
                            );
                            const formattedPaymentAccountIBANSpan = getSpanElementWithFormattedText(
                                paymentAccount.iban ?? ""
                            );

                            return (
                                <li key={index}>
                                    <div
                                        className={"list-item creditor"}
                                        id={`creditor-list-item-${index}`}
                                        onClick={onClickPaymentAccountItem(paymentAccount)}
                                    >
                                        {uiStore.isMobile ? (
                                            <>
                                                <div>{formattedPaymentAccountNameSpan}</div>
                                                <div>{formattedPaymentAccountIBANSpan}</div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={"paymentaccount-list-item-line-1"}>
                                                    {formattedPaymentAccountNameSpan}
                                                </div>
                                                <div className={"paymentaccount-list-item-line-2"}>
                                                    {formattedPaymentAccountIBANSpan}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            )}
        </div>
    );

    const currentInvoice = invoiceStore.currentInvoice;

    const editModePaymentAccountElements = (
        <>
            <UI.Row>
                <div
                    className={
                        uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP
                            ? "col-3"
                            : uiStore.invoicePaneScreenSize === SCREEN_SIZE.TABLET
                            ? "col-6"
                            : "col-12"
                    }
                >
                    <UI.Input
                        label={t("screens.kredi_flow.form.invoice.date").toString()}
                        type="date"
                        autoComplete="off"
                        value={
                            invoiceStore.currentInvoice && invoiceStore.currentInvoice.date
                                ? invoiceStore.currentInvoice.date
                                : ""
                        }
                        onChange={onChangeInvoiceFormField(INVOICE_PROPERTY.DATE)}
                    />
                </div>
                <div
                    className={
                        uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP
                            ? "col-4"
                            : uiStore.invoicePaneScreenSize === SCREEN_SIZE.TABLET
                            ? "col-6"
                            : "col-12"
                    }
                >
                    <UI.Input
                        label={t("screens.kredi_flow.form.invoice.amount").toString()}
                        type="number"
                        autoComplete="off"
                        value={
                            invoiceStore.currentInvoice && invoiceStore.currentInvoice.amount
                                ? invoiceStore.currentInvoice.amount
                                : ""
                        }
                        onChange={onChangeInvoiceFormField(INVOICE_PROPERTY.AMOUNT)}
                    />
                </div>
                <div className={uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "col-5" : "col-12"}>
                    <UI.Input
                        label={t("screens.kredi_flow.form.invoice.iban").toString()}
                        ref={paymentAccountInput}
                        type="text"
                        autoComplete="off"
                        className={`${styles.PaymentAccountInputField} ${
                            invoiceStore.currentInvoice?.errors.ibanFormatInvalid ? "error-text" : ""
                        }`}
                        id="invoice-form-paymentaccount-input"
                        value={paymentAccountSearchStore.currentSelectedIBAN}
                        onChange={onChangeIBANInput}
                        onBlur={onBlurIBANInput}
                        onFocus={onFocusPaymentAccountInput}
                        onKeyDown={onKeyDownFunction}
                        disabled={!invoiceStore.isEditing}
                        iconRight={
                            <UI.Icon icon={UI.SVGIcon.Search} color="grey" size={"small"} style={{ right: "5px" }} />
                        }
                        errorMsg={invoiceStore.currentInvoice?.errors.ibanFormatInvalid ?? ""}
                    />
                    {displayPaymentAccountList ? paymentAccountSelectionList : undefined}
                </div>
            </UI.Row>
            <UI.Row>
                <div
                    className={
                        uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP
                            ? "col-3"
                            : uiStore.invoicePaneScreenSize === SCREEN_SIZE.TABLET
                            ? "col-6"
                            : "col-12"
                    }
                >
                    <UI.Input
                        label={t("screens.kredi_flow.form.invoice.due_date").toString()}
                        type="date"
                        autoComplete="off"
                        value={
                            invoiceStore.currentInvoice && invoiceStore.currentInvoice.dueDate
                                ? invoiceStore.currentInvoice.dueDate
                                : ""
                        }
                        onChange={onChangeInvoiceFormField(INVOICE_PROPERTY.DUE_DATE)}
                    />
                </div>
                <div
                    className={
                        uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP
                            ? "col-4"
                            : uiStore.invoicePaneScreenSize === SCREEN_SIZE.TABLET
                            ? "col-6"
                            : "col-12"
                    }
                >
                    <UI.Input
                        label={t("screens.kredi_flow.form.invoice.invoice_number").toString()}
                        type="text"
                        autoComplete="off"
                        value={
                            invoiceStore.currentInvoice && invoiceStore.currentInvoice.invoiceNumber
                                ? invoiceStore.currentInvoice.invoiceNumber
                                : ""
                        }
                        onChange={onChangeInvoiceFormField(INVOICE_PROPERTY.INVOICE_NUMBER)}
                    />
                </div>
                <div className={uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "col-5" : "col-12"}>
                    <UI.Input
                        label={t("screens.kredi_flow.form.invoice.reference_number").toString()}
                        type="text"
                        autoComplete="off"
                        value={
                            invoiceStore.currentInvoice &&
                            invoiceStore.currentInvoice.paymentInfo &&
                            invoiceStore.currentInvoice.paymentInfo.reference
                                ? invoiceStore.currentInvoice.paymentInfo.reference
                                : ""
                        }
                        onChange={onChangePaymentInfoFormField(INVOICE_PAYMENT_INFO_PROPERTY.REFERENCE)}
                        onBlur={onBlurInvoiceReferenceInput}
                        errorMsg={invoiceStore.currentInvoice?.errors.referenceNumberFormatInvalid ?? ""}
                        className={invoiceStore.currentInvoice?.errors.referenceNumberFormatInvalid ? "error-text" : ""}
                    />
                </div>
            </UI.Row>
        </>
    );

    const viewCreditorElements = (
        <UI.Container fluid className="mt-3">
            <UI.Row>
                {/* First Column */}
                <div className={uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "col-3" : "col-12"}>
                    <UI.Row className={uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "mb-4" : ""}>
                        <div className={styles.InvoiceDetailInformationContainer}>
                            <div className="bold-text mb-1">{t("screens.kredi_flow.form.invoice.date").toString()}</div>
                            <div>{currentInvoice?.date ? formatDate(currentInvoice?.date) : ""}</div>
                        </div>
                    </UI.Row>
                    <UI.Row>
                        <div className={styles.InvoiceDetailInformationContainer}>
                            <div className="bold-text mb-1">
                                {t("screens.kredi_flow.form.invoice.due_date").toString()}
                            </div>
                            <div>{currentInvoice?.dueDate ? formatDate(currentInvoice?.dueDate) : ""}</div>
                        </div>
                    </UI.Row>
                </div>

                {/* Second Column */}
                <div className={uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "col-4" : "col-12"}>
                    <UI.Row
                        className={`${uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "mb-4" : ""} ${
                            uiStore.invoicePaneScreenSize > SCREEN_SIZE.TABLET ? "d-block" : "d-none"
                        }`}
                    >
                        <div className={styles.InvoiceDetailInformationContainer}></div>
                    </UI.Row>
                    <UI.Row>
                        <div className={styles.InvoiceDetailInformationContainer}>
                            <div className="bold-text mb-1">
                                {t("screens.kredi_flow.form.invoice.invoice_number").toString()}
                            </div>
                            <div>{currentInvoice?.invoiceNumber ? currentInvoice?.invoiceNumber : ""}</div>
                        </div>
                    </UI.Row>
                </div>

                {/* Third Column */}
                <div className={uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "col-5" : "col-12"}>
                    <UI.Row className={uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "mb-4" : ""}>
                        <div className={styles.InvoiceDetailInformationContainer}>
                            <div className="bold-text mb-1">{t("screens.kredi_flow.form.invoice.iban").toString()}</div>
                            <div>
                                {currentInvoice && currentInvoice.paymentAccount && currentInvoice.paymentAccount.iban
                                    ? friendlyFormatIBAN(currentInvoice.paymentAccount.iban)
                                    : currentInvoice && currentInvoice.paymentInfo && currentInvoice.paymentInfo.iban
                                    ? friendlyFormatIBAN(currentInvoice.paymentInfo.iban)
                                    : ""}
                            </div>
                        </div>
                    </UI.Row>
                    <UI.Row>
                        <div className={styles.InvoiceDetailInformationContainer}>
                            <div className="bold-text mb-1">
                                {t("screens.kredi_flow.form.invoice.reference_number").toString()}
                            </div>
                            <div>
                                {currentInvoice?.paymentInfo?.reference
                                    ? formatReferenceNumber(currentInvoice?.paymentInfo?.reference)
                                    : ""}
                            </div>
                        </div>
                    </UI.Row>
                </div>
            </UI.Row>
        </UI.Container>
    );

    return invoiceStore.isEditing ? editModePaymentAccountElements : viewCreditorElements;
};

export const InvoiceDetailFormFields = Sentry.withProfiler(observer(InvoiceDetailFormFieldsBase));
