import { observable, makeObservable, action, computed } from "mobx";
import { GetIncidentPersonTypes_common_enumerations } from "src/api/generated/GetIncidentPersonTypes";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey } from "src/network/User";
import { RootStore } from "src/stores/RootStore";

export enum MANUAL_EMAIL_PROCESS {
    START_SEND_EMAIL,
    SEND_EMAIL_RESULT
}

export enum MANAUAL_EMAIL_PROCESS_STEP {
    START_SEND_EMAIL,
    SEND_EMAIL_RESULT
}

export enum SENDEMAIL_STATUS {
    NONE,
    SENDEMAIL,
    SUCCESS,
    FAILURE
}

interface IProcessStep {
    stepNumber: number;
    stepType: MANAUAL_EMAIL_PROCESS_STEP;
    next?: () => IProcessStep | null;
    prev?: () => IProcessStep | null;
    parent: IProcess | null;
}

interface IProcess {
    type: MANUAL_EMAIL_PROCESS;
    processSteps: IProcessStep[];
}

interface IManualEmailProcess {
    stepNumber: number;
    stepType: MANAUAL_EMAIL_PROCESS_STEP;
    processes: IProcess[];
}

export interface EmailData {
    incidentId: string;
    orderId?: string | null;
    subject: string;
    body: string;
    recipients: string[];
    ccs: string[];
}
export class ManualEmailModalStore {
    rootStore: RootStore;

    ACTIVATION_MODAL_MAX_NUMBER_OF_STEPS = 2;

    currentProcessType: MANUAL_EMAIL_PROCESS = MANUAL_EMAIL_PROCESS.START_SEND_EMAIL;
    currentStepType: MANAUAL_EMAIL_PROCESS_STEP = MANAUAL_EMAIL_PROCESS_STEP.START_SEND_EMAIL;
    currentManualEmailStatus: SENDEMAIL_STATUS = SENDEMAIL_STATUS.NONE;
    isManualEmailProgressModalDisplayed = false;
    isManualEmailSuccessfullySend = false;

    manualEmailProcess: IManualEmailProcess = {
        stepNumber: 1,
        stepType: MANAUAL_EMAIL_PROCESS_STEP.START_SEND_EMAIL,
        processes: [
            {
                type: MANUAL_EMAIL_PROCESS.SEND_EMAIL_RESULT,
                processSteps: [
                    {
                        stepNumber: 2,
                        stepType: MANAUAL_EMAIL_PROCESS_STEP.SEND_EMAIL_RESULT,
                        next: function () {
                            return this.parent ? this.parent.processSteps[1] : null;
                        },
                        prev: function () {
                            return this.parent ? this.parent.processSteps[0] : null;
                        },
                        parent: null
                    }
                ]
            }
        ]
    };

    isModalDisplayed = false;
    incidentPersonEnums: GetIncidentPersonTypes_common_enumerations[] = [];
    emailData: EmailData | undefined;
    displayEmailToList = false;
    displayEmailCcList = false;
    emailListCc: string[] = [];
    manualEmailListCc: string[] = [];
    isRecipientsEmpty = true;
    errorMessage: string = "";

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            isModalDisplayed: observable,
            setIsModalDisplayed: observable,
            incidentPersonEnums: observable,
            emailData: observable,
            displayEmailToList: observable,
            displayEmailCcList: observable,
            emailListCc: observable,
            manualEmailListCc: observable,
            isRecipientsEmpty: observable,
            errorMessage: observable,

            setIncidentPersonEnums: action,
            sendManualEmail: action,
            setEmailData: action,
            setDisplayEmailToList: action,
            setDisplayEmailCcList: action,
            setEmailListCc: action,
            setManualEmailListCc: action,
            setIsRecipientsEmpty: action,
            setErrorMessage: action,

            currentProcessType: observable,
            currentStepType: observable,
            currentManualEmailStatus: observable,
            isManualEmailProgressModalDisplayed: observable,
            isManualEmailSuccessfullySend: observable,

            getProcess: action,
            resetServiceActivationModal: action,
            resetCurrentProcessType: action,
            setCurrentProcessType: action,
            setCurrentStepType: action,
            setCurrentServiceActivationStatus: action,
            setIsActivationProgressModalDisplayed: action,
            setIsManualEmailSuccessfullySend: action,

            processes: computed,
            currentProcess: computed,
            currentStep: computed
        });
    }

    getProcess = (processType: MANUAL_EMAIL_PROCESS) => {
        return this.manualEmailProcess.processes.find((process) => {
            return process.type === processType;
        })!;
    };

    resetServiceActivationModal = () => {
        this.resetCurrentProcessType();
        this.currentManualEmailStatus = SENDEMAIL_STATUS.NONE;
    };

    resetCurrentProcessType() {
        this.currentProcessType = MANUAL_EMAIL_PROCESS.SEND_EMAIL_RESULT;
        this.currentStepType = MANAUAL_EMAIL_PROCESS_STEP.SEND_EMAIL_RESULT;
    }

    setCurrentProcessType = (processType: MANUAL_EMAIL_PROCESS) => {
        this.currentProcessType = processType;
        this.currentStepType = this.getProcess(processType).processSteps[0].stepType;
    };

    setCurrentStepType = (stepType: MANAUAL_EMAIL_PROCESS_STEP) => {
        this.currentStepType = stepType;
    };

    setIsActivationProgressModalDisplayed = (isModalDisplayed: boolean) => {
        this.isManualEmailProgressModalDisplayed = isModalDisplayed;
    };

    setCurrentServiceActivationStatus = (status: SENDEMAIL_STATUS) => {
        this.currentManualEmailStatus = status;
    };
    setIsManualEmailSuccessfullySend = (isServiceSuccessfullyActivated: boolean) => {
        this.isManualEmailSuccessfullySend = isServiceSuccessfullyActivated;
    };

    /* COMPUTED */
    get processes() {
        return this.manualEmailProcess.processes;
    }

    get currentProcess(): IProcess | undefined {
        return this.manualEmailProcess.processes.find((process) => process.type === this.currentProcessType);
    }

    get currentStep(): IProcessStep | undefined {
        return this.currentProcess?.processSteps.find((step) => step.stepType === this.currentStepType);
    }

    sendManualEmail = async (): Promise<boolean> => {
        let wasSuccessfullySend = false;
        this.setIsManualEmailSuccessfullySend(false);

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const response = await fetch(NetworkConfig.sendManualEmailUrl, {
                method: "POST",
                body: JSON.stringify(this.emailData),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (response.status === 200) {
                wasSuccessfullySend = true;
                this.setIsManualEmailSuccessfullySend(true);
                await this.rootStore.ticketStore.currentTicket?.reloadHistory();
            } else {
                this.setErrorMessage(response.statusText);
                console.error("Error send manual email: ", response);
            }
        } catch (error) {
            if (error instanceof Error) {
                this.setErrorMessage(error.toString());
            }
            console.error("Error while trying to send manual email: ", error);
        }

        return wasSuccessfullySend;
    };

    /* SETTERS */
    setIsModalDisplayed = (isModalDisplayed: boolean) => {
        this.isModalDisplayed = isModalDisplayed;
    };

    setIncidentPersonEnums = (incidentPersonEnums: GetIncidentPersonTypes_common_enumerations[]) => {
        this.incidentPersonEnums = incidentPersonEnums;
    };

    setEmailData = (emailData: EmailData) => {
        this.emailData = emailData;
    };

    setDisplayEmailToList = (displayEmailToList: boolean) => {
        this.displayEmailToList = displayEmailToList;
    };

    setDisplayEmailCcList = (displayEmailCcList: boolean) => {
        this.displayEmailCcList = displayEmailCcList;
    };

    setEmailListCc = (emailListCc: string[]) => {
        this.emailListCc = emailListCc;
    };

    setManualEmailListCc = (manualEmailListCc: string[]) => {
        this.manualEmailListCc = manualEmailListCc;
    };

    setIsRecipientsEmpty = (isRecipientsEmpty: boolean) => {
        this.isRecipientsEmpty = isRecipientsEmpty;
    };

    setErrorMessage = (errorMessage: string) => {
        this.errorMessage = errorMessage;
    };
}
