import { useContext } from "react";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { Route } from "src/config/routes";
import styles from "./Footer.module.css";

const FooterBase = () => {
    const { authStore } = useContext(RootStoreContext);
    const location = useLocation();

    return (
        <footer
            className={
                authStore.user ||
                authStore.login ||
                authStore.relogin ||
                authStore.logout ||
                location.pathname === Route.logout
                    ? ""
                    : styles.loginFooter
            }
        ></footer>
    );
};

export const Footer = observer(FooterBase);
