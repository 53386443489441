import { observable, action, makeObservable } from "mobx";
import { AgendaItem } from "src/types";
import { RootStore } from "./RootStore";
import { FORM_STATUS } from "src/utils/Form";
import { getRoleKey } from "src/network/User";
import { NetworkConfig } from "src/network/NetworkConfig";

export class AssemblyStore {
    rootStore: RootStore;
    @observable isAddNewAgendaItem: boolean = false;
    @observable resetAgendaItems: boolean = false;
    @observable agendaItemsHaveChanged: boolean = false;
    @observable originalAgendaItems: AgendaItem[] = [];
    @observable agendaItems: AgendaItem[] = [];
    @observable unsavedAgendaItems?: AgendaItem[] = [];
    @observable isAssemblyInsertedEventLoaded: boolean = true;
    @observable isAgendaItemFilesExists: boolean = false;
    @observable isCopyAgendaItemsActive: boolean = false;
    @observable isAddingDocumentsDisplayed: boolean = false;
    @observable focusedAgendaItemIdText: string = "";
    @observable deletedAgendaItemIds: string[] = [];
    @observable ifAgendaItemFocusOrDocumentExists: boolean = false;
    @observable assemblyId: string = "";
    @observable insertAssemblyAgendaitems: any;
    @observable insertAgendaitemsTemplates: any;
    @observable showRefetchAssemblyData: boolean = false;
    @observable isAddNewChildAgendaItem: boolean = false;
    @observable selectedCopyAssemblyAgendaOption: number = 0;
    @observable isAssemblyFilesExists: boolean = false;
    @observable oldAssemblyWithGeneralDocuments: boolean = false;
    @observable isAgendaItemTemplate: boolean = false;
    @observable assembliesEditFormStatus: FORM_STATUS = FORM_STATUS.NONE;
    @observable assembliesEditFormHasDirtyFields: boolean = false;
    @observable isAssemblyPDFExists: boolean = false;
    @observable hasActiveCoowners: boolean = false;
    @observable isNewDescription: string = "";
    @observable focusedAgendaItemIdDescription: string = "";

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @action
    setIsAssemblyInsertedEventLoaded = (isAssemblyInsertedEventLoaded: boolean) => {
        this.isAssemblyInsertedEventLoaded = isAssemblyInsertedEventLoaded;
    };

    @action
    setIsAddNewAgendaItem = (isAddNewAgendaItem: boolean) => {
        this.isAddNewAgendaItem = isAddNewAgendaItem;
    };

    @action
    setIsAddNewChildAgendaItem = (isAddNewChildAgendaItem: boolean) => {
        this.isAddNewChildAgendaItem = isAddNewChildAgendaItem;
    };

    @action
    setResetAgendaItems = (resetAgendaItems: boolean) => {
        this.resetAgendaItems = resetAgendaItems;
    };

    @action
    updateSortAgendaItemList = () => {
        this.agendaItems.forEach((item, index) => {
            item.sort = index + 1;

            if (item.agendaitemchild) {
                item.agendaitemchild.forEach((childItem, childIndex) => {
                    childItem.sort = childIndex + 1;
                });
            }
        });

        this.agendaItems.sort((a, b) => a.sort - b.sort);
    };

    @action
    setOriginalAgendaItems = (agendaItems: AgendaItem[]) => {
        this.originalAgendaItems = agendaItems;
    };

    @action
    setCurrentAgendaItems = (agendaItems: AgendaItem[]) => {
        this.agendaItems = agendaItems;
        this.updateSortAgendaItemList();
    };

    @action
    setUnsavedAgendaItems = (unsavedAgendaItems: AgendaItem[]) => {
        this.unsavedAgendaItems = unsavedAgendaItems;
    };

    @action
    setAgendaItemsHaveChanged = () => {
        let agendaItemsHaveChanged = false;
        if (this.agendaItems.length === this.originalAgendaItems.length) {
            this.agendaItems.forEach((agendaItem, index) => {
                const originalAgendaItem = this.originalAgendaItems[index];

                if (
                    agendaItem.id !== originalAgendaItem.id ||
                    agendaItem.text !== originalAgendaItem.text ||
                    agendaItem.description !== originalAgendaItem.description ||
                    agendaItem.agendaitemchild?.length !== originalAgendaItem.agendaitemchild?.length
                ) {
                    agendaItemsHaveChanged = true;
                } else if (agendaItem.agendaitemchild) {
                    for (let i = 0; i < agendaItem.agendaitemchild.length; i++) {
                        if (
                            agendaItem.agendaitemchild[i]?.id !== originalAgendaItem.agendaitemchild?.[i]?.id ||
                            agendaItem.agendaitemchild[i]?.text !== originalAgendaItem.agendaitemchild?.[i]?.text ||
                            agendaItem.agendaitemchild[i]?.description !==
                                originalAgendaItem.agendaitemchild?.[i]?.description
                        ) {
                            agendaItemsHaveChanged = true;
                            break;
                        }
                    }
                }
            });
        } else {
            agendaItemsHaveChanged = true;
        }

        this.agendaItemsHaveChanged = agendaItemsHaveChanged;
    };

    @action
    setIsAgendaItemFilesExists = (isAgendaItemFilesExists: boolean) => {
        this.isAgendaItemFilesExists = isAgendaItemFilesExists;
    };

    @action
    setIsCopyAgendaItemsActive = (isCopyAgendaItemsActive: boolean) => {
        this.isCopyAgendaItemsActive = isCopyAgendaItemsActive;
    };

    @action
    setIsAddingDocumentsDisplayed = (isAddingDocumentsDisplayed: boolean) => {
        this.isAddingDocumentsDisplayed = isAddingDocumentsDisplayed;
    };

    @action
    setfocusedAgendaItemIdText = (focusedAgendaItemIdText: string) => {
        this.focusedAgendaItemIdText = focusedAgendaItemIdText;
    };

    @action
    setfocusedAgendaItemIdDescription = (focusedAgendaItemIdDescription: string) => {
        this.focusedAgendaItemIdDescription = focusedAgendaItemIdDescription;
    };

    @action
    addDeletedAgendaItemId = (deletedAgendaItemIds: string) => {
        this.deletedAgendaItemIds?.push(deletedAgendaItemIds);
    };

    @action
    setIfAgendaItemFocusOrDocumentExists = (ifAgendaItemFocusOrDocumentExists: boolean) => {
        this.ifAgendaItemFocusOrDocumentExists = ifAgendaItemFocusOrDocumentExists;
    };

    @action
    setAssemblyId = (assemblyId: string) => {
        this.assemblyId = assemblyId;
    };

    @action
    createInsertAssemblyAgendaitems = (language: string) => {
        const insertAssemblyAgendaitems: any = this.agendaItems.map((agendaItem) => {
            const agendaitemtempManager = agendaItem.agendaitemfiles.map((agendaitemfile) => {
                return {
                    id: agendaitemfile.id,
                    fileid: agendaitemfile.fileid,
                    roleid: 30,
                    visible: true
                };
            });
            const agendaitemtempCoowner = agendaItem.agendaitemfiles.map((agendaitemfile) => {
                return {
                    id: agendaitemfile.id,
                    fileid: agendaitemfile.fileid,
                    roleid: 32,
                    visible: true
                };
            });
            const newAgendaItemFilesList = agendaitemtempManager.concat(agendaitemtempCoowner);

            const newAgendaItemChild = agendaItem.agendaitemchild!.map((agendaitemchild) => {
                return {
                    id: agendaitemchild.id,
                    assemblyid: this.assemblyId,
                    sort: agendaitemchild.sort,
                    agendaitems_mls: {
                        data: {
                            language: language,
                            text: agendaitemchild.text,
                            description: agendaitemchild.description
                        },
                        on_conflict: {
                            constraint: "UNIQUE_agendaitem_agendaitem_mls",
                            update_columns: ["text", "language", "description"]
                        }
                    },
                    agendaitemfiles: {
                        data: newAgendaItemFilesList,
                        on_conflict: {
                            constraint: "PK_agendaitemfiles",
                            update_columns: []
                        }
                    }
                };
            });

            return {
                id: agendaItem.id,
                assemblyid: this.assemblyId,
                sort: agendaItem.sort,
                agendaitems_mls: {
                    data: {
                        language: language,
                        text: agendaItem.text,
                        description: agendaItem.description
                    },
                    on_conflict: {
                        constraint: "UNIQUE_agendaitem_agendaitem_mls",
                        update_columns: ["text", "language", "description"]
                    }
                },
                agendaitemfiles: {
                    data: newAgendaItemFilesList,
                    on_conflict: {
                        constraint: "PK_agendaitemfiles",
                        update_columns: []
                    }
                },
                agendaitems: {
                    data: newAgendaItemChild,
                    on_conflict: {
                        constraint: "PK_agendaitems",
                        update_columns: ["sort", "parentagendaitemid"]
                    }
                }
            };
        });

        this.insertAssemblyAgendaitems = insertAssemblyAgendaitems;
    };

    @action
    createInsertAgendaitemsTemplate = () => {
        const insertAgendaitemsTemplates: any = this.agendaItems.map((agendaItem) => {
            const newAgendaItemTemplateChild = agendaItem.agendaitemchild!.map((agendaitemchild) => {
                return {
                    id: agendaitemchild.id,
                    sort: agendaitemchild.sort,
                    agendaitemtemplates_mls: {
                        data: {
                            language: "de",
                            text: agendaitemchild.text,
                            description: agendaitemchild.description
                        },
                        on_conflict: {
                            constraint: "UNIQUE_agendaitemtemplate_mls",
                            update_columns: ["text", "description"]
                        }
                    }
                };
            });

            return {
                id: agendaItem.id,
                sort: agendaItem.sort,
                agendaitemtemplates_mls: {
                    data: {
                        language: "de",
                        text: agendaItem.text,
                        description: agendaItem.description
                    },
                    on_conflict: {
                        constraint: "UNIQUE_agendaitemtemplate_mls",
                        update_columns: ["text", "language", "description"]
                    }
                },
                subagendaitemtemplates: {
                    data: newAgendaItemTemplateChild,
                    on_conflict: {
                        constraint: "PK_agendaitemtemplates",
                        update_columns: ["sort", "parentagendaitemtemplateid"]
                    }
                }
            };
        });

        this.insertAgendaitemsTemplates = insertAgendaitemsTemplates;
    };

    @action
    setShowRefetchAssemblyData = (showRefetchAssemblyData: boolean) => {
        this.showRefetchAssemblyData = showRefetchAssemblyData;
    };

    @action
    setIsAgendaItemTemplate = (isAgendaItemTemplate: boolean) => {
        this.isAgendaItemTemplate = isAgendaItemTemplate;
    };

    @action
    setSelectedCopyAssemblyAgendaOption = (selectedCopyAssemblyAgendaOption: number) => {
        this.selectedCopyAssemblyAgendaOption = selectedCopyAssemblyAgendaOption;
    };

    @action
    setIsAssemblyFilesExists = (isAssemblyFilesExists: boolean) => {
        this.isAssemblyFilesExists = isAssemblyFilesExists;
    };

    @action
    setOldAssemblyWithGeneralDocuments = (oldAssemblyWithGeneralDocuments: boolean) => {
        this.oldAssemblyWithGeneralDocuments = oldAssemblyWithGeneralDocuments;
    };

    @action setAssembliesEditFormStatus = (newStatus: FORM_STATUS) => {
        this.assembliesEditFormStatus = newStatus;
    };

    @action setAssembliesEditFormHasDirtyFields = (hasDirtyFields: boolean) => {
        this.assembliesEditFormHasDirtyFields = hasDirtyFields;
    };

    @action
    setIsAssemblyPDFExists = (isAssemblyPDFExists: boolean) => {
        this.isAssemblyPDFExists = isAssemblyPDFExists;
    };

    @action
    generateAssemblyPDF = async (assemblyid: string): Promise<boolean> => {
        this.setIsAssemblyPDFExists(false);

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const response = await fetch(NetworkConfig.generateassemblypdf + assemblyid, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (response.status === 200) {
                this.setIsAssemblyPDFExists(true);
                this.rootStore.servicesStore.setRefetchServicesData(true);
            } else {
                console.error("Error generateassemblypdf: ", response);
            }
        } catch (error) {
            console.error("Error while trying to call generateassemblypdf: ", error);
        }

        return this.isAssemblyPDFExists;
    };

    @action
    setHasActiveCoowners = (countCoowner: number) => {
        this.hasActiveCoowners = countCoowner !== undefined && countCoowner > 0;
    };

    @action
    setIsNewDescription = (isNewDescription: string) => {
        this.isNewDescription = isNewDescription;
    };
}
