import React, { useContext, useState, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import styles from "./DisplayNewsBars.module.css";
import { RootStoreContext } from "src/stores/RootStore";
import { GetCoownerNews_realestates_newsitems_newsitem as newsitems } from "src/api/generated/GetCoownerNews";
import { getReactElementsFromHtmlString } from "src/utils/News";
import { DisplayNewsBarsAccordionItem } from "./news-accordion-item/DisplayNewsBarsAccordionItem";
import { NetworkConfig } from "src/network/NetworkConfig";
interface DisplayNewsBarsProperties {
    newsItems: newsitems[];
}

const INITIALLY_DISPLAYED_NEWS = 5;

const DisplayNewsBarsBase = (props: DisplayNewsBarsProperties) => {
    const { t } = useTranslation();
    const { uiStore } = useContext(RootStoreContext);
    const { newsItems } = props;

    const [displayedNewsItems, setDisplayedNewsItems] = useState<Array<newsitems>>(
        newsItems.length > INITIALLY_DISPLAYED_NEWS ? newsItems.slice(0, INITIALLY_DISPLAYED_NEWS) : newsItems
    );

    const loadMoreNewsItems = useCallback(() => {
        const currentNumberOfDisplayedNewsItems = displayedNewsItems.length;

        const sliceEnd =
            currentNumberOfDisplayedNewsItems + INITIALLY_DISPLAYED_NEWS > newsItems.length
                ? newsItems.length
                : currentNumberOfDisplayedNewsItems + INITIALLY_DISPLAYED_NEWS;

        setDisplayedNewsItems(newsItems.slice(0, sliceEnd));
    }, [displayedNewsItems.length, newsItems]);

    return displayedNewsItems.length > 0 ? (
        <>
            <div className={styles.NewsTitle}>{t("screens.realestate.coowner_dashboard.news_list_title")}</div>
            <UI.Accordion defaultActiveKey={uiStore.isMobile ? [] : ["0"]} alwaysOpen>
                {displayedNewsItems.map((newsItem, index) => {
                    const fileId = newsItem.newsfiles.length > 0 ? newsItem.newsfiles[0].fileid : undefined;
                    const imageUrl = fileId ? NetworkConfig.openThumbnailUrl + fileId : "";

                    const newsText =
                        newsItem.news.length > 0 && newsItem.news[0].text ? newsItem.news[0].text : undefined;

                    const formattedNewsText = newsText ? getReactElementsFromHtmlString(newsText) : undefined;

                    return (
                        <DisplayNewsBarsAccordionItem
                            key={`news-item-${index}`}
                            eventKey={index.toString()}
                            title={newsItem.news[0].title}
                            timestamp={newsItem.timestamp}
                            lead={newsItem.news[0].lead ?? undefined}
                            text={formattedNewsText}
                            imageUrl={imageUrl}
                            attachments={newsItem.attachments}
                            first={index === 0}
                        />
                    );
                })}
            </UI.Accordion>
            {newsItems.length > INITIALLY_DISPLAYED_NEWS ? (
                <div className={`${styles.NewsLoadMoreButton}`}>
                    <UI.Button
                        label={t("labels.more")}
                        variant="link"
                        onClick={loadMoreNewsItems}
                        disabled={displayedNewsItems.length >= newsItems.length}
                    />
                </div>
            ) : undefined}
        </>
    ) : (
        <UI.Row>
            <UI.Col>
                <UI.Card className={styles.InfoCard} title={"News"}>
                    <div className={styles.NoNews}>{t("screens.realestate.assembly.no_news")}</div>
                </UI.Card>
            </UI.Col>
        </UI.Row>
    );
};

export const DisplayNewsBars = Sentry.withProfiler(observer(DisplayNewsBarsBase));
