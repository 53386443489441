import React, { useCallback, useContext, useEffect, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { ScreenSize, UI } from "@wwimmo/ui";
import { InvoiceListRowDesktop } from "./InvoiceListRowDesktop";
import { InvoiceListRowMobile } from "./InvoiceListRowMobile";
import styles from "./InvoiceList.module.css";
import { Route, selectRoute } from "src/config/routes";
import { useQuery } from "@apollo/client";
import {
    GetRealestateUserAndAccessData,
    GetRealestateUserAndAccessData_realestateUsersAndAccess
} from "src/api/generated/GetRealestateUserAndAccessData";
import { GET_REALESTATEUSER_AND_ACCESS } from "src/api/ticket";
import { SCREEN_SIZE } from "src/stores/UIStore";
import { Invoice } from "src/stores/krediflow/InvoiceStore";
import { INVOICE_LIST_STATE, INVOICE_STATE } from "src/stores/krediflow/InvoiceEnums";

interface InvoiceListProps {
    onFilterChange: (invoiceListState: INVOICE_LIST_STATE) => void;
}

const InvoiceListBase = (props: InvoiceListProps) => {
    const { onFilterChange } = props;
    const { t } = useTranslation();
    const { uiStore, authStore, invoiceStore, invoiceListStore } = useContext(RootStoreContext);

    const { data } = useQuery<GetRealestateUserAndAccessData, GetRealestateUserAndAccessData_realestateUsersAndAccess>(
        GET_REALESTATEUSER_AND_ACCESS,
        {}
    );

    useEffect(() => {
        invoiceListStore.setRealestateUserData(data?.realestateUsersAndAccess || []);
    }, [invoiceListStore, data?.realestateUsersAndAccess]);

    const getColumnWidths = useCallback(() => {
        const columnWidthsDesktop = [0.06, 0.04, 0.1, 0.2, 0.1, 0.08, 0.1, 0.12, 0.1, 0.1];
        const columnWidthsLaptopL = [0, 0.05, 0.12, 0.24, 0.1, 0.1, 0.12, 0.15, 0.12, 0];
        const columnWidthsLaptop = [0, 0.04, 0.11, 0.29, 0.11, 0.11, 0.13, 0.21, 0, 0];
        const columnWidthsTablet = [0, 0.05, 0.14, 0.38, 0, 0.14, 0, 0.28, 0, 0];
        return uiStore.currentScreenSize > SCREEN_SIZE.LAPTOP_L
            ? columnWidthsDesktop
            : uiStore.currentScreenSize === SCREEN_SIZE.LAPTOP_L
            ? columnWidthsLaptopL
            : uiStore.currentScreenSize === SCREEN_SIZE.LAPTOP
            ? columnWidthsLaptop
            : columnWidthsTablet;
    }, [uiStore.currentScreenSize]);

    const columnWidths = getColumnWidths();

    const renderItem = useCallback(
        (invoice: Invoice, screenSize: ScreenSize) => {
            const columnWidths = getColumnWidths();

            const listRows = [
                screenSize === ScreenSize.MOBILE ? (
                    <InvoiceListRowMobile invoice={invoice} key={`row-${invoice.number}`} />
                ) : (
                    <InvoiceListRowDesktop invoice={invoice} key={`row-${invoice.number}`} widths={columnWidths} />
                )
            ];
            return (
                <UI.List.Item
                    key={`item-${invoice.number}`}
                    screenSize={screenSize}
                    rows={listRows}
                    className={styles.ListItem}
                    isClickableItemWithoutChevron
                    to={
                        invoice.number
                            ? selectRoute(Route.invoiceDetails, authStore.user?.role, {
                                  invoicenumber: String(invoice.number)
                              })
                            : Route.creditors
                    }
                />
            );
        },
        [authStore.user?.role, getColumnWidths]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            if (screenSize === ScreenSize.MOBILE) {
                return <></>;
            }

            const rows = [
                <UI.List.Cell
                    className={`${uiStore.currentScreenSize <= SCREEN_SIZE.LAPTOP_L ? "d-none" : "d-flex"}`}
                    key="type"
                    colspan={columnWidths[0]}
                    value=""
                    truncate
                />,
                <UI.List.Cell key="nr" colspan={columnWidths[1]} value={t("screens.kredi_flow.list.nr")} truncate />,
                <UI.List.Cell
                    key="invoiceDate"
                    colspan={columnWidths[2]}
                    value={
                        uiStore.currentScreenSize <= SCREEN_SIZE.LAPTOP
                            ? t("screens.kredi_flow.list.invoice_date_short")
                            : t("screens.kredi_flow.list.invoice_date")
                    }
                    truncate
                />,
                <UI.List.Cell
                    key="creditor"
                    colspan={columnWidths[3]}
                    value={t("screens.kredi_flow.list.creditor")}
                    truncate
                />,
                <UI.List.Cell
                    key="invoiceNumber"
                    className={`${uiStore.currentScreenSize <= SCREEN_SIZE.TABLET ? "d-none" : "d-flex"}`}
                    colspan={columnWidths[4]}
                    value={
                        uiStore.currentScreenSize === SCREEN_SIZE.DESKTOP
                            ? t("screens.kredi_flow.list.invoice_number")
                            : uiStore.currentScreenSize === SCREEN_SIZE.LAPTOP_L
                            ? t("screens.kredi_flow.list.invoice_number_short")
                            : t("screens.kredi_flow.list.invoice_number_shorter")
                    }
                    truncate
                />,
                <UI.List.Cell
                    key="amount"
                    colspan={columnWidths[5]}
                    value={t("screens.kredi_flow.list.amount")}
                    truncate
                />,
                <UI.List.Cell
                    key="dueDate"
                    className={`${uiStore.currentScreenSize <= SCREEN_SIZE.TABLET ? "d-none" : "d-flex"}`}
                    colspan={columnWidths[6]}
                    value={t("screens.kredi_flow.list.due_date")}
                    truncate
                />,
                <UI.List.Cell
                    key="realestate"
                    colspan={columnWidths[7]}
                    value={t("screens.kredi_flow.list.realestate")}
                    truncate
                />,
                <UI.List.Cell
                    key="assignedTo"
                    className={uiStore.currentScreenSize <= SCREEN_SIZE.LAPTOP ? "d-none" : "d-flex"}
                    colspan={columnWidths[8]}
                    value={t("screens.kredi_flow.list.assigned_to")}
                    truncate
                />,
                <UI.List.Cell
                    key="status"
                    className={`${uiStore.currentScreenSize <= SCREEN_SIZE.LAPTOP_L ? "d-none" : "d-flex"}`}
                    colspan={columnWidths[9]}
                    value={t("screens.kredi_flow.list.state")}
                    truncate
                />
            ];
            return <UI.List.Header rows={rows} className="p-0" />;
        },
        [columnWidths, uiStore.currentScreenSize, t]
    );

    const stats = invoiceListStore.invoiceStats;

    const onTabsChange = useCallback(
        (invoiceFilterStateId: string) => {
            const invoiceFilterStateIdNumber = Number(invoiceFilterStateId);
            onFilterChange(invoiceFilterStateIdNumber as INVOICE_LIST_STATE);
        },
        [onFilterChange]
    );
    const onSelectDropdown = useCallback(
        (invoiceFilterStateId: string) => {
            const invoiceFilterStateIdNumber = Number(invoiceFilterStateId);
            onFilterChange(invoiceFilterStateIdNumber as INVOICE_LIST_STATE);
        },
        [onFilterChange]
    );

    const filterStateItems = useMemo(
        () => [
            {
                label: `${t("screens.kredi_flow.list_state.new")} ${
                    invoiceStore.initialLoadAllInvoicesCompleted ? `(${stats.new})` : ""
                }`,
                id: String(INVOICE_LIST_STATE.NEW)
            },
            {
                label: `${t("screens.kredi_flow.list_state.to_approve")} ${
                    invoiceStore.initialLoadAllInvoicesCompleted ? `(${stats.toApprove})` : ""
                }`,
                id: String(INVOICE_LIST_STATE.TO_APPROVE)
            },
            {
                label: `${t("screens.kredi_flow.list_state.pay")} ${
                    invoiceStore.initialLoadAllInvoicesCompleted ? `(${stats.pay})` : ""
                }`,
                id: String(INVOICE_LIST_STATE.PAY)
            },
            {
                label: `${t("screens.kredi_flow.list_state.paid")} ${
                    invoiceStore.initialLoadAllInvoicesCompleted ? `(${stats.paid})` : ""
                }`,
                id: String(INVOICE_LIST_STATE.PAID)
            },
            {
                label: `${t("screens.kredi_flow.list_state.rejected")} ${
                    invoiceStore.initialLoadAllInvoicesCompleted ? `(${stats.rejected})` : ""
                }`,
                id: String(INVOICE_LIST_STATE.REJECTED)
            },
            {
                label: `${t("screens.kredi_flow.list_state.cancelled")} ${
                    invoiceStore.initialLoadAllInvoicesCompleted ? `(${stats.cancelled})` : ""
                }`,
                id: String(INVOICE_LIST_STATE.CANCELLED)
            },
            {
                label: `${t("labels.all")} ${
                    invoiceStore.initialLoadAllInvoicesCompleted ? `(${stats.total.filtered})` : ""
                }`,
                id: String(INVOICE_LIST_STATE.ALL)
            }
        ],
        [t, stats, invoiceStore.initialLoadAllInvoicesCompleted]
    );

    return (
        <UI.Card flex={true}>
            {uiStore.isMobile ? (
                <UI.Dropdown
                    id="sort"
                    onSelect={onSelectDropdown}
                    items={filterStateItems.map((item) => ({ label: item.label, value: item.id }))}
                    label={
                        filterStateItems.find((item) => Number(item.id) === invoiceListStore.invoiceFilter.state)
                            ?.label ?? ""
                    }
                    className="mb-2"
                />
            ) : (
                <div className={styles.Tabbar}>
                    <UI.Tabs
                        selectedId={String(invoiceListStore.invoiceFilter.state)}
                        onTabChange={onTabsChange}
                        contents={filterStateItems.map((item) => ({ ...item }))}
                        nowrap
                    ></UI.Tabs>
                </div>
            )}
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={invoiceListStore.filteredInvoices.filter((invoice) => {
                    if (invoiceListStore.invoiceFilter.state === INVOICE_LIST_STATE.ALL) {
                        return true;
                    } else {
                        const invoiceState: INVOICE_STATE | undefined = invoice.state;
                        let convertedInvoiceListFilterState: INVOICE_LIST_STATE | undefined = undefined;

                        if (invoiceState) {
                            convertedInvoiceListFilterState =
                                invoiceListStore.convertInvoiceStateToInvoiceListState(invoiceState);
                        }

                        const currentInvoiceListFilterState: INVOICE_LIST_STATE = invoiceListStore.invoiceFilter.state;

                        return currentInvoiceListFilterState === convertedInvoiceListFilterState;
                    }
                })}
                renderItem={renderItem}
                renderHeader={renderHeader}
                itemsPerPage={20}
                emptyListMessage={
                    invoiceStore.invoices.length === 0
                        ? t("screens.tickets.list.tickets_are_loading").toString()
                        : t("screens.tickets.list.no_tickets_found").toString()
                }
            />
        </UI.Card>
    );
};

export const InvoiceList = Sentry.withProfiler(observer(InvoiceListBase));
