import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { IUnitData } from "./krediflow/InvoiceTypes";
import { GET_UNIT_DATA_BY_REALESTATEID } from "src/api/ticket";
import {
    GetUnitDataByRealestateId,
    GetUnitDataByRealestateIdVariables
} from "src/api/generated/GetUnitDataByRealestateId";

export class UnitSearchStore {
    rootStore: RootStore;

    unitData: IUnitData[] = [];
    currentUnitSearchQuery: string = "";
    selectedUnitQueryString: string | undefined = undefined;
    triggerFocusOnUnitInput: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            unitData: observable,
            currentUnitSearchQuery: observable,
            selectedUnitQueryString: observable,
            triggerFocusOnUnitInput: observable,

            setUnitData: action,
            loadUnitData: action,
            setCurrentUnitSearchQuery: action,
            setSelectedUnitQueryString: action,
            setTriggerFocusOnUnitInput: action,

            filteredUnitData: computed
        });
    }

    loadUnitData = async (realestateId: string) => {
        const { data }: { data: GetUnitDataByRealestateId } = await apolloClientInstance.query<
            GetUnitDataByRealestateId,
            GetUnitDataByRealestateIdVariables
        >({
            query: GET_UNIT_DATA_BY_REALESTATEID,
            variables: {
                realestateid: realestateId
            }
        });

        const unitData: IUnitData[] = [];

        data.houses.forEach((house) => {
            house.units.forEach((unit) => {
                unitData.push({
                    houseId: house.id,
                    unitId: unit.id,
                    unitNumber: unit.number ?? undefined,
                    unitName: unit.name ?? ""
                });
            });
        });

        // Sorting units by name
        unitData.sort((unitA, unitB) => {
            if (unitA.unitName < unitB.unitName) {
                return -1;
            }
            if (unitA.unitName > unitB.unitName) {
                return 1;
            }
            return 0;
        });

        this.setUnitData(unitData);
    };

    getFormattedUnitName = (unit: IUnitData) => {
        const unitName = `${unit.unitName}${unit.unitNumber ? ` [${unit.unitNumber}]` : ""}`;

        return unitName;
    };

    setUnitData = (unitData: IUnitData[]) => {
        this.unitData = unitData;
    };

    setCurrentUnitSearchQuery = (currentSearchQuery: string) => {
        this.currentUnitSearchQuery = currentSearchQuery;
    };

    setSelectedUnitQueryString = (selectedUnitQueryString: string | undefined) => {
        this.selectedUnitQueryString = selectedUnitQueryString;
    };

    setTriggerFocusOnUnitInput = (triggerFocusOnUnitInput: boolean) => {
        this.triggerFocusOnUnitInput = triggerFocusOnUnitInput;
    };

    get filteredUnitData(): IUnitData[] {
        const searchQuery = this.currentUnitSearchQuery ?? "";

        if (this.currentUnitSearchQuery === this.selectedUnitQueryString) {
            return this.unitData;
        }

        if (searchQuery === "") {
            return this.unitData;
        }

        const filteredUnitData = this.unitData.filter((unit) => {
            const unitName = `${unit.unitName}${unit.unitNumber ? ` [${unit.unitNumber}]` : ""}`;
            return unitName.toLowerCase().includes(searchQuery.toLowerCase());
        });

        return filteredUnitData;
    }
}
