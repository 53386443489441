import React, { useState, useRef, useEffect, useCallback } from "react";
import { throttle } from "lodash";
import "./Colorpicker.css";
import { UI } from "@wwimmo/ui";

interface ColorpickerProps {
    value: string;
    onChange: any;
    height?: number;
}

export const Colorpicker = (props: ColorpickerProps) => {
    const [color, setColor] = useState<string>(props.value);
    useEffect(() => {
        setColor(props.value);
    }, [props.value]);
    const colorChange = (color: string) => {
        props.onChange(color);
    };
    const delayedColorChange = useRef(throttle((c: any) => colorChange(c), 200)).current;
    const onChangeColorpicker = useCallback(
        (event: any) => {
            const newColor = event.target.value;
            setColor(newColor);
            delayedColorChange(newColor);
        },
        [delayedColorChange, setColor]
    );
    return (
        <div className="Colorpicker">
            <input
                className="w-100 h-100"
                style={{ background: "none", position: "absolute" }}
                type="color"
                value={color}
                onChange={onChangeColorpicker}
            />
            <div className="Overlay" style={{ backgroundColor: color }}>
                <UI.Icon
                    icon={UI.SVGIcon.Edit}
                    style={{ position: "absolute", top: "50%", padding: 0, height: 0 }}
                    color="white"
                />
            </div>
        </div>
    );
};
