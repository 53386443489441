import React, { useEffect, useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import * as Screens from "src/screens";
import classes from "./InvoicedRentalVariableDetails.module.css";
import { GET_CUSTOMER_INVOICED_RENTALS_VARIABLE } from "src/api/customer";
import { GetCustomerInvoicedRentalsVariable } from "src/api/generated/GetCustomerInvoicedRentalsVariable";
import { getDateWithMonthAndYear } from "src/utils/Date";
import { Route, selectRoute } from "src/config/routes";
import { useLocation } from "react-router-dom";
import RentalsVariableDetailsList from "./list/RentalVariableDetailsList";

interface Params {
    year: string;
    month: string;
}

const InvoicedRentalVariableDetailsBase = () => {
    const { navStore, authStore } = useContext(RootStoreContext);
    const { t } = useTranslation();
    const { year: yearUrl, month: monthUrl } = useParams<Params>();
    const location = useLocation();

    const currentDate = new Date();    
    
    const onHistoryPath = location.pathname.includes(Route.servicesCostsAndInvoicesHistory);

    const year = onHistoryPath ? Number(yearUrl): currentDate.getFullYear();
    const month = onHistoryPath ? Number(monthUrl): currentDate.getMonth() + 1;

    const variables = {
        year,
        month
    };

    const dateForTitle = new Date(year, month, 0).toString();

    const { data, loading, error } = useQuery<GetCustomerInvoicedRentalsVariable>(GET_CUSTOMER_INVOICED_RENTALS_VARIABLE, {
        variables
    });

    useEffect(() => {
        navStore.setTitle("");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);

        if (onHistoryPath) {
            const date = new Date(Number(year), Number(month) - 1).toString();

            navStore.setBackbutton(
                selectRoute(Route.servicesCostsAndInvoicesHistoryInvoiceDetailsMonth, authStore.user?.role, {
                    year: yearUrl,
                    month: monthUrl
                }),
                getDateWithMonthAndYear(date)
            );
        } else {
            navStore.setBackbutton(
                selectRoute(Route.servicesCostsAndInvoicesCurrentMonth, authStore.user?.role),
                t("screens.services.costs_and_invoices.current_month.title").toString()
            );
        }
    }, [navStore, authStore.user?.role, location.pathname, month, t, year, onHistoryPath, yearUrl, monthUrl]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Title>{`${
                         t("screens.services.costs_and_invoices.invoiced_rentals_variable.title", {
                              monthyear: getDateWithMonthAndYear(dateForTitle)
                          })
                }`}</UI.Title>
                <UI.Row>
                    <UI.Col md={12}>
                        <UI.Card>
                            <div className={classes.customerList}>
                                <RentalsVariableDetailsList
                                    rentals={data.rentals_variable}
                                />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const InvoicedRentalVariableDetails = Sentry.withProfiler(observer(InvoicedRentalVariableDetailsBase));
