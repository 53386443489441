import { useContext } from "react";
import { RootStoreContext } from "src/stores/RootStore";

export const useListWithStore = (listIdentifier: string) => {
    const { navStore } = useContext(RootStoreContext);

    const urlParams = new URLSearchParams(window.location.search);

    const searchParameter = urlParams.get("search");
    const filterParameter = urlParams.get("filter");
    const sortParameter = urlParams.get("sort");

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    let currentSearchQuery: string | undefined = undefined;

    if (!searchParameter && listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    }

    let currentFilterQuery: string | undefined = undefined;

    if (!filterParameter && listParameters && listParameters.filterValue) {
        currentFilterQuery = listParameters.filterValue;
    }

    let currentSortQuery: string | undefined = undefined;

    if (!sortParameter && listParameters && listParameters.sortValue) {
        currentSortQuery = listParameters.sortValue;
    }

    return {
        listParameters,
        currentSearchQuery,
        currentFilterQuery,
        currentSortQuery
    };
};
