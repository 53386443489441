import React, { useEffect, useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { useQuery } from "@apollo/client";
import * as Screens from "src/screens";
import { useTranslation } from "react-i18next";
import { GET_TENANCYS_BY_PERSON_ID } from "src/api/person";
import {
    CommonPersonTenancys,
    CommonPersonTenancysVariables,
    CommonPersonTenancys_persons_tenants_tenancys as common_tenancy
} from "src/api/generated/CommonPersonTenancys";
import { selectRoute, Route } from "src/config/routes";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { runInAction } from "mobx";
import { UI, ScreenSize } from "@wwimmo/ui";
import { observer } from "mobx-react-lite";

interface PersonParams {
    personid: string;
}

const PersonBase = (props: RouteComponentProps<PersonParams>) => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);

    useEffect(() => {
        runInAction(() => {
            navStore.setBackbutton(null);
            navStore.setCurrentSubTitle(null);
            navStore.resetActionButtons();
        });
    });

    const { loading, error, data } = useQuery<CommonPersonTenancys, CommonPersonTenancysVariables>(
        GET_TENANCYS_BY_PERSON_ID,
        {
            variables: {
                personid: props.match.params.personid
            }
        }
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            const headerRows: React.ReactElement[] = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={1} value={t("screens.realestate.title")} />
                </UI.List.Row>
            ];

            return <UI.List.Header rows={headerRows} />;
        },
        [t]
    );

    const renderItem = useCallback(
        (tenancy: common_tenancy, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];

            listRows = [
                <UI.List.Row key={"r-1"}>
                    <UI.List.Cell key={"c-1"} colspan={1} value={tenancy.unit?.realestate?.name || "-"} className="" />
                </UI.List.Row>,
                <UI.List.Row key={"r-2"}>
                    <UI.List.Cell key={"c-1"} colspan={1} value={tenancy.unit?.name || "-"} className="sublabel" />
                </UI.List.Row>
            ];

            const link = selectRoute(Route.unit, authStore.user?.role, {
                realestateid: tenancy.unit?.realestate?.id,
                unitid: tenancy.unit?.id
            });

            return <UI.List.Item key={tenancy.id} screenSize={screenSize} rows={listRows} to={link} />;
        },
        [authStore.user?.role]
    );

    if (loading) return <Screens.Loading />;
    if (error) return <Screens.Error message={error.message} networkError={error.networkError} />;
    if (!data || data.persons.length === 0) return <Screens.Error message={t("error.nodata")} />;

    const person = data.persons[0];
    navStore.setTitle(`${person.name1} ${person.name2}`);

    const tenancys: common_tenancy[] = [];
    if (person.tenants.length > 0) {
        person.tenants[0].tenancys.forEach((tenancy) => tenancys.push(tenancy));
    }
    if (person.tenancypersons.length > 0) {
        person.tenancypersons
            .map((tenancyperson) => tenancyperson.tenancy)
            .forEach((tenancy) => {
                if (tenancy !== null && !tenancys.some((t) => t.id === tenancy.id)) {
                    tenancys.push(tenancy);
                }
            });
    }

    if (tenancys.length > 1) {
        return (
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
                        <UI.Card>
                            <UI.List.BasicList
                                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                                items={tenancys}
                                renderItem={renderItem}
                                renderHeader={renderHeader}
                            />
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        );
    } else if (tenancys.length === 1) {
        return (
            <Redirect
                to={selectRoute(Route.unit, authStore.user?.role, {
                    realestateid: tenancys[0].unit?.realestate?.id,
                    unitid: tenancys[0].unit?.id
                })}
            />
        );
    } else {
        // No tenancys
        return <Screens.Error message={t("error.emptydata")} />;
    }
};

export const Person = Sentry.withProfiler(observer(PersonBase));
