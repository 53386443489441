import { UI } from "@wwimmo/ui";
import { useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";

interface ManualEmailButtonProps {
    onClick: () => void;
}

const ManualEmailButtonBase = (props: ManualEmailButtonProps) => {
    const { onClick } = props;
    const { ticketStore, authStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const onClickManualEmailButton = useCallback(() => {
        onClick();
    }, [onClick]);

    const isAssignedToCurrentUser =
        authStore.user?.userid && authStore.user?.userid === ticketStore.currentTicket?.assignedUser?.id;

    return (
        <UI.Button
            label={t("labels.email")}
            onClick={onClickManualEmailButton}
            disabled={ticketStore.isEditing || !isAssignedToCurrentUser}
            className="ticket-button"
        />
    );
};

export const ManualEmailButton = Sentry.withProfiler(observer(ManualEmailButtonBase));
