import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useSelectionListKeyHandler } from "src/hooks/ticket/selection-list-key-handler/useSelectionListKeyHandler";
import { boldenMatchedSubstring } from "src/utils/Common";
import styles from "./UnitFormField.module.css";
import "./GeneralInvoiceFormStyles.css";
import { IUnitData } from "src/stores/krediflow/InvoiceTypes";

const UnitFormFieldBase = () => {
    const { t } = useTranslation();

    const { unitSearchStore, invoiceStore } = useContext(RootStoreContext);

    const [displayUnitList, setDisplayUnitList] = useState<boolean>(false);
    const [lastScrollPositionYUnitItems, setLastSrollYPositionUnitItems] = useState<number>(0);

    const unitInputRef = useRef<HTMLInputElement>(null);

    const resetSearchQuery = useCallback(() => {
        unitSearchStore.setCurrentUnitSearchQuery(unitSearchStore.selectedUnitQueryString ?? "");
    }, [unitSearchStore]);

    useEffect(() => {
        const handleClickOutsideUnitInput = (event: any) => {
            if (
                displayUnitList &&
                unitInputRef.current &&
                !unitInputRef.current.contains(event.target) &&
                !(event.target.classList.contains("list-item") && event.target.classList.contains("unit")) &&
                !(event.target.classList.contains("highlighted-text") && event.target.classList.contains("unit"))
            ) {
                setDisplayUnitList(false);

                if (
                    invoiceStore.currentInvoice?.isUnitSelected &&
                    unitSearchStore.currentUnitSearchQuery !== unitSearchStore.selectedUnitQueryString
                ) {
                    invoiceStore.resetUnitFormField();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutsideUnitInput);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideUnitInput);
        };
    }, [
        displayUnitList,
        resetSearchQuery,
        unitSearchStore.currentUnitSearchQuery,
        unitSearchStore.selectedUnitQueryString,
        invoiceStore
    ]);

    useEffect(() => {
        if (unitSearchStore.triggerFocusOnUnitInput) {
            unitInputRef.current?.focus();
            unitSearchStore.setTriggerFocusOnUnitInput(false);
        }
        // This useEffect only listens to changes in invoiceStore.triggerFocusOnUnitInput
        // eslint-disable-next-line
    }, [unitSearchStore.triggerFocusOnUnitInput]);

    const updateInvoiceStoreWithSelectedUnit = useCallback(
        async (unit: IUnitData) => {
            invoiceStore.updateInvoiceAndFormWithSelectedUnit(unit);
        },
        [invoiceStore]
    );

    const onSelectUnitItem = useCallback(
        (activeListItemIndex: number) => {
            const selectedUnitItem = unitSearchStore.filteredUnitData[activeListItemIndex];

            const unit: IUnitData = {
                houseId: selectedUnitItem.houseId,
                unitNumber: selectedUnitItem.unitNumber,
                unitId: selectedUnitItem.unitId,
                unitName: selectedUnitItem.unitName
            };

            updateInvoiceStoreWithSelectedUnit(unit);
        },
        [updateInvoiceStoreWithSelectedUnit, unitSearchStore.filteredUnitData]
    );

    const { onKeyDownFunction, setActiveListItemIndex, NO_LIST_ITEM_SELECTED } = useSelectionListKeyHandler({
        listName: "unit",
        totalNumberOfDisplayedItems: invoiceStore.numberOfDisplayedItems,
        setIsListDisplayed: setDisplayUnitList,
        isListDisplayed: displayUnitList,
        onSelectItem: onSelectUnitItem,
        resetSearchQuery: resetSearchQuery
    });

    const resetAmountOfItemsDisplayedInUnitList = useCallback(() => {
        setLastSrollYPositionUnitItems(0);
        invoiceStore.setNumberOfDisplayedItems(invoiceStore.sliceSizeOfDisplayedItems);
    }, [invoiceStore]);

    const onChangeUnitInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            resetAmountOfItemsDisplayedInUnitList();

            const inputUnit = e.target.value;
            unitSearchStore.setCurrentUnitSearchQuery(inputUnit);
        },
        [unitSearchStore, resetAmountOfItemsDisplayedInUnitList]
    );

    const onFocusUnitInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (invoiceStore.currentInvoice?.isUnitSelected && unitInputRef.current) {
                unitInputRef.current.select();
            }

            resetAmountOfItemsDisplayedInUnitList();
            setDisplayUnitList(true);
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
        },
        [
            resetAmountOfItemsDisplayedInUnitList,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            invoiceStore.currentInvoice?.isUnitSelected
        ]
    );

    const onClickUnitItem = useCallback(
        (unitData: IUnitData) => async (e: any) => {
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
            setDisplayUnitList(false);

            const unit: IUnitData = {
                houseId: unitData.houseId ?? "",
                unitNumber: unitData.unitNumber ?? undefined,
                unitId: unitData.unitId ?? "",
                unitName: unitData.unitName ?? ""
            };

            updateInvoiceStoreWithSelectedUnit(unit);
        },
        [updateInvoiceStoreWithSelectedUnit, setActiveListItemIndex, NO_LIST_ITEM_SELECTED]
    );

    const onScrollUnitList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYUnitItems;

            const hasMoreResulsToDisplay =
                invoiceStore.numberOfDisplayedItems < unitSearchStore.filteredUnitData.length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                invoiceStore.setNumberOfDisplayedItems(
                    invoiceStore.numberOfDisplayedItems + invoiceStore.sliceSizeOfDisplayedItems
                );
            }

            setLastSrollYPositionUnitItems(pixelsFromTop);
        },
        [lastScrollPositionYUnitItems, invoiceStore, unitSearchStore.filteredUnitData.length]
    );

    const unitSelectionList = (
        <ul id="unit-dropdown-list" className={"dropdown-list unit"} onScroll={onScrollUnitList}>
            {unitSearchStore.filteredUnitData.slice(0, invoiceStore.numberOfDisplayedItems).map((unit, index) => {
                const unitName = unitSearchStore.getFormattedUnitName(unit);

                const formattedUnitName = boldenMatchedSubstring(
                    unitSearchStore.currentUnitSearchQuery ?? "",
                    unitName,
                    "highlighted-text unit"
                );

                return (
                    <li key={index}>
                        <div
                            className={"list-item unit"}
                            id={`unit-list-item-${index}`}
                            onClick={onClickUnitItem(unit)}
                            dangerouslySetInnerHTML={{
                                __html: formattedUnitName
                            }}
                        />
                    </li>
                );
            })}
        </ul>
    );

    const readOnlyUnitElement = invoiceStore.currentInvoice?.isUnitSelected ? (
        <div className="view-only-container">
            <div className="bold-text mb-1">{t("screens.unit.object")}</div>
            <div>
                <span className="text-with-number">{invoiceStore.currentInvoice?.unit?.unitName}</span>
                <span>{`${
                    invoiceStore.currentInvoice?.unit?.unitNumber
                        ? `[${invoiceStore.currentInvoice?.unit?.unitNumber}]`
                        : ""
                }`}</span>
            </div>
        </div>
    ) : (
        <></>
    );

    return (
        <>
            {invoiceStore.isEditing ? (
                <>
                    <UI.Input
                        ref={unitInputRef}
                        label={t("screens.unit.object").toString()}
                        type="text"
                        autoComplete="off"
                        className={styles.UnitInputField}
                        id="invoice-form-unit-input"
                        value={unitSearchStore.currentUnitSearchQuery ?? ""}
                        onChange={onChangeUnitInput}
                        onFocus={onFocusUnitInput}
                        onKeyDown={onKeyDownFunction}
                        disabled={!invoiceStore.isEditing || !invoiceStore.currentInvoice?.isRealestateSelected}
                    />
                    {displayUnitList ? unitSelectionList : undefined}
                </>
            ) : (
                readOnlyUnitElement
            )}
        </>
    );
};

export const UnitFormField = Sentry.withProfiler(observer(UnitFormFieldBase));
