import React, { useContext, useState, useCallback, useRef } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { ColorStyle } from "src/utils/Colors";
import styles from "./TicketHistoryTimelineEntry.module.css";
import { HISTORY_TYPE, IHistory } from "src/stores/TicketStore";
import DOMPurify from "dompurify";
import { NetworkConfig } from "src/network/NetworkConfig";
import { Tooltip } from "src/components/tooltip/Tooltip";

interface TicketHistoryTimelineEntryProps {
    historyEntry: IHistory;
}

const TicketHistoryTimelineEntryBase = (props: TicketHistoryTimelineEntryProps) => {
    const { t } = useTranslation();
    const { uiStore } = useContext(RootStoreContext);

    const { historyEntry } = props;

    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);

    const errorTextRef = useRef(null);

    const toggleCollapse = useCallback(() => {
        setCollapsed((prevState) => !prevState);
    }, []);

    const toggleModal = useCallback(() => {
        setShowModal((prevState) => !prevState);
    }, []);

    const parseAndSanitizeEmailHtml = (html?: string) => {
        if (!html) return { cleanHtml: "", htmlStyles: "" };

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");

        // Rename class "row" to "tableRow" for <tr> elements - the 'row' class is already used by bootstrap
        doc.querySelectorAll("tr.row").forEach((tr) => {
            tr.classList.remove("row");
            tr.classList.add("tableRow");
        });

        // Extract styles
        let htmlStyles = "";
        doc.querySelectorAll("style").forEach((style) => {
            // Replace all occurrences of ".row" with ".tableRow" in CSS
            let modifiedCss = style.textContent?.replace(/\.row(?=[\s,.:{])/g, ".tableRow");
            htmlStyles += modifiedCss;
        });

        const additionalTableStyles = `
            table {
                table-layout: fixed;
            }
            td.label {
                width: 30%;
            }
            td.value {
                width: 70%;
            }
        `;

        htmlStyles += additionalTableStyles;

        const cleanHtml = DOMPurify.sanitize(doc.body.innerHTML);

        return { cleanHtml, htmlStyles };
    };

    const isComment =
        historyEntry.type === HISTORY_TYPE.INCIDENT_COMMENT || historyEntry.type === HISTORY_TYPE.ORDER_COMMENT;

    const isCreationEntry =
        historyEntry.type === HISTORY_TYPE.INCIDENT_CREATED || historyEntry.type === HISTORY_TYPE.ORDER_CREATED;

    const isEmail =
        historyEntry.type === HISTORY_TYPE.ORDER_EMAIL_OUTBOUND ||
        historyEntry.type === HISTORY_TYPE.INCIDENT_EMAIL_OUTBOUND ||
        historyEntry.type === HISTORY_TYPE.INCIDENT_EMAIL_INBOUND ||
        historyEntry.type === HISTORY_TYPE.ORDER_EMAIL_INBOUND;

    const isChangeEntry = !isComment && !isEmail;

    let firstChangeEntryNewValue = "";

    const changeTable = (
        <div className={styles.HistoryEntryChanges}>
            {uiStore.isMobile ? undefined : (
                <div className={styles.HeaderRow}>
                    <div className={styles.Column}> {t("labels.designation")}</div>

                    {isCreationEntry ? undefined : (
                        <div className={styles.Column}> {t("screens.tickets.history.old_value")}</div>
                    )}

                    <div className={styles.Column}>
                        {t(`screens.tickets.history.${isCreationEntry ? "value" : "new_value"}`)}
                    </div>
                </div>
            )}

            {historyEntry.changeEntry && historyEntry.changeEntry?.length > 0
                ? historyEntry.changeEntry.map((entry, index) => {
                      if (index === 0) {
                          firstChangeEntryNewValue = entry.newValue;
                      }

                      return (
                          <div key={index} className={styles.BodyRow}>
                              <div className={styles.Column}>{entry.description}</div>

                              {/* Old Value */}
                              {isCreationEntry ? undefined : (
                                  <div className={`${styles.Column} ${uiStore.isMobile ? styles.OldValueMobile : ""}`}>
                                      {entry.oldValue}
                                  </div>
                              )}

                              {/* New Value */}
                              <div className={styles.Column}>{entry.newValue}</div>
                          </div>
                      );
                  })
                : undefined}
        </div>
    );

    const { cleanHtml, htmlStyles } = parseAndSanitizeEmailHtml(historyEntry.email?.body ?? "");

    const historyMailEntry = (
        <div className={styles.HistoryMailEntry}>
            <div>
                <span className="bold">{`${t("screens.tickets.history.email.from")}: `}</span>
                {historyEntry.email?.sender}
            </div>
            <div>
                <span className="bold">{`${t("screens.tickets.history.email.to")}: `}</span>
                {historyEntry.email?.recipients}
            </div>
            <div className={styles.HistoryMailEntryBody}>
                <style dangerouslySetInnerHTML={{ __html: htmlStyles }} />
                <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />
            </div>
        </div>
    );

    const iconElement = isEmail ? (
        <UI.Icon icon={UI.SVGIcon.Mail} color={ColorStyle("primary")} className={styles.HistoryEntryPersonIcon} />
    ) : historyEntry.user !== "Workflow" ? (
        <UI.Icon
            icon={UI.SVGIcon.Profile}
            color={ColorStyle("primary")}
            size={25}
            className={styles.HistoryEntryPersonIcon}
        />
    ) : undefined;

    const hasAttachments =
        historyEntry.email && historyEntry.email.attachments && historyEntry.email.attachments?.length > 0;

    const hasOutboundSendingErrors = historyEntry.email?.sendingErrors && historyEntry.email.sendingErrors.length > 0;
    let outboundSendingErrors = undefined;

    if (hasOutboundSendingErrors) {
        outboundSendingErrors = historyEntry.email?.sendingErrors;
    }

    const outboundSendingErrorsTooltip = (
        <Tooltip targetRef={errorTextRef} classes={styles.SendingErrorTooltip}>
            {outboundSendingErrors && outboundSendingErrors.length > 0 ? (
                outboundSendingErrors.map((error, index, self) => {
                    let errorText = "";

                    if (error.event) {
                        switch (error.event) {
                            case "failed":
                                errorText = t("screens.tickets.history.email.errors.failed");
                                break;
                            case "bounce":
                                errorText = t("screens.tickets.history.email.errors.bounce");
                                break;
                            case "dropped":
                                errorText = t("screens.tickets.history.email.errors.dropped");
                                break;
                            case "deferred":
                                errorText = t("screens.tickets.history.email.errors.deferred");
                                break;
                            default:
                                errorText = t("screens.tickets.history.email.errors.default");
                                break;
                        }
                    }

                    if (error.message && error.message.includes("unrecognized address")) {
                        errorText = t("screens.tickets.history.email.errors.invalid_recipient");
                    }

                    return (
                        <div key={index}>
                            {/* <div key={index}> */}
                            <div>
                                <div>
                                    <strong>{t("screens.tickets.history.email.recipient")}:</strong> {error.recipient}
                                </div>
                                <div>
                                    <strong>{t("screens.tickets.history.email.error")}:</strong> {errorText}
                                </div>
                                <div>
                                    <strong>{t("screens.tickets.history.email.error_detail")}:</strong> {error.message}
                                </div>
                            </div>
                            {self.length - 1 !== index ? <hr /> : <></>}
                        </div>
                    );
                })
            ) : (
                <></>
            )}
        </Tooltip>
    );

    const historyEntryTitleRow = (
        <div className={styles.HistoryEntryTitleRow}>
            {iconElement}

            <div className={styles.HistoryEntryPersonName}>{historyEntry.user}</div>
            <div className={styles.HistoryEntryDash}>-</div>
            <div>{historyEntry.date}</div>
            {hasAttachments ? (
                <div className="ml-auto">
                    <UI.Icon icon={UI.SVGIcon.Paperclip} onClick={toggleModal} color={ColorStyle("primary")} />
                </div>
            ) : undefined}
            {hasOutboundSendingErrors ? (
                <>
                    <div className="ml-auto d-flex align-items-center">
                        <div ref={errorTextRef} className="d-flex">
                            <UI.Icon icon={UI.SVGIcon.InformationFilled} color={ColorStyle("#ff0000")} />
                        </div>
                        <div className="error-text">{t("screens.tickets.history.email.sending_failed")}</div>
                        {outboundSendingErrorsTooltip}
                    </div>
                </>
            ) : undefined}
        </div>
    );

    return (
        <div className={styles.HistoryEntryContainer}>
            <div
                className={`${styles.HistoryEntryContent} ${isComment || isEmail ? styles.GreyBackground : ""} ${
                    isEmail ? styles.Email : ""
                } ${isChangeEntry ? styles.ChangeEntry : ""}   ${collapsed ? styles.Collapsed : ""} `}
            >
                {historyEntryTitleRow}

                {!isEmail ? (
                    <div className={styles.HistoryEntryChangeText}>
                        {historyEntry.changeText}
                        {isChangeEntry ? (
                            <span>
                                : <span className="bold">{firstChangeEntryNewValue}</span>
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                ) : (
                    historyMailEntry
                )}

                {isChangeEntry ? changeTable : undefined}

                {isEmail || isChangeEntry ? (
                    <div
                        className={`${styles.HistoryEntryButtonContainer} ${isEmail ? styles.GreyBackground : ""}`}
                        onClick={toggleCollapse}
                    >
                        <button className={`${styles.HistoryEntryButton}`} />
                    </div>
                ) : undefined}
            </div>
            <UI.Modal show={showModal} onClose={toggleModal} isScrollable={true}>
                <div className={`mb-3 ${styles.ModalTitle}`}>Anhänge</div>
                {historyEntry.email?.attachments?.map((attachment, index) => {
                    return (
                        <div key={index} className="d-flex justify-content-between align-items-center">
                            <div>{attachment.fileName}</div>
                            <div>
                                <a
                                    href={NetworkConfig.datafileUrl + attachment.fileId}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <UI.Icon icon={UI.SVGIcon.Documents} color={ColorStyle("primary")} />
                                </a>
                            </div>
                        </div>
                    );
                })}
                <div className="text-center">
                    <UI.Button className={"mt-3"} label={t("labels.cancel")} onClick={toggleModal} variant="link" />
                </div>
            </UI.Modal>
        </div>
    );
};

export const TicketHistoryTimelineEntry = Sentry.withProfiler(observer(TicketHistoryTimelineEntryBase));
