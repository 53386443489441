export const getName = (
    name1: string | null | undefined,
    name2: string | null | undefined,
    legal: boolean | null | undefined
) => {
    let name = undefined;

    if (legal) {
        name = (
            <>
                <span className={name1 ? "d-block" : ""}>{name1 ?? ""}</span>
                <span className={name2 ? "d-block" : ""}>{name2 ?? ""}</span>
            </>
        );
    } else {
        name = `${name1 ?? ""} ${name2 ?? ""}`;
    }

    return name;
};
