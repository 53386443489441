import { GetNewsPreviewInformationById_news } from "src/api/generated/GetNewsPreviewInformationById";
import { getDateWithLongMonthAndTime } from "src/utils/Date";
import { useTranslation } from "react-i18next";
import { InfoCard } from "src/components/infoCard/InfoCard";
import { useNewsInsertedEventSubscription } from "src/hooks/news/useNewsInsertedEventSubscription";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { RootStoreContext } from "src/stores/RootStore";

interface NewsInfoTileProps {
    news?: GetNewsPreviewInformationById_news;
}

const NewsInfoTileBase = (props: NewsInfoTileProps) => {
    const { news } = props;
    const { t } = useTranslation();
    const { uiStore, notificationStore } = useContext(RootStoreContext);

    useEffect(() => {
        notificationStore.setNotificationId(news?.id);
        // Only re-render when news?.id change
        // eslint-disable-next-line
    }, [news?.id]);

    const publishedDate = getDateWithLongMonthAndTime(news?.timestamp);
    const stateLabel = news?.statelabel;
    const author = news?.author;
    const eventDate = useNewsInsertedEventSubscription(news?.id, "");

    const newsPreviewInfoItems = [
        {
            title: t("screens.realestate.news_preview.published_at"),
            value: publishedDate,
            loading: false
        },
        {
            title: t("screens.realestate.news_preview.state"),
            value: stateLabel,
            loading: false
        },
        {
            title: t("screens.realestate.news_preview.author"),
            value: author,
            loading: false
        },
        {
            title: t("screens.realestate.news_preview.notified_at"),
            value: eventDate !== undefined || eventDate != null ? eventDate : "-",
            loading: !uiStore.isNewsInsertedEventLoaded
        }
    ];

    notificationStore.setIsNewsOrAssemblyState(0);

    return <InfoCard infoItems={newsPreviewInfoItems} />;
};

export const NewsInfoTile = Sentry.withProfiler(observer(NewsInfoTileBase));
