import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useSelectionListKeyHandler } from "src/hooks/ticket/selection-list-key-handler/useSelectionListKeyHandler";
import { boldenMatchedSubstring } from "src/utils/Common";
import { ColorStyle } from "src/utils/Colors";
import debounce from "lodash/debounce";
import { IOrder } from "src/stores/krediflow/InvoiceTypes";
import { ORDER_SEARCH_FILTER_TYPE } from "src/stores/krediflow/OrderSearchStore";
import styles from "./OrderFormField.module.css";
import "./GeneralInvoiceFormStyles.css";
import { CONTRACTOR_TYPE } from "src/stores/OrderStore";

const OrderFormFieldBase = () => {
    const { t } = useTranslation();
    const { uiStore, realestateSearchStore, invoiceStore, ticketSearchStore, orderSearchStore } =
        useContext(RootStoreContext);

    const [displayOrderList, setDisplayOrderList] = useState<boolean>(false);
    const [lastScrollPositionYOrderItems, setLastSrollYPositionOrderItems] = useState<number>(0);

    const orderInputRef = useRef<HTMLInputElement>(null);

    const resetSearchQuery = useCallback(() => {
        orderSearchStore.setCurrentOrderSearchQuery(orderSearchStore.selectedOrderQueryString ?? "");
    }, [orderSearchStore]);

    useEffect(() => {
        const handleClickOutsideOrderInput = (event: any) => {
            if (
                displayOrderList &&
                orderInputRef.current &&
                !orderInputRef.current.contains(event.target) &&
                !(event.target.classList.contains("list-item") && event.target.classList.contains("order")) &&
                !(event.target.classList.contains("highlighted-text") && event.target.classList.contains("order")) &&
                !event.target.classList.contains("order-list-item-span") &&
                !event.target.classList.contains("order-filter-container") &&
                !event.target.classList.contains("order-filter-button") &&
                !event.target.classList.contains("order-filter-button-text") &&
                !event.target.classList.contains("order-filter-button-icon") &&
                !event.target.classList.contains("order-list-item-line-1") &&
                !event.target.classList.contains("order-list-item-line-2") &&
                !event.target?.parentElement?.parentElement?.parentElement.classList?.contains("IconSVG")
            ) {
                setDisplayOrderList(false);

                if (
                    invoiceStore.currentInvoice?.isTicketSelected &&
                    orderSearchStore.currentOrderSearchQuery !== orderSearchStore.selectedOrderQueryString
                ) {
                    invoiceStore.resetOrderFormField();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutsideOrderInput);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideOrderInput);
        };
    }, [
        invoiceStore,
        displayOrderList,
        invoiceStore.currentInvoice?.isTicketSelected,
        orderSearchStore.currentOrderSearchQuery,
        orderSearchStore.selectedOrderQueryString
    ]);

    useEffect(() => {
        if (orderSearchStore.triggerFocusOnOrderInput) {
            orderInputRef.current?.focus();
            orderSearchStore.setTriggerFocusOnOrderInput(false);
        }
        // This useEffect only listens to changes in ticketSearchStore.triggerFocusOnTicketInput
        // eslint-disable-next-line
    }, [orderSearchStore.setTriggerFocusOnOrderInput]);

    const updateInvoiceStoreWithSelectedOrder = useCallback(
        async (order: IOrder | undefined) => {
            if (order) {
                invoiceStore.updateInvoiceAndFormWithSelectedOrder(order);
            }
        },
        [invoiceStore]
    );

    const onSelectOrderItem = useCallback(
        (activeListItemIndex: number) => {
            const selectedOrderItem = orderSearchStore.filteredOrderData[activeListItemIndex];

            updateInvoiceStoreWithSelectedOrder(selectedOrderItem);
        },
        [orderSearchStore.filteredOrderData, updateInvoiceStoreWithSelectedOrder]
    );

    const { onKeyDownFunction, setActiveListItemIndex, NO_LIST_ITEM_SELECTED } = useSelectionListKeyHandler({
        listName: "order",
        totalNumberOfDisplayedItems: invoiceStore.numberOfDisplayedItems,
        setIsListDisplayed: setDisplayOrderList,
        isListDisplayed: displayOrderList,
        onSelectItem: onSelectOrderItem,
        resetSearchQuery: resetSearchQuery
    });

    const resetAmountOfItemsDisplayedInOrderList = useCallback(() => {
        setLastSrollYPositionOrderItems(0);
        invoiceStore.setNumberOfDisplayedItems(invoiceStore.sliceSizeOfDisplayedItems);
    }, [invoiceStore]);

    const searchOrder = debounce((query: string) => {
        orderSearchStore.searchOrdersInBackendByQuery(query);
    }, 800);

    const onChangeOrderInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            resetAmountOfItemsDisplayedInOrderList();

            const inputOrder = e.target.value;
            orderSearchStore.setCurrentOrderSearchQuery(inputOrder);

            if (orderSearchStore.isSearchingOrdersInBackendByQuery) {
                if (inputOrder.length > 2) {
                    searchOrder(inputOrder);
                } else {
                    orderSearchStore.setOrderData([]);
                }
            }
        },

        // searchTicket function cannot be in dependency array, because it is a debounced function
        // eslint-disable-next-line
        [orderSearchStore, resetAmountOfItemsDisplayedInOrderList]
    );

    const onFocusOrderInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (invoiceStore.currentInvoice?.isOrderSelected && orderInputRef.current) {
                orderInputRef.current.select();
            }

            resetAmountOfItemsDisplayedInOrderList();
            setDisplayOrderList(true);
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
        },
        [
            resetAmountOfItemsDisplayedInOrderList,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            invoiceStore.currentInvoice?.isOrderSelected
        ]
    );

    const onClickOrderItem = useCallback(
        (order: IOrder) => async (e: any) => {
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
            setDisplayOrderList(false);

            updateInvoiceStoreWithSelectedOrder(order);
        },
        [updateInvoiceStoreWithSelectedOrder, setActiveListItemIndex, NO_LIST_ITEM_SELECTED]
    );

    const onScrollOrderList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYOrderItems;

            const hasMoreResulsToDisplay =
                invoiceStore.numberOfDisplayedItems < orderSearchStore.filteredOrderData.length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                invoiceStore.setNumberOfDisplayedItems(
                    invoiceStore.numberOfDisplayedItems + invoiceStore.sliceSizeOfDisplayedItems
                );
            }

            setLastSrollYPositionOrderItems(pixelsFromTop);
        },
        [lastScrollPositionYOrderItems, invoiceStore, orderSearchStore.filteredOrderData.length]
    );

    const getSpanElementWithFormattedText = useCallback(
        (stringToFormat: string) => {
            const formattedString =
                orderSearchStore.currentOrderSearchQuery !== ""
                    ? boldenMatchedSubstring(
                          orderSearchStore.currentOrderSearchQuery ?? "",
                          stringToFormat,
                          "highlighted-text order"
                      )
                    : stringToFormat;

            return (
                <span
                    className="order-list-item-span"
                    dangerouslySetInnerHTML={{
                        __html: formattedString
                    }}
                />
            );
        },
        [orderSearchStore.currentOrderSearchQuery]
    );

    const toggleOrderFilter = useCallback(
        (orderSearchFilterType: ORDER_SEARCH_FILTER_TYPE) => () => {
            switch (orderSearchFilterType) {
                case ORDER_SEARCH_FILTER_TYPE.REALESTATE:
                    orderSearchStore.toggleOrderSearchFilterRealestate();
                    break;
                case ORDER_SEARCH_FILTER_TYPE.CREDITOR:
                    orderSearchStore.toggleOrderSearchFilterCreditor();
                    break;
                case ORDER_SEARCH_FILTER_TYPE.TICKET:
                    orderSearchStore.toggleOrderSearchFilterTicket();
                    break;
            }
        },
        [orderSearchStore]
    );

    const filterActivationIcon = uiStore.isMobile ? (
        <div className="order-filter-button-icon">+</div>
    ) : (
        <UI.Icon className="order-filter-button-icon" icon={UI.SVGIcon.Plus} size={25} color={ColorStyle("grey")} />
    );

    const filterDeactivationIcon = (
        <UI.Icon className="order-filter-button-icon" icon={UI.SVGIcon.Close} size={12} color={ColorStyle("white")} />
    );

    const orderFilterButtonRealestate = (
        <div
            className={`order-filter-button ${!realestateSearchStore.selectedRealestateName ? "not-clickable" : ""} ${
                orderSearchStore.orderSearchFilter.byRealestate ? "filter-active" : ""
            }`}
            onClick={
                realestateSearchStore.selectedRealestateName
                    ? toggleOrderFilter(ORDER_SEARCH_FILTER_TYPE.REALESTATE)
                    : undefined
            }
        >
            <div className="order-filter-button-text">
                {t("screens.tickets.form.person.realestate_filter_abr")}: {realestateSearchStore.selectedRealestateName}
            </div>
            {orderSearchStore.orderSearchFilter.byRealestate ? filterDeactivationIcon : filterActivationIcon}
        </div>
    );

    const orderFilterButtonTicket = (
        <div
            className={`order-filter-button ${!ticketSearchStore.selectedTicketQueryString ? "not-clickable" : ""} ${
                orderSearchStore.orderSearchFilter.byTicket ? "filter-active" : ""
            }`}
            onClick={
                ticketSearchStore.selectedTicketQueryString
                    ? toggleOrderFilter(ORDER_SEARCH_FILTER_TYPE.TICKET)
                    : undefined
            }
        >
            <div className="order-filter-button-text">{t("screens.tickets.ticket")}</div>
            {orderSearchStore.orderSearchFilter.byTicket ? filterDeactivationIcon : filterActivationIcon}
        </div>
    );

    const orderFilterButtonCreditor = (
        <div
            className={`order-filter-button ${
                !invoiceStore.currentInvoice?.isCreditorSelected ? "not-clickable" : ""
            } ${orderSearchStore.orderSearchFilter.byCreditor ? "filter-active" : ""}`}
            onClick={
                invoiceStore.currentInvoice?.isCreditorSelected
                    ? toggleOrderFilter(ORDER_SEARCH_FILTER_TYPE.CREDITOR)
                    : undefined
            }
        >
            <div className="order-filter-button-text">{t("screens.kredi_flow.list.creditor")}</div>
            {orderSearchStore.orderSearchFilter.byCreditor ? filterDeactivationIcon : filterActivationIcon}
        </div>
    );

    const orderSelectionList = (
        <div id="order-dropdown-list-container">
            <div className={"order-filter-container"}>
                {orderFilterButtonCreditor}
                {orderFilterButtonRealestate}
                {orderFilterButtonTicket}
            </div>
            <div
                className={`${styles.OrderSelectionListSearchIndicator} ${
                    !orderSearchStore.isSearchingOrderData &&
                    !(orderSearchStore.orderData.length === 0 && !orderSearchStore.isSearchingOrderData)
                        ? styles.NoBorderBottom
                        : ""
                }`}
            >
                {orderSearchStore.isSearchingOrderData ? (
                    <UI.RotatingSpinner noLogo size={30} className={styles.SearchingOrdersSpinner} />
                ) : undefined}
            </div>
            {orderSearchStore.isSearchingOrderData ? undefined : (
                <ul id="order-dropdown-list" className={"dropdown-list order"} onScroll={onScrollOrderList}>
                    {orderSearchStore.filteredOrderData
                        .slice(0, invoiceStore.numberOfDisplayedItems)
                        .map((order, index) => {
                            const formattedOrderNumber = order.number ? order.number.toString().padStart(5, "0") : "";

                            let contractorName = `${order.contractorName1 ?? ""} ${order.contractorName2 ?? ""}`.trim();

                            if (
                                order.contractorType === CONTRACTOR_TYPE.SERVICE_7000 ||
                                order.contractorType === CONTRACTOR_TYPE.YAROWA
                            ) {
                                contractorName = t(`screens.orders.contractor.type.${order.contractorType}`).toString();
                            }

                            const formattedOrderDateLongSpan = getSpanElementWithFormattedText(order.date_long ?? "");
                            const formattedOrderContractorSpan = contractorName
                                ? getSpanElementWithFormattedText(contractorName ?? "")
                                : undefined;
                            const formattedOrderNumberSpan = getSpanElementWithFormattedText(
                                formattedOrderNumber ?? ""
                            );

                            return (
                                <li key={index}>
                                    <div
                                        className={"list-item order"}
                                        id={`order-list-item-${index}`}
                                        onClick={onClickOrderItem(order)}
                                    >
                                        {uiStore.isMobile ? (
                                            <>
                                                <div>{formattedOrderDateLongSpan}</div>
                                                <div>{formattedOrderContractorSpan}</div>
                                                <div>{formattedOrderNumberSpan}</div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={"order-list-item-line-1"}>
                                                    {formattedOrderDateLongSpan}
                                                    {formattedOrderContractorSpan ? ", " : ""}
                                                    {formattedOrderContractorSpan} [{formattedOrderNumberSpan}]
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            )}
        </div>
    );

    const readOnlyOrderElement = invoiceStore.currentInvoice?.isOrderSelected ? (
        <div className="view-only-container mt-3 mt-lg-0">
            <div className="ticket bold-text mb-1">{t("screens.orders.order")}</div>
            <div className="d-flex">
                <span className="text-with-number">{`${invoiceStore.currentInvoice.order?.date}, ${
                    invoiceStore.currentInvoice?.order?.contractorName1
                } ${invoiceStore.currentInvoice?.order?.contractorName2 ?? ""}`}</span>
                <span>{`${
                    invoiceStore.currentInvoice?.order?.number ? `[${invoiceStore.currentInvoice?.order.number}]` : ""
                }`}</span>
            </div>
        </div>
    ) : (
        <></>
    );

    return (
        <>
            {invoiceStore.isEditing ? (
                <>
                    <UI.Input
                        label={t("screens.orders.order").toString()}
                        ref={orderInputRef}
                        type="text"
                        autoComplete="off"
                        className={styles.OrderInputField}
                        id="invoice-form-order-input"
                        value={orderSearchStore.currentOrderSearchQuery ?? ""}
                        onChange={onChangeOrderInput}
                        onFocus={onFocusOrderInput}
                        onKeyDown={onKeyDownFunction}
                        disabled={!invoiceStore.isEditing}
                    />
                    {displayOrderList ? orderSelectionList : undefined}
                </>
            ) : (
                readOnlyOrderElement
            )}
        </>
    );
};

export const OrderFormField = Sentry.withProfiler(observer(OrderFormFieldBase));
