import { UI } from "@wwimmo/ui";
import { useState, useContext, useEffect, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { CONTRACTOR_TYPE, ORDER_STATE } from "src/stores/OrderStore";
import { NetworkConfig } from "src/network/NetworkConfig";
import styles from "./OpenInYarowaButton.module.css";

const OpenInYarowaButtonBase = () => {
    const [urlToOpen, setUrlToOpen] = useState<string>("");
    const { orderStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    useEffect(() => {
        async function setUrlToOpenForYarowa() {
            const url =
                orderStore.currentOrder?.id !== undefined
                    ? await orderStore.getYarowaUrl(orderStore.currentOrder?.id)
                    : NetworkConfig.yarowaDashboardDefaultUrl;
            setUrlToOpen(url);
        }

        if (orderStore.currentOrder?.contractortype === CONTRACTOR_TYPE.YAROWA) {
            setUrlToOpenForYarowa();
        }
    }, [orderStore, orderStore.currentOrder]);

    const onClickOpenInYarowaButton = useCallback(() => {
        window.open(urlToOpen, "_blank");
    }, [urlToOpen]);

    return (
        <UI.Button
            label={t("screens.tickets.open_in_yarowa")}
            onClick={onClickOpenInYarowaButton}
            className={styles.OpenInYarowaButton}
            hidden={
                orderStore.currentOrder?.contractortype !== CONTRACTOR_TYPE.YAROWA ||
                orderStore.currentOrder?.state === ORDER_STATE.SUBMISSION_FAILED
            }
        />
    );
};

export const OpenInYarowaButton = Sentry.withProfiler(observer(OpenInYarowaButtonBase));
