import { CommonRealestateUnits_realestates_houses_units as common_units } from "src/api/generated/CommonRealestateUnits";
import TenanctChangeIconGreenFilePath from "src/assets/images/tenant-change-icons/tenant_change_green.png";
import TenanctChangeIconOrangeFilePath from "src/assets/images/tenant-change-icons/tenant_change_orange.png";
import TenanctChangeIconRedFilePath from "src/assets/images/tenant-change-icons/tenant_change_red.png";

export enum TENANCY_CHANGE_TYPE {
    HAS_NEW_TENANT,
    HAS_NEW_TENANT_AFTER_VACANCY,
    HAS_NO_NEW_TENANT
}

export const getTenancyChangeType = (unit: common_units): TENANCY_CHANGE_TYPE | undefined => {
    const currentTenancy = unit.tenancys_today.length > 0 ? unit.tenancys_today[0] : undefined;
    const currentTenancyHasLeaseEnd = currentTenancy && currentTenancy?.leaseend !== null ? true : false;

    const futureTenancies = unit.futuretenancies.length > 0 ? unit.futuretenancies : undefined;

    const nextTenancy = futureTenancies ? futureTenancies[0] : undefined;
    const nextTenancyIsVacancy = nextTenancy?.vacancy ? true : false;
    const nextTenancyIsTemporary = nextTenancy?.leaseend !== null;

    const tenancyAfterNextTenancy = futureTenancies && futureTenancies.length > 1 ? futureTenancies[1] : undefined;
    const tenancyAfterNextTenancyIsVacancy = tenancyAfterNextTenancy?.vacancy ? true : false;

    const hasTenancyAfterNextTenancy = tenancyAfterNextTenancy ? true : false;

    if (currentTenancyHasLeaseEnd) {
        if (!nextTenancy) {
            return TENANCY_CHANGE_TYPE.HAS_NO_NEW_TENANT;
        } else if (nextTenancyIsVacancy) {
            if (nextTenancyIsTemporary && !tenancyAfterNextTenancyIsVacancy) {
                return TENANCY_CHANGE_TYPE.HAS_NEW_TENANT_AFTER_VACANCY;
            }

            if (!nextTenancyIsTemporary || !hasTenancyAfterNextTenancy) {
                return TENANCY_CHANGE_TYPE.HAS_NO_NEW_TENANT;
            }
        } else {
            return TENANCY_CHANGE_TYPE.HAS_NEW_TENANT;
        }
    }
};

export const getTenancyChangeInformation = (
    unit: common_units
): { iconFilePath: string; descriptionTranslationPath: string; tenancyChangeType: TENANCY_CHANGE_TYPE } | undefined => {
    const tenancyChangeType = getTenancyChangeType(unit);

    if (tenancyChangeType !== undefined) {
        switch (tenancyChangeType) {
            case TENANCY_CHANGE_TYPE.HAS_NEW_TENANT:
                return {
                    iconFilePath: TenanctChangeIconGreenFilePath,
                    descriptionTranslationPath: "labels.tenant_change.has_new_tenant",
                    tenancyChangeType: TENANCY_CHANGE_TYPE.HAS_NEW_TENANT
                };
            case TENANCY_CHANGE_TYPE.HAS_NEW_TENANT_AFTER_VACANCY:
                return {
                    iconFilePath: TenanctChangeIconOrangeFilePath,
                    descriptionTranslationPath: "labels.tenant_change.has_new_tenant_after_vacancy",
                    tenancyChangeType: TENANCY_CHANGE_TYPE.HAS_NEW_TENANT_AFTER_VACANCY
                };
            case TENANCY_CHANGE_TYPE.HAS_NO_NEW_TENANT:
                return {
                    iconFilePath: TenanctChangeIconRedFilePath,
                    descriptionTranslationPath: "labels.tenant_change.has_no_new_tenant",
                    tenancyChangeType: TENANCY_CHANGE_TYPE.HAS_NO_NEW_TENANT
                };
        }
    }

    return undefined;
};
