import React, { useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import styles from "./TicketHistoryComment.module.css";
import { LOADING_TYPE, TICKET_PROPERTY } from "src/stores/TicketStore";
import { MessageType } from "src/components/notifications/Notifier";

const TicketHistoryCommentBase = () => {
    const { t } = useTranslation();
    const { ticketStore, uiStore } = useContext(RootStoreContext);

    const saveComment = useCallback(
        async (e: any) => {
            e.preventDefault();
            const wasSuccessfullyValidated = ticketStore.currentTicket?.validate();

            if (wasSuccessfullyValidated) {
                const wasSuccessfullySaved = await ticketStore.saveCurrentTicketComment();

                if (wasSuccessfullySaved) {
                    ticketStore.currentTicket?.reloadHistory();
                } else {
                    uiStore.printStatusMessage(t("screens.tickets.form.errors.save_form_error"), MessageType.ERROR);
                }
            } else {
                if (!ticketStore.currentTicket?.isNewTicket && ticketStore.currentTicket?.realestate === undefined) {
                    uiStore.printStatusMessage(t("screens.tickets.form.errors.realestate_missing"), MessageType.ERROR);
                }
            }
        },
        [ticketStore, t, uiStore]
    );

    const saveCommentButtonLabel = (
        <div className="d-flex align-items-center">
            <div>{t("labels.save")}</div>
            <UI.RotatingSpinner
                className={`${styles.FromSaveButtonSpinner} ${
                    ticketStore.loadingType !== LOADING_TYPE.SAVING_COMMENT ? "d-none" : ""
                }`}
                noLogo={true}
                size={20}
            />
        </div>
    );

    const onChangeCommentField = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newCommentText = e.target.value;

            if (ticketStore.currentTicket) {
                ticketStore.currentTicket.updateProperty(TICKET_PROPERTY.COMMENT, newCommentText);
            }
        },
        [ticketStore]
    );

    return ticketStore.currentTicket?.isNewTicket ? (
        <></>
    ) : (
        <div className={styles.TicketHistoryCommentContainer}>
            <div className={`${styles.icketHistoryCommentTitle}`}></div>
            <>
                <UI.Row className="d-flex justify-content-end">
                    <UI.Col sm={12}>
                        <UI.Input
                            placeholder={`${t("screens.tickets.history.comment_placeholder").toString()}`}
                            type="text"
                            as="textarea"
                            autoComplete="off"
                            value={ticketStore.currentTicket && ticketStore.currentTicket.comment}
                            onChange={onChangeCommentField}
                            errorMsg={ticketStore.currentTicket?.errors.damageDescription ?? undefined}
                        />
                    </UI.Col>
                    <UI.Col sm={12} className="d-flex justify-content-end">
                        <UI.Button
                            onClick={saveComment}
                            className={styles.SaveButton}
                            label={saveCommentButtonLabel}
                            disabled={
                                ticketStore.loadingType !== LOADING_TYPE.NONE ||
                                ticketStore?.currentTicket?.comment === ""
                            }
                        />
                    </UI.Col>
                </UI.Row>
            </>
        </div>
    );
};

export const TicketHistoryComment = Sentry.withProfiler(observer(TicketHistoryCommentBase));
