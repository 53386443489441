import React, { useEffect, useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import * as Screens from "src/screens";
import classes from "./InvoicedUserDetails.module.css";
import { GET_CUSTOMER_INVOICED_USERS } from "src/api/customer";
import CustomerUserList from "src/screens/customerusers/list/CustomerUserList";
import { GetCustomerInvoicedUsers } from "src/api/generated/GetCustomerInvoicedUsers";
import { GetCustomerUsers_customer_users } from "src/api/generated/GetCustomerUsers";
import { getDateWithMonthAndYear } from "src/utils/Date";
import { Route, selectRoute } from "src/config/routes";
import { useLocation } from "react-router-dom";

interface Params {
    customerinvoiceid: string;
    year: string;
    month: string;
}

const InvoicedUserDetailsBase = () => {
    const { navStore, authStore } = useContext(RootStoreContext);
    const { t } = useTranslation();
    const { customerinvoiceid, year, month } = useParams<Params>();
    const location = useLocation();

    const variables = {
        customerinvoiceid: customerinvoiceid
    };

    const { data, loading, error } = useQuery<GetCustomerInvoicedUsers>(GET_CUSTOMER_INVOICED_USERS, {
        variables: variables
    });

    useEffect(() => {
        navStore.setTitle("");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);

        const onHistoryPath = location.pathname.includes(Route.servicesCostsAndInvoicesHistory);

        if (onHistoryPath) {
            const date = new Date(Number(year), Number(month) - 1).toString();

            navStore.setBackbutton(
                selectRoute(Route.servicesCostsAndInvoicesHistoryInvoiceDetailsMonth, authStore.user?.role, {
                    year,
                    month
                }),
                getDateWithMonthAndYear(date)
            );
        } else {
            navStore.setBackbutton(
                selectRoute(Route.servicesCostsAndInvoicesCurrentMonth, authStore.user?.role),
                t("screens.services.costs_and_invoices.current_month.title").toString()
            );
        }
    }, [navStore, authStore.user?.role, location.pathname, month, t, year]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    const datasetInvoicedUsers: GetCustomerUsers_customer_users[] = data.customer_users.map((customer_user) => {
        return {
            __typename: "ums_users",
            customerid: customer_user.customerid,
            userid: customer_user.userid,
            email: customer_user.email,
            name1: customer_user.name || "",
            name2: "",
            registered: false,
            registereddate: null,
            activated: false,
            emailverified: false,
            registrationcode: null,
            userinserted: null,
            userroles: customer_user.roles.map((rolenumber: number) => {
                return {
                    role: rolenumber
                };
            }),
            login_audit: [],
            registered_audit: [],
            activated_audit: [],
            blocked_audit: []
        } as GetCustomerUsers_customer_users;
    });

    let date: string | undefined = undefined;

    if (data.customer_users.length > 0 && data.customer_users[0].date) {
        date = getDateWithMonthAndYear(data.customer_users[0].date);
    }

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Title>
                    {date
                        ? t("screens.services.costs_and_invoices.invoiced_user_details.title", {
                              monthyear: getDateWithMonthAndYear(data.customer_users[0].date)
                          })
                        : ""}
                </UI.Title>
                <UI.Row>
                    <UI.Col md={12}>
                        <UI.Card>
                            <div className={classes.customerList}>
                                <CustomerUserList
                                    users={datasetInvoicedUsers}
                                    userrolesDefinition={data.userrolesdefinition}
                                    displayUserStatusRows={false}
                                />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const InvoicedUserDetails = Sentry.withProfiler(observer(InvoicedUserDetailsBase));
