import { observable, makeObservable } from "mobx";
import { RootStore } from "src/stores/RootStore";

export class CloseTicketModalStore {
    rootStore: RootStore;

    isModalDisplayed = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            isModalDisplayed: observable,
            setIsModalDisplayed: observable
        });
    }

    /* SETTERS */
    setIsModalDisplayed = (isModalDisplayed: boolean) => {
        this.isModalDisplayed = isModalDisplayed;
    };
}
