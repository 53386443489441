import { UI } from "@wwimmo/ui";
import { useCallback, useContext } from "react";
import styles from "./DocumentFilterModalList.module.css";
import { RootStoreContext } from "src/stores/RootStore";

interface DocumentsFilterModalListProps {
    years: string[];
}

export const DocumentFilterModalList = (props: DocumentsFilterModalListProps) => {
    const { documentStore } = useContext(RootStoreContext);

    const onCheckboxChange = useCallback(
        (year: string) => async (e: any) => {
            if (documentStore.selectedYears.includes(year)) {
                documentStore.setSelectedYears(documentStore.selectedYears.filter((item) => item !== year));
            } else {
                documentStore.setSelectedYears([...documentStore.selectedYears, year]);
            }
        },
        [documentStore]
    );

    return (
        <div className={props.years.length > 5 ? styles.ScrollableContainer : ""}>
            {props.years.map((year, index) => (
                <div key={index}>
                    <UI.Checkbox
                        className={styles.FilterCheckbox}
                        label={year.toString()}
                        name={year.toString()}
                        checked={documentStore.selectedYears.includes(year)}
                        onChange={onCheckboxChange(year)}
                    />
                </div>
            ))}
        </div>
    );
};
