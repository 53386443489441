import React, { useContext, useCallback, useState, useMemo } from "react";
import {
    GetCustomerUsers_customer_users as umsUser,
    GetCustomerUsers_customer_users_userroles,
    GetCustomerUsers_userrolesdefinition as umsUserrolesDefinition
} from "src/api/generated/GetCustomerUsers";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize, DropdownItem } from "@wwimmo/ui";
import { dateFormat } from "src/utils/Date";
import styles from "./CustomerUsersList.module.css";
import { Route, selectRoute } from "src/config/routes";

interface CustomerUserListProps {
    users: umsUser[];
    userrolesDefinition: umsUserrolesDefinition[];
    displayUserStatusRows: boolean;
}

enum UserSortKey {
    NAME = "1",
    E_MAIL_ADDRESS = "2",
    STATUS = "3",
    LAST_LOGIN = "4"
}

const CustomerUserList = (props: CustomerUserListProps) => {
    const { uiStore, navStore } = useContext(RootStoreContext);
    const [displayedUsers, setDisplayedUsers] = useState<umsUser[]>([]);
    const { t } = useTranslation();
    const RolesMap = useMemo(() => props.userrolesDefinition, [props.userrolesDefinition]);

    let listIdentifier = "customeruserslist";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    let currentSearchQuery: string | undefined = undefined;

    if (listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    }

    let currentSortQuery: string | undefined = undefined;

    if (listParameters && listParameters.sortValue) {
        currentSortQuery = listParameters.sortValue;
    }

    const sortDropdownItems: DropdownItem[] = [
        { value: UserSortKey.NAME, label: t("screens.customer_users.name") },
        { value: UserSortKey.E_MAIL_ADDRESS, label: t("screens.customer_users.email_address") }
    ];

    if (props.displayUserStatusRows) {
        sortDropdownItems.push({ value: UserSortKey.STATUS, label: t("screens.customer_users.status") });
        sortDropdownItems.push({ value: UserSortKey.LAST_LOGIN, label: t("screens.customer_users.last_login") });
    }

    const initialSortItem = sortDropdownItems.find((item) => item.value === currentSortQuery);

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listIdentifier, listParameters]
    );

    const getUserRoles = useCallback(
        (userRoles: GetCustomerUsers_customer_users_userroles[]) => {
            return userRoles
                .map(({ role }, i) => {
                    return `${RolesMap.find((m) => m.key === role)?.label}`;
                })
                .join("; ");
        },
        [RolesMap]
    );

    const getDate = useCallback((timestamp: string) => {
        return dateFormat(new Date(`${timestamp}+00:00`), "dd.MM.yyyy HH:mm");
    }, []);

    const getStatusAndStatusSince = useCallback(
        (user: umsUser): { userStatus: string; statusSince: string } => {
            let userStatus = t("user.status.not_activated");
            let statusSince: string = getDate(user.userinserted);

            if (user.registered && !user.activated) {
                userStatus = t("user.status.blocked");

                if (user.blocked_audit.length > 0) {
                    statusSince = getDate(user.blocked_audit[0].timestamp);
                }
            } else if (user.registered && !user.activated && !user.emailverified) {
                userStatus = t("user.status.registered");

                if (user.registered_audit.length > 0) {
                    statusSince = getDate(user.registered_audit[0].timestamp);
                }
            } else if (user.registered && user.activated && user.emailverified) {
                userStatus = t("user.status.activated");

                if (user.activated_audit.length > 0) {
                    statusSince = getDate(user.activated_audit[0].timestamp);
                }
            }

            return { userStatus: userStatus, statusSince: statusSince };
        },
        [getDate, t]
    );

    const renderItem = useCallback(
        (user: umsUser, screenSize: ScreenSize) => {
            let userRoles = getUserRoles(user.userroles);

            let listRows: React.ReactElement[] = [];
            let userStatusValue = "";
            let statusSinceValue = "";

            if (props.displayUserStatusRows) {
                const { userStatus, statusSince } = getStatusAndStatusSince(user);
                userStatusValue = userStatus;
                statusSinceValue = statusSince;
            }

            let lastLogin = "";
            if (props.displayUserStatusRows && user.login_audit.length > 0) {
                lastLogin = getDate(user.login_audit[0].timestamp);
            }

            const userName =
                user.name1 === user.name2 ? user.name1 : user.name2 ? `${user.name1} ${user.name2}` : user.name1;

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = props.displayUserStatusRows
                        ? [
                              <UI.List.Row key={"r-1"}>
                                  <UI.List.Cell key={"c-1"} colspan={2.25} value={userName} />
                                  <UI.List.Cell key={"c-2"} colspan={2.25} value={user.email ? user.email : ""} />
                                  <UI.List.Cell key={"c-3"} colspan={2.25} value={userRoles} />
                                  <UI.List.Cell key={"c-4"} colspan={1} value={userStatusValue} />
                                  <UI.List.Cell key={"c-5"} colspan={1} value={statusSinceValue} />
                                  <UI.List.Cell key={"c-6"} colspan={1} value={lastLogin} />
                              </UI.List.Row>
                          ]
                        : [
                              <UI.List.Row key={"r-1"}>
                                  <UI.List.Cell key={"c-1"} colspan={2.25} value={userName} />
                                  <UI.List.Cell key={"c-2"} colspan={2.25} value={user.email ? user.email : ""} />
                                  <UI.List.Cell key={"c-3"} colspan={2.25} value={userRoles} />
                              </UI.List.Row>
                          ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = props.displayUserStatusRows
                        ? [
                              <UI.List.Row key={"r-1"}>
                                  <UI.List.Cell key={"c-1"} colspan={1.75} value={userName} />
                                  <UI.List.Cell key={"c-2"} colspan={1} value={userStatusValue} />
                              </UI.List.Row>
                          ]
                        : [
                              <UI.List.Row key={"r-1"}>
                                  <UI.List.Cell key={"c-1"} colspan={1.75} value={userName} />
                                  <UI.List.Cell key={"c-2"} colspan={1} value={user.email ? user.email : ""} />
                              </UI.List.Row>
                          ];
                    break;
            }

            const link = selectRoute(Route.customerUserdetails, undefined, {
                userid: user.userid
            });

            return (
                <UI.List.Item
                    key={`${user.userid}-${user.customerid}`}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                />
            );
        },
        [getUserRoles, getStatusAndStatusSince, props.displayUserStatusRows, getDate]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = props.displayUserStatusRows
                        ? [
                              <UI.List.Row key={"row-1"} className={styles.ListHeader}>
                                  <UI.List.Cell key={"c-1"} colspan={2.25} value={t("screens.customer_users.name")} />
                                  <UI.List.Cell
                                      key={"c-2"}
                                      colspan={2.25}
                                      value={t("screens.customer_users.email_address")}
                                  />
                                  <UI.List.Cell key={"c-3"} colspan={2.25} value={t("screens.customer_users.roles")} />
                                  <UI.List.Cell key={"c-4"} colspan={1} value={t("screens.customer_users.status")} />
                                  <UI.List.Cell
                                      key={"c-5"}
                                      colspan={1}
                                      value={t("screens.customer_users.status_since")}
                                  />
                                  <UI.List.Cell
                                      key={"c-6"}
                                      colspan={1}
                                      value={t("screens.customer_users.last_login")}
                                  />
                              </UI.List.Row>
                          ]
                        : [
                              <UI.List.Row key={"row-1"} className={styles.ListHeader}>
                                  <UI.List.Cell key={"c-1"} colspan={2.25} value={t("screens.customer_users.name")} />
                                  <UI.List.Cell
                                      key={"c-2"}
                                      colspan={2.25}
                                      value={t("screens.customer_users.email_address")}
                                  />
                                  <UI.List.Cell key={"c-3"} colspan={2.25} value={t("screens.customer_users.roles")} />
                              </UI.List.Row>
                          ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1.75} value={t("screens.customer_users.name")} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={t("screens.customer_users.status")} />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} sticky={true} top={80} />;
        },
        [t, props.displayUserStatusRows]
    );

    const getUserCompareValue = useCallback(
        (userA: umsUser, userB: umsUser, compareType: string) => {
            let valueUserA = "";
            let valueUserB = "";

            if (props.displayUserStatusRows) {
                let dateUserA: Date | undefined;
                let dateUserB: Date | undefined;

                const { userStatus: userStatusA } = getStatusAndStatusSince(userA);

                let lastLoginUserA: Date | undefined = undefined;

                if (userA.login_audit.length > 0) {
                    lastLoginUserA = new Date(userA.login_audit[0].timestamp);
                }

                const { userStatus: userStatusB } = getStatusAndStatusSince(userB);

                let lastLoginUserB: Date | undefined;

                if (userB.login_audit.length > 0) {
                    lastLoginUserB = new Date(userB.login_audit[0].timestamp);
                }

                switch (compareType) {
                    case UserSortKey.NAME:
                        valueUserA = userA.name1 ? userA.name1.toLowerCase().trim() : "";
                        valueUserB = userB.name1 ? userB.name1.toLowerCase().trim() : "";
                        break;
                    case UserSortKey.E_MAIL_ADDRESS:
                        valueUserA = userA.email ? userA.email.toLowerCase().trim() : "";
                        valueUserB = userB.email ? userB.email.toLowerCase().trim() : "";
                        break;
                    case UserSortKey.STATUS:
                        valueUserA = userStatusA ? userStatusA.toLowerCase().trim() : "";
                        valueUserB = userStatusB ? userStatusB.toLowerCase().trim() : "";
                        break;
                    case UserSortKey.LAST_LOGIN:
                        dateUserA = lastLoginUserA;
                        dateUserB = lastLoginUserB;
                        break;
                    default:
                        break;
                }

                if (compareType !== UserSortKey.LAST_LOGIN) {
                    if (valueUserA.length > 0 && valueUserB.length > 0) {
                        if (valueUserA < valueUserB) {
                            return -1;
                        }

                        if (valueUserA > valueUserB) {
                            return 1;
                        }

                        return 0;
                    } else if (valueUserA.length === 0 && valueUserB.length > 0) {
                        return 1;
                    } else if (valueUserA.length > 0 && valueUserB.length === 0) {
                        return -1;
                    } else {
                        return 0;
                    }
                } else {
                    if (dateUserA && dateUserB) {
                        if (dateUserA < dateUserB) {
                            return 1;
                        }

                        if (dateUserA > dateUserB) {
                            return -1;
                        }

                        return 0;
                    } else if (!dateUserA && dateUserB) {
                        return 1;
                    } else if (dateUserA && !dateUserB) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            } else {
                switch (compareType) {
                    case UserSortKey.NAME:
                        valueUserA = userA.name1 ? userA.name1.toLowerCase().trim() : "";
                        valueUserB = userB.name1 ? userB.name1.toLowerCase().trim() : "";
                        break;
                    case UserSortKey.E_MAIL_ADDRESS:
                        valueUserA = userA.email ? userA.email.toLowerCase().trim() : "";
                        valueUserB = userB.email ? userB.email.toLowerCase().trim() : "";
                        break;
                    default:
                        break;
                }

                if (valueUserA.length > 0 && valueUserB.length > 0) {
                    if (valueUserA < valueUserB) {
                        return -1;
                    }

                    if (valueUserA > valueUserB) {
                        return 1;
                    }

                    return 0;
                } else if (valueUserA.length === 0 && valueUserB.length > 0) {
                    return 1;
                } else if (valueUserA.length > 0 && valueUserB.length === 0) {
                    return -1;
                } else {
                    return 0;
                }
            }
        },
        [getStatusAndStatusSince, props.displayUserStatusRows]
    );

    const searchAndSortUsers = useCallback(
        (searchQuery: string = "", sortItemValue: string = "0") => {
            if (props.users) {
                const foundUsers = props.users.filter((user) => {
                    let userStatusValue = "";
                    let statusSinceValue = "";
                    if (props.displayUserStatusRows) {
                        const { userStatus, statusSince } = getStatusAndStatusSince(user);
                        userStatusValue = userStatus;
                        statusSinceValue = statusSince;
                    }

                    let lastLogin = "";

                    if (props.displayUserStatusRows && user.login_audit.length > 0) {
                        lastLogin = getDate(user.login_audit[0].timestamp);
                    }

                    let userRoles = getUserRoles(user.userroles);

                    const userName =
                        user.name1 === user.name2
                            ? user.name1
                            : user.name2
                            ? `${user.name1} ${user.name2}`
                            : user.name1;

                    return (
                        user.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        userName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        userStatusValue.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        statusSinceValue.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (lastLogin.length > 0 ? lastLogin.toLowerCase().includes(searchQuery.toLowerCase()) : false) ||
                        (userRoles.length > 0 ? userRoles.toLowerCase().includes(searchQuery.toLowerCase()) : false)
                    );
                });

                const sortedUsers = foundUsers.sort((userA, userB) => {
                    return getUserCompareValue(userA, userB, sortItemValue);
                });

                setDisplayedUsers([...sortedUsers]);
            }
        },
        [props.users, getUserCompareValue, getStatusAndStatusSince, getDate, getUserRoles, props.displayUserStatusRows]
    );

    const handleSearchInput = useCallback(
        ({ searchQuery, sortItemValue }) => {
            searchAndSortUsers(searchQuery, sortItemValue);

            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: searchQuery, sortValue: sortItemValue }
                    : { scrollPosition: 0, searchQuery: searchQuery, sortValue: sortItemValue }
            );
        },
        [searchAndSortUsers, navStore, listIdentifier, listParameters]
    );

    const onChangeSort = useCallback(
        ({ searchQuery, sortItemValue }) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, sortValue: sortItemValue, searchQuery: searchQuery }
                    : { sortValue: sortItemValue, searchQuery: searchQuery }
            );

            searchAndSortUsers(searchQuery, sortItemValue);
        },
        [searchAndSortUsers, navStore, listIdentifier, listParameters]
    );

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={displayedUsers}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={25}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
            onSearch={handleSearchInput}
            initialSearchQuery={currentSearchQuery}
            sortItems={sortDropdownItems}
            initialSortItem={initialSortItem}
            onChangeSort={onChangeSort}
        />
    );
};

export default observer(CustomerUserList);
