import React, { useContext } from "react";
import * as Sentry from "@sentry/react";
import { Switch, withRouter } from "react-router";
import { Header, PrivateRoute } from "src/components/";
import { observer } from "mobx-react-lite";
import { Route, getParams, getRoute } from "src/config/routes";
import * as Screens from "src/screens";
import { NAVIGATIONBAR_TYPE } from "src/components/header/navigationbar/Navigationbar";
import { RootStoreContext } from "src/stores/RootStore";
import "./Creditor.css";

export const CreditorBase = () => {
    const { uiStore, authStore, invoiceStore } = useContext(RootStoreContext);

    const role = authStore.user?.role;
    const currentPage = role ? getRoute(window.location.pathname, role) : undefined;

    let params: { [key: string]: string } = {};

    if (currentPage === Route.invoiceDetails) {
        params = getParams(Route.invoiceDetails, role);
    }

    // if (currentPage === Route.ticketHistory) {
    //     params = getParams(Route.ticketHistory, role);
    // }

    const invoiceNumber = params.hasOwnProperty("invoicenumber")
        ? params["invoicenumber"]
        : invoiceStore.NEW_INVOICE_ID;

    return (
        <React.Fragment>
            {uiStore.isMobile ? (
                <Header.Navigationbar navigationbarType={NAVIGATIONBAR_TYPE.INVOICE} invoiceNumber={invoiceNumber} />
            ) : undefined}

            <Switch>
                <PrivateRoute
                    key={Route.manager + Route.invoiceDetails}
                    path={Route.manager + Route.invoiceDetails}
                    exact
                    component={Screens.InvoiceDetails}
                />
                {/* <PrivateRoute
                    key={Route.manager + Route.ticketHistory}
                    path={Route.manager + Route.ticketHistory}
                    exact
                    component={Screens.TicketHistory}
                />
                <PrivateRoute
                    key={Route.manager + Route.ticketOrderDetails}
                    path={Route.manager + Route.ticketOrderDetails}
                    exact
                    component={Screens.OrderDetails}
                /> */}
                <PrivateRoute component={Screens.ErrorNotFound} />
            </Switch>
        </React.Fragment>
    );
};

export const Creditor = Sentry.withProfiler(withRouter(observer(CreditorBase)));
