import React, { useState, useContext, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import "./LogoUpload.css";
import { RootStoreContext } from "src/stores/RootStore";
import { NetworkConfig } from "src/network/NetworkConfig";

const maxDataSize = 1000000;
const dataTypes = ["image/jpeg", "image/png"];

export const LogoUpload = () => {
    const { t } = useTranslation();
    const { authStore } = useContext(RootStoreContext);

    const [file, setFile] = useState<string | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | null>(null);
    const [uploading, setUploading] = useState<boolean>(false);
    const user = authStore.user;

    const handleChangeInput = useCallback(
        async (event: any) => {
            const toBase64 = (file: Blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (e) => {
                        //only use second part of encoding!
                        resolve(reader.result?.toString().split(",")[1]);
                    };
                    reader.onerror = (error) => reject(error);
                });
            const newFile = event.target.files[0];
            if (newFile.size > maxDataSize) {
                setWarningMessage(t("error.wrong_size", { size: (maxDataSize / 1000000).toString() }));
                return;
            }
            if (dataTypes.indexOf(newFile.type) === -1) {
                setWarningMessage(t("error.wrong_imagetype"));
                return;
            }
            await toBase64(newFile).then((f) => {
                setWarningMessage(null);
                setFile(f as string);
            });
        },
        [setFile, setWarningMessage, t]
    );

    const onUpload = useCallback(async () => {
        setUploading(true);
        if (file && user) {
            const options = {
                method: "PUT",
                headers: {
                    Authorization: "Bearer " + authStore.token
                },
                body: JSON.stringify({
                    file: file
                })
            };
            let request = NetworkConfig.brandAssets.uploadLogo;

            try {
                const rawResponse = await fetch(request, options);

                if (rawResponse.status === 200) {
                    window.location.reload();
                } else if (rawResponse.status === 401) {
                    setWarningMessage(t("error.401"));
                } else {
                    setWarningMessage(t("error.generic") + " - " + rawResponse.statusText);
                }
            } catch (error: any) {
                setWarningMessage(t("error.generic") + " - " + error.message);
            } finally {
                setUploading(false);
            }
        }

        return () => {
            setUploading(false);
        };
    }, [setUploading, setWarningMessage, file, user, authStore.token, t]);

    const imageSrc = file ? "data:image/jpeg;base64," + file : `${NetworkConfig.brandAssets.logoImage}`;

    return (
        <div className="LogoUpload d-flex flex-column">
            <div className="LogoContainer flex-fill">
                <img src={imageSrc} alt="Customer Logo" />
            </div>
            <input className="mt-2 mb-4" type="file" onChange={handleChangeInput} accept={dataTypes.join(",")} />
            <UI.Button
                label={uploading ? t("labels.uploading") : t("labels.upload")}
                onClick={onUpload}
                disabled={(file ? false : true) || uploading}
            />
            {warningMessage && (
                <UI.Alert variant="warning" className="mt-3">
                    {warningMessage}
                </UI.Alert>
            )}
        </div>
    );
};
