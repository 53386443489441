import React, { useState, useCallback } from "react";
import { ScreenSize, UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { portal_accounts_part, portal_accounts_part_bookings } from "src/api/generated/portal_accounts_part";
import styles from "./CollapsableBookinglist.module.css";
import { v4 as uuidv4 } from "uuid";
import { AccountList } from "src/screens/realestates/realestate/finances/account/accountList/AccountList";
import { dateFormat } from "src/utils/Date";
import { getCurrencyElement } from "src/utils/Currency";

interface Account extends portal_accounts_part {
    accounts: Account[];
}

interface CollapsableBookinglistProperties {
    accounts: Account[];
    searchQuery: string;
}

const CollapsableBookinglistBase = (props: CollapsableBookinglistProperties) => {
    const { t } = useTranslation();
    const [activeAccount, setActiveAccount] = useState<Account>();
    const [isActiveTab, setIsActiveTab] = useState<boolean>(true);

    const renderHeaderAccounts = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            const account = t("screens.realestate.finances.account");
            const description = t("screens.realestate.finances.description");
            const debit = t("screens.realestate.finances.debit");
            const credit = t("screens.realestate.finances.credit");

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1.25} value={account} />
                            <UI.List.Cell key={"c-2"} colspan={3} value={description} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={debit} className="text-right" />
                            <UI.List.Cell key={"c-4"} colspan={1} value={credit} className="text-right" />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={account} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={debit} className="text-right" />
                            <UI.List.Cell key={"c-3"} colspan={1} value={credit} className="text-right" />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header key={account} rows={headerRows} />;
        },
        [t]
    );

    const onTabNavigationClick = useCallback(
        (account: Account) => async (e: any) => {
            if (activeAccount?.id === account.id) {
                setIsActiveTab(isActiveTab ? false : true);
            } else {
                setIsActiveTab(isActiveTab ? true : false);
            }

            setActiveAccount(account);
        },
        [activeAccount?.id, isActiveTab]
    );

    const tabNavigationItem = useCallback(
        (account: Account) => {
            return (
                <UI.Nav.Item key={uuidv4()} className={styles.TabNavigationNavItem}>
                    <UI.Nav.Link
                        eventKey={account.id}
                        className={styles.TabNavigationNavLink}
                        onClick={onTabNavigationClick(account)}
                    >
                        <UI.List.Row key={"r-1"} className={styles.TabNavigationNavLinkRow}>
                            <UI.List.Cell key={"c-1"} colspan={1.25} value={account.number} />
                            <UI.List.Cell key={"c-2"} colspan={3} value={account.title} />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={1}
                                value={getCurrencyElement(account.debit)}
                                className="text-right"
                            />
                            <UI.List.Cell
                                key={"c-4"}
                                colspan={1}
                                value={getCurrencyElement(account.credit)}
                                className="text-right"
                            />
                            <UI.Icon icon={UI.SVGIcon.ChevronDownThin} />
                        </UI.List.Row>
                    </UI.Nav.Link>
                </UI.Nav.Item>
            );
        },
        [onTabNavigationClick]
    );

    const renderTabNavigationTabPane = useCallback(() => {
        if (activeAccount) {
            const foundBookings: portal_accounts_part_bookings[] = [];
            activeAccount.bookings.forEach((booking) => {
                const bookingDate = dateFormat(new Date(booking.date), "dd.MM.yyyy") || "";
                if (
                    booking.text?.toLowerCase().includes(props.searchQuery.toLowerCase()) ||
                    bookingDate?.toString().includes(props.searchQuery.toLowerCase())
                ) {
                    foundBookings.push(booking);
                } else if (
                    !Number.isNaN(props.searchQuery.toLowerCase()) &&
                    (booking.credit?.toString().includes(Number(props.searchQuery.toLowerCase())) ||
                        booking.debit?.toString().includes(Number(props.searchQuery.toLowerCase())))
                ) {
                    foundBookings.push(booking);
                } else {
                    booking.bookingfiles.forEach((files) => {
                        if (files.name?.toLowerCase().includes(props.searchQuery.toLowerCase())) {
                            const foundBookingWithFiles = foundBookings.find(
                                (foundBooking) => foundBooking.id === booking.id
                            );

                            if (foundBookingWithFiles === undefined) {
                                foundBookings.push(booking);
                            }
                        }
                    });
                }
            });
            return (
                <UI.Tab.Pane key={uuidv4()} eventKey={isActiveTab ? activeAccount.id : ""} className={styles.TabPane}>
                    <AccountList bookings={foundBookings} />
                </UI.Tab.Pane>
            );
        } else {
            return <></>;
        }
    }, [activeAccount, isActiveTab, props.searchQuery]);

    const renderTabNavigationItemsList = useCallback(() => {
        const listRows: JSX.Element[] = [];
        const accountItems: Account[] = [];

        if (props.accounts) {
            props.accounts.forEach((topLevelAccount) => {
                topLevelAccount.accounts.forEach((account) => {
                    accountItems.push(account);
                });
            });
        }

        if (accountItems) {
            listRows.push(renderHeaderAccounts(ScreenSize.DESKTOP));
            accountItems.forEach((account) => {
                listRows.push(
                    tabNavigationItem(account),
                    activeAccount?.id === account.id ? (
                        <UI.Col key={account.id} className={`pl-0 pr-0 ${styles.columnTabContent}`}>
                            <UI.Tab.Content>{renderTabNavigationTabPane()}</UI.Tab.Content>
                        </UI.Col>
                    ) : (
                        <div key={account.id}></div>
                    )
                );
            });
        }
        return listRows;
    }, [props.accounts, activeAccount?.id, tabNavigationItem, renderTabNavigationTabPane, renderHeaderAccounts]);

    return (
        <UI.Tab.Container defaultActiveKey="" transition={false}>
            <UI.Row>
                <UI.Col style={{ marginTop: 25 }}>
                    <UI.Nav variant="pills" className="flex-column">
                        {renderTabNavigationItemsList()}
                    </UI.Nav>
                </UI.Col>
            </UI.Row>
        </UI.Tab.Container>
    );
};

export const CollapsableBookinglist = Sentry.withProfiler(observer(CollapsableBookinglistBase));
