import React, { useContext, useCallback, useState } from "react";
import { GetUserRatingsPortal_userratingsportal as userRatingsPortal } from "src/api/generated/GetUserRatingsPortal";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize, DropdownItem } from "@wwimmo/ui";
import styles from "./UserRatingsPortalSettingList.module.css";
import { getDate } from "src/utils/Date";
import { uuid4 } from "@sentry/utils";
import { StarRatingControl } from "src/components/ratingControl/StarRatingControl";

interface UserRatingsPortalSettingListProps {
    userRatingsPortal: userRatingsPortal[];
}

enum UserSortKey {
    NAME = "1",
    E_MAIL_ADDRESS = "2",
    DATE = "4",
    RATING = "3"
}

const UserRatingsPortalSettingList = (props: UserRatingsPortalSettingListProps) => {
    const { uiStore, navStore } = useContext(RootStoreContext);
    const [displayedUsers, setDisplayedUsers] = useState<userRatingsPortal[]>([]);
    const { t } = useTranslation();

    let listIdentifier = "userratingsportallist";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    let currentSearchQuery: string | undefined = undefined;

    if (listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    }

    let currentSortQuery: string | undefined = undefined;

    if (listParameters && listParameters.sortValue) {
        currentSortQuery = listParameters.sortValue;
    }

    const sortDropdownItems: DropdownItem[] = [
        { value: UserSortKey.DATE, label: t("screens.userratingsportalsetting.date") },
        { value: UserSortKey.NAME, label: t("screens.userratingsportalsetting.name") },
        { value: UserSortKey.E_MAIL_ADDRESS, label: t("screens.userratingsportalsetting.email") },
        { value: UserSortKey.RATING, label: t("screens.userratingsportalsetting.rating_header_title") }
    ];

    const initialSortItem = sortDropdownItems.find((item) => item.value === currentSortQuery);

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listIdentifier, listParameters]
    );

    const onChangeRating = useCallback((rating: number) => {}, []);

    const renderItem = useCallback(
        (userrating: userRatingsPortal, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];
            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={1.65}
                                value={userrating.username ? userrating.username : ""}
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1.25}
                                value={userrating.email ? userrating.email : ""}
                                className={styles.UserListCell}
                            />
                            <UI.List.Cell key={"c-3"} colspan={1} value={getDate(userrating.ratingdateportal)} />
                            <StarRatingControl
                                rating={userrating.rating ?? 0}
                                nrOfStars={5}
                                onChangeRating={onChangeRating}
                                size={24}
                                disabled={true}
                            />
                            <UI.List.Cell key={"c-5"} colspan={0.2} value="" />
                            <UI.List.Cell key={"c-6"} colspan={3} value={userrating.comment} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={0.75}
                                value={userrating.username ? userrating.username : ""}
                            />
                            <StarRatingControl
                                rating={userrating.rating ?? 0}
                                nrOfStars={5}
                                onChangeRating={onChangeRating}
                                size={24}
                                disabled={true}
                            />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Item key={`${uuid4()}`} screenSize={screenSize} rows={listRows} />;
        },
        [onChangeRating]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];
            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"} className={styles.ListHeader}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={1.7}
                                value={t("screens.userratingsportalsetting.name")}
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1.3}
                                value={t("screens.userratingsportalsetting.email")}
                            />
                            <UI.List.Cell key={"c-3"} colspan={1} value={t("screens.userratingsportalsetting.date")} />
                            <UI.List.Cell
                                key={"c-4"}
                                colspan={1}
                                value={t("screens.userratingsportalsetting.rating_header_title")}
                            />
                            <UI.List.Cell
                                key={"c-5"}
                                colspan={3}
                                value={t("screens.userratingsportalsetting.comment")}
                            />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={1.25}
                                value={t("screens.userratingsportalsetting.name")}
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1}
                                value={t("screens.userratingsportalsetting.rating_header_title")}
                            />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} sticky={true} top={80} />;
        },
        [t]
    );

    const getUserCompareValue = useCallback(
        (userA: userRatingsPortal, userB: userRatingsPortal, compareType: string) => {
            let valueUserA = "";
            let valueUserB = "";
            let dateUserA: Date | undefined;
            let dateUserB: Date | undefined;
            let ratingUserA: number | undefined;
            let ratingUserB: number | undefined;

            let ratingDatePortalUserA: Date | undefined = undefined;
            if (userA.ratingdateportal.length > 0) {
                ratingDatePortalUserA = new Date(userA.ratingdateportal);
            }

            let ratingDatePortalUserB: Date | undefined = undefined;
            if (userB.ratingdateportal.length > 0) {
                ratingDatePortalUserB = new Date(userB.ratingdateportal);
            }

            switch (compareType) {
                case UserSortKey.NAME:
                    valueUserA = userA.username ? userA.username.toLowerCase().trim() : "";
                    valueUserB = userB.username ? userB.username.toLowerCase().trim() : "";
                    break;
                case UserSortKey.E_MAIL_ADDRESS:
                    valueUserA = userA.email ? userA.email.toLowerCase().trim() : "";
                    valueUserB = userB.email ? userB.email.toLowerCase().trim() : "";
                    break;
                case UserSortKey.DATE:
                    dateUserA = ratingDatePortalUserA;
                    dateUserB = ratingDatePortalUserB;
                    break;
                case UserSortKey.RATING:
                    ratingUserA = userA.rating ?? 0;
                    ratingUserB = userB.rating ?? 0;
                    break;
                default:
                    break;
            }

            if (compareType === UserSortKey.DATE) {
                if (dateUserA && dateUserB) {
                    if (dateUserA < dateUserB) {
                        return 1;
                    }

                    if (dateUserA > dateUserB) {
                        return -1;
                    }
                    return 0;
                } else if (!dateUserA && dateUserB) {
                    return 1;
                } else if (dateUserA && !dateUserB) {
                    return -1;
                } else {
                    return 0;
                }
            } else if (compareType === UserSortKey.RATING) {
                if (ratingUserA && ratingUserB) {
                    if (ratingUserA < ratingUserB) {
                        return 1;
                    }

                    if (ratingUserA > ratingUserB) {
                        return -1;
                    }
                    return 0;
                } else if (!ratingUserA && ratingUserB) {
                    return 1;
                } else if (ratingUserA && !ratingUserB) {
                    return -1;
                } else {
                    return 0;
                }
            } else {
                if (valueUserA.length > 0 && valueUserB.length > 0) {
                    if (valueUserA < valueUserB) {
                        return -1;
                    }
                    if (valueUserA > valueUserB) {
                        return 1;
                    }
                    return 0;
                } else if (valueUserA.length === 0 && valueUserB.length > 0) {
                    return 1;
                } else if (valueUserA.length > 0 && valueUserB.length === 0) {
                    return -1;
                } else {
                    return 0;
                }
            }
        },
        []
    );

    const searchAndSortUsers = useCallback(
        (searchQuery: string = "", sortItemValue: string = "0") => {
            if (props.userRatingsPortal) {
                console.log("searchAndSortUsers: ", sortItemValue);
                const foundUsers = props.userRatingsPortal.filter((user) => {
                    return (
                        user.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        user.username?.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                });

                const sortedUsers = foundUsers.sort((userA, userB) => {
                    return getUserCompareValue(userA, userB, sortItemValue);
                });

                setDisplayedUsers([...sortedUsers]);
            }
        },
        [props.userRatingsPortal, getUserCompareValue]
    );

    const handleSearchInput = useCallback(
        ({ searchQuery, sortItemValue }) => {
            searchAndSortUsers(searchQuery, sortItemValue);

            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: searchQuery, sortValue: sortItemValue }
                    : { scrollPosition: 0, searchQuery: searchQuery, sortValue: sortItemValue }
            );
        },
        [searchAndSortUsers, navStore, listIdentifier, listParameters]
    );

    const onChangeSort = useCallback(
        ({ searchQuery, sortItemValue }) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, sortValue: sortItemValue, searchQuery: searchQuery }
                    : { sortValue: sortItemValue, searchQuery: searchQuery }
            );

            searchAndSortUsers(searchQuery, sortItemValue);
        },
        [searchAndSortUsers, navStore, listIdentifier, listParameters]
    );

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={displayedUsers}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={25}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
            onSearch={handleSearchInput}
            initialSearchQuery={currentSearchQuery}
            sortItems={sortDropdownItems}
            initialSortItem={initialSortItem}
            onChangeSort={onChangeSort}
        />
    );
};

export default observer(UserRatingsPortalSettingList);
