import React, { useCallback, useContext, useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { Route, selectRoute } from "src/config/routes";
import { observer } from "mobx-react-lite";
import { portal_accounts_part } from "src/api/generated/portal_accounts_part";
import { UI, CollapsableListSection, ListRowProps } from "@wwimmo/ui";
import styles from "./CollapsableAccountList.module.css";
import { PortalAccountlistById_portal_accountlists_accounts } from "src/api/generated/PortalAccountlistById";
import { AccountlistDropdown } from "src/screens/realestates/realestate/finances/AccountlistDropdown";
import { PortalAccountlistsByRealestateId } from "src/api/generated/PortalAccountlistsByRealestateId";
import { useHistory } from "react-router-dom";
import { useUrlQuery } from "src/utils/Route";
import { isEmpty } from "lodash";
import { CollapsableBookinglist } from "src/screens/realestates/realestate/finances/account/collapsableBookinglist/CollapsableBookinglist";
import { dateFormat } from "src/utils/Date";
import { getCurrencyElement } from "src/utils/Currency";

interface Account extends portal_accounts_part {
    accounts: Account[];
}

enum AccountType {
    GROUP = 1,
    ACCOUNT = 2,
    TOTAL = 3
}

interface Props {
    selectedlistid: any;
    realestateid: any;
    accountList: PortalAccountlistById_portal_accountlists_accounts[];
    accountlistsByRealestateId: PortalAccountlistsByRealestateId;
}

const CollapsableAccountlistBase = (props: Props) => {
    const { t } = useTranslation();
    const { uiStore, authStore, navStore } = useContext(RootStoreContext);

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [accountList, setAccountList] = useState<Account[]>(props.accountList);

    const history = useHistory();

    let urlQuery = useUrlQuery();
    const urlSearchQueryStr = urlQuery.get("search");

    let listIdentifier = "";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);
    let currentSearchQuery: string | undefined = undefined;

    if (listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    } else if (urlSearchQueryStr) {
        currentSearchQuery = urlSearchQueryStr;
    }

    useEffect(() => {
        setAccountList(props.accountList);
    }, [props.accountList]);

    const onListExpandChange = useCallback(
        (expanded: string[]) => {
            uiStore.setFinanceListExpandedItems(expanded);
        },
        [uiStore]
    );

    const headerRows: ListRowProps[] = [
        {
            cells: [
                { label: t("screens.realestate.finances.account") },
                { label: t("screens.realestate.finances.description") },
                {
                    label: t("screens.realestate.finances.debit"),
                    className: "text-right"
                },
                {
                    label: t("screens.realestate.finances.credit"),
                    className: "text-right"
                },
                { label: t("screens.realestate.finances.account") },
                {
                    label: t("screens.realestate.finances.debit"),
                    className: "text-right"
                },
                {
                    label: t("screens.realestate.finances.credit"),
                    className: "text-right"
                }
            ]
        }
    ];

    const getCollapsableListFromAccounts = useCallback(
        (accounts: Account[], accountlistid: string): CollapsableListSection[] => {
            return accounts.map((account) => {
                if (account.type === AccountType.ACCOUNT) {
                    const accountNumber = String(account.number) || "-";
                    const description =
                        account.title + (account.accounts!.length !== 0 ? " (" + account.accounts!.length + ")" : "") ||
                        "-";
                    return {
                        id: account.id || "",
                        collapsableRow: null,
                        listRow: {
                            to: selectRoute(Route.account, authStore.user?.role, {
                                realestateid: props.realestateid,
                                accountlistid,
                                accountid: account.id
                            }),
                            cells: [
                                { label: accountNumber },
                                {
                                    label: description
                                },
                                { label: getCurrencyElement(account.debit), className: "text-right" },
                                { label: getCurrencyElement(account.credit), className: "text-right" },
                                {
                                    label: `${accountNumber} - ${description}`,
                                    sublabel: "",
                                    className: styles.FinanceCell
                                },
                                {
                                    label: "",
                                    sublabel: getCurrencyElement(account.debit),
                                    className: `${styles.FinanceCell} text-right`
                                },
                                {
                                    label: "",
                                    sublabel: getCurrencyElement(account.credit),
                                    className: `${styles.FinanceCell} text-right`
                                }
                            ],
                            isMobile: uiStore.isMobile
                        },
                        list: []
                    };
                } else {
                    const accountNumber = String(account.number) || "-";
                    const description = account.title + ` (${account.accounts!.length})` || "-";

                    return {
                        id: account.id || "",
                        collapsableRow: [
                            { label: String(account.number) || "-" },
                            { label: description, className: "bold" },
                            {
                                label: getCurrencyElement(account.debit),
                                className: "text-right"
                            },
                            { label: getCurrencyElement(account.credit), className: "text-right" },
                            {
                                label: `${accountNumber} - ${description}`,
                                sublabel: "",
                                className: `${styles.FinanceCell} bold`
                            },
                            {
                                label: "",
                                sublabel: getCurrencyElement(account.debit),
                                className: `${styles.FinanceCell} text-right`
                            },
                            {
                                label: "",
                                sublabel: getCurrencyElement(account.credit),
                                className: `${styles.FinanceCell} text-right`
                            }
                        ],
                        listRow: null,
                        list: getCollapsableListFromAccounts(account.accounts!, accountlistid)
                    };
                }
            });
        },
        [authStore.user?.role, props.realestateid, uiStore.isMobile]
    );

    const [collapsableList, setCollapsableList] = useState<CollapsableListSection[]>(
        getCollapsableListFromAccounts(
            accountList.filter((account) => account.type === AccountType.ACCOUNT || account.type === AccountType.GROUP),
            props.selectedlistid
        )
    );

    useEffect(() => {
        setCollapsableList(
            getCollapsableListFromAccounts(
                accountList.filter(
                    (account) => account.type === AccountType.ACCOUNT || account.type === AccountType.GROUP
                ),
                props.selectedlistid
            )
        );
    }, [accountList, getCollapsableListFromAccounts, props.selectedlistid]);

    const filterAccountList = useCallback(
        (searchQuery: any) => {
            const filteredAccountIds: any[] = [];

            props.accountList.forEach((toplevelAccounts) => {
                toplevelAccounts.accounts.forEach((account) => {
                    account.bookings.forEach((booking) => {
                        const date = dateFormat(new Date(booking.date), "dd.MM.yyyy") || "";
                        if (
                            booking.text?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            date?.toString().includes(searchQuery.toLowerCase())
                        ) {
                            if (!filteredAccountIds.some((item) => item.accountId === booking.accountid)) {
                                filteredAccountIds.push({
                                    accountId: booking.accountid,
                                    toplevelId: toplevelAccounts.id
                                });
                            }
                        } else if (
                            !Number.isNaN(searchQuery.toLowerCase()) &&
                            (booking.credit?.toString().includes(Number(searchQuery.toLowerCase())) ||
                                booking.debit?.toString().includes(Number(searchQuery.toLowerCase())))
                        ) {
                            if (!filteredAccountIds.some((item) => item.accountId === booking.accountid)) {
                                filteredAccountIds.push({
                                    accountId: booking.accountid,
                                    toplevelId: toplevelAccounts.id
                                });
                            }
                        }
                        booking.bookingfiles.forEach((files) => {
                            if (files.name?.toLowerCase().includes(searchQuery.toLowerCase())) {
                                if (!filteredAccountIds.some((item) => item.accountId === booking.accountid)) {
                                    filteredAccountIds.push({
                                        accountId: booking.accountid,
                                        toplevelId: toplevelAccounts.id
                                    });
                                }
                            }
                        });
                    });
                });
            });
            let filteredAccounts: Account[] = [];
            const filteredAccountList: Account[] = [];

            props.accountList.forEach((toplevelAccounts) => {
                filteredAccounts = [];
                filteredAccountIds.forEach((filteredAccount) => {
                    if (filteredAccount.toplevelId === toplevelAccounts.id) {
                        toplevelAccounts.accounts.forEach((account) => {
                            if (
                                filteredAccountIds.some(
                                    (item) => item.accountId === account.id && item.toplevelId === toplevelAccounts.id
                                )
                            ) {
                                if (!filteredAccounts.some((item) => item.id === account.id)) {
                                    filteredAccounts.push(account);
                                }
                            }
                        });
                    }
                });

                if (filteredAccounts.length > 0) {
                    if (!filteredAccountList.some((item) => item.id === toplevelAccounts.id)) {
                        filteredAccountList.push({
                            __typename: toplevelAccounts.__typename,
                            accounts: filteredAccounts,
                            bookings: toplevelAccounts.bookings,
                            credit: toplevelAccounts.credit,
                            debit: toplevelAccounts.debit,
                            id: toplevelAccounts.id,
                            number: toplevelAccounts.number,
                            title: toplevelAccounts.title,
                            type: toplevelAccounts.type
                        });
                    }
                }
            });

            setAccountList(filteredAccountList);
        },
        [props.accountList]
    );

    const onSearch = useCallback(
        (e: any) => {
            urlQuery.set("search", e.target.value ?? "");
            history.replace({
                search: urlQuery.toString()
            });
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: e.target.value }
                    : { scrollPosition: 0, searchQuery: searchQuery }
            );

            setSearchQuery(e.target.value);
            filterAccountList(e.target.value);
        },
        [filterAccountList, listIdentifier, listParameters, navStore, searchQuery, history, urlQuery]
    );

    useEffect(() => {
        if (currentSearchQuery) {
            if (currentSearchQuery!.length > 0 && urlSearchQueryStr === null) {
                urlQuery.set("search", currentSearchQuery ?? "");
                history.replace({
                    search: urlQuery.toString()
                });
                filterAccountList(currentSearchQuery);
            }
        }
    }, [currentSearchQuery, urlSearchQueryStr, filterAccountList, history, urlQuery]);

    useEffect(() => {
        if (isEmpty(searchQuery)) {
            if (currentSearchQuery) {
                urlQuery.set("search", currentSearchQuery ?? "");
                history.replace({
                    search: urlQuery.toString()
                });

                navStore.setListMapValue(
                    listIdentifier,
                    listParameters
                        ? { ...listParameters, scrollPosition: 0, searchQuery: currentSearchQuery }
                        : { scrollPosition: 0, searchQuery: searchQuery }
                );
                setSearchQuery(currentSearchQuery);
                filterAccountList(currentSearchQuery);
            }
        }
        // Only re-render should cause running of this function
        // eslint-disable-next-line
    }, []);

    let footerRowValues = accountList.filter((account) => account.type === AccountType.TOTAL);
    let footerRows = footerRowValues.map((rowValue) => {
        return {
            cells: [
                { label: "" },
                { label: rowValue.title },
                { label: getCurrencyElement(rowValue.debit), className: "text-right" },
                { label: getCurrencyElement(rowValue.credit), className: "text-right" },
                { label: rowValue.title },
                { label: getCurrencyElement(rowValue.debit), className: "text-right" },
                { label: getCurrencyElement(rowValue.credit), className: "text-right" }
            ]
        };
    });

    const onAccountsSelectorChange = useCallback(
        (newId: string) => {
            uiStore.setFinanceListSelectionId(newId);
            history.push(
                selectRoute(Route.finances, authStore.user?.role, {
                    realestateid: props.realestateid,
                    accountlistid: newId
                })
            );
        },
        [uiStore, authStore.user?.role, history, props.realestateid]
    );

    return (
        <>
            <UI.Row>
                <UI.Col>
                    <AccountlistDropdown
                        realestateid={props.realestateid}
                        selectedlistid={props.selectedlistid}
                        data={props.accountlistsByRealestateId}
                        onChange={onAccountsSelectorChange}
                    />
                </UI.Col>
                <UI.Col>
                    <UI.Input
                        type="search"
                        onChange={onSearch}
                        placeholder={t("labels.search")}
                        value={searchQuery}
                    ></UI.Input>
                </UI.Col>
            </UI.Row>
            <>
                {searchQuery.length > 0 ? (
                    <CollapsableBookinglist accounts={accountList} searchQuery={searchQuery} />
                ) : (
                    <>
                        {collapsableList ? (
                            <UI.List.CollapsableList
                                className="mt-4 font-small"
                                headerRows={headerRows}
                                footerRows={footerRows}
                                colspansMd={[1.25, 3, 1, 1, 0, 0, 0]}
                                colspans={[0, 0, 0, 0, 1, 1, 1]}
                                listSections={collapsableList}
                                onChange={onListExpandChange}
                                expandedItems={uiStore.financeListExpandedItems}
                                isMobile={uiStore.isMobile}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </>
        </>
    );
};

export const CollapsableAccountlist = Sentry.withProfiler(observer(CollapsableAccountlistBase));
