import { UI } from "@wwimmo/ui";
import { useState, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./CopyAssemblyAgendaButton.module.css";
import { useTranslation } from "react-i18next";
import * as Screens from "src/screens";
import { CopyAssemblyAgendaModal } from "./CopyAssemblyAgendaModal";
import {
    GetAgendaitemInformationToCopy,
    GetAgendaitemInformationToCopyVariables
} from "src/api/generated/GetAgendaitemInformationToCopy";
import { useQuery } from "@apollo/client";
import { GET_AGENDAITEMS_INFORMATION_TO_COPY } from "src/api/assembly";

interface CopyAssemblyAgendaButtonProps {
    assemblyid: string;
    realestateid: string;
    assemblydate: string;
    refetchAgendaItems: any;
}

const CopyAssemblyAgendaButtonBase = (props: CopyAssemblyAgendaButtonProps) => {
    const [showCopyAssemblyAgendaModal, setShowCopyAssemblyAgendaModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const { assemblyid, realestateid, assemblydate, refetchAgendaItems } = props;

    const { data, loading, error } = useQuery<GetAgendaitemInformationToCopy, GetAgendaitemInformationToCopyVariables>(
        GET_AGENDAITEMS_INFORMATION_TO_COPY,
        {
            variables: {
                assemblyid: assemblyid,
                realestateid: realestateid,
                assemblydate: assemblydate
            }
        }
    );

    const handleShowCopyAssemblyAgendaModal = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setShowCopyAssemblyAgendaModal(true);
    }, []);

    const handleDisposeCopyAssemblyAgendaModal = useCallback(async () => {
        setShowCopyAssemblyAgendaModal(false);
    }, []);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    const agendaitemsCounter = data.agendaitemtexts_by_assembly[0]?.agendaitemsCounter?.aggregate
        ? data.agendaitemtexts_by_assembly[0]?.agendaitemsCounter.aggregate?.count
        : 0;

    const agendaitemTemplatesCounter = data.agendaitemtemplatesCounter?.aggregate
        ? data.agendaitemtemplatesCounter.aggregate?.count
        : 0;

    return (
        <>
            {showCopyAssemblyAgendaModal && (agendaitemTemplatesCounter > 0 || agendaitemsCounter > 0) ? (
                <CopyAssemblyAgendaModal
                    onClickClose={handleDisposeCopyAssemblyAgendaModal}
                    assemblyid={assemblyid}
                    realestateid={realestateid}
                    refetchAgendaItems={refetchAgendaItems}
                    agendaitemTemplatesCounter={agendaitemTemplatesCounter}
                    agendaitemsCounter={agendaitemsCounter}
                    agendaItemTemplates={data.agendaitemtemplatetexts}
                    agendaItems={data.agendaitemtexts_by_assembly[0]?.agendaitemtexts}
                />
            ) : null}

            <UI.Button
                label={t("screens.realestate.assembly.agendacopy")}
                onClick={handleShowCopyAssemblyAgendaModal}
                className={`${styles.CopyAgendaItemsButton} ml-sm-2 mt-2 mt-sm-0`}
            ></UI.Button>
        </>
    );
};

export const CopyAssemblyAgendaButton = Sentry.withProfiler(observer(CopyAssemblyAgendaButtonBase));
