import { action, makeObservable, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";

export class ServicesInfoModalStore {
    rootStore: RootStore;
    isServiceInfoModalDisplayed = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this, {
            isServiceInfoModalDisplayed: observable,
            setIsServiceInfoModalDisplayed: action
        });
    }

    setIsServiceInfoModalDisplayed = (isModalDisplayed: boolean) => {
        this.isServiceInfoModalDisplayed = isModalDisplayed;
    };
}
