import { ScreenSize, UI } from "@wwimmo/ui";
import styles from "./NotificationStatusModalList.module.css";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useEffect, useState } from "react";
import { uuid4 } from "@sentry/utils";
import { RootStoreContext } from "src/stores/RootStore";
import { GetDataAssemblyNotification } from "./GetDataAssemblyNotification";
import { GetDataNewsNotification } from "./GetDataNewsNotification";
import { INotificationStatus } from "src/stores/NotificationStore";
import { getDateAndTime } from "src/utils/Date";

export interface NotificationStatusListProps {
    notificationStatusList: INotificationStatus[];
}

export enum NOTIFICATIONSTATE {
    NEWS = 0,
    ASSEMBLY = 1
}

export const NotificationStatusModalList = (props: NotificationStatusListProps) => {
    const { t } = useTranslation();
    const { uiStore, notificationStore } = useContext(RootStoreContext);

    const [modalNotificationStatus, setModalNotificationStatus] = useState<INotificationStatus[]>(
        props.notificationStatusList
    );

    let notificationStateData: any;
    if (notificationStore.isNewsOrAssemblyState === NOTIFICATIONSTATE.NEWS) {
        notificationStateData = GetDataNewsNotification();
    } else if (notificationStore.isNewsOrAssemblyState === NOTIFICATIONSTATE.ASSEMBLY) {
        notificationStateData = GetDataAssemblyNotification();
    }

    useEffect(() => {
        if (Array.isArray(notificationStateData) && notificationStateData.length > 0) {
            const notificationStates: INotificationStatus[] = notificationStateData;
            setModalNotificationStatus(notificationStates);
        }
        // useEffect only re-render when notificationStateData.loading is changed
        // eslint-disable-next-line
    }, [notificationStateData.loading]);

    const getStatusAndStatusSince = useCallback(
        (state: number) => {
            let userStatus = "";

            switch (state) {
                case 1:
                    userStatus = t("screens.realestate.notifications.states.not_registered");
                    break;
                case 2:
                    userStatus = t("screens.realestate.notifications.states.not_notified");
                    break;
                case 3:
                    userStatus = t("screens.realestate.notifications.states.notified");
                    break;
                case 4:
                    userStatus = t("screens.realestate.notifications.states.read_notification");
                    break;
                case 5:
                    userStatus = t("screens.realestate.notifications.states.viewd_assembly");
                    break;
                default:
                    userStatus = t("screens.realestate.notifications.states.not_registered");
            }

            return userStatus;
        },
        [t]
    );

    const renderNotificationStatusItem = useCallback(
        (notificationStatus: INotificationStatus, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={1.5}
                                value={notificationStatus.name}
                                className={styles.ListCell}
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1.5}
                                value={notificationStatus.email}
                                className={styles.ListCell}
                            />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={1}
                                value={getDateAndTime(notificationStatus.date || "")}
                            />
                            <UI.List.Cell
                                key={"c-4"}
                                colspan={1.2}
                                value={getStatusAndStatusSince(notificationStatus.state)}
                                className={styles.ListCell}
                            />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={""} />
                        </UI.List.Row>,
                        <UI.List.Row key={"r-2"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={""} className="sublabel" />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Item key={uuid4()} screenSize={screenSize} rows={listRows} />;
        },
        [getStatusAndStatusSince]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={1.5}
                                value={t("screens.realestate.notifications.name")}
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1.5}
                                value={t("screens.realestate.notifications.email")}
                            />
                            <UI.List.Cell key={"c-3"} colspan={1} value={t("screens.realestate.notifications.date")} />
                            <UI.List.Cell
                                key={"c-4"}
                                colspan={1.2}
                                value={t("screens.realestate.notifications.state")}
                            />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={t("screens.realestate.notifications.name")} />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} />;
        },
        [t]
    );

    return (
        <div>
            <div className={styles.ModalTitle}>{t("screens.realestate.notifications.modal_title")}</div>
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={modalNotificationStatus}
                renderItem={renderNotificationStatusItem}
                renderHeader={renderHeader}
            />
        </div>
    );
};

export default NotificationStatusModalList;
