import { gql } from "@apollo/client";

const MAINTENANCES_FRAGMENTS = gql`
    fragment maintenance_details on common_maintenances {
        id
        date
        name
        status
        appliance {
            name
        }
        craftsman: person {
            id
            legal
            name1
            name2
        }
    }
`;

export const GET_MAINTENANCES = gql`
    ${MAINTENANCES_FRAGMENTS}
    query GetMaintenances(
        $realestateid: uuid!
        $unitid: uuid
        $applianceid: uuid
        $lang: String = "de"
        $isunitidnull: Boolean
    ) {
        maintenances: common_maintenances(
            where: {
                realestateid: { _eq: $realestateid }
                unitid: { _eq: $unitid, _is_null: $isunitidnull }
                applianceid: { _eq: $applianceid }
            }
            order_by: { date: desc_nulls_last }
        ) {
            ...maintenance_details
        }

        maintenancestatus: common_enumerations(
            where: { type: { _eq: 14 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;

export const GET_REALESTATE_MAINTENANCES = gql`
    ${MAINTENANCES_FRAGMENTS}
    query GetRealestateMaintenances($realestateid: uuid!, $lang: String = "de", $isunitidnull: Boolean) {
        maintenances: common_maintenances(
            where: { realestateid: { _eq: $realestateid }, unitid: { _is_null: $isunitidnull } }
            order_by: { date: desc_nulls_last }
        ) {
            ...maintenance_details
        }

        maintenancestatus: common_enumerations(
            where: { type: { _eq: 14 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;

export const GET_UNIT_MAINTENANCES = gql`
    ${MAINTENANCES_FRAGMENTS}
    query GetUnitMaintenances($realestateid: uuid!, $unitid: uuid, $lang: String = "de", $isunitidnull: Boolean) {
        maintenances: common_maintenances(
            where: { realestateid: { _eq: $realestateid }, unitid: { _eq: $unitid, _is_null: $isunitidnull } }
            order_by: { date: desc_nulls_last }
        ) {
            ...maintenance_details
        }

        maintenancestatus: common_enumerations(
            where: { type: { _eq: 14 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;
