import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { Route, selectRoute } from "src/config/routes";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { GET_COOWNER_NEWS, GET_COOWNER_NEWS_IT2 } from "src/api/news";
import {
    GetCoownerNews,
    GetCoownerNewsVariables,
    GetCoownerNews_realestates_newsitems_newsitem as newsitems
} from "src/api/generated/GetCoownerNews";
import * as Screens from "src/screens";
import { getLang } from "src/utils/i18n";
import { ErpType } from "src/network/User";
import { TilesDashboard } from "src/screens/realestates/realestate/dashboard/tilesDashboard/TilesDashboard";

interface Params {
    realestateid: string;
}

const CoownerDashboardBase = () => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);
    const { realestateid } = useParams<Params>();

    useEffect(() => {
        if (!navStore.singleRealestate) {
            navStore.setBackbutton(
                selectRoute(Route.realestates, authStore.portalRoleWithHighestPriority ?? authStore.user?.role),
                t("screens.realestates.title")
            );
        }
        uiStore.setShowNavigationBar(false);

        return () => {
            uiStore.setShowNavigationBar(true);
        };
    }, [authStore.user, navStore, uiStore, t, authStore.portalRoleWithHighestPriority]);

    const { data, loading, error } = useQuery<GetCoownerNews, GetCoownerNewsVariables>(
        authStore.user?.hasCoownerRole && authStore.user.erpType === ErpType.IT2
            ? GET_COOWNER_NEWS_IT2
            : GET_COOWNER_NEWS,
        {
            variables: {
                language: getLang(),
                realestateid: realestateid
            }
        }
    );

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const newsItems: newsitems[] = [];

    data.realestates.forEach((realestate) => {
        realestate.newsitems.forEach((newsItem) => {
            if (newsItem.newsitem) {
                newsItems.push(newsItem.newsitem);
            }
        });
    });

    const newsSortedItems = newsItems.sort((itemA, itemB) => {
        if (itemA.timestamp < itemB.timestamp) {
            return 1;
        } else if (itemA.timestamp > itemB.timestamp) {
            return -1;
        } else {
            return 0;
        }
    });

    return <TilesDashboard coownerNewsSortedItems={newsSortedItems} tenantNewsSortedItems={[]} />;
};

export const CoownerDashboard = Sentry.withProfiler(observer(CoownerDashboardBase));
