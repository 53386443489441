import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { useForm } from "react-hook-form";
import styles from "./PortalDemoSettingsForm.module.css";
import { GetDemoPortalSettings_ums_demoportalsettings as PortalDemoSettings } from "src/api/generated/GetDemoPortalSettings";
import { useMutation } from "@apollo/client";
import { UPSERT_DEMO_PORTAL_SETTINGS } from "src/api/demoPortal";
import {
    UpsertDemoPortalSettings,
    UpsertDemoPortalSettingsVariables
} from "src/api/generated/UpsertDemoPortalSettings";
import { MessageType } from "src/components/notifications/Notifier";
import { ums_demoportalsettings_insert_input } from "src/api/generated/globalTypes";
import { getLang } from "src/utils/i18n";

interface PortalDemoFormProps {
    portalDemoSettings: PortalDemoSettings;
    refetchPortalDemoSettings: any;
    refetchStwegDemoData: any;
}

interface PortalDemoFormFields {
    id: string;
    text1: string;
    text2: string;
    text1default?: string;
    text2default?: string;
    contactperson: string;
    onlinecontactemail: string;
    email: string;
    mobile: string;
    phone: string;
    language: string;
}

const PortalDemoFormSettingsBase = (props: PortalDemoFormProps) => {
    const { t } = useTranslation();
    const { uiStore } = useContext(RootStoreContext);

    const { refetchPortalDemoSettings, refetchStwegDemoData, portalDemoSettings } = props;

    const [upsertCustomersettingMutation] = useMutation<UpsertDemoPortalSettings, UpsertDemoPortalSettingsVariables>(
        UPSERT_DEMO_PORTAL_SETTINGS,
        {
            onCompleted: () => {
                uiStore.printStatusMessage(t("screens.portalDemo.success_save_message"), MessageType.INFO);
            }
        }
    );

    const { register, handleSubmit, reset } = useForm<PortalDemoFormFields>({
        mode: "onBlur",
        defaultValues: {
            id: portalDemoSettings.id,
            text1: portalDemoSettings.text1 ?? "",
            text2: portalDemoSettings.text2 ?? "",
            email: portalDemoSettings.email ?? "",
            contactperson: portalDemoSettings.contactperson ?? "",
            mobile: portalDemoSettings.mobile ?? "",
            onlinecontactemail: portalDemoSettings.onlinecontactemail ?? "",
            phone: portalDemoSettings.phone ?? "",
            language: getLang()
        }
    });

    const resetForm = useCallback(async () => {
        try {
            const insertInputList: ums_demoportalsettings_insert_input[] = [];

            const insertInput: ums_demoportalsettings_insert_input = {
                id: portalDemoSettings.id,
                text1: portalDemoSettings.text1default,
                text2: portalDemoSettings.text2default,
                contactperson: portalDemoSettings.contactperson,
                onlinecontactemail: portalDemoSettings.onlinecontactemail,
                email: portalDemoSettings.email,
                mobile: portalDemoSettings.mobile,
                phone: portalDemoSettings.phone,
                language: getLang()
            };

            insertInputList.push(insertInput);

            await upsertCustomersettingMutation({
                variables: {
                    objects: insertInputList
                }
            });

            refetchPortalDemoSettings();
            refetchStwegDemoData();
        } catch (error: any) {
            uiStore.printStatusMessage(
                t("screens.portalDemo.failure_save_message", {
                    errorMessage: error.message
                }),
                MessageType.ERROR
            );
        }

        reset({
            id: portalDemoSettings.id,
            text1: portalDemoSettings.text1default ?? "",
            text2: portalDemoSettings.text2default ?? "",
            email: portalDemoSettings.email ?? "",
            contactperson: portalDemoSettings.contactperson ?? "",
            mobile: portalDemoSettings.mobile ?? "",
            onlinecontactemail: portalDemoSettings.onlinecontactemail ?? "",
            phone: portalDemoSettings.phone ?? "",
            language: getLang()
        });
    }, [
        reset,
        portalDemoSettings,
        uiStore,
        refetchPortalDemoSettings,
        refetchStwegDemoData,
        t,
        upsertCustomersettingMutation
    ]);

    const handleFormSubmit = useCallback(
        async (formData: PortalDemoFormFields) => {
            try {
                const insertInputList: ums_demoportalsettings_insert_input[] = [];

                const insertInput: ums_demoportalsettings_insert_input = {
                    id: formData.id,
                    text1: formData.text1,
                    text2: formData.text2,
                    contactperson: formData.contactperson,
                    onlinecontactemail: formData.onlinecontactemail,
                    email: formData.email,
                    mobile: formData.mobile,
                    phone: formData.phone,
                    language: formData.language
                };

                insertInputList.push(insertInput);

                await upsertCustomersettingMutation({
                    variables: {
                        objects: insertInputList
                    }
                });

                refetchPortalDemoSettings();
                refetchStwegDemoData();
            } catch (error: any) {
                uiStore.printStatusMessage(
                    t("screens.portalDemo.failure_save_message", {
                        errorMessage: error.message
                    }),
                    MessageType.ERROR
                );
            }
        },
        [refetchPortalDemoSettings, refetchStwegDemoData, upsertCustomersettingMutation, uiStore, t]
    );

    return (
        <UI.Form>
            <UI.Card title={t("screens.portalDemo.title")}>
                <div className="mt-2">
                    <label className="form-label">{t("screens.portalDemo.text1")}</label>
                    <UI.Input {...register("text1")} as="textarea" type="text" className={styles.Textarea} />
                    <label className="form-label">{t("screens.portalDemo.text2")}</label>
                    <UI.Input {...register("text2")} as="textarea" type="text" />
                    <UI.Row className={styles.ContactRow}>
                        <UI.Col className={styles.ContactCol1}>
                            <div className={styles.DivContastInputs}>
                                <UI.Input
                                    {...register("contactperson")}
                                    label={t("screens.portalDemo.contactperson")}
                                    type="text"
                                />
                                <UI.Input {...register("phone")} label={t("screens.portalDemo.phone")} type="tel" />
                                <UI.Input {...register("mobile")} label={t("screens.portalDemo.mobile")} type="tel" />
                                <UI.Input {...register("email")} label={t("screens.portalDemo.email")} type="email" />
                            </div>
                        </UI.Col>
                        <UI.Col className={styles.ContactCol1}>
                            {/* Wird zu späterem Zeitpunkt verwendet */}
                            {/* <UI.Input
                                {...register("onlinecontactemail")}
                                label={t("screens.portalDemo.onlinecontactemail")}
                                type="email"
                            /> */}
                        </UI.Col>
                    </UI.Row>
                </div>
                <div className={`mt-4 ${styles.DivButtons}`}>
                    <UI.Button
                        type="submit"
                        label={t("labels.save")}
                        disabled={false}
                        onClick={handleSubmit(handleFormSubmit)}
                        className={styles.FormButton}
                    />
                    <UI.Button
                        label={t("screens.portalDemo.reset")}
                        disabled={false}
                        onClick={resetForm}
                        className={`${styles.DiscardButton} ml-sm-2 mt-2 mt-sm-0`}
                        variant="danger"
                    />
                </div>
            </UI.Card>
        </UI.Form>
    );
};

export const PortalDemoFormSettings = Sentry.withProfiler(observer(PortalDemoFormSettingsBase));
