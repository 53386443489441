import React, { useContext } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import styles from "./TicketHistoryTimeline.module.css";
import { TicketHistoryTimelineEntry } from "./entry/TicketHistoryTimelineEntry";

const TicketHistoryTimelineBase = () => {
    const { ticketStore } = useContext(RootStoreContext);

    return ticketStore.currentTicket?.history && ticketStore.currentTicket.history?.length > 0 ? (
        <div className={styles.TimelineContainer}>
            {ticketStore.currentTicket.history.map((historyEntry, index) => {
                return <TicketHistoryTimelineEntry key={index} historyEntry={historyEntry} />;
            })}
        </div>
    ) : (
        <></>
    );
};

export const TicketHistoryTimeline = Sentry.withProfiler(observer(TicketHistoryTimelineBase));
