import React, { useCallback, useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./Service7000Description.module.css";
import generalStyles from "src/screens/tickets/ticket/details/actions/assignOrder/AssignOrderModal.module.css";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";

export const Service7000DescriptionBase = () => {
    const { t } = useTranslation();
    const { uiStore, assignOrderModalStore } = useContext(RootStoreContext);

    const onClickBack = useCallback(() => {
        assignOrderModalStore.moveToPrevProcessStep();
    }, [assignOrderModalStore]);

    const onClickSendOrder = useCallback(() => {
        const isServiceOrderValid = assignOrderModalStore.validateServiceOrderDescription();

        if (isServiceOrderValid) {
            assignOrderModalStore.moveToNextProcessStep();
            assignOrderModalStore.createOrder();
        }
    }, [assignOrderModalStore]);

    const onChangeServiceProviderOrderDescription = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newServiceProviderOrderDescriptionText = e.target.value;

            assignOrderModalStore.setServiceProviderOrderDescription(newServiceProviderOrderDescriptionText);
        },
        [assignOrderModalStore]
    );

    return (
        <>
            <UI.Row>
                <UI.Col xs={12} className={`mt-4 ${styles.Service7000DescriptionContainer}`}>
                    <UI.Input
                        label={`${t("screens.tickets.modals.assign_order.order_text").toString()} *`}
                        type="text"
                        as="textarea"
                        autoComplete="off"
                        value={assignOrderModalStore.serviceProviderOrderDescription}
                        onChange={onChangeServiceProviderOrderDescription}
                        errorMsg={assignOrderModalStore.errors.serviceOrderDescription ?? undefined}
                        className={styles.DescriptionTextArea}
                    />
                </UI.Col>
            </UI.Row>
            <UI.Row className={`mt-2 d-flex ${uiStore.isMobile ? generalStyles.MobileRowButton : ""}`}>
                <UI.Col>
                    <UI.Button
                        className={`ticket-button mt-2 ${generalStyles.BackButton}`}
                        label={t("labels.goback")}
                        variant={"outline-primary"}
                        onClick={onClickBack}
                    ></UI.Button>
                </UI.Col>
                <UI.Col>
                    <UI.Button
                        className={`ticket-button mt-2 ${generalStyles.NextButton}`}
                        label={t("labels.create_order")}
                        onClick={onClickSendOrder}
                    ></UI.Button>
                </UI.Col>
            </UI.Row>
        </>
    );
};

export const Service7000Description = Sentry.withProfiler(observer(Service7000DescriptionBase));
