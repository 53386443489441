import { UI } from "@wwimmo/ui";
import { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { ColorStyle } from "src/utils/Colors";
import { AssignInvoiceProgress } from "src/screens/creditors/creditor/details/actions/assignInvoice/progress/AssignInvoiceProgress";
import { WORKFLOWUSER_ROLE } from "src/stores/krediflow/InvoiceEnums";
import { ASSIGN_INVOICE_STATE, IUserToAssign } from "src/stores/krediflow/AssignInvoiceStore";
import styles from "./AssignInvoiceModal.module.css";

interface AssignInvoiceModalProps {
    workflowUserRole: WORKFLOWUSER_ROLE;
}

const AssignInvoiceModalBase = (props: AssignInvoiceModalProps) => {
    const { t } = useTranslation();
    const { assignInvoiceStore } = useContext(RootStoreContext);

    const { workflowUserRole } = props;

    const toggleDisplayAssignInvoiceModal = useCallback(() => {
        const modalIsBeingClosed = assignInvoiceStore.isModalDisplayed;

        assignInvoiceStore.setIsModalDisplayed(!assignInvoiceStore.isModalDisplayed, workflowUserRole);

        if (modalIsBeingClosed) {
            assignInvoiceStore.resetAssignInvoiceModal();
        }
    }, [assignInvoiceStore, workflowUserRole]);

    const onChangeAssignUserInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const assignUser = e.target.value;
            assignInvoiceStore.setCurrentAssignUserSearchQuery(assignUser);
        },
        [assignInvoiceStore]
    );

    const onClickAssignUserItem = useCallback(
        (assignUserId: string) => () => {
            assignInvoiceStore.assignInvoiceToWorkflowUser(assignUserId, workflowUserRole);
        },
        [assignInvoiceStore, workflowUserRole]
    );

    const getUserToAssignElement = useCallback(
        (userToAssign: IUserToAssign) => {
            return (
                <div
                    key={userToAssign.userid}
                    className={styles.AssignUserItem}
                    onClick={onClickAssignUserItem(userToAssign.userid)}
                >
                    <UI.Icon icon={UI.SVGIcon.Person} color={ColorStyle("black")} />
                    <div className={styles.AssignUserItemName}>{userToAssign.name}</div>
                    <UI.Icon className="ml-auto" icon={UI.SVGIcon.ChevronRight} color={ColorStyle("primary")} />
                </div>
            );
        },
        [onClickAssignUserItem]
    );

    return (
        <UI.Modal
            title={t("screens.tickets.action.assign_ticket.select_manager").toString()}
            size="lg"
            show={
                assignInvoiceStore.isModalDisplayed && assignInvoiceStore.modalCurrentlyUsedForRole === workflowUserRole
            }
            onClose={toggleDisplayAssignInvoiceModal}
            centered={true}
        >
            {assignInvoiceStore.assignInvoiceState === ASSIGN_INVOICE_STATE.INIT ? (
                <>
                    <UI.Row className={"mt-2"}>
                        <UI.Col sm={12}>
                            <UI.Input
                                type="text"
                                autoComplete="off"
                                className={styles.FormInput}
                                id="assign-invoice-select-user-input"
                                value={assignInvoiceStore.currentUserToAssignSearchQuery ?? ""}
                                onChange={onChangeAssignUserInput}
                                placeholder={t("labels.search").toString()}
                                iconRight={
                                    <UI.Icon
                                        icon={UI.SVGIcon.Search}
                                        color="grey"
                                        size={"small"}
                                        style={{ right: "5px" }}
                                    />
                                }
                            />
                        </UI.Col>
                    </UI.Row>
                    <UI.Row className={"mt-2"}>
                        <UI.Col sm={12}>
                            <div className={styles.AssignUserContainer}>
                                {assignInvoiceStore.filteredUsersToAssignData.map((user) => {
                                    return getUserToAssignElement(user);
                                })}
                            </div>
                        </UI.Col>
                    </UI.Row>
                </>
            ) : undefined}

            {assignInvoiceStore.assignInvoiceState === ASSIGN_INVOICE_STATE.ASSIGNING ? (
                <AssignInvoiceProgress
                    key={"assign-invoice-progress-assigning"}
                    currentAssignInvoiceState={ASSIGN_INVOICE_STATE.ASSIGNING}
                />
            ) : undefined}
            {assignInvoiceStore.assignInvoiceState === ASSIGN_INVOICE_STATE.FINISHED ? (
                <AssignInvoiceProgress
                    key={"assign-invoice-progress-finished"}
                    currentAssignInvoiceState={ASSIGN_INVOICE_STATE.FINISHED}
                />
            ) : undefined}
        </UI.Modal>
    );
};

export const AssignInvoiceModal = Sentry.withProfiler(observer(AssignInvoiceModalBase));
