import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { TicketHistoryComment } from "./historyComment/TicketHistoryComment";
import { TicketHistoryTimeline } from "./historyTimeline/TicketHistoryTimeline";
import { useParams } from "react-router";
import styles from "./TicketHistory.module.css";
import { Route, getRoute } from "src/config/routes";

const TicketHistoryBase = () => {
    const { t } = useTranslation();
    const { ticketStore, navStore, authStore } = useContext(RootStoreContext);
    const { ticketnumber } = useParams<{ ticketnumber: string }>();

    useEffect(() => {
        async function initializeTicketData() {
            await ticketStore.init(ticketnumber);
        }
        if (ticketStore.currentTicket === undefined) {
            initializeTicketData();
        }

        const role = authStore.user?.role;
        const currentPage = role ? getRoute(window.location.pathname, role) : undefined;

        if (currentPage === Route.ticketHistory) {
            navStore.setTitle("");
        }

        // only run one time. will only run if TicketHistory component is openend by itself
        // eslint-disable-next-line
    }, []);

    return ticketStore.currentTicket?.isNewTicket ? (
        <></>
    ) : (
        <div className={styles.TicketHistoryContainer}>
            <div className={`${styles.TicketHistoryTitle} mb-3`}>{t("screens.tickets.history.section_title")}</div>
            {/* History Comment Section */}
            <TicketHistoryComment />
            {/* History Entries Section */}
            <TicketHistoryTimeline />
        </div>
    );
};

export const TicketHistory = Sentry.withProfiler(observer(TicketHistoryBase));
