import React, { useCallback, useContext, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import SplitPane from "react-split-pane";
import { InvoiceForm } from "src/screens/creditors/creditor/details/form/InvoiceForm";
import { InvoiceFilePreview } from "src/screens/creditors/creditor/details/preview/InvoiceFilePreview";
import { DEFAULT_INVOICE_PANE_WIDTH_RATIO } from "src/stores/UIStore";
import "./SplitView.css";

const SplitViewBase = () => {
    const { navStore, uiStore, invoiceStore } = useContext(RootStoreContext);

    const splitPaneRef = useRef<any>(null);

    useEffect(() => {
        const invoicePaneWidthUserSetting = navStore.user.invoicePaneWidthUserSetting;

        if (
            invoicePaneWidthUserSetting &&
            invoicePaneWidthUserSetting.value &&
            Number(invoicePaneWidthUserSetting.value) !== 0
        ) {
            uiStore.setInvoicePaneWidthRatio(Number(invoicePaneWidthUserSetting.value));
        } else {
            uiStore.setInvoicePaneWidthRatio(DEFAULT_INVOICE_PANE_WIDTH_RATIO);
        }

        // Needs to only run one time
        // eslint-disable-next-line
    }, []);

    // Use ResizeObserver to observe the width of the SplitPane
    useEffect(() => {
        const element = splitPaneRef.current.splitPane;

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === element) {
                    let newSplitPaneWidth = Math.floor(entry.contentRect.width);

                    if (newSplitPaneWidth !== uiStore.splitPaneWidth) {
                        uiStore.setSplitPaneWidth(newSplitPaneWidth);
                        uiStore.setInvoicePaneWidth(newSplitPaneWidth * uiStore.invoicePaneWidthRatio);
                    }
                }
            }
        });

        if (element) {
            resizeObserver.observe(element);
        }

        return () => {
            if (element) {
                resizeObserver.unobserve(element);
            }
        };
    }, [uiStore]);

    const onDragFinished = useCallback(
        (newSizeNumber: number) => {
            if (!uiStore.isResettingInvoicePaneWidth && newSizeNumber) {
                if (newSizeNumber > uiStore.splitPaneWidth) {
                    uiStore.setInvoicePaneWidth(uiStore.splitPaneWidth);

                    const pane = document.querySelector(".Pane.vertical.Pane1") as HTMLElement;
                    if (pane) {
                        pane.style.width = `${uiStore.splitPaneWidth}px`;
                    }
                } else {
                    uiStore.setInvoicePaneWidth(newSizeNumber);
                }
            }
        },
        [uiStore]
    );

    const onResizerDoubleClick = useCallback(() => {
        uiStore.resetInvoicePaneWidth();
    }, [uiStore]);

    return (
        <div style={{ height: uiStore.invoiceFormContainerHeight }}>
            <SplitPane
                ref={splitPaneRef}
                className="invoice-details-split-pane"
                defaultSize={700}
                size={uiStore.invoicePaneWidth}
                onDragFinished={onDragFinished}
                onResizerDoubleClick={onResizerDoubleClick}
            >
                <InvoiceForm />
                <InvoiceFilePreview
                    file={invoiceStore.currentInvoice?.invoiceFile}
                    reviewWindowWidth={uiStore.invoiceFilePreviewWidth}
                />
            </SplitPane>
        </div>
    );
};

export const SplitView = Sentry.withProfiler(observer(SplitViewBase));
