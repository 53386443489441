import React, { useContext } from "react";
import { UI } from "@wwimmo/ui";
import { Ticket } from "src/stores/TicketStore";
import { format } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import styles from "./TicketListRow.module.css";
import { ColorStyle } from "src/utils/Colors";
import { getIcon } from "src/screens/tickets/Tickets";
import { RootStoreContext } from "src/stores/RootStore";
import { SCREEN_SIZE } from "src/stores/UIStore";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";

const TicketListRowDesktopBase = (props: { ticket: Ticket; widths: number[] }) => {
    const { uiStore } = useContext(RootStoreContext);

    const {
        number,
        date,
        workflow_type,
        appliance,
        incidentContactPerson: person,
        state,
        realestate,
        assignedUser,
        title
    } = props.ticket;

    const { widths } = props;
    const { t } = useTranslation();

    return (
        <UI.List.Row className={styles.TicketListRow} key={number}>
            <UI.List.Cell
                className={`${uiStore.currentScreenSize <= SCREEN_SIZE.LAPTOP_L ? "d-none" : "d-flex"}`}
                value={
                    <div className={styles.TypeIcon}>
                        <img
                            src={getIcon(workflow_type)}
                            alt={t(`easyticket.workflow_types.${String(workflow_type)}`) ?? "icon"}
                        />
                    </div>
                }
                colspan={widths[0]}
            />
            <UI.List.Cell value={number} colspan={widths[1]} />
            <UI.List.Cell
                value={
                    uiStore.currentScreenSize > SCREEN_SIZE.LAPTOP
                        ? Ticket.formatDate(date ?? format(new Date(), "d.M.yy"))
                        : Ticket.formatDate(date ?? format(new Date(), "d.M.yy"), true)
                }
                colspan={widths[2]}
            />
            <UI.List.Cell
                colspan={widths[3]}
                value={
                    <>
                        <strong>
                            {title ? title : <Trans>easyticket.workflow_types.{String(workflow_type)}</Trans>}
                        </strong>
                        {appliance && (
                            <>
                                <br />
                                <span>{appliance.name}</span>
                            </>
                        )}
                    </>
                }
                truncate
            />
            <UI.List.Cell
                colspan={widths[4]}
                value={
                    realestate && (
                        <div>
                            <div>{realestate.name}</div>
                            <div>
                                {realestate.zip} {realestate.city}
                            </div>
                        </div>
                    )
                }
                truncate
            />
            <UI.List.Cell
                className={`${uiStore.currentScreenSize < SCREEN_SIZE.LAPTOP ? "d-none" : "d-flex"}`}
                colspan={widths[5]}
                value={
                    person && (
                        <div>
                            <div>
                                {person.name1} {person.name2 ?? ""}
                            </div>
                            {person.email && <div>{person.email}</div>}
                            {person.mobile && <div>{person.mobile}</div>}
                        </div>
                    )
                }
                truncate
            />
            <UI.List.Cell
                value={
                    <div className={styles.CellContainerCenter}>
                        {uiStore.currentScreenSize > SCREEN_SIZE.LAPTOP ? (
                            <UI.Icon
                                icon={UI.SVGIcon.Profile}
                                color={ColorStyle(assignedUser ? "primary" : "yellow")}
                            />
                        ) : undefined}
                        {assignedUser ? `${assignedUser.name1} ${assignedUser.name2}` : "-"}
                    </div>
                }
                colspan={widths[6]}
                truncate
            />
            <UI.List.Cell
                className={`${uiStore.currentScreenSize <= SCREEN_SIZE.LAPTOP ? "d-none" : "d-flex"}`}
                colspan={widths[7]}
                value={
                    <div className={styles[`status-${state}`]}>
                        <Trans>easyticket.states.{state}</Trans>
                    </div>
                }
                truncate
            />
        </UI.List.Row>
    );
};

export const TicketListRowDesktop = Sentry.withProfiler(observer(TicketListRowDesktopBase));
