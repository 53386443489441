import React, { useState, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import CookieConsent from "react-cookie-consent";
import { Disclaimers } from "src/screens/auth/register/Disclaimers";
import { UI } from "@wwimmo/ui";
import { useTranslation, Trans } from "react-i18next";
import "./CookieConsentMessage.css";

export const CookieConsentMessageBase = () => {
    const { t } = useTranslation();
    const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);

    const onShowDisclaimer = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            setShowDisclaimer(true);
        },
        [setShowDisclaimer]
    );
    const onHideDisclaimer = useCallback(() => setShowDisclaimer(false), [setShowDisclaimer]);

    const termsOfUse = t("screens.auth.register.disclaimers");

    return (
        <>
            <CookieConsent
                containerClasses="CookieConsentContainer"
                buttonClasses="btn CookieConsentButton"
                buttonText={t("labels.ok")}
                overlay
            >
                <UI.Col className="CookieConsentText">
                    <Trans i18nKey="cookie_consent">
                        A <button onClick={onShowDisclaimer}>{{ termsOfUse }}</button> B
                    </Trans>
                </UI.Col>
            </CookieConsent>
            <UI.Modal
                title={t("screens.auth.register.disclaimers").toString()}
                show={showDisclaimer}
                onClose={onHideDisclaimer}
                size="lg"
            >
                <Disclaimers />
            </UI.Modal>
        </>
    );
};

export const CookieConsentMessage = Sentry.withProfiler(observer(CookieConsentMessageBase));
