import { UI } from "@wwimmo/ui";
import { useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { ORDER_LOADING_TYPE, ORDER_STATE } from "src/stores/OrderStore";
import styles from "./RetrySubmissionButton.module.css";

const RetrySubmissionButtonBase = () => {
    const { orderStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const onClickRetrySubmissionButton = useCallback(async () => {
        await orderStore.retrySubmission();
    }, [orderStore]);

    const retrySubmissionButtonLabel = (
        <div className="d-flex align-items-center position-relative">
            <div>{t("labels.retry_submission")}</div>
            <UI.RotatingSpinner
                className={`${styles.ButtonSpinner} ${
                    orderStore.loadingType !== ORDER_LOADING_TYPE.RETRY_SUBMISSION ? "d-none" : ""
                }`}
                noLogo={true}
                size={20}
            />
        </div>
    );

    return (
        <UI.Button
            label={retrySubmissionButtonLabel}
            onClick={onClickRetrySubmissionButton}
            className={"ticket-button mt-2"}
            hidden={orderStore.currentOrder?.state !== ORDER_STATE.SUBMISSION_FAILED}
        />
    );
};

export const RetrySubmissionButton = Sentry.withProfiler(observer(RetrySubmissionButtonBase));
