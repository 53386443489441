import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_INVOICES_BY_DATE } from "src/api/invoice";
import {
    GetCustomerInvoicesByDate,
    GetCustomerInvoicesByDateVariables
} from "src/api/generated/GetCustomerInvoicesByDate";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { withRouter, RouteComponentProps } from "react-router";
import { getDateForHasuraQuery } from "src/utils/Date";
import { getLang } from "src/utils/i18n";
import InvoiceDetailsList from "src/screens/services/costsAndInvoices/invoiceDetailsList/InvoiceDetailsList";

export interface InvoiceDetail {
    yearAndMonth: string;
    amount: number;
    invoiced: string;
}

export interface InvoiceWithCostType {
    customerInvoiceId?: number;
    costType?: number;
    count?: number;
    costPerUnit?: number;
    total: number;
    isTotalRow?: boolean;
    countUserInvoiced: number;
}

const ServicesCostsAndInvoicesCurrentMonthBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);

    const currentDate = new Date();
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).toString();

    const { data, loading, error } = useQuery<GetCustomerInvoicesByDate, GetCustomerInvoicesByDateVariables>(
        GET_CUSTOMER_INVOICES_BY_DATE,
        {
            variables: {
                date: getDateForHasuraQuery(lastDayOfMonth),
                language: getLang()
            }
        }
    );

    useEffect(() => {
        navStore.setTitle("");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);
        navStore.setBackbutton(null);
    }, [navStore, t, authStore.user?.role, uiStore.isSidebarActive]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    let customerInvoiceItems: InvoiceWithCostType[] = [];
    let totalOfAllInvoices: number = 0;

    data.customerinvoices.forEach((invoice) => {
        const count = invoice.count ?? 0;
        const total = invoice.costperunit * count;
        const customerInvoiceItem: InvoiceWithCostType = {
            customerInvoiceId: invoice.id,
            costType: invoice.costtype ?? 0,
            count: count,
            costPerUnit: invoice.costperunit,
            total: total,
            isTotalRow: false,
            countUserInvoiced: invoice.customerinvoiceusers_aggregate.aggregate?.count ?? 0
        };

        totalOfAllInvoices += total;
        customerInvoiceItems.push(customerInvoiceItem);
    });

    // Add the "TOTAL" row at the end of the list
    if (data.customerinvoices.length > 0) {
        customerInvoiceItems.push({
            total: totalOfAllInvoices,
            isTotalRow: true,
            countUserInvoiced: 0
        });
    }

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Title>{t("screens.services.costs_and_invoices.current_month.title")}</UI.Title>
                <UI.Row>
                    <UI.Col md={12}>
                        <UI.Card>
                            <div>
                                <InvoiceDetailsList
                                    invoiceItems={customerInvoiceItems}
                                    costTypeEnumeration={data.costtype_enumeration}
                                />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const ServicesCostsAndInvoicesCurrentMonth = withRouter(
    Sentry.withProfiler(observer(ServicesCostsAndInvoicesCurrentMonthBase))
);
