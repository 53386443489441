import React, { useContext } from "react";
import { NetworkConfig } from "src/network/NetworkConfig";
import styles from "./Auth.module.css";
import { RootStoreContext } from "src/stores/RootStore";
import { useEffect } from "react";
import { runInAction } from "mobx";
import { ColorStyle } from "src/utils/Colors";
import { UI } from "@wwimmo/ui";

export const AuthWrapper = (props: any) => {
    const { navStore, uiStore } = useContext(RootStoreContext);
    useEffect(() => {
        runInAction(() => {
            navStore.resetActionButtons();
            uiStore.setBackgroundColor(ColorStyle("white"));
        });
    }, [navStore, uiStore]);

    return (
        <UI.Container fluid className="flex-grow-1 d-flex">
            <UI.Row className="flex-grow-1">
                <UI.Col sm={6} md={3} lg={4} className="d-flex align-items-center justify-content-center p-4">
                    <img src={NetworkConfig.brandAssets.logoImage} className={styles.customerLogo} alt="Logo" />
                </UI.Col>
                <UI.Col
                    sm={6}
                    md={9}
                    lg={8}
                    className={`d-flex align-items-center justify-content-center py-4 ${styles.container}`}
                >
                    <UI.Container>
                        <UI.Row>
                            <UI.Col sm={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                                {props.children}
                            </UI.Col>
                        </UI.Row>
                    </UI.Container>
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};
