import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useSelectionListKeyHandler } from "src/hooks/ticket/selection-list-key-handler/useSelectionListKeyHandler";
import { boldenMatchedSubstring } from "src/utils/Common";
import { ColorStyle } from "src/utils/Colors";
import debounce from "lodash/debounce";
import styles from "./TicketFormField.module.css";
import "./GeneralInvoiceFormStyles.css";
import { ITicket } from "src/stores/krediflow/InvoiceTypes";
import { TICKET_SEARCH_FILTER_TYPE } from "src/stores/krediflow/TicketSearchStore";

const TicketFormFieldBase = () => {
    const { t } = useTranslation();
    const { ticketStore, uiStore, realestateSearchStore, invoiceStore, ticketSearchStore } =
        useContext(RootStoreContext);

    const [displayTicketList, setDisplayTicketList] = useState<boolean>(false);
    const [lastScrollPositionYTicketItems, setLastSrollYPositionTicketItems] = useState<number>(0);

    const ticketInputRef = useRef<HTMLInputElement>(null);

    const resetSearchQuery = useCallback(() => {
        ticketSearchStore.setCurrentTicketSearchQuery(ticketSearchStore.selectedTicketQueryString ?? "");
    }, [ticketSearchStore]);

    useEffect(() => {
        const handleClickOutsideTicketInput = (event: any) => {
            if (
                displayTicketList &&
                ticketInputRef.current &&
                !ticketInputRef.current.contains(event.target) &&
                !(event.target.classList.contains("list-item") && event.target.classList.contains("ticket")) &&
                !(event.target.classList.contains("highlighted-text") && event.target.classList.contains("ticket")) &&
                !event.target.classList.contains("ticket-list-item-span") &&
                !event.target.classList.contains("ticket-filter-container") &&
                !event.target.classList.contains("ticket-filter-button") &&
                !event.target.classList.contains("ticket-filter-button-text") &&
                !event.target.classList.contains("ticket-filter-button-icon") &&
                !event.target.classList.contains("ticket-list-item-line-1") &&
                !event.target.classList.contains("ticket-list-item-line-2") &&
                !event.target?.parentElement?.parentElement?.parentElement.classList?.contains("IconSVG")
            ) {
                setDisplayTicketList(false);

                if (
                    invoiceStore.currentInvoice?.isTicketSelected &&
                    ticketSearchStore.currentTicketSearchQuery !== ticketSearchStore.selectedTicketQueryString
                ) {
                    invoiceStore.resetTicketFormField();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutsideTicketInput);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideTicketInput);
        };
    }, [
        invoiceStore,
        displayTicketList,
        invoiceStore.currentInvoice?.isTicketSelected,
        ticketSearchStore.currentTicketSearchQuery,
        ticketSearchStore.selectedTicketQueryString
    ]);

    useEffect(() => {
        if (ticketSearchStore.triggerFocusOnTicketInput) {
            ticketInputRef.current?.focus();
            ticketSearchStore.setTriggerFocusOnTicketInput(false);
        }
        // This useEffect only listens to changes in ticketSearchStore.triggerFocusOnTicketInput
        // eslint-disable-next-line
    }, [ticketSearchStore.setTriggerFocusOnTicketInput]);

    const updateInvoiceStoreWithSelectedTicket = useCallback(
        async (ticket: ITicket | undefined) => {
            if (ticket) {
                invoiceStore.updateInvoiceAndFormWithSelectedTicket(ticket);
            }
        },
        [invoiceStore]
    );

    const onSelectTicketItem = useCallback(
        (activeListItemIndex: number) => {
            const selectedTicketItem = ticketSearchStore.filteredTicketData[activeListItemIndex];

            updateInvoiceStoreWithSelectedTicket(selectedTicketItem);
        },
        [ticketSearchStore.filteredTicketData, updateInvoiceStoreWithSelectedTicket]
    );

    const { onKeyDownFunction, setActiveListItemIndex, NO_LIST_ITEM_SELECTED } = useSelectionListKeyHandler({
        listName: "ticket",
        totalNumberOfDisplayedItems: invoiceStore.numberOfDisplayedItems,
        setIsListDisplayed: setDisplayTicketList,
        isListDisplayed: displayTicketList,
        onSelectItem: onSelectTicketItem,
        resetSearchQuery: resetSearchQuery
    });

    const resetAmountOfItemsDisplayedInTicketList = useCallback(() => {
        setLastSrollYPositionTicketItems(0);
        ticketStore.setNumberOfDisplayedItems(ticketStore.sliceSizeOfDisplayedItems);
    }, [ticketStore]);

    const searchTicket = debounce((query: string) => {
        ticketSearchStore.searchTicketsInBackendByQuery(query);
    }, 800);

    const onChangeTicketInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            resetAmountOfItemsDisplayedInTicketList();

            const inputTicket = e.target.value;
            ticketSearchStore.setCurrentTicketSearchQuery(inputTicket);

            if (ticketSearchStore.isSearchingTicketsInBackendByQuery) {
                if (inputTicket.length > 2) {
                    searchTicket(inputTicket);
                } else {
                    ticketSearchStore.setTicketData([]);
                }
            }
        },

        // searchTicket function cannot be in dependency array, because it is a debounced function
        // eslint-disable-next-line
        [ticketSearchStore, resetAmountOfItemsDisplayedInTicketList]
    );

    const onFocusTicketInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (invoiceStore.currentInvoice?.isTicketSelected && ticketInputRef.current) {
                ticketInputRef.current.select();
            }

            resetAmountOfItemsDisplayedInTicketList();
            setDisplayTicketList(true);
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
        },
        [
            resetAmountOfItemsDisplayedInTicketList,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            invoiceStore.currentInvoice?.isTicketSelected
        ]
    );

    const onClickTicketItem = useCallback(
        (ticket: ITicket) => async (e: any) => {
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
            setDisplayTicketList(false);

            updateInvoiceStoreWithSelectedTicket(ticket);
        },
        [updateInvoiceStoreWithSelectedTicket, setActiveListItemIndex, NO_LIST_ITEM_SELECTED]
    );

    const onScrollTicketList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYTicketItems;

            const hasMoreResulsToDisplay =
                invoiceStore.numberOfDisplayedItems < ticketSearchStore.filteredTicketData.length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                invoiceStore.setNumberOfDisplayedItems(
                    invoiceStore.numberOfDisplayedItems + invoiceStore.sliceSizeOfDisplayedItems
                );
            }

            setLastSrollYPositionTicketItems(pixelsFromTop);
        },
        [lastScrollPositionYTicketItems, invoiceStore, ticketSearchStore.filteredTicketData.length]
    );

    const getSpanElementWithFormattedText = useCallback(
        (stringToFormat: string) => {
            const formattedString =
                ticketSearchStore.currentTicketSearchQuery !== ""
                    ? boldenMatchedSubstring(
                          ticketSearchStore.currentTicketSearchQuery ?? "",
                          stringToFormat,
                          "highlighted-text ticket"
                      )
                    : stringToFormat;

            return (
                <span
                    className="ticket-list-item-span"
                    dangerouslySetInnerHTML={{
                        __html: formattedString
                    }}
                />
            );
        },
        [ticketSearchStore.currentTicketSearchQuery]
    );

    const toggleTicketFilter = useCallback(
        (ticketSearchFilterType: TICKET_SEARCH_FILTER_TYPE) => () => {
            switch (ticketSearchFilterType) {
                case TICKET_SEARCH_FILTER_TYPE.REALESTATE:
                    ticketSearchStore.toggleTicketSearchFilterRealestate();
                    break;
            }
        },
        [ticketSearchStore]
    );

    const filterActivationIcon = uiStore.isMobile ? (
        <div className="ticket-filter-button-icon">+</div>
    ) : (
        <UI.Icon className="ticket-filter-button-icon" icon={UI.SVGIcon.Plus} size={25} color={ColorStyle("grey")} />
    );

    const filterDeactivationIcon = (
        <UI.Icon className="ticket-filter-button-icon" icon={UI.SVGIcon.Close} size={12} color={ColorStyle("white")} />
    );

    const ticketFilterButtonRealestate = (
        <div
            className={`ticket-filter-button ${!realestateSearchStore.selectedRealestateName ? "not-clickable" : ""} ${
                ticketSearchStore.ticketSearchFilter.byRealestate ? "filter-active" : ""
            }`}
            onClick={
                realestateSearchStore.selectedRealestateName
                    ? toggleTicketFilter(TICKET_SEARCH_FILTER_TYPE.REALESTATE)
                    : undefined
            }
        >
            <div className="ticket-filter-button-text">
                {t("screens.tickets.form.person.realestate_filter_abr")}: {realestateSearchStore.selectedRealestateName}
            </div>
            {ticketSearchStore.ticketSearchFilter.byRealestate ? filterDeactivationIcon : filterActivationIcon}
        </div>
    );

    const ticketSelectionList = (
        <div id="ticket-dropdown-list-container">
            <div className={"ticket-filter-container"}>{ticketFilterButtonRealestate}</div>
            <div
                className={`${styles.TicketSelectionListSearchIndicator} ${
                    !ticketSearchStore.isSearchingTicketData &&
                    !(ticketSearchStore.ticketData.length === 0 && !ticketSearchStore.isSearchingTicketData)
                        ? styles.NoBorderBottom
                        : ""
                }`}
            >
                {ticketSearchStore.isSearchingTicketData ? (
                    <UI.RotatingSpinner noLogo size={30} className={styles.SearchingTicketsSpinner} />
                ) : undefined}
            </div>
            {ticketSearchStore.isSearchingTicketData ? undefined : (
                <ul id="ticket-dropdown-list" className={"dropdown-list ticket"} onScroll={onScrollTicketList}>
                    {ticketSearchStore.filteredTicketData
                        .slice(0, invoiceStore.numberOfDisplayedItems)
                        .map((ticket, index) => {
                            const formattedTicketNumber = ticket.number
                                ? ticket.number.toString().padStart(5, "0")
                                : "";

                            const formattedTicketDateLongSpan = getSpanElementWithFormattedText(ticket.date_long ?? "");
                            const formattedTicketTitleSpan = getSpanElementWithFormattedText(ticket.title ?? "");
                            const formattedTicketNumberSpan = getSpanElementWithFormattedText(
                                formattedTicketNumber ?? ""
                            );

                            return (
                                <li key={index}>
                                    <div
                                        className={"list-item ticket"}
                                        id={`ticket-list-item-${index}`}
                                        onClick={onClickTicketItem(ticket)}
                                    >
                                        {uiStore.isMobile ? (
                                            <>
                                                <div>{formattedTicketDateLongSpan}</div>
                                                <div>{formattedTicketTitleSpan}</div>
                                                <div>{formattedTicketNumberSpan}</div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={"ticket-list-item-line-1"}>
                                                    {formattedTicketDateLongSpan}, {formattedTicketTitleSpan} [
                                                    {formattedTicketNumberSpan}]
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            )}
        </div>
    );

    const readOnlyTicketElement = invoiceStore.currentInvoice?.isTicketSelected ? (
        <div className="view-only-container mt-3 mt-lg-0">
            <div className="ticket bold-text mb-1">{t("screens.tickets.ticket")}</div>
            <div className="d-flex">
                <span className="text-with-number">{`${invoiceStore.currentInvoice.ticket?.date}, ${invoiceStore.currentInvoice?.ticket?.title}`}</span>
                <span>{`${
                    invoiceStore.currentInvoice?.ticket?.number ? `[${invoiceStore.currentInvoice?.ticket.number}]` : ""
                }`}</span>
            </div>
        </div>
    ) : (
        <></>
    );

    return (
        <>
            {invoiceStore.isEditing ? (
                <>
                    <UI.Input
                        label={t("screens.tickets.ticket").toString()}
                        ref={ticketInputRef}
                        type="text"
                        autoComplete="off"
                        className={styles.TicketInputField}
                        id="invoice-form-ticket-input"
                        value={ticketSearchStore.currentTicketSearchQuery ?? ""}
                        onChange={onChangeTicketInput}
                        onFocus={onFocusTicketInput}
                        onKeyDown={onKeyDownFunction}
                        disabled={!invoiceStore.isEditing}
                    />
                    {displayTicketList ? ticketSelectionList : undefined}
                </>
            ) : (
                readOnlyTicketElement
            )}
        </>
    );
};

export const TicketFormField = Sentry.withProfiler(observer(TicketFormFieldBase));
