import { gql } from "@apollo/client";

export const GET_APPLIANCE_DETAILS = gql`
    query GetApplianceDetails($applianceid: uuid!, $lang: String = "de") {
        appliance: common_appliances(where: { id: { _eq: $applianceid } }) {
            id
            manufacturer
            model
            name
            serialnumber
            productionnumber
            remarks
            servicecompany: person {
                id
                name1
                name2
                legal
            }
            purchasedate
            lastrevision
            nextrevision
            lastofficialinspection
            nextofficialinspection
            servicestart
            serviceend
            serviceconditions
            outofservicedate
            maintenances(order_by: { date: desc_nulls_first }) {
                id
                date
                name
                status
            }
            unit {
                id
                name
            }
        }

        maintenancestatus: common_enumerations(
            where: { type: { _eq: 14 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;
