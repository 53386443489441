import React, { useCallback, useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { observer } from "mobx-react";
import { UseFormRegister, DeepMap, FieldError, UseFormWatch } from "react-hook-form";
import { GetStreetsInCity, GetStreetNumbersInStreet } from "src/utils/Location";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";
import "src/screens/realestates/realestate/assemblies/manager/edit/form/AssembliesEditForm.css";
import {
    SEARCH_STATE,
    ERROR_TYPE,
    LIST_TYPE,
    AssemblyEditFormFields
} from "src/screens/realestates/realestate/assemblies/manager/edit/form/AssembliesEditForm";
import { handleListKeyDown, handleListKeyUp, setFocus } from "src/utils/AutocompleteInputHelper";

interface StreetAutocompleteInputProps {
    setZipWasSelected: any;
    streetWasSelected: boolean;
    setStreetWasSelected: any;
    setStreetNumberWasSelected: any;
    setNumbersOfSelectedStreet: any;
    closeAllLists: any;
    setValue: any;
    watch: UseFormWatch<AssemblyEditFormFields>;
    watchStreet: string;
    register: UseFormRegister<AssemblyEditFormFields>;
    errors: DeepMap<AssemblyEditFormFields, FieldError>;
    setSearchType: any;
    searchType: SEARCH_STATE;
    setError: any;
    streetItems: { id: string; street: string }[];
    setStreetItems: any;
    zipWasSelected: boolean;
    cityWasSelected: boolean;
    currentStreet: string;
    setCurrentStreet: any;
    dirtyFields: DeepMap<AssemblyEditFormFields, true>;
}

const StreetAutocompleteInputBase = (props: StreetAutocompleteInputProps) => {
    const { t } = useTranslation();

    const [activeListItemIndex, setActiveListItemIndex] = useState<number>(-1);
    const [hasRendered, setHasRendered] = useState<boolean>(false);

    const {
        setError,
        searchType,
        streetWasSelected,
        setStreetWasSelected,
        setStreetNumberWasSelected,
        setNumbersOfSelectedStreet,
        setStreetItems,
        streetItems,
        setValue,
        register,
        watch,
        watchStreet,
        errors,
        setSearchType,
        closeAllLists,
        zipWasSelected,
        cityWasSelected,
        currentStreet,
        setCurrentStreet,
        dirtyFields
    } = props;

    useEffect(() => {
        if (!hasRendered) {
            setHasRendered(true);
        } else if (watchStreet !== currentStreet) {
            onChangeInputStreet();
        }
        // Only changes to 'watchStreet' should cause an running of this function
        // eslint-disable-next-line
    }, [watchStreet]);

    const onChangeInputStreet = debounce(async () => {
        setError(ERROR_TYPE.NONE);
        closeAllLists();

        setStreetWasSelected(false);
        setStreetNumberWasSelected(false);

        setValue("housenumber", "", { shouldDirty: true });

        setSearchType(SEARCH_STATE.STREET);

        const inputStreet = watchStreet.trim();

        if (inputStreet.length > 1) {
            const streets = await GetStreetsInCity({ zip: watch("zip"), streetName: inputStreet });
            setStreetItems(streets);

            if (streets.length === 0) {
                setError(ERROR_TYPE.STREET);
            }
        } else {
            setStreetItems([]);
        }

        setSearchType(SEARCH_STATE.NONE);
    }, 800);

    const onClickInputStreet = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            closeAllLists();
            setActiveListItemIndex(-1);

            const streetName = e.target.value.trim();
            if (streetName.length > 1 && !streetWasSelected) {
                const streets = await GetStreetsInCity({ zip: watch("zip"), streetName: streetName });

                setStreetItems(streets);

                if (streets.length === 0) {
                    setError(ERROR_TYPE.STREET);
                }
            } else {
                setStreetItems([]);
            }
        },
        [closeAllLists, streetWasSelected, watch, setError, setStreetItems]
    );

    const setStreetFormValue = useCallback(
        (streetName: string) => {
            setCurrentStreet(streetName);
            setStreetWasSelected(true);
            setStreetItems([]);
            setValue("street", streetName);

            setFocus("number");
        },
        [setValue, setStreetItems, setStreetWasSelected, setCurrentStreet]
    );

    const setStreetNumbers = useCallback(
        async (streetId: string) => {
            const streetNumbers = await GetStreetNumbersInStreet(streetId);
            setNumbersOfSelectedStreet(streetNumbers);
        },
        [setNumbersOfSelectedStreet]
    );

    const onClickStreetItem = useCallback(
        async (e: any) => {
            const streetName = e.target.innerHTML;
            const streetId = e.target.getAttribute("data-streetid");

            setStreetFormValue(streetName);
            setStreetNumbers(streetId);
        },
        [setStreetNumbers, setStreetFormValue]
    );

    const onFocusInput = useCallback(
        (e: any) => {
            closeAllLists();
            setActiveListItemIndex(-1);
        },
        [closeAllLists]
    );

    const onKeyDownInputStreet = useCallback(
        async (e: any) => {
            if (e.key === "Tab") {
                closeAllLists();
                onChangeInputStreet.cancel();

                const streetName = e.target.value.trim();

                const isStreetListItemSelected = streetItems.length > 0 && activeListItemIndex !== -1;

                if (isStreetListItemSelected) {
                    e.preventDefault();
                    setStreetFormValue(streetItems[activeListItemIndex].street);
                    setStreetNumbers(streetItems[activeListItemIndex].id);
                } else if (streetName.length > 3) {
                    setSearchType(SEARCH_STATE.STREET);

                    const currentZipValue = watch("zip");
                    const streets = await GetStreetsInCity({ zip: currentZipValue, streetName: streetName });

                    if (streets.length === 0) {
                        setError(ERROR_TYPE.STREET);
                    }

                    const exactStreet = streets.find(
                        (street: { id: number; street: string }) =>
                            street.street.toLowerCase() === streetName.toLowerCase()
                    );

                    if (exactStreet) {
                        setStreetFormValue(exactStreet.street);
                        setStreetNumbers(exactStreet.id);
                    }

                    setSearchType(SEARCH_STATE.NONE);
                }
            } else if (streetItems.length > 0 && e.key === "ArrowDown") {
                handleListKeyDown(streetItems.length, LIST_TYPE.STREET, activeListItemIndex, setActiveListItemIndex);
            } else if (streetItems.length > 0 && e.key === "ArrowUp") {
                handleListKeyUp(LIST_TYPE.STREET, activeListItemIndex, setActiveListItemIndex);
            } else if (streetItems.length > 0 && e.key === "Enter") {
                setStreetFormValue(streetItems[activeListItemIndex].street);
                setStreetNumbers(streetItems[activeListItemIndex].id);
            }
        },
        [
            watch,
            closeAllLists,
            onChangeInputStreet,
            streetItems,
            activeListItemIndex,
            setStreetNumbers,
            setStreetFormValue,
            setError,
            setSearchType
        ]
    );

    const loadingSpinner = <UI.RotatingSpinner noLogo size={20} className="loading-items-spinner" />;

    const streetSelectionList = (
        <ul
            id="street-dropdown-list"
            className={`${searchType === SEARCH_STATE.STREET ? "searching" : ""} dropdown-list street`}
        >
            {searchType === SEARCH_STATE.STREET ? loadingSpinner : undefined}
            {streetItems && streetItems.length > 0
                ? streetItems.map((item, index) => (
                      <li key={index}>
                          <div
                              className="list-item"
                              id={`street-list-item-${index}`}
                              data-streetid={item.id}
                              onClick={onClickStreetItem}
                          >
                              {item.street}
                          </div>
                      </li>
                  ))
                : undefined}
        </ul>
    );

    return (
        <>
            <UI.Input
                id="street"
                label={`${t("screens.realestate.assembly.street")}`}
                {...register("street")}
                type="text"
                onFocus={onFocusInput}
                onKeyDown={onKeyDownInputStreet}
                onClick={onClickInputStreet}
                autoComplete="nope"
                readOnly={!(zipWasSelected && cityWasSelected)}
                errorMsg={errors.street ? errors.street.message : undefined}
                className={dirtyFields.street ? "changed" : ""}
            />
            {streetSelectionList}
        </>
    );
};

export const StreetAutocompleteInput = Sentry.withProfiler(observer(StreetAutocompleteInputBase));
