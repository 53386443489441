import { useCallback } from "react";

export const useSetFocus = () => {
    const setFocus = useCallback((inputElementId: string) => {
        const inputElement: HTMLInputElement | null = document?.getElementById(inputElementId) as HTMLInputElement;

        if (inputElement) {
            var tempEl = document.createElement("input");

            tempEl.style.height = "0";
            tempEl.style.opacity = "0";

            inputElement.appendChild(tempEl);
            tempEl.focus();

            setTimeout(function () {
                inputElement.focus();
                inputElement.click();

                inputElement.removeChild(tempEl);
            }, 100);
        }
    }, []);

    return setFocus;
};
