import { UI } from "@wwimmo/ui";
import { useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { LOADING_TYPE } from "src/stores/TicketStore";
import styles from "./CloseTicketButton.module.css";

interface CloseTicketButtonProps {
    onClick: () => void;
}

const CloseTicketButtonBase = (props: CloseTicketButtonProps) => {
    const { onClick } = props;
    const { ticketStore, authStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const onClickCloseTicketButton = useCallback(() => {
        onClick();
    }, [onClick]);

    const closeTicketButtonLabel = (
        <div className="d-flex align-items-center position-relative">
            <div className={styles.ButtonLabel}>{t("screens.tickets.close")}</div>
            <UI.RotatingSpinner
                className={`${styles.ButtonSpinner} ${
                    ticketStore.loadingType !== LOADING_TYPE.CHECK_INCIDENT_CAN_BE_COMPLETED ? "d-none" : ""
                }`}
                noLogo={true}
                size={20}
            />
        </div>
    );

    const isAssignedToCurrentUser =
        authStore.user?.userid && authStore.user?.userid === ticketStore.currentTicket?.assignedUser?.id;

    return (
        <UI.Button
            label={closeTicketButtonLabel}
            onClick={onClickCloseTicketButton}
            disabled={ticketStore.isEditing || !isAssignedToCurrentUser}
            className="ticket-button"
        />
    );
};

export const CloseTicketButton = Sentry.withProfiler(observer(CloseTicketButtonBase));
