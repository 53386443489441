import Cookies from "js-cookie";

type SameSiteOption = "Strict" | "Lax" | "None";

export const setCookie = (name: string, value: string, sameSite?: SameSiteOption) => {
    const options: Cookies.CookieAttributes = { path: "/" };

    if (sameSite) {
        options.sameSite = sameSite;
    }

    Cookies.set(name, value, options);
};

export const getCookie = (name: string) => {
    return Cookies.get(name);
};

export const removeCookie = (name: string) => {
    Cookies.remove(name, { path: "/" });
};
