import { RootStore } from "src/stores/RootStore";
import { createApolloClient } from "./createApolloClient";
import { ApolloClient } from "@apollo/client";

export let apolloClientInstance: ApolloClient<any>;

export function initializeApolloClient(rootStore: RootStore) {
    if (!apolloClientInstance) {
        apolloClientInstance = createApolloClient(rootStore.authStore);
    }
}
