import { observable, action, makeObservable, computed } from "mobx";
import { Config } from "src/config/config";
import { Customer, BackButton, User } from "src/types";
import { CUSTOMER_FEATURE, CUSTOMER_OPTION } from "src/api/customer";
import { RootStore } from "./RootStore";
export interface ListParameters {
    searchQuery?: string;
    scrollPosition?: number;
    paginationPage?: number;
    filterValue?: string;
    sortValue?: string;
}

const HISTORY_STACK_MAX_SIZE = 20;

export class NavigationStore {
    rootStore: RootStore;

    @observable customer: Customer = {
        name: "",
        userName: "",
        email: "",
        options: [],
        features: []
    };
    @observable user: User = {};
    @observable closeNavTarget = "";
    @observable currentTitle = "";
    @observable appheaderTitle = "";
    @observable currentSubtitle: string | null = null;
    @observable singleRealestate: boolean = false;
    @observable isCondominium: boolean = false;
    @observable isConsolidation: boolean = false;
    @observable currentOwnerId: string | null = null;
    @observable backButton: BackButton | null = null;
    @observable.ref actionButtonLeft: React.ReactNode | null = null;
    @observable.ref actionButtonMiddle: React.ReactNode | null = null;
    @observable.ref actionButtonRight: React.ReactNode | null = null;
    @observable listMap: Map<string, ListParameters> = new Map();
    @observable historyStack: string[] = [];
    @observable consolidationId: string = "";

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @action
    getPreviousPage = () => {
        if (this.historyStack.length >= 2) {
            return this.historyStack[this.historyStack.length - 2];
        } else {
            return undefined;
        }
    };

    @action
    addToHistoryStack = (url: string) => {
        if (this.historyStack.length < HISTORY_STACK_MAX_SIZE) {
            this.historyStack.push(url);
        } else {
            this.historyStack.shift();
            this.historyStack.push(url);
        }
    };

    @action
    setListMapValue = (listIdentifier: string, value: ListParameters) => {
        this.listMap.set(listIdentifier, value);
    };

    @action
    getListMapValue = (key: string) => {
        return this.listMap.get(key);
    };

    @action
    setActionButtonMiddle = (actionButton: React.ReactNode | null) => {
        this.actionButtonMiddle = actionButton;
    };
    @action
    setActionButtonLeft = (actionButton: React.ReactNode | null) => {
        this.actionButtonLeft = actionButton;
    };
    @action
    setActionButtonRight = (actionButton: React.ReactNode | null) => {
        this.actionButtonRight = actionButton;
    };
    @action
    resetActionButtons = () => {
        this.actionButtonRight = null;
        this.actionButtonLeft = null;
        this.actionButtonMiddle = null;
    };

    @action
    setCurrentSubTitle = (currentSubTitle: string | null) => {
        this.currentSubtitle = currentSubTitle;
    };

    @action
    setCurrentOwnerId = (currentOwnerId: string | null) => {
        this.currentOwnerId = currentOwnerId;
    };

    @action
    setSingleRealEstate = (singleRealEstate: boolean) => {
        this.singleRealestate = singleRealEstate;
    };

    @action
    setIsCondominium = (isCondominium: boolean) => {
        this.isCondominium = isCondominium;
    };

    @action
    setIsConsolidation = (isConsolidation: boolean) => {
        this.isConsolidation = isConsolidation;
    };

    @action
    setConsolidationId = (consolidationId: string) => {
        this.consolidationId = consolidationId;
    };

    @action
    setBackbutton = (target: string | null, label?: string) => {
        //Remove Backbutton
        if (target === null) {
            this.backButton = null;
            return;
        }
        this.backButton = { label: label || "", to: target };
    };

    @action
    setTitle = (newTitle: string) => {
        const titles: string[] = [];
        if (this.customer.name) {
            titles.push(this.customer.name);
        }
        if (newTitle) {
            titles.push(newTitle);
        } else {
            titles.push(Config.appname);
        }
        this.currentTitle = titles.join(" - ");
        this.appheaderTitle = newTitle;
    };

    @action
    setTabTitle = (newTitle: string) => {
        this.currentTitle = newTitle;
    };

    @action
    setCustomer = (newCustomer: Customer) => {
        this.customer = newCustomer;
    };

    @action
    setUser = (newUser: User) => {
        this.user = newUser;
    };

    @action
    setCloseNavTarget = (newCloseNavTarget: string) => {
        this.closeNavTarget = newCloseNavTarget;
    };

    @action
    reset = () => {
        this.customer = {
            name: "",
            userName: "",
            email: "",
            options: [],
            features: []
        };
        this.closeNavTarget = "";
        this.currentTitle = "";
        this.appheaderTitle = "";
        this.currentSubtitle = null;
        this.singleRealestate = false;
        this.currentOwnerId = null;
        this.backButton = null;
    };

    @computed
    get hasFeatureEasyContact(): boolean {
        return (
            this.customer.features?.find((feature) => feature.feature === CUSTOMER_FEATURE.EASYCONTACT) !== undefined
        );
    }

    @computed
    get hasFeatureService7000(): boolean {
        return (
            this.customer.features?.find((feature) => feature.feature === CUSTOMER_FEATURE.SERVICE7000) !== undefined
        );
    }

    @computed
    get hasFeatureYarowa(): boolean {
        return this.customer.features?.find((feature) => feature.feature === CUSTOMER_FEATURE.YAROWA) !== undefined;
    }

    @computed
    get hasCustomerOptionAppliances(): boolean {
        return this.customer.options?.find((option) => option.option === CUSTOMER_OPTION.APPLIANCES)?.value === 1;
    }

    @computed
    get hasOptionMaintenances(): boolean {
        return this.customer.options?.find((option) => option.option === CUSTOMER_OPTION.MAINTENANCES)?.value === 1;
    }

    @computed
    get hasOptionRealestatePersons(): boolean {
        return (
            this.customer.options?.find((option) => option.option === CUSTOMER_OPTION.REALESTATEPERSONS)?.value === 1
        );
    }

    @computed
    get hasOptionTenantBookingsAndFiles(): boolean {
        return (
            this.customer.options?.find((option) => option.option === CUSTOMER_OPTION.TENANT_BOOKINGS_AND_FILES)
                ?.value === 1
        );
    }

    @computed
    get hasOptionMultilingual(): boolean {
        return this.customer.options?.find((option) => option.option === CUSTOMER_OPTION.MULTILINGUAL)?.value === 1;
    }

    // ASB -> Azure Service Bus
    @computed
    get hasOptionUpdatePersonsViaASB(): boolean {
        return (
            this.customer.options?.find((option) => option.option === CUSTOMER_OPTION.UDPATE_PERSONS_VIA_ASB)?.value ===
            1
        );
    }

    @computed
    get hasFeatureTicketing(): boolean {
        return this.customer.features?.find((feature) => feature.feature === CUSTOMER_FEATURE.TICKETING) !== undefined;
    }

    @computed
    get hasFeatureKrediFlow(): boolean {
        return this.customer.features?.find((feature) => feature.feature === CUSTOMER_FEATURE.KREDI_FLOW) !== undefined;
    }

    @computed
    get hasFeatureTenant(): boolean {
        return (
            this.customer.features?.find((feature) => feature.feature === CUSTOMER_FEATURE.TENANT_PORTAL) !== undefined
        );
    }
}
