import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import { selectRoute, Route } from "src/config/routes";
import { GET_USER_RATINGS_PORTAL } from "src/api/userRating";
import { GetUserRatingsPortal } from "src/api/generated/GetUserRatingsPortal";
import { useQuery } from "@apollo/client";
import * as Screens from "src/screens";
import { UI } from "@wwimmo/ui";
import UserRatingsPortalSettingList from "./list/UserRatingsPortalSettingList";
import styles from "./UserRatingsPortalSetting.module.css";

const UserRatingsPortalSettingBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);

    const { data, loading, error } = useQuery<GetUserRatingsPortal>(GET_USER_RATINGS_PORTAL, {});

    useEffect(() => {
        navStore.setTitle(t("sidebar.settings.userratingsportal"));
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);

        if (uiStore.isSidebarActive) {
            navStore.setBackbutton(null);
        } else {
            navStore.setBackbutton(selectRoute(Route.profile), t("screens.account.title").toString());
        }
    }, [navStore, t, authStore.user?.role, uiStore.isSidebarActive]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const ratingHeaderSubtitle = t("screens.userratingsportalsetting.rating_header_title");
    const reviewsTitle = t("screens.userratingsportalsetting.reviews");
    const avgRatingTitle = t("screens.userratingsportalsetting.avg_rating");
    const ratingNotNullable = data.userratingsportalheader.aggregate?.avg?.rating ?? 0;
    const ratingCounter = data.userratingsportalheader.aggregate?.count ?? 0;
    const ratingAVG = ratingNotNullable > 0 && ratingCounter > 0 ? ratingNotNullable : 0;

    return (
        <React.Fragment>
            <div>
                <UI.Container className="pt-4">
                    <UI.Row>
                        <UI.Col md={12}>
                            <UI.Card title={ratingHeaderSubtitle} className={styles.Card}>
                                <div className={styles.RatingHeaderText}>
                                    {avgRatingTitle} <span className="font-weight-bold">{ratingAVG.toFixed(1)}</span>{" "}
                                    {`- ${ratingCounter} ${reviewsTitle}`}
                                </div>
                            </UI.Card>
                        </UI.Col>
                    </UI.Row>
                    <UI.Row>
                        <UI.Col md={12}>
                            <UI.Card title={reviewsTitle}>
                                <div>
                                    <UserRatingsPortalSettingList userRatingsPortal={data.userratingsportal} />
                                </div>
                            </UI.Card>
                        </UI.Col>
                    </UI.Row>
                </UI.Container>
            </div>
        </React.Fragment>
    );
};

export const UserRatingsPortalSetting = withRouter(Sentry.withProfiler(observer(UserRatingsPortalSettingBase)));
