import { observer } from "mobx-react";
import * as Sentry from "@sentry/react";
import { GetServiceDetailsByServiceType_uccm_v_customerservices_tiles as customerservice } from "src/api/generated/GetServiceDetailsByServiceType";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useState, useEffect } from "react";
import { RootStoreContext } from "src/stores/RootStore";
import { UI } from "@wwimmo/ui";
import { startCase } from "lodash";
import {
    SERVICE_DEACTIVATION_PROCESS,
    SERVICE_DEACTIVATION_PROCESS_STEP
} from "src/stores/services/modals/ServicesDeactivationProgressModalStore";
import styles from "./ServicesDeactivateProgressModal.module.css";

export enum ContactItemType {
    PHONE,
    MAIL
}
interface ServicesDeactivateModalProps {
    customerService?: customerservice;
}

const ServicesDeactivateProgressModalBase = (props: ServicesDeactivateModalProps) => {
    const { t } = useTranslation();
    const { uiStore, servicesDeactivationProgressModalStore } = useContext(RootStoreContext);
    const {
        setCurrentProcessType,
        setIsDeactivationProgressModalDisplayed,
        currentStepType,
        currentStep,
        isDeactivationProgressModalDisplayed,
        DEACTIVATION_MODAL_MAX_NUMBER_OF_STEPS,
        deactivateServices,
        isServiceSuccessfullyDeactivated,
        inputFeedback,
        setInputFeedback,
        setCurrentStepType
    } = servicesDeactivationProgressModalStore;
    const { customerService } = props;

    const [isDeactivationConfirmed, setIsDeactivationConfirmed] = useState<boolean>(false);

    const onClickStep = useCallback(
        (processType: SERVICE_DEACTIVATION_PROCESS) => () => {
            setCurrentProcessType(processType);
        },
        [setCurrentProcessType]
    );

    useEffect(() => {
        if (currentStepType === SERVICE_DEACTIVATION_PROCESS_STEP.SERVICE_DEACTIVATION_RESULT) {
            deactivateServices(customerService?.serviceid);
        }
    }, [currentStepType, deactivateServices, customerService?.serviceid]);

    const onCloseModal = useCallback(() => {
        setIsDeactivationProgressModalDisplayed(false);
        setIsDeactivationConfirmed(false);

        setCurrentStepType(0);
    }, [setIsDeactivationProgressModalDisplayed, setIsDeactivationConfirmed, setCurrentStepType]);

    const onChangeDeactivationConfirmed = useCallback(() => {
        isDeactivationConfirmed ? setIsDeactivationConfirmed(false) : setIsDeactivationConfirmed(true);
    }, [isDeactivationConfirmed, setIsDeactivationConfirmed]);

    const onChangeInputFeedback = useCallback(
        (e: any) => {
            setInputFeedback(e.target.value);
        },
        [setInputFeedback]
    );

    const renderStep = () => {
        switch (currentStepType) {
            case SERVICE_DEACTIVATION_PROCESS_STEP.START_SERVICE_DEACTIVATION:
            default:
                return (
                    <>
                        <UI.Row className={"mt-2"}>
                            <UI.Col>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: customerService?.deactivate ?? ""
                                    }}
                                />
                            </UI.Col>
                        </UI.Row>
                        <UI.Row className={"mt-5"}>
                            <UI.Col xs={12} md={6} className={styles.ColDeactivationConfirmed}>
                                <UI.Checkbox
                                    className={styles.DeactivationConfirmed}
                                    name="activationConfirmed"
                                    label={t("screens.services.deactivate.deactivationConfirmed")}
                                    checked={isDeactivationConfirmed}
                                    onChange={onChangeDeactivationConfirmed}
                                />
                            </UI.Col>
                            <UI.Col xs={12} md={3}>
                                <UI.Button
                                    className={`ticket-button mt-2 ${styles.DiscardButton}`}
                                    label={startCase(t("labels.cancel").toString())}
                                    variant={"outline-primary"}
                                    onClick={onCloseModal}
                                ></UI.Button>
                            </UI.Col>
                            <UI.Col xs={12} md={3}>
                                <UI.Button
                                    className={"ticket-button mt-2"}
                                    label={t("screens.services.deactivate.onwards")}
                                    disabled={!isDeactivationConfirmed}
                                    onClick={onClickStep(SERVICE_DEACTIVATION_PROCESS.SERVICE_DEACTIVATION_FEEDBACK)}
                                ></UI.Button>
                            </UI.Col>
                        </UI.Row>
                    </>
                );
            case SERVICE_DEACTIVATION_PROCESS_STEP.SERVICE_DEACTIVATION_FEEDBACK:
                return (
                    <>
                        <div>{t("screens.services.deactivate.feedback_text")}</div>
                        <div className={styles.TestTest}>
                            <UI.Input
                                as="textarea"
                                type="text"
                                placeholder="Ihr Feedback..."
                                className={styles.InputFeedback}
                                value={inputFeedback}
                                onChange={onChangeInputFeedback}
                            />
                        </div>
                        <UI.Row className={"mt-3"}>
                            <UI.Col xs={12} md={6} className={styles.ColDeactivationConfirmed}></UI.Col>
                            <UI.Col xs={12} md={3}>
                                <UI.Button
                                    className={`ticket-button mt-2 ${styles.DiscardButton}`}
                                    label={startCase(t("labels.cancel").toString())}
                                    variant={"outline-primary"}
                                    onClick={onCloseModal}
                                ></UI.Button>
                            </UI.Col>
                            <UI.Col xs={12} md={3}>
                                <UI.Button
                                    className={`ticket-button mt-2 ${styles.DeactivateButton}`}
                                    label={t("screens.services.deactivate.deactivate")}
                                    disabled={false}
                                    onClick={onClickStep(SERVICE_DEACTIVATION_PROCESS.SERVICE_DEACTIVATION_RESULT)}
                                ></UI.Button>
                            </UI.Col>
                        </UI.Row>
                    </>
                );
            case SERVICE_DEACTIVATION_PROCESS_STEP.SERVICE_DEACTIVATION_RESULT:
                return (
                    <>
                        {!isServiceSuccessfullyDeactivated ? (
                            <>
                                <UI.Row className={styles.DivImageLoadSpinner}>
                                    <div>
                                        <UI.RotatingSpinner noLogo size={50} className={styles.ImageLoadSpinner} />
                                        <div>
                                            {customerService?.name}{" "}
                                            {t("screens.services.deactivate.will_be_deactivated")}.
                                        </div>
                                    </div>
                                </UI.Row>
                                <UI.Row className={"mt-2 justify-content-end"}>
                                    <UI.Col xs={12} md={3}>
                                        <UI.Button
                                            className={`ticket-button mt-2 ${styles.DiscardButton}`}
                                            label={startCase(t("labels.cancel").toString())}
                                            variant={"outline-primary"}
                                            onClick={onCloseModal}
                                        ></UI.Button>
                                    </UI.Col>
                                </UI.Row>
                            </>
                        ) : (
                            <>
                                <UI.Row className={"mt-2"}>
                                    <UI.Col className={styles.ColAfterActivate}>
                                        <UI.Icon icon={UI.SVGIcon.Check} color={"black"} size={80} />
                                    </UI.Col>
                                </UI.Row>
                                <UI.Row className={"mt-2"}>
                                    <UI.Col className={styles.ColAfterActivate}>
                                        <div>
                                            {customerService?.name}{" "}
                                            {t("screens.services.deactivate.successfull_deactivaion")}
                                        </div>
                                    </UI.Col>
                                </UI.Row>
                                <UI.Row className={"mt-5 justify-content-end"}>
                                    <UI.Col xs={12} md={3}>
                                        <UI.Button
                                            className={"d-flex align-self-end ticket-button mt-2"}
                                            label={t("labels.finished")}
                                            onClick={onCloseModal}
                                        ></UI.Button>
                                    </UI.Col>
                                </UI.Row>
                            </>
                        )}
                    </>
                );
        }
    };

    return (
        <UI.ProgressModal
            currentStep={currentStep?.stepNumber ?? 1}
            totalSteps={DEACTIVATION_MODAL_MAX_NUMBER_OF_STEPS}
            title={`${customerService?.name} ${t("screens.services.deactivate.title")}`}
            show={isDeactivationProgressModalDisplayed}
            onDismissModal={onCloseModal}
            centered={uiStore.isMobile ? true : false}
            size={uiStore.isMobile ? undefined : "lg"}
        >
            {renderStep()}
        </UI.ProgressModal>
    );
};

export const ServicesDeactivateProgressModal = Sentry.withProfiler(observer(ServicesDeactivateProgressModalBase));
