import { Location } from "./location/Location";
import { GenericList } from "./charts/GenericList";
import { Pie } from "./charts/Pie";
import { Doughnut } from "./charts/Doughnut";
import { Line } from "./charts/Line";
import { VerticalBar, HorizontalBar } from "./charts/Bar";
import { Comparsion } from "./charts/Comparsion";
import { Tile } from "./Tile";
import { ContactTile } from "./contact/ContactTile";
import { RealestateInfoTile } from "./realestateinfo/RealestateInfoTile";
import { LatestDocumentsTile } from "./latestdocuments/LatestDocumentsTile";
import { StackedArea } from "./charts/StackedArea";

export const Tiles = {
    Comparsion,
    ContactTile,
    Doughnut,
    GenericList,
    HorizontalBar,
    LatestDocumentsTile,
    Line,
    Location,
    Pie,
    RealestateInfoTile,
    Tile,
    VerticalBar,
    StackedArea
};
