import { observable, action, makeObservable, computed, runInAction } from "mobx";
import {
    COMPONENT_TYPE,
    DAMAGE_NOTIFICATION_PROPERTY,
    IApplianceData,
    IComponent,
    LOADING_TYPE,
    TICKET_PROPERTY
} from "src/stores/TicketStore";
import { RootStore } from "src/stores/RootStore";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { GET_APPLIANCE_DATA, GET_COMPONENT_DATA } from "src/api/ticket";
import { GetApplianceData } from "src/api/generated/GetApplianceData";
import {
    GetComponentData,
    GetComponentDataVariables,
    GetComponentData_components
} from "src/api/generated/GetComponentData";
import i18next from "i18next";

const DAMAGE_ROOT_COMPONENTS_PARENT_ID = "b753e048-71c2-473a-4f51-97f70a5d2997";

export class DamageNotificationStore {
    rootStore: RootStore;

    applianceData: IApplianceData[] = [];
    currentApplianceSearchQuery: string = "";
    selectedApplianceQueryString: string | undefined = undefined;
    isApplianceSearchedFromMasterdata: boolean = false;

    componentData: GetComponentData_components[] = [];
    formComponentTreeData: Array<IComponent[]> = [];
    isComponentSelectionFinished: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            applianceData: observable,
            componentData: observable,
            currentApplianceSearchQuery: observable,
            selectedApplianceQueryString: observable,
            isApplianceSearchedFromMasterdata: observable,
            formComponentTreeData: observable,
            isComponentSelectionFinished: observable,

            init: action,
            loadApplianceData: action,
            loadComponentData: action,
            setApplianceData: action,
            setCurrentApplianceSearchQuery: action,
            setSelectedApplianceQueryString: action,
            setIsApplianceSearchedFromMasterdata: action,
            resetApplianceSelection: action,
            resetComponentSelection: action,
            resetDamageFormFields: action,
            initializeFormComponentTreeData: action,
            reconstructFormComponentTreeData: action,
            setFormComponentTreeData: action,
            setIsComponentSelectionFinished: action,
            handleClickOnComponentTreeElement: action,
            handleEditComponentTreeAfterFinishedSelection: action,
            setComponentParametersOnTicket: action,

            filteredApplianceData: computed,
            isApplianceSelectionFromMasterdataFinished: computed
        });
    }

    init = async () => {
        await this.loadApplianceData();
        await this.loadComponentData();
    };

    loadApplianceData = async () => {
        this.rootStore.ticketStore.setLoadingType(LOADING_TYPE.TICKET);

        const { data }: { data: GetApplianceData } = await apolloClientInstance.query<GetApplianceData>({
            query: GET_APPLIANCE_DATA
        });

        this.setApplianceData(data.appliances);

        this.rootStore.ticketStore.setLoadingType(LOADING_TYPE.NONE);
    };

    loadComponentData = async () => {
        this.rootStore.ticketStore.setLoadingType(LOADING_TYPE.TICKET);

        const { data }: { data: GetComponentData } = await apolloClientInstance.query<
            GetComponentData,
            GetComponentDataVariables
        >({
            query: GET_COMPONENT_DATA,
            variables: {
                language: i18next.language
            }
        });

        runInAction(() => {
            this.componentData = data.components;
        });

        this.rootStore.ticketStore.setLoadingType(LOADING_TYPE.NONE);
    };

    getIComponentObject = (component: GetComponentData_components, isSelected: boolean): IComponent => {
        let childComponentsIds: string[] = [];

        if (component.child_components.length > 0) {
            component.child_components.forEach((childComponent) => {
                childComponentsIds.push(childComponent.id);
            });
        }

        return {
            id: component.id,
            parentid: component.parentid,
            type: component.type,
            parameters: component.parameters,
            title: component.component_mls[0].title,
            childComponentsIds: childComponentsIds,
            isSelected: isSelected
        };
    };

    initializeFormComponentTreeData = () => {
        this.formComponentTreeData = [];
        let damageRootComponents: IComponent[] = [];

        this.componentData.forEach((component) => {
            if (component.parentid === DAMAGE_ROOT_COMPONENTS_PARENT_ID) {
                damageRootComponents.push(this.getIComponentObject(component, false));
            }
        });

        this.formComponentTreeData.push(damageRootComponents);
    };

    getParentComponentsRecursive = (componentId: string): GetComponentData_components[] => {
        const component = this.componentData.find((component) => {
            return component.id === componentId;
        });

        if (component?.parentid && component.id !== DAMAGE_ROOT_COMPONENTS_PARENT_ID) {
            const parentComponents = this.getParentComponentsRecursive(component.parentid);
            return [component, ...parentComponents];
        } else {
            if (component) {
                return [component];
            } else {
                return [];
            }
        }
    };

    reconstructFormComponentTreeData = () => {
        this.formComponentTreeData = [];

        const selectedComponent = this.componentData.find((component) => {
            return component.id === this.rootStore.ticketStore.currentTicket?.componentid;
        });

        if (selectedComponent?.parentid) {
            const selectedComponents = this.getParentComponentsRecursive(selectedComponent.id);
            selectedComponents.pop();
            selectedComponents.reverse();

            selectedComponents.forEach((selectedComponent) => {
                this.setComponentParametersOnTicket(selectedComponent);

                let damageComponentRow: IComponent[] = [];

                this.componentData.forEach((component) => {
                    if (component.parentid === selectedComponent.parentid) {
                        const isComponentSelect = component.id === selectedComponent.id;
                        damageComponentRow.push(this.getIComponentObject(component, isComponentSelect));
                    }
                });

                this.formComponentTreeData.push(damageComponentRow);
            });

            this.setIsComponentSelectionFinished(true);
        }
    };

    setComponentParametersOnTicket = (component: IComponent | GetComponentData_components) => {
        if (component.parameters?.roomtype) {
            this.rootStore.ticketStore.currentTicket?.updateProperty(
                TICKET_PROPERTY.ROOMTYPE,
                component.parameters.roomtype
            );
        }

        if (component.parameters?.appliancetype) {
            this.rootStore.ticketStore.currentTicket?.updateProperty(
                TICKET_PROPERTY.APPLIANCETYPE,
                component.parameters?.appliancetype
            );
        }

        if (component.parameters?.manufacturer) {
            this.rootStore.ticketStore.currentTicket?.updateDamageNotificationProperty(
                DAMAGE_NOTIFICATION_PROPERTY.HAS_MANUFACTURER,
                component.parameters?.manufacturer
            );
        }

        if (component.parameters?.serialnumber) {
            this.rootStore.ticketStore.currentTicket?.updateDamageNotificationProperty(
                DAMAGE_NOTIFICATION_PROPERTY.HAS_SERIALNUMBER,
                component.parameters?.serialnumber
            );
        }

        if (component.type === COMPONENT_TYPE.OTHER) {
            this.rootStore.ticketStore.currentTicket?.updateDamageNotificationProperty(
                DAMAGE_NOTIFICATION_PROPERTY.IS_OTHER_COMPONENT_SELECTED,
                true
            );
        }
    };

    handleClickOnComponentTreeElement = (componentId: string, componentTreeRow: number) => {
        let componentTreeData = [...this.formComponentTreeData];

        // Delete all the component-rows after the selected row
        if (componentTreeData.length > componentTreeRow + 1) {
            componentTreeData.splice(componentTreeRow + 1);
        }

        if (componentTreeData[componentTreeRow]) {
            componentTreeData[componentTreeRow].forEach((component) => {
                if (component.id === componentId) {
                    component.isSelected = !component.isSelected;

                    if (component.isSelected) {
                        this.setComponentParametersOnTicket(component);

                        if (component.childComponentsIds && component.childComponentsIds?.length > 0) {
                            let childComponents: IComponent[] = [];

                            this.componentData.forEach((component) => {
                                if (component.parentid === componentId) {
                                    childComponents.push(this.getIComponentObject(component, false));
                                }
                            });

                            componentTreeData.push(childComponents);
                        } else {
                            // FINAL COMPONENT IN TREE WAS SELECTED
                            this.rootStore.ticketStore.currentTicket?.updateProperty(
                                TICKET_PROPERTY.COMPONENTID,
                                component.id
                            );

                            this.setIsComponentSelectionFinished(true);
                        }
                    } else {
                        // COMPONENT ROW WAS DESELECTED
                        if (!component.parameters?.roomtype) {
                            this.rootStore.ticketStore.currentTicket?.updateProperty(
                                TICKET_PROPERTY.ROOMTYPE,
                                undefined
                            );
                        }

                        if (!component.parameters?.appliancetype) {
                            this.rootStore.ticketStore.currentTicket?.updateProperty(
                                TICKET_PROPERTY.APPLIANCETYPE,
                                undefined
                            );
                        }

                        if (!component.parameters?.manufacturer) {
                            this.rootStore.ticketStore.currentTicket?.updateDamageNotificationProperty(
                                DAMAGE_NOTIFICATION_PROPERTY.HAS_MANUFACTURER,
                                false
                            );
                        }

                        if (!component.parameters?.serialnumber) {
                            this.rootStore.ticketStore.currentTicket?.updateDamageNotificationProperty(
                                DAMAGE_NOTIFICATION_PROPERTY.HAS_SERIALNUMBER,
                                false
                            );
                        }

                        if (component.type === COMPONENT_TYPE.OTHER) {
                            this.rootStore.ticketStore.currentTicket?.updateDamageNotificationProperty(
                                DAMAGE_NOTIFICATION_PROPERTY.IS_OTHER_COMPONENT_SELECTED,
                                false
                            );
                        }
                    }
                } else {
                    component.isSelected = false;
                }
            });
        }

        this.formComponentTreeData = componentTreeData;
    };

    handleEditComponentTreeAfterFinishedSelection = () => {
        this.setIsComponentSelectionFinished(false);

        // unselect component in last row of component tree and reset already selected ticket properties
        const lastRowOfComponentTree = this.formComponentTreeData[this.formComponentTreeData.length - 1];

        lastRowOfComponentTree.forEach((component) => {
            if (component.isSelected) {
                component.isSelected = false;

                this.rootStore.ticketStore.currentTicket?.updateProperty(TICKET_PROPERTY.COMPONENTID, undefined);

                if (component.parameters?.roomtype) {
                    this.rootStore.ticketStore.currentTicket?.updateProperty(TICKET_PROPERTY.ROOMTYPE, undefined);
                }

                if (component.parameters?.appliancetype) {
                    this.rootStore.ticketStore.currentTicket?.updateProperty(TICKET_PROPERTY.APPLIANCETYPE, undefined);
                }

                if (component.parameters?.manufacturer) {
                    this.rootStore.ticketStore.currentTicket?.updateDamageNotificationProperty(
                        DAMAGE_NOTIFICATION_PROPERTY.HAS_MANUFACTURER,
                        false
                    );
                }

                if (component.parameters?.serialnumber) {
                    this.rootStore.ticketStore.currentTicket?.updateDamageNotificationProperty(
                        DAMAGE_NOTIFICATION_PROPERTY.HAS_SERIALNUMBER,
                        false
                    );
                }
            }
        });
    };

    resetApplianceSelection = () => {
        this.rootStore.ticketStore.currentTicket?.updateProperty(TICKET_PROPERTY.APPLIANCE, undefined);
        this.setCurrentApplianceSearchQuery("");
        this.setSelectedApplianceQueryString("");
    };

    resetComponentSelection = () => {
        this.isComponentSelectionFinished = false;
        this.initializeFormComponentTreeData();
    };

    resetDamageFormFields = () => {
        this.resetApplianceSelection();
        this.resetComponentSelection();
    };

    get filteredApplianceData(): IApplianceData[] {
        const searchQuery = this.currentApplianceSearchQuery ?? "";
        const realestateId = this.rootStore.ticketStore.currentTicket?.realestate?.id;

        let filteredApplianceData: IApplianceData[] = [...this.applianceData];

        if (realestateId) {
            const filteredApplianceDataByRealestate = this.applianceData.filter((appliance) => {
                return appliance.realestateid === realestateId;
            });

            filteredApplianceData = [...filteredApplianceDataByRealestate];
        }

        const filteredApplianceDataBySearchQuery = filteredApplianceData.filter((appliance) => {
            return (
                appliance.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                appliance.model?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                appliance.manufacturer?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                appliance.serialnumber?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        });

        if (searchQuery === "") {
            return filteredApplianceData;
        } else {
            return filteredApplianceDataBySearchQuery;
        }
    }

    hasApplianceInSelectedRealestate(realestateId: string): boolean {
        const selectedRealestateId = realestateId;

        if (selectedRealestateId === undefined) {
            return false;
        }

        const applianceData = this.applianceData.filter((appliance) => {
            return appliance.realestateid === selectedRealestateId;
        });

        return applianceData.length > 0;
    }

    hasApplianceInSelectedUnit(unitId: string): boolean {
        const selectedRealestateId = this.rootStore.ticketStore.currentTicket?.realestate?.id;
        const selectedUnitId = unitId;

        if (selectedRealestateId === undefined && selectedUnitId === undefined) {
            return false;
        }

        const applianceData = this.applianceData.filter((appliance) => {
            return appliance.realestateid === selectedRealestateId || appliance.unitid === selectedUnitId;
        });

        return applianceData.length > 0;
    }

    get isApplianceSelectionFromMasterdataFinished(): boolean {
        return this.rootStore.ticketStore.currentTicket?.appliance !== undefined;
    }

    setApplianceData = (applianceData: IApplianceData[]) => {
        this.applianceData = applianceData;
    };

    setCurrentApplianceSearchQuery = (currentApplianceSearchQuery: string) => {
        this.currentApplianceSearchQuery = currentApplianceSearchQuery;
    };

    setSelectedApplianceQueryString = (selectedApplianceQueryString: string | undefined) => {
        this.selectedApplianceQueryString = selectedApplianceQueryString;
    };

    setIsApplianceSearchedFromMasterdata = (isApplianceSearchedFromMasterdata: boolean) => {
        this.isApplianceSearchedFromMasterdata = isApplianceSearchedFromMasterdata;
    };

    setFormComponentTreeData = (formComponentTreeData: Array<IComponent[]>) => {
        this.formComponentTreeData = formComponentTreeData;
    };

    setIsComponentSelectionFinished = (isComponentSelectionFinished: boolean) => {
        this.isComponentSelectionFinished = isComponentSelectionFinished;
        this.rootStore.ticketStore.currentTicket?.validateDamageNotificationComponentSelected();
    };
}
