import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { IUnitData, TICKET_STATE } from "src/stores/TicketStore";
import { useSelectionListKeyHandler } from "src/hooks/ticket/selection-list-key-handler/useSelectionListKeyHandler";
import { boldenMatchedSubstring } from "src/utils/Common";
import styles from "./UnitFormField.module.css";
import "./GeneralFormStyles.css";

const UnitFormFieldBase = () => {
    const { t } = useTranslation();

    const { ticketStore, contactPersonSearchStore, damageNotificationStore, unitSearchStore } =
        useContext(RootStoreContext);

    const [displayUnitList, setDisplayUnitList] = useState<boolean>(false);
    const [lastScrollPositionYUnitItems, setLastSrollYPositionUnitItems] = useState<number>(0);

    const unitInputRef = useRef<HTMLInputElement>(null);

    const resetSearchQuery = useCallback(() => {
        unitSearchStore.setCurrentUnitSearchQuery(unitSearchStore.selectedUnitQueryString ?? "");
    }, [unitSearchStore]);

    useEffect(() => {
        const handleClickOutsideUnitInput = (event: any) => {
            if (
                displayUnitList &&
                unitInputRef.current &&
                !unitInputRef.current.contains(event.target) &&
                !(event.target.classList.contains("list-item") && event.target.classList.contains("unit")) &&
                !(event.target.classList.contains("highlighted-text") && event.target.classList.contains("unit"))
            ) {
                setDisplayUnitList(false);

                if (
                    ticketStore.currentTicket?.isUnitSelected &&
                    unitSearchStore.currentUnitSearchQuery !== unitSearchStore.selectedUnitQueryString
                ) {
                    ticketStore.resetUnitFormField();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutsideUnitInput);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideUnitInput);
        };
    }, [
        displayUnitList,
        ticketStore,
        resetSearchQuery,
        unitSearchStore.currentUnitSearchQuery,
        unitSearchStore.selectedUnitQueryString
    ]);

    useEffect(() => {
        if (unitSearchStore.triggerFocusOnUnitInput) {
            unitInputRef.current?.focus();
            unitSearchStore.setTriggerFocusOnUnitInput(false);
        }
        // This useEffect only listens to changes in ticketStore.triggerFocusOnUnitInput
        // eslint-disable-next-line
    }, [unitSearchStore.triggerFocusOnUnitInput]);

    const getFormattedUnitName = useCallback((unit: IUnitData) => {
        const unitName = `${unit.unitName}${unit.unitNumber ? ` [${unit.unitNumber}]` : ""}`;

        return unitName;
    }, []);

    const updateTicketStoreWithSelectedUnit = useCallback(
        async (unit: IUnitData) => {
            const unitOnTicketSuccessfullyUpdated = await ticketStore.updateUnitOnTicketWithAdditionalTenantInformation(
                unit
            );

            if (unitOnTicketSuccessfullyUpdated) {
                const newUnitFieldValue = getFormattedUnitName(unit);

                unitSearchStore.setCurrentUnitSearchQuery(newUnitFieldValue);
                unitSearchStore.setSelectedUnitQueryString(newUnitFieldValue);

                contactPersonSearchStore.setPersonSearchFilterUnit(true);

                damageNotificationStore.setIsApplianceSearchedFromMasterdata(
                    damageNotificationStore.hasApplianceInSelectedUnit(unit.unitId)
                );

                contactPersonSearchStore.setTriggerFocusOnPersonInput(true);
            }
        },
        [ticketStore, contactPersonSearchStore, damageNotificationStore, getFormattedUnitName, unitSearchStore]
    );

    const onSelectUnitItem = useCallback(
        (activeListItemIndex: number) => {
            const selectedUnitItem = unitSearchStore.filteredUnitData[activeListItemIndex];

            const unit: IUnitData = {
                houseId: selectedUnitItem.houseId,
                unitNumber: selectedUnitItem.unitNumber,
                unitId: selectedUnitItem.unitId,
                unitName: selectedUnitItem.unitName
            };

            updateTicketStoreWithSelectedUnit(unit);
        },
        [updateTicketStoreWithSelectedUnit, unitSearchStore.filteredUnitData]
    );

    const { onKeyDownFunction, setActiveListItemIndex, NO_LIST_ITEM_SELECTED } = useSelectionListKeyHandler({
        listName: "unit",
        totalNumberOfDisplayedItems: ticketStore.numberOfDisplayedItems,
        setIsListDisplayed: setDisplayUnitList,
        isListDisplayed: displayUnitList,
        onSelectItem: onSelectUnitItem,
        resetSearchQuery: resetSearchQuery
    });

    const resetAmountOfItemsDisplayedInUnitList = useCallback(() => {
        setLastSrollYPositionUnitItems(0);
        ticketStore.setNumberOfDisplayedItems(ticketStore.sliceSizeOfDisplayedItems);
    }, [ticketStore]);

    const onChangeUnitInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            resetAmountOfItemsDisplayedInUnitList();

            const inputUnit = e.target.value;
            unitSearchStore.setCurrentUnitSearchQuery(inputUnit);
        },
        [unitSearchStore, resetAmountOfItemsDisplayedInUnitList]
    );

    const onFocusUnitInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (ticketStore.currentTicket?.isUnitSelected && unitInputRef.current) {
                unitInputRef.current.select();
            }

            resetAmountOfItemsDisplayedInUnitList();
            setDisplayUnitList(true);
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
        },
        [
            resetAmountOfItemsDisplayedInUnitList,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            ticketStore.currentTicket?.isUnitSelected
        ]
    );

    const onClickUnitItem = useCallback(
        (unitData: IUnitData) => async (e: any) => {
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
            setDisplayUnitList(false);

            const unit: IUnitData = {
                houseId: unitData.houseId ?? "",
                unitNumber: unitData.unitNumber ?? undefined,
                unitId: unitData.unitId ?? "",
                unitName: unitData.unitName ?? ""
            };

            updateTicketStoreWithSelectedUnit(unit);
        },
        [updateTicketStoreWithSelectedUnit, setActiveListItemIndex, NO_LIST_ITEM_SELECTED]
    );

    const onScrollUnitList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYUnitItems;

            const hasMoreResulsToDisplay = ticketStore.numberOfDisplayedItems < unitSearchStore.filteredUnitData.length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                ticketStore.setNumberOfDisplayedItems(
                    ticketStore.numberOfDisplayedItems + ticketStore.sliceSizeOfDisplayedItems
                );
            }

            setLastSrollYPositionUnitItems(pixelsFromTop);
        },
        [lastScrollPositionYUnitItems, ticketStore, unitSearchStore.filteredUnitData.length]
    );

    const unitSelectionList = (
        <ul id="unit-dropdown-list" className={"dropdown-list unit"} onScroll={onScrollUnitList}>
            {unitSearchStore.filteredUnitData.slice(0, ticketStore.numberOfDisplayedItems).map((unit, index) => {
                const unitName = getFormattedUnitName(unit);

                const formattedUnitName = boldenMatchedSubstring(
                    unitSearchStore.currentUnitSearchQuery ?? "",
                    unitName,
                    "highlighted-text unit"
                );

                return (
                    <li key={index}>
                        <div
                            className={"list-item unit"}
                            id={`unit-list-item-${index}`}
                            onClick={onClickUnitItem(unit)}
                            dangerouslySetInnerHTML={{
                                __html: formattedUnitName
                            }}
                        />
                    </li>
                );
            })}
        </ul>
    );

    const readOnlyUnitElement = (
        <div className="view-only-container mt-4">
            <div className="title">{t("screens.unit.object")}</div>
            <div>
                <span className="text-with-number">{ticketStore.currentTicket?.unit?.unitName}</span>
                <span>{`${
                    ticketStore.currentTicket?.unit?.unitNumber
                        ? `[${ticketStore.currentTicket?.unit?.unitNumber}]`
                        : ""
                }`}</span>
            </div>
        </div>
    );

    return (
        <>
            {ticketStore.isEditing ? (
                <>
                    <UI.Input
                        ref={unitInputRef}
                        label={t("screens.unit.object").toString()}
                        type="text"
                        autoComplete="off"
                        className={styles.UnitInputField}
                        id="ticket-form-unit-input"
                        value={unitSearchStore.currentUnitSearchQuery ?? ""}
                        onChange={onChangeUnitInput}
                        onFocus={onFocusUnitInput}
                        onKeyDown={onKeyDownFunction}
                        disabled={
                            !ticketStore.isEditing ||
                            !ticketStore.currentTicket?.isRealestateSelected ||
                            ticketStore.currentTicket?.state === TICKET_STATE.COMISSIONED ||
                            ticketStore.currentTicket?.state === TICKET_STATE.COMPLETED ||
                            ticketStore.currentTicket?.state === TICKET_STATE.ARCHIVED
                        }
                    />
                    {displayUnitList ? unitSelectionList : undefined}
                </>
            ) : (
                readOnlyUnitElement
            )}
        </>
    );
};

export const UnitFormField = Sentry.withProfiler(observer(UnitFormFieldBase));
