import React, { useCallback, useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { observer } from "mobx-react";
import { UseFormRegister, DeepMap, FieldError, UseFormGetValues } from "react-hook-form";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";
import "src/screens/realestates/realestate/assemblies/manager/edit/form/AssembliesEditForm.css";
import {
    SEARCH_STATE,
    ERROR_TYPE,
    LIST_TYPE,
    AssemblyEditFormFields
} from "src/screens/realestates/realestate/assemblies/manager/edit/form/AssembliesEditForm";
import { handleListKeyDown, handleListKeyUp } from "src/utils/AutocompleteInputHelper";
import { GetStreetNumbersInStreet, GetStreetsInCity } from "src/utils/Location";

interface StreetNumberAutocompleteInputProps {
    setZipWasSelected: any;
    setStreetWasSelected: any;
    setStreetNumberWasSelected: any;
    streetWasSelected: boolean;
    closeAllLists: any;
    setValue: any;
    watchHousenumber: string;
    register: UseFormRegister<AssemblyEditFormFields>;
    errors: DeepMap<AssemblyEditFormFields, FieldError>;
    setSearchType: any;
    searchType: SEARCH_STATE;
    setError: any;
    setStreetItems: any;
    numbersOfSelectedStreet: string[];
    setNumbersOfSelectedStreet: any;
    setStreetNumberItems: any;
    streetNumberWasSelected: boolean;
    streetNumberItems: string[];
    dirtyFields: DeepMap<AssemblyEditFormFields, true>;
    getValues: UseFormGetValues<AssemblyEditFormFields>;
    currentStreetNumber: string;
    setCurrentStreetNumber: any;
}

const StreetNumberAutocompleteInputBase = (props: StreetNumberAutocompleteInputProps) => {
    const { t } = useTranslation();

    const [activeListItemIndex, setActiveListItemIndex] = useState<number>(-1);
    const [hasRendered, setHasRendered] = useState<boolean>(false);

    const {
        setError,
        searchType,
        setStreetNumberWasSelected,
        setStreetItems,
        numbersOfSelectedStreet,
        setNumbersOfSelectedStreet,
        streetWasSelected,
        setStreetNumberItems,
        setValue,
        register,
        watchHousenumber,
        errors,
        setSearchType,
        closeAllLists,
        streetNumberWasSelected,
        streetNumberItems,
        dirtyFields,
        getValues,
        currentStreetNumber,
        setCurrentStreetNumber
    } = props;

    useEffect(() => {
        if (!hasRendered) {
            setHasRendered(true);
        } else if (currentStreetNumber !== watchHousenumber) {
            onChangeInputStreetNumber();
        }
        // Only changes to 'watchStreet' should cause an running of this function
        // eslint-disable-next-line
    }, [watchHousenumber]);

    const onChangeInputStreetNumber = debounce(async () => {
        setError(ERROR_TYPE.NONE);

        setSearchType(SEARCH_STATE.STREETNUMBER);

        const inputNumber = watchHousenumber.toLowerCase().trim();

        let currentNumbersOfSelectedStreet =
            numbersOfSelectedStreet && numbersOfSelectedStreet.length > 0 ? numbersOfSelectedStreet : undefined;

        const currentSelectedZip = getValues("zip");
        const currentSelectedStreet = getValues("street");

        if (!currentNumbersOfSelectedStreet && inputNumber && currentSelectedZip && currentSelectedStreet) {
            const streetsInCity = await GetStreetsInCity({
                zip: currentSelectedZip,
                streetName: currentSelectedStreet
            });

            if (streetsInCity && streetsInCity.length === 1) {
                const selectedStreetId = streetsInCity[0].id;
                const streetNumbers = await GetStreetNumbersInStreet(selectedStreetId);
                currentNumbersOfSelectedStreet = streetNumbers;
                setNumbersOfSelectedStreet(streetNumbers);
            }
        }

        if (inputNumber && currentNumbersOfSelectedStreet) {
            const foundNumbers = currentNumbersOfSelectedStreet.filter((number: string) =>
                number.includes(inputNumber)
            );

            if (foundNumbers.length > 0) {
                setStreetNumberItems(foundNumbers);
            } else {
                setError(ERROR_TYPE.STREETNUMBER);
                setStreetNumberItems([]);
                setStreetNumberWasSelected(false);
            }
        } else {
            setError(ERROR_TYPE.NONE);
            setStreetNumberItems([]);
            setStreetNumberWasSelected(false);
        }

        setSearchType(SEARCH_STATE.NONE);
    }, 800);

    const onClickInputStreetNumber = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            closeAllLists();
            setActiveListItemIndex(-1);

            const streetNumber = e.target.value.trim();
            if (streetNumber && numbersOfSelectedStreet && !streetNumberWasSelected) {
                const foundNumbers = numbersOfSelectedStreet.filter((number: string) => number.includes(streetNumber));

                if (foundNumbers.length === 0) {
                    setError(ERROR_TYPE.STREETNUMBER);
                } else {
                    setError(ERROR_TYPE.NONE);
                    setStreetNumberItems(foundNumbers);
                }
            } else {
                setStreetItems([]);
            }
        },
        [
            closeAllLists,
            numbersOfSelectedStreet,
            streetNumberWasSelected,
            setError,
            setStreetItems,
            setStreetNumberItems
        ]
    );

    const setStreetNumberFormValue = useCallback(
        (streetNumber: string) => {
            setStreetNumberWasSelected(true);
            setCurrentStreetNumber(streetNumber);
            setValue("housenumber", streetNumber);
            setStreetNumberItems([]);
        },
        [setValue, setStreetNumberItems, setStreetNumberWasSelected, setCurrentStreetNumber]
    );

    const onClickStreetNumberItem = useCallback(
        (e: any) => {
            const streetNumber = e.target.innerHTML;
            setStreetNumberFormValue(streetNumber);
        },
        [setStreetNumberFormValue]
    );

    const onFocusInput = useCallback(
        (e: any) => {
            closeAllLists();
            setActiveListItemIndex(-1);
        },
        [closeAllLists]
    );

    const onKeyDownInputStreetNumber = useCallback(
        async (e: any) => {
            if (e.key === "Tab" && e.shiftKey) {
                document?.getElementById("number")?.focus();
            } else if (e.key === "Tab") {
                closeAllLists();
                onChangeInputStreetNumber.cancel();

                const streetNumberInput = e.target.value.toLowerCase().trim();
                const isStreetNumberListItemSelected = streetNumberItems.length > 0 && activeListItemIndex !== -1;

                if (isStreetNumberListItemSelected) {
                    e.preventDefault();
                    setStreetNumberFormValue(streetNumberItems[activeListItemIndex]);
                    setError(ERROR_TYPE.NONE);
                } else if (streetNumberInput && numbersOfSelectedStreet && numbersOfSelectedStreet.length > 0) {
                    setSearchType(SEARCH_STATE.STREETNUMBER);
                    const foundNumber = numbersOfSelectedStreet.find((number: string) => number === streetNumberInput);

                    if (foundNumber) {
                        setStreetNumberFormValue(foundNumber);
                        setError(ERROR_TYPE.NONE);

                        const submitButton = document.getElementById("assembly-submit-button");
                        submitButton?.focus();
                    } else {
                        setError(ERROR_TYPE.STREETNUMBER);
                    }
                } else {
                    setStreetNumberItems([]);
                    setStreetNumberWasSelected(false);
                }

                setSearchType(SEARCH_STATE.NONE);
            } else if (streetNumberItems.length > 0 && e.key === "ArrowDown") {
                handleListKeyDown(
                    streetNumberItems.length,
                    LIST_TYPE.STREETNUMBER,
                    activeListItemIndex,
                    setActiveListItemIndex
                );
            } else if (streetNumberItems.length > 0 && e.key === "ArrowUp") {
                handleListKeyUp(LIST_TYPE.STREETNUMBER, activeListItemIndex, setActiveListItemIndex);
            } else if (streetNumberItems.length > 0 && e.key === "Enter") {
                setStreetNumberFormValue(streetNumberItems[activeListItemIndex]);
                setError(ERROR_TYPE.NONE);
            }
        },
        [
            closeAllLists,
            onChangeInputStreetNumber,
            numbersOfSelectedStreet,
            activeListItemIndex,
            streetNumberItems,
            setStreetNumberFormValue,
            setError,
            setSearchType,
            setStreetNumberItems,
            setStreetNumberWasSelected
        ]
    );

    const loadingSpinner = <UI.RotatingSpinner noLogo size={20} className="loading-items-spinner" />;

    const streetNumberSelectionList = (
        <ul
            id="streetnumber-dropdown-list"
            className={`${searchType === SEARCH_STATE.STREETNUMBER ? "searching" : ""} dropdown-list number`}
        >
            {searchType === SEARCH_STATE.STREETNUMBER ? loadingSpinner : undefined}
            {streetNumberItems && streetNumberItems.length > 0
                ? streetNumberItems.map((number, index) => (
                      <li key={number}>
                          <div
                              className="list-item"
                              id={`streetnumber-list-item-${index}`}
                              onClick={onClickStreetNumberItem}
                          >
                              {number}
                          </div>
                      </li>
                  ))
                : undefined}
        </ul>
    );

    return (
        <>
            <UI.Input
                id="number"
                label={`${t("screens.realestate.assembly.streetnumber")}`}
                {...register("housenumber")}
                type="text"
                onFocus={onFocusInput}
                onClick={onClickInputStreetNumber}
                autoComplete="off"
                onKeyDown={onKeyDownInputStreetNumber}
                readOnly={!streetWasSelected}
                errorMsg={errors.housenumber ? errors.housenumber.message : undefined}
                className={dirtyFields.housenumber ? "changed" : ""}
            />
            {streetNumberSelectionList}
        </>
    );
};

export const StreetNumberAutocompleteInput = Sentry.withProfiler(observer(StreetNumberAutocompleteInputBase));
