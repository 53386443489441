import React, { useEffect, useContext, useCallback, useState } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { GetServiceDetailsByServiceType_uccm_v_customerservices_tiles as customerservices } from "src/api/generated/GetServiceDetailsByServiceType";
import { ServicesInfoModal } from "src/screens/services/ServicesInfoModal";
import styles from "./ServicesTiles.module.css";
import { uuid4 } from "@sentry/utils";
import { NetworkConfig } from "src/network/NetworkConfig";
import { ServicesActivateProgressModal } from "./ServicesActivateProgressModal";
import { ServicesDeactivateProgressModal } from "./ServicesDeactivateProgressModal";

interface ServicesTileProperties {
    customerServices: customerservices[];
    serviceType: number;
}

export enum ServiceType {
    FEATURE = 10,
    INTEGRATION = 20
}

const ServicesTilesBase = (props: ServicesTileProperties) => {
    const {
        navStore,
        authStore,
        uiStore,
        servicesInfoModalStore,
        servicesActivationProgressModalStore,
        servicesDeactivationProgressModalStore,
        servicesStore
    } = useContext(RootStoreContext);
    const { setIsActivationProgressModalDisplayed, isServiceSuccessfullyActivated } =
        servicesActivationProgressModalStore;
    const { setIsDeactivationProgressModalDisplayed } = servicesDeactivationProgressModalStore;
    const { setIsServiceInfoModalDisplayed } = servicesInfoModalStore;
    const { setRefetchServicesData, refetchServicesData } = servicesStore;
    const { t } = useTranslation();
    const [servicesIndex, setServicesIndex] = useState<number>();

    useEffect(() => {
        navStore.setTitle("");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);
    }, [
        navStore,
        t,
        authStore.user?.role,
        uiStore.isSidebarActive,
        isServiceSuccessfullyActivated,
        refetchServicesData
    ]);

    const customerServices = props.customerServices.length >= 1 ? props.customerServices : undefined;

    const onOpenServiceInfoModal = useCallback(
        (index: number) => () => {
            setIsServiceInfoModalDisplayed(true);
            setServicesIndex(index);
        },
        [setIsServiceInfoModalDisplayed]
    );

    const onActivateServiceSwitch = useCallback(
        (serviceid: any) => (e: any) => {
            if (customerServices) {
                const index = customerServices.findIndex((service) => service.serviceid === serviceid);
                setServicesIndex(index);
            }

            setIsServiceInfoModalDisplayed(false);
            if (e === true) {
                setIsActivationProgressModalDisplayed(true);
                setIsDeactivationProgressModalDisplayed(false);
            } else {
                setIsActivationProgressModalDisplayed(false);
                setIsDeactivationProgressModalDisplayed(true);
            }
            setRefetchServicesData(true);
        },
        [
            setIsActivationProgressModalDisplayed,
            setIsServiceInfoModalDisplayed,
            customerServices,
            setIsDeactivationProgressModalDisplayed,
            setRefetchServicesData
        ]
    );

    return (
        <>
            {customerServices?.map((service, index) => {
                return (
                    <UI.Col sm={6} lg={4} xl={3} key={uuid4()}>
                        <UI.Card className={styles.FeatureCard} square title={service.title} key={uuid4()}>
                            {customerServices[index].enableable ? (
                                <div className={styles.ActivateServiceSwitch}>
                                    <UI.Switch
                                        checked={customerServices[index].activated || undefined}
                                        onToggle={onActivateServiceSwitch(customerServices[index].serviceid)}
                                    />
                                </div>
                            ) : undefined}

                            <UI.Row className={styles.FeatureRow}>
                                <UI.Col className={styles.Thumbnail} key={uuid4()}>
                                    {service.servicesfiles.length > 0 ? (
                                        <img
                                            src={`${NetworkConfig.proxy}/files/services/${service.serviceid}/de/${service.servicesfiles[0].id}.${service.servicesfiles[0].extension}`}
                                            alt={service.servicesfiles[0].id}
                                            key={service.servicesfiles[0].id}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </UI.Col>
                                <UI.Col className={styles.TeaserText} key={uuid4()}>
                                    {service.teaser}
                                </UI.Col>
                            </UI.Row>
                            <div className={styles.ModalLinkContainer}>
                                <div className={styles.ModalLink} onClick={onOpenServiceInfoModal(index)}>
                                    {`${t("screens.services.features_and_integrations.more")}...`}
                                </div>
                            </div>
                        </UI.Card>
                    </UI.Col>
                );
            })}
            <ServicesInfoModal
                customerService={customerServices?.[servicesIndex ?? 0]}
                serviceType={props.serviceType}
            />
            <ServicesDeactivateProgressModal customerService={customerServices?.[servicesIndex ?? 0]} />
            <ServicesActivateProgressModal customerService={customerServices?.[servicesIndex ?? 0]} />
        </>
    );
};

export const ServicesTiles = Sentry.withProfiler(observer(ServicesTilesBase));
