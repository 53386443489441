import { useState, useCallback, useContext, useEffect } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./YarowaSubmit.module.css";
import generalStyles from "src/screens/tickets/ticket/details/actions/assignOrder/AssignOrderModal.module.css";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { ORDER_CREATION_STATUS } from "src/stores/tickets/AssignOrderModalStore";
import { ORDER_STATE } from "src/stores/OrderStore";
import { NetworkConfig } from "src/network/NetworkConfig";

export const YarowaSubmitBase = () => {
    const { t } = useTranslation();
    const { uiStore, assignOrderModalStore, orderStore } = useContext(RootStoreContext);
    const [urlToOpen, setUrlToOpen] = useState<string>("");

    useEffect(() => {
        async function setUrlToOpenForYarowa() {
            const url = await orderStore.getYarowaUrl(assignOrderModalStore.orderId);
            setUrlToOpen(url.length > 0 ? url : NetworkConfig.yarowaDashboardDefaultUrl);
        }

        if (
            assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.SUCCESS &&
            assignOrderModalStore.orderId !== "" &&
            assignOrderModalStore.orderStateSubscription === null &&
            assignOrderModalStore.orderState === ORDER_STATE.SUBMITTED
        ) {
            setUrlToOpenForYarowa();
        }
    }, [
        assignOrderModalStore,
        assignOrderModalStore.currentOrderCreationStatus,
        assignOrderModalStore.orderId,
        assignOrderModalStore.orderState,
        assignOrderModalStore.orderStateSubscription,
        orderStore
    ]);

    const onClickCreateOrder = useCallback(() => {
        if (assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.FAILURE) {
            if (assignOrderModalStore.orderId?.length > 0) {
                assignOrderModalStore.retryOrderSubmitFromModal();
            } else {
                assignOrderModalStore.createOrder();
            }
        } else {
            if (
                assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.SUCCESS &&
                urlToOpen?.length > 0
            ) {
                window.open(urlToOpen, "_blank"); // _blank during the on-click-event works fine.
            }
            assignOrderModalStore.setIsModalDisplayed(false);
            assignOrderModalStore.resetAssignOrderModal();
        }
    }, [assignOrderModalStore, urlToOpen]);

    const onClickCancel = useCallback(() => {
        assignOrderModalStore.setIsModalDisplayed(false);
        assignOrderModalStore.resetAssignOrderModal();
    }, [assignOrderModalStore]);

    let icon;
    let text;

    switch (assignOrderModalStore.currentOrderCreationStatus) {
        case ORDER_CREATION_STATUS.IN_PROGRESS:
            icon = <UI.RotatingSpinner noLogo size={50} className="loading-items-spinner" />;
            text = t("screens.tickets.modals.assign_order.order_creation_in_progress").toString();
            break;
        case ORDER_CREATION_STATUS.SUBMITTING:
            icon = <UI.RotatingSpinner noLogo size={50} className="loading-items-spinner" />;
            text = t("screens.tickets.modals.assign_order.order_creation_in_progress").toString();
            break;
        case ORDER_CREATION_STATUS.SUCCESS:
            icon = undefined;
            const buttonText = `"${t("labels.go_to_yarowa").toString()}"`;
            text = (
                <>
                    <p>{t("screens.tickets.modals.assign_order.yarowa_order_submitted_title")}</p>
                    <p>{t("screens.tickets.modals.assign_order.yarowa_order_submitted_text", { open: buttonText })}</p>
                </>
            );
            break;
        default:
            icon = <UI.Icon icon={UI.SVGIcon.Error} size={50} color={"black"} />;
            text = t("screens.tickets.modals.assign_order.errors.could_not_submit");
            break;
    }

    return (
        <UI.Row className={"mt-2 d-flex"}>
            <UI.Col
                xs={12}
                className={`d-flex align-items-center justify-content-center ${styles.YarowaSubmitContainer}`}
            >
                <div className="d-flex align-items-center flex-column">
                    <div>{icon}</div>
                    <div className="d-flex mt-2">
                        <span className={`${styles.YarowaSubmitText} text-center`}>{text}</span>
                    </div>
                </div>
            </UI.Col>
            <UI.Col xs={12} className="mt-4">
                <UI.Row className={`mt-2 d-flex ${uiStore.isMobile ? "flex-column-reverse" : ""}`}>
                    <UI.Col xs={12} md={6}>
                        <UI.Button
                            className={`ticket-button mt-2 ${generalStyles.BackButton}`}
                            label={t("labels.cancel")}
                            variant={"outline-primary"}
                            onClick={onClickCancel}
                        ></UI.Button>
                    </UI.Col>
                    <UI.Col xs={12} md={6}>
                        <UI.Button
                            className={`ticket-button mt-2 ${generalStyles.NextButton}`}
                            label={
                                assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.FAILURE
                                    ? t("labels.retry")
                                    : t("labels.go_to_yarowa")
                            }
                            hidden={
                                assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.SUCCESS ||
                                assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.FAILURE
                                    ? false
                                    : true
                            }
                            disabled={
                                assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.IN_PROGRESS
                                    ? true
                                    : false
                            }
                            onClick={onClickCreateOrder}
                        ></UI.Button>
                    </UI.Col>
                </UI.Row>
            </UI.Col>
        </UI.Row>
    );
};

export const YarowaSubmit = Sentry.withProfiler(observer(YarowaSubmitBase));
