import { runInAction } from "mobx";
import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import { RootStoreContext } from "src/stores/RootStore";
import { useForm } from "react-hook-form";
import { Patterns } from "src/utils/Patterns";
import { Loading } from "src/screens/loading/Loading";
import { AuthWrapper } from "src/screens/auth/AuthWrapper";
import { Route } from "src/config/routes";
import { Error } from "src/screens/error/Error";
import { UI } from "@wwimmo/ui";
import i18next from "i18next";

enum RESETSTATES {
    PENDING = 0,
    ACTIVE = 1,
    DONE = 2,
    MISSING_CODE = 3
}

interface ResetForm {
    username: string;
    code: string;
    newPassword: string;
    newPasswordConfirm: string;
}

export const PasswordReset = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        watch,
        setValue,

        formState: { errors }
    } = useForm<ResetForm>({
        shouldUnregister: false
    });
    const { navStore, authStore } = useContext(RootStoreContext);
    const [resetState, setResetState] = useState<RESETSTATES>(RESETSTATES.PENDING);
    const [error, setError] = useState<Error | undefined>(undefined);

    useEffect(() => {
        runInAction(() => {
            navStore.setTitle(t("screens.auth.password.reset.title"));
            navStore.setBackbutton(null);
        });
        const search = new URLSearchParams(props.location.search);
        const code = search.get("code");
        const username = search.get("username");
        if (code && code.length > 0) {
            setValue("code", code.replace(/ /g, "+"));
        } else {
            setResetState(RESETSTATES.MISSING_CODE);
        }
        if (code && code.length > 0) {
            setValue("username", username === null ? "" : username);
        }
    }, [navStore, t, setValue, props.location.search]);

    const onSubmit = async (formData: ResetForm) => {
        setResetState(RESETSTATES.ACTIVE);

        let hasPasswordResetError = false;

        try {
            await authStore.authClient.passwordReset(formData.username, formData.code, formData.newPassword);
        } catch (error: any) {
            hasPasswordResetError = true;

            error.message = i18next.exists(`error.${error.message}`)
                ? t(`error.${error.message}`)
                : t("screens.auth.password.recovery.default_error");

            setError(error);
        }

        if (hasPasswordResetError) {
            setResetState(RESETSTATES.PENDING);
        } else {
            setResetState(RESETSTATES.DONE);
        }
    };

    if (resetState === RESETSTATES.MISSING_CODE) {
        return (
            <AuthWrapper>
                <Error message={t("screens.auth.password.reset.error_no_code")} />
            </AuthWrapper>
        );
    } else if (resetState === RESETSTATES.ACTIVE) {
        return (
            <AuthWrapper>
                <Loading noLogo noBackgroundChange />
            </AuthWrapper>
        );
    } else if (resetState === RESETSTATES.DONE) {
        if (!error) {
            return (
                <AuthWrapper>
                    <UI.Container className="flex-grow-1 d-flex justify-content-center align-items-center flex-column">
                        <UI.Row>
                            <UI.Col>
                                <Trans>screens.auth.password.reset.success</Trans>
                            </UI.Col>
                        </UI.Row>
                        <UI.Row className="mt-2">
                            <UI.Col>
                                <Link to={Route.login}>
                                    <Trans>screens.auth.login_link</Trans>
                                </Link>
                            </UI.Col>
                        </UI.Row>
                    </UI.Container>
                </AuthWrapper>
            );
        }
    }

    return (
        <AuthWrapper>
            <UI.Form onSubmit={handleSubmit(onSubmit)}>
                <UI.FormGroup className="mb-3">
                    <UI.Form.Label>
                        <Trans>screens.auth.emailaddress</Trans>
                    </UI.Form.Label>
                    <UI.Form.Control
                        {...register("username", { required: true, pattern: Patterns.EMAILADDRESS })}
                        type="email"
                        readOnly={true}
                        isInvalid={errors.username !== undefined}
                        placeholder={t("screens.auth.emailaddress_placeholder")}
                    />
                </UI.FormGroup>
                <UI.FormGroup className="mb-3">
                    <UI.Form.Control name="code" type="text" hidden={true} readOnly={true} />
                </UI.FormGroup>
                <UI.FormGroup className="mb-3">
                    <UI.Form.Label>
                        <Trans>screens.auth.password.new</Trans>
                    </UI.Form.Label>
                    <UI.Form.Control
                        {...register("newPassword", { required: true, pattern: Patterns.PASSWORD })}
                        type="password"
                        isInvalid={errors.newPassword !== undefined}
                        placeholder={t("screens.auth.password.new_placeholder")}
                    />
                    {errors.newPassword && (
                        <UI.Form.Text className="text-danger">
                            <Trans>screens.auth.password.invalid</Trans>
                        </UI.Form.Text>
                    )}
                </UI.FormGroup>
                <UI.FormGroup className="mb-3">
                    <UI.Form.Label>
                        <Trans>screens.auth.password.new_confirm</Trans>
                    </UI.Form.Label>
                    <UI.Form.Control
                        {...register("newPasswordConfirm", { validate: (value) => value === watch("newPassword") })}
                        type="password"
                        isInvalid={errors.newPasswordConfirm !== undefined}
                        placeholder={t("screens.auth.password.new_confirm_placeholder")}
                    />
                    {errors.newPasswordConfirm && (
                        <UI.Form.Text className="text-danger">
                            <Trans>screens.auth.password.new_mismatch</Trans>
                        </UI.Form.Text>
                    )}
                </UI.FormGroup>
                <UI.Button type="submit" label={t("screens.auth.password.reset.title")} />
                {error && (
                    <UI.Alert variant="danger" className="mt-2">
                        {error.message ? error.message : error}
                    </UI.Alert>
                )}
            </UI.Form>
        </AuthWrapper>
    );
};
