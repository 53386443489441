import { action, computed, makeObservable, observable } from "mobx";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey } from "src/network/User";
import { RootStore } from "src/stores/RootStore";

export enum SERVICE_DEACTIVATION_PROCESS {
    START_SERVICE_DEACTIVATION,
    SERVICE_DEACTIVATION_FEEDBACK,
    SERVICE_DEACTIVATION_RESULT
}

export enum SERVICE_DEACTIVATION_PROCESS_STEP {
    START_SERVICE_DEACTIVATION,
    SERVICE_DEACTIVATION_FEEDBACK,
    SERVICE_DEACTIVATION_RESULT
}

export enum DEACTIVATION_STATUS {
    NONE,
    ACTIVATING,
    SUCCESS,
    FAILURE
}

interface IProcessStep {
    stepNumber: number;
    stepType: SERVICE_DEACTIVATION_PROCESS_STEP;
    next?: () => IProcessStep | null;
    prev?: () => IProcessStep | null;
    parent: IProcess | null;
}

interface IProcess {
    type: SERVICE_DEACTIVATION_PROCESS;
    processSteps: IProcessStep[];
}

interface IServiceDeactivationProcess {
    stepNumber: number;
    stepType: SERVICE_DEACTIVATION_PROCESS_STEP;
    processes: IProcess[];
}

export class ServicesDeactivationProgressModalStore {
    rootStore: RootStore;

    DEACTIVATION_MODAL_MAX_NUMBER_OF_STEPS = 3;

    currentProcessType: SERVICE_DEACTIVATION_PROCESS = SERVICE_DEACTIVATION_PROCESS.START_SERVICE_DEACTIVATION;
    currentStepType: SERVICE_DEACTIVATION_PROCESS_STEP = SERVICE_DEACTIVATION_PROCESS_STEP.START_SERVICE_DEACTIVATION;
    currentServiceDeactivationStatus: DEACTIVATION_STATUS = DEACTIVATION_STATUS.NONE;
    isDeactivationProgressModalDisplayed = false;
    isServiceSuccessfullyDeactivated = false;
    inputFeedback = "";
    serviceDeactivationProcess: IServiceDeactivationProcess = {
        stepNumber: 1,
        stepType: SERVICE_DEACTIVATION_PROCESS_STEP.START_SERVICE_DEACTIVATION,
        processes: [
            {
                type: SERVICE_DEACTIVATION_PROCESS.SERVICE_DEACTIVATION_FEEDBACK,
                processSteps: [
                    {
                        stepNumber: 2,
                        stepType: SERVICE_DEACTIVATION_PROCESS_STEP.SERVICE_DEACTIVATION_FEEDBACK,
                        next: function () {
                            return this.parent ? this.parent.processSteps[1] : null;
                        },
                        prev: function () {
                            return this.parent ? this.parent.processSteps[0] : null;
                        },
                        parent: null
                    }
                ]
            },
            {
                type: SERVICE_DEACTIVATION_PROCESS.SERVICE_DEACTIVATION_RESULT,
                processSteps: [
                    {
                        stepNumber: 3,
                        stepType: SERVICE_DEACTIVATION_PROCESS_STEP.SERVICE_DEACTIVATION_RESULT,
                        next: function () {
                            return this.parent ? this.parent.processSteps[1] : null;
                        },
                        prev: function () {
                            return this.parent ? this.parent.processSteps[0] : null;
                        },
                        parent: null
                    }
                ]
            }
        ]
    };

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        // Assign parents post object creation
        for (let process of this.serviceDeactivationProcess.processes) {
            for (let step of process.processSteps) {
                step.parent = process;
            }
        }

        makeObservable(this, {
            currentProcessType: observable,
            currentStepType: observable,
            currentServiceDeactivationStatus: observable,
            isDeactivationProgressModalDisplayed: observable,
            isServiceSuccessfullyDeactivated: observable,
            inputFeedback: observable,

            getProcess: action,
            resetServiceDeactivationModal: action,
            resetCurrentProcessType: action,
            setCurrentProcessType: action,
            setCurrentStepType: action,
            setCurrentServiceDeactivationStatus: action,
            setIsDeactivationProgressModalDisplayed: action,
            setIsServiceSuccessfullyDeactivated: action,
            setInputFeedback: action,

            processes: computed,
            currentProcess: computed,
            currentStep: computed
        });
    }

    getProcess = (processType: SERVICE_DEACTIVATION_PROCESS) => {
        return this.serviceDeactivationProcess.processes.find((process) => {
            return process.type === processType;
        })!;
    };

    resetServiceDeactivationModal = () => {
        this.resetCurrentProcessType();
        this.currentServiceDeactivationStatus = DEACTIVATION_STATUS.NONE;
    };

    resetCurrentProcessType() {
        this.currentProcessType = SERVICE_DEACTIVATION_PROCESS.SERVICE_DEACTIVATION_RESULT;
        this.currentStepType = SERVICE_DEACTIVATION_PROCESS_STEP.SERVICE_DEACTIVATION_RESULT;
    }

    setCurrentProcessType = (processType: SERVICE_DEACTIVATION_PROCESS) => {
        this.currentProcessType = processType;
        this.currentStepType = this.getProcess(processType).processSteps[0].stepType;
    };

    setCurrentStepType = (stepType: SERVICE_DEACTIVATION_PROCESS_STEP) => {
        this.currentStepType = stepType;
    };

    setIsDeactivationProgressModalDisplayed = (isModalDisplayed: boolean) => {
        this.isDeactivationProgressModalDisplayed = isModalDisplayed;
    };

    setCurrentServiceDeactivationStatus = (status: DEACTIVATION_STATUS) => {
        this.currentServiceDeactivationStatus = status;
    };
    setIsServiceSuccessfullyDeactivated = (isServiceSuccessfullyDeactivated: boolean) => {
        this.isServiceSuccessfullyDeactivated = isServiceSuccessfullyDeactivated;
    };

    setInputFeedback = (inputFeedback: "") => {
        this.inputFeedback = inputFeedback;
    };

    /* COMPUTED */
    get processes() {
        return this.serviceDeactivationProcess.processes;
    }

    get currentProcess(): IProcess | undefined {
        return this.serviceDeactivationProcess.processes.find((process) => process.type === this.currentProcessType);
    }

    get currentStep(): IProcessStep | undefined {
        return this.currentProcess?.processSteps.find((step) => step.stepType === this.currentStepType);
    }

    deactivateServices = async (serviceid: string): Promise<boolean> => {
        this.setIsServiceSuccessfullyDeactivated(false);

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const requestBody = {
                feedback: this.inputFeedback
            };

            const response = await fetch(NetworkConfig.deactivateService + serviceid, {
                method: "POST",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (response.status === 200) {
                this.setIsServiceSuccessfullyDeactivated(true);
                this.rootStore.servicesStore.setRefetchServicesData(true);
            } else {
                console.error("Error deactivate_service: ", response);
            }
        } catch (error) {
            console.error("Error while trying to call deactivate_service: ", error);
        }

        return this.isServiceSuccessfullyDeactivated;
    };
}
