import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import translationDE from "src/assets/locales/de/translation.json";
import disclaimersDE from "src/assets/locales/de/disclaimers.json";
import translationEN from "src/assets/locales/en/translation.json";
import disclaimersEN from "src/assets/locales/en/disclaimers.json";
import translationFR from "src/assets/locales/fr/translation.json";
import disclaimersFR from "src/assets/locales/fr/disclaimers.json";
import translationIT from "src/assets/locales/it/translation.json";
import disclaimersIT from "src/assets/locales/it/disclaimers.json";
const resources = {
    de: {
        translation: translationDE,
        disclaimers: disclaimersDE
    },
    en: {
        translation: translationEN,
        disclaimers: disclaimersEN
    },
    fr: {
        translation: translationFR,
        disclaimers: disclaimersFR
    },
    it: {
        translation: translationIT,
        disclaimers: disclaimersIT
    }
};

export const getBrowserLanguage = () => {
    let language = "";

    const languageBrowser = window.navigator.language;
    const languageBrowserParts = languageBrowser.split("-");

    if (languageBrowserParts.length > 1) {
        language = languageBrowserParts[0];
    } else {
        language = languageBrowser;
    }

    return language.toLowerCase();
};

i18n.use(XHR)
    .use(initReactI18next)
    .init({
        resources,
        lng: getBrowserLanguage(),
        fallbackLng: "de",
        debug: false,
        load: "languageOnly",
        interpolation: {
            escapeValue: false
        },
        preload: ["de", "en", "fr", "it"]
    });

export const getLang = (): string => {
    return i18n.language.split("-")[0];
};

export default i18n;
