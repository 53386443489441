import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useSelectionListKeyHandler } from "src/hooks/ticket/selection-list-key-handler/useSelectionListKeyHandler";
import { boldenMatchedSubstring } from "src/utils/Common";
import styles from "./RealestateFormField.module.css";
import "./GeneralInvoiceFormStyles.css";
import { ColorStyle } from "src/utils/Colors";
import { IRealestate } from "src/stores/krediflow/InvoiceTypes";
import { IRealestateData } from "src/stores/RealestateSearchStore";

const RealestateFormFieldBase = () => {
    const { t } = useTranslation();
    const { realestateSearchStore, invoiceStore } = useContext(RootStoreContext);

    const [displayRealestateList, setDisplayRealesteateList] = useState<boolean>(false);
    const [lastScrollPositionYRealestateItems, setLastSrollYPositionRealestateItems] = useState<number>(0);

    const realestateInputRef = useRef<HTMLInputElement>(null);

    const resetSearchQuery = useCallback(() => {
        realestateSearchStore.setCurrentRealestateSearchQuery(
            realestateSearchStore.selectedRealestateQueryString ?? ""
        );
    }, [realestateSearchStore]);

    useEffect(() => {
        const handleClickOutsideRealestateInput = (event: any) => {
            if (
                displayRealestateList &&
                realestateInputRef.current &&
                !realestateInputRef.current.contains(event.target) &&
                !(event.target.classList.contains("list-item") && event.target.classList.contains("realestate")) &&
                !(
                    event.target.parentElement.classList.contains("list-item") &&
                    event.target.parentElement.classList.contains("realestate")
                ) &&
                !(event.target.localName === "path" || event.target.localName === "svg") &&
                !(event.target.classList.contains("highlighted-text") && event.target.classList.contains("realestate"))
            ) {
                setDisplayRealesteateList(false);

                if (
                    invoiceStore.currentInvoice?.isRealestateSelected &&
                    realestateSearchStore.currentRealestateSearchQuery !==
                        realestateSearchStore.selectedRealestateQueryString
                ) {
                    resetSearchQuery();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutsideRealestateInput);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideRealestateInput);
        };
    }, [
        displayRealestateList,
        resetSearchQuery,
        realestateSearchStore.currentRealestateSearchQuery,
        realestateSearchStore.selectedRealestateQueryString,
        invoiceStore.currentInvoice?.isRealestateSelected
    ]);

    const updateInvoiceWithSelectedRealestate = useCallback(
        async (realestate: IRealestate) => {
            invoiceStore.updateInvoiceAndFormWithSelectedRealestate(realestate);
        },
        [invoiceStore]
    );

    const onSelectRealestateItem = useCallback(
        (activeListItemIndex: number) => {
            const selectedRealestateItem = realestateSearchStore.filteredRealestateData[activeListItemIndex];

            const realestate: IRealestate = {
                id: selectedRealestateItem.id,
                number: selectedRealestateItem.number,
                name: selectedRealestateItem.name,
                zip: selectedRealestateItem.zip,
                city: selectedRealestateItem.city
            };

            updateInvoiceWithSelectedRealestate(realestate);
        },
        [updateInvoiceWithSelectedRealestate, realestateSearchStore.filteredRealestateData]
    );

    const { onKeyDownFunction, setActiveListItemIndex, NO_LIST_ITEM_SELECTED } = useSelectionListKeyHandler({
        listName: "realestate",
        totalNumberOfDisplayedItems: invoiceStore.numberOfDisplayedItems,
        setIsListDisplayed: setDisplayRealesteateList,
        isListDisplayed: displayRealestateList,
        onSelectItem: onSelectRealestateItem,
        resetSearchQuery: resetSearchQuery
    });

    const resetAmountOfItemsDisplayedInRealestateList = useCallback(() => {
        setLastSrollYPositionRealestateItems(0);
        invoiceStore.setNumberOfDisplayedItems(invoiceStore.sliceSizeOfDisplayedItems);
    }, [invoiceStore]);

    const onChangeRealestateInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            resetAmountOfItemsDisplayedInRealestateList();

            const inputRealestate = e.target.value;
            realestateSearchStore.setCurrentRealestateSearchQuery(inputRealestate);
        },
        [realestateSearchStore, resetAmountOfItemsDisplayedInRealestateList]
    );

    const onFocusRealestateInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (invoiceStore.currentInvoice?.isRealestateSelected && realestateInputRef.current) {
                realestateInputRef.current.select();
            }

            resetAmountOfItemsDisplayedInRealestateList();
            setDisplayRealesteateList(true);
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
        },
        [
            resetAmountOfItemsDisplayedInRealestateList,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            invoiceStore.currentInvoice?.isRealestateSelected
        ]
    );

    const onClickRealestateItem = useCallback(
        (realestateData: IRealestateData) => async (e: any) => {
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
            setDisplayRealesteateList(false);

            const realestate: IRealestate = {
                id: realestateData.id,
                number: realestateData.number,
                name: realestateData.name,
                zip: realestateData.zip,
                city: realestateData.city
            };

            updateInvoiceWithSelectedRealestate(realestate);
            invoiceStore.currentInvoice?.validateRealestateInvoiceDetails();
        },
        [
            updateInvoiceWithSelectedRealestate,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            invoiceStore.currentInvoice
        ]
    );

    const onScrollRealestateList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYRealestateItems;

            const hasMoreResulsToDisplay =
                invoiceStore.numberOfDisplayedItems < realestateSearchStore.filteredRealestateData.length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                invoiceStore.setNumberOfDisplayedItems(
                    invoiceStore.numberOfDisplayedItems + invoiceStore.sliceSizeOfDisplayedItems
                );
            }

            setLastSrollYPositionRealestateItems(pixelsFromTop);
        },
        [lastScrollPositionYRealestateItems, invoiceStore, realestateSearchStore.filteredRealestateData.length]
    );

    const realestateSelectionList = (
        <ul
            id="realestate-dropdown-list"
            className={"dropdown-list realestate krediflow"}
            onScroll={onScrollRealestateList}
        >
            {realestateSearchStore.filteredRealestateData
                .slice(0, invoiceStore.numberOfDisplayedItems)
                .map((realestate, index) => {
                    const realestateName = `${realestate.nameZipCity} [${realestate.number}]`;

                    const formattedRealestateName = boldenMatchedSubstring(
                        realestateSearchStore.currentRealestateSearchQuery ?? "",
                        realestateName,
                        "highlighted-text realestate"
                    );

                    return (
                        <li
                            key={index}
                            className="d-flex align-items-center list-item realestate"
                            onClick={onClickRealestateItem(realestate)}
                            id={`realestate-list-item-${index}`}
                        >
                            <UI.Icon
                                icon={realestate.consolidation ? UI.SVGIcon.Realestates : UI.SVGIcon.Realestate}
                                size={25}
                                circular
                                color={ColorStyle("grey")}
                                backgroundColor="#D2D5D9"
                                className="realestate-icon"
                            />
                            <div
                                className="realestate-text"
                                dangerouslySetInnerHTML={{
                                    __html: formattedRealestateName
                                }}
                            />
                        </li>
                    );
                })}
        </ul>
    );

    const onBlurRealestateInput = useCallback(() => {
        invoiceStore.currentInvoice?.validateRealestateInvoiceDetails();
    }, [invoiceStore]);

    const readOnlyRealestateElement = (
        <div className="view-only-container mt-3 mt-lg-0">
            <div className="realestate bold-text mb-1">{t("screens.realestate.title")}</div>
            <div>
                <span className="text-with-number">{invoiceStore.currentInvoice?.realestate?.name}</span>
                <span>{`${
                    invoiceStore.currentInvoice?.realestate?.number
                        ? `[${invoiceStore.currentInvoice?.realestate?.number}]`
                        : ""
                }`}</span>
            </div>

            <div>
                {invoiceStore.currentInvoice?.realestate?.zip} {invoiceStore.currentInvoice?.realestate?.city}
            </div>
        </div>
    );

    return (
        <>
            {invoiceStore.isEditing ? (
                <>
                    <UI.Input
                        ref={realestateInputRef}
                        label={t("screens.realestate.title").toString()}
                        type="text"
                        as="textarea"
                        autoComplete="off"
                        className={styles.RealestateTextArea}
                        value={realestateSearchStore.currentRealestateSearchQuery ?? ""}
                        onChange={onChangeRealestateInput}
                        onFocus={onFocusRealestateInput}
                        onKeyDown={onKeyDownFunction}
                        onBlur={onBlurRealestateInput}
                        disabled={!invoiceStore.isEditing}
                        errorMsg={invoiceStore.currentInvoice?.errors.realestateRequired ?? undefined}
                    />
                    {displayRealestateList ? realestateSelectionList : undefined}
                </>
            ) : (
                readOnlyRealestateElement
            )}
        </>
    );
};

export const RealestateFormField = Sentry.withProfiler(observer(RealestateFormFieldBase));
