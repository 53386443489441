import React, { useContext, useCallback, FunctionComponent } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { CUSTOMER_FEATURE, UPSERT_CUSTOMERSETTING } from "src/api/customer";
import { UpsertCustomersetting, UpsertCustomersettingVariables } from "src/api/generated/UpsertCustomersetting";
import { MessageType } from "src/components/notifications/Notifier";
import { GetEasyContactInformation as EasyContactInformation } from "src/api/generated/GetEasyContactInformation";
import { ums_customersettings_insert_input } from "src/api/generated/globalTypes";
import styles from "./EasyContactSettingsForm.module.css";

type EasyContactSettingsFormProps = {
    easyContactDetails: EasyContactInformation;
    refetchEasyContactDetails: any;
};

interface EasyContactSettingsFormFields {
    inboxemailaddress: string;
    confirmationemailtextDE: string;
    confirmationemailtextEN: string;
    confirmationemailtextFR: string;
    confirmationemailtextIT: string;
    sendemailecandticketing: boolean;
}

interface ConfirmationEmailText {
    id: any;
    value: string | null;
}

const EasyContactSettingsFormBase: FunctionComponent<EasyContactSettingsFormProps> = (props) => {
    const { t } = useTranslation();

    const { uiStore, navStore } = useContext(RootStoreContext);

    const { refetchEasyContactDetails } = props;

    const [upsertCustomersettingMutation, { loading: isUpsertCustomersettingMutationLoading }] = useMutation<
        UpsertCustomersetting,
        UpsertCustomersettingVariables
    >(UPSERT_CUSTOMERSETTING, {
        onCompleted: () => {
            printStatusMessage(t("screens.easycontact_settings.success_save_message"), MessageType.INFO);
        }
    });

    const printStatusMessage = useCallback(
        (message: string, msgType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: msgType,
                    autoClose: msgType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    const inboxemailaddress =
        props.easyContactDetails.inboxemailaddress.length > 0
            ? props.easyContactDetails.inboxemailaddress[0].value
            : "";
    const sendemailecandticketing = props.easyContactDetails.sendemailecandticketing[0]?.value ?? false;
    let confirmationemailtextDE = "";
    let confirmationemailtextEN = "";
    let confirmationemailtextFR = "";
    let confirmationemailtextIT = "";

    const getTextFormattedForTextArea = (confirmationEmailTextDBValue: ConfirmationEmailText[]) => {
        if (confirmationEmailTextDBValue.length > 0 && confirmationEmailTextDBValue[0].value) {
            return confirmationEmailTextDBValue[0].value.replaceAll("<br>", "\n");
        } else {
            return "";
        }
    };

    confirmationemailtextDE = getTextFormattedForTextArea(props.easyContactDetails.confirmationemailtext_de);
    confirmationemailtextEN = getTextFormattedForTextArea(props.easyContactDetails.confirmationemailtext_en);
    confirmationemailtextFR = getTextFormattedForTextArea(props.easyContactDetails.confirmationemailtext_fr);
    confirmationemailtextIT = getTextFormattedForTextArea(props.easyContactDetails.confirmationemailtext_it);

    const { register, handleSubmit, formState, reset } = useForm<EasyContactSettingsFormFields>({
        mode: "onBlur",
        defaultValues: {
            inboxemailaddress: inboxemailaddress ?? "",
            confirmationemailtextDE: confirmationemailtextDE ?? "",
            confirmationemailtextEN: confirmationemailtextEN ?? "",
            confirmationemailtextFR: confirmationemailtextFR ?? "",
            confirmationemailtextIT: confirmationemailtextIT ?? "",
            sendemailecandticketing: sendemailecandticketing === "true" ? true : false
        }
    });

    const { errors } = formState;

    const resetForm = useCallback(() => {
        const inboxemailaddress = props.easyContactDetails.inboxemailaddress[0].value;
        const sendemailecandticketing = props.easyContactDetails.sendemailecandticketing[0].value;
        const confirmationemailtextDE = getTextFormattedForTextArea(props.easyContactDetails.confirmationemailtext_de);
        const confirmationemailtextEN = getTextFormattedForTextArea(props.easyContactDetails.confirmationemailtext_en);
        const confirmationemailtextFR = getTextFormattedForTextArea(props.easyContactDetails.confirmationemailtext_fr);
        const confirmationemailtextIT = getTextFormattedForTextArea(props.easyContactDetails.confirmationemailtext_it);

        reset({
            inboxemailaddress: inboxemailaddress ?? "",
            confirmationemailtextDE: confirmationemailtextDE ?? "",
            confirmationemailtextEN: confirmationemailtextEN ?? "",
            confirmationemailtextFR: confirmationemailtextFR ?? "",
            confirmationemailtextIT: confirmationemailtextIT ?? "",
            sendemailecandticketing: sendemailecandticketing === "true" ? true : false
        });
    }, [reset, props.easyContactDetails]);

    const { dirtyFields } = formState;

    const handleFormSubmit = useCallback(
        async (formData: EasyContactSettingsFormFields) => {
            try {
                const insertInput: ums_customersettings_insert_input[] = [];

                if (dirtyFields.inboxemailaddress) {
                    insertInput.push({
                        key: "EC.EMAILINBOX",
                        value: formData.inboxemailaddress,
                        valuetype: 3
                    });
                }

                if (dirtyFields.sendemailecandticketing) {
                    insertInput.push({
                        key: "EC.SEND_EMAIL_IN_EC_AND_TICKETING",
                        value: formData.sendemailecandticketing.toString(),
                        valuetype: 1
                    });
                }

                if (dirtyFields.confirmationemailtextDE) {
                    insertInput.push({
                        key: "EC.CONFIRMATIONMAIL_DE",
                        value: formData.confirmationemailtextDE.replaceAll("\n", "<br>"),
                        valuetype: 3
                    });
                }

                if (dirtyFields.confirmationemailtextEN) {
                    insertInput.push({
                        key: "EC.CONFIRMATIONMAIL_EN",
                        value: formData.confirmationemailtextEN.replaceAll("\n", "<br>"),
                        valuetype: 3
                    });
                }

                if (dirtyFields.confirmationemailtextFR) {
                    insertInput.push({
                        key: "EC.CONFIRMATIONMAIL_FR",
                        value: formData.confirmationemailtextFR.replaceAll("\n", "<br>"),
                        valuetype: 3
                    });
                }

                if (dirtyFields.confirmationemailtextIT) {
                    insertInput.push({
                        key: "EC.CONFIRMATIONMAIL_IT",
                        value: formData.confirmationemailtextIT.replaceAll("\n", "<br>"),
                        valuetype: 3
                    });
                }

                await upsertCustomersettingMutation({
                    variables: {
                        objects: insertInput
                    }
                });

                refetchEasyContactDetails();

                reset(
                    { ...formData },
                    {
                        keepDirty: false,
                        keepDefaultValues: false,
                        keepValues: false
                    }
                );
            } catch (error: any) {
                printStatusMessage(
                    `${t("screens.easycontact_settings.failure_save_message")}: ${error.message}`,
                    MessageType.ERROR
                );
            }
        },
        [reset, upsertCustomersettingMutation, printStatusMessage, t, dirtyFields, refetchEasyContactDetails]
    );

    return (
        <UI.Form>
            <UI.Input
                label={t("screens.easycontact_settings.form.main_email_address")}
                {...register("inboxemailaddress")}
                type="text"
                className={dirtyFields.inboxemailaddress ? styles.changed : ""}
                errorMsg={errors.inboxemailaddress ? errors.inboxemailaddress.message : undefined}
            />
            {navStore.customer.features?.find((feat) => feat.feature === CUSTOMER_FEATURE.TICKETING) ? (
                <UI.Checkbox
                    label={t("screens.easycontact_settings.form.send_email_ec_and_ticketing")}
                    {...register("sendemailecandticketing")}
                    className={dirtyFields.sendemailecandticketing ? styles.changed : ""}
                    errorMsg={errors.inboxemailaddress ? errors.inboxemailaddress.message : undefined}
                />
            ) : undefined}
            <div className="mt-2">
                <label className="form-label">{t("screens.easycontact_settings.form.confirmation_emailtext")}</label>
                <UI.BootstrapTabs
                    defaultActiveKey="german"
                    id="ec-confirmation-text-language"
                    className={`${styles.EcConfirmationTextTabList} mb-3 ${
                        navStore.hasOptionMultilingual ? "" : styles.NoMultilingual
                    }`}
                >
                    <UI.Tab eventKey="german" title="Deutsch">
                        <UI.Input
                            {...register("confirmationemailtextDE")}
                            as="textarea"
                            type="text"
                            className={`${dirtyFields.confirmationemailtextDE ? styles.changed : ""} ${
                                styles.ConfirmationEmailTextArea
                            }`}
                            errorMsg={
                                errors.confirmationemailtextDE ? errors.confirmationemailtextDE.message : undefined
                            }
                        />
                    </UI.Tab>
                    <UI.Tab eventKey="french" title="Französisch">
                        <UI.Input
                            {...register("confirmationemailtextFR")}
                            as="textarea"
                            type="text"
                            className={`${dirtyFields.confirmationemailtextFR ? styles.changed : ""} ${
                                styles.ConfirmationEmailTextArea
                            }`}
                            errorMsg={
                                errors.confirmationemailtextFR ? errors.confirmationemailtextFR.message : undefined
                            }
                        />
                    </UI.Tab>
                    <UI.Tab eventKey="italian" title="Italienisch">
                        <UI.Input
                            {...register("confirmationemailtextIT")}
                            as="textarea"
                            type="text"
                            className={`${dirtyFields.confirmationemailtextIT ? styles.changed : ""} ${
                                styles.ConfirmationEmailTextArea
                            }`}
                            errorMsg={
                                errors.confirmationemailtextIT ? errors.confirmationemailtextIT.message : undefined
                            }
                        />
                    </UI.Tab>
                    <UI.Tab eventKey="english" title="Englisch">
                        <UI.Input
                            {...register("confirmationemailtextEN")}
                            as="textarea"
                            type="text"
                            className={`${dirtyFields.confirmationemailtextEN ? styles.changed : ""} ${
                                styles.ConfirmationEmailTextArea
                            }`}
                            errorMsg={
                                errors.confirmationemailtextEN ? errors.confirmationemailtextEN.message : undefined
                            }
                        />
                    </UI.Tab>
                </UI.BootstrapTabs>
            </div>
            <div className="mt-4">
                <UI.Button
                    type="submit"
                    label={isUpsertCustomersettingMutationLoading ? t("labels.is_saving") : t("labels.save")}
                    disabled={!formState.isDirty}
                    onClick={handleSubmit(handleFormSubmit)}
                    className={styles.FormButton}
                />
                <UI.Button
                    label={t("labels.reset")}
                    disabled={!formState.isDirty}
                    onClick={resetForm}
                    className={`${styles.FormButton} ml-sm-2 mt-2 mt-sm-0`}
                />
            </div>
            {props.children}
        </UI.Form>
    );
};

export const EasyContactSettingsForm = Sentry.withProfiler(EasyContactSettingsFormBase);
