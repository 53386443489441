import React, { FunctionComponent, useContext } from "react";
import { Tiles } from "src/components";
import { UI } from "@wwimmo/ui";
import { useQuery } from "@apollo/client";
import {
    GetTilesByScreen,
    GetTilesByScreenVariables,
    GetTilesByScreen_portal_tilescreens
} from "src/api/generated/GetTilesByScreen";
import { GET_TILES_BY_SCREEN, ScreenType } from "src/api/tiles";
import * as Screens from "src/screens";
import { getLang } from "src/utils/i18n";
import { useParams } from "react-router";
import { ScreenWidth } from "src/stores/UIStore";
import { RootStoreContext } from "src/stores/RootStore";

interface Params {
    realestateid: string;
}

type ScreenWithTilesProps = {
    screenId: ScreenType;
    realestateid?: string;
    title: JSX.Element | string;
};

const ScreenWithTilesBase: FunctionComponent<ScreenWithTilesProps> = (props) => {
    const { navStore, uiStore } = useContext(RootStoreContext);
    const { realestateid } = useParams<Params>();

    uiStore.setIsLegendDisplayed(true);

    const { loading, error, data } = useQuery<GetTilesByScreen, GetTilesByScreenVariables>(GET_TILES_BY_SCREEN, {
        variables: {
            realestateid: props.realestateid ?? realestateid,
            screen: props.screenId,
            lang: getLang(),
            tiletypes: navStore.customer.activatedTiles
        }
    });

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }

    const tiles =
        data &&
        data.portal_tilescreens.map((tilescreen: GetTilesByScreen_portal_tilescreens) => {
            if (tilescreen.tile) {
                return window.innerWidth >= ScreenWidth.XL ? (
                    <Tiles.Tile key={tilescreen.tile.id} data={tilescreen.tile!} />
                ) : (
                    <UI.Col key={tilescreen.tile.id} sm={12} md={6}>
                        <Tiles.Tile data={tilescreen.tile!} />
                    </UI.Col>
                );
            }
            return React.Fragment;
        });

    return (
        <UI.Container className="pt-4">
            <UI.Row>
                <UI.Col xl={9}>
                    <UI.Title>{props.title}</UI.Title>
                </UI.Col>
            </UI.Row>
            <UI.Row>
                <UI.Col xl={9} className="position-relative">
                    {props.children}
                </UI.Col>
                {window.innerWidth >= ScreenWidth.XL ? (
                    <UI.Col xl={3}>
                        <Tiles.ContactTile realestateid={props.realestateid ?? realestateid} />
                        {tiles}
                    </UI.Col>
                ) : (
                    <>
                        <UI.Col sm={12}>
                            <Tiles.ContactTile realestateid={props.realestateid ?? realestateid} />
                        </UI.Col>
                        {tiles}
                    </>
                )}
            </UI.Row>
        </UI.Container>
    );
};

export const ScreenWithTiles = ScreenWithTilesBase;
