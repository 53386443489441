import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { UI } from "@wwimmo/ui";

interface NavigationbarItemProps {
    to: string;
    icon: JSX.Element;
    label: string;
    isCondominium: boolean;
    className?: string;
    onClick?: any;
    isDisabled?: boolean;
    exact?: boolean;
}

export const NavigationbarItem = (props: NavigationbarItemProps) => (
    <LinkContainer
        className={`${props.className ?? "NavigationbarItem"} ${props.isDisabled ? "disabled" : ""}`}
        to={props.to}
        onClick={props.onClick}
        exact={props.exact}
    >
        <UI.Nav.Link active={false}>
            <UI.Icon icon={props.icon} />
            <span className={`d-none ${props.isCondominium ? "d-lg-block" : "d-md-block"} ml-2`}>{props.label}</span>
        </UI.Nav.Link>
    </LinkContainer>
);
