import React, { useContext } from "react";
import { UI } from "@wwimmo/ui";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { ColorStyle } from "src/utils/Colors";
import { RootStoreContext } from "src/stores/RootStore";
import { SCREEN_SIZE } from "src/stores/UIStore";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { Invoice } from "src/stores/krediflow/InvoiceStore";
import { getInvoiceTypeIcon } from "src/screens/creditors/Creditors";
import { formatDate } from "src/stores/krediflow/Utils";
import { formatCurrency } from "src/utils/Currency";
import styles from "./InvoiceListRow.module.css";

const InvoiceListRowDesktopBase = (props: { invoice: Invoice; widths: number[] }) => {
    const { uiStore } = useContext(RootStoreContext);

    const { invoiceType, number, date, creditor, invoiceNumber, amount, dueDate, realestate, assignedUser, state } =
        props.invoice;

    const { widths } = props;
    const { t } = useTranslation();

    return (
        <UI.List.Row className={styles.InvoiceListRow} key={number}>
            {/* TYPE (ICON) */}
            <UI.List.Cell
                className={`${uiStore.currentScreenSize <= SCREEN_SIZE.LAPTOP_L ? "d-none" : "d-flex"}`}
                value={
                    <div className={styles.TypeIcon}>
                        <img
                            src={getInvoiceTypeIcon(invoiceType)}
                            alt={t(`screens.kredi_flow.invoice_type.${String(invoiceType)}`) ?? "icon"}
                        />
                    </div>
                }
                colspan={widths[0]}
            />

            {/* NUMBER */}
            <UI.List.Cell value={number} colspan={widths[1]} />

            {/* INVOICE DATE */}
            <UI.List.Cell
                value={
                    uiStore.currentScreenSize > SCREEN_SIZE.LAPTOP
                        ? formatDate(date ?? format(new Date(), "d.M.yy"))
                        : formatDate(date ?? format(new Date(), "d.M.yy"), true)
                }
                colspan={widths[2]}
            />

            {/* CREDITOR NAME */}
            <UI.List.Cell colspan={widths[3]} value={creditor?.name ?? ""} truncate />

            {/* INVOICE NUMBER */}
            <UI.List.Cell
                className={uiStore.currentScreenSize <= SCREEN_SIZE.TABLET ? "d-none" : "d-flex"}
                colspan={widths[4]}
                value={invoiceNumber ?? ""}
                truncate
            />

            {/* AMOUNT */}
            <UI.List.Cell
                // className={`${uiStore.currentScreenSize < SCREEN_SIZE.LAPTOP ? "d-none" : "d-flex"}`}
                colspan={widths[5]}
                value={amount ? formatCurrency(amount) : "-"}
                truncate
            />

            {/* DUE DATE */}
            <UI.List.Cell
                className={uiStore.currentScreenSize <= SCREEN_SIZE.TABLET ? "d-none" : "d-flex"}
                value={
                    uiStore.currentScreenSize > SCREEN_SIZE.LAPTOP
                        ? formatDate(dueDate ?? format(new Date(), "d.M.yy"))
                        : formatDate(dueDate ?? format(new Date(), "d.M.yy"), true)
                }
                colspan={widths[6]}
            />

            {/* REALESTATE */}
            <UI.List.Cell
                colspan={widths[7]}
                value={
                    realestate && (
                        <div>
                            <div>{realestate.name}</div>
                            <div>
                                {realestate.zip} {realestate.city}
                            </div>
                        </div>
                    )
                }
                truncate
            />

            {/* ASSIGNED USER */}
            <UI.List.Cell
                className={uiStore.currentScreenSize <= SCREEN_SIZE.LAPTOP ? "d-none" : "d-flex"}
                value={
                    <div className={styles.CellContainerCenter}>
                        {uiStore.currentScreenSize > SCREEN_SIZE.LAPTOP ? (
                            <UI.Icon
                                icon={UI.SVGIcon.Profile}
                                color={ColorStyle(assignedUser ? "primary" : "yellow")}
                            />
                        ) : undefined}
                        {assignedUser ? `${assignedUser.name1} ${assignedUser.name2}` : "-"}
                    </div>
                }
                colspan={widths[8]}
                truncate
            />

            {/* STATE */}
            <UI.List.Cell
                className={`${uiStore.currentScreenSize <= SCREEN_SIZE.LAPTOP_L ? "d-none" : "d-flex"}`}
                colspan={widths[9]}
                value={
                    <div className={styles[`status-${state}`]}>{t(`screens.kredi_flow.state.${String(state)}`)}</div>
                }
                truncate
            />
        </UI.List.Row>
    );
};

export const InvoiceListRowDesktop = Sentry.withProfiler(observer(InvoiceListRowDesktopBase));
