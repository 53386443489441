import React from "react";
import { useTranslation } from "react-i18next";
import DisclaimerGraph from "src/assets/images/disclaimer-graph.jpg";
import styles from "src/screens/auth/register/Disclaimers.module.css";

interface DisclaimersProps {
    onAccept?: () => void;
}

export const Disclaimers = (props: DisclaimersProps) => {
    const { t } = useTranslation(["disclaimers"]);
    const urlDataProcessingAgreement =
        "https://www.wwimmo.ch/wp-content/uploads/2023/06/Auftragsverarbeitungsvertrag.pdf";
    return (
        <div className="p-4">
            <h5>{t("paragraph1.title")}</h5>
            <p>{t("paragraph1.content1")}</p>
            <p>{t("paragraph1.content2")}</p>
            <p>{t("paragraph1.content3")}</p>
            <p>{t("paragraph1.content4")}</p>

            <h5>{t("paragraph2.title")}</h5>
            <p>{t("paragraph2.content1")}</p>
            <p>{t("paragraph2.content2")}</p>
            <p>{t("paragraph2.content3")}</p>

            <div className="text-center m-5">
                <img className={styles.DisclaimersImage} src={DisclaimerGraph} alt={t("paragraph2.image1")} />
            </div>

            <h5>{t("paragraph3.title")}</h5>
            <p>{t("paragraph3.content1")}</p>

            <h5>{t("paragraph4.title")}</h5>
            <p>{t("paragraph4.content1")}</p>

            <h6>{t("paragraph4.subtitle1")}</h6>
            <p>{t("paragraph4.content2.listtitle")}</p>
            <ul>
                <li>{t("paragraph4.content2.item1")}</li>
                <li>{t("paragraph4.content2.item2")}</li>
                <li>{t("paragraph4.content2.item3")}</li>
            </ul>
            <h6>{t("paragraph4.subtitle2")}</h6>
            <p>{t("paragraph4.content3")}</p>
            <h6>{t("paragraph4.subtitle3")}</h6>
            <p>{t("paragraph4.content4")}</p>

            <h5>{t("paragraph5.title")}</h5>
            <p>{t("paragraph5.content1")}</p>
            <p>{t("paragraph5.content2")}</p>
            <p>
                {t("paragraph5.content3")}{" "}
                <a
                    href={urlDataProcessingAgreement}
                    className={styles.LinkParagraph5Content3}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {t("paragraph5.data_processing_agreement")}
                </a>{" "}
                {t("paragraph5.restOfContent3")}
            </p>
            <p>{t("paragraph5.content4")}</p>
            <p>{t("paragraph5.content5")}</p>
            <p>{t("paragraph5.content6")}</p>
            <p>{t("paragraph5.content7")}</p>

            <h5>{t("paragraph6.title")}</h5>
            <p>{t("paragraph6.content1")}</p>

            <h5>{t("paragraph7.title")}</h5>
            <h6>{t("paragraph7.subtitle1")}</h6>
            <p>{t("paragraph7.content1")}</p>
            <h6>{t("paragraph7.subtitle2")}</h6>
            <p>{t("paragraph7.content2")}</p>
            <h6>{t("paragraph7.subtitle3")}</h6>
            <p>{t("paragraph7.content3")}</p>
            <h6>{t("paragraph7.subtitle4")}</h6>
            <p>{t("paragraph7.content4")}</p>
            <h6>{t("paragraph7.subtitle5")}</h6>
            <p>{t("paragraph7.content5")}</p>
        </div>
    );
};
