import { useState, useEffect, useContext, useCallback } from "react";
import { UI, ScreenSize } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { GetTileTypes_tiletypes as tile_types } from "src/api/generated/GetTileTypes";
import { useForm } from "react-hook-form";
import { MessageType } from "src/components/notifications/Notifier";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useMutation } from "@apollo/client";
import { UPSERT_CUSTOMERSETTING } from "src/api/customer";
import { UpsertCustomersetting, UpsertCustomersettingVariables } from "src/api/generated/UpsertCustomersetting";
import { ums_customersettings_insert_input } from "src/api/generated/globalTypes";
import { useTranslation } from "react-i18next";
import styles from "src/screens/tileadministration/TileAdministration.module.css";

interface TileAdministrationListProps {
    tileTypes: tile_types[];
    activatedTiles: number[];
    calculatedTiles?: number[];
}

interface TileTypeFields {
    tiles: number[];
}

interface LooseObject {
    [key: string]: any;
}

interface TileConfigurationListItem {
    name: string;
    checkbox: any;
    isDisplayedOnScreen: string;
    displayItem?: boolean;
}

const ALL_TILE_TYPES = ["1", "2", "3", "4", "5", "6", "7", "8"];

const TileAdministrationListBase = (props: TileAdministrationListProps) => {
    const { t } = useTranslation();

    const { register, handleSubmit, reset, formState } = useForm<TileTypeFields>({
        mode: "onBlur",
        defaultValues: {
            tiles: props.activatedTiles
        }
    });

    const { uiStore, navStore } = useContext(RootStoreContext);
    const [isSavingTileConfiguration, setIsSavingTileConfiguration] = useState(false);
    const [tileTranslationMap, setTileTranslationMap] = useState<Map<number, { label: string }>>();

    const tileToScreenMap: Map<number, { screen: string }> = new Map([
        [1, { screen: t("screens.labels.dashboard") }],
        [2, { screen: t("screens.labels.dashboard") }],
        [3, { screen: t("screens.labels.dashboard") }],
        [4, { screen: t("screens.labels.dashboard") }],
        [5, { screen: t("screens.labels.dashboard") }],
        [6, { screen: t("screens.labels.tenancies") }],
        [7, { screen: t("screens.labels.finances") }],
        [8, { screen: t("screens.labels.finances") }]
    ]);

    const [upsertCustomersettingMutation] = useMutation<UpsertCustomersetting, UpsertCustomersettingVariables>(
        UPSERT_CUSTOMERSETTING,
        {
            onCompleted: () => {
                printStatusMessage(t("screens.tile_administration.save_success_message"), MessageType.INFO);
            }
        }
    );

    const printStatusMessage = useCallback(
        (message: string, msgType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: msgType,
                    autoClose: msgType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    useEffect(() => {
        if (props.tileTypes) {
            setTileTranslationMap(getEnumerationMap(props.tileTypes));
        }
    }, [props.tileTypes]);

    const getEnumerationMap = (enumerations: Array<tile_types>) => {
        const enumerationMap = new Map();

        enumerations.forEach((enumeration) => {
            if (enumeration.key !== null) {
                enumerationMap.set(enumeration.key, {
                    label: enumeration.label
                });
            }
        });

        return enumerationMap;
    };

    const saveNewTileConfiguration = useCallback(
        async (formData: TileTypeFields) => {
            setIsSavingTileConfiguration(true);

            const upsertTileConfigurationObject: LooseObject = {};
            let activatedTiles: number[] = [];

            ALL_TILE_TYPES.forEach((tileNumber) => {
                const formDataTiles = formData.tiles.map((tileNumber) => tileNumber.toString());

                const isTileActivated = formDataTiles.includes(tileNumber);

                if (isTileActivated) {
                    activatedTiles.push(Number(tileNumber));
                }

                upsertTileConfigurationObject[tileNumber] = isTileActivated;
            });

            const insertInput: ums_customersettings_insert_input[] = [
                {
                    key: "PORTAL.TILECONFIGURATION",
                    value: JSON.stringify(upsertTileConfigurationObject),
                    valuetype: 3
                }
            ];

            await upsertCustomersettingMutation({
                variables: {
                    objects: insertInput
                }
            });

            navStore.setCustomer({
                ...navStore.customer,
                activatedTiles
            });

            reset(
                { ...formData },
                {
                    keepDirty: false,
                    keepDefaultValues: false,
                    keepValues: false
                }
            );

            setIsSavingTileConfiguration(false);
        },
        [upsertCustomersettingMutation, reset, navStore]
    );

    const resetForm = useCallback(() => {
        const { activatedTiles } = props;

        reset({
            tiles: activatedTiles
        });
    }, [reset, props]);

    const renderItem = useCallback((item: TileConfigurationListItem, screenSize: ScreenSize) => {
        const listRows: any[] = [];

        let rows: React.ReactElement[] = [];

        switch (screenSize) {
            case ScreenSize.DESKTOP:
                rows = [
                    <UI.List.Row key={"row-1"}>
                        <UI.List.Cell key={"c-1"} colspan={0.25} value={item.name} />
                        <UI.List.Cell key={"c-2"} colspan={0.25} value={item.checkbox} />
                        <UI.List.Cell key={"c-3"} colspan={0.25} value={item.isDisplayedOnScreen} />
                    </UI.List.Row>
                ];
                break;
            case ScreenSize.MOBILE:
                rows = [
                    <UI.List.Row key={"row-1"} className={styles.TileItemRowMobile}>
                        <UI.List.Cell key={"c-1"} colspan={0.25} value={item.checkbox} />
                        <UI.List.Cell key={"c-2"} colspan={1} value={item.name} />
                        <UI.List.Cell key={"c-3"} colspan={1} value={item.isDisplayedOnScreen} />
                    </UI.List.Row>
                ];
                break;
        }

        listRows.push(rows);

        return (
            <UI.List.Item
                key={item.name}
                screenSize={screenSize}
                rows={listRows}
                className={!item.displayItem ? styles.HideRow : ""}
            />
        );
    }, []);

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={0.25}
                                value={t("screens.tile_administration.table_headers.name")}
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={0.25}
                                value={t("screens.tile_administration.table_headers.active")}
                            />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={0.25}
                                value={t("screens.tile_administration.table_headers.isDisplayedOnPage")}
                            />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={0.25}
                                value={t("screens.tile_administration.table_headers.active")}
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1}
                                value={t("screens.tile_administration.table_headers.name")}
                            />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={1}
                                value={t("screens.tile_administration.table_headers.isDisplayedOnPage")}
                            />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} className={styles.TileItemHeaderRowMobile} />;
        },
        [t]
    );

    let tileAdministrationListItems: TileConfigurationListItem[] = [];

    // Adding dummy-checkbox. Otherwise first checkbox will always be checked
    tileAdministrationListItems.push({
        name: "dummy-checkbox",
        checkbox: (
            <UI.Form.Check inline={false} key={0}>
                <UI.Form.Check.Input {...register("tiles")} defaultChecked={false} />
                <UI.Form.Check.Label />
            </UI.Form.Check>
        ),
        isDisplayedOnScreen: "dummy-checkbox",
        displayItem: false
    });

    if (tileTranslationMap) {
        tileTranslationMap.forEach(({ label }, key) => {
            const tileName = label;
            const tileNumber: number = key;

            let isTileActivated = false;
            if (props.activatedTiles && tileNumber) {
                isTileActivated = props.activatedTiles.includes(tileNumber) ? true : false;
            }

            let isTileCalculated = false;
            if (props.calculatedTiles && tileNumber) {
                isTileCalculated = props.calculatedTiles.includes(tileNumber) ? true : false;
            }

            if (isTileCalculated) {
                const checkbox = (
                    <UI.Form.Check inline={false} key={tileNumber}>
                        <UI.Form.Check.Input
                            {...register("tiles")}
                            type="checkbox"
                            id={`tiles-${tileNumber}`}
                            defaultChecked={isTileActivated}
                            disabled={false}
                            value={tileNumber}
                        />
                        <UI.Form.Check.Label />
                    </UI.Form.Check>
                );

                tileAdministrationListItems.push({
                    name: tileName,
                    checkbox: checkbox,
                    isDisplayedOnScreen: tileToScreenMap.get(tileNumber)?.screen ?? "",
                    displayItem: true
                });
            }
        });
    }

    return (
        <UI.Form>
            <div>
                <div className="mb-4">
                    <UI.Button
                        label={isSavingTileConfiguration ? t("labels.is_saving") : t("labels.save")}
                        onClick={handleSubmit(saveNewTileConfiguration)}
                        disabled={!formState.isDirty || isSavingTileConfiguration}
                        className={`${styles.FormButton} d-inline`}
                    />
                    <UI.Button
                        label={t("labels.reset")}
                        disabled={!formState.isDirty || isSavingTileConfiguration}
                        onClick={resetForm}
                        className={`${styles.FormButton} d-inline ml-sm-2 mt-2 mt-sm-0`}
                    />
                </div>
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={tileAdministrationListItems}
                    renderItem={renderItem}
                    renderHeader={renderHeader}
                />
            </div>
        </UI.Form>
    );
};

export const TileAdministrationList = Sentry.withProfiler(observer(TileAdministrationListBase));
