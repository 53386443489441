import React, { useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { GET_PORTAL_ACCOUNTLISTS_BY_REALESTATEID } from "src/api/finances";
import {
    PortalAccountlistsByRealestateId,
    PortalAccountlistsByRealestateIdVariables
} from "src/api/generated/PortalAccountlistsByRealestateId";
import { ScreenType } from "src/api/tiles";
import "src/screens/realestates/realestate/finances/Finances.css";
import * as Screens from "src/screens";
import { Route, selectRoute } from "src/config/routes";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router";
import { getLang } from "src/utils/i18n";
import { Redirect } from "react-router-dom";
import { Accountlist } from "./Accountlist";
import { ScreenWithContactTiles } from "src/components/screenWithTiles/screenWithContactTiles/ScreenWithContactTile";
import { AccountFilesList } from "src/screens/realestates/realestate/finances/AccountFilesList";
import { getRoleKey, Role } from "src/network/User";

interface MatchParams {
    realestateid: string;
    accountlistid?: string | undefined;
}

const FinancesBase = (props: RouteComponentProps<MatchParams>) => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);

    useEffect(() => {
        if (navStore.currentOwnerId) {
            if (navStore.singleRealestate) {
                navStore.setBackbutton(
                    selectRoute(Route.owners, authStore.portalRoleWithHighestPriority ?? authStore.user?.role),
                    t("screens.owners.title").toString()
                );
            } else {
                navStore.setBackbutton(
                    selectRoute(
                        Route.realestatesOwner,
                        authStore.portalRoleWithHighestPriority ?? authStore.user?.role,
                        { ownerid: navStore.currentOwnerId }
                    ),
                    t("screens.realestates.title").toString()
                );
            }
        } else {
            navStore.setBackbutton(
                selectRoute(
                    Route.realestates,
                    authStore.portalRoleWithHighestPriority ?? (authStore.user?.role && !uiStore.isMobile)
                ),
                t("screens.realestates.title").toString()
            );
        }
    });

    const realestateid = props.match.params.realestateid;

    const { loading, error, data } = useQuery<
        PortalAccountlistsByRealestateId,
        PortalAccountlistsByRealestateIdVariables
    >(GET_PORTAL_ACCOUNTLISTS_BY_REALESTATEID, {
        variables: {
            realestateid: realestateid,
            lang: getLang()
        },
        context: {
            headers: {
                "x-hasura-role": authStore.user?.hasCoownerAuditorRole
                    ? getRoleKey(Role.AUDITOR)
                    : getRoleKey(authStore.user?.role)
            }
        }
    });

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }
    if (data.portal_accountlists.length === 0) {
        return <Screens.ErrorEmptyData message={t("error.emptydata")} />;
    }

    let selectedListId = data.portal_accountlists[0].id;

    if (props.match.params.accountlistid) {
        selectedListId = props.match.params.accountlistid;
    } else {
        let newId = data.portal_accountlists[0].id;
        if (uiStore.financeListSelectionId) {
            newId = uiStore.financeListSelectionId;
        }
        return (
            <Redirect
                to={selectRoute(Route.finances, authStore.user?.role, {
                    realestateid: realestateid,
                    accountlistid: newId
                })}
            />
        );
    }

    const financeScreenContent = (
        <>
            <UI.Card flex={true}>
                <Accountlist
                    realestateid={realestateid}
                    selectedlistid={selectedListId}
                    accountlistsByRealestateId={data}
                />
            </UI.Card>
            <AccountFilesList selectedlistid={selectedListId} />
        </>
    );

    return (
        <ScreenWithContactTiles
            screenId={ScreenType.FINANCE}
            realestateid={realestateid}
            title={t("screens.realestate.finances.title")}
        >
            {financeScreenContent}
        </ScreenWithContactTiles>
    );
};

export const Finances = Sentry.withProfiler(observer(FinancesBase));
