import { useCallback, useEffect } from "react";

interface UseSaveFormByShortcutParameters {
    saveFormFunction: any;
    isFormDirty: boolean;
}

export const useSaveFormByShortcut = (useSaveFormByShortcutParameters: UseSaveFormByShortcutParameters) => {
    const { saveFormFunction, isFormDirty } = useSaveFormByShortcutParameters;

    const onKeyDown = useCallback(
        (e: any) => {
            if (e.key === "s" && e.ctrlKey) {
                e.preventDefault();
                if (isFormDirty) {
                    saveFormFunction();
                }
            }
        },
        [saveFormFunction, isFormDirty]
    );

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [onKeyDown]);
};
