import React, { useContext, useCallback } from "react";
import { UI, ScreenSize } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { GetManagerFilesByRealEstateId_realestates_files as file_item } from "src/api/generated/GetManagerFilesByRealEstateId";
import { NetworkConfig } from "src/network/NetworkConfig";
import { format } from "date-fns";
import styles from "./LatestDocuments.module.css";

interface LatestDocumentsTileProps {
    title: string;
    documents: file_item[];
    className?: string;
}

export interface LatestDocumentsTileInformation {
    to?: string;
    label?: string;
    value?: any;
    className?: string;
    spansTwoColumns?: boolean;
    onClick?: any;
}

export const LatestDocumentsTile = (props: LatestDocumentsTileProps) => {
    const { uiStore } = useContext(RootStoreContext);

    const renderItem = useCallback((item: file_item, screenSize: ScreenSize) => {
        const file = item;
        const listRows: any[] = [];

        if (file) {
            const date = format(new Date(file.filedate), "dd.MM.yyy");

            let urlLink = NetworkConfig.datafileUrl + file?.fileid;

            const fileDescriptionWithLink = (
                <a href={urlLink} className="link" rel="noopener noreferrer" target="_blank">
                    {file.name}
                </a>
            );

            let rows: React.ReactElement[] = [];

            rows = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell
                        key={"c-1"}
                        colspan={0.65}
                        value={fileDescriptionWithLink}
                        className={styles.latestdocumentsCell}
                    />
                    <UI.List.Cell key={"c-2"} colspan={0.35} value={date} />
                </UI.List.Row>
            ];

            listRows.push(rows);
        }

        return (
            <UI.List.Item
                key={file?.id}
                screenSize={screenSize}
                rows={listRows}
                className={styles.latestdocumentsItem}
            />
        );
    }, []);

    const renderHeader = useCallback((screenSize: ScreenSize) => {
        const headerRows: React.ReactElement[] = [
            <UI.List.Row key={"row-1"}>
                <UI.List.Cell key={"c-1"} colspan={0.65} value={"Bezeichnung"} />
                <UI.List.Cell key={"c-2"} colspan={0.35} value={"Datum"} />
            </UI.List.Row>
        ];

        return <UI.List.Header rows={headerRows} className={styles.latestdocumentsHeader} />;
    }, []);

    const realestateDetailList = (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={props.documents}
            renderItem={renderItem}
            renderHeader={renderHeader}
        />
    );

    return (
        <div>
            <UI.Card title={props.title} square={true} className={props.className}>
                {realestateDetailList}
            </UI.Card>
        </div>
    );
};
