import React, { useContext, useCallback, useEffect, useState } from "react";
import {
    GetRealestatePersons_realestatepersons as realestateperson,
    GetRealestatePersons_realestatepersonroles as realestatepersonrole
} from "src/api/generated/GetRealestatePersons";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { Route, selectRoute } from "src/config/routes";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize, DropdownItem } from "@wwimmo/ui";
import { useParams } from "react-router";
import * as Screens from "src/screens";

interface RealestatePersonsListProps {
    realestatepersons: realestateperson[];
    realestatepersonroles: realestatepersonrole[];
}

interface Params {
    realestateid: string;
}

enum ROLE {
    ALL = "0"
}

const ROLE_UNDEFINED_KEY = 99;

const RealestatePersonsList = (props: RealestatePersonsListProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore, authStore } = useContext(RootStoreContext);
    const { realestateid } = useParams<Params>();

    const listIdentifier = `realestatepersonlist-${realestateid}`;

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    let currentSearchQuery: string | undefined = undefined;

    if (listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    }

    let currentFilterQuery: string | undefined = undefined;

    if (listParameters && listParameters.filterValue) {
        currentFilterQuery = listParameters.filterValue;
    }

    const [displayedPersons, setDisplayedPersons] = useState<realestateperson[]>([]);
    const [realestatepersonroles, setRealestatepersonroles] = useState<Map<number | null, { label: string }>>();
    const [filterDropdownItems, setFilterDropdownItems] = useState<Array<DropdownItem>>([]);

    useEffect(() => {
        if (props.realestatepersonroles) {
            setRealestatepersonroles(getEnumerationMap(props.realestatepersonroles));
        }
    }, [props.realestatepersonroles]);

    const filterPersonsAndSetSearchQuery = useCallback(
        (searchQuery: string = "", roleId: string = ROLE.ALL) => {
            if (props.realestatepersons) {
                let foundPersons = props.realestatepersons.filter((realestateperson) => {
                    const person = realestateperson.person;

                    if (person) {
                        return (
                            person.name1?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            person.name2?.toLowerCase().includes(searchQuery.toLowerCase())
                        );
                    } else {
                        return false;
                    }
                });

                if (roleId !== ROLE.ALL) {
                    foundPersons = foundPersons.filter((person) => {
                        return person.role === Number(roleId);
                    });
                }
                setDisplayedPersons([...foundPersons]);
            }
        },
        [props.realestatepersons]
    );

    const onRowClick = useCallback(() => {
        navStore.setListMapValue(
            listIdentifier,
            listParameters
                ? { ...listParameters, scrollPosition: window.pageYOffset }
                : { scrollPosition: window.pageYOffset }
        );

        navStore.setBackbutton(
            selectRoute(Route.realestatePersons, authStore.user?.role, {
                realestateid: realestateid
            }),
            t("screens.realestate.dashboard.realestatepersons")
        );
    }, [navStore, t, authStore.user?.role, realestateid, listIdentifier, listParameters]);

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listIdentifier, listParameters]
    );

    const handleSearchInput = useCallback(
        ({ searchQuery, filterItemValue }) => {
            filterPersonsAndSetSearchQuery(searchQuery, filterItemValue);

            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: searchQuery, filterValue: filterItemValue }
                    : { scrollPosition: 0, searchQuery: searchQuery, filterValue: filterItemValue }
            );
        },
        [filterPersonsAndSetSearchQuery, navStore, listIdentifier, listParameters]
    );

    const getEnumerationMap = (enumerations: Array<realestatepersonrole>) => {
        const enumerationMap = new Map();

        enumerations.forEach((enumeration) => {
            if (enumeration.key) {
                enumerationMap.set(enumeration.key, {
                    label: enumeration.label
                });
            }
        });

        return enumerationMap;
    };

    const getFilterItems = (enumerations: Array<realestatepersonrole>) => {
        const filterDropdownItems: DropdownItem[] = [];

        filterDropdownItems.push({
            value: ROLE.ALL,
            label: "Alle"
        });

        enumerations.forEach((enumeration) => {
            if (enumeration.key) {
                filterDropdownItems.push({
                    value: String(enumeration.key),
                    label: enumeration.label ?? ""
                });
            }
        });

        return filterDropdownItems;
    };

    useEffect(() => {
        if (props.realestatepersonroles) {
            setFilterDropdownItems(getFilterItems(props.realestatepersonroles));
        }
    }, [props.realestatepersonroles]);

    const onChangeFilter = useCallback(
        ({ searchQuery, filterItemValue }) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, filterValue: filterItemValue, searchQuery: searchQuery }
                    : { filterValue: filterItemValue, searchQuery: searchQuery }
            );
            filterPersonsAndSetSearchQuery(searchQuery, filterItemValue);
        },
        [filterPersonsAndSetSearchQuery, navStore, listIdentifier, listParameters]
    );

    const renderItem = useCallback(
        (realestateperson: realestateperson, screenSize: ScreenSize) => {
            const person = realestateperson.person;
            const role = realestatepersonroles?.get(realestateperson.role ?? ROLE_UNDEFINED_KEY)?.label;

            let listRows: React.ReactElement[] = [
                <UI.List.Row key={"r-1"}>
                    <UI.List.Cell key={"c-1"} colspan={2.5} value={`${person?.name1 ?? ""} ${person?.name2 ?? ""}`} />
                    <UI.List.Cell key={"c-2"} colspan={1} value={role} />
                </UI.List.Row>
            ];

            let link = undefined;

            if (realestateperson.personid) {
                link = selectRoute(Route.realestatePerson, authStore.user?.role, {
                    realestateid: realestateid,
                    personid: realestateperson.personid
                });
            }

            return (
                <UI.List.Item
                    key={realestateperson.id}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                    onClick={onRowClick}
                />
            );
        },
        [onRowClick, authStore.user?.role, realestateid, realestatepersonroles]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={2.5} value={t("screens.realestate.realestatepersons.name")} />
                    <UI.List.Cell key={"c-2"} colspan={1} value={t("screens.realestate.realestatepersons.role")} />
                </UI.List.Row>
            ];

            return <UI.List.Header rows={headerRows} sticky={true} top={130} />;
        },
        [t]
    );

    if (displayedPersons === [] || filterDropdownItems.length === 0) {
        return <Screens.Loading />;
    }

    const initialFilterItem = filterDropdownItems.find((item) => item.value === currentFilterQuery);

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={displayedPersons}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={25}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
            onSearch={handleSearchInput}
            initialSearchQuery={currentSearchQuery}
            filterItems={filterDropdownItems}
            initialFilterItem={initialFilterItem}
            onChangeFilter={onChangeFilter}
        />
    );
};

export default observer(RealestatePersonsList);
