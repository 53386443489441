import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStoreContext } from "src/stores/RootStore";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import styles from "./DocumentsFilter.module.css";
import { DocumentFilterModalList } from "src/screens/realestates/realestate/documents/modal-list/DocumentFilterModalList";
import { ScreenWidth } from "src/stores/UIStore";

export const DocumentFilterBase = () => {
    const { t } = useTranslation();
    const { documentStore } = useContext(RootStoreContext);

    const [uniqueYears, setUniqueYears] = useState<string[]>([]);

    useEffect(() => {
        if (documentStore.displayedDocuments.length > 0) {
            const yearsArray = documentStore.getYearsFromArray(documentStore.displayedDocuments);
            setUniqueYears(yearsArray);
        }
    }, [documentStore.displayedDocuments, documentStore]); // Run the effect only when displayedDocuments changes

    const onFilterTagClick = useCallback(
        (year: string) => async (e: any) => {
            if (documentStore.selectedYears.includes(year)) {
                documentStore.setSelectedYears(documentStore.selectedYears.filter((item) => item !== year));
            } else {
                documentStore.setSelectedYears([...documentStore.selectedYears, year]);
            }
        },
        [documentStore]
    );

    const onClickDocumentFilter = useCallback(
        async (e: any) => {
            e.preventDefault();
            documentStore.setIsDocumentFilterModalOpen(true);
        },
        [documentStore]
    );

    const onCloseModalClick = useCallback(() => {
        // documentStore.setSelectedYears([]);
        documentStore.setIsDocumentFilterModalOpen(false);
    }, [documentStore]);

    const onApplyClick = useCallback(() => {
        documentStore.setIsDocumentFilterModalOpen(false);
    }, [documentStore]);

    const onSelectedYearsClickMobile = useCallback(() => {
        documentStore.setSelectedYears([]);
    }, [documentStore]);

    const yearsToDisplay = uniqueYears.slice(0, 2).filter((year) => !documentStore.selectedYears.includes(year));

    const maxWidth = `${Math.min(documentStore.selectedYears.length * 90, 200)}px`;

    return (
        <div>
            <UI.Row className={styles.DocumentFilterRow}>
                {window.innerWidth <= ScreenWidth.SM ? (
                    <>
                        {documentStore.selectedYears.length > 0 && (
                            <UI.Col
                                style={{ maxWidth }}
                                className={styles.SelectedYear}
                                onClick={onSelectedYearsClickMobile}
                            >
                                <div>
                                    <span>{documentStore.selectedYears.join(", ")}</span>
                                    <UI.Icon
                                        icon={UI.SVGIcon.Close}
                                        className={`${styles.CloseIcon} ${styles.CloseIconMobile}`}
                                        size={"small"}
                                    />
                                </div>
                            </UI.Col>
                        )}
                        {documentStore.selectedYears.length <= 1 &&
                            yearsToDisplay.map((year: string) => (
                                <UI.Col
                                    className={styles.FilterTags}
                                    key={year}
                                    md={1}
                                    sm={1}
                                    onClick={onFilterTagClick(year)}
                                >
                                    {year}
                                </UI.Col>
                            ))}
                    </>
                ) : (
                    <>
                        {documentStore.selectedYears.map((year: string) => (
                            <UI.Col
                                className={`${styles.SelectedYear} ${styles.SelectedYearDesktop}`}
                                key={year}
                                onClick={onFilterTagClick(year)}
                            >
                                <div>{year}</div>
                                <UI.Icon icon={UI.SVGIcon.Close} className={styles.CloseIcon} size={"small"} />
                            </UI.Col>
                        ))}
                        {yearsToDisplay.map((year: string) => (
                            <UI.Col
                                className={styles.FilterTags}
                                key={year}
                                md={1}
                                sm={1}
                                onClick={onFilterTagClick(year)}
                            >
                                {year}
                            </UI.Col>
                        ))}
                    </>
                )}

                <UI.Col className={styles.FilterYearCol} onClick={onClickDocumentFilter}>
                    <div className={styles.FilterTagsYears}>
                        <span>{t("labels.year")}</span>
                        <UI.Icon icon={UI.SVGIcon.ArrowDown} className={styles.IconArrowDown} />
                    </div>
                </UI.Col>
            </UI.Row>
            <UI.Modal
                className={styles.DocumentFilterModal}
                show={documentStore.isDocumentFilterModalOpen}
                size="lg"
                title={`${t("labels.filter")}: ${t("labels.year")} `}
            >
                <DocumentFilterModalList years={uniqueYears} />
                <div className={styles.ButtonContainer}>
                    <UI.Button
                        label={t("labels.cancel")}
                        onClick={onCloseModalClick}
                        variant="outline-primary"
                        className={styles.ButtonCancel}
                    />
                    <UI.Button
                        label={t("labels.apply")}
                        onClick={onApplyClick}
                        variant="outline-primary"
                        className={styles.ButtonApply}
                    />
                </div>
            </UI.Modal>
        </div>
    );
};

export const DocumentFilter = Sentry.withProfiler(observer(DocumentFilterBase));
