import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { Route, getParams, selectRoute } from "src/config/routes";
import { Error } from "src/screens/error/Error";
import { LOADING_TYPE } from "src/stores/krediflow/InvoiceEnums";
import { InvoiceForm } from "./form/InvoiceForm";
import styles from "./InvoiceDetails.module.css";
import { SCREEN_SIZE, ScreenWidth } from "src/stores/UIStore";
import { SplitView } from "./splitView/SplitView";
import { InvoiceWorkflowCard } from "./cards/InvoiceWorkflowCard";

const InvoiceDetailsBase = () => {
    const { t } = useTranslation();
    const { navStore, authStore, invoiceStore, uiStore } = useContext(RootStoreContext);

    const role = authStore.user?.role;
    let params = getParams(Route.invoiceDetails, role);
    const invoiceNumber = params["invoicenumber"];

    useEffect(() => {
        navStore.setTitle("");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);

        navStore.setBackbutton(
            selectRoute(Route.creditors, authStore.user?.role),
            t("screens.kredi_flow.sidebar_title").toString()
        );
    }, [navStore, authStore, t]);

    // Initialize Data for Ticket
    useEffect(() => {
        async function initializeInvoiceData() {
            if (invoiceStore.isinvoiceId(invoiceNumber)) {
                await invoiceStore.reRoutePathToInvoiceNumber(invoiceNumber);
            } else {
                await invoiceStore.init(invoiceNumber);
            }
        }
        initializeInvoiceData();
    }, [invoiceStore, invoiceNumber]);

    if (invoiceStore.error && invoiceStore.error.loadingInvoice) {
        return <Error message={invoiceStore.error.loadingInvoice} />;
    }

    if (invoiceStore.error && invoiceStore.error.loadingInvoice) {
        return <Error message={invoiceStore.error.loadingInvoice} />;
    }

    const desktopViewElements = <SplitView />;

    const mobileViewElements = (
        <>
            <InvoiceForm />
            <div>{/* Preview */}</div>
        </>
    );

    return (
        <UI.Container className={`pt-3 pt-md-5 ${styles.InvoiceDetailsContainer}`} fluid>
            <div className="px-2 px-md-0">
                <UI.Title>
                    {invoiceStore.currentInvoice?.isNewInvoice
                        ? t("screens.kredi_flow.new_invoice")
                        : t("screens.kredi_flow.invoice")}
                </UI.Title>
            </div>
            <UI.Row>
                <UI.Col sm={12} lg={9}>
                    {invoiceStore.loadingType === LOADING_TYPE.LOADING_INVOICE_DETAILS ? (
                        <UI.RotatingSpinner noLogo size={50} className={styles.LoadingTicketSpinner} />
                    ) : uiStore.currentScreenSize > SCREEN_SIZE.MOBILE_L ? (
                        desktopViewElements
                    ) : (
                        mobileViewElements
                    )}
                </UI.Col>

                <UI.Col sm={12} lg={3}>
                    {invoiceStore.currentInvoice?.isNewInvoice ? undefined : (
                        <>
                            {window.innerWidth <= ScreenWidth.MD && <UI.Spacer />}
                            <InvoiceWorkflowCard />
                        </>
                    )}
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};

export const InvoiceDetails = Sentry.withProfiler(observer(InvoiceDetailsBase));
