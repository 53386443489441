import { UI } from "@wwimmo/ui";
import { useState, useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./ConfirmationModal.module.css";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";

enum MODAL_STATE {
    INIT,
    PROCESSING,
    FINISHED
}

interface ConfirmationModalProps {
    modalTitle: string;
    isModalDisplayed: boolean;
    setIsModalDisplayed: (isModalDisplayed: boolean) => void;
    modalSize?: "sm" | "lg" | "xl";
    confirmationQuestion: string;
    inProgressText?: string;
    onConfirmation: () => Promise<boolean>;
    onNegation?: () => Promise<boolean>;
    closeAfterConfirmation?: boolean;
    closeAfterNegation?: boolean;
    modalClasses?: string;
    isModalConfirmationButtonHidden?: boolean;
    isModalNegationButtonHidden?: boolean;
    modalConfirmationButtonLabel?: string;
    modalNegationButtonLabel?: string;
    isDangerousOperation?: boolean;
    processFinishedMessage?: string;
    processErrordMessage?: string;
}

const ConfirmationModalBase = (props: ConfirmationModalProps) => {
    const { uiStore } = useContext(RootStoreContext);

    const [modalState, setModalState] = useState<MODAL_STATE>(MODAL_STATE.INIT);

    const { t } = useTranslation();

    const {
        onConfirmation,
        onNegation,
        isDangerousOperation,
        closeAfterConfirmation,
        closeAfterNegation,
        modalTitle,
        modalClasses,
        confirmationQuestion,
        inProgressText,
        isModalConfirmationButtonHidden,
        modalConfirmationButtonLabel,
        isModalNegationButtonHidden,
        modalNegationButtonLabel,
        modalSize,
        isModalDisplayed,
        setIsModalDisplayed,
        processFinishedMessage,
        processErrordMessage
    } = props;

    const closeModal = useCallback(() => {
        setIsModalDisplayed(false);
    }, [setIsModalDisplayed]);

    const performConfirmationAction = useCallback(
        async (e: any) => {
            e.preventDefault();

            setModalState(MODAL_STATE.PROCESSING);

            const wasConfirmationActionSuccessful = await onConfirmation();

            if (wasConfirmationActionSuccessful) {
                setModalState(MODAL_STATE.FINISHED);

                if (closeAfterConfirmation) {
                    setIsModalDisplayed(false);
                }
            } else {
                uiStore.printTicketingErrorMessage(processErrordMessage ?? t("confirmation_modal.process_failed"));
                setModalState(MODAL_STATE.INIT);
            }
        },
        [closeAfterConfirmation, onConfirmation, setIsModalDisplayed, processErrordMessage, t, uiStore]
    );

    const performNegationAction = useCallback(
        async (e: any) => {
            e.preventDefault();
            setModalState(MODAL_STATE.PROCESSING);

            let wasNegationActionSuccessful = true;

            if (onNegation) {
                wasNegationActionSuccessful = await onNegation();
            }

            if (wasNegationActionSuccessful) {
                setModalState(MODAL_STATE.FINISHED);

                if (closeAfterNegation) {
                    setIsModalDisplayed(false);
                }
            } else {
                uiStore.printTicketingErrorMessage(processErrordMessage ?? t("confirmation_modal.process_failed"));
                setModalState(MODAL_STATE.INIT);
            }
        },
        [closeAfterNegation, onNegation, setIsModalDisplayed, processErrordMessage, t, uiStore]
    );

    const onCloseModal = useCallback(() => {
        setIsModalDisplayed(false);
    }, [setIsModalDisplayed]);

    return (
        <UI.Modal
            size={modalSize}
            backdrop={"static"}
            title={modalTitle}
            show={isModalDisplayed}
            onClose={onCloseModal}
            className={modalClasses ?? ""}
        >
            <div>
                {modalState === MODAL_STATE.PROCESSING ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {inProgressText ? <span>{inProgressText}</span> : undefined}
                        <UI.RotatingSpinner noLogo={true} size={35} className={"align-items-start"} />
                    </div>
                ) : undefined}

                {modalState === MODAL_STATE.INIT ? (
                    <>
                        <p>{confirmationQuestion}</p>
                        <div className="d-flex">
                            {isModalConfirmationButtonHidden ? undefined : (
                                <UI.Button
                                    label={modalConfirmationButtonLabel ?? t("labels.yes")}
                                    onClick={performConfirmationAction}
                                    className={`${styles.modalButton} ticket-button mb-2 mr-2 mb-sm-0`}
                                    variant={isDangerousOperation ? "danger" : undefined}
                                />
                            )}
                            {isModalNegationButtonHidden ? undefined : (
                                <UI.Button
                                    label={modalNegationButtonLabel ?? t("labels.no")}
                                    onClick={onNegation ? performNegationAction : closeModal}
                                    className={`${styles.modalButton} ticket-button`}
                                    variant={isDangerousOperation ? "danger" : undefined}
                                />
                            )}
                        </div>
                    </>
                ) : undefined}

                {modalState === MODAL_STATE.FINISHED ? (
                    <div className="d-flex flex-column">
                        <p>{processFinishedMessage ?? t("confirmation_modal.process_finished_success")}</p>
                        <UI.Button
                            label={t("labels.close")}
                            onClick={closeModal}
                            className={`${styles.modalButton} ticket-button`}
                        />
                    </div>
                ) : undefined}
            </div>
        </UI.Modal>
    );
};

ConfirmationModalBase.defaultProps = {
    closeAfterConfirmation: true
};

export const ConfirmationModal = Sentry.withProfiler(observer(ConfirmationModalBase));
