import React, { useContext } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { UI } from "@wwimmo/ui";
import { observer } from "mobx-react-lite";
import classes from "./Backnavigation.module.css";
import { RootStoreContext } from "src/stores/RootStore";
import { Route, selectRoute } from "src/config/routes";
import { ColorStyle } from "src/utils/Colors";
import { ScreenWidth } from "src/stores/UIStore";

export const BacknavigationBase = () => {
    const { navStore, authStore } = useContext(RootStoreContext);

    if (!navStore.backButton && window.innerWidth <= ScreenWidth.SM) {
        return (
            <span>
                <span className={classes.BacknavigationLabelMobile}>{navStore.appheaderTitle}</span>
            </span>
        );
    } else if (!navStore.backButton) return <></>;
    else if (
        navStore.backButton.to === selectRoute(Route.realestates, authStore.user?.role) &&
        navStore.singleRealestate
    ) {
        return <></>;
    } else {
        return (
            <LinkContainer className={`${classes.Backnavigation} cursor-pointer`} to={navStore.backButton.to}>
                <span>
                    <UI.Icon icon={UI.SVGIcon.ChevronLeft} color={ColorStyle("white")} />
                    <span className={classes.BacknavigationLabel}>{navStore.backButton.label}</span>
                    <span className={classes.BacknavigationLabelMobile}>{navStore.appheaderTitle}</span>
                </span>
            </LinkContainer>
        );
    }
};

export const Backnavigation = observer(BacknavigationBase);
