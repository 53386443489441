import React, { useEffect, useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { RouteComponentProps, withRouter } from "react-router";
import { useParams } from "react-router-dom";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { UI, ScreenSize } from "@wwimmo/ui";
import { GET_PERSON_BY_ID_MANAGER, GET_PERSON_BY_ID_OWNER } from "src/api/person";
import { CommonPersonManager, CommonPersonManagerVariables } from "src/api/generated/CommonPersonManager";
import { dateFormat } from "src/utils/Date";
import * as Screens from "src/screens";
import { runInAction } from "mobx";
import { ScreenWithTiles } from "src/components/screenWithTiles/ScreenWithTiles";
import { observer } from "mobx-react-lite";
import { ScreenType } from "src/api/tiles";
import { Role } from "src/network/User";
import { getName } from "src/utils/PersonName";
import { Route, selectRoute } from "src/config/routes";

import styles from "./Person.module.css";

interface MatchParams {
    realestateid: string;
    unitid: string;
    personid: string;
    servicecompanyid: string;
    applianceid: string;
    craftsmanid: string;
    maintenanceid: string;
    ownerid: string;
}

interface PersonItem {
    label?: string;
    value?: any;
    to?: string;
    className?: string;
}

const PersonBase = (props: RouteComponentProps<MatchParams>) => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);
    const { realestateid, unitid, personid, servicecompanyid, applianceid, craftsmanid, maintenanceid, ownerid } =
        useParams<MatchParams>();
    const personIds = [personid, servicecompanyid, craftsmanid, ownerid];
    const personQueryId = personIds.find((personid) => personid !== undefined);

    const currentDate = `${dateFormat(new Date(), "yyyy-MM-dd")}T00:00:00.000Z`;

    const isManager = authStore.user?.role === Role.MANAGER;

    const { loading, error, data } = useQuery<CommonPersonManager, CommonPersonManagerVariables>(
        isManager ? GET_PERSON_BY_ID_MANAGER : GET_PERSON_BY_ID_OWNER,
        {
            variables: { personid: personQueryId, today: currentDate }
        }
    );

    useEffect(() => {
        runInAction(() => {
            navStore.resetActionButtons();

            if (realestateid && unitid && applianceid && maintenanceid) {
                navStore.setBackbutton(
                    selectRoute(Route.unitApplianceMaintenance, authStore.user?.role, {
                        realestateid: realestateid,
                        unitid: unitid,
                        applianceid: applianceid,
                        maintenanceid: maintenanceid
                    }),
                    t("screens.realestate.maintenance.title")
                );
            } else if (realestateid && unitid && maintenanceid) {
                navStore.setBackbutton(
                    selectRoute(Route.unitMaintenance, authStore.user?.role, {
                        realestateid: realestateid,
                        unitid: unitid,
                        maintenanceid: maintenanceid
                    }),
                    t("screens.realestate.maintenance.title")
                );
            } else if (maintenanceid && applianceid && craftsmanid) {
                navStore.setBackbutton(
                    selectRoute(Route.realestateApplianceMaintenance, authStore.user?.role, {
                        maintenanceid: maintenanceid,
                        applianceid: applianceid,
                        realestateid: realestateid
                    }),
                    t("screens.realestate.maintenance.title")
                );
            } else if (unitid && applianceid) {
                navStore.setBackbutton(
                    selectRoute(Route.unitAppliance, authStore.user?.role, {
                        applianceid: applianceid,
                        unitid: unitid,
                        realestateid: realestateid
                    }),
                    t("screens.realestate.appliance.title")
                );
            } else if (craftsmanid) {
                navStore.setBackbutton(
                    selectRoute(Route.realestateMaintenance, authStore.user?.role, {
                        maintenanceid: maintenanceid,
                        realestateid: realestateid
                    }),
                    t("screens.realestate.maintenance.title")
                );
            } else if (applianceid) {
                navStore.setBackbutton(
                    selectRoute(Route.realestateAppliance, authStore.user?.role, {
                        applianceid: applianceid,
                        realestateid: realestateid
                    }),
                    t("screens.realestate.appliance.title")
                );
            } else if (unitid) {
                navStore.setBackbutton(
                    selectRoute(Route.unit, authStore.user?.role, {
                        realestateid: realestateid,
                        unitid: unitid
                    }),
                    t("screens.unit.title")
                );
            } else if (ownerid) {
                navStore.setBackbutton(
                    selectRoute(Route.dashboard, authStore.user?.role, {
                        realestateid: realestateid
                    }),
                    t("screens.realestate.dashboard.title")
                );
            } else {
                navStore.setBackbutton(
                    selectRoute(Route.realestatePersons, authStore.user?.role, {
                        realestateid: realestateid
                    }),
                    t("screens.realestate.dashboard.realestatepersons")
                );
            }

            if (data) {
                const person = data.persons[0];
                const name = `${person.name1 ?? ""} ${person.name2 ?? ""}`;
                navStore.setTitle(name.trim().length === 0 ? "-" : name);
            }
        });
    }, [t, authStore.user, navStore, realestateid, unitid, data, applianceid, craftsmanid, maintenanceid, ownerid]);

    const renderItem = useCallback((item: PersonItem, screenSize: ScreenSize) => {
        const listRows: any[] = [];

        if (item.value) {
            let rows: React.ReactElement[] = [];

            rows = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={1} value={item.label} />
                    <UI.List.Cell
                        key={"c-2"}
                        colspan={screenSize === ScreenSize.DESKTOP ? 2 : 1}
                        value={item.value}
                        className={item.className ?? ""}
                    />
                </UI.List.Row>
            ];

            listRows.push(rows);

            return <UI.List.Item key={item.label} screenSize={screenSize} rows={listRows} />;
        }
    }, []);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data || data.persons.length === 0) {
        return <Screens.ErrorEmptyData message={t("error.nodata")} />;
    }

    const l = (key: string) => {
        return t("screens.realestate.person." + key);
    };

    const getDate = (input: string) => {
        if (input) {
            return dateFormat(new Date(input), "dd.MM.yyyy");
        }
    };

    const person = data.persons[0];

    const name = getName(person.name1, person.name2, person.legal);

    const personDetails: Array<PersonItem> = [
        { label: l("name"), value: name, className: styles.DisplayAllText },
        { label: l("salutation"), value: person.salutation },
        {
            label: l("email_address"),
            value: person.email ? <a href={`mailto:${person.email}`}>{person.email}</a> : undefined
        },
        {
            label: l("mobile"),
            value: person.mobile ? <a href={`tel:${person.mobile}`}>{person.mobile}</a> : undefined
        },
        {
            label: l("phone_privat"),
            value: person.phoneprivate ? <a href={`tel:${person.phoneprivate}`}>{person.phoneprivate}</a> : undefined
        },
        {
            label: l("phone_business"),
            value: person.phonebusiness ? <a href={`tel:${person.phonebusiness}`}>{person.phonebusiness}</a> : undefined
        },
        {
            label: l("remarks"),
            value: person.remarks ?? undefined,
            className: styles.DisplayAllText
        }
    ];

    const address = person.current_address[0];

    let place = undefined;
    let validfrom = undefined;
    let street = undefined;

    if (address) {
        place = `${address.zip ?? ""} ${address.city ?? ""}${address.city || address.country ? ", " : " "}${
            address.country ?? ""
        }`;

        validfrom = getDate(address.validfrom);
        street = address.street;
    }

    const addressInformation: Array<PersonItem> = [
        { label: l("address.validfrom"), value: validfrom },
        { label: l("address.street"), value: street },
        { label: l("address.place"), value: place }
    ];

    const personDetailList = (
        <UI.Card title={l("title")} flex>
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={personDetails}
                renderItem={renderItem}
            />
        </UI.Card>
    );

    const addressDetailList = address ? (
        <UI.Card title={l("address.title")} flex>
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={addressInformation}
                renderItem={renderItem}
            />
        </UI.Card>
    ) : undefined;

    const titleName = `${person.name1 ?? ""} ${person.name2 ?? ""}`;

    return (
        <ScreenWithTiles title={titleName} screenId={ScreenType.PERSON} realestateid={realestateid}>
            {personDetailList}
            {addressDetailList}
        </ScreenWithTiles>
    );
};
export const Person = Sentry.withProfiler(withRouter(observer(PersonBase)));
