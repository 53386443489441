import { observer } from "mobx-react";
import React, { useContext } from "react";
import { RootStoreContext } from "src/stores/RootStore";
import "./ActionButtons.css";

interface ActionButtonsProps {
    isMobile: boolean;
    iconSize: number;
}

export const ActionButtons = observer((props: ActionButtonsProps) => {
    const iconSize = props.iconSize;
    const { navStore } = useContext(RootStoreContext);
    const { actionButtonLeft, actionButtonMiddle, actionButtonRight } = navStore;
    return (
        <React.Fragment>
            <div className="IconContainer" style={props.isMobile ? { right: iconSize * 2 } : { left: -iconSize }}>
                {actionButtonLeft}
            </div>
            <div className="IconContainer" style={props.isMobile ? { right: iconSize } : { right: -iconSize }}>
                {actionButtonMiddle}
            </div>
            <div className="IconContainer" style={props.isMobile ? { right: 0 } : { right: -iconSize * 2 }}>
                {actionButtonRight}
            </div>
        </React.Fragment>
    );
});
