import { UI } from "@wwimmo/ui";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColorStyle } from "src/utils/Colors";
import { observer } from "mobx-react";
import * as Sentry from "@sentry/react";
import { NetworkConfig } from "src/network/NetworkConfig";
import styles from "src/components/imageSelector/ImageSelector.module.css";
import { ITicketImage } from "src/stores/TicketStore";

interface ImageSelectorItemModalProps {
    image?: ITicketImage;
    isDisplayed: boolean;
    toggleDisplayImageModal: any;
}

const ImageSelectorItemModalBase = (props: ImageSelectorItemModalProps) => {
    const { t } = useTranslation();

    const { image, isDisplayed, toggleDisplayImageModal } = props;

    const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
    const [hadErrorLoadingImage, setHadErrorLoadingImage] = useState<boolean>(false);

    const onImageLoad = useCallback(() => {
        setIsImageLoading(false);
    }, []);

    const onImageError = useCallback(() => {
        setHadErrorLoadingImage(true);
        setIsImageLoading(false);
    }, []);

    const imageSource = image?.base64String
        ? `data:${image?.mimetype};base64,${image?.base64String}`
        : `${NetworkConfig.openThumbnailUrl}${image?.fileId}`;

    return (
        <UI.Modal
            size="lg"
            show={isDisplayed}
            onClose={toggleDisplayImageModal}
            centered={true}
            className={styles.ImageModal}
        >
            <img src={imageSource} onLoad={onImageLoad} onError={onImageError} alt="" />

            {isImageLoading && image?.fileId ? <UI.RotatingSpinner noLogo={true} size={25} /> : undefined}

            {hadErrorLoadingImage ? (
                <div className={styles.ImageLoadingErrorContainer}>
                    <UI.Icon icon={UI.SVGIcon.CloseCircle} color={ColorStyle("danger")} size={40} className="mb-1" />
                    <span>{t("screens.tickets.form.notification.image_loading_error")}</span>
                </div>
            ) : undefined}
        </UI.Modal>
    );
};

export const ImageSelectorItemModal = Sentry.withProfiler(observer(ImageSelectorItemModalBase));
