import { gql } from "@apollo/client";

export enum CUSTOMER_FEATURE {
    INSPECTION = 20,
    MANAGER_PORTAL = 30,
    OWNER_PORTAL = 31,
    CONDOMINIUMOWNER_PORTAL = 32,
    TENANT_PORTAL = 33,
    EASYCONTACT = 50,
    SERVICE7000 = 51,
    YAROWA = 52,
    TICKETING = 55,
    KREDI_FLOW = 60,
    RENTAL_EMONITOR = 70
}

export enum CUSTOMER_OPTION {
    TASKS = 100,
    MANAGEMENTS = 101,
    APPLIANCES = 102,
    MAINTENANCES = 103,
    TENANTNAME = 104,
    REALESTATEPERSONS = 105,
    CONSOLIDATIONS = 106,
    TENANT_BOOKINGS_AND_FILES = 107,
    MULTILINGUAL = 108,
    UDPATE_PERSONS_VIA_ASB = 109
}

export enum USER_ROLE {}

const CUSTOMER_FRAGMENTS = gql`
    fragment customer_basic_details on ums_customers {
        id
        name
        erp
        email
        partner
        apiversion
        licencedunits
        users(where: { id: { _eq: $userid } }) {
            name1
            name2
            isSidebarCollapsed: usersettings(where: { key: { _eq: "PORTAL.IS_SIDEBAR_COLLAPSED" } }) {
                value
            }
            invoicePaneWidth: usersettings(where: { key: { _eq: "PORTAL.INVOICE_PANE_WIDTH_RATIO" } }) {
                value
            }
        }
        options(where: { option: { _in: [102, 103, 105, 107, 108, 109] } }) {
            option
            value
        }
        tileconfigurations: customersettings(where: { key: { _eq: "PORTAL.TILECONFIGURATION" } }) {
            value
        }
        cred_numberofvisa: customersettings(where: { key: { _eq: "CRED.NUMBEROFVISA" } }) {
            value
        }
    }
`;

export const GET_UMS_CUSTOMER = gql`
    ${CUSTOMER_FRAGMENTS}
    query GetUmsCustomer($userid: uuid!) {
        ums_customers {
            ...customer_basic_details
            customerfeatures {
                feature
            }
        }
        demomode: ums_customersettings(where: { key: { _eq: "TICKET.DEMOMODE" } }) {
            id
            value
        }
    }
`;

export const GET_UMS_CUSTOMER_BASIC = gql`
    ${CUSTOMER_FRAGMENTS}
    query GetUmsCustomerBasic($userid: uuid!) {
        ums_customers {
            ...customer_basic_details
        }
    }
`;

const CUSTOMER_USERS_FRAGMENT = gql`
    fragment customer_user_details on ums_users {
        customerid
        userid: id
        email
        name1
        name2
        registered
        registereddate
        activated
        emailverified
        registrationcode
        userinserted: inserted
        userroles(distinct_on: role, order_by: { role: asc }) {
            role
        }
        login_audit: audits(order_by: { timestamp: desc }, where: { event: { _in: [10, 30] } }, limit: 1) {
            timestamp
        }
        registered_audit: audits(order_by: { timestamp: desc }, where: { event: { _eq: 60 } }, limit: 1) {
            timestamp
        }
        activated_audit: audits(order_by: { timestamp: desc }, where: { event: { _eq: 70 } }, limit: 1) {
            timestamp
        }
        blocked_audit: audits(order_by: { timestamp: desc }, where: { event: { _eq: 90 } }, limit: 1) {
            timestamp
        }
    }
`;

export const GET_CUSTOMER_USERS = gql`
    ${CUSTOMER_USERS_FRAGMENT}
    query GetCustomerUsers($language: String = "de") {
        customer_users: ums_users(order_by: { name1: asc }, where: { system: { _eq: false } }) {
            ...customer_user_details
        }
        userrolesdefinition: common_enumerations(
            where: { language: { _eq: $language }, type: { _eq: 91 } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;

export const GET_CUSTOMER_USER = gql`
    ${CUSTOMER_USERS_FRAGMENT}
    query GetCustomerUser($language: String = "de", $userid: uuid!) {
        customer_users: ums_users(order_by: { name1: asc }, where: { system: { _eq: false }, id: { _eq: $userid } }) {
            ...customer_user_details
        }
        userrolesdefinition: common_enumerations(
            where: { language: { _eq: $language }, type: { _eq: 91 } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;

// export const GET_CUSTOMER_USERS = gql`
//     query GetCustomerUsers($language: String = "de") {
//         customer_users: ums_v_customer_users(order_by: { name: asc }, where: { system: { _eq: false } }) {
//             customerid
//             userid
//             email
//             name
//             customername
//             registered
//             activated
//             system
//             blocked
//             userinserted
//             login_audit: audits(order_by: { timestamp: desc }, where: { event: { _in: [10, 30] } }, limit: 1) {
//                 timestamp
//             }
//             registered_audit: audits(order_by: { timestamp: desc }, where: { event: { _eq: 60 } }, limit: 1) {
//                 timestamp
//             }
//             activated_audit: audits(order_by: { timestamp: desc }, where: { event: { _eq: 70 } }, limit: 1) {
//                 timestamp
//             }
//             blocked_audit: audits(order_by: { timestamp: desc }, where: { event: { _eq: 90 } }, limit: 1) {
//                 timestamp
//             }
//             roles(distinct_on: role, order_by: { role: asc }) {
//                 role
//             }
//         }
//         userrolesdefinition: common_enumerations(
//             where: { language: { _eq: $language }, type: { _eq: 91 } }
//             order_by: { key: asc }
//         ) {
//             id
//             key
//             label
//         }
//     }
// `;

export const GET_CUSTOMER_INVOICED_USERS = gql`
    query GetCustomerInvoicedUsers($customerinvoiceid: uuid!, $language: String = "de") {
        customer_users: ums_v_customer_invoiced_users(
            order_by: { name: asc }
            where: { customerinvoiceid: { _eq: $customerinvoiceid } }
        ) {
            customerid
            userid
            email
            name
            customername
            roles
            date
        }
        userrolesdefinition: common_enumerations(
            where: { language: { _eq: $language }, type: { _eq: 91 } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;

export const GET_CUSTOMER_INVOICED_RENTALS_VARIABLE = gql`
    query GetCustomerInvoicedRentalsVariable($year: numeric!, $month: numeric!) {
        rentals_variable: rent_v_rentals_variable_details(
            order_by: { rentaldate: desc }
            where: { year: { _eq: $year }, month: { _eq: $month } }
        ) {
            realestatename
            realestatenumber
            unitname
            unitnumber
            referencenumber
            tenantname
            rentaldate
            date
        }
    }
`;

export const UPSERT_CUSTOMERSETTING = gql`
    mutation UpsertCustomersetting($objects: [ums_customersettings_insert_input!]!) {
        updatecustomersetting: insert_ums_customersettings(
            objects: $objects
            on_conflict: { constraint: customer_customersetting, update_columns: value }
        ) {
            affected_rows
        }
    }
`;

export const UPSERT_USERSETTING = gql`
    mutation UpsertUsersetting($objects: [ums_usersettings_insert_input!]!) {
        updateusersetting: insert_ums_usersettings(
            objects: $objects
            on_conflict: { constraint: usersettings_customerid_userid_key, update_columns: value }
        ) {
            affected_rows
        }
    }
`;

export const GET_EASYCONTACT_INFORMATION = gql`
    query GetEasyContactInformation {
        inboxemailaddress: ums_customersettings(where: { key: { _eq: "EC.EMAILINBOX" } }) {
            id
            value
        }
        confirmationemailtext_de: ums_customersettings(where: { key: { _eq: "EC.CONFIRMATIONMAIL_DE" } }) {
            id
            value
        }
        confirmationemailtext_en: ums_customersettings(where: { key: { _eq: "EC.CONFIRMATIONMAIL_EN" } }) {
            id
            value
        }
        confirmationemailtext_fr: ums_customersettings(where: { key: { _eq: "EC.CONFIRMATIONMAIL_FR" } }) {
            id
            value
        }
        confirmationemailtext_it: ums_customersettings(where: { key: { _eq: "EC.CONFIRMATIONMAIL_IT" } }) {
            id
            value
        }
        sendemailecandticketing: ums_customersettings(where: { key: { _eq: "EC.SEND_EMAIL_IN_EC_AND_TICKETING" } }) {
            id
            value
        }
    }
`;

export const GET_TICKETING_SETTINGS = gql`
    query GetTicketingSettings {
        demomode: ums_customersettings(where: { key: { _eq: "TICKET.DEMOMODE" } }) {
            id
            value
        }
        sendemailecandticketing: ums_customersettings(where: { key: { _eq: "EC.SEND_EMAIL_IN_EC_AND_TICKETING" } }) {
            id
            value
        }
        incidentnotifyreportbody: ums_customersettings(where: { key: { _eq: "TICKET.INCIDENTNOTIFYREPORTERBODY" } }) {
            id
            value
        }
        incidentsendconfirmationemailbody: ums_customersettings(
            where: { key: { _eq: "TICKET.INCIDENTSENDCONFIRMATIONEMAILBODY" } }
        ) {
            id
            value
        }
        ordernotifyreporterbody: ums_customersettings(where: { key: { _eq: "TICKET.ORDERNOTIFYREPORTERBODY" } }) {
            id
            value
        }
        billingaddress: ums_customersettings(where: { key: { _eq: "TICKET.BILLINGADDRESS" } }) {
            id
            value
        }
        billingemail: ums_customersettings(where: { key: { _eq: "TICKET.BILLINGEMAIL" } }) {
            id
            value
        }
    }
`;

export const GET_OLD_ASSEMBLY_WITH_GENERAL_DOCUMENTS = gql`
    query GetOldAssemblyWithGeneralDocuments {
        oldAssemblyWithGeneralDocuments: ums_customersettings(
            where: { key: { _eq: "ASSEMBLY.OLDASSEMBLYWITHGENERALDOCUMENTS" } }
        ) {
            id
            value
        }
    }
`;
