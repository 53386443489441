import React, { useContext } from "react";
import "src/components/contactsales/ContactSales.css";
import { ContactItemSales, ContactItemRow } from "src/components/contactsales/contactitemsales/ContactItemSales";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { CustomerType } from "src/components/customer/Customer";
import contacts from "./ContactSales.json";

export enum ContactItemType {
    PHONE,
    MAIL
}

export const ContactSales = () => {
    const { t } = useTranslation();
    const { navStore } = useContext(RootStoreContext);

    const contactSales = contacts.contactSales;

    const contentWW: Array<ContactItemRow> = [
        { type: ContactItemType.PHONE, content: contactSales[0]?.tel || null },
        { type: ContactItemType.MAIL, content: contactSales[0]?.email || null }
    ];
    const contentExtenso: Array<{ type: ContactItemType; content: string | null }> = [
        { type: ContactItemType.PHONE, content: contactSales[1]?.tel || null },
        { type: ContactItemType.MAIL, content: contactSales[1]?.email || null }
    ];

    return (
        <UI.Col sm={6} lg={4} xl={3}>
            <UI.Card square title={t("screens.contact.title")}>
                <div className="ContactSales">
                    {navStore.customer.partner === CustomerType.WW ? (
                        <>
                            <div className="ContactName">{contactSales[0].company}</div>
                            <div className="ContactTitle">{contactSales[0].department}</div>
                            <ContactItemSales name={contactSales[0].company} contacts={contentWW} />
                        </>
                    ) : navStore.customer.partner === CustomerType.EXTENSO ? (
                        <>
                            <div className="ContactName">{contactSales[1].company}</div>
                            <div className="ContactTitle">{contactSales[1].department}</div>
                            <ContactItemSales name={contactSales[0].company} contacts={contentExtenso} />
                        </>
                    ) : undefined}
                </div>
            </UI.Card>
        </UI.Col>
    );
};
