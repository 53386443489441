import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_USERS } from "src/api/customer";
import { GetCustomerUsers } from "src/api/generated/GetCustomerUsers";
import CustomerUserList from "src/screens/customerusers/list/CustomerUserList";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { withRouter, RouteComponentProps } from "react-router";
import { selectRoute, Route } from "src/config/routes";
import classes from "./CustomerUsers.module.css";
import { getLang } from "src/utils/i18n";

const CustomerUsersBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);

    const variables = {
        planguage: getLang()
    };

    const { data, loading, error } = useQuery<GetCustomerUsers>(GET_CUSTOMER_USERS, {
        variables: variables
    });

    useEffect(() => {
        navStore.setTitle(t("screens.customer_users.title"));
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);

        if (uiStore.isSidebarActive) {
            navStore.setBackbutton(null);
        } else {
            navStore.setBackbutton(selectRoute(Route.profile), t("screens.account.title").toString());
        }
    }, [navStore, t, authStore.user?.role, uiStore.isSidebarActive]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col md={12}>
                        <UI.Card>
                            <div className={classes.customerList}>
                                <CustomerUserList
                                    users={data.customer_users}
                                    userrolesDefinition={data.userrolesdefinition}
                                    displayUserStatusRows={true}
                                />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const CustomerUsers = withRouter(Sentry.withProfiler(observer(CustomerUsersBase)));
