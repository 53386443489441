import React, { useContext, useCallback, useState } from "react";
import * as Sentry from "@sentry/react";
import { UI, ScreenSize } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import "src/screens/realestates/realestate/finances/Finances.css";
import { GetBookingsByAccountId_portal_accounts_bookings_bookingfiles as bookingfiles } from "src/api/generated/GetBookingsByAccountId";
import { portal_accounts_part_bookings } from "src/api/generated/portal_accounts_part";
import { dateFormat, getDate } from "src/utils/Date";
import { NetworkConfig } from "src/network/NetworkConfig";
import { ColorStyle } from "src/utils/Colors";
import { observer } from "mobx-react-lite";
import styles from "./AccountList.module.css";
import { getCurrencyElement } from "src/utils/Currency";

interface AccountListProperties {
    bookings: portal_accounts_part_bookings[];
}

interface AccountItem {
    key?: any;
    date?: string;
    text?: string;
    debit?: any;
    credit?: any;
    isTitle?: boolean;
    icon?: any;
}

const AccountListBase = (props: AccountListProperties) => {
    const { t } = useTranslation();
    const { uiStore } = useContext(RootStoreContext);
    const iconSize = UI.IconSize.default;
    const [modalBookingFiles, setModalBookingFiles] = useState<bookingfiles[]>([]);
    const [displaySelectBookingFilesModal, setDisplaySelectBookingFilesModal] = useState<boolean>(false);

    const renderItem = useCallback((item: AccountItem, screenSize: ScreenSize) => {
        let listRows: React.ReactElement[] = [];

        switch (screenSize) {
            case ScreenSize.DESKTOP:
                if (item.isTitle) {
                    listRows = [
                        <UI.List.Row key={"r-1"} className="bold">
                            <UI.List.Cell key={"c-1"} colspan={1} value={item.date} />
                            <UI.List.Cell key={"c-2"} colspan={3} value={item.text} className="uppercase" />
                            <UI.List.Cell key={"c-3"} colspan={1} value={item.credit} className="text-right" />
                            <UI.List.Cell key={"c-4"} colspan={1} value={item.debit} className="text-right" />
                        </UI.List.Row>
                    ];
                } else {
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={item.date} />
                            <UI.List.Cell key={"c-2"} colspan={3} value={item.text} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={item.debit} className="text-right" />
                            <UI.List.Cell key={"c-4"} colspan={1} value={item.credit} className="text-right" />
                        </UI.List.Row>
                    ];
                }
                break;
            case ScreenSize.MOBILE:
                if (item.isTitle) {
                    listRows = [
                        <UI.List.Row key={"r-1"} className="bold">
                            <UI.List.Cell key={"c-1"} colspan={1} value={item.text} className="uppercase" />
                            <UI.List.Cell key={"c-3"} colspan={1} value={item.credit} className="text-right" />
                            <UI.List.Cell key={"c-2"} colspan={1} value={item.debit} className="text-right" />
                        </UI.List.Row>
                    ];
                } else {
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={item.date} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={item.debit} className="text-right" />
                            <UI.List.Cell key={"c-3"} colspan={1} value={item.credit} className="text-right" />
                        </UI.List.Row>,
                        <UI.List.Row key={"r-2"}>
                            <UI.List.Cell key={"c-4"} colspan={1} value={item.text} className="sublabel" />
                        </UI.List.Row>
                    ];
                }

                break;
        }

        return (
            <UI.List.Item
                key={item.key}
                screenSize={screenSize}
                rows={listRows}
                icon={item.icon}
                className={item.isTitle ? "border-bottom" : ""}
            />
        );
    }, []);

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            const bookingDate = t("screens.realestate.finances.booking_date");
            const description = t("screens.realestate.finances.booking_description");
            const debit = t("screens.realestate.finances.debit");
            const credit = t("screens.realestate.finances.credit");

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={bookingDate} />
                            <UI.List.Cell key={"c-2"} colspan={3} value={description} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={debit} className="text-right" />
                            <UI.List.Cell key={"c-4"} colspan={1} value={credit} className="text-right" />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={bookingDate} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={debit} className="text-right" />
                            <UI.List.Cell key={"c-3"} colspan={1} value={credit} className="text-right" />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header key={bookingDate} rows={headerRows} />;
        },
        [t]
    );

    const getDocumentLinkIcon = useCallback((fileId?: any) => {
        let documentLinkIcon = undefined;

        if (fileId) {
            let urlLink = NetworkConfig.datafileUrl + fileId;
            documentLinkIcon = (
                <a href={urlLink} className="link" rel="noopener noreferrer d-flex" target="_blank">
                    <UI.Icon icon={UI.SVGIcon.Documents} color={ColorStyle("primary")} />
                </a>
            );
        }

        return documentLinkIcon;
    }, []);

    const renderBookingFileItem = useCallback(
        (bookingFile: bookingfiles, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];

            const file = bookingFile;
            const fileDate = getDate(file?.filedate);

            const documentLinkIcon = getDocumentLinkIcon(bookingFile.fileid);

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={file?.name} />
                            <UI.List.Cell key={"c-2"} colspan={0.4} value={fileDate} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={file?.name} />
                        </UI.List.Row>,
                        <UI.List.Row key={"r-2"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={fileDate} className="sublabel" />
                        </UI.List.Row>
                    ];
                    break;
            }

            return (
                <UI.List.Item
                    key={bookingFile.fileid}
                    screenSize={screenSize}
                    rows={listRows}
                    icon={documentLinkIcon}
                />
            );
        },
        [getDocumentLinkIcon]
    );

    const openDocumentSelectModal = useCallback(
        (bookingFiles: bookingfiles[]) => () => {
            setModalBookingFiles(bookingFiles);
            setDisplaySelectBookingFilesModal(true);
        },
        []
    );

    const onCloseSelectBookingFilesModal = useCallback(() => {
        setDisplaySelectBookingFilesModal(false);
    }, []);

    const bookingItems: AccountItem[] = [];

    props.bookings.forEach((booking: portal_accounts_part_bookings, index) => {
        const date = dateFormat(new Date(booking.date), "dd.MM.yyyy") || "";

        const getAccountDocumentsIcon = (bookingFiles: bookingfiles[]) => {
            let accountDocumentIcon = undefined;

            if (bookingFiles.length === 1 && bookingFiles[0].fileid) {
                accountDocumentIcon = getDocumentLinkIcon(bookingFiles[0].fileid);
            } else if (bookingFiles.length > 1) {
                accountDocumentIcon = (
                    <UI.Icon
                        icon={UI.SVGIcon.Documents}
                        color={ColorStyle("primary")}
                        onClick={openDocumentSelectModal(bookingFiles)}
                    />
                );
            } else {
                accountDocumentIcon = <span style={{ width: iconSize }} />;
            }

            return accountDocumentIcon;
        };

        bookingItems.push({
            key: index,
            date: date,
            text: booking.text,
            debit: getCurrencyElement(booking.debit),
            credit: getCurrencyElement(booking.credit),
            icon: getAccountDocumentsIcon(booking.bookingfiles)
        });
    });

    const selectBookingFilesModal = (
        <UI.Modal show={displaySelectBookingFilesModal} onClose={onCloseSelectBookingFilesModal} isScrollable={true}>
            <div>
                <h2 className={`${styles.DocumentsModalTitle} mt-2 mb-3`}>{t("screens.realestate.documents.title")}</h2>
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={modalBookingFiles}
                    renderItem={renderBookingFileItem}
                />
            </div>
            <UI.Button
                className="AnnouncementModalButton"
                label={t("labels.cancel")}
                onClick={onCloseSelectBookingFilesModal}
                variant="link"
            />
        </UI.Modal>
    );

    return (
        <>
            {selectBookingFilesModal}
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={bookingItems}
                renderItem={renderItem}
                renderHeader={renderHeader}
                itemsPerPage={30}
            />
        </>
    );
};

export const AccountList = Sentry.withProfiler(observer(AccountListBase));
