import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_REALESTATE_MAINTENANCES, GET_UNIT_MAINTENANCES } from "src/api/maintenances";
import { GetUnitMaintenances, GetUnitMaintenancesVariables } from "src/api/generated/GetUnitMaintenances";
import MaintenancesList from "src/screens/realestates/realestate/maintenances/list/MaintenancesList";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { Route, selectRoute } from "src/config/routes";
import { withRouter, RouteComponentProps, useParams } from "react-router";
import { getLang } from "src/utils/i18n";
import { ScreenWithTiles } from "src/components/screenWithTiles/ScreenWithTiles";
import { ScreenType } from "src/api/tiles";

interface Params {
    realestateid: string;
    unitid: string;
    applianceid: string;
}

const MaintenancesBase = (props: RouteComponentProps) => {
    const { realestateid, unitid, applianceid } = useParams<Params>();

    const { t } = useTranslation();
    const { navStore, authStore } = useContext(RootStoreContext);

    useEffect(() => {
        if (unitid) {
            navStore.setBackbutton(
                selectRoute(Route.unit, authStore.user?.role, { realestateid: realestateid, unitid: unitid }),
                t("screens.unit.title")
            );
        } else {
            navStore.setBackbutton(
                selectRoute(Route.dashboard, authStore.user?.role, { realestateid: realestateid }),
                t("screens.realestate.dashboard.title")
            );
        }
    }, [unitid, authStore.user?.role, navStore, realestateid, t]);

    const { data, loading, error } = useQuery<GetUnitMaintenances, GetUnitMaintenancesVariables>(
        unitid ? GET_UNIT_MAINTENANCES : GET_REALESTATE_MAINTENANCES,
        {
            variables: {
                realestateid: realestateid,
                unitid: unitid,
                lang: getLang(),
                isunitidnull: unitid ? false : true
            }
        }
    );

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    let screenTitle = t("screens.realestate.dashboard.maintenances");

    if (unitid) {
        screenTitle = t("screens.unit.maintenances");
    } else if (applianceid) {
        screenTitle = t("screens.realestate.appliances.maintenances");
    } else if (navStore.isConsolidation) {
        screenTitle = t("screens.realestate.dashboard.maintenances_short");
    }

    return (
        <ScreenWithTiles title={screenTitle} screenId={ScreenType.MAINTENANCES} realestateid={realestateid}>
            <UI.Card>
                <MaintenancesList maintenances={data.maintenances} maintenancestatus={data.maintenancestatus} />
            </UI.Card>
        </ScreenWithTiles>
    );
};

export const Maintenances = withRouter(Sentry.withProfiler(observer(MaintenancesBase)));
