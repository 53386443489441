interface HSL {
    h: number;
    s: number;
    l: number;
}

export const getCssRootValue = (rootVariable: string) => {
    return getComputedStyle(document.documentElement)
        .getPropertyValue("--" + rootVariable)
        .trim()
        .toLocaleLowerCase();
};

export const ColorStyle = (input: string | undefined): string => {
    let color = "#000";
    if (input) {
        if (input.indexOf("#") === 0 || input.indexOf("rgb") === 0) {
            color = input;
        } else {
            color = `var(--${input})`;
        }
    }
    return color;
};

export const contrastColor = (color: string): string => {
    if (color.indexOf("#") === 0) {
        return hexToHSL(color).l > 50 ? "black" : "white";
    } else {
        return color;
    }
};

export const hexToHSL = (H: string): HSL => {
    // From: https://css-tricks.com/converting-color-spaces-in-javascript/
    // Convert hex to RGB first
    let r = 0;
    let g = 0;
    let b = 0;
    if (H.length === 4) {
        r = parseInt("0x" + H[1] + H[1]);
        g = parseInt("0x" + H[2] + H[2]);
        b = parseInt("0x" + H[3] + H[3]);
    } else if (H.length === 7) {
        r = parseInt("0x" + H[1] + H[2]);
        g = parseInt("0x" + H[3] + H[4]);
        b = parseInt("0x" + H[5] + H[6]);
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;

    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    if (delta === 0) h = 0;
    else if (cmax === r) h = ((g - b) / delta) % 6;
    else if (cmax === g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0) h += 360;

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);
    return {
        h,
        s,
        l
    };
};
export const HSLToHex = (h: number, s: number, l: number): string => {
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s;
    let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    let m = l - c / 2;
    let r = 0;
    let g = 0;
    let b = 0;

    if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
    }
    // Having obtained RGB, convert channels to hex
    let _r = Math.round((r + m) * 255).toString(16);
    let _g = Math.round((g + m) * 255).toString(16);
    let _b = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (_r.length === 1) _r = "0" + r;
    if (_g.length === 1) _g = "0" + g;
    if (_b.length === 1) _b = "0" + b;

    return "#" + _r + _g + _b;
};
export const hslToCSS = (values: HSL): string => {
    return HSLToHex(values.h, values.s, values.l); // Returns a hex color
};

export const TransformColors = (props: HSL): HSL[] => {
    const base_h = props.h;
    const base_s = props.s;
    const base_l = props.l;

    let s_1 = base_s;
    let s_2 = base_s > 50 ? base_s - 50 : base_s + 50;
    let l_1 = base_l;
    let l_2 = 0;
    let l_3 = 0;

    if (base_l > 66) {
        l_2 = base_l - 25;
        l_3 = base_l - 50;
    } else if (base_l > 33) {
        l_2 = base_l - 25;
        l_3 = base_l + 25;
    } else {
        l_2 = base_l + 50;
        l_3 = base_l + 25;
    }

    let colors: HSL[] = [
        { h: base_h, s: s_1, l: l_1 },
        { h: base_h, s: s_1, l: l_2 },
        { h: base_h, s: s_1, l: l_3 },
        { h: base_h, s: s_2, l: l_1 }
    ].sort((lhs, rhs) => {
        return lhs.l - rhs.l;
    }); // sort color by lightness

    //Additional Color 1 & 2
    colors.push({ h: base_h, s: s_2, l: l_2 });
    colors.push({ h: base_h, s: s_2, l: l_3 });

    return colors;
};
