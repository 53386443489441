import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { NAMESIGN_NOTIFICATION_PROPERTY } from "src/stores/TicketStore";
import styles from "./NamesignNotificationFormFields.module.css";
import "./GeneralFormStyles.css";

const NamesignNotificationFormFieldsBase = () => {
    const { t } = useTranslation();
    const { ticketStore } = useContext(RootStoreContext);

    const onChangeNamesignNotificationField = useCallback(
        (namesignNotificationProperty: NAMESIGN_NOTIFICATION_PROPERTY) => (e: React.ChangeEvent<HTMLInputElement>) => {
            let newValue: any = e.target.value;
            let oldValue = undefined;

            if (namesignNotificationProperty === NAMESIGN_NOTIFICATION_PROPERTY.BELL_DIFFERENT) {
                oldValue = ticketStore.currentTicket?.namesignNotificationDetails?.belldifferent;
                newValue = !oldValue;

                if (newValue === false) {
                    ticketStore.currentTicket?.updateNamesignNotificationProperty(
                        NAMESIGN_NOTIFICATION_PROPERTY.BELL_INSCRIPTION,
                        ""
                    );
                }
            }

            if (namesignNotificationProperty === NAMESIGN_NOTIFICATION_PROPERTY.DOOR_DIFFERENT) {
                oldValue = ticketStore.currentTicket?.namesignNotificationDetails?.doordifferent;
                newValue = !oldValue;

                if (newValue === false) {
                    ticketStore.currentTicket?.updateNamesignNotificationProperty(
                        NAMESIGN_NOTIFICATION_PROPERTY.DOOR_INSCRIPTION,
                        ""
                    );
                }
            }

            if (namesignNotificationProperty === NAMESIGN_NOTIFICATION_PROPERTY.MAILBOX_DIFFERENT) {
                oldValue = ticketStore.currentTicket?.namesignNotificationDetails?.mailboxdifferent;
                newValue = !oldValue;

                if (newValue === false) {
                    ticketStore.currentTicket?.updateNamesignNotificationProperty(
                        NAMESIGN_NOTIFICATION_PROPERTY.MAILBOX_INSCRIPTION,
                        ""
                    );
                }
            }

            if (ticketStore.currentTicket) {
                ticketStore.currentTicket.updateNamesignNotificationProperty(namesignNotificationProperty, newValue);
            }
        },
        [ticketStore.currentTicket]
    );

    const onBlurInscriptionField = useCallback(
        (e: any) => {
            ticketStore.currentTicket?.validateNamesignNotificationDetails();
        },
        [ticketStore.currentTicket]
    );

    const bellsignCheckboxAndInput = (
        <>
            <UI.Checkbox
                name={"belldifferentCheckbox"}
                key={"belldifferentCheckbox"}
                onChange={onChangeNamesignNotificationField(NAMESIGN_NOTIFICATION_PROPERTY.BELL_DIFFERENT)}
                checked={
                    ticketStore.currentTicket && ticketStore.currentTicket.namesignNotificationDetails
                        ? ticketStore.currentTicket.namesignNotificationDetails?.belldifferent ?? false
                        : false
                }
                label={t("screens.tickets.form.notification.namesign.belldifferent").toString()}
                disabled={!ticketStore.isEditing}
                className="mb-1"
            />
            {ticketStore.currentTicket?.namesignNotificationDetails.belldifferent ? (
                ticketStore.isEditing ? (
                    <UI.Input
                        type="text"
                        as="textarea"
                        autoComplete="off"
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.namesignNotificationDetails
                                ? ticketStore.currentTicket.namesignNotificationDetails?.bellinscription ?? ""
                                : ""
                        }
                        onChange={onChangeNamesignNotificationField(NAMESIGN_NOTIFICATION_PROPERTY.BELL_INSCRIPTION)}
                        onBlur={onBlurInscriptionField}
                        errorMsg={ticketStore.currentTicket?.errors.namesignBellInscription ?? undefined}
                        disabled={!ticketStore.isEditing}
                        className={styles.TextArea}
                    />
                ) : (
                    <div className="mb-4 mt-2 pl-0">
                        {ticketStore.currentTicket && ticketStore.currentTicket.namesignNotificationDetails
                            ? ticketStore.currentTicket.namesignNotificationDetails?.bellinscription ?? ""
                            : ""}
                    </div>
                )
            ) : undefined}
        </>
    );

    const mailboxCheckboxAndInput = (
        <>
            <UI.Checkbox
                name={"mailboxdifferentCheckbox"}
                key={"mailboxdifferentCheckbox"}
                onChange={onChangeNamesignNotificationField(NAMESIGN_NOTIFICATION_PROPERTY.MAILBOX_DIFFERENT)}
                checked={
                    ticketStore.currentTicket && ticketStore.currentTicket.namesignNotificationDetails
                        ? ticketStore.currentTicket.namesignNotificationDetails?.mailboxdifferent ?? false
                        : false
                }
                label={t("screens.tickets.form.notification.namesign.mailboxdifferent").toString()}
                disabled={!ticketStore.isEditing}
                className="mb-1"
            />
            {ticketStore.currentTicket?.namesignNotificationDetails.mailboxdifferent ? (
                ticketStore.isEditing ? (
                    <UI.Input
                        type="text"
                        as="textarea"
                        autoComplete="off"
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.namesignNotificationDetails
                                ? ticketStore.currentTicket.namesignNotificationDetails?.mailboxinscription ?? ""
                                : ""
                        }
                        onChange={onChangeNamesignNotificationField(NAMESIGN_NOTIFICATION_PROPERTY.MAILBOX_INSCRIPTION)}
                        onBlur={onBlurInscriptionField}
                        errorMsg={ticketStore.currentTicket?.errors.namesignMailboxInscription ?? undefined}
                        disabled={!ticketStore.isEditing}
                        className={styles.TextArea}
                    />
                ) : (
                    <div className="mb-4 mt-2 pl-0">
                        {ticketStore.currentTicket && ticketStore.currentTicket.namesignNotificationDetails
                            ? ticketStore.currentTicket.namesignNotificationDetails?.mailboxinscription ?? ""
                            : ""}
                    </div>
                )
            ) : undefined}
        </>
    );

    const doorCheckboxAndInput = (
        <>
            <UI.Checkbox
                name={"doordifferentCheckbox"}
                key={"doordifferentCheckbox"}
                onChange={onChangeNamesignNotificationField(NAMESIGN_NOTIFICATION_PROPERTY.DOOR_DIFFERENT)}
                checked={
                    ticketStore.currentTicket && ticketStore.currentTicket.namesignNotificationDetails
                        ? ticketStore.currentTicket.namesignNotificationDetails?.doordifferent ?? false
                        : false
                }
                label={t("screens.tickets.form.notification.namesign.doordifferent").toString()}
                disabled={!ticketStore.isEditing}
                className="mb-1"
            />
            {ticketStore.currentTicket?.namesignNotificationDetails.doordifferent ? (
                ticketStore.isEditing ? (
                    <UI.Input
                        type="text"
                        as="textarea"
                        autoComplete="off"
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.namesignNotificationDetails
                                ? ticketStore.currentTicket.namesignNotificationDetails?.doorinscription ?? ""
                                : ""
                        }
                        onChange={onChangeNamesignNotificationField(NAMESIGN_NOTIFICATION_PROPERTY.DOOR_INSCRIPTION)}
                        onBlur={onBlurInscriptionField}
                        errorMsg={ticketStore.currentTicket?.errors.namesignDoorInscription ?? undefined}
                        disabled={!ticketStore.isEditing}
                        className={styles.TextArea}
                    />
                ) : (
                    <div className="mb-4 mt-2 pl-0">
                        {ticketStore.currentTicket && ticketStore.currentTicket.namesignNotificationDetails
                            ? ticketStore.currentTicket.namesignNotificationDetails?.doorinscription ?? ""
                            : ""}
                    </div>
                )
            ) : undefined}
        </>
    );

    return (
        <div className={`${styles.FormContainer} ${!ticketStore.isEditing ? "view-only-container" : ""}`}>
            <div className="p-2">
                <UI.Row>
                    <UI.Col>
                        {ticketStore.isEditing ? (
                            <UI.Input
                                label={`${t("screens.tickets.form.notification.namesign.inscription").toString()} *`}
                                type="text"
                                as="textarea"
                                autoComplete="off"
                                value={
                                    ticketStore.currentTicket && ticketStore.currentTicket.namesignNotificationDetails
                                        ? ticketStore.currentTicket.namesignNotificationDetails?.inscription ?? ""
                                        : ""
                                }
                                onChange={onChangeNamesignNotificationField(NAMESIGN_NOTIFICATION_PROPERTY.INSCRIPTION)}
                                onBlur={onBlurInscriptionField}
                                errorMsg={ticketStore.currentTicket?.errors.namesignInscription ?? undefined}
                                disabled={!ticketStore.isEditing}
                                className={styles.TextArea}
                            />
                        ) : (
                            <>
                                <div className="title">
                                    {t("screens.tickets.form.notification.namesign.inscription")}
                                </div>
                                <div className="mb-4 mt-3 pl-0">
                                    {ticketStore.currentTicket && ticketStore.currentTicket.namesignNotificationDetails
                                        ? ticketStore.currentTicket.namesignNotificationDetails?.inscription ?? ""
                                        : ""}
                                </div>
                            </>
                        )}

                        {bellsignCheckboxAndInput}
                        {mailboxCheckboxAndInput}
                        {doorCheckboxAndInput}

                        {/* DOCUMENT MESSAGE ROW */}
                        <UI.Input
                            label={`${t("screens.tickets.form.notification.question_to_document.message").toString()}`}
                            type="text"
                            as="textarea"
                            autoComplete="off"
                            value={
                                ticketStore.currentTicket && ticketStore.currentTicket.namesignNotificationDetails
                                    ? ticketStore.currentTicket.namesignNotificationDetails?.description ?? ""
                                    : ""
                            }
                            onChange={onChangeNamesignNotificationField(NAMESIGN_NOTIFICATION_PROPERTY.DESCRIPTION)}
                            disabled={!ticketStore.isEditing}
                            className={styles.TextArea}
                        />
                    </UI.Col>
                </UI.Row>
            </div>
        </div>
    );
};

export const NamesignNotificationFormFields = Sentry.withProfiler(observer(NamesignNotificationFormFieldsBase));
