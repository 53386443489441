import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_TICKETING_SETTINGS } from "src/api/customer";
import { TicketingEmailSettingsForm } from "src/screens/ticketingsettings/form/email-settings/TicketingEmailSettingsForm";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { withRouter, RouteComponentProps } from "react-router";
import { selectRoute, Route } from "src/config/routes";
import styles from "./TicketingSettings.module.css";
import { GetTicketingSettings } from "src/api/generated/GetTicketingSettings";
import { TicketingBillingInformationForm } from "./form/billing-address/TicketingBillingInformationForm";

const TicketingSettingsBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore, uiStore } = useContext(RootStoreContext);

    const { data, loading, error, refetch } = useQuery<GetTicketingSettings>(GET_TICKETING_SETTINGS);

    useEffect(() => {
        navStore.setTitle(t("screens.account.ticketing_settings"));
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);

        if (uiStore.isSidebarActive) {
            navStore.setBackbutton(null);
        } else {
            navStore.setBackbutton(selectRoute(Route.profile), t("screens.account.title").toString());
        }
    }, [navStore, t, uiStore.isSidebarActive]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col xl={9}>
                        <UI.Title>{t("screens.ticketing_settings.title")}</UI.Title>
                    </UI.Col>
                </UI.Row>
                <UI.Row>
                    <UI.Col md={12}>
                        <UI.Card
                            title={t("screens.ticketing_settings.billing_information_form.title").toString()}
                            className={styles.TicketingSettingsFormContainer}
                        >
                            <div>
                                <TicketingBillingInformationForm
                                    ticketingSettings={data}
                                    refetchTicketingSettings={refetch}
                                />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
                <UI.Row>
                    <UI.Col md={12}>
                        <UI.Card
                            title={t("screens.ticketing_settings.email_form.title").toString()}
                            className={styles.TicketingSettingsFormContainer}
                        >
                            <div>
                                <TicketingEmailSettingsForm
                                    ticketingSettings={data}
                                    refetchTicketingSettings={refetch}
                                />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const TicketingSettings = withRouter(Sentry.withProfiler(observer(TicketingSettingsBase)));
