import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { IComponent, IMAGE_TYPE, KEY_ORDER_NOTIFICATION_PROPERTY } from "src/stores/TicketStore";
import styles from "./KeyOrderNotificationFormFields.module.css";
import "./GeneralFormStyles.css";
import { ImageSelectorContainer } from "src/components/imageSelector/ImageSelectorContainer";
import { ImageSelectorItem } from "src/components/imageSelector/ImageSelectorItem";

const KeyOrderNotificationFormFieldsBase = () => {
    const { t } = useTranslation();
    const { ticketStore, keyOrderNotificationStore } = useContext(RootStoreContext);

    const onClickComponentTreeElement = useCallback(
        (component: IComponent) => (e: any) => {
            keyOrderNotificationStore.handleClickOnComponentTreeElement(component);
        },
        [keyOrderNotificationStore]
    );

    const onClickEditComponentTree = useCallback(() => {
        keyOrderNotificationStore.handleEditComponentTreeAfterFinishedSelection();
    }, [keyOrderNotificationStore]);

    const componentTree = (
        <>
            <div className={styles.ComponentTreeContainer}>
                <div className={styles.ComponentTreeRow}>
                    {keyOrderNotificationStore.formComponentTreeData.map((component) => {
                        const componentTreeElement = (
                            <div
                                key={component.id}
                                className={`${styles.ComponentTreeRowElement} ${
                                    component.isSelected ? styles.Selected : ""
                                } ${
                                    keyOrderNotificationStore.isComponentSelectionFinished
                                        ? styles.ComponentSelectionFinished
                                        : ""
                                }`}
                                onClick={onClickComponentTreeElement(component)}
                            >
                                {component.title}
                            </div>
                        );

                        return keyOrderNotificationStore.isComponentSelectionFinished
                            ? component.isSelected
                                ? componentTreeElement
                                : undefined
                            : componentTreeElement;
                    })}
                </div>
                {keyOrderNotificationStore.isComponentSelectionFinished && ticketStore.isEditing ? (
                    <div
                        key={"component-tree-edit-button-container"}
                        className={`${styles.ComponentTreeRowElement} ${styles.EditButton}`}
                        onClick={onClickEditComponentTree}
                    >
                        {t("screens.tickets.form.edit").toString()}
                    </div>
                ) : undefined}
            </div>
            {ticketStore.currentTicket?.errors.keyOrderSelectKeyType ? (
                <div className={styles.Error}>{ticketStore.currentTicket?.errors.keyOrderSelectKeyType}</div>
            ) : undefined}
        </>
    );

    const onChangeKeyOrderNotificationField = useCallback(
        (keyOrderNotificationProperty: KEY_ORDER_NOTIFICATION_PROPERTY) => (e: React.ChangeEvent<HTMLInputElement>) => {
            let newValue: any = e.target.value;

            if (keyOrderNotificationProperty === KEY_ORDER_NOTIFICATION_PROPERTY.AMOUNT) {
                if (newValue === "" || newValue === undefined || newValue === null) {
                    newValue = "";
                } else {
                    newValue = Number(newValue);
                }
            }

            if (keyOrderNotificationProperty === KEY_ORDER_NOTIFICATION_PROPERTY.LOST) {
                const oldValue = ticketStore.currentTicket?.keyOrderNotificationDetails?.lost;
                newValue = !oldValue;
            }

            if (ticketStore.currentTicket) {
                ticketStore.currentTicket.updateKeyOrderNotificationProperty(keyOrderNotificationProperty, newValue);
            }
        },
        [ticketStore.currentTicket]
    );

    const onBlurField = useCallback(
        (e: any) => {
            ticketStore.currentTicket?.validateKeyOrderNotificationDetails();
        },
        [ticketStore.currentTicket]
    );

    const updateTicketImageProperty = useCallback(
        (keyOrderNotificationProperty: KEY_ORDER_NOTIFICATION_PROPERTY) => (newValue: any, fileId?: string) => {
            if (ticketStore.currentTicket) {
                ticketStore.currentTicket.updateKeyOrderNotificationProperty(
                    keyOrderNotificationProperty,
                    newValue,
                    fileId
                );
            }
        },
        [ticketStore.currentTicket]
    );

    const images = ticketStore.currentTicket?.keyOrderNotificationDetails?.images || [];
    const imagesToRender = [...images, null].slice(0, 8); // Limit to 8 items

    const keyOrderNotificationDetails = (
        <div className="p-2">
            {/* OTHER KEY DESCRIPTION ROW */}
            {ticketStore.currentTicket?.keyOrderNotificationDetails?.isOtherKeyComponentSelected ? (
                <UI.Row>
                    <UI.Col>
                        <UI.Input
                            label={`${t("screens.tickets.form.notification.key_order.key").toString()} *`}
                            type="text"
                            autoComplete="off"
                            value={
                                ticketStore.currentTicket && ticketStore.currentTicket.keyOrderNotificationDetails
                                    ? ticketStore.currentTicket.keyOrderNotificationDetails?.otherKeyName ?? ""
                                    : ""
                            }
                            onChange={onChangeKeyOrderNotificationField(KEY_ORDER_NOTIFICATION_PROPERTY.OTHER_KEY_NAME)}
                            onBlur={onBlurField}
                            errorMsg={ticketStore.currentTicket?.errors.keyOrderOtherKeyInscription ?? undefined}
                            disabled={!ticketStore.isEditing}
                        />
                    </UI.Col>
                </UI.Row>
            ) : undefined}

            {/* AMOUNT OF KEYS ROW */}
            <UI.Row>
                <UI.Col>
                    <UI.Input
                        label={`${t("screens.tickets.form.notification.key_order.key_amount").toString()} *`}
                        type="number"
                        autoComplete="off"
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.keyOrderNotificationDetails
                                ? ticketStore.currentTicket.keyOrderNotificationDetails?.amount ?? undefined
                                : undefined
                        }
                        onChange={onChangeKeyOrderNotificationField(KEY_ORDER_NOTIFICATION_PROPERTY.AMOUNT)}
                        onBlur={onBlurField}
                        errorMsg={ticketStore.currentTicket?.errors.keyOrderAmount ?? undefined}
                        disabled={!ticketStore.isEditing}
                        className="mb-0"
                    />
                </UI.Col>
            </UI.Row>

            {/* KEY LOST ROW */}
            <UI.Row>
                <UI.Col>
                    <UI.Checkbox
                        name={"keyLostCheckbox"}
                        key={"keyLostCheckbox"}
                        onChange={onChangeKeyOrderNotificationField(KEY_ORDER_NOTIFICATION_PROPERTY.LOST)}
                        checked={
                            ticketStore.currentTicket && ticketStore.currentTicket.keyOrderNotificationDetails.lost
                                ? ticketStore.currentTicket.keyOrderNotificationDetails.lost ?? false
                                : false
                        }
                        label={t("screens.tickets.form.notification.key_order.key_loss").toString()}
                        disabled={!ticketStore.isEditing}
                        className="mb-1"
                    />
                </UI.Col>
            </UI.Row>

            {/* KEY ISNCRIPTION ROW */}
            <UI.Row>
                <UI.Col>
                    <UI.Input
                        label={`${t("screens.tickets.form.notification.key_order.key_inscription").toString()} *`}
                        type="text"
                        as="textarea"
                        autoComplete="off"
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.keyOrderNotificationDetails
                                ? ticketStore.currentTicket.keyOrderNotificationDetails?.inscription ?? ""
                                : ""
                        }
                        onChange={onChangeKeyOrderNotificationField(KEY_ORDER_NOTIFICATION_PROPERTY.INSCRIPTION)}
                        errorMsg={ticketStore.currentTicket?.errors.keyOrderInscription ?? undefined}
                        onBlur={onBlurField}
                        disabled={!ticketStore.isEditing}
                        className={styles.TextArea}
                    />
                </UI.Col>
            </UI.Row>
            {/* DOCUMENT MESSAGE ROW */}
            <UI.Row>
                <UI.Col>
                    <UI.Input
                        label={`${t("screens.tickets.form.notification.question_to_document.message").toString()}`}
                        type="text"
                        as="textarea"
                        autoComplete="off"
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.keyOrderNotificationDetails
                                ? ticketStore.currentTicket.keyOrderNotificationDetails?.description ?? ""
                                : ""
                        }
                        onChange={onChangeKeyOrderNotificationField(KEY_ORDER_NOTIFICATION_PROPERTY.DESCRIPTION)}
                        disabled={!ticketStore.isEditing}
                        className={styles.TextArea}
                    />
                </UI.Col>
            </UI.Row>

            {/* KEY ORDER IMAGES ROW */}
            <UI.Row>
                <UI.Col>
                    <div>
                        <span className={styles.ImagesLabel}>{t("screens.tickets.form.notification.images")}</span>
                        <ImageSelectorContainer>
                            {imagesToRender.map((image, index) => (
                                <ImageSelectorItem
                                    key={index}
                                    label={`${t("screens.tickets.form.notification.image")} ${t(
                                        "screens.tickets.form.notification.key_order.key"
                                    )} ${index + 1}`}
                                    imageType={IMAGE_TYPE.KEY}
                                    image={image || {}}
                                    updateTicketImagePropertyFunction={updateTicketImageProperty(
                                        KEY_ORDER_NOTIFICATION_PROPERTY.IMAGES
                                    )}
                                    disabled={!ticketStore.isEditing}
                                />
                            ))}
                        </ImageSelectorContainer>
                    </div>
                </UI.Col>
            </UI.Row>
        </div>
    );

    return (
        <div className={`${styles.KeyOrderFormContainer} ${!ticketStore.isEditing ? "view-only-container" : ""}`}>
            {componentTree}
            {keyOrderNotificationStore.isComponentSelectionFinished ? keyOrderNotificationDetails : undefined}
        </div>
    );
};

export const KeyOrderNotificationFormFields = Sentry.withProfiler(observer(KeyOrderNotificationFormFieldsBase));
