import React, { useContext, useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { Header } from "src/components/header";
import { RootStoreContext } from "src/stores/RootStore";
import { Route } from "src/config/routes";
import { AppbarButtonProps } from "src/components/header/appbarButtons/AppbarButtons";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";
import styles from "./Appheader.module.css";
import { UI } from "@wwimmo/ui";
import { useSidebarFunctions } from "src/hooks/sidebar-functions/useSidebarFunctions";
import { Role } from "src/network/User";

interface MatchParams {
    realestateid: string;
}

const AppheaderBase = (props: RouteComponentProps<MatchParams>) => {
    const { uiStore, navStore, authStore } = useContext(RootStoreContext);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
    const { expandSidebar } = useSidebarFunctions();

    const iconSize = 42;

    const corner = (style: { left?: number; right?: number; transform?: string }) => {
        return (
            <svg
                className={styles.Corner}
                style={style}
                width={`${uiStore.isMobile ? 20 : 40}px`}
                height={`${uiStore.isMobile ? 20 : 40}px`}
                viewBox="0 0 20 20"
            >
                <path
                    d="M0,0 L40,0 L40,40 L0,40 L0,0 Z M20,40 C31.045695,40 40,31.045695 40,20 C40,8.954305 31.045695,0 20,0 C8.954305,0 0,8.954305 0,20 C0,31.045695 8.954305,40 20,40 Z"
                    fill="var(--primary)"
                />
            </svg>
        );
    };

    const displaySearchInput = useCallback(() => {
        setShowSearchInput(true);
    }, []);

    const hideSearchInput = useCallback(() => {
        setShowSearchInput(false);
    }, []);

    const appbarButtons: AppbarButtonProps[] = [
        { svgIcon: UI.SVGIcon.Search, onClick: displaySearchInput, isDisplayed: uiStore.showSearchAppbarButton },
        { svgIcon: UI.SVGIcon.Profile, to: Route.profile, isDisplayed: uiStore.showProfileAppbarButton }
    ];

    const closeSearchbar = useCallback(
        (e: any) => {
            if (showSearchInput && e.target.id !== "searchbarInput") {
                hideSearchInput();
            }
        },
        [showSearchInput, hideSearchInput]
    );

    const onClickHamburgerMenu = useCallback(() => {
        expandSidebar();
    }, [expandSidebar]);

    return (
        <div className={styles.Appheader} onClick={closeSearchbar}>
            <Helmet>
                <title>{navStore.currentTitle}</title>
            </Helmet>
            <UI.Container
                className={styles.Container}
                style={{
                    height: uiStore.headerHeight,
                    paddingTop: 20
                }}
                fluid
            >
                {uiStore.closeAppbar ? (
                    <UI.Row>
                        <UI.Col xs={{ span: 8 }} md={{ span: 3, order: 1 }}>
                            <Header.Backnavigation />
                        </UI.Col>
                        <UI.Col
                            xs={{ span: 12, order: 3 }}
                            sm={{ span: 6, order: 2 }}
                            className="pt-1 text-left text-md-center"
                        >
                            <div className={styles.Titlebar}>
                                <h1>{navStore.appheaderTitle}</h1>
                            </div>
                        </UI.Col>
                        <UI.Col xs={{ span: 4, order: 2 }} md={{ span: 3, order: 3 }} className="text-right">
                            <Header.Close />
                        </UI.Col>
                    </UI.Row>
                ) : (
                    // Show standard Header
                    <UI.Row className="align-items-center">
                        {!showSearchInput && (
                            <UI.Col
                                className="AppHeaderLeft"
                                xs={9}
                                sm={showSearchInput ? 6 : 8}
                                lg={showSearchInput ? 4 : 3}
                            >
                                <UI.Row>
                                    {authStore.user?.role === Role.MANAGER || authStore.user?.role === Role.ECADMIN ? (
                                        <UI.Col xs={2} sm={0} className="d-md-none">
                                            <UI.Icon icon={UI.SVGIcon.List} onClick={onClickHamburgerMenu} />
                                        </UI.Col>
                                    ) : undefined}

                                    <UI.Col xs={10} sm={12}>
                                        <Header.Backnavigation />
                                    </UI.Col>
                                </UI.Row>
                            </UI.Col>
                        )}
                        <UI.Col
                            className="AppHeaderMiddle pt-1 text-left text-md-center d-sm-none d-lg-block"
                            xs={{ span: 12, order: 3, offset: 0 }}
                            md={{ span: showSearchInput ? 4 : 6, order: 2, offset: showSearchInput ? 4 : 0 }}
                        >
                            {!showSearchInput && (
                                <div className={styles.Titlebar}>
                                    <h1
                                        style={
                                            uiStore.isMobile
                                                ? { display: "block", paddingRight: iconSize * 3 }
                                                : { display: "inline", paddingRight: 0 }
                                        }
                                    >
                                        <span className="ellipsise">{navStore.appheaderTitle}</span>
                                        {authStore.isLoggedIn && (
                                            <Header.ActionButtons iconSize={iconSize} isMobile={uiStore.isMobile} />
                                        )}
                                    </h1>
                                </div>
                            )}
                            {navStore.currentSubtitle ? <h2>{navStore.currentSubtitle}</h2> : <></>}
                        </UI.Col>
                        <UI.Col
                            className="AppHeaderRight d-flex flex-nowrap justify-content-end"
                            xs={{ span: showSearchInput ? 12 : 3, order: 2 }}
                            sm={{ span: showSearchInput ? 8 : 4, order: 3, offset: showSearchInput ? 2 : 0 }}
                            lg={{ span: showSearchInput ? 4 : 3, order: 3, offset: showSearchInput ? 4 : 0 }}
                        >
                            {authStore.isLoggedIn && (
                                <React.Fragment>
                                    {showSearchInput ? (
                                        <Header.Searchbar onClose={hideSearchInput} />
                                    ) : (
                                        <React.Fragment>
                                            <Header.AppbarButtons buttons={appbarButtons} />
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </UI.Col>
                    </UI.Row>
                )}
            </UI.Container>
            <div className={styles.CornerContainer}>
                {corner({ left: 0 })}
                {corner({ right: 0, transform: "scaleX(-1)" })}
            </div>
        </div>
    );
};
export const Appheader = withRouter(observer(AppheaderBase));
