import { observer } from "mobx-react-lite";
import * as Sentry from "@sentry/react";
import React, { useCallback, useContext } from "react";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { UI } from "@wwimmo/ui";
import styles from "./UserRatingModal.module.css";
import { StarRatingControl } from "src/components/ratingControl/StarRatingControl";

const UserRatingModalBase = () => {
    const { t } = useTranslation();
    const { userRatingModalStore } = useContext(RootStoreContext);

    const onChangeQueryComment = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            userRatingModalStore.setComment(event.target.value);
        },
        [userRatingModalStore]
    );

    const onChangeRating = useCallback(
        (rating: number) => {
            userRatingModalStore.setRating(rating);
        },
        [userRatingModalStore]
    );

    const onChangeDoNotAskAgain = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            userRatingModalStore.setDoNotAskAgain(!userRatingModalStore.doNotAskAgain);
        },
        [userRatingModalStore]
    );

    if (userRatingModalStore.isVisible) {
        return (
            <UI.Modal
                show={userRatingModalStore.isVisible}
                onClose={userRatingModalStore.dismissModal}
                size="lg"
                centered={true}
                isScrollable={true}
            >
                <UI.Col className={"m-2 pr-4"}>
                    <UI.Form.Label className={`${styles.Title}`}>{t("screens.userratingmodal.title")}</UI.Form.Label>
                    <StarRatingControl
                        rating={userRatingModalStore.rating}
                        nrOfStars={5}
                        onChangeRating={onChangeRating}
                        size={32}
                        disabled={userRatingModalStore.doNotAskAgain}
                    />
                    <UI.Form.Label
                        className={`${styles.InputLabel} ${
                            userRatingModalStore.doNotAskAgain ? styles.InputLabelDisabled : {}
                        }`}
                    >
                        {t("screens.userratingmodal.feedbackInputLabel")}
                    </UI.Form.Label>
                    <UI.Input
                        type="text"
                        as="textarea"
                        onChange={onChangeQueryComment}
                        disabled={userRatingModalStore.doNotAskAgain}
                    />
                    {userRatingModalStore.isLoading ? (
                        <UI.RotatingSpinner size={30} noLogo={true} className={`${styles.LoadingSpinner}`} />
                    ) : (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <UI.Checkbox
                                name={t("screens.userratingmodal.doNotAskAgain")}
                                label={t("screens.userratingmodal.doNotAskAgain")}
                                inline={true}
                                className={`${styles.doNotAskAgain} mr-4`}
                                checked={userRatingModalStore.doNotAskAgain}
                                onChange={onChangeDoNotAskAgain}
                            />
                            <UI.Button
                                className={`${styles.Button} ${
                                    userRatingModalStore.doNotAskAgain ? styles.DisabledButton : styles.OutlineButton
                                } mr-4`}
                                label={t("screens.userratingmodal.deferButton")}
                                variant="outline-primary"
                                onClick={userRatingModalStore.deferFeedback}
                                disabled={userRatingModalStore.doNotAskAgain}
                                active={userRatingModalStore.doNotAskAgain}
                            />
                            <UI.Button
                                className={`${styles.Button}`}
                                label={
                                    userRatingModalStore.doNotAskAgain
                                        ? t("labels.ok").toUpperCase()
                                        : t("screens.userratingmodal.rateButton")
                                }
                                disabled={
                                    userRatingModalStore.doNotAskAgain === false && userRatingModalStore.rating === 0
                                        ? true
                                        : false
                                }
                                onClick={
                                    userRatingModalStore.doNotAskAgain
                                        ? userRatingModalStore.deferFeedback
                                        : userRatingModalStore.sendFeedback
                                }
                            />
                        </div>
                    )}
                </UI.Col>
            </UI.Modal>
        );
    } else {
        return <React.Fragment />;
    }
};

export const UserRatingModal = Sentry.withProfiler(observer(UserRatingModalBase));
