import React from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./ServiceProviderListItem.module.css";
import generalStyles from "src/screens/tickets/ticket/details/actions/assignOrder/AssignOrderModal.module.css";
import { ColorStyle } from "src/utils/Colors";
import { IServiceProvider } from "src/stores/tickets/AssignOrderModalStore";

interface ServiceProviderListItemProps {
    serviceProvider: IServiceProvider;
    onClickServiceProviderButton: any;
    serviceProviderItemButtonLabel: string;
}

export const ServiceProviderListItemBase = (props: ServiceProviderListItemProps) => {
    const { serviceProvider, onClickServiceProviderButton, serviceProviderItemButtonLabel } = props;

    const serviceProviderName = `${serviceProvider.name1 ?? ""} ${serviceProvider.name2 ?? ""}`.trim();

    return (
        <div className={styles.ServiceProviderListItem} key={serviceProvider.personId}>
            <div className={styles.ServiceProviderListRow}>
                <div className="font-weight-bold">{serviceProviderName}</div>
            </div>
            <div className={styles.ServiceProviderListRow}>
                {serviceProvider.phone ? (
                    <div className={styles.ServiceProviderListRowIconItem}>
                        <UI.Icon icon={UI.SVGIcon.PhoneOutlined} size={25} color={ColorStyle("grey")} />
                        <span>{serviceProvider.phone}</span>
                    </div>
                ) : undefined}
                {serviceProvider.mobile ? (
                    <div className={styles.ServiceProviderListRowIconItem}>
                        <UI.Icon icon={UI.SVGIcon.Device} size={25} color={ColorStyle("grey")} />
                        <span>{serviceProvider.mobile}</span>
                    </div>
                ) : undefined}
                {serviceProvider.email ? (
                    <div className={styles.ServiceProviderListRowIconItem}>
                        <UI.Icon icon={UI.SVGIcon.Mail} size={25} color={ColorStyle("grey")} />
                        <span>{serviceProvider.email}</span>
                    </div>
                ) : undefined}
            </div>
            <div className={`${styles.ServiceProviderListRow} ${styles.SelectServiceProviderButtonContainer}`}>
                <UI.Button
                    className={`ticket-button ${generalStyles.BackButton}`}
                    label={serviceProviderItemButtonLabel}
                    variant={"outline-primary"}
                    onClick={onClickServiceProviderButton}
                ></UI.Button>
            </div>
        </div>
    );
};

export const ServiceProviderListItem = Sentry.withProfiler(observer(ServiceProviderListItemBase));
