import React from "react";
import * as Sentry from "@sentry/react";
import { ApolloProvider } from "@apollo/client";
import { observer } from "mobx-react";
import { apolloClientInstance } from "./apolloClientInstance";

const GraphqlClientBase = (props: any) => {
    return <ApolloProvider client={apolloClientInstance}>{props.children}</ApolloProvider>;
};

export const GraphqlClient = Sentry.withProfiler(observer(GraphqlClientBase));
