import { Role, RolesMap } from "src/network/User";
import { generatePath } from "react-router";
import { pathToRegexp } from "path-to-regexp";

export enum Route {
    root = "/",
    account = "/realestate/:realestateid/finances/:accountlistid/account/:accountid",
    coownerAccount = "/realestate/:realestateid/account",
    tenantAccount = "/realestate/:realestateid/account",
    activate = "/activate",
    customerusers = "/users",
    userInfoPage = "/user-info-page",
    customerUserdetails = "/users/:userid",
    agendaTemplate = "/agenda-template",
    portalDemo = "/portal-demo",
    userRatingsPortal = "/userratingsportal",
    stwegDemoPage = "/demo/stweg",
    dashboard = "/realestate/:realestateid/dashboard",
    documents = "/realestate/:realestateid/documents",
    coownerAssemblies = "/realestate/:realestateid/coowner/assemblies",
    managerAssemblies = "/realestate/:realestateid/manager/assemblies",
    managerAssembliesEdit = "/realestate/:realestateid/assemblies-edit/:assemblyid",
    easycontactsettings = "/easycontactsettings",
    ticketingsettings = "/ticketingsettings",
    news = "/realestate/:realestateid/news",
    finances = "/realestate/:realestateid/finances/:accountlistid?",
    fileerror = "/fileerror",
    systemHealthError = "/system-health-error",
    inspectionapphint = "/inspectionapphint",
    login = "/login",
    logout = "/logout",
    manager = "/manager",
    owners = "/owners",
    owner = "/owner",
    coowner = "/coowner",
    auditor = "/auditor",
    tenant = "/tenant",
    managerDashboard = "/dashboard",
    passwordchange = "/passwordchange",
    passwordrecovery = "/passwordrecovery",
    passwordreset = "/passwordreset",
    profile = "/profile",
    realestate = "/realestate/:realestateid",
    realestates = "/realestates",
    realestatesOwner = "/realestates/:ownerid",
    realestateAppliance = "/realestate/:realestateid/appliance/:applianceid",
    realestateAppliances = "/realestate/:realestateid/appliances",
    realestateApplianceServiceCompany = "/realestate/:realestateid/appliance/:applianceid/servicecompany/:servicecompanyid",
    realestateApplianceMaintenance = "/realestate/:realestateid/appliance/:applianceid/maintenance/:maintenanceid",
    realestateApplianceMaintenanceCraftsman = "/realestate/:realestateid/appliance/:applianceid/maintenance/:maintenanceid/craftsman/:craftsmanid",
    realestateApplianceMaintenanceAccount = "/realestate/:realestateid/appliance/:applianceid/maintenance/:maintenanceid/account/:accountid",
    realestateApplianceMaintenances = "/realestate/:realestateid/appliance/:applianceid/maintenances",
    ownerPerson = "/realestate/:realestateid/owner/:ownerid",
    realestateNewsPreview = "/realestate/:realestateid/news-preview/:newsid",
    realestateNewsPreviewEdit = "/realestate/:realestateid/news-preview/:newsid/news-edit/:newspreviewnewsid",
    realestatePerson = "/realestate/:realestateid/realestateperson/:personid",
    realestatePersons = "/realestate/:realestateid/realestatepersons",
    realestateUsers = "/realestate/:realestateid/realestateusers",
    realestateMaintenance = "/realestate/:realestateid/maintenance/:maintenanceid",
    realestateMaintenanceCraftsman = "/realestate/:realestateid/maintenance/:maintenanceid/craftsman/:craftsmanid",
    realestateMaintenanceAccount = "/realestate/:realestateid/maintenance/:maintenanceid/account/:accountid",
    realestateMaintenances = "/realestate/:realestateid/maintenances",
    realestateNewsEdit = "/realestate/:realestateid/news-edit/:newsid",
    register = "/register",
    resendActivationEMail = "/resendaccountactivationemail",
    services = "/services",
    servicesOverview = "/services/services-overview",
    servicesCostsAndInvoices = "/services/costs-and-invoices",
    servicesCostsAndInvoicesOverview = "/services/costs-and-invoices/overview",
    servicesCostsAndInvoicesCurrentMonth = "/services/costs-and-invoices/current-month",
    servicesCostsAndInvoicesCurrentMonthInvoicedUserDetails = "/services/costs-and-invoices/current-month/invoiced-user-details/:customerinvoiceid",
    servicesCostsAndInvoicesCurrentMonthInvoicedRentalVariableDetails = "/services/costs-and-invoices/current-month/invoiced-rental-variable-details",
    servicesCostsAndInvoicesHistory = "/services/costs-and-invoices/history",
    servicesCostsAndInvoicesHistoryInvoiceDetailsMonth = "/services/costs-and-invoices/history/:year/:month/invoice-details",
    servicesCostsAndInvoicesHistoryMonthInvoicedUserDetails = "/services/costs-and-invoices/history/:year/:month/invoiced-user-details/:customerinvoiceid",
    servicesCostsAndInvoicesHistoryMonthInvoicedRentalVariableDetails = "/services/costs-and-invoices/history/:year/:month/invoiced-rental-variable-details",
    servicesFeatureAndIntegration = "/services/feature-and-integration",
    servicesFeatures = "/services/feature-and-integration/features",
    servicesIntegrations = "/services/feature-and-integration/integrations",
    theming = "/profile/theming",
    tickets = "/tickets",
    ticket = "/ticket",
    newTicket = "/ticket/new-ticket",
    ticketDetails = "/ticket/:ticketnumber",
    ticketHistory = "/ticket/:ticketnumber/history",
    ticketOrder = "/ticket/:ticketnumber/order",
    ticketOrderDetails = "/ticket/:ticketnumber/order/:ordernumber",
    creditors = "/creditors",
    creditor = "/creditor",
    newInvoice = "/creditor/new-invoice",
    invoiceDetails = "/creditor/:invoicenumber",
    tileAdministration = "/tile-administration",
    units = "/realestate/:realestateid/units",
    unit = "/realestate/:realestateid/unit/:unitid",
    unitPerson = "/realestate/:realestateid/unit/:unitid/tenancyperson/:personid",
    unitTenant = "/realestate/:realestateid/unit/:unitid/tenant/:tenantid",
    unitAppliance = "/realestate/:realestateid/unit/:unitid/appliance/:applianceid",
    unitAppliances = "/realestate/:realestateid/unit/:unitid/appliances",
    unitApplianceServiceCompany = "/realestate/:realestateid/unit/:unitid/appliance/:applianceid/servicecompany/:servicecompanyid",
    unitApplianceMaintenance = "/realestate/:realestateid/unit/:unitid/appliance/:applianceid/maintenance/:maintenanceid",
    unitApplianceMaintenanceCraftsman = "/realestate/:realestateid/unit/:unitid/appliance/:applianceid/maintenance/:maintenanceid/craftsman/:craftsmanid",
    unitApplianceMaintenanceAccount = "/realestate/:realestateid/unit/:unitid/appliance/:applianceid/maintenance/:maintenanceid/account/:accountid",
    unitApplianceMaintenances = "/realestate/:realestateid/unit/:unitid/appliance/:applianceid/maintenances",
    unitMaintenance = "/realestate/:realestateid/unit/:unitid/maintenance/:maintenanceid",
    unitMaintenanceCraftsman = "/realestate/:realestateid/unit/:unitid/maintenance/:maintenanceid/craftsman/:craftsmanid",
    unitMaintenanceAccount = "/realestate/:realestateid/unit/:unitid/maintenance/:maintenanceid/account/:accountid",
    unitMaintenances = "/realestate/:realestateid/unit/:unitid/maintenances"
}

export enum SearchRoute {
    person = "/search/person/:personid"
}

export const getRootRoute = (role: Role) => {
    const rootRoute = RolesMap.find((r) => r.role === role)?.rootRoute;
    return rootRoute ? rootRoute : RolesMap.find((r) => r.role === Role.NONE)?.rootRoute || "/";
};

export const getRoute = (url: string, role: Role): Route | undefined => {
    const routes = Route as any;
    const rootRoute = getRootRoute(role) || "";
    for (const r in routes) {
        const routePath = rootRoute.concat(routes[r]);
        const keys: any[] = [];
        const regexp = pathToRegexp(routePath, keys);
        const match = regexp.exec(url);
        if (match) {
            return routes[r] as Route;
        }
    }
    return undefined;
};

export const selectRoute = (
    route: Route | SearchRoute,
    role?: Role | null,
    params?: { [key: string]: string }
): string => {
    if (params) {
        return generatePath((role ? getRootRoute(role) : "") + route, params);
    } else if (role) {
        return getRootRoute(role) + route;
    } else {
        return route;
    }
};

export const getParams = (url: string, role?: Role): { [key: string]: string } => {
    const rootRoute = role ? getRootRoute(role) : "";

    const params: { [key: string]: string } = {};

    const keys: any[] = [];
    const regexp = pathToRegexp(rootRoute + url, keys);
    const result = regexp.exec(window.location.pathname);

    if (result) {
        for (let i = 1; i < result.length; i++) {
            params[keys[i - 1].name] = result[i];
        }
    }

    return params;
};
