import React, { useContext, useCallback, useEffect, useState } from "react";
import {
    GetApplianceDetails_appliance_maintenances as maintenance,
    GetApplianceDetails_maintenancestatus as maintenancestatus
} from "src/api/generated/GetApplianceDetails";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { Route, selectRoute } from "src/config/routes";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import { useParams } from "react-router";
import { dateFormat } from "src/utils/Date";

interface ApplianceMaintenancesListProps {
    maintenances: maintenance[];
    maintenancestatus: maintenancestatus[];
}

interface Params {
    realestateid: string;
    unitid: string;
    applianceid: string;
}

const ApplianceMaintenances = (props: ApplianceMaintenancesListProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore, authStore } = useContext(RootStoreContext);
    const { realestateid, unitid, applianceid } = useParams<Params>();

    let listIdentifier = `appliancemaintenanceslist-${applianceid}`;

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    const [maintenancestatus, setMaintenancestatus] = useState<Map<number | null, { label: string }>>();

    useEffect(() => {
        if (props.maintenancestatus) {
            setMaintenancestatus(getEnumerationMap(props.maintenancestatus));
        }
    }, [props.maintenancestatus]);

    const onRowClick = useCallback(() => {
        navStore.setListMapValue(
            listIdentifier,
            listParameters
                ? { ...listParameters, scrollPosition: window.pageYOffset }
                : { scrollPosition: window.pageYOffset }
        );

        navStore.setBackbutton(
            selectRoute(unitid ? Route.unitAppliance : Route.realestateAppliance, authStore.user?.role, {
                realestateid: realestateid,
                unitid: unitid,
                applianceid: applianceid
            }),
            t("screens.realestate.appliance.title")
        );
    }, [navStore, t, authStore.user?.role, realestateid, unitid, applianceid, listIdentifier, listParameters]);

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listIdentifier, listParameters]
    );

    const getEnumerationMap = (enumerations: Array<maintenancestatus>) => {
        const enumerationMap = new Map();

        enumerations.forEach((enumeration) => {
            if (enumeration.key !== null) {
                enumerationMap.set(enumeration.key, {
                    label: enumeration.label
                });
            }
        });

        return enumerationMap;
    };

    const getDate = (input: string) => {
        if (input) {
            return dateFormat(new Date(input), "dd.MM.yyyy");
        }
    };

    const renderItem = useCallback(
        (maintenance: maintenance, screenSize: ScreenSize) => {
            const maintenanceStatus = maintenancestatus?.get(maintenance.status)?.label;

            let listRows: React.ReactElement[] = [];
            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={0.4} value={getDate(maintenance.date)} />
                            <UI.List.Cell key={"c-2"} colspan={0.4} value={maintenanceStatus} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={maintenance.name ?? ""} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={maintenance.name ?? ""} />
                        </UI.List.Row>
                    ];
                    break;
            }

            const link = selectRoute(
                unitid ? Route.unitApplianceMaintenance : Route.realestateApplianceMaintenance,
                authStore.user?.role,
                {
                    realestateid: realestateid,
                    unitid: unitid,
                    applianceid: applianceid,
                    maintenanceid: maintenance.id
                }
            );

            return (
                <UI.List.Item
                    key={maintenance.id}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                    onClick={onRowClick}
                />
            );
        },
        [onRowClick, authStore.user?.role, realestateid, maintenancestatus, unitid, applianceid]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={0.4} value={t("screens.realestate.maintenance.date")} />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={0.4}
                                value={t("screens.realestate.maintenance.status")}
                            />
                            <UI.List.Cell key={"c-3"} colspan={1} value={t("screens.realestate.maintenance.name")} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={t("screens.realestate.maintenance.name")} />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} sticky={true} top={130} />;
        },
        [t]
    );

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={props.maintenances}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={25}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
        />
    );
};

export default observer(ApplianceMaintenances);
