import React, { useContext, useEffect, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_NEWS_PREVIEW_INFORMATION_BY_ID } from "src/api/news";
import {
    GetNewsPreviewInformationById,
    GetNewsPreviewInformationByIdVariables
} from "src/api/generated/GetNewsPreviewInformationById";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { UI } from "@wwimmo/ui";
import { getRoleKey } from "src/network/User";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { Route, selectRoute } from "src/config/routes";
import { runInAction } from "mobx";
import { useParams } from "react-router";
import { NewsPreview as NewsPreviewComponent } from "src/components/news/preview/NewsPreview";
import { NetworkConfig } from "src/network/NetworkConfig";
import { useHistory } from "react-router-dom";
import { MessageType } from "src/components/notifications/Notifier";
import { DELETE_NEWS_BY_ID } from "src/api/news";
import { DeleteNewsById, DeleteNewsByIdVariables } from "src/api/generated/DeleteNewsById";
import { NewsState } from "src/api/news";

import styles from "./NewsPreview.module.css";
import { NewsInfoTile } from "./NewsInfoTile";

interface Params {
    newsid: string;
    realestateid: string;
}

const NewsPreviewBase = () => {
    const { realestateid, newsid } = useParams<Params>();
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);
    const history = useHistory();

    useEffect(() => {
        runInAction(() => {
            requestAnimationFrame(() => {
                navStore.setTitle(t("screens.realestate.news_preview.title"));
                navStore.setBackbutton(
                    selectRoute(Route.dashboard, authStore.user?.role, { realestateid: realestateid }),
                    t("screens.realestate.dashboard.title")
                );
            });
        });
    }, [navStore, t, authStore.user, realestateid, newsid]);

    const printStatusMessage = useCallback(
        (message: string, msgType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: msgType,
                    autoClose: msgType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    const { data, loading, error, refetch } = useQuery<
        GetNewsPreviewInformationById,
        GetNewsPreviewInformationByIdVariables
    >(GET_NEWS_PREVIEW_INFORMATION_BY_ID, {
        variables: {
            newsid: newsid,
            realestateid: realestateid
        }
    });

    useEffect(() => {
        refetch();
    }, [refetch]);

    const [deleteNewsByIdMutation] = useMutation<DeleteNewsById, DeleteNewsByIdVariables>(DELETE_NEWS_BY_ID, {
        onCompleted: () => {
            printStatusMessage(t("screens.realestate.news_preview.delete_success"), MessageType.INFO);
        }
    });

    const hasActiveCoowners =
        data?.active_coowners_count.aggregate?.count !== undefined && data.active_coowners_count.aggregate?.count > 0;

    const hasActiveTenants =
        data?.active_tenants_count.aggregate?.count !== undefined && data.active_tenants_count.aggregate?.count > 0;

    const onNotifyNews = useCallback(async () => {
        if ((hasActiveCoowners || hasActiveTenants) && authStore.user && authStore.token) {
            const accessToken = authStore.token;
            const tokenType = authStore.tokenType;
            const role = authStore.user?.role;
            const requestBody = {
                newsid: newsid
            };
            try {
                const fetchResult = await fetch(`${NetworkConfig.insertNotificationUrl}`, {
                    method: "PUT",
                    body: JSON.stringify(requestBody),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    refetch();
                    printStatusMessage(
                        navStore.isCondominium
                            ? t("screens.realestate.news_preview.save_notification_success")
                            : t("screens.realestate.news_preview.save_notification_success_tenant"),
                        MessageType.INFO
                    );
                } else {
                    const returnValue = await fetchResult.json();
                    printStatusMessage(
                        t("screens.realestate.news_preview.save_notification_error", {
                            errorMessage: returnValue.message
                        }),
                        MessageType.ERROR
                    );
                }
            } catch (error: any) {
                printStatusMessage(
                    t("screens.realestate.news_preview.save_notification_error", {
                        errorMessage: error.message
                    }),
                    MessageType.ERROR
                );
            }
        }
    }, [
        newsid,
        authStore,
        printStatusMessage,
        t,
        refetch,
        hasActiveCoowners,
        navStore.isCondominium,
        hasActiveTenants
    ]);

    const onDeleteNews = useCallback(async () => {
        await deleteNewsByIdMutation({
            variables: {
                newsid: newsid
            }
        });

        uiStore.setHasNewNewsData(true);

        history.replace(
            selectRoute(navStore.isCondominium ? Route.dashboard : Route.news, authStore.user?.role, {
                realestateid: realestateid
            })
        );
    }, [authStore.user?.role, history, realestateid, deleteNewsByIdMutation, newsid, uiStore, navStore.isCondominium]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const news = data.news.length > 0 ? data.news[0] : undefined;

    const state = news?.state ? Number(news.state) : undefined;

    const imageUrl = news?.fileid ? NetworkConfig.openThumbnailUrl + news?.fileid : "";
    const hasNotificationEvents = news?.newsevents.events?.count && news?.newsevents.events?.count > 0 ? true : false;

    return (
        <UI.Container className="my-4">
            <UI.Row>
                <UI.Col md={8} className={`${styles.InfoPreviewContainer} mb-3 mb-md-0`}>
                    <NewsPreviewComponent
                        title={news?.title ?? ""}
                        lead={news?.lead ?? ""}
                        text={news?.text ?? ""}
                        date={news?.timestamp}
                        imageUrl={imageUrl}
                        className={styles.NewsPreviewStyles}
                    />
                </UI.Col>
                <UI.Col md={4}>
                    <UI.Card title={t("screens.realestate.news_preview.news_info_title")}>
                        <NewsInfoTile news={news} />
                    </UI.Card>
                </UI.Col>
            </UI.Row>
            <UI.Row>
                <UI.Col>
                    <div className={`${styles.NewsButtonContainer} mt-3`}>
                        {state === NewsState.Published ? (
                            <UI.ConfirmationDialog
                                key="notificationNewsDialog"
                                buttonText={t("labels.notification")}
                                modalTitle={t("labels.notification")}
                                confirmationQuestion={
                                    navStore.isCondominium
                                        ? hasActiveCoowners
                                            ? t("screens.realestate.news_preview.create_notification")
                                            : t("screens.realestate.news_preview.no_coowners_by_create_notification")
                                        : t("screens.realestate.news_preview.create_notification_tenant")
                                }
                                inProgressText={
                                    navStore.isCondominium
                                        ? t("screens.realestate.news_preview.create_notification_in_progress")
                                        : t("screens.realestate.news_preview.create_notification_in_progress_tenant")
                                }
                                onConfirmation={onNotifyNews}
                                buttonClasses={`${styles.NewsEventButton} d-inline mx-0 mx-md-2`}
                                isModalConfirmationButtonHidden={navStore.isCondominium ? !hasActiveCoowners : false}
                                modalNegationButtonLabel={
                                    navStore.isCondominium ? (hasActiveCoowners ? undefined : "Ok") : undefined
                                }
                            />
                        ) : undefined}
                        <UI.Button
                            className={styles.NewsEditButton}
                            variant="outline-primary"
                            label={t("labels.edit")}
                            to={selectRoute(Route.realestateNewsPreviewEdit, authStore.user?.role, {
                                realestateid: realestateid,
                                newsid: newsid,
                                newspreviewnewsid: newsid
                            })}
                        />
                        {hasNotificationEvents ? (
                            <UI.ConfirmationDialog
                                key="customerDeleteDialog"
                                buttonText={t("labels.delete")}
                                modalTitle={t("labels.delete")}
                                confirmationQuestion={
                                    navStore.isCondominium
                                        ? t("screens.realestate.news_preview.delete_confirmation")
                                        : t("screens.realestate.news_preview.delete_confirmation_tenant")
                                }
                                inProgressText={t("screens.realestate.news_preview.delete_in_progress")}
                                onConfirmation={onDeleteNews}
                                buttonClasses={`${styles.NewsDeleteButton} d-inline mx-0 mx-md-2`}
                                isDangerousOperation
                            />
                        ) : (
                            <UI.Button
                                className={`${styles.NewsDeleteButton} d-inline mx-0 mx-md-2`}
                                variant="danger"
                                label={t("labels.delete")}
                                onClick={onDeleteNews}
                            />
                        )}
                    </div>
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};

export const NewsPreview = Sentry.withProfiler(observer(NewsPreviewBase));
