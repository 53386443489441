import React from "react";
import { UI } from "@wwimmo/ui";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Invoice } from "src/stores/krediflow/InvoiceStore";
import { getInvoiceTypeIcon } from "src/screens/creditors/Creditors";
import { formatCurrency } from "src/utils/Currency";
import styles from "./InvoiceListRow.module.css";

export const InvoiceListRowMobile = (props: { invoice: Invoice }) => {
    const { number, date, invoiceType, realestate, assignedUser, creditor, amount } = props.invoice;

    const { t } = useTranslation();

    return (
        <UI.List.Row className={styles.InvoiceListRowMobile} key={`row-mobile-${number}`}>
            <div className={styles.MobileTop}>
                <div className={styles.TypeIconMobile}>
                    <img
                        src={getInvoiceTypeIcon(invoiceType)}
                        alt={t(`screens.kredi_flow.invoice_type.${String(invoiceType)}`) ?? "icon"}
                    />
                </div>
                <div className={styles.MobileLeft}>
                    <div>
                        <strong>
                            {number} -{" "}
                            {creditor?.name
                                ? creditor?.name
                                : t(`screens.kredi_flow.invoice_type.${String(invoiceType)}`)}
                        </strong>
                    </div>
                </div>
                <div className={styles.MobileRight}>
                    <div>{amount ? formatCurrency(amount) : "-"}</div>
                </div>
            </div>
            <div className={styles.MobileBottom}>
                {assignedUser && (
                    <div className={styles.Avatar}>{`${assignedUser.name1.charAt(0)}${
                        assignedUser.name2?.charAt(0) ?? ""
                    }`}</div>
                )}
                <div className={styles.MobileLeft}>
                    {realestate && (
                        <div>
                            <div>{realestate.name}</div>
                            <div>
                                {realestate.zip} {realestate.city}
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.MobileRight}>{date && format(new Date(date), "dd.MM.yy")}</div>
            </div>
        </UI.List.Row>
    );
};
