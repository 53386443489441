import { UI } from "@wwimmo/ui";
import React, { useCallback, useState } from "react";
import { ColorStyle } from "src/utils/Colors";

interface StarRatingControlProps {
    rating: number;
    nrOfStars: number;
    onChangeRating: (rate: number) => void;
    size: number;
    disabled: boolean;
}

export const StarRatingControl = (props: StarRatingControlProps) => {
    const [hoverIndex, setHoverIndex] = useState<number>(0);
    const { rating, nrOfStars, onChangeRating } = props;

    const onHoverIndex = useCallback(
        (newHoverIndex: number) => async (e: any) => {
            if (newHoverIndex >= rating) {
                setHoverIndex(newHoverIndex);
            }
        },
        [setHoverIndex, rating]
    );

    const onClickStar = useCallback(
        (newIndex: number) => async (e: any) => {
            setHoverIndex(newIndex);
            onChangeRating(newIndex);
        },
        [onChangeRating]
    );

    const onHoverEnd = useCallback(
        async (e: any) => {
            setHoverIndex(rating);
        },
        [setHoverIndex, rating]
    );

    if (nrOfStars <= 0) {
        return <React.Fragment />;
    }

    const renderStars = () => {
        let stars: any[] = [];
        for (let index = 1; index <= nrOfStars; index++) {
            stars.push(
                <div
                    key={index}
                    onMouseEnter={props.disabled ? undefined : onHoverIndex(index)}
                    onMouseLeave={props.disabled ? undefined : onHoverEnd}
                    style={{ width: props.size, height: props.size }}
                >
                    <UI.Icon
                        icon={UI.SVGIcon.StarFilled}
                        color={
                            index <= (hoverIndex || rating)
                                ? ColorStyle("primary")
                                : props.disabled
                                ? ColorStyle("#EFEEED")
                                : ColorStyle("grey")
                        }
                        onClick={props.disabled ? undefined : onClickStar(index)}
                        size={props.size}
                    />
                </div>
            );
        }
        return stars;
    };

    return (
        <div style={{ display: "flex", flexDirection: "row" }} className={"mt-1 mb-2"}>
            {renderStars()}
        </div>
    );
};
