import { useTranslation } from "react-i18next";
import { GET_ASSEMBLIES_BY_ID } from "src/api/assembly";
import { GetAssemblyByID, GetAssemblyByIDVariables } from "src/api/generated/GetAssemblyByID";
import { useQuery } from "@apollo/client";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { getDateWithLongMonth } from "src/utils/Date";
import * as Screens from "src/screens";
import styles from "./AssembliesDeadlines.module.css";
import { UI } from "@wwimmo/ui";

interface AssembliesDeadlinesProps {
    assemblyid: string;
}

interface Params {
    realestateid: string;
}

const AssemblyDeadlines = (props: AssembliesDeadlinesProps) => {
    const { realestateid } = useParams<Params>();
    const { t } = useTranslation();

    const { data, loading, error } = useQuery<GetAssemblyByID, GetAssemblyByIDVariables>(GET_ASSEMBLIES_BY_ID, {
        variables: {
            realestateid: realestateid,
            id: props.assemblyid
        }
    });

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    let eventDate: string | undefined;
    let motiondeadline = undefined;
    if (data.assemblyitems.length > 0 && data.assemblyitems[0].motiondeadline !== undefined) {
        motiondeadline = getDateWithLongMonth(data.assemblyitems[0].motiondeadline);
    }
    if (data.assemblyitems.length > 0 && data.assemblyitems[0].assemblyevents.length > 0) {
        eventDate = getDateWithLongMonth(data.assemblyitems[0].assemblyevents[0].inserted || "");
    } else {
        eventDate = undefined;
    }

    return (
        <UI.Card className={styles.AssemblyDeadlinesCard} title={t("screens.realestate.assembly.deadlines")}>
            {eventDate ? (
                <div className={styles.EventData}>
                    <div className={styles.CardDetails}>{t("screens.realestate.assembly.deadlineAnnounced")}</div>
                    <UI.Row className={styles.CardValues}>
                        <div>{eventDate}</div>
                    </UI.Row>
                </div>
            ) : undefined}
            {motiondeadline ? (
                <>
                    <div className={styles.CardDetails}>{t("screens.realestate.assembly.motiondeadline")}</div>
                    <UI.Row className={styles.CardValues}>
                        <div>{motiondeadline}</div>
                    </UI.Row>
                </>
            ) : undefined}
        </UI.Card>
    );
};

export default observer(AssemblyDeadlines);
