import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { getLang } from "src/utils/i18n";
import { GET_ASSEMBLIES_BY_REALESTATEID } from "src/api/assembly";
import { useQuery } from "@apollo/client";
import {
    GetAssembliesByRealestateid,
    GetAssembliesByRealestateidVariables
} from "src/api/generated/GetAssembliesByRealestateid";
import * as Screens from "src/screens";
import { RootStoreContext } from "src/stores/RootStore";
import { withRouter, RouteComponentProps, useParams } from "react-router";
import { Route, selectRoute } from "src/config/routes";
import { v4 as uuidv4 } from "uuid";
import styles from "./ManagerAssemblies.module.css";
import { ScreenWidth } from "src/stores/UIStore";
import AssembliesManagerList from "./list/AssembliesManagerList";
import { Tiles } from "src/components";

interface Params {
    realestateid: string;
}

const ManagerAssembliesBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { realestateid } = useParams<Params>();
    const { uiStore, authStore, navStore } = useContext(RootStoreContext);

    const { data, loading, error, refetch } = useQuery<
        GetAssembliesByRealestateid,
        GetAssembliesByRealestateidVariables
    >(GET_ASSEMBLIES_BY_REALESTATEID, {
        variables: {
            language: getLang(),
            realestateid: realestateid
        }
    });

    useEffect(() => {
        if (uiStore.hasNewAssembliesData) {
            refetch();
            uiStore.setHasNewAssembliesData(false);
        }
    }, [refetch, uiStore]);

    useEffect(() => {
        navStore.setBackbutton(
            selectRoute(Route.dashboard, authStore.user?.role, { realestateid: realestateid }),
            t("screens.realestate.dashboard.title")
        );
    }, [authStore.user?.role, realestateid, t, navStore]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const contactTile = <Tiles.ContactTile realestateid={realestateid} />;

    return (
        <UI.Container className="pt-4">
            <UI.Title>{t("screens.realestate.assemblies.title")}</UI.Title>
            <UI.Row>
                <UI.Col xl={9}>
                    <UI.Card>
                        <UI.Button
                            className={styles.ManagerAddAssemblyButton}
                            label={t("screens.realestate.assemblies.create_assembly")}
                            to={selectRoute(Route.managerAssembliesEdit, authStore.user?.role, {
                                realestateid: realestateid,
                                assemblyid: uuidv4()
                            })}
                        />
                        <AssembliesManagerList
                            assemblyItems={data.assemblyitems}
                            assemblyStatus={data.assemblystates}
                        />
                    </UI.Card>
                </UI.Col>
                {window.innerWidth >= ScreenWidth.XL ? (
                    <UI.Col xl={3}>{contactTile}</UI.Col>
                ) : (
                    <UI.Col sm={12}>{contactTile}</UI.Col>
                )}
            </UI.Row>
        </UI.Container>
    );
};

export const ManagerAssemblies = withRouter(Sentry.withProfiler(observer(ManagerAssembliesBase)));
