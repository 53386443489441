import React, { useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import "src/screens/realestates/realestate/finances/Finances.css";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useQuery } from "@apollo/client";
import { PortalAccountlistById, PortalAccountlistByIdVariables } from "src/api/generated/PortalAccountlistById";
import { GET_PORTAL_ACCOUNTLIST_BY_ID } from "src/api/finances";
import { getRoleKey, Role } from "src/network/User";
import { CollapsableAccountlist } from "./account/collapsableAccountlist/CollapsableAccountlist";
import { PortalAccountlistsByRealestateId } from "src/api/generated/PortalAccountlistsByRealestateId";
import { AccountlistDropdown } from "src/screens/realestates/realestate/finances/AccountlistDropdown";
import { useHistory } from "react-router-dom";
import { Route, selectRoute } from "src/config/routes";

interface Props {
    selectedlistid: any;
    realestateid: any;
    accountlistsByRealestateId: PortalAccountlistsByRealestateId;
}

const AccountlistBase = (props: Props) => {
    const { t } = useTranslation();
    const { authStore, uiStore } = useContext(RootStoreContext);

    const history = useHistory();
    const onAccountsSelectorChange = useCallback(
        (newId: string) => {
            uiStore.setFinanceListSelectionId(newId);
            history.push(
                selectRoute(Route.finances, authStore.user?.role, {
                    realestateid: props.realestateid,
                    accountlistid: newId
                })
            );
        },
        [uiStore, authStore.user?.role, history, props.realestateid]
    );

    const { loading, error, data } = useQuery<PortalAccountlistById, PortalAccountlistByIdVariables>(
        GET_PORTAL_ACCOUNTLIST_BY_ID,
        {
            variables: { id: props.selectedlistid },
            context: {
                headers: {
                    "x-hasura-role": authStore.user?.hasCoownerAuditorRole
                        ? getRoleKey(Role.AUDITOR)
                        : getRoleKey(authStore.user?.role)
                }
            }
        }
    );

    if (loading) {
        return <UI.LoadingCard />;
    }
    if (error) {
        return <UI.ErrorCard message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <UI.ErrorCard message={t("error.nodata")} />;
    }
    if (data.portal_accountlists.length === 0 || data.portal_accountlists[0].accounts.length === 0) {
        return (
            <>
                <div style={{ width: "50%" }}>
                    <AccountlistDropdown
                        realestateid={props.realestateid}
                        selectedlistid={props.selectedlistid}
                        data={props.accountlistsByRealestateId}
                        onChange={onAccountsSelectorChange}
                    />
                </div>
                <UI.Card>{t("error.emptydata")}</UI.Card>
            </>
        );
    }

    if (uiStore.financeListExpandedItems === undefined) {
        //Expand all on first time
        const items: string[] = [];
        data.portal_accountlists.forEach((list) => {
            list.accounts.forEach((account) => {
                items.push(account.id);
            });
        });
        uiStore.setFinanceListExpandedItems(items);
    }

    const getAccountlistIndexById = (id: string): number => {
        const i = data.portal_accountlists.findIndex((list) => list.id === id);
        if (i === -1) {
            return 0;
        }
        return i;
    };

    let accountList = data.portal_accountlists[getAccountlistIndexById(props.selectedlistid)].accounts;

    return (
        <CollapsableAccountlist
            realestateid={props.realestateid}
            selectedlistid={props.selectedlistid}
            accountList={accountList}
            accountlistsByRealestateId={props.accountlistsByRealestateId}
        />
    );
};

export const Accountlist = Sentry.withProfiler(observer(AccountlistBase));
