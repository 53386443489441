import { useCallback, useContext, useEffect, useState } from "react";
import { SUBCRIPTION_GET_LAST_INSERTED_ASSEMBLY_EVENT } from "src/api/assembly";
import {
    GetLastAssemblyEventById,
    GetLastAssemblyEventByIdVariables,
    GetLastAssemblyEventById_noti_assemblyevents
} from "src/api/generated/GetLastAssemblyEventById";
import { getDateWithLongMonthAndTime } from "src/utils/Date";
import { RootStoreContext } from "src/stores/RootStore";
import { MessageType } from "src/components/notifications/Notifier";
import { useTranslation } from "react-i18next";
import { apolloClientInstance } from "src/network/apolloClientInstance";

export const useAssemblyInsertedEventSubscription = (assemblyId: string, initialValue?: string) => {
    const { t } = useTranslation();
    const [insertedValueState, setInsertedValueState] = useState<string>(initialValue ?? "-");
    const [assemblyEventState, setAssemblyEventState] = useState<GetLastAssemblyEventById_noti_assemblyevents[]>([]);
    const { uiStore, assemblyStore } = useContext(RootStoreContext);
    const [assemblyInsertedEventStateSubscription, setAssemblyInsertedEventStateSubscription] =
        useState<any>(undefined);

    const printStatusMessage = useCallback(
        (message: string, msgType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: msgType,
                    autoClose: msgType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    const stopAssemblyInsertedEventStateSubscription = useCallback(() => {
        if (assemblyInsertedEventStateSubscription) {
            assemblyInsertedEventStateSubscription.unsubscribe();
            setAssemblyInsertedEventStateSubscription(null);
        }
    }, [assemblyInsertedEventStateSubscription]);

    const startAssemblyInsertedEventStateSubscription = useCallback((assemblyId: string) => {
        if (assemblyId && assemblyId !== "") {
            setAssemblyInsertedEventStateSubscription(
                apolloClientInstance
                    .subscribe<GetLastAssemblyEventById, GetLastAssemblyEventByIdVariables>({
                        query: SUBCRIPTION_GET_LAST_INSERTED_ASSEMBLY_EVENT,
                        variables: { assemblyid: assemblyId }
                    })
                    .subscribe({
                        next: ({ data }) => {
                            if (data?.noti_assemblyevents && data.noti_assemblyevents.length > 0) {
                                setAssemblyEventState(data.noti_assemblyevents);
                            }
                        },
                        error: (err) => {
                            console.error("SUBCRIPTION_GET_LAST_INSERTED_ASSEMBLY_EVENT subscription error:", err);
                        }
                    })
            );
        }
    }, []);

    useEffect(() => {
        if (assemblyInsertedEventStateSubscription === undefined && assemblyEventState.length === 0) {
            startAssemblyInsertedEventStateSubscription(assemblyId);
        } else {
            if (assemblyEventState.length > 0) {
                setInsertedValueState(assemblyEventState[0].inserted);
                if (assemblyStore.isAssemblyInsertedEventLoaded === false) {
                    printStatusMessage(
                        t("screens.realestate.news_preview.save_notification_success"),
                        MessageType.INFO
                    );
                    assemblyStore.setIsAssemblyInsertedEventLoaded(true);
                    stopAssemblyInsertedEventStateSubscription();
                }
            }
        }
    }, [
        assemblyEventState,
        assemblyStore,
        printStatusMessage,
        t,
        startAssemblyInsertedEventStateSubscription,
        assemblyInsertedEventStateSubscription,
        stopAssemblyInsertedEventStateSubscription,
        assemblyId
    ]);

    return insertedValueState !== "-" ? getDateWithLongMonthAndTime(insertedValueState) : insertedValueState;
};
