import { observable, action, makeObservable, computed } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { IPaymentAccount } from "./InvoiceTypes";
import { Invoice } from "./InvoiceStore";
import { INVOICE_PAYMENT_INFO_PROPERTY, INVOICE_PROPERTY } from "./InvoiceEnums";
import { isEmpty } from "lodash";

export class PaymentAccountSearchStore {
    rootStore: RootStore;

    paymentAccountData: IPaymentAccount[] = [];
    selectedPaymentAccountQueryString: string | undefined = undefined;
    isSearchingPaymentAccountData: boolean = false;

    currentSelectedIBAN: string = "";
    isCurrentIBANValid: boolean = true;
    isReferenceNumberValid: boolean = true;

    triggerFocusOnPaymentAccountInput: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            paymentAccountData: observable,
            triggerFocusOnPaymentAccountInput: observable,
            selectedPaymentAccountQueryString: observable,
            isSearchingPaymentAccountData: observable,
            currentSelectedIBAN: observable,
            isCurrentIBANValid: observable,
            isReferenceNumberValid: observable,

            setSelectedPaymentAccountQueryString: action,
            setTriggerFocusOnPaymentAccountInput: action,
            setIsSearchingPaymentAccountData: action,
            setPaymentAccountData: action,
            setCurrentSelectedIBAN: action,
            updateInvoiceStoreWithSelectedPaymentAccount: action,
            updateInvoiceStoreWithManuallyEnteredIBAN: action,
            setIsCurrentIBANValid: action,
            setIsReferenceNumberValid: action,

            filteredPaymentAccountData: computed
        });
    }

    init = (invoice: Invoice) => {
        if (invoice.creditor && invoice.creditor.paymentAccounts) {
            this.setPaymentAccountData(invoice.creditor.paymentAccounts);
        }

        if (invoice.paymentAccount && invoice.paymentAccount.iban) {
            this.setCurrentSelectedIBAN(invoice.paymentAccount.iban);
        } else if (invoice.paymentInfo && invoice.paymentInfo.iban) {
            this.setCurrentSelectedIBAN(invoice.paymentInfo.iban);
        }
    };

    // A payment account was selected from the search results
    // For one the 'paymentaccountid' and 'paymentaccount' variables will the set on the current invoice
    // For the other the 'paymentinfo.iban' will be reset since there should only one iban defined per invoice
    updateInvoiceStoreWithSelectedPaymentAccount = (paymentAccount: IPaymentAccount | undefined) => {
        if (paymentAccount) {
            this.setCurrentSelectedIBAN(paymentAccount.iban ?? "");

            if (this.rootStore.invoiceStore.currentInvoice) {
                this.rootStore.invoiceStore.currentInvoice.updateProperty(
                    INVOICE_PROPERTY.PAYMENT_ACCOUNT_ID,
                    paymentAccount.id
                );
                this.rootStore.invoiceStore.currentInvoice.updateProperty(
                    INVOICE_PROPERTY.PAYMENT_ACCOUNT,
                    paymentAccount
                );

                if (
                    !isEmpty(this.rootStore.invoiceStore.currentInvoice.paymentInfo) &&
                    this.rootStore.invoiceStore.currentInvoice.paymentInfo.iban
                ) {
                    this.rootStore.invoiceStore.currentInvoice.updatePaymentInfoProperty(
                        INVOICE_PAYMENT_INFO_PROPERTY.IBAN,
                        undefined
                    );
                }
            }
        }
    };

    updateInvoiceStoreWithManuallyEnteredIBAN = (iban: string) => {
        this.rootStore.invoiceStore.currentInvoice?.validate();

        this.setCurrentSelectedIBAN(iban);

        if (this.rootStore.invoiceStore.currentInvoice) {
            this.rootStore.invoiceStore.currentInvoice.updateProperty(INVOICE_PROPERTY.PAYMENT_ACCOUNT_ID, undefined);
            this.rootStore.invoiceStore.currentInvoice.updateProperty(INVOICE_PROPERTY.PAYMENT_ACCOUNT, undefined);

            this.rootStore.invoiceStore.currentInvoice.updatePaymentInfoProperty(
                INVOICE_PAYMENT_INFO_PROPERTY.IBAN,
                iban
            );
        }
    };

    get filteredPaymentAccountData(): IPaymentAccount[] {
        let filteredPaymentAccountData: IPaymentAccount[] = [...this.paymentAccountData];

        const searchQuery = this.currentSelectedIBAN ?? "";

        if (searchQuery === "") {
            return filteredPaymentAccountData;
        }

        const filteredPaymentAccountDataBySearchQuery = filteredPaymentAccountData.filter((paymentAccount) => {
            return (
                paymentAccount.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                paymentAccount.iban?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        });

        return filteredPaymentAccountDataBySearchQuery;
    }

    // Setters
    setIsSearchingPaymentAccountData = (isSearchingPaymentAccountData: boolean) => {
        this.isSearchingPaymentAccountData = isSearchingPaymentAccountData;
    };

    setSelectedPaymentAccountQueryString = (selectedPaymentAccountQueryString: string | undefined) => {
        this.selectedPaymentAccountQueryString = selectedPaymentAccountQueryString;
    };

    setTriggerFocusOnPaymentAccountInput = (triggerFocusOnPaymentAccountInput: boolean) => {
        this.triggerFocusOnPaymentAccountInput = triggerFocusOnPaymentAccountInput;
    };

    setPaymentAccountData = (paymentAccountData: IPaymentAccount[]) => {
        this.paymentAccountData = paymentAccountData;
    };

    setCurrentSelectedIBAN = (iban: string) => {
        this.currentSelectedIBAN = iban;
    };

    setIsCurrentIBANValid = (isValid: boolean) => {
        this.isCurrentIBANValid = isValid;
    };

    setIsReferenceNumberValid = (isValid: boolean) => {
        this.isReferenceNumberValid = isValid;
    };
}
