import React, { useContext } from "react";
import { Ticket } from "src/stores/TicketStore";
import styles from "./TicketsKanban.module.css";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { Route, selectRoute } from "src/config/routes";
import { RootStoreContext } from "src/stores/RootStore";
import { getIcon } from "src/screens/tickets/Tickets";
import { UserAvatar } from "src/components/userAvatar/UserAvatar";

interface TicketCardProps {
    index: number;
    ticket: Ticket;
}

export const TicketCard = (props: TicketCardProps) => {
    const { number, date, workflow_type, appliance, realestate, assignedUser, title } = props.ticket;
    const { authStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    return (
        <Draggable draggableId={String(number) ?? "-"} index={props.index}>
            {(provided, snapshot) => (
                <div
                    className={`${styles.TicketCard} ${snapshot.isDragging && styles.IsDragging}`}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <div className="d-flex justify-content-between mb-4">
                        <div className={styles.Title}>
                            <Link
                                to={
                                    number
                                        ? selectRoute(Route.ticketDetails, authStore.user?.role, {
                                              ticketnumber: String(number)
                                          })
                                        : Route.tickets
                                }
                            >
                                <strong>
                                    {`${number} - ${
                                        title === "" ? t(`easyticket.workflow_types.${String(workflow_type)}`) : title
                                    }`}
                                </strong>
                            </Link>
                            <br />
                            {appliance && <div>{appliance.name}</div>}
                        </div>
                        <div>
                            <img
                                className={styles.CardIcon}
                                src={getIcon(workflow_type)}
                                alt={t(`easyticket.workflow_types.${String(workflow_type)}`) ?? "icon"}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div>
                            {realestate && (
                                <div>
                                    <div>{realestate.name}</div>
                                    <div>
                                        {realestate.zip} {realestate.city}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>{/* TODO: Action Button */}</div>
                    </div>
                    <hr className={styles.Divder} />
                    <div className="d-flex justify-content-between">
                        <div>
                            {assignedUser && <UserAvatar name1={assignedUser.name1} name2={assignedUser.name2} />}
                        </div>
                        <div>{Ticket.formatDate(date ?? format(new Date(), "YYYY-MM-DD"))}</div>
                    </div>
                </div>
            )}
        </Draggable>
    );
};
