import { observable, action, makeObservable, runInAction } from "mobx";
import {
    QUESTION_TO_DOCUMENT_NOTIFICATION_PROPERTY,
    IComponent,
    LOADING_TYPE,
    TICKET_PROPERTY
} from "src/stores/TicketStore";
import { RootStore } from "src/stores/RootStore";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { GET_COMPONENT_DATA } from "src/api/ticket";
import {
    GetComponentData,
    GetComponentDataVariables,
    GetComponentData_components
} from "src/api/generated/GetComponentData";
import i18next from "i18next";

const QUESTION_TO_DOCUMENT_ROOT_COMPONENTS_PARENT_ID = "b753e048-71c2-473a-4f51-97f70a5d3997";
const OTHER_KEY_COMPONENT_ID = "b753e048-71c2-473a-4f51-97f70a5d4002";

export class QuestionToDocumentNotificationStore {
    rootStore: RootStore;

    componentData: GetComponentData_components[] = [];
    formComponentTreeData: IComponent[] = [];
    isComponentSelectionFinished: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            componentData: observable,
            formComponentTreeData: observable,
            isComponentSelectionFinished: observable,

            init: action,
            loadComponentData: action,
            resetComponentSelection: action,
            initializeFormComponentTreeData: action,
            setFormComponentTreeData: action,
            setIsComponentSelectionFinished: action,
            handleClickOnComponentTreeElement: action,
            handleEditComponentTreeAfterFinishedSelection: action
        });
    }

    init = async () => {
        await this.loadComponentData();
        this.initializeFormComponentTreeData();
    };

    loadComponentData = async () => {
        this.rootStore.ticketStore.setLoadingType(LOADING_TYPE.TICKET);

        const { data }: { data: GetComponentData } = await apolloClientInstance.query<
            GetComponentData,
            GetComponentDataVariables
        >({
            query: GET_COMPONENT_DATA,
            variables: {
                language: i18next.language
            }
        });

        runInAction(() => {
            this.componentData = data.components;
        });

        this.rootStore.ticketStore.setLoadingType(LOADING_TYPE.NONE);
    };

    getIComponentObject = (component: GetComponentData_components, isSelected: boolean): IComponent => {
        let childComponentsIds: string[] = [];

        if (component.child_components.length > 0) {
            component.child_components.forEach((childComponent) => {
                childComponentsIds.push(childComponent.id);
            });
        }

        return {
            id: component.id,
            parentid: component.parentid,
            type: component.type,
            parameters: component.parameters,
            title: component.component_mls[0].title,
            childComponentsIds: childComponentsIds,
            isSelected: isSelected
        };
    };

    initializeFormComponentTreeData = () => {
        this.formComponentTreeData = [];

        this.componentData.forEach((component) => {
            if (component.parentid === QUESTION_TO_DOCUMENT_ROOT_COMPONENTS_PARENT_ID) {
                let isSelected = false;

                if (component.id === this.rootStore.ticketStore.currentTicket?.componentid) {
                    isSelected = true;
                    this.setIsComponentSelectionFinished(true);
                }

                if (this.rootStore.ticketStore.currentTicket?.componentid === OTHER_KEY_COMPONENT_ID) {
                    this.rootStore.ticketStore.currentTicket?.updateQuestionToDocumentNotificationProperty(
                        QUESTION_TO_DOCUMENT_NOTIFICATION_PROPERTY.IS_OTHER_DOCUMENT_COMPONENT_SELECTED,
                        true
                    );
                }

                this.formComponentTreeData.push(this.getIComponentObject(component, isSelected));
            }
        });
    };

    handleClickOnComponentTreeElement = (component: IComponent) => {
        const newComponenetSelectedValue = !component.isSelected;

        if (newComponenetSelectedValue === true) {
            this.setIsComponentSelectionFinished(true);
        }

        if (component.id === OTHER_KEY_COMPONENT_ID) {
            this.rootStore.ticketStore.currentTicket?.updateQuestionToDocumentNotificationProperty(
                QUESTION_TO_DOCUMENT_NOTIFICATION_PROPERTY.IS_OTHER_DOCUMENT_COMPONENT_SELECTED,
                newComponenetSelectedValue
            );
        }

        this.rootStore.ticketStore.currentTicket?.updateProperty(TICKET_PROPERTY.COMPONENTID, component.id);

        component.isSelected = newComponenetSelectedValue;
    };

    handleEditComponentTreeAfterFinishedSelection = () => {
        this.setIsComponentSelectionFinished(false);

        this.formComponentTreeData.forEach((component) => {
            if (component.isSelected) {
                component.isSelected = false;
            }
        });

        this.rootStore.ticketStore.currentTicket?.updateQuestionToDocumentNotificationProperty(
            QUESTION_TO_DOCUMENT_NOTIFICATION_PROPERTY.IS_OTHER_DOCUMENT_COMPONENT_SELECTED,
            false
        );

        this.rootStore.ticketStore.currentTicket?.updateQuestionToDocumentNotificationProperty(
            QUESTION_TO_DOCUMENT_NOTIFICATION_PROPERTY.OTHER_DOCUMENT_NAME,
            undefined
        );

        this.rootStore.ticketStore.currentTicket?.updateProperty(TICKET_PROPERTY.COMPONENTID, undefined);
    };

    resetComponentSelection = () => {
        this.isComponentSelectionFinished = false;
        this.initializeFormComponentTreeData();
    };

    resetQuestionToDocumentFormFields = () => {
        this.resetComponentSelection();
    };

    setFormComponentTreeData = (formComponentTreeData: IComponent[]) => {
        this.formComponentTreeData = formComponentTreeData;
    };

    setIsComponentSelectionFinished = (isComponentSelectionFinished: boolean) => {
        this.isComponentSelectionFinished = isComponentSelectionFinished;
        this.rootStore.ticketStore.currentTicket?.validateQuestionToDocumentComponentSelected();
    };
}
