import { useCallback, useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./Service7000Submit.module.css";
import generalStyles from "src/screens/tickets/ticket/details/actions/assignOrder/AssignOrderModal.module.css";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { ORDER_CREATION_STATUS } from "src/stores/tickets/AssignOrderModalStore";

export const Service7000SubmitBase = () => {
    const { t } = useTranslation();
    const { uiStore, assignOrderModalStore, ticketStore } = useContext(RootStoreContext);

    const onClickCreateOrder = useCallback(() => {
        if (assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.FAILURE) {
            if (assignOrderModalStore.orderId?.length > 0) {
                assignOrderModalStore.retryOrderSubmitFromModal();
            } else {
                assignOrderModalStore.createOrder();
            }
        } else {
            assignOrderModalStore.setIsModalDisplayed(false);
            assignOrderModalStore.resetAssignOrderModal();
        }
    }, [assignOrderModalStore]);

    const onClickCancel = useCallback(() => {
        assignOrderModalStore.setIsModalDisplayed(false);
        assignOrderModalStore.resetAssignOrderModal();
    }, [assignOrderModalStore]);

    let icon;
    let text;

    switch (assignOrderModalStore.currentOrderCreationStatus) {
        case ORDER_CREATION_STATUS.IN_PROGRESS:
        case ORDER_CREATION_STATUS.SUBMITTING:
            icon = <UI.RotatingSpinner noLogo size={50} className="loading-items-spinner" />;
            text = t("screens.tickets.modals.assign_order.order_creation_in_progress").toString();
            break;
        case ORDER_CREATION_STATUS.SUCCESS:
            icon = <UI.Icon icon={UI.SVGIcon.Check} size={50} color={"black"} />;
            text = (
                <>
                    <div>{t("screens.tickets.modals.assign_order.service7000_order_submitted_title")}</div>
                    <div>
                        {t("screens.tickets.modals.assign_order.service7000_order_submitted_text", {
                            contact_person:
                                ticketStore.currentTicket?.incidentContactPerson?.name2?.length === undefined ||
                                ticketStore.currentTicket?.incidentContactPerson?.name2?.length > 0
                                    ? ticketStore.currentTicket?.incidentContactPerson?.name1 +
                                      " " +
                                      ticketStore.currentTicket?.incidentContactPerson?.name2
                                    : ticketStore.currentTicket?.incidentContactPerson?.name1
                        })}
                    </div>
                </>
            );
            break;
        default:
            icon = <UI.Icon icon={UI.SVGIcon.Error} size={50} color={"black"} />;
            text = t("screens.tickets.modals.assign_order.errors.could_not_submit");
            break;
    }

    return (
        <UI.Row className={"mt-2 d-flex"}>
            <UI.Col
                xs={12}
                className={`d-flex align-items-center justify-content-center ${styles.Service7000SubmitContainer}`}
            >
                <div className="d-flex align-items-center flex-column">
                    <div>{icon}</div>
                    <div className="d-flex mt-2">
                        <span className={`${styles.Service7000SubmitText} text-center`}>{text}</span>
                    </div>
                </div>
            </UI.Col>
            <UI.Col xs={12} className="mt-4">
                <UI.Row className={`mt-2 d-flex ${uiStore.isMobile ? "flex-column-reverse" : ""}`}>
                    {assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.FAILURE ? (
                        <UI.Col xs={12} md={6}>
                            <UI.Button
                                className={`ticket-button mt-2 ${generalStyles.BackButton}`}
                                label={t("labels.cancel")}
                                variant={"outline-primary"}
                                onClick={onClickCancel}
                            ></UI.Button>
                        </UI.Col>
                    ) : (
                        <></>
                    )}
                    <UI.Col
                        xs={12}
                        md={assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.FAILURE ? 6 : 12}
                    >
                        {assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.SUCCESS ||
                        assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.FAILURE ? (
                            <UI.Button
                                className={`ticket-button mt-2  ${generalStyles.NextButton}`}
                                label={
                                    assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.FAILURE
                                        ? t("labels.retry")
                                        : t("labels.ok")
                                }
                                onClick={onClickCreateOrder}
                            ></UI.Button>
                        ) : (
                            <UI.Button
                                className={`ticket-button mt-2 ${generalStyles.BackButton}`}
                                label={t("labels.cancel")}
                                variant={"outline-primary"}
                                onClick={onClickCancel}
                            ></UI.Button>
                        )}
                    </UI.Col>
                </UI.Row>
            </UI.Col>
        </UI.Row>
    );
};

export const Service7000Submit = Sentry.withProfiler(observer(Service7000SubmitBase));
