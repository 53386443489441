import { Appheader } from "src/components/header/appheader/Appheader";
import { ActionButtons } from "src/components/header/actionButtons/ActionButtons";
import { AppbarButtons } from "src/components/header/appbarButtons/AppbarButtons";
import { Backnavigation } from "src/components/header/backnavigation/Backnavigation";
import { Navigationbar } from "src/components/header/navigationbar/Navigationbar";
import { Searchbar } from "src/components/header/searchbar/Searchbar";
import { Close } from "src/components/header/close/Close";

export const Header = {
    Appheader,
    ActionButtons,
    AppbarButtons,
    Backnavigation,
    Navigationbar,
    Searchbar,
    Close
};
