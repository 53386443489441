export interface Colors {
    primary: string;
    primary100: string;
    primary400: string;
    primary900: string;
    additionalColor: string;
    additionalColor2?: string;
}
export const defaultColors = {
    standard: {
        secondaryLight: "#E3E4E6",
        onPrimary: "#ffffff",
        onSecondary: "#000000",
        background: "#f0f1f2",
        line: "#E3E4E6"
    }
};
export interface AllColors {
    ww: Colors;
    "1": Colors;
    "2": Colors;
    "3": Colors;
    "4": Colors;
    "5": Colors;
    "6": Colors;
}

export const allColors: AllColors = {
    ww: {
        primary: "#004997",
        primary100: "#B3CCFF",
        primary400: "#91c0f2",
        primary900: "#00254d",
        additionalColor: "#606366",
        additionalColor2: "#5189c5",
        ...defaultColors.standard
    },
    "1": {
        primary: "#1F7080",
        primary100: "#B6D3D9",
        primary400: "#2EAAC2",
        primary900: "#12454F",
        additionalColor: "#cad3d4",
        additionalColor2: "#83989c",
        ...defaultColors.standard
    },
    "2": {
        primary: "#61801F",
        primary100: "#CED9B6",
        primary400: "#719524",
        primary900: "#4F671A",
        additionalColor: "#d1d4ca",
        additionalColor2: "#949c83",
        ...defaultColors.standard
    },
    "3": {
        primary: "#801A1A",
        primary100: "#D9ADAD",
        primary400: "#B12223",
        primary900: "#601213",
        additionalColor: "#d5c4c4",
        additionalColor2: "#9f7a7a",
        ...defaultColors.standard
    },
    "4": {
        primary: "#C21D2E",
        primary100: "#FFBFC6",
        primary400: "#D92E40",
        primary900: "#A91827",
        additionalColor: "#3b2427",
        additionalColor2: "#c29ca0",
        ...defaultColors.standard
    },
    "5": {
        primary: "#E68600",
        primary100: "#FFD599",
        primary400: "#FB9200",
        primary900: "#C97500",
        additionalColor: "#4d381a",
        additionalColor2: "#d9b98d",
        ...defaultColors.standard
    },
    "6": {
        primary: "#004997",
        primary100: "#F0F1F2",
        primary400: "#E3E4E6",
        primary900: "#606366",
        additionalColor: "#606366",
        additionalColor2: "#00254d",
        ...defaultColors.standard
    }
};
