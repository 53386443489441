import React, { useState, useCallback, useContext } from "react";
import { Line as LineChartjs } from "react-chartjs-2";
import { ChartConfig } from "src/config/charts";
import { ChartDataType, formatValue } from "src/components/tiles/Tile";
import { ChartLegendLabelItem } from "chart.js";
import { RootStoreContext } from "src/stores/RootStore";

interface StackedAreaProps {
    data: LineData;
    colors: string[];
}

interface ChartElement {
    chart: any;
}

interface LineData {
    labels: string[];
    datasets: {
        label: string;
        hint?: string;
        data: number[];
        pointBackgroundColor?: string;
        pointRadius?: number;
    }[];
    valuetype: ChartDataType;
}

export const StackedArea = (props: StackedAreaProps) => {
    const { uiStore } = useContext(RootStoreContext);

    const [dimension, setDimension] = useState({
        width: undefined,
        height: undefined
    });

    const ref = useCallback((node) => {
        if (node !== null) {
            setDimension({
                width: node.getBoundingClientRect().width,
                height: node.getBoundingClientRect().height
            });
        }
    }, []);

    const legendPosition: "bottom" = "bottom";

    const options = {
        legend: {
            display: uiStore.isLegendDisplayed,
            position: legendPosition,
            onClick: function (this: ChartElement, event: MouseEvent, legendItem: ChartLegendLabelItem) {
                let datasetIndex = Number(legendItem.datasetIndex);
                let chart = this.chart;
                let index, datasets, meta;

                for (index = 0, datasets = (chart.config.data.datasets || []).length; index < datasets; ++index) {
                    meta = chart.getDatasetMeta(index);
                    if (!isNaN(datasetIndex) && meta.dataset._datasetIndex === datasetIndex) {
                        meta.hidden = !meta.hidden;
                    }
                }

                chart.update();
            },
            labels: {
                boxWidth: 12,
                lineWidth: 0,
                generateLabels: function (chart: any) {
                    const labels: ChartLegendLabelItem[] = [];

                    if (chart.config.data.datasets && chart.config.data.datasets.length > 0) {
                        chart.config.data.datasets.forEach((dataset: any, index: number) => {
                            let meta = chart.getDatasetMeta(index);

                            labels.push({
                                text: dataset.label,
                                fillStyle: dataset.backgroundColor,
                                lineWidth: 0,
                                datasetIndex: index,
                                hidden: meta.hidden
                            });
                        });
                    }
                    return labels;
                }
            }
        },
        cutoutPercentage: 70,
        animation: {
            animateScale: true,
            animateRotate: true,
            duration: ChartConfig.animationDuration
        },
        tooltips: {
            enabled: true,
            displayColors: true,
            callbacks: {
                title: function (tooltipItem: any, data: any) {
                    let datasetLabel =
                        data.datasets[tooltipItem[0].datasetIndex].hint ??
                        data.datasets[tooltipItem[0].datasetIndex].label;

                    if (!datasetLabel) {
                        datasetLabel = "";
                    }

                    const xAxisValue = data.labels[tooltipItem[0].index];
                    return datasetLabel ? `${datasetLabel} ${xAxisValue}` : xAxisValue;
                },
                label: (tooltipItem: any, data: any) => {
                    const dataValue = Number(tooltipItem.value);

                    if (!isNaN(dataValue)) {
                        return formatValue(Number(tooltipItem.value), data.valuetype);
                    } else {
                        return "";
                    }
                },
                labelColor: function (tooltipItem: any, chart: any) {
                    const color = props.colors[tooltipItem.datasetIndex];
                    return {
                        backgroundColor: color,
                        borderColor: color
                    };
                }
            },
            intersect: false
        },
        hover: {
            mode: undefined
        },
        scales: {
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        suggestedMax: 10,
                        callback: function (value: number, index: number, values: any) {
                            if (props.data.valuetype === ChartDataType.AMOUNT) {
                                return Number(value).toFixed(2);
                            } else {
                                return value + (props.data.valuetype === ChartDataType.PERCENTAGE ? "%" : "");
                            }
                        }
                    }
                }
            ],
            xAxes: [
                {
                    ticks: {
                        maxTicksLimit: 7,
                        maxRotation: 45,
                        minRotation: 0
                    }
                }
            ]
        },
        plugins: {
            datalabels: {
                display: () => false
            }
        }
    };

    return (
        <div className="Line" ref={ref}>
            {dimension.width && dimension.height && (
                <LineChartjs height={dimension.height} width={dimension.width} data={props.data} options={options} />
            )}
        </div>
    );
};
