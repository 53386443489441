import React, { useState, useCallback } from "react";
import { Line as LineChartjs } from "react-chartjs-2";
import { ChartConfig } from "src/config/charts";
import { ChartDataType, formatValue } from "src/components/tiles/Tile";

interface LineProps {
    data: LineData;
    colors: string[];
}

interface LineData {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        pointBackgroundColor?: string;
        pointRadius?: number;
    }[];
    valuetype: ChartDataType;
}

export const Line = (props: LineProps) => {
    const [dimension, setDimension] = useState({
        width: undefined,
        height: undefined
    });
    const ref = useCallback((node) => {
        if (node !== null) {
            setDimension({
                width: node.getBoundingClientRect().width,
                height: node.getBoundingClientRect().height
            });
        }
    }, []);
    const options = {
        legend: {
            display: false
        },
        cutoutPercentage: 70,
        animation: {
            animateScale: true,
            animateRotate: true,
            duration: ChartConfig.animationDuration
        },
        tooltips: {
            enabled: true,
            callbacks: {
                title: function (tooltipItem: any, data: any) {
                    return data.labels[tooltipItem[0].index];
                },
                label: (tooltipItem: any, data: any) => {
                    if (data.datasets[0].data[tooltipItem.index] > 0) {
                        return formatValue(data.datasets[0].data[tooltipItem.index], data.valuetype);
                    } else {
                        return `${data.datasets[0].data[tooltipItem.index]}`;
                    }
                }
            }
        },
        hover: {
            mode: undefined
        },
        scales: {
            yAxes: [
                {
                    scaleLabel: {
                        display: false,
                        labelString: "in %"
                    },
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        suggestedMax: 10,
                        callback: function (value: number, index: number, values: any) {
                            return value + (props.data.valuetype === ChartDataType.PERCENTAGE ? "%" : "");
                        }
                    }
                }
            ],
            xAxes: [
                {
                    ticks: {
                        maxTicksLimit: 7,
                        maxRotation: 45,
                        minRotation: 0
                    }
                }
            ]
        },
        plugins: {
            datalabels: {
                display: () => false
            }
        }
    };

    props.data.datasets.map((dataset) => {
        if (!dataset.pointBackgroundColor)
            dataset.pointBackgroundColor = props.colors.length !== 0 ? props.colors[0] : "red";
        if (!dataset.pointRadius) dataset.pointRadius = 5;
        return dataset;
    });

    return (
        <div className="Line" ref={ref}>
            {dimension.width && dimension.height && (
                <LineChartjs height={dimension.height} width={dimension.width} data={props.data} options={options} />
            )}
        </div>
    );
};
