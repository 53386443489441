import { UI } from "@wwimmo/ui";
import { useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { ORDER_LOADING_TYPE, ORDER_STATE } from "src/stores/OrderStore";
import styles from "./CloseOrderButton.module.css";

const CloseOrderButtonBase = () => {
    const { orderStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const onClickCloseTicketButton = useCallback(async () => {
        await orderStore.closeCurrentOrder();
    }, [orderStore]);

    const closeTicketButtonLabel = (
        <div className="d-flex align-items-center position-relative">
            <div>{t("screens.tickets.close")}</div>
            <UI.RotatingSpinner
                className={`${styles.ButtonSpinner} ${
                    orderStore.loadingType !== ORDER_LOADING_TYPE.CLOSING_ORDER ? "d-none" : ""
                }`}
                noLogo={true}
                size={20}
            />
        </div>
    );

    return (
        <UI.Button
            label={closeTicketButtonLabel}
            onClick={onClickCloseTicketButton}
            disabled={orderStore.currentOrder?.state === ORDER_STATE.DONE}
            className="ticket-button"
        />
    );
};

export const CloseOrderButton = Sentry.withProfiler(observer(CloseOrderButtonBase));
