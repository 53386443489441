import React, { useCallback, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { ScreenWidth } from "src/stores/UIStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { LOADING_TYPE, PROCESS, TICKET_ORIGIN, TICKET_PROPERTY, TICKET_STATE, Ticket } from "src/stores/TicketStore";
import { ColorStyle } from "src/utils/Colors";
import styles from "./TicketForm.module.css";
import { NetworkConfig } from "src/network/NetworkConfig";
import { RealestateFormField } from "./fields/RealestateFormField";
import { UnitFormField } from "./fields/UnitFormField";
import { TicketActionMobileButton } from "src/screens/tickets/ticket/details/actions/TicketActionMobileButton";
import { PersonFormFields } from "./fields/PersonFormFields";
import { DamageNotificationFormFields } from "./fields/DamageNotificationFormFields";
import { CommonRequestNotificationFormFields } from "./fields/CommonRequestNotificationFormFields";
import { NamesignNotificationFormFields } from "./fields/NamesignNotificationFormFields";
import { KeyOrderNotificationFormFields } from "./fields/KeyOrderNotificationFormFields";
import { QuestionToDocumentFormFields } from "./fields/QuestionToDocumentNotificationFormFields";
import { useSaveUnsavedChangesInForm } from "src/hooks/save-unsaved-changes-in-form/useSaveUnsavedChangesInForm";
import { FORM_STATUS } from "src/utils/Form";
import { useSaveFormByShortcut } from "src/hooks/form/useSaveFormByShortcut";
import { MessageType } from "src/components/notifications/Notifier";

export enum ERROR_TYPE {
    NONE = 0,
    REALESTATE = 1
}

const TicketFormBase = () => {
    const { t } = useTranslation();
    const { navStore, ticketStore, uiStore } = useContext(RootStoreContext);

    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        navStore.setTitle("");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);
    }, [navStore]);

    const onChangeTicketField = useCallback(
        (ticketProperty: TICKET_PROPERTY) => (e: any) => {
            if (ticketStore.currentTicket) {
                let newPropertyValue: string | number = e.target.value;

                if (ticketProperty === TICKET_PROPERTY.WORKFLOW_TYPE) {
                    newPropertyValue = Number(e.target.value);
                }

                ticketStore.currentTicket.updateProperty(ticketProperty, newPropertyValue);
            }
        },
        [ticketStore.currentTicket]
    );

    const saveForm = useCallback(
        (closeEditModeAfterSave: boolean = true) =>
            async (e: any) => {

                if (isSaving) {
                    return;
                }
                setIsSaving(true);
                if (ticketStore.isEditing) {
                    if (e) {
                        e.preventDefault();
                    }

                    const wasSuccessfullyValidated = ticketStore.currentTicket?.validate();

                    if (wasSuccessfullyValidated) {
                        await ticketStore.saveCurrentTicket(closeEditModeAfterSave);
                        setIsSaving(false);
                    } else {
                        if (
                            !ticketStore.currentTicket?.isNewTicket &&
                            ticketStore.currentTicket?.realestate === undefined
                        ) {
                            uiStore.printStatusMessage(
                                t("screens.tickets.form.errors.realestate_missing"),
                                MessageType.ERROR
                            );
                        }

                        setIsSaving(false);
                    }
                }
            },
        [ticketStore, uiStore, t, isSaving]
    );

    const { saveFormModal, closeSaveFormModalAndGoBack } = useSaveUnsavedChangesInForm({
        saveFormFunction: saveForm(true),
        currentFormStatus:
            ticketStore.loadingType === LOADING_TYPE.SAVING_TICKET ? FORM_STATUS.SAVING_FORM : FORM_STATUS.NONE,
        isFormDirty: ticketStore.currentTicket?.hasChanges ?? false
    });

    useSaveFormByShortcut({
        saveFormFunction: saveForm(false),
        isFormDirty: ticketStore.currentTicket?.hasChanges ?? false
    });

    const onResetForm = useCallback(async () => {
        await ticketStore.discardCurrentTicketChanges();
    }, [ticketStore]);

    ticketStore.setUserDefinedAfterSaveFunction(closeSaveFormModalAndGoBack);

    const editForm = useCallback(
        (e: any) => {
            e.preventDefault();
            ticketStore.setIsEditing(true);
        },
        [ticketStore]
    );

    const onBlurTicketField = useCallback(
        (e: any) => {
            ticketStore.currentTicket?.validateBaseNotificationDetails();
        },
        [ticketStore.currentTicket]
    );

    const saveFormButtonLabel = (
        <div className="d-flex align-items-center">
            <div>{t("labels.save")}</div>
            <UI.RotatingSpinner
                className={`${styles.FromSaveButtonSpinner} ${
                    ticketStore.loadingType !== LOADING_TYPE.SAVING_TICKET ? "d-none" : ""
                }`}
                noLogo={true}
                size={20}
            />
        </div>
    );

    const saveFormButton = uiStore.isMobile ? (
        <>
            {ticketStore.loadingType !== LOADING_TYPE.NONE ? (
                <UI.RotatingSpinner noLogo={true} size={30} />
            ) : (
                <UI.Icon onClick={saveForm(true)} icon={UI.SVGIcon.Save} color={ColorStyle("primary")} size={"large"} />
            )}
        </>
    ) : (
        <UI.Button
            onClick={saveForm(true)}
            className={styles.SaveButton}
            label={saveFormButtonLabel}
            disabled={ticketStore.loadingType !== LOADING_TYPE.NONE}
        />
    );

    const editFormButton =
        window.innerWidth <= ScreenWidth.MD ? (
            <UI.Icon
                className={styles.EditIcon}
                onClick={editForm}
                icon={UI.SVGIcon.Edit}
                color={ColorStyle("primary")}
                size={"large"}
            />
        ) : (
            <UI.Button
                onClick={editForm}
                className={styles.EditButton}
                variant={"outline-primary"}
                label={t("labels.edit")}
            />
        );

    let realestatePlaceholderIconSize = 170;

    if (window.innerWidth <= 1440) {
        realestatePlaceholderIconSize = 125;
    }

    const isNewTicket = ticketStore.currentTicket?.isNewTicket;

    const shouldRenderActionMobileButton =
        window.innerWidth <= ScreenWidth.MD &&
        !isNewTicket &&
        (ticketStore.currentTicket?.state === TICKET_STATE.NEW ||
            ticketStore.currentTicket?.state === TICKET_STATE.ASSIGNED);
    const showSaveButtonInNewRow =
        !isNewTicket && ticketStore.isEditing && !uiStore.isMobile && window.innerWidth <= ScreenWidth.MD;

    const isTicketFromEasyContactWithoutDeterminedRealestate =
        ticketStore.currentTicket?.realestate === undefined &&
        ticketStore.currentTicket?.origin === TICKET_ORIGIN.EASY_CONTACT;

    const wasRealestateManuallySet = ticketStore.currentTicket?.realestate !== undefined;

    const isNotificationAddressDisplayed =
        isTicketFromEasyContactWithoutDeterminedRealestate && !wasRealestateManuallySet;

    const notificationAddress = ticketStore.currentTicket?.notificationAddress;

    const notificationAddressElement = (
        <div>
            <div className="bold-text">Adresse</div>
            <div>
                {notificationAddress?.street} {notificationAddress?.housenumber}
            </div>
            <div>
                {notificationAddress?.zip} {notificationAddress?.city}
            </div>
        </div>
    );

    return (
        <>
            {saveFormModal}
            <UI.Form className={styles.TicketForm}>
                {/* Ticket-Number / Creation-Date / Title */}
                <UI.Row className={`mt-2 ${styles.TitleRow}`}>
                    <UI.Col
                        xs={isNewTicket ? 0 : shouldRenderActionMobileButton ? 6 : 10}
                        md={isNewTicket ? 0 : ticketStore.isEditing ? 2 : shouldRenderActionMobileButton ? 6 : 10}
                        lg={ticketStore.isEditing ? 2 : 9}
                        className={`${isNewTicket ? "d-none" : ""} ${
                            uiStore.isMobile && !ticketStore.isEditing ? "mb-3" : ""
                        }`}
                    >
                        <div>
                            <div className={styles.TicketNumber}>
                                {ticketStore.currentTicket?.number
                                    ? ticketStore.currentTicket?.number.toString().padStart(5, "0")
                                    : ""}{" "}
                                - {!ticketStore.isEditing ? ticketStore.currentTicket?.title : ""}
                            </div>
                            <div className={styles.CreationDate}>
                                {ticketStore.currentTicket?.date && Ticket.formatDate(ticketStore.currentTicket.date)}
                            </div>
                        </div>
                    </UI.Col>
                    <UI.Col
                        xs={shouldRenderActionMobileButton ? 3 : 0}
                        className={`order-2 ${shouldRenderActionMobileButton ? "" : "d-none"}`}
                    >
                        <TicketActionMobileButton />
                    </UI.Col>
                    {!showSaveButtonInNewRow && (
                        <UI.Col
                            xs={isNewTicket ? 12 : 2}
                            md={isNewTicket || ticketStore.isEditing ? 4 : 2}
                            lg={3}
                            className={`order-2 ${uiStore.isMobile ? "ml-2" : ""} d-flex justify-content-end ${
                                styles.EditColumn
                            }`}
                        >
                            {ticketStore.isEditing ? saveFormButton : editFormButton}
                        </UI.Col>
                    )}
                    {ticketStore.isEditing && (
                        <UI.Col
                            xs={12}
                            md={isNewTicket ? 8 : 7}
                            lg={isNewTicket ? 9 : 7}
                            className={"order-3 order-md-1 mt-3 mt-md-0"}
                        >
                            <UI.Input
                                type="text"
                                autoComplete="off"
                                placeholder={`${t("screens.tickets.form.title").toString()}*`}
                                value={ticketStore.currentTicket ? ticketStore.currentTicket.title : ""}
                                onChange={onChangeTicketField(TICKET_PROPERTY.TITLE)}
                                onBlur={onBlurTicketField}
                                errorMsg={ticketStore.currentTicket?.errors.title ?? undefined}
                            />
                        </UI.Col>
                    )}
                </UI.Row>
                {showSaveButtonInNewRow && (
                    <UI.Row>
                        <UI.Col md={12} className="d-flex justify-content-end">
                            {ticketStore.isEditing ? saveFormButton : editFormButton}
                        </UI.Col>
                    </UI.Row>
                )}

                {/* Status / Workflow-Typ */}
                <UI.Row>
                    <UI.Col lg={2}>
                        <UI.Input
                            label={t("screens.tickets.form.state").toString()}
                            type="text"
                            autoComplete="off"
                            disabled={true}
                            value={
                                ticketStore.currentTicket
                                    ? t(`screens.tickets.ticket_state.${ticketStore.currentTicket.state}`).toString()
                                    : ""
                            }
                        />
                    </UI.Col>
                    <UI.Col lg={4}>
                        <UI.Input
                            label={t("screens.tickets.form.workflow_type").toString()}
                            as="select"
                            onChange={onChangeTicketField(TICKET_PROPERTY.WORKFLOW_TYPE)}
                            className={`${styles.FormFieldSelect} ${ticketStore.isEditing ? "" : styles.ReadOnly}`}
                            disabled={!ticketStore.isEditing || !isNewTicket}
                            value={String(ticketStore.currentTicket?.workflow_type)}
                        >
                            {ticketStore.processes.map((process) => {
                                return (
                                    <option key={process.notificationtype} value={process.notificationtype ?? ""}>
                                        {process.name}
                                    </option>
                                );
                            })}
                        </UI.Input>
                    </UI.Col>
                </UI.Row>
                {/* Objekt */}
                <div className="mt-3">
                    <span className={styles.FormSectionTitle}>{t("screens.unit.object")}</span>
                </div>
                <UI.Row className="mt-md-2">
                    <UI.Col lg={2}>
                        <div className="d-flex align-items-center justify-content-center h-100">
                            {ticketStore.currentTicket?.realestateThumbnailFileId ? (
                                <UI.Thumbnail
                                    src={
                                        NetworkConfig.openThumbnailUrl +
                                        ticketStore.currentTicket?.realestateThumbnailFileId
                                    }
                                    className={styles.RealestateThumbnail}
                                />
                            ) : (
                                <div className={styles.RealestatePlaceholderThumbnailContainer}>
                                    <UI.Icon
                                        icon={UI.SVGIcon.Consolidated}
                                        size={realestatePlaceholderIconSize}
                                        color={ColorStyle("grey")}
                                        backgroundColor="#D2D5D9"
                                    />
                                </div>
                            )}
                        </div>
                    </UI.Col>
                    <UI.Col lg={10}>
                        <UI.Row>
                            <UI.Col lg={isNotificationAddressDisplayed ? 8 : 12}>
                                <RealestateFormField />
                            </UI.Col>
                            <UI.Col
                                lg={isNotificationAddressDisplayed ? 4 : 0}
                                className={isNotificationAddressDisplayed ? "d-block" : "d-none"}
                            >
                                {notificationAddressElement}
                            </UI.Col>
                            <UI.Col lg={12}>
                                <UnitFormField />
                            </UI.Col>
                        </UI.Row>
                    </UI.Col>
                </UI.Row>
                {/* Person */}
                {!ticketStore.isEditing && !ticketStore.currentTicket?.incidentContactPerson ? (
                    <></>
                ) : (
                    <>
                        <div className="mt-3">
                            <span className={styles.FormSectionTitle}>
                                {t("screens.tickets.form.person.title").toString()}
                            </span>
                        </div>
                        <PersonFormFields />
                    </>
                )}

                {/* Meldung */}
                <div className="mt-3 mb-2">
                    <span className={styles.FormSectionTitle}>
                        {t("screens.tickets.form.notification.title").toString()}
                    </span>
                </div>
                {Number(ticketStore.currentTicket?.workflow_type) === PROCESS.DAMAGE_NOTIFICATION ? (
                    <DamageNotificationFormFields />
                ) : undefined}
                {Number(ticketStore.currentTicket?.workflow_type) === PROCESS.COMMON_REQUEST ? (
                    <CommonRequestNotificationFormFields />
                ) : undefined}
                {Number(ticketStore.currentTicket?.workflow_type) === PROCESS.NAMESIGN ? (
                    <NamesignNotificationFormFields />
                ) : undefined}
                {Number(ticketStore.currentTicket?.workflow_type) === PROCESS.KEY_ORDER ? (
                    <KeyOrderNotificationFormFields />
                ) : undefined}
                {Number(ticketStore.currentTicket?.workflow_type) === PROCESS.DOCUMENT_QUESTION ? (
                    <QuestionToDocumentFormFields />
                ) : undefined}
            </UI.Form>

            {ticketStore.isEditing ? (
                uiStore.isMobile ? (
                    <UI.Row className="d-flex mt-2 flex-column row">
                        <UI.Col xs={12} className="d-flex mt-2 justify-content-center align-items-center col-12">
                            <UI.Button
                                onClick={saveForm(true)}
                                className={"ticket-button mx-2"}
                                label={saveFormButtonLabel}
                                disabled={ticketStore.loadingType !== LOADING_TYPE.NONE}
                            />
                        </UI.Col>
                        <UI.Col xs={12} className="d-flex mt-2 justify-content-center align-items-center col-12">
                            <UI.Button
                                label={t("labels.discard")}
                                disabled={
                                    !ticketStore.currentTicket?.hasChanges ||
                                    ticketStore.loadingType === LOADING_TYPE.SAVING_TICKET
                                }
                                onClick={onResetForm}
                                className={`${styles.DiscardButtonMobile} mx-2`}
                                variant="outline-primary"
                            />
                        </UI.Col>
                    </UI.Row>
                ) : (
                    <UI.Row className="my-2">
                        <UI.Col className={"flex-grow-0"}>
                            <UI.Button
                                onClick={saveForm(true)}
                                className={styles.SaveButton}
                                label={saveFormButtonLabel}
                                disabled={ticketStore.loadingType !== LOADING_TYPE.NONE}
                            />
                        </UI.Col>
                        <UI.Col>
                            <UI.Button
                                label={t("labels.discard")}
                                disabled={
                                    !ticketStore.currentTicket?.hasChanges ||
                                    ticketStore.loadingType === LOADING_TYPE.SAVING_TICKET
                                }
                                onClick={onResetForm}
                                className={`${styles.DiscardButton}`}
                                variant="outline-primary"
                            />
                        </UI.Col>
                    </UI.Row>
                )
            ) : undefined}
        </>
    );
};

export const TicketForm = Sentry.withProfiler(observer(TicketFormBase));
