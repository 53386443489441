import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import SystemHealth404 from "src/assets/images/system-health/404.svg";
import styles from "./SystemHealthError.module.css";

const SystemHealthErrorBase = () => {
    const { navStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    useEffect(() => {
        navStore.setTitle(t("screens.system_health_error.header"));
        navStore.setBackbutton(null);
    }, [navStore, t]);

    return (
        <UI.Container>
            <UI.Row className={styles.HealthErrorContainer}>
                <UI.Col md={6} className="d-flex justify-content-end">
                    <img className={styles.HealthErrorImage} src={SystemHealth404} alt="404" />
                </UI.Col>
                <UI.Col sm={6} lg={4}>
                    <div>
                        <div className={`${styles.ErrorTextTitle} mb-3`}>{t("screens.system_health_error.title")}</div>
                        <div className={`${styles.ErrorTextSubtitle} mb-3`}>
                            {t("screens.system_health_error.subtitle")}
                        </div>
                        <div className={styles.ErrorText}>{t("screens.system_health_error.text")}</div>
                    </div>
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};

export const SystemHealthError = Sentry.withProfiler(SystemHealthErrorBase);
