import React, { useCallback, useContext } from "react";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import "./Close.css";
import { withRouter, RouterProps } from "react-router";
import { ColorStyle } from "src/utils/Colors";

const CloseBase = (props: RouterProps) => {
    const { navStore, uiStore } = useContext(RootStoreContext);

    const onClose = useCallback(() => {
        uiStore.setCloseAppBar(false);
        if (navStore.closeNavTarget !== "") {
            props.history.push(navStore.closeNavTarget);
        }
    }, [props.history, navStore.closeNavTarget, uiStore]);
    return (
        <div className="Close">
            <UI.Icon onClick={onClose} icon={UI.SVGIcon.Close} color={ColorStyle("white")} />
        </div>
    );
};
export const Close = withRouter(CloseBase);
