import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { TICKET_STATE } from "src/stores/TicketStore";
import styles from "./TicketsKanban.module.css";
import { KanbanRail } from "./KanbanRail";
import { DragDropContext, DraggableId, DraggableLocation } from "react-beautiful-dnd";
import { UI } from "@wwimmo/ui";

const TicketsKanbanBase = () => {
    const { t } = useTranslation();
    const { ticketListStore, uiStore } = useContext(RootStoreContext);
    const scrollContainer = useRef<HTMLDivElement | null>(null);
    const [selectedTab, setSelectedTab] = useState<TICKET_STATE>(TICKET_STATE.NEW);

    useEffect(() => {
        ticketListStore.setFilter({ ...ticketListStore.ticketFilter, state: "All" });
    }, [ticketListStore]);

    const onDragEnd = useCallback(
        (result: { destination?: DraggableLocation | null; source: DraggableLocation; draggableId: DraggableId }) => {
            const { destination, source, draggableId } = result;
            if (destination) {
                ticketListStore.setKanbanTicketState(
                    destination.droppableId as TICKET_STATE,
                    source.droppableId as TICKET_STATE,
                    parseInt(draggableId),
                    destination.index,
                    result.source.index
                );
            }
        },
        [ticketListStore]
    );
    const stats = ticketListStore.ticketStats;
    const sortItems = useMemo(
        () => [
            {
                label: `${t("easyticket.states.new")} (${stats.new})`,
                id: TICKET_STATE.NEW
            },
            {
                label: `${t("easyticket.states.assigned")} (${stats.assigned})`,
                id: TICKET_STATE.ASSIGNED
            },
            {
                label: `${t("easyticket.states.comissioned")} (${stats.comissioned})`,
                id: TICKET_STATE.COMISSIONED
            },
            {
                label: `${t("easyticket.states.completed")} (${stats.completed})`,
                id: TICKET_STATE.COMPLETED
            }
        ],
        [t, stats]
    );
    const onContainerScroll = useCallback(
        (event: unknown) => {
            const scrollEvent = event as React.UIEvent<HTMLDivElement>;
            const yPos = Math.round(
                (1 / (sortItems.length * scrollEvent.currentTarget.offsetWidth)) *
                    scrollEvent.currentTarget.scrollLeft *
                    sortItems.length
            );
            setSelectedTab(sortItems[yPos].id);
        },
        [sortItems, setSelectedTab]
    );
    useEffect(() => {
        const { current } = scrollContainer;
        if (current) {
            current.addEventListener("scroll", onContainerScroll);
        }
        return () => {
            if (current) {
                current.removeEventListener("scroll", onContainerScroll);
            }
        };
    }, [scrollContainer, onContainerScroll]);

    const onTabsChange = useCallback(
        (id: string) => {
            if (scrollContainer) {
                scrollContainer.current?.scroll({
                    top: 0,
                    left: sortItems.findIndex((item) => item.id === id) * scrollContainer.current?.offsetWidth ?? 0,
                    behavior: "smooth"
                });
            }
        },
        [scrollContainer, sortItems]
    );

    const rail = (state: TICKET_STATE) => (
        <KanbanRail
            id={state}
            title={`${t("easyticket.states." + String(state))} (${stats[state]})`}
            ticketNumbers={ticketListStore.kanbanBoard[state]}
        />
    );
    return (
        <>
            {uiStore.isMobile && (
                <UI.Tabs
                    selectedId={selectedTab}
                    onTabChange={onTabsChange}
                    contents={sortItems.map((item) => ({ ...item }))}
                    nowrap
                ></UI.Tabs>
            )}
            <div className={styles.TicketsKanban}>
                <div className={styles.ScrollContainer} ref={scrollContainer}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        {rail(TICKET_STATE.NEW)}
                        {rail(TICKET_STATE.ASSIGNED)}
                        {rail(TICKET_STATE.COMISSIONED)}
                        {rail(TICKET_STATE.COMPLETED)}
                    </DragDropContext>
                </div>
            </div>
        </>
    );
};

export const TicketsKanban = Sentry.withProfiler(observer(TicketsKanbanBase));
