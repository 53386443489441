import { observable, action, makeObservable, computed } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { ITicket } from "./InvoiceTypes";
import { GET_TICKET_DATA, GET_TICKET_DATA_BY_REALESTATEID, GET_TICKETS_WITH_QUERY } from "src/api/cred";
import {
    GetTicketsWithQuery,
    GetTicketsWithQuery_tickets,
    GetTicketsWithQueryVariables
} from "src/api/generated/GetTicketsWithQuery";
import { GetTicketData } from "src/api/generated/GetTicketData";
import {
    GetTicketDataByRealestateId,
    GetTicketDataByRealestateIdVariables
} from "src/api/generated/GetTicketDataByRealestateId";

export enum TICKET_SEARCH_FILTER_TYPE {
    REALESTATE,
    UNIT
}

export interface ITicketSearchFilter {
    byRealestate: boolean;
}

export class TicketSearchStore {
    rootStore: RootStore;

    ticketData: ITicket[] = [];
    currentTicketSearchQuery: string = "";
    selectedTicketQueryString: string | undefined = undefined;
    isSearchingTicketData: boolean = false;
    ticketSearchFilter: ITicketSearchFilter = {
        byRealestate: false
    };
    triggerFocusOnTicketInput: boolean = false;
    isSearchingTicketsInBackendByQuery: boolean = true;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            ticketData: observable,
            triggerFocusOnTicketInput: observable,
            currentTicketSearchQuery: observable,
            selectedTicketQueryString: observable,
            ticketSearchFilter: observable,
            isSearchingTicketData: observable,
            isSearchingTicketsInBackendByQuery: observable,

            init: action,
            loadTicketData: action,
            searchTicketsInBackendByQuery: action,
            searchTicketsByRealestate: action,
            getMappedAndSortedTicketData: action,
            setCurrentTicketSearchQuery: action,
            setSelectedTicketQueryString: action,
            setTriggerFocusOnTicketInput: action,
            setTicketData: action,
            setTicketSearchFilter: action,
            setTicketSearchFilterRealestate: action,
            setIsSearchingTicketData: action,
            setIsSearchingTicketsInBackendByQuery: action,
            toggleTicketSearchFilterRealestate: action,

            filteredTicketData: computed
        });
    }

    init = async () => {
        await this.loadTicketData();
    };

    searchTicketsInBackendByQuery = async (searchQuery: string) => {
        this.setIsSearchingTicketData(true);

        const { data }: { data: GetTicketsWithQuery } = await apolloClientInstance.query<
            GetTicketsWithQuery,
            GetTicketsWithQueryVariables
        >({
            query: GET_TICKETS_WITH_QUERY,
            variables: {
                searchQuery: `%${searchQuery}%`
            }
        });

        const ticketData: ITicket[] = this.getMappedAndSortedTicketData(data.tickets);

        this.setTicketData(ticketData);

        this.setIsSearchingTicketData(false);
    };

    searchTicketsByRealestate = async () => {
        this.setIsSearchingTicketData(true);

        const { data }: { data: GetTicketDataByRealestateId } = await apolloClientInstance.query<
            GetTicketDataByRealestateId,
            GetTicketDataByRealestateIdVariables
        >({
            query: GET_TICKET_DATA_BY_REALESTATEID,
            variables: {
                realestateid: this.rootStore.invoiceStore.currentInvoice?.realestate?.id
            }
        });

        const ticketData: ITicket[] = this.getMappedAndSortedTicketData(data.tickets);

        this.setTicketData(ticketData);

        this.setIsSearchingTicketData(false);
    };

    loadTicketData = async () => {
        this.setIsSearchingTicketData(true);

        const { data }: { data: GetTicketData } = await apolloClientInstance.query<GetTicketData>({
            query: GET_TICKET_DATA
        });

        const ticketData: ITicket[] = this.getMappedAndSortedTicketData(data.tickets);

        if (ticketData.length > 1000) {
            this.setIsSearchingTicketsInBackendByQuery(true);
        } else {
            this.setIsSearchingTicketsInBackendByQuery(false);
        }

        this.setTicketData(ticketData);

        this.setIsSearchingTicketData(false);
    };

    getMappedAndSortedTicketData = (tickets: GetTicketsWithQuery_tickets[]): ITicket[] => {
        const ticketData: ITicket[] = tickets.map((ticket) => {
            return {
                id: ticket.incidentid ?? undefined,
                realestateid: ticket.realestateid ?? undefined,
                unitid: ticket.unitid ?? undefined,
                date: ticket.date ?? undefined,
                date_long: ticket.date_long ?? undefined,
                number: ticket.number ?? undefined,
                title: ticket.title ?? undefined
            };
        });

        // Sorting tickets by number
        ticketData.sort((ticketA, ticketB) => {
            if (ticketA.number === null && ticketB.number !== null) {
                return 1;
            }

            if (ticketA.number !== null && ticketB.number === null) {
                return -1;
            }

            const ticketANumber = ticketA.number !== null ? Number(ticketA.number) : null;
            const ticketBNumber = ticketB.number !== null ? Number(ticketB.number) : null;

            if (ticketANumber === null && ticketBNumber !== null) {
                return 1;
            }

            if (ticketANumber !== null && ticketBNumber === null) {
                return -1;
            }

            if (ticketANumber! < ticketBNumber!) {
                return 1;
            }

            if (ticketANumber! > ticketBNumber!) {
                return -1;
            }

            return 0;
        });

        return ticketData;
    };

    getFormattedTicketFieldValue = (ticket: ITicket): string => {
        return `${ticket.date}, ${ticket.title} [${ticket.number}]`;
    };

    // Setters
    setIsSearchingTicketData = (isSearchingTicketData: boolean) => {
        this.isSearchingTicketData = isSearchingTicketData;
    };

    setCurrentTicketSearchQuery = (currentTicketSearchQuery: string) => {
        this.currentTicketSearchQuery = currentTicketSearchQuery;
    };

    setSelectedTicketQueryString = (selectedTicketQueryString: string | undefined) => {
        this.selectedTicketQueryString = selectedTicketQueryString;
    };

    setTriggerFocusOnTicketInput = (triggerFocusOnTicketInput: boolean) => {
        this.triggerFocusOnTicketInput = triggerFocusOnTicketInput;
    };

    setTicketData = (ticketData: ITicket[]) => {
        this.ticketData = ticketData;
    };

    setTicketSearchFilter = (ticketSearchFilter: ITicketSearchFilter) => {
        this.ticketSearchFilter = ticketSearchFilter;
    };

    setTicketSearchFilterRealestate = (filterByRealestate: boolean) => {
        this.ticketSearchFilter.byRealestate = filterByRealestate;
    };

    toggleTicketSearchFilterRealestate = () => {
        this.ticketSearchFilter.byRealestate = !this.ticketSearchFilter.byRealestate;
    };

    setIsSearchingTicketsInBackendByQuery = (isSearchingTicketsInBackendByQuery: boolean) => {
        this.isSearchingTicketsInBackendByQuery = isSearchingTicketsInBackendByQuery;
    };

    get filteredTicketData(): ITicket[] {
        let filteredTicketData: ITicket[] = [...this.ticketData];

        if (this.ticketSearchFilter.byRealestate) {
            const filteredTicketDataByRealestate = this.ticketData.filter((ticket) => {
                return ticket.realestateid === this.rootStore.invoiceStore.currentInvoice?.realestate?.id;
            });

            filteredTicketData = [...filteredTicketDataByRealestate];
        }

        if (!this.isSearchingTicketsInBackendByQuery) {
            const searchQuery = this.currentTicketSearchQuery ?? "";

            if (this.currentTicketSearchQuery === this.selectedTicketQueryString) {
                return filteredTicketData;
            }

            if (searchQuery === "") {
                return filteredTicketData;
            }

            const filteredPersonDataBySearchQuery = filteredTicketData.filter((ticket) => {
                return (
                    ticket.number?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    ticket.date_long?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    ticket.title?.toLowerCase().includes(searchQuery.toLowerCase())
                );
            });

            return filteredPersonDataBySearchQuery;
        } else {
            return filteredTicketData;
        }
    }
}
