import { useContext, useCallback } from "react";
import { RootStoreContext } from "src/stores/RootStore";
import { useMutation } from "@apollo/client";
import { UPSERT_USERSETTING } from "src/api/customer";
import { UpsertUsersetting, UpsertUsersettingVariables } from "src/api/generated/UpsertUsersetting";
import { ums_usersettings_insert_input } from "src/api/generated/globalTypes";

export const useSidebarFunctions = () => {
    const { uiStore } = useContext(RootStoreContext);

    const [upsertUsersettingSidebarMutation] = useMutation<UpsertUsersetting, UpsertUsersettingVariables>(
        UPSERT_USERSETTING
    );

    const upsertUsersettingSidebar = useCallback(
        (isSidebarCollapsed: boolean) => {
            const upsertAssemblyUserAuditObject: ums_usersettings_insert_input[] = [
                {
                    value: isSidebarCollapsed.toString(),
                    valuetype: 1,
                    key: "PORTAL.IS_SIDEBAR_COLLAPSED"
                }
            ];

            upsertUsersettingSidebarMutation({
                variables: {
                    objects: upsertAssemblyUserAuditObject
                }
            });
        },
        [upsertUsersettingSidebarMutation]
    );

    const expandSidebar = useCallback(() => {
        uiStore.expandSidebar();
        upsertUsersettingSidebar(false);
    }, [uiStore, upsertUsersettingSidebar]);

    const collapseSidebar = useCallback(() => {
        uiStore.collapseSidebar();
        upsertUsersettingSidebar(true);
    }, [uiStore, upsertUsersettingSidebar]);

    return {
        expandSidebar,
        collapseSidebar,
        upsertUsersettingSidebar
    };
};
