import React, { useCallback, useContext, useState } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./SelectServiceProvider.module.css";
import generalStyles from "src/screens/tickets/ticket/details/actions/assignOrder/AssignOrderModal.module.css";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { ColorStyle } from "src/utils/Colors";
import { IServiceProvider } from "src/stores/tickets/AssignOrderModalStore";
import { ServiceProviderListItem } from "./serviceProviderListItem/ServiceProviderListItem";

export const SelectServiceProviderBase = () => {
    const { t } = useTranslation();
    const { uiStore, assignOrderModalStore, realestateSearchStore } = useContext(RootStoreContext);
    const [lastScrollPositionYServiceProviderItems, setLastSrollYPositionServiceProviderItems] = useState<number>(0);

    const onClickBack = useCallback(() => {
        assignOrderModalStore.moveToPrevProcessStep();
    }, [assignOrderModalStore]);

    const onClickSelectServiceProvider = useCallback(
        (serviceProvider: IServiceProvider) => () => {
            assignOrderModalStore.setSelectedServiceProvider(serviceProvider);
            assignOrderModalStore.moveToNextProcessStep();
        },
        [assignOrderModalStore]
    );

    const resetAmountOfItemsDisplayedInServiceProviderList = useCallback(() => {
        setLastSrollYPositionServiceProviderItems(0);
        assignOrderModalStore.setNumberOfDisplayedServiceProviders(
            assignOrderModalStore.sliceSizeOfDisplayedServiceProviders
        );
    }, [assignOrderModalStore]);

    const onChangeServiceProviderInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputServiceProvider = e.target.value;
            resetAmountOfItemsDisplayedInServiceProviderList();

            assignOrderModalStore.setCurrentServiceProviderSearchQuery(inputServiceProvider);
        },
        [assignOrderModalStore, resetAmountOfItemsDisplayedInServiceProviderList]
    );

    const filterActivationIcon = uiStore.isMobile ? (
        <div className="filter-button-icon">+</div>
    ) : (
        <UI.Icon className="filter-button-icon svg-icon" icon={UI.SVGIcon.Plus} size={25} color={ColorStyle("grey")} />
    );

    const filterDeactivationIcon = (
        <UI.Icon className="filter-button-icon" icon={UI.SVGIcon.Close} size={12} color={ColorStyle("white")} />
    );

    const serviceProviderFilterButtonRealestate = (
        <div
            className={`filter-button ${!realestateSearchStore.selectedRealestateName ? "not-clickable" : ""} ${
                assignOrderModalStore.areServiceProviderFilteredByRealestate ? "filter-active" : ""
            }`}
            onClick={
                realestateSearchStore.selectedRealestateName
                    ? assignOrderModalStore.toggleServiceProviderSearchFilterRealestate
                    : undefined
            }
        >
            {t("screens.tickets.form.person.realestate_filter_abr")}: {realestateSearchStore.selectedRealestateName}
            {assignOrderModalStore.areServiceProviderFilteredByRealestate
                ? filterDeactivationIcon
                : filterActivationIcon}
        </div>
    );

    const onScrollServiceProviderList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYServiceProviderItems;

            const hasMoreResulsToDisplay =
                assignOrderModalStore.numberOfDisplayedServiceProviders < assignOrderModalStore.serviceProviders.length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                assignOrderModalStore.setNumberOfDisplayedServiceProviders(
                    assignOrderModalStore.numberOfDisplayedServiceProviders +
                        assignOrderModalStore.sliceSizeOfDisplayedServiceProviders
                );
            }

            setLastSrollYPositionServiceProviderItems(pixelsFromTop);
        },
        [lastScrollPositionYServiceProviderItems, assignOrderModalStore]
    );

    return (
        <>
            <UI.Row className={`mt-2 ${generalStyles.TitleRow}`}>
                <UI.Col sm={12}>
                    <UI.Input
                        type="text"
                        autoComplete="off"
                        className={styles.FormInput}
                        id="assign-order-select-service-provider-person-input"
                        value={assignOrderModalStore.currentServiceProviderSearchQuery ?? ""}
                        onChange={onChangeServiceProviderInput}
                        label={t("screens.tickets.modals.assign_order.search_service_provider").toString()}
                        placeholder={t("labels.search").toString()}
                        iconRight={
                            <UI.Icon icon={UI.SVGIcon.Search} color="grey" size={"small"} style={{ right: "5px" }} />
                        }
                    />
                </UI.Col>
                <UI.Col sm={12}>
                    <div className={`${styles.FormInput} ${styles.ManualFormInput}`}>
                        {serviceProviderFilterButtonRealestate}
                    </div>
                </UI.Col>
                <UI.Col sm={12}>
                    <div className={`${styles.ServiceProviderListContainer}`} onScroll={onScrollServiceProviderList}>
                        {assignOrderModalStore.serviceProviders
                            .slice(0, assignOrderModalStore.numberOfDisplayedServiceProviders)
                            .filter((serviceProvider, index, self) => {
                                return index === self.findIndex((sp) => sp.personId === serviceProvider.personId);
                            })
                            .map((serviceProvider, index) => {
                                return (
                                    <ServiceProviderListItem
                                        key={index}
                                        serviceProvider={serviceProvider}
                                        onClickServiceProviderButton={onClickSelectServiceProvider(serviceProvider)}
                                        serviceProviderItemButtonLabel={t("labels.choose").toString()}
                                    />
                                );
                            })}
                    </div>
                </UI.Col>
            </UI.Row>
            <UI.Spacer />
            <UI.Row className={`mt-2 d-flex ${uiStore.isMobile ? "flex-column-reverse" : ""}`}>
                <UI.Col xs={12} md={6}>
                    <UI.Button
                        className={`ticket-button mt-2 ${generalStyles.BackButton}`}
                        label={t("labels.goback")}
                        variant={"outline-primary"}
                        onClick={onClickBack}
                    ></UI.Button>
                </UI.Col>
            </UI.Row>
        </>
    );
};

export const SelectServiceProvider = Sentry.withProfiler(observer(SelectServiceProviderBase));
