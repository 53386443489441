import React, { useCallback, useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { observer } from "mobx-react";
import { UseFormRegister, DeepMap, FieldError } from "react-hook-form";
import { GetCitiesByZip, GetCities } from "src/utils/Location";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";
import "src/screens/realestates/realestate/assemblies/manager/edit/form/AssembliesEditForm.css";
import {
    SEARCH_STATE,
    ERROR_TYPE,
    LIST_TYPE,
    AssemblyEditFormFields
} from "src/screens/realestates/realestate/assemblies/manager/edit/form/AssembliesEditForm";
import { handleListKeyDown, handleListKeyUp, setFocus } from "src/utils/AutocompleteInputHelper";

interface ZipAutocompleteInputProps {
    setZipWasSelected: any;
    zipWasSelected: boolean;
    setStreetWasSelected: any;
    setStreetNumberWasSelected: any;
    setCityWasSelected: any;
    closeAllLists: any;
    setValue: any;
    watchZip: string;
    register: UseFormRegister<AssemblyEditFormFields>;
    errors: DeepMap<AssemblyEditFormFields, FieldError>;
    setSearchType: any;
    setNameItems: any;
    searchType: SEARCH_STATE;
    setError: any;
    zipItems: string[];
    setZipItems: any;
    currentZip: string;
    setCurrentZip: any;
    setCurrentCityName: any;
    dirtyFields: DeepMap<AssemblyEditFormFields, true>;
}

const ZipAutocompleteInputBase = (props: ZipAutocompleteInputProps) => {
    const { t } = useTranslation();

    const [activeListItemIndex, setActiveListItemIndex] = useState<number>(-1);
    const [hasRendered, setHasRendered] = useState<boolean>(false);

    const {
        setError,
        searchType,
        setZipWasSelected,
        zipWasSelected,
        zipItems,
        setZipItems,
        setStreetWasSelected,
        setStreetNumberWasSelected,
        setValue,
        register,
        watchZip,
        errors,
        setSearchType,
        setNameItems,
        closeAllLists,
        setCityWasSelected,
        currentZip,
        setCurrentZip,
        setCurrentCityName,
        dirtyFields
    } = props;

    useEffect(() => {
        if (!hasRendered) {
            setHasRendered(true);
        } else if (watchZip !== currentZip) {
            onChangeInputCityZip(watchZip);
        }
        // Only changes to 'watchZip' should cause an running of this function
        // eslint-disable-next-line
    }, [watchZip]);

    const onChangeInputCityZip = debounce(async (inputZip: string) => {
        const inputZipName = inputZip.trim();

        setError(ERROR_TYPE.NONE);

        setZipWasSelected(false);
        setStreetWasSelected(false);
        setStreetNumberWasSelected(false);

        setValue("street", "");
        setValue("housenumber", "");

        setSearchType(SEARCH_STATE.ZIP);

        if (inputZipName.length > 1) {
            const cities = await GetCitiesByZip(inputZipName);

            setZipItems(cities.map((city: any) => `${city.zipcode} ${city.city}`));

            if (cities.length === 0) {
                setError(ERROR_TYPE.ZIP);
            }
        } else {
            setZipItems([]);
            setNameItems([]);
        }

        setSearchType(SEARCH_STATE.NONE);
    }, 800);

    const onClickInputCityZip = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            closeAllLists();
            setActiveListItemIndex(-1);

            const inputZip = e.target.value.trim();
            if (inputZip.length > 1 && !zipWasSelected) {
                const cities = await GetCitiesByZip(inputZip);

                setZipItems(cities.map((city: any) => `${city.zipcode} ${city.city}`));

                if (cities.length === 0) {
                    setError(ERROR_TYPE.ZIP);
                }
            } else {
                setZipItems([]);
                setNameItems([]);
            }
        },
        [closeAllLists, zipWasSelected, setError, setNameItems, setZipItems]
    );

    const getZipAndCityValues = (zipAndCity: string): { zip: string; city: string } => {
        const addressParts = zipAndCity.split(" ");

        let zip = "";
        let city = "";

        if (addressParts && addressParts.length > 0) {
            zip = addressParts[0];
            addressParts.shift();
            city = addressParts.join(" ");
        }

        return { zip: zip, city: city };
    };

    const setZipAndCityFormValues = useCallback(
        (zipAndCity: string) => {
            const { zip, city } = getZipAndCityValues(zipAndCity);

            setValue("zip", zip);
            setValue("city", city, { shouldDirty: true });

            setCurrentZip(zip);
            setCurrentCityName(city);

            setZipItems([]);
            setNameItems([]);

            setCityWasSelected(true);
            setZipWasSelected(true);

            setFocus("street");
        },
        [setValue, setCityWasSelected, setNameItems, setZipWasSelected, setZipItems, setCurrentCityName, setCurrentZip]
    );

    const onClickZipItem = useCallback(
        (e: any) => {
            const itemValue = e.target.innerHTML;
            setZipAndCityFormValues(itemValue);
        },
        [setZipAndCityFormValues]
    );

    const onFocusInput = useCallback(
        (e: any) => {
            closeAllLists();
            setActiveListItemIndex(-1);
        },
        [closeAllLists]
    );

    const onKeyDownInputCityZip = useCallback(
        async (e: any) => {
            if (e.key === "Tab") {
                closeAllLists();
                onChangeInputCityZip.cancel();

                const zip = e.target.value.trim();

                const isZipListItemSelected = zipItems.length > 0 && activeListItemIndex !== -1;

                if (isZipListItemSelected) {
                    e.preventDefault();
                    setZipAndCityFormValues(zipItems[activeListItemIndex]);
                } else if (zip.length === 4) {
                    setSearchType(SEARCH_STATE.ZIP);

                    const cities = await GetCities({ zip: zip });

                    if (cities.length > 0) {
                        const cityWithShortestName = cities.reduce(
                            (city: { city: string; zip: string }, cityB: { city: string; zip: string }) =>
                                city.city.length <= cityB.city.length ? city : cityB
                        );

                        setCurrentZip(cityWithShortestName.zipcode);
                        setCurrentCityName(cityWithShortestName.city);

                        setZipWasSelected(true);
                        setCityWasSelected(true);

                        setValue("zip", cityWithShortestName.zipcode);
                        setValue("city", cityWithShortestName.city, { shouldDirty: true });

                        setFocus("street");
                    }

                    setSearchType(SEARCH_STATE.NONE);
                }
            } else if (zipItems.length > 0 && e.key === "ArrowDown") {
                handleListKeyDown(zipItems.length, LIST_TYPE.ZIP, activeListItemIndex, setActiveListItemIndex);
            } else if (zipItems.length > 0 && e.key === "ArrowUp") {
                handleListKeyUp(LIST_TYPE.ZIP, activeListItemIndex, setActiveListItemIndex);
            } else if (zipItems.length > 0 && e.key === "Enter") {
                setZipAndCityFormValues(zipItems[activeListItemIndex]);
            }
        },
        [
            closeAllLists,
            onChangeInputCityZip,
            setValue,
            activeListItemIndex,
            setZipAndCityFormValues,
            zipItems,
            setCityWasSelected,
            setSearchType,
            setZipWasSelected,
            setCurrentCityName,
            setCurrentZip
        ]
    );

    const loadingSpinner = <UI.RotatingSpinner noLogo size={20} className="loading-items-spinner" />;

    const zipSelectionList = (
        <ul
            id="zip-dropdown-list"
            className={`${searchType === SEARCH_STATE.ZIP ? "searching" : ""} dropdown-list zip`}
        >
            {searchType === SEARCH_STATE.ZIP ? loadingSpinner : undefined}
            {zipItems && zipItems.length > 0
                ? zipItems.map((item, index) => (
                      <li key={index}>
                          <div className="list-item" id={`zip-list-item-${index}`} onClick={onClickZipItem}>
                              {item}
                          </div>
                      </li>
                  ))
                : undefined}
        </ul>
    );

    return (
        <>
            <UI.Input
                id="zip"
                label={`${t("screens.realestate.assembly.zip")}`}
                {...register("zip")}
                type="text"
                onKeyDown={onKeyDownInputCityZip}
                onClick={onClickInputCityZip}
                onFocus={onFocusInput}
                autoComplete="nope"
                errorMsg={errors.zip ? errors.zip.message : undefined}
                className={dirtyFields.zip ? "changed" : ""}
            />
            {zipSelectionList}
        </>
    );
};

export const ZipAutocompleteInput = Sentry.withProfiler(observer(ZipAutocompleteInputBase));
