import React, { useContext, useEffect, useCallback, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { UnitLists } from "./unitLists/UnitLists";
import { RouteComponentProps, withRouter } from "react-router";
import { Route, selectRoute } from "src/config/routes";
import { ScreenType } from "src/api/tiles";
import { ScreenWithTiles } from "src/components/screenWithTiles/ScreenWithTiles";
import { UI } from "@wwimmo/ui";
import { ColorStyle } from "src/utils/Colors";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey } from "src/network/User";
import { MessageType } from "src/components/notifications/Notifier";
import { observer } from "mobx-react-lite";

interface MatchParams {
    realestateid: string;
    ownerid: string;
}
const UnitsBase = (props: RouteComponentProps<MatchParams>) => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);
    const [isLoadingTenancyIndex, setLoadingTenancyIndex] = useState<boolean>(false);

    const printStatusMessage = useCallback(
        (message: string | React.ReactNode, messageType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: messageType,
                    autoClose: messageType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    useEffect(() => {
        if (navStore.currentOwnerId) {
            if (navStore.singleRealestate) {
                navStore.setBackbutton(
                    selectRoute(Route.owners, authStore.portalRoleWithHighestPriority ?? authStore.user?.role),
                    t("screens.owners.title")
                );
            } else {
                navStore.setBackbutton(
                    selectRoute(
                        Route.realestatesOwner,
                        authStore.portalRoleWithHighestPriority ?? authStore.user?.role,
                        {
                            ownerid: navStore.currentOwnerId
                        }
                    ),
                    t("screens.realestates.title")
                );
            }
        } else {
            navStore.setBackbutton(
                selectRoute(Route.realestates, authStore.portalRoleWithHighestPriority ?? authStore.user?.role),
                t("screens.realestates.title")
            );
        }
    });

    const realestateid = props.match.params.realestateid;

    const downloadTenancyIndexCsv = useCallback(async () => {
        setLoadingTenancyIndex(true);

        if (authStore.user) {
            const accessToken = authStore.token;
            const tokenType = "Bearer";
            const role = authStore.user?.role;

            try {
                const fetchResult = await fetch(`${NetworkConfig.getTenancyIndexCsvFile}/${realestateid}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    try {
                        const contentDisposition = fetchResult.headers.get("Content-Disposition");
                        // prettier-ignore
                        const fileName = contentDisposition?.split("filename=")[1]?.replaceAll("\"", "");

                        let fileBlob = await fetchResult.blob();
                        const objUrl = window.URL.createObjectURL(fileBlob);

                        const a = document.createElement("a");
                        a.href = objUrl;
                        a.download = fileName ?? t("screens.realestate.units.default_tenancy_index_file_name");
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    } catch (error) {
                        printStatusMessage(
                            t("screens.realestate.units.tenancy_index_download_error"),
                            MessageType.ERROR
                        );
                        setLoadingTenancyIndex(false);
                    }
                } else {
                    printStatusMessage(t("screens.realestate.units.tenancy_index_download_error"), MessageType.ERROR);
                }
            } catch (fetchError) {
                setLoadingTenancyIndex(false);
                printStatusMessage(t("screens.realestate.units.tenancy_index_download_error"), MessageType.ERROR);
            }
        }

        setLoadingTenancyIndex(false);
    }, [authStore.token, authStore.user, realestateid, t, printStatusMessage]);

    const title = (
        <div>
            {navStore.isCondominium ? t("screens.realestate.units.co_title") : t("screens.realestate.units.title")}
            <div className="d-inline-flex align-middle position-absolute" style={{ right: "20px" }}>
                {isLoadingTenancyIndex ? (
                    <UI.RotatingSpinner noLogo={true} size={25} />
                ) : (
                    <UI.Icon
                        icon={UI.SVGIcon.DownloadCloud}
                        color={ColorStyle("black")}
                        onClick={downloadTenancyIndexCsv}
                    />
                )}
            </div>
        </div>
    );

    return (
        <ScreenWithTiles title={title} screenId={ScreenType.TENANTS} realestateid={realestateid}>
            <UnitLists realestatesId={realestateid} />
        </ScreenWithTiles>
    );
};
export const Units = Sentry.withProfiler(withRouter(observer(UnitsBase)));
