import React from "react";

export const getStringWithNewlinesFromHtmlString = (htmlString: string) => {
    let result = "";

    if (htmlString.length > 0) {
        result = htmlString.replace(/<div>|<\/div>|<br \/>/gim, (substring: string) => {
            if (substring === "<br />" || substring === "</div>") {
                return "\n";
            } else {
                return "";
            }
        });
    }

    result = result.substring(0, result.lastIndexOf("\n"));

    return result;
};

export const getReactElementsFromStringArray = (textArray: string[]): React.ReactNode[] | undefined => {
    let result: React.ReactNode[] | undefined;

    textArray.length > 0
        ? (result = textArray.map((textLine, index) => {
              if (textLine.length === 0) {
                  const brElement = React.createElement("br", { key: `textline-${index}` });
                  return brElement;
              } else {
                  const divElement = React.createElement("div", { key: `textline-${index}` }, textLine);
                  return divElement;
              }
          }))
        : (result = undefined);

    return result;
};

export const getHtmlStringFromStringArray = (textArray: string[]): string => {
    let result: string = "";

    if (textArray.length > 0) {
        textArray.forEach((textLine, index) => {
            if (textLine.length === 0) {
                result = result?.concat("<br />");
            } else {
                result = result?.concat(`<div>${textLine}</div>`);
            }
        });
    }

    return result;
};

export const getStringArrayFromHtmlString = (htmlString: string) => {
    const textFromHtmlString = getStringWithNewlinesFromHtmlString(htmlString);
    const stringArrayFromHtmlString = textFromHtmlString.split("\n");

    if (stringArrayFromHtmlString[stringArrayFromHtmlString.length - 1] === "") {
        stringArrayFromHtmlString.splice(-1);
    }

    return stringArrayFromHtmlString;
};

export const getReactElementsFromHtmlString = (htmlString: string): React.ReactNode[] | undefined => {
    const stringArrayFromHtmlString = getStringArrayFromHtmlString(htmlString);
    return getReactElementsFromStringArray(stringArrayFromHtmlString);
};
