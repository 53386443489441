import React, { useState, useCallback } from "react";
import { Doughnut as DoughnutChartjs } from "react-chartjs-2";
import { ChartConfig } from "src/config/charts";
import { formatValue } from "src/components/tiles/Tile";

interface DoughnutProps {
    data: DoughnutData;
}

interface DoughnutData {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string[];
    }[];
    centerText?: string;
}

export const Doughnut = (props: DoughnutProps) => {
    const [dimension, setDimension] = useState({
        width: undefined,
        height: undefined
    });
    const ref = useCallback((node) => {
        if (node !== null) {
            setDimension({
                width: node.getBoundingClientRect().width,
                height: node.getBoundingClientRect().height
            });
        }
    }, []);
    const options = {
        legend: {
            display: false
        },
        cutoutPercentage: 70,
        animation: {
            animateScale: true,
            animateRotate: false,
            duration: ChartConfig.animationDuration
        },
        tooltips: {
            enabled: true,
            callbacks: {
                title: function (tooltipItem: any, data: any) {
                    return "";
                },
                label: (tooltipItem: any, data: any) => {
                    if (data.datasets[0].data[tooltipItem.index] > 0) {
                        return formatValue(data.datasets[0].data[tooltipItem.index].toFixed(2), data.valuetype);
                    } else {
                        return `${data.datasets[0].data[tooltipItem.index]}`;
                    }
                }
            }
        },
        hover: {
            mode: undefined
        },
        plugins: {
            datalabels: {
                display: () => false
            }
        }
    };

    const center = props.data.centerText ? <h1 className="Overlay">{props.data.centerText}</h1> : React.Fragment;
    const data = props.data;
    data.datasets.forEach((dataset) => {
        if (dataset.data.length === 1) {
            // Only ONE value available. Add rest of 100.
            dataset.data.push(dataset.data[0] - 100);
        }
    });
    return (
        <div className="Doughnut" ref={ref}>
            {dimension.width && dimension.height && (
                <DoughnutChartjs height={dimension.height} width={dimension.width} data={data} options={options} />
            )}
            {center}
        </div>
    );
};
