import React from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { PortalAccountlistsByRealestateId } from "src/api/generated/PortalAccountlistsByRealestateId";
import "src/screens/realestates/realestate/finances/Finances.css";

interface AccountlistDropdownProps {
    selectedlistid: any;
    realestateid: any;
    data: PortalAccountlistsByRealestateId;
    onChange: (newid: string) => void;
}

const AccountlistDropdownBase = (props: AccountlistDropdownProps) => {
    const getAccountlistType = (type: number | null): string => {
        if (type === null) return "";
        const result = props.data.common_enumerations.find((enumtype) => enumtype.key === type);
        return result?.label || "";
    };

    const accountsSelectorItems: { label: string; value: string }[] = props.data.portal_accountlists.map(
        (accountlist) => {
            return {
                label: `${accountlist.title || "-"}: ${getAccountlistType(accountlist.type)}`,
                value: accountlist.id
            };
        }
    );

    const accountsSelectorLabel =
        accountsSelectorItems.find((item) => item.value === props.selectedlistid)?.label || "";

    return (
        <UI.Dropdown
            id="accountsselector"
            label={accountsSelectorLabel}
            items={accountsSelectorItems}
            onSelect={props.onChange}
        />
    );
};

export const AccountlistDropdown = Sentry.withProfiler(AccountlistDropdownBase);
