import { gql } from "@apollo/client";

export enum ScreenType {
    DASHBOARD = 101,
    TENANTS = 102,
    FINANCE = 103,
    DOCUMENTS = 104,
    UNIT = 111,
    PERSONS = 120,
    PERSON = 121,
    ACCOUNT = 131,
    APPLIANCES = 140,
    APPLICANCE = 141,
    MAINTENANCES = 150,
    MAINTENANCE = 151,
    TENANT = 160
}

export const GET_TILES_BY_SCREEN = gql`
    query GetTilesByScreen(
        $realestateid: uuid!
        $screen: Int!
        $lang: String!
        $tiletypes: [Int!] = [1, 2, 3, 4, 5, 6, 7, 8]
    ) {
        portal_tilescreens(
            where: {
                tile: { realestateid: { _eq: $realestateid }, tiletype: { _in: $tiletypes } }
                screen: { _eq: $screen }
            }
            order_by: { sort: asc }
        ) {
            tile {
                id
                realestateid
                charttype
                tiles_mls(where: { language: { _eq: $lang } }) {
                    title
                }
                tiledatasets(order_by: { sort: asc }) {
                    id
                    tiledatasets_mls(where: { language: { _eq: $lang } }) {
                        subtitle
                        label
                    }
                    sort
                    tiledatavalues(order_by: { sort: asc }) {
                        id
                        group
                        value
                        tiledatavalues_mls(where: { language: { _eq: $lang } }) {
                            label
                            hint
                        }
                        sort
                        valuetype
                    }
                }
            }
        }
    }
`;

export const GET_TILE_INFORMATION = gql`
    query GetTileInformation($lang: String = "de", $customerid: uuid!) {
        tilecustomersettings: ums_customersettings(where: { key: { _eq: "PORTAL.TILECONFIGURATION" } }) {
            default
            id
            value
        }

        tiletypes: common_enumerations(
            where: { type: { _eq: 304 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }

        aggregatedportaltiles: portal_tiles_aggregate(
            where: { customerid: { _eq: $customerid } }
            distinct_on: tiletype
        ) {
            calculatedtiletypes: nodes {
                tiletype
            }
        }
    }
`;
