import React, { useContext } from "react";
import "src/components/contactsales/ContactSales.css";
import { ContactItemSales, ContactItemRow } from "src/components/contactsales/contactitemsales/ContactItemSales";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { CustomerType } from "src/components/customer/Customer";
import contacts from "./ContactSales.json";
import { ErpType } from "src/network/User";

export enum ContactItemType {
    PHONE,
    MAIL
}

export const ContactCustomerSuccess = () => {
    const { navStore, authStore } = useContext(RootStoreContext);

    const contactCustomerSuccess = contacts.contactCustomerSuccess;

    const contentExtenso: Array<{ type: ContactItemType; content: string | null }> = [
        { type: ContactItemType.PHONE, content: contactCustomerSuccess[0]?.tel || null },
        { type: ContactItemType.MAIL, content: contactCustomerSuccess[0]?.email || null }
    ];
    const contentWWImmoTop2: Array<ContactItemRow> = [
        { type: ContactItemType.PHONE, content: contactCustomerSuccess[1]?.tel || null },
        { type: ContactItemType.MAIL, content: contactCustomerSuccess[1]?.email || null }
    ];
    const contentWWRimoR5: Array<ContactItemRow> = [
        { type: ContactItemType.PHONE, content: contactCustomerSuccess[2]?.tel || null },
        { type: ContactItemType.MAIL, content: contactCustomerSuccess[2]?.email || null }
    ];

    return (
        <UI.Col className="ContactCustomerSuccess">
            <div>
                {navStore.customer.partner === CustomerType.WW && authStore.user?.erpType === ErpType.IT2 ? (
                    <>
                        <div className="ContactTitle">{contactCustomerSuccess[1].department}</div>
                        <ContactItemSales name={""} contacts={contentWWImmoTop2} />
                    </>
                ) : navStore.customer.partner === CustomerType.WW && authStore.user?.erpType === ErpType.RIMO ? (
                    <>
                        <div className="ContactName">{contactCustomerSuccess[2].department}</div>
                        <ContactItemSales name={""} contacts={contentWWRimoR5} />
                    </>
                ) : navStore.customer.partner === CustomerType.EXTENSO ? (
                    <>
                        <div className="ContactTitle">{contactCustomerSuccess[0].department}</div>
                        <ContactItemSales name={""} contacts={contentExtenso} />
                    </>
                ) : undefined}
            </div>
        </UI.Col>
    );
};
