import React, { useContext, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { UI, ScreenSize } from "@wwimmo/ui";
import styles from "src/components/tiles/charts/GenericList.module.css";

interface GenericListProps {
    height: number;
    data: GenericListData;
}

interface GenericListData {
    label?: string;
    subtitle?: string;
    headerRows?: Array<string[] | undefined>;
    listRows?: Array<string[] | undefined>;
    listColumns?: string[] | undefined;
    numberOfColumns?: number;
}

const GenericListBase = (props: GenericListProps) => {
    const { uiStore } = useContext(RootStoreContext);

    const renderItem = useCallback(
        (rowItems: string[], screenSize: ScreenSize) => {
            const listCells: JSX.Element[] = [];
            let listRows: React.ReactElement[] = [];

            if (rowItems.length > 0) {
                rowItems.forEach((rowItem, index) => {
                    let colspanString = "";
                    if (props.data.listColumns && props.data.listColumns.length > 0) {
                        colspanString = props.data.listColumns[index] ?? "";
                    }
                    let colspan: number = Number(colspanString) ?? 1;

                    listCells.push(<UI.List.Cell key={`c-${index}`} colspan={colspan} value={rowItem} />);
                });

                listRows = [<UI.List.Row key={"r-1"}>{listCells}</UI.List.Row>];

                return (
                    <UI.List.Item
                        key={`item-${rowItems[0]}`}
                        screenSize={screenSize}
                        rows={listRows}
                        className={styles.GenericListItem}
                    />
                );
            } else {
                return <></>;
            }
        },
        [props.data.listColumns]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            const headerCells: JSX.Element[] = [];
            let headerRows: React.ReactElement[] = [];

            if (
                props.data.headerRows &&
                props.data.headerRows.length > 0 &&
                props.data.headerRows[0] &&
                props.data.headerRows[0].length > 0
            ) {
                props.data.headerRows[0].forEach((headerItem, index) => {
                    let colspanString = "";
                    if (props.data.listColumns && props.data.listColumns.length > 0) {
                        colspanString = props.data.listColumns[index] ?? "";
                    }
                    let colspan: number = Number(colspanString) ?? 1;

                    headerCells.push(<UI.List.Cell key={`c-${index}`} colspan={colspan} value={headerItem} />);
                });

                headerRows = [<UI.List.Row key={"r-1"}>{headerCells}</UI.List.Row>];

                return <UI.List.Header key={"generic-list-header"} rows={headerRows} />;
            } else {
                return <></>;
            }
        },
        [props.data.headerRows, props.data.listColumns]
    );

    const listItems: Array<string[]> = [];

    if (props.data.listRows && props.data.listRows.length > 0) {
        props.data.listRows.forEach((listRow) => {
            if (listRow) {
                listItems.push(listRow);
            }
        });
    }

    return (
        <div className={styles.ListContainer}>
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={listItems}
                renderItem={renderItem}
                renderHeader={renderHeader}
            />
        </div>
    );
};

export const GenericList = observer(GenericListBase);
