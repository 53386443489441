export const findDifferences = (
    objA: any,
    objB: any,
    showOnlyTopLevelDifferences: boolean = false,
    variablesToExclude?: string[]
): string[] => {
    const differences: string[] = [];
    const differencesTopLevel = new Set<string>();

    const origKeysA = Object.keys(objA);
    const origKeysB = Object.keys(objB);

    function compare(objA: any, objB: any, prefix: string = ""): void {
        // Skip comparison if variable is in variablesToExclude
        if (variablesToExclude && variablesToExclude.includes(prefix)) {
            return;
        }

        // Skip comparison if variable only exists in objB
        if (origKeysB.includes(prefix) && !origKeysA.includes(prefix)) {
            return;
        }

        // Skip comparison if the value is a function
        if (typeof objA === "function" || typeof objB === "function") {
            return;
        }

        // If both objA and objB are the same object or have the same value, return
        if (objA === objB) {
            return;
        }

        // If either objA or objB is null or not an object, they are different
        if (typeof objA !== "object" || objA === null || typeof objB !== "object" || objB === null) {
            differences.push(prefix);
            return;
        }

        const keysA = Object.keys(objA);
        const keysB = Object.keys(objB);
        const allKeys = new Set([...keysA, ...keysB]);

        allKeys.forEach((key) => {
            const newPrefix = prefix ? `${prefix}.${key}` : key;
            compare(objA[key], objB[key], newPrefix);
        });
    }

    compare(objA, objB);

    differences.forEach((difference) => {
        const differenceSubObjectLevel = difference.split(".");

        if (differenceSubObjectLevel.length > 1) {
            differencesTopLevel.add(difference.split(".")[0]);
        } else {
            differencesTopLevel.add(difference);
        }
    });

    return showOnlyTopLevelDifferences ? Array.from(differencesTopLevel) : differences;
};

export const isEmptyObject = (obj: any): boolean => {
    return Object.keys(obj).length === 0;
};
