import React, { useContext, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize, DropdownItem } from "@wwimmo/ui";
import { getDate } from "src/utils/Date";
import { GetCustomerInvoicedRentalsVariable_rentals_variable as rentRentalDetail } from "src/api/generated/GetCustomerInvoicedRentalsVariable";
import styles from "./RentalVariableDetailsList.module.css";

interface RentalVariableDetailsListProps {
    rentals: rentRentalDetail[]
}

enum RentalVariableDetailsSortKey {
    REFERENCENUMBER = "1",
    REALESTATES = "2",
    UNIT = "3",
    TENANT = "4",
    RENTAL_DATE = "5"
}

const RentalVariableDetailsList = (props: RentalVariableDetailsListProps) => {

    const { uiStore, navStore } = useContext(RootStoreContext);
    const [ displayedRentals, setDisplayedRentals] = useState<rentRentalDetail[]>([]);
    const { t } = useTranslation();

    let listIdentifier = "rentaVariableDetailsList";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    let currentSearchQuery: string | undefined = undefined;

    if (listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    }

    let currentSortQuery: string | undefined = undefined;

    if (listParameters && listParameters.sortValue) {
        currentSortQuery = listParameters.sortValue;
    }

    const sortDropdownItems: DropdownItem[] = [
        { value: RentalVariableDetailsSortKey.REFERENCENUMBER, label: t("screens.rentals.referencenumber") },
        { value: RentalVariableDetailsSortKey.REALESTATES, label: t("screens.rentals.realestate") },
        { value: RentalVariableDetailsSortKey.UNIT, label: t("screens.rentals.unit") },
        { value: RentalVariableDetailsSortKey.TENANT, label: t("screens.rentals.tenant") },
        { value: RentalVariableDetailsSortKey.RENTAL_DATE, label: t("screens.rentals.applicationfetchedat") }
    ];

    const initialSortItem = sortDropdownItems.find((item) => item.value === currentSortQuery);

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listIdentifier, listParameters]
    );

    const renderItem = useCallback(
        (rental: rentRentalDetail, screenSize: ScreenSize) => {

            let listRows: React.ReactElement[] = [];

            let date = undefined;

            if (rental.date) {
                date = getDate(rental.date);
            }

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1.75} value={rental.referencenumber ? rental.referencenumber : ""} />
                            <UI.List.Cell key={"c-2"} colspan={2.25} value={rental.realestatename ? rental.realestatename : ""} />
                            <UI.List.Cell key={"c-3"} colspan={2.25} value={rental.unitname? rental.unitname: ""} />
                            <UI.List.Cell key={"c-4"} colspan={2.25} value={rental.tenantname? rental.tenantname: ""} />
                            <UI.List.Cell key={"c-5"} colspan={2.25} value={date? date: ""} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1.75} value={rental.referencenumber ? rental.referencenumber : ""} />
                            <UI.List.Cell key={"c-2"} colspan={1.75} value={rental.tenantname ? rental.tenantname : ""} />
                            <UI.List.Cell key={"c-3"} colspan={1.75} value={date? date: ""} />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Item key={`${rental.referencenumber}`} screenSize={screenSize} rows={listRows} />;
        },
        []
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"} className={styles.ListHeader}>
                            <UI.List.Cell key={"c-1"} colspan={1.75} value={t("screens.rentals.referencenumber")} />
                            <UI.List.Cell key={"c-2"} colspan={2.25} value={t("screens.rentals.realestate")} />
                            <UI.List.Cell key={"c-3"} colspan={2.25} value={t("screens.rentals.unit")} />
                            <UI.List.Cell key={"c-4"} colspan={2.25} value={t("screens.rentals.tenant")} />
                            <UI.List.Cell key={"c-5"} colspan={2.25} value={t("screens.rentals.applicationfetchedat")} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1.75} value={t("screens.rentals.referencenumber")} />
                            <UI.List.Cell key={"c-2"} colspan={1.75} value={t("screens.rentals.applicationfetchedat")} />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} sticky={true} top={80} />;
        },
        [t]
    );

    const getRentalsCompareValue = useCallback(
        (rentalA: rentRentalDetail, rentalB: rentRentalDetail, compareType: string) => {
            let valueRentalA = "";
            let valueRentalB = "";

            let dateRentalA: Date | undefined;
            let dateRentalB: Date | undefined;

            switch (compareType) {
                case RentalVariableDetailsSortKey.REFERENCENUMBER:
                    valueRentalA = rentalA.referencenumber ? rentalA.referencenumber.toLowerCase().trim() : "";
                    valueRentalB = rentalB.referencenumber ? rentalB.referencenumber.toLowerCase().trim() : "";
                    break;                        
                case RentalVariableDetailsSortKey.REALESTATES:
                    valueRentalA = rentalA.realestatename ? rentalA.realestatename.toLowerCase().trim() : "";
                    valueRentalB = rentalB.realestatename ? rentalB.realestatename.toLowerCase().trim() : "";
                    break;
                case RentalVariableDetailsSortKey.UNIT:
                    valueRentalA = rentalA.unitname ? rentalA.unitname.toLowerCase().trim() : "";
                    valueRentalB = rentalB.unitname ? rentalB.unitname.toLowerCase().trim() : "";
                    break;
                case RentalVariableDetailsSortKey.TENANT:
                    valueRentalA = rentalA.tenantname ? rentalA.tenantname.toLowerCase().trim() : "";
                    valueRentalB = rentalB.tenantname ? rentalB.tenantname.toLowerCase().trim() : "";
                    break;
                case RentalVariableDetailsSortKey.RENTAL_DATE:
                    dateRentalA = new Date(rentalA.date);
                    dateRentalB = new Date(rentalB.date);
                    break;
                default:
                    break;
            }

            if (compareType !== RentalVariableDetailsSortKey.RENTAL_DATE) {
                if (valueRentalA.length > 0 && valueRentalB.length > 0) {
                    if (valueRentalA < valueRentalB) {
                        return -1;
                    }

                    if (valueRentalA > valueRentalB) {
                        return 1;
                    }

                    return 0;
                } else if (valueRentalA.length === 0 && valueRentalB.length > 0) {
                    return 1;
                } else if (valueRentalA.length > 0 && valueRentalB.length === 0) {
                    return -1;
                } else {
                    return 0;
                }
            } else {
                if (dateRentalA && dateRentalB) {
                    if (dateRentalA < dateRentalB) {
                        return 1;
                    }

                    if (dateRentalA > dateRentalB) {
                        return -1;
                    }

                    return 0;
                } else if (!dateRentalA && dateRentalB) {
                    return 1;
                } else if (dateRentalA && !dateRentalB) {
                    return -1;
                } else {
                    return 0;
                }
            }
        },
        []
    );

    const searchAndSortRentals = useCallback(
        (searchQuery: string = "", sortItemValue: string = "0") => {
            if (props.rentals) {
                const foundRentals = props.rentals.filter((rental) => {
                    
                    let date = getDate(rental.date);
                                        
                    return (
                        rental.referencenumber?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        rental.realestatename?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        rental.unitname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        rental.tenantname?.toLowerCase().includes(searchQuery.toLowerCase()) || 
                        (date !== undefined && date.length > 0 ? date.toLowerCase().includes(searchQuery.toLowerCase()) : false)
                    );
                });

                const sortedRentals = foundRentals.sort((rentalA, rentalB) => {
                    return getRentalsCompareValue(rentalA, rentalB, sortItemValue);
                });

                setDisplayedRentals([...sortedRentals]);
            }
        },
        [props.rentals, getRentalsCompareValue]
    );

    const handleSearchInput = useCallback(
        ({ searchQuery, sortItemValue }) => {
            searchAndSortRentals(searchQuery, sortItemValue);

            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: searchQuery, sortValue: sortItemValue }
                    : { scrollPosition: 0, searchQuery: searchQuery, sortValue: sortItemValue }
            );
        },
        [searchAndSortRentals, navStore, listIdentifier, listParameters]
    );

    const onChangeSort = useCallback(
        ({ searchQuery, sortItemValue }) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, sortValue: sortItemValue, searchQuery: searchQuery }
                    : { sortValue: sortItemValue, searchQuery: searchQuery }
            );

            searchAndSortRentals(searchQuery, sortItemValue);
        },
        [searchAndSortRentals, navStore, listIdentifier, listParameters]
    );

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={displayedRentals}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={25}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
            onSearch={handleSearchInput}
            initialSearchQuery={currentSearchQuery}
            sortItems={sortDropdownItems}
            initialSortItem={initialSortItem}
            onChangeSort={onChangeSort}
        />
    );
};

export default observer(RentalVariableDetailsList);
