import React, { useCallback, useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./CreateOrderProgress.module.css";
import generalStyles from "src/screens/tickets/ticket/details/actions/assignOrder/AssignOrderModal.module.css";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { ORDER_CREATION_STATUS } from "src/stores/tickets/AssignOrderModalStore";

interface CreateOrderProgressProps {
    currentCreateOrderState: ORDER_CREATION_STATUS;
}

export const CreateOrderProgressBase = (props: CreateOrderProgressProps) => {
    const { t } = useTranslation();
    const { assignOrderModalStore } = useContext(RootStoreContext);

    const { currentCreateOrderState } = props;

    const onClickFinishCreateOrder = useCallback(() => {
        assignOrderModalStore.setIsModalDisplayed(false);
        assignOrderModalStore.resetAssignOrderModal();
    }, [assignOrderModalStore]);

    let icon;
    let text;

    switch (currentCreateOrderState) {
        case ORDER_CREATION_STATUS.IN_PROGRESS:
            icon = <UI.RotatingSpinner noLogo size={50} className="loading-items-spinner" />;
            text = t("screens.tickets.modals.assign_order.order_creation_in_progress").toString();
            break;
        case ORDER_CREATION_STATUS.SUCCESS:
            icon = <UI.Icon icon={UI.SVGIcon.Check} size={50} color={"black"} />;
            text = t("screens.tickets.modals.assign_order.order_creation_success", {
                email: assignOrderModalStore.selectedServiceProvider?.email
            }).toString();
            break;
    }

    return (
        <UI.Row className={"mt-2 d-flex"}>
            <UI.Col
                xs={12}
                className={`d-flex align-items-center justify-content-center ${styles.CreatingOrderProgressContainer}`}
            >
                <div className="d-flex align-items-center flex-column">
                    <div>{icon}</div>
                    <div className="d-flex mt-2">
                        <span className={`${styles.CreatingOrderProgressText} text-center`}>{text}</span>
                    </div>
                </div>
            </UI.Col>
            <UI.Col xs={12} className="mt-4">
                <UI.Button
                    className={`ticket-button mt-2 ${generalStyles.NextButton}`}
                    label={t("labels.finished").toString()}
                    disabled={currentCreateOrderState === ORDER_CREATION_STATUS.IN_PROGRESS ? true : false}
                    onClick={onClickFinishCreateOrder}
                ></UI.Button>
            </UI.Col>
        </UI.Row>
    );
};

export const CreateOrderProgress = Sentry.withProfiler(observer(CreateOrderProgressBase));
