import { ApolloClient, from, HttpLink, InMemoryCache, ApolloLink, split } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import * as Sentry from "@sentry/react";
import { NetworkConfig } from "./NetworkConfig";
import { getRoleKey } from "./User";
import { AuthStore } from "src/stores/AuthStore";

export const createApolloClient = (authStore: AuthStore): ApolloClient<any> => {
    const httpLink = new HttpLink({
        uri: NetworkConfig.graphqlUrl
    });

    const wsLink = new GraphQLWsLink(
        createClient({
            url: NetworkConfig.graphqlWssUrl,
            connectionParams: () => {
                return {
                    reconnect: true,
                    headers: {
                        // Für den Ws-Link wird hier das Auth direkt gesetzt.
                        Authorization: authStore.token ? `Bearer ${authStore.token}` : "",
                        "x-hasura-role": getRoleKey(authStore.user?.role)
                    }
                };
            }
        })
    );

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === "OperationDefinition" && definition.operation === "subscription";
        },
        wsLink, // use wsLink for subscriptions only
        httpLink // use httpLink as default
    );

    const queryPerformanceLink = new ApolloLink((operation, forward) => {
        const definition = getMainDefinition(operation.query);
        const transaction = Sentry.startTransaction({
            name: operation.operationName,
            op:
                definition.kind === "OperationDefinition" && definition.operation === "subscription"
                    ? "subscription"
                    : "query",
            data: operation.variables
        });

        operation.setContext({ transaction });

        return forward(operation).map((data) => {
            const transaction = operation.getContext().transaction;
            transaction.finish();
            return data;
        });
    });

    const authLink = setContext((_, { headers }) => {
        let manuallySetHasuraRole = undefined;

        if (headers && headers.hasOwnProperty("x-hasura-role")) {
            manuallySetHasuraRole = headers["x-hasura-role"];
        }

        const headerObject = authStore.token
            ? {
                  ...headers,
                  Authorization: authStore.token ? `Bearer ${authStore.token}` : "",
                  "x-hasura-role":
                      manuallySetHasuraRole ?? authStore.currentlyUsedRole ?? getRoleKey(authStore.user?.role)
              }
            : { ...headers };

        return {
            headers: headerObject
        };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message, extensions }) => {
                console.log(`[GraphQL error] Code: ${extensions?.code}; Message: ${message}`);
                if (extensions?.code === "invalid-jwt") {
                    authStore.setToken(undefined);
                }
            });
        }
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const client = new ApolloClient({
        link: from([queryPerformanceLink, errorLink, authLink, splitLink]),
        cache: new InMemoryCache({
            typePolicies: {
                file_v_manager_files_overview: {
                    keyFields: ["id", "category"]
                },
                file_v_owner_files_overview: {
                    keyFields: ["id", "category"]
                },
                file_v_coowner_files_overview: {
                    keyFields: ["id", "category"]
                }
            }
        })
    });

    return client;
};
