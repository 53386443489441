import React from "react";
import { UI } from "@wwimmo/ui";
import { Ticket } from "src/stores/TicketStore";
import { format } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import styles from "./TicketListRow.module.css";
import { getIcon } from "src/screens/tickets/Tickets";

export const TicketListRowMobile = (props: { ticket: Ticket }) => {
    const { number, date, workflow_type, appliance, realestate, assignedUser, title } = props.ticket;
    const { t } = useTranslation();
    return (
        <UI.List.Row className={styles.TicketListRowMobile} key={`row-mobile-${number}`}>
            <div className={styles.MobileTop}>
                <div className={styles.TypeIconMobile}>
                    <img
                        src={getIcon(workflow_type)}
                        alt={t(`easyticket.workflow_types.${String(workflow_type)}`) ?? "icon"}
                    />
                </div>
                <div className={styles.MobileLeft}>
                    <div>
                        <strong>
                            {number} -{" "}
                            {title ? title : <Trans>easyticket.workflow_types.{String(workflow_type)}</Trans>}
                        </strong>
                    </div>
                    {appliance && <div>{appliance.name}</div>}
                </div>
                <div className={styles.MobileRight}>{/* Action Button */}</div>
            </div>
            <div className={styles.MobileBottom}>
                {assignedUser && (
                    <div className={styles.Avatar}>{`${assignedUser.name1.charAt(0)}${
                        assignedUser.name2?.charAt(0) ?? ""
                    }`}</div>
                )}
                <div className={styles.MobileLeft}>
                    {realestate && (
                        <div>
                            <div>{realestate.name}</div>
                            <div>
                                {realestate.zip} {realestate.city}
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.MobileRight}>{date && format(new Date(date), "dd.MM.yy")}</div>
            </div>
        </UI.List.Row>
    );
};
