import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { IComponent, IMAGE_TYPE, QUESTION_TO_DOCUMENT_NOTIFICATION_PROPERTY } from "src/stores/TicketStore";
import styles from "./QuestionToDocumentNotificationFormFields.module.css";
import "./GeneralFormStyles.css";
import { ImageSelectorContainer } from "src/components/imageSelector/ImageSelectorContainer";
import { ImageSelectorItem } from "src/components/imageSelector/ImageSelectorItem";

const QuestionToDocumentNotificationFormFieldsBase = () => {
    const { t } = useTranslation();
    const { ticketStore, questionToDocumentNotificationStore } = useContext(RootStoreContext);

    const onClickComponentTreeElement = useCallback(
        (component: IComponent) => (e: any) => {
            questionToDocumentNotificationStore.handleClickOnComponentTreeElement(component);
        },
        [questionToDocumentNotificationStore]
    );

    const onClickEditComponentTree = useCallback(() => {
        questionToDocumentNotificationStore.handleEditComponentTreeAfterFinishedSelection();
    }, [questionToDocumentNotificationStore]);

    const componentTree = (
        <>
            <div className={styles.ComponentTreeContainer}>
                <div className={styles.ComponentTreeRow}>
                    {questionToDocumentNotificationStore.formComponentTreeData.map((component) => {
                        const componentTreeElement = (
                            <div
                                key={component.id}
                                className={`${styles.ComponentTreeRowElement} ${
                                    component.isSelected ? styles.Selected : ""
                                } ${
                                    questionToDocumentNotificationStore.isComponentSelectionFinished
                                        ? styles.ComponentSelectionFinished
                                        : ""
                                }`}
                                onClick={onClickComponentTreeElement(component)}
                            >
                                {component.title}
                            </div>
                        );

                        return questionToDocumentNotificationStore.isComponentSelectionFinished
                            ? component.isSelected
                                ? componentTreeElement
                                : undefined
                            : componentTreeElement;
                    })}
                </div>
                {questionToDocumentNotificationStore.isComponentSelectionFinished && ticketStore.isEditing ? (
                    <div
                        key={"component-tree-edit-button-container"}
                        className={`${styles.ComponentTreeRowElement} ${styles.EditButton}`}
                        onClick={onClickEditComponentTree}
                    >
                        {t("screens.tickets.form.edit").toString()}
                    </div>
                ) : undefined}
            </div>
            {ticketStore.currentTicket?.errors.questionToDocumentSelectDocumentType ? (
                <div className={styles.Error}>
                    {ticketStore.currentTicket?.errors.questionToDocumentSelectDocumentType}
                </div>
            ) : undefined}
        </>
    );

    const onChangeQuestionToDocumentNotificationField = useCallback(
        (questionToDocumentNotificationProperty: QUESTION_TO_DOCUMENT_NOTIFICATION_PROPERTY) =>
            (e: React.ChangeEvent<HTMLInputElement>) => {
                let newValue: any = e.target.value;

                if (
                    questionToDocumentNotificationProperty ===
                    QUESTION_TO_DOCUMENT_NOTIFICATION_PROPERTY.IS_OTHER_DOCUMENT_COMPONENT_SELECTED
                ) {
                    const oldValue =
                        ticketStore.currentTicket?.questionToDocumentNotificationDetails
                            ?.isOtherDocumentComponentSelected;
                    newValue = !oldValue;
                }

                if (ticketStore.currentTicket) {
                    ticketStore.currentTicket.updateQuestionToDocumentNotificationProperty(
                        questionToDocumentNotificationProperty,
                        newValue
                    );
                }
            },
        [ticketStore.currentTicket]
    );

    const onBlurField = useCallback(
        (e: any) => {
            ticketStore.currentTicket?.validateQuestionToDocumentNotificationDetails();
        },
        [ticketStore.currentTicket]
    );

    const updateTicketImageProperty = useCallback(
        (questionToDocumentOrderNotificationProperty: QUESTION_TO_DOCUMENT_NOTIFICATION_PROPERTY) =>
            (newValue: any, fileId?: string) => {
                if (ticketStore.currentTicket) {
                    ticketStore.currentTicket.updateQuestionToDocumentNotificationProperty(
                        questionToDocumentOrderNotificationProperty,
                        newValue,
                        fileId
                    );
                }
            },
        [ticketStore.currentTicket]
    );

    const images = ticketStore.currentTicket?.questionToDocumentNotificationDetails?.images || [];
    const imagesToRender = [...images, null].slice(0, 8); // Limit to 8 items

    const questionToDocumentNotificationDetails = (
        <div className="p-2">
            {/* OTHER DOCUMENT DESCRIPTION ROW */}
            {ticketStore.currentTicket?.questionToDocumentNotificationDetails?.isOtherDocumentComponentSelected ? (
                <UI.Row>
                    <UI.Col>
                        <UI.Input
                            label={`${t(
                                "screens.tickets.form.notification.question_to_document.document_other"
                            ).toString()} *`}
                            type="text"
                            autoComplete="off"
                            value={
                                ticketStore.currentTicket &&
                                ticketStore.currentTicket.questionToDocumentNotificationDetails
                                    ? ticketStore.currentTicket.questionToDocumentNotificationDetails
                                          ?.otherDocumentName ?? ""
                                    : ""
                            }
                            onChange={onChangeQuestionToDocumentNotificationField(
                                QUESTION_TO_DOCUMENT_NOTIFICATION_PROPERTY.OTHER_DOCUMENT_NAME
                            )}
                            onBlur={onBlurField}
                            errorMsg={
                                ticketStore.currentTicket?.errors.questionToDocumentOtherDocumentName ?? undefined
                            }
                            disabled={!ticketStore.isEditing}
                        />
                    </UI.Col>
                </UI.Row>
            ) : undefined}

            {/* DOCUMENT MESSAGE ROW */}
            <UI.Row className={`${!ticketStore.isEditing ? "view-only-container" : ""}`}>
                <UI.Col>
                    <UI.Input
                        label={`${t("screens.tickets.form.notification.question_to_document.message").toString()} *`}
                        type="text"
                        as="textarea"
                        autoComplete="off"
                        value={
                            ticketStore.currentTicket && ticketStore.currentTicket.questionToDocumentNotificationDetails
                                ? ticketStore.currentTicket.questionToDocumentNotificationDetails?.message ?? ""
                                : ""
                        }
                        onChange={onChangeQuestionToDocumentNotificationField(
                            QUESTION_TO_DOCUMENT_NOTIFICATION_PROPERTY.MESSAGE
                        )}
                        errorMsg={ticketStore.currentTicket?.errors.questionToDocumentMessage ?? undefined}
                        onBlur={onBlurField}
                        disabled={!ticketStore.isEditing}
                        className={styles.TextArea}
                    />
                </UI.Col>
            </UI.Row>

            {/* DOCUMENT IMAGE ROW */}
            <UI.Row>
                <UI.Col>
                    <div>
                        <span className={styles.ImagesLabel}>{t("screens.tickets.form.notification.images")}</span>
                        <ImageSelectorContainer>
                            {imagesToRender.map((image, index) => (
                                <ImageSelectorItem
                                    key={index}
                                    label={`${t("screens.tickets.form.notification.image")} ${t(
                                        "screens.tickets.form.notification.question_to_document.document"
                                    )} ${index + 1}`}
                                    imageType={IMAGE_TYPE.DOCUMENT}
                                    image={image || {}}
                                    updateTicketImagePropertyFunction={updateTicketImageProperty(
                                        QUESTION_TO_DOCUMENT_NOTIFICATION_PROPERTY.IMAGES
                                    )}
                                    disabled={!ticketStore.isEditing}
                                />
                            ))}
                        </ImageSelectorContainer>
                    </div>
                </UI.Col>
            </UI.Row>
        </div>
    );

    return (
        <div>
            {componentTree}
            {questionToDocumentNotificationStore.isComponentSelectionFinished
                ? questionToDocumentNotificationDetails
                : undefined}
        </div>
    );
};

export const QuestionToDocumentFormFields = Sentry.withProfiler(observer(QuestionToDocumentNotificationFormFieldsBase));
