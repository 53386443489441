import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_REALESTATE_PERSONS } from "src/api/realestatePersons";
import { GetRealestatePersons, GetRealestatePersonsVariables } from "src/api/generated/GetRealestatePersons";
import RealestatePersonsList from "src/screens/realestates/realestate/persons/list/RealestatePersonsList";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { Route, selectRoute } from "src/config/routes";
import { withRouter, RouteComponentProps, useParams } from "react-router";
import { getLang } from "src/utils/i18n";
import { ScreenWithTiles } from "src/components/screenWithTiles/ScreenWithTiles";
import { ScreenType } from "src/api/tiles";

interface Params {
    realestateid: string;
}

const RealestatePersonsBase = (props: RouteComponentProps) => {
    const { realestateid } = useParams<Params>();

    const { t } = useTranslation();
    const { navStore, authStore } = useContext(RootStoreContext);

    useEffect(() => {
        navStore.setBackbutton(
            selectRoute(Route.dashboard, authStore.user?.role, { realestateid: realestateid }),
            t("screens.realestate.dashboard.title")
        );
    });

    const { data, loading, error } = useQuery<GetRealestatePersons, GetRealestatePersonsVariables>(
        GET_REALESTATE_PERSONS,
        {
            variables: {
                realestateid: realestateid,
                lang: getLang()
            }
        }
    );

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const title = navStore.isConsolidation
        ? t("screens.realestate.realestatepersons.title_short")
        : t("screens.realestate.realestatepersons.title");

    return (
        <>
            <ScreenWithTiles title={title} screenId={ScreenType.PERSONS} realestateid={realestateid}>
                <UI.Card>
                    <RealestatePersonsList
                        realestatepersons={data.realestatepersons}
                        realestatepersonroles={data.realestatepersonroles}
                    />
                </UI.Card>
            </ScreenWithTiles>
        </>
    );
};

export const RealestatePersons = withRouter(Sentry.withProfiler(observer(RealestatePersonsBase)));
