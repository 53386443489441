import { gql } from "@apollo/client";

export const FILE_OVERVIEW_CATEGORY_FIELDS = gql`
    fragment fields_file_categories on file_v_files_category_overview {
        id
        key
        label
    }
`;

export const GET_MANAGER_FILES_BY_REALESTATEID = gql`
    ${FILE_OVERVIEW_CATEGORY_FIELDS}
    query GetManagerFilesByRealEstateId($realestateid: uuid!, $lang: String! = "de", $limit: Int) {
        realestates: common_realestates(where: { id: { _eq: $realestateid } }) {
            files: managerfilesoverview(
                where: { language: { _eq: $lang } }
                limit: $limit
                order_by: { filedate: desc }
            ) {
                id
                category
                categorylabel
                name
                mimetype
                fileid
                filedate
                thumbnailid
            }
        }

        file_categories: file_v_files_category_overview(
            where: { language: { _eq: $lang } }
            distinct_on: label
            order_by: { label: asc }
        ) {
            ...fields_file_categories
        }
    }
`;

export const GET_OWNER_FILES_BY_REALESTATEID = gql`
    ${FILE_OVERVIEW_CATEGORY_FIELDS}
    query GetOwnerFilesByRealEstateId($realestateid: uuid!, $lang: String! = "de", $limit: Int) {
        realestates: common_realestates(where: { id: { _eq: $realestateid } }) {
            files: ownerfilesoverview(
                where: { language: { _eq: $lang } }
                limit: $limit
                order_by: { filedate: desc }
            ) {
                id
                category
                categorylabel
                name
                mimetype
                fileid
                filedate
                thumbnailid
            }
        }

        file_categories: file_v_files_category_overview(
            where: { language: { _eq: $lang } }
            distinct_on: label
            order_by: { label: asc }
        ) {
            ...fields_file_categories
        }
    }
`;

export const GET_COOWNER_FILES_BY_REALESTATEID = gql`
    ${FILE_OVERVIEW_CATEGORY_FIELDS}
    query GetCoownerFilesByRealEstateId($realestateid: uuid!, $lang: String! = "de", $limit: Int) {
        realestates: common_realestates(where: { id: { _eq: $realestateid } }) {
            files: coownerfilesoverview(
                where: { language: { _eq: $lang } }
                limit: $limit
                order_by: { filedate: desc }
            ) {
                id
                category
                categorylabel
                name
                mimetype
                fileid
                filedate
                thumbnailid
            }
        }

        file_categories: file_v_files_category_overview(
            where: { language: { _eq: $lang } }
            distinct_on: label
            order_by: { label: asc }
        ) {
            ...fields_file_categories
        }
    }
`;

export const GET_COOWNER_FILES_BY_REALESTATEID_IT2 = gql`
    ${FILE_OVERVIEW_CATEGORY_FIELDS}
    query GetCoownerFilesByRealEstateIdIT2($realestateid: uuid!, $lang: String! = "de", $limit: Int) {
        realestates: common_realestates(
            where: { _or: [{ id: { _eq: $realestateid } }, { consolidationid: { _eq: $realestateid } }] }
        ) {
            files: coownerfilesoverview(
                where: { language: { _eq: $lang } }
                limit: $limit
                order_by: { filedate: desc }
            ) {
                id
                category
                categorylabel
                name
                mimetype
                fileid
                filedate
                thumbnailid
            }
        }

        file_categories: file_v_files_category_overview(
            where: { language: { _eq: $lang } }
            distinct_on: label
            order_by: { label: asc }
        ) {
            ...fields_file_categories
        }
    }
`;

export const GET_TENANT_FILES_BY_REALESTATEID = gql`
    ${FILE_OVERVIEW_CATEGORY_FIELDS}
    query GetTenantFilesByRealEstateId($realestateid: uuid!, $lang: String! = "de", $limit: Int) {
        realestates: common_realestates(where: { id: { _eq: $realestateid } }) {
            files: tenantfilesoverview(
                where: { language: { _eq: $lang } }
                limit: $limit
                order_by: { filedate: desc }
            ) {
                id
                category
                categorylabel
                name
                mimetype
                fileid
                filedate
                thumbnailid
            }
        }

        file_categories: file_v_files_category_overview(
            where: { language: { _eq: $lang } }
            distinct_on: label
            order_by: { label: asc }
        ) {
            ...fields_file_categories
        }
    }
`;

export const GET_TENANT_FILES_BY_REALESTATEID_IT2 = gql`
    ${FILE_OVERVIEW_CATEGORY_FIELDS}
    query GetTenantFilesByRealEstateIdIT2($realestateid: uuid!, $lang: String! = "de", $limit: Int) {
        realestates: common_realestates(
            where: { _or: [{ id: { _eq: $realestateid } }, { consolidationid: { _eq: $realestateid } }] }
        ) {
            files: tenantfilesoverview(
                where: { language: { _eq: $lang } }
                limit: $limit
                order_by: { filedate: desc }
            ) {
                id
                category
                categorylabel
                name
                mimetype
                fileid
                filedate
                thumbnailid
            }
        }

        file_categories: file_v_files_category_overview(
            where: { language: { _eq: $lang } }
            distinct_on: label
            order_by: { label: asc }
        ) {
            ...fields_file_categories
        }
    }
`;
