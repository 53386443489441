import { action, computed, makeObservable, observable } from "mobx";
import { InvoiceDetail } from "src/types";
import { RootStore } from "src/stores/RootStore";

export class ServicesStore {
    rootStore: RootStore;
    invoiceDetails: InvoiceDetail[] = [];
    refetchServicesData: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this, {
            invoiceDetails: observable,
            refetchServicesData: observable,

            setInvoiceDetails: action,
            toggleInvoiceSelected: action,
            toggleAllInvoicesSelected: action,
            setRefetchServicesData: action,

            isEveryInvoiceSelected: computed,
            isAnyInvoiceSelected: computed,
            invoicesSelected: computed
        });
    }

    setInvoiceDetails = (invoiceDetailsList: InvoiceDetail[]) => {
        this.invoiceDetails = invoiceDetailsList;
    };

    setRefetchServicesData = (refetchServicesData: boolean) => {
        this.refetchServicesData = refetchServicesData;
    };

    toggleInvoiceSelected = (id: string) => {
        this.invoiceDetails = this.invoiceDetails.map((sel) => {
            if (sel.id === id) {
                let localSelected = sel.selected ?? false;
                return {
                    ...sel,
                    selected: !localSelected
                };
            } else {
                return { ...sel };
            }
        });
    };

    toggleAllInvoicesSelected = () => {
        this.invoiceDetails = this.invoiceDetails.map((sel) => {
            return {
                ...sel,
                selected: !this.isEveryInvoiceSelected
            };
        });
    };

    get isEveryInvoiceSelected(): boolean {
        return this.invoiceDetails.filter((e) => e.selected === undefined || e.selected === false).length === 0;
    }

    get isAnyInvoiceSelected(): boolean {
        return this.invoiceDetails.filter((e) => e.selected === true).length > 0;
    }

    get invoicesSelected(): InvoiceDetail[] {
        return this.invoiceDetails.filter((e) => e.selected === true);
    }
}
