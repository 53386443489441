import React, { useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import {
    GetCommonSearchText,
    GetCommonSearchTextVariables,
    GetCommonSearchText_common_searchtext as search_result
} from "src/api/generated/GetCommonSearchText";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { selectRoute, Route, SearchRoute } from "src/config/routes";
import { Role } from "src/network/User";
import { GET_COMMON_SEARCHTEXT } from "src/api/search";
import { useQuery } from "@apollo/client";
import { UI, ScreenSize } from "@wwimmo/ui";
import { ColorStyle } from "src/utils/Colors";
import { observer } from "mobx-react-lite";
import { getLang } from "src/utils/i18n";
import { NetworkConfig } from "src/network/NetworkConfig";
import "./SearchResult.css";
interface SearchResultProps {
    query: string;
    onClose: () => void;
}
const SearchResultBase = (props: SearchResultProps) => {
    const { t } = useTranslation();
    const { authStore, uiStore } = useContext(RootStoreContext);

    const getLink = useCallback(
        (
            id: string,
            type: string,
            realestateid: string,
            fileid: string,
            tenantid: string,
            accountlistid: string,
            accountid: string,
            role: Role | undefined
        ): string => {
            switch (type) {
                case "common_persons":
                    return selectRoute(SearchRoute.person, null, { personid: id });
                case "common_realestates":
                case "news_news":
                    if (authStore.user?.role === Role.AUDITOR) {
                        return selectRoute(Route.finances, role, { realestateid: realestateid });
                    } else {
                        return selectRoute(Route.dashboard, role, { realestateid: realestateid });
                    }
                case "cond_assemblys":
                case "cond_agendaitems_mls":
                    if (authStore.user?.role === Role.COOWNER) {
                        return selectRoute(Route.coownerAssemblies, role, { realestateid: realestateid });
                    }
                    return selectRoute(Route.managerAssemblies, role, { realestateid: realestateid });
                case "file_accountlistfiles":
                case "file_assemblyfiles":
                case "file_bookingfiles":
                case "file_customerfiles":
                case "file_inspectionfiles":
                case "file_inspectionitemfiles":
                case "file_inspectionpersonfiles":
                case "file_managementfiles":
                case "file_newsfiles":
                case "file_realestatefiles":
                case "file_tenantbookingfiles":
                case "file_tenantfiles":
                case "file_agendaitemfiles":
                case "file_unitfiles":
                    return NetworkConfig.datafileUrl + fileid;
                case "portal_tenantbookings":
                    return (
                        selectRoute(Route.realestate, role, { realestateid: realestateid }) +
                        `/account?filter=${tenantid}&search=${props.query}`
                    );
                case "portal_accounts":
                    return (
                        selectRoute(Route.realestate, role, { realestateid: realestateid }) +
                        `/finances/${accountlistid}/account/${accountid}`
                    );
                default:
                    return "/";
            }
        },
        [authStore.user?.role, props.query]
    );

    const variables = {
        searchtext: props.query,
        planguage: getLang()
    };

    const { loading, data } = useQuery<GetCommonSearchText, GetCommonSearchTextVariables>(GET_COMMON_SEARCHTEXT, {
        variables: variables,
        skip: props.query === ""
    });

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            const headerRows: React.ReactElement[] = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={1} value={t("search.type")} />
                    <UI.List.Cell key={"c-2"} colspan={8} value={t("search.result")} />
                </UI.List.Row>
            ];

            return <UI.List.Header rows={headerRows} />;
        },
        [t]
    );

    const renderItem = useCallback(
        (searchResult: search_result, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];

            const selectIcon = (entity: string | null) => {
                switch (entity) {
                    case "common_persons":
                        return UI.SVGIcon.Person;
                    case "common_realestates":
                        return UI.SVGIcon.Realestate;
                    case "file_accountlistfiles":
                    case "file_assemblyfiles":
                    case "file_bookingfiles":
                    case "file_customerfiles":
                    case "file_inspectionfiles":
                    case "file_inspectionitemfiles":
                    case "file_inspectionpersonfiles":
                    case "file_managementfiles":
                    case "file_newsfiles":
                    case "file_realestatefiles":
                    case "file_tenantbookingfiles":
                    case "file_tenantfiles":
                    case "file_agendaitemfiles":
                    case "file_unitfiles":
                        return UI.SVGIcon.Documents;
                    case "news_news":
                        return UI.SVGIcon.Comment;
                    case "portal_tenantbookings":
                        return UI.SVGIcon.Myaccount;
                    case "portal_accounts":
                        return UI.SVGIcon.BookingRecord;
                    case "cond_assemblys":
                        return UI.SVGIcon.Assembly;
                    case "cond_agendaitems_mls":
                        return UI.SVGIcon.Assembly;
                    default:
                        return UI.SVGIcon.Questionmark;
                }
            };

            const icon = <UI.Icon icon={selectIcon(searchResult.entity)} color={ColorStyle("primary")} />;

            const searchResultValue = (
                <>
                    <span key={"p-1"}>{searchResult.title || "-"}</span>
                    <span key={"p-2"} className="sublabel">
                        {searchResult.description || ""}
                    </span>
                </>
            );

            listRows = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={1} value={icon} className="d-flex justify-content-center" />
                    <UI.List.Cell key={"c-2"} colspan={8} value={searchResultValue} />
                </UI.List.Row>
            ];

            const link = getLink(
                searchResult.id,
                searchResult.entity || "",
                searchResult.realestateid,
                searchResult.fileid,
                searchResult.tenantid,
                searchResult.accountlistid,
                searchResult.accountid,
                authStore.user?.role
            );

            return (
                <UI.List.Item
                    key={searchResult.id}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                    className="multi-line search-result-item"
                />
            );
        },
        [authStore.user?.role, getLink]
    );

    const list = (results: GetCommonSearchText | undefined) => {
        if (results === undefined) {
            return <span>{t("error.nodata")}</span>;
        } else if (authStore.user?.customerid === undefined) {
            return <span>{t("search.no_customer_id")}</span>;
        } else {
            return (
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={results.common_searchtext}
                    renderItem={renderItem}
                    renderHeader={renderHeader}
                />
            );
        }
    };

    const SearchOverlay = (props: any) => (
        <div className="SearchResultsContainer d-flex justify-content-center">{props.children}</div>
    );

    const RotatingSpinner = (props: any) => (
        <div className="inner flex-grow-1 d-flex justify-content-center align-items-center">
            <UI.RotatingSpinner noLogo />
        </div>
    );

    const isSidebarOpen = uiStore.sidebarWidth > 0;

    return (
        <div
            className="SearchOverlay"
            style={{ borderTopLeftRadius: isSidebarOpen ? "0px" : "40px" }}
            onClick={props.onClose}
        >
            {loading === true ? (
                <SearchOverlay>
                    <RotatingSpinner />
                </SearchOverlay>
            ) : data?.common_searchtext !== undefined && data?.common_searchtext.length > 0 ? (
                <SearchOverlay>
                    <div className="inner flex-grow-1">{list(data)}</div>
                </SearchOverlay>
            ) : data?.common_searchtext !== undefined && data?.common_searchtext.length === 0 ? (
                <SearchOverlay>
                    <div className="inner flex-grow-1 text-center">{t("search.no_search_results")}</div>
                </SearchOverlay>
            ) : (
                <></>
            )}
        </div>
    );
};

export const SearchResult = Sentry.withProfiler(observer(SearchResultBase));
