import { gql } from "@apollo/client";

export enum NewsState {
    Editing = 10,
    Published = 20
}

const REALESTATE_NEWS_FRAGMENT = gql`
    fragment realestate_news on common_realestates {
        newsitems: newsrealestates(
            where: { news: { news_mls: { language: { _eq: $language } } } }
            order_by: { news: { timestamp: desc_nulls_last } }
        ) {
            newsitem: news {
                timestamp
                news: news_mls(limit: 1) {
                    id
                    lead
                    text
                    title
                }
                newsfiles(where: { type: { _eq: 601 } }, limit: 1) {
                    fileid
                }
                attachments: newsfiles(where: { type: { _eq: 602 }, roleid: { _eq: 32 } }) {
                    fileid
                    file {
                        name
                    }
                }
            }
        }
    }
`;

export const GET_COOWNER_NEWS = gql`
    ${REALESTATE_NEWS_FRAGMENT}
    query GetCoownerNews($language: String = "de", $realestateid: uuid!) {
        realestates: common_realestates(where: { id: { _eq: $realestateid } }) {
            ...realestate_news
        }
    }
`;

export const GET_COOWNER_NEWS_IT2 = gql`
    ${REALESTATE_NEWS_FRAGMENT}
    query GetCoownerNewsIT2($language: String = "de", $realestateid: uuid!) {
        realestates: common_realestates(
            where: { _or: [{ id: { _eq: $realestateid } }, { consolidationid: { _eq: $realestateid } }] }
        ) {
            ...realestate_news
        }
    }
`;

export const GET_MANAGER_NEWS = gql`
    query GetManagerNews($language: String = "de", $realestateid: uuid!) {
        newsitems: news_news(
            order_by: { inserted: desc_nulls_last }
            where: {
                news_mls: { language: { _eq: $language } }
                newsrealestates: { realestateid: { _eq: $realestateid } }
            }
        ) {
            id
            timestamp
            state
            news: news_mls {
                id
                title
            }
        }

        newsstatus: common_enumerations(
            where: { type: { _eq: 601 }, language: { _eq: $language } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;

export const GET_NEWS_PREVIEW_INFORMATION_BY_ID = gql`
    query GetNewsPreviewInformationById($newsid: uuid!, $realestateid: uuid!, $language: String = "de") {
        news: news_v_news_preview(where: { id: { _eq: $newsid }, language: { _eq: $language } }, limit: 1) {
            id
            timestamp
            state
            statelabel
            title
            lead
            text
            fileid
            author
            newsevents: newsevents_aggregate {
                events: aggregate {
                    count
                }
            }
        }
        active_coowners_count: common_v_coowners_by_realestateaccess_aggregate(
            where: { realestateid: { _eq: $realestateid } }
        ) {
            aggregate {
                count
            }
        }
        active_tenants_count: common_v_tenants_by_realestateaccess_aggregate(
            where: { realestateid: { _eq: $realestateid } }
        ) {
            aggregate {
                count
            }
        }
    }
`;

export const SUBCRIPTION_GET_LAST_INSERTED_NEWS_EVENT = gql`
    subscription GetLastNewsEventById($newsid: uuid!) {
        noti_newsevents(order_by: { inserted: desc }, limit: 1, where: { newsid: { _eq: $newsid } }) {
            inserted
        }
    }
`;

export const GET_NEWS_DETAILS_BY_ID = gql`
    query GetNewsDetailsById($newsid: uuid!, $language: String = "de") {
        newsitems: news_news(
            order_by: { timestamp: desc_nulls_last }
            where: { news_mls: { language: { _eq: $language } }, id: { _eq: $newsid } }
        ) {
            id
            timestamp
            state
            news: news_mls {
                id
                title
                lead
                text
            }
            newsfileimage: newsfiles(where: { type: { _eq: 601 } }) {
                file {
                    id
                    name
                }
            }
            newsfiles(where: { type: { _eq: 602 }, _and: { roleid: { _eq: 30 } } }) {
                file {
                    id
                    name
                }
            }
        }
        newsstatus: common_enumerations(
            where: { type: { _eq: 601 }, language: { _eq: $language } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;

export const DELETE_NEWS_BY_ID = gql`
    mutation DeleteNewsById($newsid: uuid!) {
        delete_news_news(where: { id: { _eq: $newsid } }) {
            affected_rows
        }
    }
`;

export const DELETE_NEWSFILE_BY_FILEID = gql`
    mutation DeleteNewsFileByFileId($fileid: uuid!) {
        delete_file_newsfiles(where: { fileid: { _eq: $fileid } }) {
            affected_rows
        }
    }
`;

export const GET_NEWS_NOTIFICATION_STATE_BY_ASSEMBLYID = gql`
    query NewsEventState($newsid: uuid!) {
        NewsNotificationState: noti_v_notification_news_state(where: { newsid: { _eq: $newsid } }) {
            name
            email
            datetime
            state
        }
    }
`;

export const GET_NEWS_SELECTION_FILES_MANAGER = gql`
    query GetNewsSelectionFilesManager($realestateid: uuid!) {
        files: file_files(
            where: {
                _or: [
                    { realestatefiles: { realestateid: { _eq: $realestateid } } }
                    { accountlistfiles: { accountlist: { realestateid: { _eq: $realestateid } } } }
                    { realestatefiles: { realestate: { consolidationid: { _eq: $realestateid } } } }
                    { accountlistfiles: { accountlist: { realestate: { consolidationid: { _eq: $realestateid } } } } }
                ]
            }
            order_by: { filedate: desc }
        ) {
            id
            accountlistfiles(limit: 1) {
                id
                type
                file {
                    id
                    name
                    mimetype
                    filedate
                }
            }
            realestatefiles(where: { type: { _neq: 202 } }, limit: 1) {
                id
                type
                file {
                    id
                    name
                    mimetype
                    filedate
                }
            }
        }
    }
`;

export const GET_NEWS_SELECTION_FILES_MANAGER_IT2 = gql`
    query GetNewsSelectionFilesManagerIT2($realestateid: uuid!, $consolidationid: uuid!) {
        files: file_files(
            where: {
                _or: [
                    { realestatefiles: { realestateid: { _eq: $realestateid } } }
                    { accountlistfiles: { accountlist: { realestateid: { _eq: $realestateid } } } }
                    { realestatefiles: { realestate: { consolidationid: { _eq: $realestateid } } } }
                    { accountlistfiles: { accountlist: { realestate: { consolidationid: { _eq: $realestateid } } } } }
                    { realestatefiles: { realestateid: { _eq: $consolidationid } } }
                    { accountlistfiles: { accountlist: { realestateid: { _eq: $consolidationid } } } }
                ]
            }
            order_by: { filedate: desc }
        ) {
            id
            accountlistfiles(limit: 1) {
                id
                type
                file {
                    id
                    name
                    mimetype
                    filedate
                }
            }
            realestatefiles(where: { type: { _neq: 202 } }, limit: 1) {
                id
                type
                file {
                    id
                    name
                    mimetype
                    filedate
                }
            }
        }
    }
`;

export const INSERT_NEWS_FILE_MANAGER = gql`
    mutation InsertNewsFileManager($objects: [file_newsfiles_insert_input!]!) {
        insertedfiles: insert_file_newsfiles(objects: $objects) {
            returning {
                id
                fileid
            }
        }
    }
`;

const REALESTATE_TENANT_NEWS_FRAGMENT = gql`
    fragment realestate_tenant_news on common_realestates {
        newsitems: newsrealestates(
            where: { news: { news_mls: { language: { _eq: $language } } } }
            order_by: { news: { timestamp: desc_nulls_last } }
        ) {
            newsitem: news {
                timestamp
                news: news_mls(limit: 1) {
                    id
                    lead
                    text
                    title
                }
                newsfiles(where: { type: { _eq: 601 } }, limit: 1) {
                    fileid
                }
                attachments: newsfiles(where: { type: { _eq: 602 }, roleid: { _eq: 33 } }) {
                    fileid
                    file {
                        name
                    }
                }
            }
        }
    }
`;

export const GET_TENANT_NEWS = gql`
    ${REALESTATE_TENANT_NEWS_FRAGMENT}
    query GetTenantNews($language: String = "de", $realestateid: uuid!) {
        realestates: common_realestates(where: { id: { _eq: $realestateid } }) {
            ...realestate_tenant_news
        }
    }
`;

export const GET_TENANT_NEWS_IT2 = gql`
    ${REALESTATE_TENANT_NEWS_FRAGMENT}
    query GetTenantNewsIT2($language: String = "de", $realestateid: uuid!) {
        realestates: common_realestates(
            where: { _or: [{ id: { _eq: $realestateid } }, { consolidationid: { _eq: $realestateid } }] }
        ) {
            ...realestate_tenant_news
        }
    }
`;
