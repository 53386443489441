import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { RouteComponentProps, useParams } from "react-router";
import styles from "./CoownerAssemblies.module.css";
import { ScreenWidth } from "src/stores/UIStore";
import { Tiles } from "src/components";
import * as Screens from "src/screens";
import { useMutation, useQuery } from "@apollo/client";
import { ASSEMBLY_AUDIT_EVENT, GET_ASSEMBLY_BY_REALESTATEID, GET_ASSEMBLY_BY_REALESTATEID_IT2 } from "src/api/assembly";
import {
    GetAssemblyByRealestateid,
    GetAssemblyByRealestateidVariables
} from "src/api/generated/GetAssemblyByRealestateid";
import { getTime, getDateWithLongMonth } from "src/utils/Date";
import AssemblyAgendaItems from "./agenda/AssembliesAgendaItems";
import AssemblyDocuments from "./document/AssemblyDocuments";
import AssemblyDeadlines from "./deadlines/AssembliesDeadlines";
import { UPSERT_ASSEMBLY_USER_AUDITS } from "src/api/assembly";
import {
    UpsertAssemblyUserAudits,
    UpsertAssemblyUserAuditsVariables
} from "src/api/generated/UpsertAssemblyUserAudits";
import { Route, selectRoute } from "src/config/routes";
import { cond_assemblyuseraudits_insert_input } from "src/api/generated/globalTypes";
import { ErpType, getRoleKey } from "src/network/User";
import { NetworkConfig } from "src/network/NetworkConfig";
import { MessageType } from "src/components/notifications/Notifier";
import DownloadCloud from "src/assets/images/common/DownloadCloud.png";
import { getDate } from "src/utils/Date";
import { v4 as uuidv4 } from "uuid";

interface Params {
    realestateid: string;
}

const CoownerAssembliesBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore, authStore } = useContext(RootStoreContext);
    const { realestateid } = useParams<Params>();
    const [hasCoownerViewedAssembly, setHasCoownerViewedAssembly] = useState<boolean>(false);
    const [isDownloadAgendaItemFiles, setIsDownloadAgendaItemFiles] = useState<boolean>(false);

    const printStatusMessage = useCallback(
        (message: string | React.ReactNode, messageType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: messageType,
                    autoClose: messageType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    useEffect(() => {
        navStore.setTitle(t("screens.realestate.assemblies.title"));
    }, [navStore, t]);

    const { data, loading, error } = useQuery<GetAssemblyByRealestateid, GetAssemblyByRealestateidVariables>(
        authStore.user?.hasCoownerRole && authStore.user.erpType === ErpType.IT2
            ? GET_ASSEMBLY_BY_REALESTATEID_IT2
            : GET_ASSEMBLY_BY_REALESTATEID,
        {
            variables: {
                realestateid: realestateid
            }
        }
    );

    const [upsertAssemblyUserAuditMutation] = useMutation<UpsertAssemblyUserAudits, UpsertAssemblyUserAuditsVariables>(
        UPSERT_ASSEMBLY_USER_AUDITS
    );

    const onDownloadAgendaItemDocuments = useCallback(async () => {
        setIsDownloadAgendaItemFiles(true);
        if (authStore.user && data) {
            const accessToken = authStore.token;
            const tokenType = "Bearer";
            const role = authStore.user?.role;
            try {
                const fetchResult = await fetch(`${NetworkConfig.downloadAgendaItemFiles}/${data.assemblys[0].id}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    try {
                        const contentDisposition = fetchResult.headers.get("Content-Disposition");
                        // prettier-ignore
                        let fileName = contentDisposition?.split("filename=")[1]?.replaceAll("\"", "");
                        let fileBlob = await fetchResult.blob();
                        const objUrl = window.URL.createObjectURL(fileBlob);
                        const a = document.createElement("a");
                        a.href = objUrl;
                        const date = getDate(data.assemblys[0].date);
                        fileName =
                            fileName ??
                            `${t("screens.realestate.assembly.default_agenda_file_name", {
                                date: date?.toString().replaceAll(".", "_")
                            })}`;
                        fileName = fileName.replace("assemblytitle", data.assemblys[0].title.replaceAll(" ", "_"));
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    } catch (error) {
                        printStatusMessage(
                            t("screens.realestate.assembly.error.assembly_file_download_error"),
                            MessageType.ERROR
                        );
                    }
                } else {
                    printStatusMessage(
                        t("screens.realestate.assembly.error.assembly_file_download_error"),
                        MessageType.ERROR
                    );
                }
            } catch (fetchError) {
                setIsDownloadAgendaItemFiles(false);
                printStatusMessage(
                    t("screens.realestate.assembly.error.assembly_file_download_error"),
                    MessageType.ERROR
                );
            }
        }
        setIsDownloadAgendaItemFiles(false);
    }, [authStore.token, authStore.user, t, printStatusMessage, data]);

    const renderTooltip = useMemo(
        () => (
            <UI.Tooltip id="downloadDocumentTooltip">
                {t("screens.realestate.assembly.download_file_tootlip")}
            </UI.Tooltip>
        ),
        [t]
    );

    const DownloadAgendItemDocument = useCallback(() => {
        return (
            <div
                className={`${styles.SidebarIconTitleContainer} ${styles.TooltipContainer} d-flex align-items-center ${
                    uiStore.isMobile ? styles.Mobile : ""
                }`}
            >
                {isDownloadAgendaItemFiles ? (
                    <UI.RotatingSpinner noLogo={true} size={25} />
                ) : (
                    <UI.OverlayTrigger key={"downloadDocumentTooltip2"} placement="right" overlay={renderTooltip}>
                        <UI.BootstrapButton variant="link" style={{ paddingRight: 0, borderRightWidth: 0 }}>
                            <img
                                src={DownloadCloud}
                                style={{ width: 30, alignSelf: "center" }}
                                onClick={onDownloadAgendaItemDocuments}
                                alt=""
                            />
                        </UI.BootstrapButton>
                    </UI.OverlayTrigger>
                )}
            </div>
        );
    }, [isDownloadAgendaItemFiles, onDownloadAgendaItemDocuments, renderTooltip, uiStore.isMobile]);

    useEffect(() => {
        if (navStore.currentOwnerId) {
            if (navStore.singleRealestate) {
                navStore.setBackbutton(selectRoute(Route.owners, authStore.user?.role), t("screens.owners.title"));
            } else {
                navStore.setBackbutton(
                    selectRoute(Route.realestatesOwner, authStore.user?.role, { ownerid: navStore.currentOwnerId }),
                    t("screens.realestates.title")
                );
            }
        } else {
            navStore.setBackbutton(
                selectRoute(Route.realestates, authStore.user?.role),
                t("screens.realestates.title")
            );
        }
    });

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    let hasAssemblies = false;

    if (data.assemblys.length > 0) {
        hasAssemblies = true;
        if (!hasCoownerViewedAssembly) {
            const upsertAssemblyUserAuditObject: cond_assemblyuseraudits_insert_input[] = [
                {
                    assemblyid: data.assemblys[0].id,
                    event: ASSEMBLY_AUDIT_EVENT.VIEWED,
                    timestamp: new Date(Date.now())
                }
            ];

            upsertAssemblyUserAuditMutation({
                variables: {
                    objects: upsertAssemblyUserAuditObject
                }
            });

            setHasCoownerViewedAssembly(true);
        }
    }

    const InfoCardAssemblyData = () => {
        if (data.assemblys.length > 0 && data.assemblys[0].date) {
            const dateFromDB = data.assemblys[0].date;
            const date = getDateWithLongMonth(dateFromDB);
            const time = getTime(dateFromDB);

            const dateWithTime = `${date} - ${time}`;

            return (
                <UI.Row className={styles.InfoCardValues}>
                    <div>{dateWithTime}</div>
                </UI.Row>
            );
        }

        return <UI.Row className={styles.InfoCardValues}></UI.Row>;
    };

    const InfoCardAssemblyPlace = () => {
        if (data.assemblys.length > 0 && data.assemblys[0].street && data.assemblys[0].housenumber) {
            const assembly = data.assemblys[0];
            const formatAddress = `${assembly.street} ${assembly.housenumber}, ${assembly.zip} ${assembly.city}`;

            return (
                <UI.Row className={styles.InfoCardValues}>
                    <div className={styles.CityLocationValue}>{assembly.locationdetail}</div>
                    <div className={styles.CityLocationValue}>{formatAddress}</div>
                </UI.Row>
            );
        }

        return <UI.Row className={styles.InfoCardValues}></UI.Row>;
    };

    const AssemblyInfoCard = () => {
        if (data.assemblys.length > 0) {
            return (
                <UI.Card className={styles.InfoCard} title={t("screens.realestate.assembly.info")}>
                    <div className={styles.InfoCardDetails}>{t("screens.realestate.assembly.date")}</div>
                    <InfoCardAssemblyData />
                    <div className={styles.InfoCardDetails}>{t("screens.realestate.assembly.city")}</div>
                    <InfoCardAssemblyPlace />
                </UI.Card>
            );
        } else {
            return (
                <UI.Card className={styles.InfoCard} title={t("screens.realestate.assembly.info")}>
                    <div>{t("screens.realestate.assembly.no_assambly")}</div>
                </UI.Card>
            );
        }
    };

    const AssamblyTitle = () => {
        if (data.assemblys.length > 0) {
            const title = data.assemblys[0].title;
            const date = data.assemblys[0].date;

            return <UI.Title>{`${title} am ${getDateWithLongMonth(date)}`}</UI.Title>;
        } else {
            return <UI.Title>{t("screens.realestate.assemblies.title")}</UI.Title>;
        }
    };

    const AssemblyDocumentsCard = () => {
        if (data.assemblys.length > 0) {
            const assemblyId = data.assemblys[0].id;

            return (
                <AssemblyDocuments
                    assemblyid={assemblyId ?? ""}
                    assemblyTitle={data.assemblys[0].title}
                    assemblyDate={data.assemblys[0].date}
                />
            );
        } else {
            return <></>;
        }
    };

    const AssemblyAgendaCard = () => {
        if (data.assemblys.length > 0) {
            const assemblyId = data.assemblys[0].id;

            return (
                <UI.Card
                    className={styles.AgendaCard}
                    title={t("screens.realestate.assembly.agenda")}
                    icon={<DownloadAgendItemDocument />}
                >
                    <AssemblyAgendaItems assemblyid={assemblyId ?? ""} key={uuidv4()} />
                </UI.Card>
            );
        } else {
            return <></>;
        }
    };

    const AssemblyDeadlinesCard = () => {
        if (data.assemblys.length > 0) {
            const assemblyId = data.assemblys[0].id;

            return <AssemblyDeadlines assemblyid={assemblyId ?? ""} />;
        } else {
            return <></>;
        }
    };

    return (
        <UI.Container className="pt-4">
            <AssamblyTitle />
            {hasAssemblies ? (
                <UI.Row>
                    {window.innerWidth >= ScreenWidth.XL ? (
                        <>
                            <UI.Col xl={3}>
                                <AssemblyInfoCard />
                                <AssemblyDocumentsCard />
                            </UI.Col>
                            <UI.Col xl={6}>
                                <AssemblyAgendaCard />
                            </UI.Col>
                        </>
                    ) : (
                        <>
                            <UI.Col lg={4}>
                                <AssemblyInfoCard />
                            </UI.Col>
                            <UI.Col lg={4}>
                                <AssemblyDocumentsCard />
                            </UI.Col>
                            <UI.Col lg={4}>
                                <AssemblyAgendaCard />
                            </UI.Col>
                        </>
                    )}

                    {window.innerWidth >= ScreenWidth.XL ? (
                        <UI.Col xl={3}>
                            <AssemblyDeadlinesCard />
                            <Tiles.ContactTile realestateid={realestateid} />
                        </UI.Col>
                    ) : (
                        <>
                            <UI.Col lg={4}>
                                <AssemblyDeadlinesCard />
                            </UI.Col>
                            <UI.Col lg={4}>
                                <Tiles.ContactTile realestateid={realestateid} />
                            </UI.Col>
                        </>
                    )}
                </UI.Row>
            ) : (
                <>
                    <UI.Row>
                        <UI.Col xl={9}>
                            <UI.Card className={styles.InfoCard} title={t("screens.realestate.assembly.info")}>
                                <div className={styles.NoAssemblies}>
                                    {t("screens.realestate.assembly.no_assambly")}
                                </div>
                            </UI.Card>
                        </UI.Col>
                        <UI.Col xl={3}>
                            <Tiles.ContactTile realestateid={realestateid} />
                        </UI.Col>
                    </UI.Row>
                </>
            )}
        </UI.Container>
    );
};

export const CoownerAssemblies = Sentry.withProfiler(observer(CoownerAssembliesBase));
