import { gql } from "@apollo/client";

export const GET_SERVICE_BY_SERVICETYPE = gql`
    query GetServiceDetailsByServiceType($servicetype: Int, $language: String! = "de") {
        uccm_v_customerservices_tiles(
            order_by: { name: asc }
            where: { servicetype: { _eq: $servicetype }, language: { _eq: $language }, servicestate: { _eq: 30 } }
        ) {
            serviceid
            chargeable
            name
            price
            pricelink
            remarks
            title
            teaser
            content
            activate
            deactivate
            afteractivate
            activated
            enableable
            activationdate
            deactivationdate
            trialenddate
            servicesfiles(where: { islogo: { _eq: true } }) {
                id
                name
                extension
                filedate
                mimetype
                type
            }
        }
    }
`;
