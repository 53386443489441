import { UI } from "@wwimmo/ui";
import { ChangeEvent, useCallback, useState, useContext, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { Ticket } from "src/stores/TicketStore";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react";
import * as Sentry from "@sentry/react";
import { IInvoiceFile } from "src/stores/krediflow/InvoiceTypes";
import styles from "./InvoiceFilePreview.module.css";
import { INVOICE_FILE_STATE, INVOICE_FILE_TYPE, INVOICE_PROPERTY } from "src/stores/krediflow/InvoiceEnums";
import { Document, Page, pdfjs } from "react-pdf";
import "./AnnotationLayer.css";
import "./TextLayer.css";
import { MessageType } from "src/components/notifications/Notifier";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface InvoiceFilePreviewProps {
    label?: string;
    file?: IInvoiceFile;
    reviewWindowWidth?: number;
}

interface FileHTMLInputEvent extends ChangeEvent {
    target: HTMLInputElement & EventTarget;
}

const dataTypes = ["application/pdf"];

const extensions = [".pdf"];

const InvoiceFilePreviewBase = (props: InvoiceFilePreviewProps) => {
    const { t } = useTranslation();

    const { invoiceStore, uiStore } = useContext(RootStoreContext);

    const { file, reviewWindowWidth } = props;

    const invoiceFilePreviewRef = useRef<HTMLDivElement>(null);

    const [isFileDeleting, setIsFileDeleting] = useState<boolean>(false);
    const [isLoadingPDFFile, setIsLoadingPDFFile] = useState<boolean>(false);
    const [numPages, setNumPages] = useState<number>(0);
    const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);

    useEffect(() => {
        const invoiceFormPreviewElement = invoiceFilePreviewRef.current;

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === invoiceFormPreviewElement) {
                    uiStore.setInvoiceFilePreviewWidth(entry.contentRect.width - 20);
                }
            }
        });

        if (invoiceFormPreviewElement) {
            resizeObserver.observe(invoiceFormPreviewElement);
        }

        return () => {
            if (invoiceFormPreviewElement) {
                resizeObserver.unobserve(invoiceFormPreviewElement);
            }
        };
    }, [uiStore, invoiceStore.isEditing]);

    const onHandleChangeInput = useCallback(
        async (event: FileHTMLInputEvent) => {
            setIsLoadingPDFFile(true);

            if (event?.target?.files) {
                const files = Array.from(event.target.files);

                if (files.length > 0) {
                    const file: File = files[0];
                    const fileUrl = URL.createObjectURL(file);

                    const invoiceFile: IInvoiceFile = {
                        fileId: uuidv4(),
                        invoiceFileId: uuidv4(),
                        name: file.name,
                        url: fileUrl,
                        type: INVOICE_FILE_TYPE.INVOICE,
                        mimetype: file.type,
                        extension: file.name.split(".").pop(),
                        fileDate: Ticket.formatDateForImage(new Date(file.lastModified)),
                        state: INVOICE_FILE_STATE.HAS_CHANGED
                    };

                    const isPDFFile = dataTypes.includes(file.type);

                    if (isPDFFile) {
                        const reader = new FileReader();

                        const readFileAsBase64 = (file: File) => {
                            return new Promise<string>((resolve, reject) => {
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                    const fileBase64String = reader.result as string;
                                    const base64String = fileBase64String.split(",")[1];
                                    resolve(base64String);
                                };
                                reader.onerror = (error) => {
                                    reject(error);
                                };
                            });
                        };

                        try {
                            const base64String = await readFileAsBase64(file);
                            invoiceFile.base64String = base64String;

                            await invoiceStore.extractQrCodeDataFromPDF(base64String);

                            invoiceStore.currentInvoice?.updateProperty(INVOICE_PROPERTY.INVOICE_FILE, invoiceFile);
                        } catch (error) {
                            console.error("Error reading file:", error);
                        }
                    } else {
                        setIsDraggingOver(false);

                        uiStore.printStatusMessage(
                            t("screens.kredi_flow.form.invoice.preview.file_selection_error"),
                            MessageType.ERROR
                        );
                    }
                }

                setIsLoadingPDFFile(false);
            }
        },
        [invoiceStore, t, uiStore]
    );

    const onDeleteFile = useCallback(async () => {
        setIsFileDeleting(true);
        setIsDraggingOver(false);

        const invoiceFile: IInvoiceFile = {
            state: INVOICE_FILE_STATE.NEW
        };

        invoiceStore.currentInvoice?.updateProperty(INVOICE_PROPERTY.INVOICE_FILE, invoiceFile);

        setIsFileDeleting(false);
    }, [invoiceStore.currentInvoice]);

    const onLoadError = useCallback((error: any) => {
        console.error("Fehler beim Laden der Datei: ", error.message);
    }, []);

    const onDocumentLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    }, []);

    const onDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setIsDraggingOver(true);
    }, []);

    const onDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setIsDraggingOver(false);
    }, []);

    return (
        <div ref={invoiceFilePreviewRef}>
            <div>
                {isFileDeleting || isLoadingPDFFile ? (
                    <div className={`${styles.InvoicePreviewDropzoneContainer}`}>
                        <div>{t("screens.kredi_flow.document_is_being_analysed")}</div>
                        <UI.RotatingSpinner noLogo={true} size={25} />
                    </div>
                ) : undefined}
                {!file?.url && !isLoadingPDFFile ? (
                    <>
                        <div
                            className={`${styles.InvoicePreviewDropzoneContainer} ${
                                isDraggingOver ? styles.DraggingOver : ""
                            }`}
                            onDragEnter={onDragEnter}
                            onDragLeave={onDragLeave}
                        >
                            <div>{t("screens.kredi_flow.add_document")}</div>
                            <input
                                id={styles.InvoicePreviewInput}
                                type="file"
                                onChange={onHandleChangeInput}
                                accept={dataTypes.concat(extensions).join(",")}
                            />
                            <UI.Icon style={{ marginTop: 20 }} color="var(--grey)" icon={UI.SVGIcon.Documents} />
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
            {file?.url && !isLoadingPDFFile && (
                <>
                    <div
                        id="invoice-preview-pdf-container"
                        style={{ overflowY: "scroll", height: uiStore.invoiceFormHeight }}
                    >
                        <Document
                            file={file.url}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={onLoadError}
                            loading={""}
                            error={""}
                        >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page width={reviewWindowWidth} key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                        </Document>
                    </div>
                    <div className={styles.FileDeleteIcon}>
                        <UI.Icon onClick={onDeleteFile} color="var(--grey)" icon={UI.SVGIcon.Trash} />
                    </div>
                </>
            )}
        </div>
    );
};

export const InvoiceFilePreview = Sentry.withProfiler(observer(InvoiceFilePreviewBase));
