import { UI } from "@wwimmo/ui";
import React, { useCallback, useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./CopyAssemblyAgendaModal.module.css";
import { cloneDeep, startCase } from "lodash";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { AgendaItem } from "src/types";
import { toJS } from "mobx";
import { NetworkConfig } from "src/network/NetworkConfig";
import { MessageType } from "src/components/notifications/Notifier";
import {
    GetAgendaitemInformationToCopy_agendaitemtemplatetexts,
    GetAgendaitemInformationToCopy_agendaitemtexts_by_assembly_agendaitemtexts
} from "src/api/generated/GetAgendaitemInformationToCopy";
import { Role, getRoleKey } from "src/network/User";

interface CopyAssemblyAgendaModalProps {
    onClickClose: (toogle: boolean) => void;
    assemblyid: string;
    realestateid: string;
    refetchAgendaItems: any;
    agendaitemTemplatesCounter: number;
    agendaitemsCounter: number;
    agendaItemTemplates: GetAgendaitemInformationToCopy_agendaitemtemplatetexts[] | undefined;
    agendaItems: GetAgendaitemInformationToCopy_agendaitemtexts_by_assembly_agendaitemtexts[] | undefined;
}

export const CopyAssemblyAgendaModalBase = (props: CopyAssemblyAgendaModalProps) => {
    const { t } = useTranslation();
    const { assemblyStore, uiStore, authStore } = useContext(RootStoreContext);
    const {
        onClickClose,
        assemblyid,
        realestateid,
        refetchAgendaItems,
        agendaitemTemplatesCounter,
        agendaitemsCounter,
        agendaItems,
        agendaItemTemplates
    } = props;

    useEffect(() => {
        const preSelectedKey = agendaitemsCounter > 0 ? 1 : agendaitemTemplatesCounter ? 2 : 0;
        assemblyStore.setSelectedCopyAssemblyAgendaOption(preSelectedKey);
    }, [assemblyStore, agendaitemTemplatesCounter, agendaitemsCounter]);

    const printStatusMessage = useCallback(
        (message: string, msgType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: msgType,
                    autoClose: msgType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    const onClickBack = useCallback(async () => {
        if (assemblyStore.selectedCopyAssemblyAgendaOption > 0) {
            const unsavedAgendaItems: AgendaItem[] = [];
            if (assemblyStore.agendaItemsHaveChanged) {
                const tempNotSaveAgendaItems = cloneDeep(toJS(assemblyStore.agendaItems));
                tempNotSaveAgendaItems.forEach((agi) => {
                    if (agi.isNew) {
                        unsavedAgendaItems.push(agi);
                    }
                });
                if (unsavedAgendaItems) {
                    assemblyStore.setUnsavedAgendaItems(unsavedAgendaItems);
                }
            }

            if (authStore.user && authStore.token) {
                const accessToken = authStore.token;
                const tokenType = authStore.tokenType;

                const copyUrl =
                    assemblyStore.selectedCopyAssemblyAgendaOption === 1
                        ? `${NetworkConfig.copyagendaitemsLastAssembly}?assemblyid=${assemblyid}&realestateid=${realestateid}`
                        : `${NetworkConfig.copyagendaitemTemplates}?assemblyid=${assemblyid}&realestateid=${realestateid}`;

                const fetchResult = await fetch(copyUrl, {
                    method: "GET",
                    headers: {
                        "x-hasura-role": getRoleKey(Role.MANAGER),
                        Authorization: `${tokenType} ${accessToken}`
                    }
                });

                if (fetchResult.status === 200) {
                    await refetchAgendaItems();
                    if (assemblyStore.selectedCopyAssemblyAgendaOption === 1) {
                        printStatusMessage(
                            t("screens.realestate.assembly.copy_agendaitem_modal.agendacopymessage"),
                            MessageType.INFO
                        );
                    } else {
                        printStatusMessage(
                            t("screens.realestate.assembly.copy_agendaitem_modal.agendatemplatecopymessage"),
                            MessageType.INFO
                        );
                    }
                    assemblyStore.setIsCopyAgendaItemsActive(true);
                    assemblyStore.generateAssemblyPDF(assemblyid);
                }
            }
        }
        onClickClose(true);
    }, [
        onClickClose,
        authStore.token,
        authStore.tokenType,
        authStore.user,
        assemblyid,
        realestateid,
        refetchAgendaItems,
        printStatusMessage,
        t,
        assemblyStore
    ]);

    const onClickDismiss = useCallback(() => {
        assemblyStore.setSelectedCopyAssemblyAgendaOption(0);
        onClickClose(false);
    }, [onClickClose, assemblyStore]);

    const onRadioOptionChange = useCallback(
        (event: any) => {
            if (event.currentTarget.value === "AGENDAITEMS_LAST_ASSEMBLY") {
                assemblyStore.setSelectedCopyAssemblyAgendaOption(1);
            } else if (event.currentTarget.value === "AGENDAITEMS_TEMPLATE_ASSEMBLY") {
                assemblyStore.setSelectedCopyAssemblyAgendaOption(2);
            } else {
                assemblyStore.setSelectedCopyAssemblyAgendaOption(0);
            }
        },
        //
        // eslint-disable-next-line
        [assemblyStore, assemblyStore.selectedCopyAssemblyAgendaOption]
    );

    const Content = (props: { children: React.ReactNode }) => {
        return (
            <>
                {agendaitemsCounter > 0 ? (
                    <UI.Row className={"mt-1"}>
                        <UI.Form.Check
                            key={"AGENDAITEMS_LAST_ASSEMBLY"}
                            id={t(
                                "screens.realestate.assembly.copy_agendaitem_modal.agendaitems_from_last_assembly"
                            ).toString()}
                            className={`${styles.RadioButton} d-flex align-items-center`}
                            label={t(
                                "screens.realestate.assembly.copy_agendaitem_modal.agendaitems_from_last_assembly"
                            )}
                            type={"radio"}
                            checked={assemblyStore.selectedCopyAssemblyAgendaOption === 1}
                            value={"AGENDAITEMS_LAST_ASSEMBLY"}
                            onChange={onRadioOptionChange}
                        />
                    </UI.Row>
                ) : undefined}

                {agendaitemTemplatesCounter > 0 ? (
                    <UI.Row className={"mt-1"}>
                        <UI.Form.Check
                            key={"AGENDAITEMS_TEMPLATE_ASSEMBLY"}
                            id={t(
                                "screens.realestate.assembly.copy_agendaitem_modal.agendaitems_from_template"
                            ).toString()}
                            className={`${styles.RadioButton} d-flex align-items-center`}
                            label={t("screens.realestate.assembly.copy_agendaitem_modal.agendaitems_from_template")}
                            type={"radio"}
                            checked={assemblyStore.selectedCopyAssemblyAgendaOption === 2}
                            value={"AGENDAITEMS_TEMPLATE_ASSEMBLY"}
                            onChange={onRadioOptionChange}
                        />
                    </UI.Row>
                ) : undefined}

                <UI.Form.Label className={`${styles.Subtitle} mt-2`}>
                    {t("screens.realestate.assembly.copy_agendaitem_modal.subtitle")}
                </UI.Form.Label>

                {agendaitemsCounter > 0 && assemblyStore.selectedCopyAssemblyAgendaOption === 1 ? (
                    <UI.Row className={"mt-1"}>
                        <ul className={styles.AgendaList}>
                            {agendaItems?.map((parentitem) => (
                                <li key={parentitem.sort + parentitem.id}>
                                    <div
                                        className={styles.ParentAgendaItems}
                                    >{`${parentitem.sort}. ${parentitem.text}`}</div>
                                    {parentitem.subagendaitemtext.map((childItem) => (
                                        <div className={styles.ChildAgendaItems} key={childItem.sort + childItem.id}>
                                            {`${parentitem.sort}.${childItem.sort} ${childItem.text}`}
                                        </div>
                                    ))}
                                </li>
                            ))}
                        </ul>
                    </UI.Row>
                ) : undefined}

                {agendaitemTemplatesCounter > 0 && assemblyStore.selectedCopyAssemblyAgendaOption === 2 ? (
                    <UI.Row className={"mt-1"}>
                        <ul className={styles.AgendaList}>
                            {agendaItemTemplates?.map((parentitem) => (
                                <li key={parentitem.sort + parentitem.id}>
                                    <div
                                        className={styles.ParentAgendaItems}
                                    >{`${parentitem.sort}. ${parentitem.text}`}</div>
                                    {parentitem.subagendaitemtemplatetext.map((childItem) => (
                                        <div className={styles.ChildAgendaItems} key={childItem.sort + childItem.id}>
                                            {`${parentitem.sort}.${childItem.sort} ${childItem.text}`}
                                        </div>
                                    ))}
                                </li>
                            ))}
                        </ul>
                    </UI.Row>
                ) : undefined}

                <UI.Row className={"mt-2 d-flex justify-content-center"}>
                    <UI.Col xs={12} md={6}>
                        <UI.Button
                            className={`mt-2  ${styles.DiscardButton}`}
                            label={startCase(t("labels.cancel").toString())}
                            variant={"outline-primary"}
                            onClick={onClickDismiss}
                            disabled={agendaitemTemplatesCounter === 0 && agendaitemsCounter === 0}
                        ></UI.Button>
                    </UI.Col>
                    <UI.Col xs={12} md={6}>
                        <UI.Button
                            className={`mt-2  ${styles.DiscardButton}`}
                            label="Übernehmen"
                            variant={"outline-primary"}
                            onClick={onClickBack}
                        ></UI.Button>
                    </UI.Col>
                </UI.Row>
            </>
        );
    };

    return (
        <UI.Modal
            show={true}
            title={t("screens.realestate.assembly.copy_agendaitem_modal.title").toString()}
            onClose={onClickClose}
            centered={uiStore.isMobile}
            size={uiStore.isMobile ? undefined : "lg"}
        >
            <Content children={undefined}></Content>
        </UI.Modal>
    );
};

export const CopyAssemblyAgendaModal = Sentry.withProfiler(observer(CopyAssemblyAgendaModalBase));
