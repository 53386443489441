import React from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

const UserInfoPageBase = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const maintenanceEndTimeQueryParameter = queryParams.get("end");

    return (
        <UI.Container>
            <UI.Row>
                <UI.Col className="mt-sm-2 mt-1 mx-auto pt-sm-4 pt-1 col-sm-8">
                    <UI.Card>
                        <p>{t("screens.user_info_page.paragraph_1")}</p>
                        <p>{t("screens.user_info_page.paragraph_2")}</p>

                        {maintenanceEndTimeQueryParameter ? (
                            <p>
                                {t("screens.user_info_page.paragraph_maintenance_end_time", {
                                    maintenanceEndTime: maintenanceEndTimeQueryParameter
                                })}
                            </p>
                        ) : (
                            <></>
                        )}

                        <p>{t("screens.user_info_page.paragraph_3")}</p>
                    </UI.Card>
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};

export const UserInfoPage = Sentry.withProfiler(UserInfoPageBase);
