import { runInAction } from "mobx";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { Route } from "src/config/routes";
import { RootStoreContext } from "src/stores/RootStore";
import { AuthWrapper } from "src/screens/auth/AuthWrapper";
import { UI } from "@wwimmo/ui";
import i18next from "i18next";
import { useUrlQuery } from "src/utils/Route";
import { MessageType } from "src/components/notifications/Notifier";

interface ActivationEmailForm {
    email: string;
}

export const ResendActivationEmail = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const history = useHistory();

    let urlQuery = useUrlQuery();
    const email = urlQuery.get("email");

    const { navStore, authStore, uiStore } = useContext(RootStoreContext);
    const [error, setError] = useState<Error | undefined>(undefined);
    const {
        register,
        handleSubmit,

        formState: { errors }
    } = useForm<ActivationEmailForm>({
        defaultValues: { email: email ?? "" }
    });

    useEffect(() => {
        runInAction(() => {
            navStore.setTitle(t("screens.auth.activate.resendactivationemail"));
            navStore.setBackbutton(Route.login, t("screens.auth.login"));
        });
    }, [navStore, t, props.location.search]);

    const printStatusMessage = useCallback(
        (message: string, msgType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: msgType,
                    autoClose: msgType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    const onSubmit = async (formData: ActivationEmailForm) => {
        urlQuery.set("email", formData.email);
        history.replace({ search: urlQuery.toString() });
        let hasResendActivationEMailError = false;

        try {
            await authStore.authClient.resendActivationEmail(formData.email);
        } catch (error: any) {
            hasResendActivationEMailError = true;
            error.message = i18next.exists(`error.${error.message}`)
                ? t(`error.${error.message}`)
                : t("screens.auth.password.recovery.default_error");

            setError(error);
        }

        if (hasResendActivationEMailError) {
            printStatusMessage(t("screens.auth.activate.resendactivationemail_error"), MessageType.INFO);
        } else {
            printStatusMessage(t("screens.auth.activate.resendactivationemail_sucess"), MessageType.INFO);
        }
    };

    const content = () => {
        return (
            <div>
                <div>
                    <UI.Alert variant="danger" className="mt-2" style={{ marginTop: 3, paddingLeft: 0 }}>
                        {t("screens.auth.activate.resendactivationemail_error_site")}
                    </UI.Alert>
                </div>
                <div style={{ marginBottom: 15 }} />
                <div>
                    <UI.Form onSubmit={handleSubmit(onSubmit)}>
                        <UI.FormGroup controlId="formEmail" className="mb-3">
                            <UI.Form.Label>
                                <Trans>screens.auth.emailaddress</Trans>
                            </UI.Form.Label>
                            <UI.Form.Control
                                {...register("email", { required: true })}
                                type="email"
                                placeholder={t("screens.auth.emailaddress_placeholder")}
                                isInvalid={errors.email !== undefined}
                            />
                        </UI.FormGroup>
                        <UI.Button type="submit" label={t("screens.auth.activate.resendactivationemail")} />
                        {error && (
                            <UI.Alert variant="danger" className="mt-2">
                                {error.message}
                            </UI.Alert>
                        )}
                    </UI.Form>
                </div>
            </div>
        );
    };

    return (
        <AuthWrapper>
            {content()}
            <hr />
            <p>
                <Link to={Route.login}>
                    <Trans>screens.auth.login</Trans>
                </Link>
                <br />
                <Link to={Route.register}>
                    <Trans>screens.auth.register.title</Trans>
                </Link>
            </p>
        </AuthWrapper>
    );
};
