import React from "react";
import { Switch, useLocation } from "react-router";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { Customer } from "src/components/customer/Customer";
import { PrivateRoute, PublicRoute } from "src/components";
import { Route } from "src/config/routes";
import { Auth } from "src/screens/auth";
import * as Screens from "src/screens";
import { NetworkConfig } from "src/network/NetworkConfig";

export const MainRoutesBase = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    if (location.pathname === Route.register) {
        const authRegisterRedirectUrl = new URL(`${NetworkConfig.authWebsiteUrl}/register`);
        const subdomain = NetworkConfig.customerAuthSubdomain;
        const hasCustomerSpecificSubdomain = subdomain !== "prod" && subdomain !== "dev" && subdomain !== "test";

        if (hasCustomerSpecificSubdomain) {
            queryParams.set("subdomain", subdomain);
        }
        if (queryParams.size > 0) {
            for (const param of queryParams) {
                authRegisterRedirectUrl.searchParams.set(param[0], param[1]);
            }
        }
        window.location.replace(authRegisterRedirectUrl);
    }

    return (
        <Switch>
            <PublicRoute path={Route.logout} component={Auth.Logout} />
            <PublicRoute path={Route.passwordrecovery} component={Auth.PasswordRecovery} />
            <PublicRoute path={Route.passwordreset} component={Auth.PasswordReset} />
            <PublicRoute path={Route.activate} component={Auth.Activate} />
            <PublicRoute path={Route.resendActivationEMail} component={Auth.ResendActivationEmail} />
            <PublicRoute path={Route.fileerror} component={Screens.FileError} />
            <PublicRoute path={Route.systemHealthError} component={Screens.SystemHealthError} />
            <PublicRoute path={Route.stwegDemoPage} component={Screens.StwegDemoPage} />
            <PublicRoute path={Route.userInfoPage} component={Screens.UserInfoPage} />

            <PrivateRoute path="/" component={Customer} />
        </Switch>
    );
};

export const MainRoutes = Sentry.withProfiler(observer(MainRoutesBase));
