import React, { useContext, useCallback, useState } from "react";
import { GetUnitAppliances_appliances as appliance } from "src/api/generated/GetUnitAppliances";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { Route, selectRoute } from "src/config/routes";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import { useParams } from "react-router";
import * as Screens from "src/screens";
import styles from "./AppliancesList.module.css";

interface ApplicancesListProps {
    appliances: appliance[];
}

interface Params {
    realestateid: string;
    unitid?: string;
}

const STICKY_HEADER_OFFSET = 130;

const AppliancesList = (props: ApplicancesListProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore, authStore } = useContext(RootStoreContext);
    const { realestateid, unitid } = useParams<Params>();

    const listIdentifier = unitid ? `appliancelist-${unitid}` : `appliancelist-${realestateid}`;

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    let currentSearchQuery: string | undefined = undefined;

    if (listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    }

    const [displayedAppliances, setDisplayedAppliances] = useState<appliance[]>([]);

    const filterAppliancesAndSetSearchQuery = useCallback(
        (searchQuery: string = "") => {
            if (props.appliances) {
                let foundPersons = props.appliances.filter((appliance) => {
                    const name = appliance.name;
                    const model = appliance.model;
                    const manufacturer = appliance.manufacturer;
                    const servicecompany = appliance.servicecompany;

                    return (
                        name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        model?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        manufacturer?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        servicecompany?.name1?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        servicecompany?.name2?.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                });

                setDisplayedAppliances([...foundPersons]);
            }
        },
        [props.appliances]
    );

    const onRowClick = useCallback(() => {
        navStore.setListMapValue(
            listIdentifier,
            listParameters
                ? { ...listParameters, scrollPosition: window.pageYOffset }
                : { scrollPosition: window.pageYOffset }
        );

        navStore.setBackbutton(
            selectRoute(unitid ? Route.unitAppliances : Route.realestateAppliances, authStore.user?.role, {
                realestateid: realestateid,
                unitid: unitid ?? ""
            }),
            unitid ? t("screens.unit.appliances") : t("screens.realestate.dashboard.appliances")
        );
    }, [navStore, t, authStore.user?.role, realestateid, unitid, listIdentifier, listParameters]);

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listIdentifier, listParameters]
    );

    const handleSearchInput = useCallback(
        ({ searchQuery }) => {
            filterAppliancesAndSetSearchQuery(searchQuery);

            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: searchQuery }
                    : { scrollPosition: 0, searchQuery: searchQuery }
            );
        },
        [filterAppliancesAndSetSearchQuery, navStore, listIdentifier, listParameters]
    );

    const renderItem = useCallback(
        (appliance: appliance, screenSize: ScreenSize) => {
            const servicecompany = appliance.servicecompany;

            let listRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={appliance.name ?? ""} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={appliance.model ?? ""} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={appliance.manufacturer ?? ""} />
                            <UI.List.Cell
                                key={"c-4"}
                                colspan={1}
                                value={`${servicecompany?.name1 ?? ""} ${servicecompany?.name2 ?? ""}`}
                            />
                        </UI.List.Row>
                    ];
                    break;

                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={appliance.name ?? ""} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={appliance.manufacturer ?? ""} />
                        </UI.List.Row>
                    ];
                    break;
            }

            const link = selectRoute(unitid ? Route.unitAppliance : Route.realestateAppliance, authStore.user?.role, {
                realestateid: realestateid,
                applianceid: appliance.id,
                unitid: unitid ?? ""
            });

            return (
                <UI.List.Item
                    key={appliance.id}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                    onClick={onRowClick}
                />
            );
        },
        [onRowClick, authStore.user?.role, realestateid, unitid]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"} className={styles.appliancesHeader}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={t("screens.realestate.appliances.name")} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={t("screens.realestate.appliances.model")} />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={1}
                                value={t("screens.realestate.appliances.manufacturer")}
                            />
                            <UI.List.Cell
                                key={"c-4"}
                                colspan={1}
                                value={t("screens.realestate.appliances.servicecompany")}
                            />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"} className={styles.appliancesHeader}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={t("screens.realestate.appliances.name")} />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1}
                                value={t("screens.realestate.appliances.manufacturer")}
                            />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} sticky={true} top={STICKY_HEADER_OFFSET} />;
        },
        [t]
    );

    if (displayedAppliances === []) {
        return <Screens.Loading />;
    }

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={displayedAppliances}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={25}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
            onSearch={handleSearchInput}
            initialSearchQuery={currentSearchQuery}
        />
    );
};

export default observer(AppliancesList);
