import { gql } from "@apollo/client";

export const GET_TENANCYS_BY_PERSON_ID = gql`
    query CommonPersonTenancys($personid: uuid!) {
        persons: common_persons(where: { id: { _eq: $personid } }) {
            name1
            name2
            tenants {
                tenancys {
                    id
                    unit {
                        id
                        name
                        realestate {
                            id
                            name
                        }
                    }
                }
            }
            tenancypersons {
                tenancy {
                    id
                    unit {
                        id
                        name
                        realestate {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

const PERSON_FRAGMENTS = gql`
    fragment person_details on common_persons {
        email
        id
        legal
        mobile
        name1
        name2
        phonebusiness
        phoneprivate
        salutation
        current_address: addresses(order_by: { validfrom: desc }, limit: 1, where: { validfrom: { _lte: $today } }) {
            city
            country
            street
            validfrom
            zip
        }
    }
`;

export const GET_PERSON_BY_ID_MANAGER = gql`
    ${PERSON_FRAGMENTS}
    query CommonPersonManager($personid: uuid!, $today: timestamp!) {
        persons: common_persons(where: { id: { _eq: $personid } }) {
            ...person_details
            remarks
        }
    }
`;

export const GET_PERSON_BY_ID_OWNER = gql`
    ${PERSON_FRAGMENTS}
    query CommonPersonOwner($personid: uuid!, $today: timestamp!) {
        persons: common_persons(where: { id: { _eq: $personid } }) {
            ...person_details
        }
    }
`;
