import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { ScreenSize, UI } from "@wwimmo/ui";
import { Route, selectRoute } from "src/config/routes";
import styles from "./OrderLinkListCard.module.css";
import { CONTRACTOR_TYPE, ORDER_LOADING_TYPE, ORDER_STATE } from "src/stores/OrderStore";
import { IOrder, LOADING_TYPE } from "src/stores/TicketStore";

interface OrderLinkListCardProps {
    ticketNumber: string;
    orders: IOrder[];
}

const OrderLinkListCardBase = (props: OrderLinkListCardProps) => {
    const { t } = useTranslation();
    const { uiStore, authStore, orderStore, ticketStore } = useContext(RootStoreContext);
    const { orders, ticketNumber } = props;

    const renderItem = useCallback(
        (item: IOrder, screenSize: ScreenSize) => {
            const listRows: any[] = [];

            let serviceProviderName = "";
            switch (item.contractorType) {
                case CONTRACTOR_TYPE.SERVICE:
                    serviceProviderName = item.serviceProviderName ?? "";
                    break;
                case CONTRACTOR_TYPE.SERVICE_7000:
                    serviceProviderName = t(
                        `screens.orders.contractor.type.${CONTRACTOR_TYPE.SERVICE_7000}`
                    ).toString();
                    break;
                case CONTRACTOR_TYPE.YAROWA:
                    serviceProviderName = t(`screens.orders.contractor.type.${CONTRACTOR_TYPE.YAROWA}`).toString();
                    break;
            }
            let rows: React.ReactElement[] = [];

            rows = [
                <UI.List.Row
                    key={"row-1"}
                    className={
                        item.state === ORDER_STATE.SUBMISSION_FAILED ? `${styles["list-item-submission-failed"]}` : ""
                    }
                >
                    <UI.List.Cell
                        key={"c-1"}
                        colspan={1}
                        value={item.state === ORDER_STATE.SUBMISSION_FAILED ? "(!) " + item.number : item.number}
                    />
                    <UI.List.Cell key={"c-2"} colspan={3} value={serviceProviderName} />
                </UI.List.Row>
            ];

            listRows.push(rows);

            return (
                <UI.List.Item
                    key={item.number}
                    screenSize={screenSize}
                    rows={listRows}
                    to={selectRoute(Route.ticketOrderDetails, authStore.user?.role, {
                        ticketnumber: ticketNumber,
                        ordernumber: String(item.number)
                    })}
                />
            );
        },
        [authStore.user?.role, t, ticketNumber]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            const headerRows: React.ReactElement[] = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={1} value={`${t("labels.number_abr")}.`} />
                    <UI.List.Cell key={"c-2"} colspan={3} value={t("screens.tickets.modals.service_provider")} />
                </UI.List.Row>
            ];

            return <UI.List.Header rows={headerRows} />;
        },
        [t]
    );

    return (
        <UI.Card title={t("screens.orders.order").toString()} flex>
            {orderStore.loadingType === ORDER_LOADING_TYPE.INITIALIZING ||
            ticketStore.loadingType === LOADING_TYPE.RELOADING_ORDERS ? (
                <UI.RotatingSpinner />
            ) : (
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={orders}
                    renderItem={renderItem}
                    renderHeader={renderHeader}
                />
            )}
        </UI.Card>
    );
};

export const OrderLinkListCard = Sentry.withProfiler(observer(OrderLinkListCardBase));
