import React, { useState, useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { UI } from "@wwimmo/ui";
import { AssemblyAddDocumentsListFile } from "src/components/assemblies/documents/add-documents-modal/AddDocumentsModal";
import { useMutation } from "@apollo/client";
import { INSERT_AGENDAITEMS_FILE_MANAGER } from "src/api/assembly";
import {
    InsertAgendaItemsFileManager,
    InsertAgendaItemsFileManagerVariables
} from "src/api/generated/InsertAgendaItemsFileManager";
import { MessageType } from "src/components/notifications/Notifier";
import { RootStoreContext } from "src/stores/RootStore";
import { file_agendaitemfiles_insert_input } from "src/api/generated/globalTypes";
import { NetworkConfig } from "src/network/NetworkConfig";
import { AssemblyDocumentsListItem } from "src/components/assemblies/documents/list-item/AssemblyDocumentsListItem";
import styles from "./AddAgendaDocumentsModalList.module.css";

interface AddAgendaDocumentsModalListProps {
    files: AssemblyAddDocumentsListFile[];
}

const MINIMUM_CHARACTERS_FOR_SEARCH = 3;

const AddAgendaDocumentsModalListBase = (props: AddAgendaDocumentsModalListProps) => {
    const { t } = useTranslation();
    const { uiStore, assemblyStore } = useContext(RootStoreContext);
    const { files } = props;

    const [displayedFileItems, setDisplayedFileItems] = useState<Array<AssemblyAddDocumentsListFile>>(files);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const [insertAgendaItemFileMutation] = useMutation<
        InsertAgendaItemsFileManager,
        InsertAgendaItemsFileManagerVariables
    >(INSERT_AGENDAITEMS_FILE_MANAGER, {
        onCompleted: () => {
            printStatusMessage(t("screens.realestate.assembly.agendaitem_edit.add_file_success"), MessageType.INFO);
            assemblyStore.setResetAgendaItems(true);
        }
    });

    const printStatusMessage = useCallback(
        (message: string, msgType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: msgType,
                    autoClose: msgType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    const removeFileFromDisplayedList = useCallback(
        (fileid: string) => {
            const newlyDisplayedFileItems = displayedFileItems.filter((fileitem) => fileitem.fileId !== fileid);
            setDisplayedFileItems([...newlyDisplayedFileItems]);
        },
        [displayedFileItems]
    );

    const onSearch = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const currentSearchQuery = e.target.value;
            setSearchQuery(currentSearchQuery);

            if (currentSearchQuery.length >= MINIMUM_CHARACTERS_FOR_SEARCH && files.length > 0) {
                const foundItems = files.filter((file) => {
                    return (
                        file.fileName?.toLowerCase().includes(currentSearchQuery.toLowerCase()) ||
                        file.fileDate?.toLowerCase().includes(currentSearchQuery.toLowerCase())
                    );
                });

                setDisplayedFileItems([...foundItems]);
            } else {
                setDisplayedFileItems([...files]);
            }
        },
        [files]
    );

    const onAddDocumentToAgendaItem = useCallback(
        (fileItem: AssemblyAddDocumentsListFile) => async () => {
            const insertAssemblyFileObjects: file_agendaitemfiles_insert_input[] = [
                {
                    fileid: fileItem.fileId,
                    agendaitemid:
                        assemblyStore.focusedAgendaItemIdText !== ""
                            ? assemblyStore.focusedAgendaItemIdText
                            : assemblyStore.focusedAgendaItemIdDescription,
                    roleid: 30,
                    visible: true,
                    type: fileItem.fileType
                },
                {
                    fileid: fileItem.fileId,
                    agendaitemid:
                        assemblyStore.focusedAgendaItemIdText !== ""
                            ? assemblyStore.focusedAgendaItemIdText
                            : assemblyStore.focusedAgendaItemIdDescription,
                    roleid: 32,
                    visible: true,
                    type: fileItem.fileType
                }
            ];

            await insertAgendaItemFileMutation({
                variables: {
                    objects: insertAssemblyFileObjects
                }
            });

            removeFileFromDisplayedList(fileItem.fileId ?? "");
            assemblyStore.setShowRefetchAssemblyData(true);
        },
        [removeFileFromDisplayedList, insertAgendaItemFileMutation, assemblyStore]
    );

    const filesItems = displayedFileItems.map((fileItem) => {
        let assemblyFileLink = NetworkConfig.datafileUrl + fileItem.fileId;

        const addDocumentsIcon = (
            <div className={styles.PlusIcon} onClick={onAddDocumentToAgendaItem(fileItem)}>
                +
            </div>
        );

        return (
            <AssemblyDocumentsListItem
                key={fileItem.fileId}
                fileDate={fileItem.fileDate}
                fileId={fileItem.fileId}
                fileName={fileItem.fileName}
                additionalIcon={addDocumentsIcon}
                fileLink={assemblyFileLink}
                isDisplayedInModal={true}
            />
        );
    });

    return (
        <div>
            <div style={{ width: "93%" }}>
                <UI.Input type="search" onChange={onSearch} placeholder={t("labels.search")} value={searchQuery} />
            </div>
            <div className="mb-4" />
            <div>{filesItems}</div>
        </div>
    );
};

export const AddAgendaDocumentsModalList = Sentry.withProfiler(observer(AddAgendaDocumentsModalListBase));
