import { gql } from "@apollo/client";

export const GET_COMMON_OWNERS = gql`
    query CommonOwner {
        common_owners {
            id
            person {
                name1
                name2
            }
            realestates {
                id
            }
        }
    }
`;
