import React, { useCallback, useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./AssignTicketProgress.module.css";
import generalStyles from "src/screens/tickets/ticket/details/actions/assignOrder/AssignOrderModal.module.css";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { ASSIGN_TICKET_STATE } from "src/stores/tickets/AssignTicketModalStore";

interface AssignTicketProgressProps {
    currentAssignTicketState: ASSIGN_TICKET_STATE;
}

export const AssignTicketProgressBase = (props: AssignTicketProgressProps) => {
    const { t } = useTranslation();
    const { assignTicketModalStore } = useContext(RootStoreContext);

    const { currentAssignTicketState } = props;

    const onClickFinishAssignTicket = useCallback(() => {
        assignTicketModalStore.setIsModalDisplayed(false);
        assignTicketModalStore.resetAssignTicketModal();
    }, [assignTicketModalStore]);

    let icon;
    let text;

    switch (currentAssignTicketState) {
        case ASSIGN_TICKET_STATE.ASSIGNING:
            icon = <UI.RotatingSpinner noLogo size={50} className="loading-items-spinner" />;
            text = t("screens.tickets.action.assign_ticket.in_progress").toString();
            break;
        case ASSIGN_TICKET_STATE.FINISHED:
            icon = <UI.Icon icon={UI.SVGIcon.Check} size={50} color={"black"} />;
            text = t("screens.tickets.action.assign_ticket.success").toString();
            break;
    }

    return (
        <UI.Row className={"mt-2 d-flex"}>
            <UI.Col
                xs={12}
                className={`d-flex align-items-center justify-content-center ${styles.AssignTicketProgressContainer}`}
            >
                <div className="d-flex align-items-center flex-column">
                    <div>{icon}</div>
                    <div className="d-flex mt-2">
                        <span className={`${styles.AssignTicketProgressText} text-center`}>{text}</span>
                    </div>
                </div>
            </UI.Col>
            <UI.Col xs={12} className="mt-4">
                <UI.Button
                    className={`ticket-button mt-2 ${generalStyles.NextButton}`}
                    label={t("labels.finished").toString()}
                    disabled={currentAssignTicketState === ASSIGN_TICKET_STATE.ASSIGNING ? true : false}
                    onClick={onClickFinishAssignTicket}
                ></UI.Button>
            </UI.Col>
        </UI.Row>
    );
};

export const AssignTicketProgress = Sentry.withProfiler(observer(AssignTicketProgressBase));
