import React from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { AssignOrderButtonAndModal } from "src/components/ticketing/AssignOrderButtonAndModal";
import { CloseTicketButtonAndModal } from "src/components/ticketing/CloseTicketButtonAndModal";
import { ManualEmailButtonAndModal } from "src/components/ticketing/ManualEmailButtonAndModal";

const TicketActionSelectorBase = () => {
    return (
        <>
            <AssignOrderButtonAndModal />
            <div className="mb-2" />
            <CloseTicketButtonAndModal />
            <div className="mb-2" />
            <ManualEmailButtonAndModal />
        </>
    );
};

export const TicketActionSelector = Sentry.withProfiler(observer(TicketActionSelectorBase));
