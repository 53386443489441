import { gql } from "@apollo/client";

export const GET_COMMON_SEARCHTEXT = gql`
    query GetCommonSearchText($searchtext: String, $planguage: String) {
        common_searchtext(
            args: {
                searchtext: $searchtext
                planguage: $planguage
            }
            order_by: { similarvalue: desc }
        ) {
            id
            entity
            realestateid
            fileid
            tenantid
            similarvalue
            description
            title
            accountlistid
            accountid
        }
    }
`;
