import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import * as serviceWorker from "src/serviceWorker";
import App from "src/App";
import "src/utils/i18n";
import "src/utils/Date";
import manifestDev from "src/manifest_dev.json";
import manifest from "src/manifest.json";
import { createRoot } from "react-dom/client";

const isLocalhost = window.location.host.indexOf("localhost") === 0;
const upperCaseSubdomain = () => {
    const subdomain = window.location.hostname?.split(".")[0];
    return `${subdomain[0].toUpperCase()}${subdomain.slice(1).toLowerCase()}`;
};

let manifestJSON = process.env.REACT_APP_ENVIRONMENT === "dev" ? manifestDev : manifest;
const name = isLocalhost ? "Localhost Portal" : `${upperCaseSubdomain()} Portal`;
manifestJSON.name = name;
manifestJSON.short_name = name;
const stringManifest = JSON.stringify(manifestJSON);
const blob = new Blob([stringManifest], { type: "application/json" });
const manifestURL = URL.createObjectURL(blob);
document?.querySelector("#my-manifest-placeholder")?.setAttribute("href", manifestURL);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

serviceWorker.unregister();
